import {
  ListUsersForAccountApiArg,
  V1PagingMeta,
  V1Relationships,
  useListUsersForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { User } from "../types";

export interface HookArgs {
  page?: ListUsersForAccountApiArg["page"];
  filter?: ListUsersForAccountApiArg["filter"];
}

const useListUsersOnAccount = ({ page, filter }: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } = useListUsersForAccountQuery({
    slug: accountSlug,
    include: [],
    page,
    filter,
  });

  let users: User[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    try {
      users = data.data.map((user) => {
        if (!user.id) {
          throw new Error("Missing user.id");
        }

        return {
          id: user.id,
          name: user.attributes.name,
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return { users, error: error || customError, isLoading, pageMeta, refetch };
};

export default useListUsersOnAccount;
