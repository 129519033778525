import React, { useEffect, useState } from "react";
import OnboardingCards from "../reusable/OnboardingCards";
import { useNavigate } from "react-router";
import fileGreenCheckGears from "../images/fileGreenCheckGears.png";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import {
  faCheck,
  faX,
  faPencil,
  faTrashCan,
  faGripDotsVertical,
} from "@fortawesome/pro-solid-svg-icons";
import API from "@utils/API/API";
import {
  formatUnprocessibleResponse,
  handleAlert,
  validateDrop,
} from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function LanguageFieldEdit({
  languageFields,
  allLanguageFields,
  isLoading,
  account_slug,
  account_id,
  authorizationCode,
  setRerender,
}) {
  let navigate = useNavigate();
  const [lfToDisplay, setLfToDisplay] = useState([]);
  const [errorMessages, setErrorMessages] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showLanguageFieldConfirmation, setShowLanguageFieldConfirmation] =
    useState(false);
  const [languageFieldToDelete, setLanguageFieldToDelete] = useState({});
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  useEffect(() => {
    let newLanguageFields = [...languageFields].map((languageField) => {
      languageField.userIsEditing = false;
      languageField.languageFieldNameChanged = false;
      return languageField;
    });
    setLfToDisplay(
      newLanguageFields.sort(
        (a, b) => a.attributes.position - b.attributes.position
      )
    );
  }, [languageFields, isLoading]);

  useEffect(() => {
    if (!authorizationCode) return;

    API.Get(`${apiHost}/v1/me`, authorizationCode).then((response) => {
      API.Patch(
        `${apiHost}/${account_slug}/v1/users/${response.data.data.id}`,
        {
          data: {
            id: response.data.data.id,
            type: "users",
            attributes: {
              "guided-onboarding": {
                onboarding_status: "language-fields-edit",
              },
            },
          },
        },
        authorizationCode
      ).then((response) => {
        console.log(response);
      });
    });
  }, [authorizationCode]);

  window.onpopstate = function (e) {
    setRerender(true);
  };

  const lfText = (
    <div>
      <p>
        <strong>Language Fields</strong> in ScopeStack assist you in describing
        Services and Subservices more precisely and accurately.
      </p>{" "}
      <p>
        They typically align to sections in your Scope of Work such as “Scope of
        Services”, "Key Assumptions", and  "Client Responsibilities."
      </p>
      <p>Customize, add, or remove them to match your document sections. </p>
    </div>
  );

  const addLanguageField = () => {
    let newLanguageField = {
      id: `newLanguageField-${Math.floor(Math.random() * 100)}`,
      userIsEditing: true,
      languageFieldNameChanged: false,
      attributes: { name: "" },
    };
    if (lfToDisplay.filter((lf) => lf.userIsEditing).length == 0) {
      let newLanguageFields = [...lfToDisplay];
      newLanguageFields.splice(0, 0, newLanguageField);
      setLfToDisplay(newLanguageFields);
    }
  };

  const deleteLanguageField = (lfToDelete) => {
    setShowLanguageFieldConfirmation(false);
    if (lfToDelete) {
      if (lfToDelete.id.includes("newLanguageField")) {
        setLfToDisplay(
          lfToDisplay.filter(
            (languageField) => languageField.id !== lfToDelete.id
          )
        );
        return;
      }
      let languageFieldData = {
        data: {
          type: "language-fields",
          id: lfToDelete.id,
        },
      };
      API.Delete(
        `${apiHost}/${account_slug}/v1/language-fields/${lfToDelete.id}`,
        languageFieldData,
        authorizationCode
      )
        .then((res) => {
          setLfToDisplay(
            lfToDisplay.filter(
              (languageField) => languageField.id !== lfToDelete.id
            )
          );
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "language field"));
          setShowFailAlert(true);
        });
    }
  };

  const submitLanguageField = (languageField) => {
    let submitMethod = "POST";
    let url = `${apiHost}/${account_slug}/v1/language-fields`;
    let languageFieldData = {
      data: {
        type: "language-fields",
        attributes: {
          name: languageField.newLanguageFieldName
            ? languageField.newLanguageFieldName
            : languageField.attributes.name,
        },
      },
    };
    if (!languageField.id.includes("newLanguageField")) {
      languageFieldData.data.id = languageField.id;
      submitMethod = "PATCH";
      url = `${apiHost}/${account_slug}/v1/language-fields/${languageField.id}`;
    }
    API.Submit(submitMethod, url, languageFieldData, authorizationCode)
      .then((res) => {
        setLfToDisplay(
          lfToDisplay.map((lf) => {
            if (lf.id.includes("newLanguageField") && submitMethod == "POST") {
              lf.id = res.data.data.id;
            }
            if (lf.id == res.data.data.id) {
              lf.userIsEditing = false;
              lf.languageFieldNameChanged = false;
              lf.attributes.name = res.data.data.attributes.name;
            }
            return lf;
          })
        );
      })
      .catch((err) => {
        let error = formatUnprocessibleResponse(err, "language field");
        if (
          error.includes("Name has already been taken") &&
          lfToDisplay.filter(
            (lf) =>
              (lf.attributes.name === languageField.attributes.name ||
                (languageField.newLanguageFieldName &&
                  languageField.newLanguageFieldName == lf.attributes.name)) &&
              lf.id !== languageField.id
          ).length == 0
        ) {
          let languageFieldToReactivate = {};
          allLanguageFields.forEach((lf) => {
            if (
              (lf.attributes.name === languageField.attributes.name ||
                (languageField.newLanguageFieldName &&
                  languageField.newLanguageFieldName == lf.attributes.name)) &&
              lf.id !== languageField.id
            ) {
              languageFieldToReactivate = lf;
            }
          });
          API.Patch(
            `${apiHost}/${account_slug}/v1/language-fields/${languageFieldToReactivate.id}`,
            {
              data: {
                id: languageFieldToReactivate.id,
                type: "language-fields",
                attributes: { active: true, position: lfToDisplay.length + 1 },
              },
            },
            authorizationCode
          ).then((res) => {
            let reactivatedLanguageField = res.data.data;
            reactivatedLanguageField.userIsEditing = false;
            reactivatedLanguageField.languageFieldNameChanged = false;
            deleteLanguageField(languageField);
            if (submitMethod == "PATCH") {
              lfToDisplay.push(reactivatedLanguageField);
              setLfToDisplay(lfToDisplay);
            } else {
              setLfToDisplay([
                ...lfToDisplay.filter((lf) => lf.id != languageField.id),
                reactivatedLanguageField,
              ]);
            }
          });
        } else {
          setErrorMessages(error);
          setShowFailAlert(true);
        }
      });
  };

  const dragLanguageFieldEnd = (result) => {
    let isValid = validateDrop(result);
    if (!isValid) return;
    const { source, destination } = result;
    const copyListItems = [...lfToDisplay];
    const dragItemContent = copyListItems[source.index];
    if (destination.droppableId !== "LfDropZone") return;
    copyListItems.splice(source.index, 1);
    copyListItems.splice(destination.index, 0, dragItemContent);
    let newLf = lfToDisplay.filter(
      (o1) => !copyListItems.some((o2) => o1.id === o2.id)
    );
    setLfToDisplay(newLf.concat(copyListItems));
    let lfToUpdate = dragItemContent;
    lfToUpdate.type = "language-fields";
    delete lfToUpdate.userIsEditing;
    delete lfToUpdate.languageFieldNameChanged;
    delete lfToUpdate.newLanguageFieldName;
    delete lfToUpdate.links;
    lfToUpdate.attributes = {
      name: dragItemContent.attributes.name,
      position:
        destination.index == 0 ? 1 : copyListItems.indexOf(dragItemContent) + 1,
    };
    lfToUpdate.relationships = {
      account: { data: { id: account_id, type: "accounts" } },
    };
    API.Patch(
      `${apiHost}/${account_slug}/v1/language-fields/${lfToUpdate.id}/move-to`,
      { data: lfToUpdate },
      authorizationCode
    ).catch((err) => {
      setErrorMessages(formatUnprocessibleResponse(err, "lanugage field"));
      setShowFailAlert(true);
    });
  };

  const getLanguageFields = () => {
    if (isLoading) {
      return <SmallSpinner />;
    } else {
      return (
        <>
          <Row
            style={{
              marginLeft: "1px",
              display: "flex",
              alignItems: "center",
            }}
            className="cursorPoint whiteBar"
            onClick={() => addLanguageField()}
          >
            <Col style={{ paddingLeft: "5px" }}>
              <strong
                style={
                  lfToDisplay.filter((lf) => lf.userIsEditing).length == 0
                    ? { color: "#418172" }
                    : { color: "darkgray" }
                }
              >
                Add a language field +
              </strong>
            </Col>
          </Row>
          <Row
            style={{
              marginLeft: "1px",
              display: "flex",
              alignItems: "center",
            }}
            className="whiteBar"
          >
            <Col style={{ paddingLeft: "5px" }}>
              <strong style={{ color: "darkgray " }}>
                Service Description (required)
              </strong>
            </Col>
          </Row>
          <DragDropContext onDragEnd={dragLanguageFieldEnd}>
            <Droppable droppableId="LfDropZone">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {provided.placeholder}
                  {lfToDisplay.map((languageField, index) => (
                    <Draggable
                      key={languageField.id}
                      draggableId={languageField.id}
                      index={index}
                      isDragDisabled={false}
                    >
                      {(provided) => (
                        <div
                          key={languageField.id}
                          id={languageField.id}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {provided.placeholder}
                          <Row
                            key={languageField.id}
                            style={
                              languageField.userIsEditing
                                ? {
                                    padding: "4px 10px 4px 4px",
                                    height: "45px",
                                    marginLeft: "1px",
                                    display: "flex",
                                    alignItems: "center",
                                  }
                                : {
                                    marginLeft: "1px",
                                    display: "flex",
                                    alignItems: "center",
                                  }
                            }
                            className={"whiteBar"}
                          >
                            <Col style={{ paddingLeft: "5px" }}>
                              {languageField.userIsEditing ? (
                                <Form.Control
                                  type="text"
                                  value={
                                    languageField.languageFieldNameChanged
                                      ? languageField.newLanguageFieldName
                                      : languageField.attributes.name
                                  }
                                  placeholder="Enter language field name"
                                  onChange={(e) => {
                                    languageField.newLanguageFieldName =
                                      e.target.value;
                                    languageField.languageFieldNameChanged = true;
                                    setLfToDisplay(
                                      lfToDisplay.map((lf) => {
                                        if (lf.id == languageField.id) {
                                          lf.newLanguageFieldName =
                                            e.target.value;
                                          lf.languageFieldNameChanged = true;
                                        }
                                        return lf;
                                      })
                                    );
                                  }}
                                />
                              ) : (
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="gripIcon"
                                    icon={faGripDotsVertical}
                                  />
                                  <strong>
                                    {languageField.attributes.name}
                                  </strong>
                                </span>
                              )}
                            </Col>

                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {!languageField.userIsEditing &&
                              lfToDisplay.filter((lf) => lf.userIsEditing)
                                .length > 0 ? null : (
                                <FontAwesomeIcon
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  icon={
                                    languageField.userIsEditing
                                      ? faCheck
                                      : faPencil
                                  }
                                  onClick={() => {
                                    if (
                                      !languageField.userIsEditing &&
                                      lfToDisplay.filter(
                                        (lf) => lf.userIsEditing
                                      ).length == 0
                                    ) {
                                      languageField.userIsEditing = true;
                                      setLfToDisplay(
                                        lfToDisplay.map((lf) => {
                                          if (
                                            lf.id == languageField.id &&
                                            !languageField.userIsEditing
                                          ) {
                                            lf.userIsEditing = true;
                                          }
                                          return lf;
                                        })
                                      );
                                    }
                                    if (
                                      languageField.userIsEditing &&
                                      languageField.languageFieldNameChanged
                                    ) {
                                      submitLanguageField(languageField);
                                    } else if (
                                      languageField.userIsEditing &&
                                      languageField.attributes.name ==
                                        "Enter languageField name"
                                    ) {
                                      deleteLanguageField(languageField);
                                    }
                                  }}
                                />
                              )}
                              {!languageField.userIsEditing &&
                              lfToDisplay.filter((lf) => lf.userIsEditing)
                                .length > 0 ? null : (
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer" }}
                                  icon={
                                    languageField.userIsEditing
                                      ? faX
                                      : faTrashCan
                                  }
                                  onClick={() => {
                                    if (
                                      languageField.id.includes(
                                        "newLanguageField"
                                      )
                                    ) {
                                      deleteLanguageField(languageField);
                                    }
                                    if (
                                      languageField.userIsEditing &&
                                      !languageField.id.includes(
                                        "newLanguageField"
                                      )
                                    ) {
                                      setLfToDisplay(
                                        lfToDisplay.map((lf) => {
                                          if (lf.id == languageField.id) {
                                            lf.userIsEditing = false;
                                            lf.languageFieldNameChanged = false;
                                            delete lf.newLanguageFieldName;
                                          }
                                          return lf;
                                        })
                                      );
                                    } else if (
                                      !languageField.userIsEditing &&
                                      !languageField.id.includes(
                                        "newLanguageField"
                                      )
                                    ) {
                                      setLanguageFieldToDelete(languageField);
                                      setShowLanguageFieldConfirmation(true);
                                    }
                                  }}
                                />
                              )}
                            </div>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      );
    }
  };
  return (
    <>
      <ConfirmationModal
        show={showLanguageFieldConfirmation}
        title="Delete Language Field"
        message="Are you sure you want to delete this language field?"
        onConfirm={() => deleteLanguageField(languageFieldToDelete)}
        onCancel={() => {
          setLanguageFieldToDelete({});
          setShowLanguageFieldConfirmation(false);
        }}
      />
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <OnboardingCards
        contentCardHeader={"Let’s tailor your account to the way you work"}
        leftContent={
          <>
            {lfText}
            {getLanguageFields()}
          </>
        }
        rightContent={
          <div>
            <img
              src={fileGreenCheckGears}
              alt="caricature files with green check mark, gears, and graphs"
              style={{ width: "100%" }}
            />
          </div>
        }
        rightButtonText={"Continue"}
        rightButtonOnClick={() => {
          setRerender(true);
          navigate("/onboarding/resources");
        }}
        disabledRightButton={
          lfToDisplay.filter((lf) => lf.userIsEditing).length !== 0
        }
        skipSetupButton={true}
      />
    </>
  );
}

export default LanguageFieldEdit;
