import { useListUserFiltersForAccountQuery, ServiceFilter } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { UserSelectionsState } from "../types";

interface Filter {
  label: string;
  value: string;
  serviceType: string;
  filters: ServiceFilter;
}

const useListServiceFiltersForAccount = (serviceType) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError

  const { data, error, isLoading, refetch, isFetching } =
    useListUserFiltersForAccountQuery({
      slug: accountSlug,
    });

  let filters: Filter[] = [];

  if (data?.data) {
    try {
      filters = data.data.map((filter, i) => {
        if (!filter.id) {
          throw new Error("Missing filter.id");
        }
        if (!filter.attributes) {
          throw new Error("Missing filter.attributes");
        }

        return {
          value: String(filter.id),
          label: filter.attributes.name || "",
          serviceType: filter.attributes?.["data-type"],
          filters: filter.attributes.filters as ServiceFilter,
        };
      });
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    filters,
    filtersLoading: isLoading,
    error: error ?? customError,
    refetch,
    filtersFetching: isFetching,
  };
};
export default useListServiceFiltersForAccount;
