import React from "react";
import Outcome from "../Outcome/Outcome";

function NewOutcome({
  account_slug,
  project_id,
  projectStatus,
  outcomes,
  outcomeId,
  account_id,
  setOutcomeCreated,
  authorizationCode,
}) {
  return (
    <Outcome
      account_slug={account_slug}
      project_id={project_id}
      projectStatus={projectStatus}
      outcomes={outcomes}
      outcomeId={outcomeId}
      account_id={account_id}
      setOutcomeCreated={setOutcomeCreated}
      authorizationCode={authorizationCode}
    />
  );
}

export default NewOutcome;
