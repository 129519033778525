import { useEffect, useState } from "react";
import FormTextField from "@components/Forms/FormTextField";
import DataTable from "@components/DataTable";
import { Form, Button } from "react-bootstrap";
import useListUsersOnAccount from "./api/useListUsersOnAccount";
import useGetAccount from "./api/useGetAccount";
import { faCirclePlus, faCircleMinus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import SearchField from "@components/Forms/SearchField/SearchField";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { useNavigate } from "react-router";
import {
  useCreateTeamMutation,
  V1TeamResource,
  useUpdateTeamMutation,
} from "@generated";
import FEPaginatedTable from "@components/Tables/FEPaginatedTable/FEPaginatedTable";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

const TeamInfo = ({ mode, data, refetch = () => {} }) => {
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const navigate = useNavigate();
  const [createTeam] = useCreateTeamMutation();
  const [updateTeam] = useUpdateTeamMutation();
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [filter, setFilter] = useState<{ name?: string; exclude?: string }>(
    mode == "edit" && data.users ? { exclude: data.users.map((u) => u.id) } : {}
  );
  const [assignedUsers, setAssignedUsers] = useState(
    mode == "edit" && data.users ? data.users : []
  );
  const [teamName, setTeamName] = useState(
    mode == "edit" && data.name ? data.name : ""
  );
  const [displayedAssignedUsers, setDisplayedAssignedUsers] = useState(
    mode == "edit" && data.users ? data.users : []
  );
  const [availableUsersSearch, setAvailableUsersSearch] = useState("");
  const [assignedUsersSearch, setAssignedUsersSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);

  const {
    users,
    isLoading: usersLoading,
    pageMeta,
  } = useListUsersOnAccount({
    page: {
      number: currentPage,
      size: pageSize,
    },
    filter,
  });

  useEffect(() => {
    setFilter({
      ...filter,
      exclude: assignedUsers.map((u) => String(u.id)).join(","),
    });
    setDisplayedAssignedUsers(assignedUsers);
  }, [currentPage, assignedUsers]);

  interface Column {
    header: string;
    cell: (props: any) => JSX.Element;
  }

  const { account, isLoading: accountLoading } = useGetAccount();

  const availableUsersColumns: Column[] = [
    {
      header: "Available Users",
      cell(props) {
        if (props.row.original.name == "No available users") {
          return <>{props.row.original.name}</>;
        }
        return (
          <span
            key={props.row.original.id}
            className="tableItem"
            onClick={() => {
              if (
                assignedUsers.filter((u) => u.id == props.row.original.id)
                  .length == 0
              ) {
                setAssignedUsers([
                  ...assignedUsers,
                  {
                    id: props.row.original.id,
                    name: props.row.original.name,
                  },
                ]);
              }
            }}
          >
            <FontAwesomeIcon className="addUser" icon={faCirclePlus} />
            {props.row.original.name}
          </span>
        );
      },
    },
  ];
  const assignedUsersColumns: Column[] = [
    {
      header: "Assigned Users",
      cell(props) {
        if (
          props.row.original.name ==
          "Assign users to get started creating a team"
        ) {
          return <>{props.row.original.name}</>;
        }

        return (
          <span
            key={props.row.original.id}
            className="tableItem"
            onClick={() => {
              setCurrentPage(1);
              setAssignedUsers(
                assignedUsers.filter(
                  (user) => user.id !== props.row.original.id
                )
              );
            }}
          >
            <FontAwesomeIcon className="removeUser" icon={faCircleMinus} />
            {props.row.original.name}
          </span>
        );
      },
    },
  ];

  const searchAvailableUsers = (userName: string) => {
    setFilter({ ...filter, name: userName });
  };

  const searchAssignedUsers = (userName: string) => {
    if (userName == "") {
      setDisplayedAssignedUsers(assignedUsers);
    } else {
      setDisplayedAssignedUsers(
        assignedUsers.filter((u) =>
          u.name.toLowerCase().includes(userName.toLowerCase())
        )
      );
    }
  };

  const handleAvailableSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchAvailableUsers(availableUsersSearch);
  };

  const handleAssignedSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchAssignedUsers(assignedUsersSearch);
  };

  const getAssignedUsers = () => {
    return displayedAssignedUsers.length < 1
      ? {
          data: [
            {
              id: 0,
              checked: false,
              attributes: {
                name: "No users assigned",
              },
            },
          ],
          meta: {
            "record-count": 0,
          },
        }
      : {
          data: displayedAssignedUsers.map((u) => {
            return {
              id: u.id,
              checked: false,
              attributes: {
                name: (
                  <span
                    key={u.id}
                    className="tableItem"
                    onClick={() => {
                      setCurrentPage(1);
                      setDisplayedAssignedUsers(
                        displayedAssignedUsers.filter(
                          (user) => user.id !== u.id
                        )
                      );
                      setAssignedUsers(
                        displayedAssignedUsers.filter(
                          (user) => user.id !== u.id
                        )
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      className="removeUser"
                      icon={faCircleMinus}
                    />
                    {u.name}
                  </span>
                ),
              },
            };
          }),
          meta: { "record-count": assignedUsers.length },
        };
  };

  const handleSaveTeam = () => {
    if (
      (mode == "create" && teamName == "") ||
      (mode == "edit" &&
        JSON.stringify(assignedUsers.slice().sort()) ==
          JSON.stringify(data.users.slice().sort()) &&
        teamName == data.name)
    ) {
      return;
    }

    if (account !== null) {
      let teamData: V1TeamResource = {
        type: "teams",
        attributes: {
          name: teamName,
          "created-at":
            mode == "create" ? Date.now().toLocaleString() : data.createdAt,
        },
        relationships: {
          account: {
            data: {
              id: account.id,
              type: "accounts",
            },
          },
        },
      };
      if (assignedUsers.length > 0) {
        const users = assignedUsers.map((user) => {
          return { type: "users", id: user.id };
        });

        const ids: number[] = [];
        const uniqueUsers = users.filter((obj: { id: number }) => {
          if (ids.indexOf(obj.id) === -1) {
            ids.push(obj.id);
            return true;
          }
          return false;
        });

        teamData.relationships.users = {
          data: uniqueUsers,
        };
      }

      if (mode == "edit") {
        teamData.id = Number(data.id);
        updateTeam({
          slug: accountSlug,
          id: data.id,
          body: { data: teamData },
        })
          .unwrap()
          .then((res) => {
            if (res?.data?.id) {
              setSuccessMessage("Team updated.");
              setShowSuccessAlert(true);
              refetch();
            }
          })
          .catch((error) => {
            const msg = error?.data?.errors?.[0]?.detail;
            if (msg) {
              setErrorMessage(msg);
              setShowFailAlert(true);
            } else {
              setErrorMessage("Something went wrong.");
              setShowFailAlert(true);
            }
          });
      } else {
        createTeam({ slug: accountSlug, body: { data: teamData } })
          .unwrap()
          .then((res) => {
            if (res?.data?.id) {
              const teamId = res.data.id;
              const currentPageURL = window.location.href;
              localStorage.setItem("lastPage", currentPageURL);
              navigate(`/admin/teams/edit/${teamId}`);
            }
          })
          .catch((error) => {
            const msg = error?.data?.errors?.[0]?.detail;
            if (msg) {
              setErrorMessage(msg);
              setShowFailAlert(true);
            } else {
              setErrorMessage("Something went wrong.");
              setShowFailAlert(true);
            }
          });
      }
    }
  };

  if (usersLoading || accountLoading) {
    return (
      <div className="usersLoading">
        <ScopeStackSpinner />
      </div>
    );
  }

  return (
    <>
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <div className="editTeamsCard">
        <div className="editTeamsHeader">
          <h3>{`${mode == "create" ? "Create" : "Edit"}`} a Team</h3>
          <div>
            <Button
              style={{ marginRight: "10px" }}
              onClick={() => {
                if (mode == "create") {
                  navigate("/admin/teams");
                } else {
                  window.location.replace(`${appHost}/admin/teams`);
                }
              }}
              className="ssButtonBgWhite"
            >
              Cancel
            </Button>
            <Button
              id={"saveTeam"}
              style={{ marginRight: "10px" }}
              onClick={handleSaveTeam}
              className={`seafoamBgButton ${
                (mode == "create" && teamName == "") ||
                (mode == "edit" &&
                  JSON.stringify(assignedUsers.slice().sort()) ==
                    JSON.stringify(data.users.slice().sort()) &&
                  teamName == data.name)
                  ? "disabled"
                  : ""
              }`}
            >
              Save Team
            </Button>
          </div>
        </div>
        <div className="teamInfoContainer">
          <FormTextField
            id="teamName"
            onChange={(e) => setTeamName(e.target.value)}
            value={teamName}
            placeholder="Name this team"
            label={`${mode == "create" ? "*" : ""}Team name`}
            required={mode == "create"}
            readOnly={false}
          />
          <h3>Assign Team Members</h3>
          <div className="assignUserTables">
            <div className="availableUsers">
              <FormFieldLabel
                label="Search Available Users"
                className={undefined}
              />
              <Form
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAvailableSearch(e);
                  }
                }}
                onSubmit={handleAvailableSearch}
              >
                <SearchField
                  placeholder="Search by user name"
                  onChange={(e) => {
                    setAvailableUsersSearch(e.target.value);
                  }}
                  value={availableUsersSearch}
                  onClick={() => {
                    setAvailableUsersSearch("");
                    searchAvailableUsers("");
                  }}
                  fullWidth
                />
              </Form>
              <DataTable
                data={
                  users.length < 1
                    ? [
                        {
                          id: 0,
                          name: "No available users",
                        },
                      ]
                    : users
                }
                columns={availableUsersColumns}
                selectable={false}
                bordered
                striped
                hover
                totalPages={pageMeta.pageCount}
                totalRows={pageMeta.recordCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
                selectedIds={[]}
                setSelectedIds={() => null}
                paginationEnabled
              />
            </div>
            <div className={`assignedUsers ${mode == "edit" ? "edit" : ""}`}>
              {mode == "edit" ? (
                <>
                  <FormFieldLabel
                    label="Search Assigned Users"
                    className={undefined}
                  />
                  <Form
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleAssignedSearch(e);
                      }
                    }}
                    onSubmit={handleAssignedSearch}
                  >
                    <SearchField
                      placeholder="Search by user name"
                      onChange={(e) => {
                        const value = e.target.value;
                        setAssignedUsersSearch(value);
                        if (value == "") {
                          searchAssignedUsers("");
                        }
                      }}
                      value={assignedUsersSearch}
                      onClick={() => {
                        setAssignedUsersSearch("");
                        searchAssignedUsers("");
                      }}
                      fullWidth
                    />
                  </Form>
                </>
              ) : (
                <div style={{ height: "70px" }}></div>
              )}
              {mode == "edit" ? (
                <FEPaginatedTable
                  tableType="users"
                  tableItemsData={getAssignedUsers()}
                  columnHeaders={[
                    {
                      label: "Assigned Users",
                      width: 12,
                      sorted: "desc",
                      sortable: false,
                    },
                  ]}
                  checkboxes={false}
                  filtered={false}
                  checkedItems={[]}
                  setCheckedItems={() => null}
                  pageSizeEnabled={false}
                />
              ) : (
                <DataTable
                  data={
                    assignedUsers.length < 1
                      ? [
                          {
                            id: 0,
                            name: "Assign users to get started creating a team",
                          },
                        ]
                      : assignedUsers
                  }
                  columns={assignedUsersColumns}
                  selectable={false}
                  bordered
                  striped
                  hover
                  totalPages={pageMeta.pageCount}
                  totalRows={pageMeta.recordCount}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                  selectedIds={[]}
                  setSelectedIds={() => null}
                  paginationEnabled={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamInfo;
