import React, { useEffect, useContext } from "react";
import ServicesByPhase from "../ServicesByPhase";
import { AccountContext } from "../../../ProjectsMspa";

function ApplyQuestionnaire({
  subpage,
  setSubpage,
  services,
  resources,
  subservices,
  phases,
  projectId,
  rateType,
  resourceRatesEnabled,
  resourceRates,
  lobs,
}) {
  useEffect(() => {
    // Add grey background to body
    document.body.setAttribute("style", "background: #eee");

    // Remove screenoverlay from slideout on previous page
    if (document.querySelector(".screenOverlay")) {
      document.querySelector(".screenOverlay").remove();
    }
  }, []);
  const account = useContext(AccountContext);

  let phasesWithServices = new Set();
  services.forEach((service) => {
    if (service.relationships["phase"] && service.relationships["phase"].data) {
      phases.forEach((phase) => {
        if (phase.id == service.relationships["phase"].data.id) {
          phasesWithServices.add(phase);
        }
      });
    }
  });

  return (
    <ServicesByPhase
      authorizationCode={account.authorizationCode}
      account_slug={account.slug}
      subpage={subpage}
      setSubpage={setSubpage}
      projectId={projectId}
      data={{
        data: services,
        resources,
        projectSubservices: subservices,
        phases: Array.from(phasesWithServices),
      }}
      resources={resources}
      rateType={rateType}
      title="Add Services From Survey"
      subtitle="Review survey and apply recommended services."
      submitButtonText="Apply"
      sourcePage={"addByQuestionnaire"}
      resourceRatesEnabled={resourceRatesEnabled}
      resourceRates={resourceRates}
      lobs={lobs}
    />
  );
}

export default ApplyQuestionnaire;
