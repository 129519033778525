import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import {
  useGetServiceLevelsOfEffortQuery,
  useGetSubserviceLevelsOfEffortQuery,
} from "@generated";
import { TaskRate } from "../types";

const useListLOEs = (service) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError

  const { data, error, isLoading, refetch, isFetching } =
    service.type === "service"
      ? useGetServiceLevelsOfEffortQuery({
          slug: accountSlug,
          serviceId: service.id,
        })
      : useGetSubserviceLevelsOfEffortQuery({
          slug: accountSlug,
          subserviceId: service.id,
        });

  let loes: TaskRate[] = [];

  if (data?.data) {
    try {
      loes = data.data.map((loe) => {
        if (!loe.id) {
          throw new Error("Missing loe.id");
        }

        return {
          id: String(loe.id),
          type: "variable_hour",
          minQty: loe?.attributes?.["minimum-quantity"] || 1,
          fixedAmt: loe?.attributes?.["base-amount"] || 0,
          unitAmt: loe?.attributes?.["unit-amount"] || 0,
        };
      });
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    loes,
    loesLoading: isLoading,
    error: error ?? customError,
    refetchLOEs: refetch,
    loesFetching: isFetching,
  };
};
export default useListLOEs;
