import React, { useEffect, useState } from "react";
import ServicesByPhase from "../ServicesByPhase";
import useProjectServicesData from "../api/useProjectServicesData";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";

function ServicesAdded({
  authorizationCode,
  account_slug,
  subpage,
  setSubpage,
  projectId,
  setServicesHaveBeenAdded,
  onData,
  resources,
  rateType,
  onServicesData,
  continueProjectCreationWorkflow,
  resourceRates,
  resourceRatesEnabled,
  lobs,
}) {
  const { data, error, loaded } = useProjectServicesData({
    account_slug,
    authorizationCode,
    projectId,
  });
  const [continueClicked, setContinueClicked] = useState(false);

  useEffect(() => {
    if (data !== null) {
      onData(data);
      onServicesData(data);
    }
  }, [data]);

  useEffect(() => {
    setServicesHaveBeenAdded(true);
  }, []);

  useEffect(() => {
    if (continueClicked) {
      continueProjectCreationWorkflow();
    }
  }, [continueClicked]);

  return loaded ? (
    <ServicesByPhase
      authorizationCode={authorizationCode}
      account_slug={account_slug}
      subpage={subpage}
      setSubpage={setSubpage}
      projectId={projectId}
      data={data}
      resources={resources}
      rateType={rateType}
      setContinueClicked={(bool) => setContinueClicked(bool)}
      isServicesAdded={true}
      resourceRates={resourceRates}
      resourceRatesEnabled={resourceRatesEnabled}
      lobs={lobs}
    />
  ) : (
    <SmallSpinner />
  );
}

export default ServicesAdded;
