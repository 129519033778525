import { combineReducers } from "@reduxjs/toolkit";
import { authReducer, slugReducer, userReducer } from "../slices";
import { scopeStackApi } from "../services/ScopeStackAPI";

const rootReducer = combineReducers({
  [scopeStackApi.reducerPath]: scopeStackApi.reducer,
  auth: authReducer,
  slug: slugReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
