import React from "react";
import { Row } from "react-bootstrap";

function Markdown() {
  return (
    <dl>
      <Row>
        <dt
          className="col"
          style={{
            textDecoration: "underline",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          Markdown
        </dt>
        <dd
          className="col"
          style={{
            textDecoration: "underline",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          Formatted
        </dd>
      </Row>
      <Row>
        <dt className="col"># Headline</dt>
        <dd className="col">
          <h1 style={{ fontSize: "2em" }}>Headline</h1>
        </dd>
      </Row>
      <Row>
        <dt className="col">## Headline 2</dt>
        <dd className="col">
          <h2>Headline 2</h2>
        </dd>
      </Row>
      <Row>
        <dt className="col">### Headline 3</dt>
        <dd className="col">
          <h3 style={{ fontSize: "1.25em" }}>Headline 3</h3>
        </dd>
      </Row>
      <Row style={{ marginTop: "0.5em" }}>
        <dt className="col">**Bold Text**</dt>
        <dd className="col">
          <strong>Bold</strong>
        </dd>
      </Row>
      <Row style={{ marginTop: "0.5em" }}>
        <dt className="col">_Italic Text_</dt>
        <dd className="col">
          <em>Italic Text</em>
        </dd>
      </Row>
      <Row style={{ marginTop: "0.5em" }}>
        <dt className="col">
          1. Numbered item
          <br />
          2. Numbered item
          <br />
          3. Numbered item
        </dt>
        <dd className="col">
          <ol style={{ paddingLeft: "1em" }}>
            <li>Numbered item</li>
            <li>Numbered item</li>
            <li>Numbered item</li>
          </ol>
        </dd>
      </Row>
      <Row style={{ marginTop: "0.5em" }}>
        <dt className="col">
          <p>
            - Bullet item 1
            <br />
            - Bullet item 2
            <br />- Bullet item 3
          </p>
        </dt>
        <dd className="col">
          <ul style={{ paddingLeft: "1em" }}>
            <li>Bullet item 1</li>
            <li>Bullet item 2</li>
            <li>Bullet item 3</li>
          </ul>
        </dd>
      </Row>
    </dl>
  );
}

export default Markdown;
