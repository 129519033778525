import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useListTagsForAccountQuery } from "@generated";

interface Option {
  label: string;
  value: string;
}

const useListTagsForAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError

  const { data, error, isLoading, refetch, isFetching } =
    useListTagsForAccountQuery({
      slug: accountSlug,
    });

  let tags: Option[] = [];

  if (data?.data) {
    try {
      tags = data.data.map((tag) => {
        if (!tag.id) {
          throw new Error("Missing tag.id");
        }
        if (!tag.attributes?.name) {
          throw new Error("Missing tag.attributes.name");
        }

        return {
          value: tag.attributes.name || "",
          label: tag.attributes.name || "",
        };
      });
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    tags,
    tagsLoading: isLoading,
    error: error ?? customError,
    refetch,
    isFetching,
  };
};
export default useListTagsForAccount;
