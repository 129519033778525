import { useState } from "react";
import { FormCheck } from "react-bootstrap";
import MultiSelectAutocomplete from "../MultiSelectAutoComplete/MultiSelectAutocomplete";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import "./style.css";

interface Option {
  label: string;
  value: string;
}
interface CheckOrMultiSelectProps {
  label: string;
  options: Option[];
  selectedOptions: Option[];
  setSelectedOptions: (selected: Option[]) => void;
  clearAll?: boolean;
}

const CheckOrMultiSelect = ({
  label,
  options,
  selectedOptions,
  setSelectedOptions,
  clearAll,
}: CheckOrMultiSelectProps) => {
  const handleChange = (e, option) => {
    if (options.length > 4) {
      setSelectedOptions(e);
    } else {
      if (e.target.checked) {
        setSelectedOptions([...selectedOptions, option]);
      } else {
        setSelectedOptions(
          selectedOptions.filter((opt) => opt.value !== option.value)
        );
      }
    }
  };

  return (
    <div className="checkOrMultiSelect">
      {options.length > 4 ? (
        <MultiSelectAutocomplete
          options={options}
          onChange={handleChange}
          label={label}
          updateRef={clearAll}
          value={selectedOptions}
        />
      ) : (
        <div className="formGroup">
          <FormFieldLabel label={label} className="" />
          <div className="displayFlex">
            {options.map((option, index) => {
              const selected = selectedOptions.find(
                (o) => o.value === option.value
              );
              return (
                <FormCheck
                  key={index}
                  type="checkbox"
                  id={`checkbox-${index}`}
                  label={option.label}
                  checked={selected ? true : false}
                  onChange={(e) => handleChange(e, option)}
                  className={`checkOption ${selected ? "checked" : ""}`}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckOrMultiSelect;
