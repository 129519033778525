import React, { ChangeEvent } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { OptionType } from "app/javascript/src/pages/TravelAndExpenseV2/types/Expense";

interface Select2V2Props {
  label: string;
  value?: OptionType;
  onChange?: (event: any) => void;
  onInputChange?: (event: any) => void;
  defaultValue?: OptionType;
  options: OptionType[] | any[];
  isDisabled?: boolean;
  placeholder?: string;
  isMulti?: boolean;
}

const Select2V2: React.FC<Select2V2Props> = ({
  defaultValue,
  options,
  label,
  onChange,
  onInputChange,
  value,
  isDisabled = false,
  placeholder,
  isMulti
}) => {
  return (
    <Form.Group className="formGroup select2">
      <Form.Label>{label}</Form.Label>
      <Select
        onChange={onChange}
        onInputChange={onInputChange}
        className="basic-single"
        value={value}
        defaultValue={defaultValue}
        options={options}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isMulti={isMulti}
      />
    </Form.Group>
  );
};
export default Select2V2;
