import { useListQuestionnairesForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";

interface Questionnaire {
  label: string;
  value: string;
}

const useListQuestionnairesForAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListQuestionnairesForAccountQuery({
      slug: accountSlug,
      filter: { active: true },
    });

  let questionnaires: Questionnaire[] = [];

  if (data?.data) {
    try {
      questionnaires = data.data.map((questionnaire) => {
        if (!questionnaire.id) {
          throw new Error("Missing questionnaire.id");
        }
        if (!questionnaire.attributes?.name) {
          throw new Error("Missing questionnaire.attributes.name");
        }

        return {
          value: String(questionnaire.id),
          label: questionnaire.attributes.name || "",
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    questionnaires,
    questionnairesLoading: isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListQuestionnairesForAccount;
