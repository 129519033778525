import {
  ListDocumentTemplatesForAccountApiArg,
  ListDocumentTemplatesForAccountApiResponse,
  V1PagingMeta,
  V1Relationships,
  useListDocumentTemplatesForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { DocumentTemplate } from "../types";

export interface HookArgs {
  page?: ListDocumentTemplatesForAccountApiArg["page"];
}

const useListDocumentTemplates = ({ page }: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } =
    useListDocumentTemplatesForAccountQuery({
      slug: accountSlug,
      page,
      filter: { active: [true, false] },
    });

  let documentTemplates: DocumentTemplate[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    try {
      documentTemplates = data.data.map((documentTemplate) => {
        if (!documentTemplate.id) {
          throw new Error("Missing documentTemplate.id");
        }

        return {
          id: documentTemplate.id,
          active: documentTemplate?.attributes?.["active"],
          name: documentTemplate?.attributes?.name || "",
          format: documentTemplate?.attributes?.format || "word_template",
          filenameFormat: documentTemplate?.attributes?.["filename-format"],
          mergeTemplate: documentTemplate?.attributes?.["merge-template"],
          mergeTemplateFilename: documentTemplate?.attributes?.["merge-template-filename"],
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    documentTemplates,
    documentTemplatesError: error || customError,
    documentTemplatesLoading: isLoading,
    pageMeta,
    refetch,
  };
};

export default useListDocumentTemplates;
