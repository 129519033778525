import React, { useState, useEffect } from "react";
import CardHeader from "@components/CardHeader/CardHeader";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import { Card, Form, Row, Col } from "react-bootstrap";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import { useNavigate } from "react-router-dom";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";

function Version({
  account_slug,
  project_id,
  projectStatus,
  versions,
  versionId,
  account_id,
  setVersionCreated,
  setVersionUpdated,
  currentUser,
  authorizationCode,
}) {
  const [comment, setComment] = useState(null);
  const [userOnVersion, setUserOnVersion] = useState("");
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showUnauthorizedAlert, setShowUnauthorizedAlert] = useState(false);
  const [versionIsEditable, setVersionIsEditable] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();

  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  useEffect(() => {
    if (lastWordInLocation === "edit") {
      for (let i = 0; i < versions.length; i++) {
        if (versions[i].id === id) {
          setComment(versions[i].attributes.comment);
          setUserOnVersion(versions[i].attributes.username);
          setVersionIsEditable(versions[i].attributes.editable);
        }
      }
    }
  }, [versions]);

  const redirectToProjectVersions = () => {
    navigate(`/projects/${project_id}/project_versions`);
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (lastWordInLocation === "edit") {
      const updatedVersion = {
        data: {
          type: "project-versions",
          id: id,
          attributes: {
            comment: comment,
          },
          relationships: {
            project: { data: { type: "projects", id: project_id } },
          },
        },
      };

      API.Patch(
        `${apiHost}/${account_slug}/v1/project-versions/${id}`,
        updatedVersion,
        authorizationCode
      )
        .then((response) => {
          setVersionUpdated(true);
          setUpdatedAlertIsOpen(true);
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "Version"));
          setShowFailAlert(true);
        });
    }

    if (lastWordInLocation === "new") {
      const newVersion = {
        data: {
          type: "project-versions",
          attributes: {
            comment: comment,
          },
          relationships: {
            project: { data: { type: "projects", id: project_id } },
          },
        },
      };

      API.Post(
        `${apiHost}/${account_slug}/v1/project-versions`,
        newVersion,
        authorizationCode
      )
        .then((response) => {
          if (response.status == 201) {
            setVersionCreated(true);
            redirectToProjectVersions();
          }
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "Version"));
          setShowFailAlert(true);
        });
    }
  };

  const versionUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Version updated"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  const handleUnauthorizedAlert = () => {
    if (showUnauthorizedAlert === true) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setShowUnauthorizedAlert(false)}
          text="You are unauthorized to edit this version. Only the person who created the version can edit it."
        />
      );
    }
  };

  return (
    <div>
      {versionUpdatedAlert()}
      {handleFailAlert()}
      {handleUnauthorizedAlert()}
      <Card>
        <CardHeader title="Version" />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Col sm={8}>
              <MultipleLineField
                label="Comment"
                rows={2}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Col>
            <Row>
              <Col xs={6}>
                <SeafoamButton
                  type="submit"
                  text="Submit"
                  className="submitButton"
                />
              </Col>
              <Col xs={6}>
                <BackButton url={`/projects/${project_id}/project_versions`} />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Version;
