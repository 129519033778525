import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import FormTextField from "./FormTextField";
import CountryPicker from "./CountryPicker";
import FormFieldLabel from "./FormFieldLabel/FormFieldLabel";

function AddressForm({ label, siteName, onSiteChange, onAddressChange, onCityChange, onStateChange, onZipChange, addressText, cityText, stateText, zipText, remoteLocation, placeholder, onStreet2Change, street2Text, country, onCountryChange, onRemoteChanged }) {
  return (
    <>
      <Row>
        <Form.Group className="formGroup col-9" controlId="formGridAddressName">
          {siteName == null ? <FormFieldLabel label={label} /> : null}
          {siteName !== null ? <FormTextField onChange={onSiteChange} value={siteName} label={label} placeholder={placeholder} /> : null}
        </Form.Group>
        <Form.Group className="formGroup col-3" style={{marginTop: '2.5em'}}>
          <Form.Check checked={remoteLocation} label={"Remote location?"} onChange={onRemoteChanged} />
        </Form.Group>
      </Row>

      <Form.Group className="formGroup" controlId="formGridAddress1">
        <Form.Control onChange={onAddressChange} value={addressText === null ? "" : addressText} placeholder="221 Baker St" />
      </Form.Group>

      <Form.Group className="formGroup" controlId="formGridAddress2">
        <Form.Control onChange={onStreet2Change} value={street2Text === null ? "" : street2Text} placeholder="Apt B" />
      </Form.Group>

      <Row>
        <Form.Group className="formGroup" as={Col} controlId="formGridCity">
          <Form.Control onChange={onCityChange} value={cityText === null ? "" : cityText} placeholder="Anytown" />
        </Form.Group>

        <Form.Group className="formGroup" as={Col} controlId="formGridState">
          <Form.Control onChange={onStateChange} value={stateText === null ? "" : stateText} placeholder="NY"/>
        </Form.Group>

        <Form.Group className="formGroup" as={Col} controlId="formGridZip">
          <Form.Control onChange={onZipChange} value={zipText === null ? "" : zipText} placeholder="01234"/>
        </Form.Group>

        <Form.Group className="formGroup" as={Col} controlId="formGridState">
            <CountryPicker country={country} onCountryChange={onCountryChange} />
        </Form.Group>
      </Row>
    </>
  );
}

export default AddressForm;
