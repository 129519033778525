export const getSurveyTags = (tags: string[]) => {
  if (!tags) return <div></div>;
  return (
    <div className="tagsContainer">
      {tags.map((tag: string, i: number) => {
        return (
          <div className="surveyTag" key={i}>
            {tag}
          </div>
        );
      })}
    </div>
  );
};
