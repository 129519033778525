import { useWhoAmIQuery } from "@generated";

const usePrivilegeCheck = () => {
  const { data: user, isLoading, isError } = useWhoAmIQuery();

  const hasPrivilege = (
    requiredPrivilege: string,
    requiredAccessLevels: string[]
  ): boolean => {
    const privileges = user?.data?.attributes?.privileges ?? [];

    return privileges.some((privilege) => {
      if (!privilege["access-level"]) return false;

      return (
        privilege.privilege === requiredPrivilege &&
        requiredAccessLevels.includes(privilege["access-level"])
      );
    });
  };

  return { hasPrivilege, isLoading, isError, user };
};

export default usePrivilegeCheck;
