import { Route, Routes, Outlet } from "react-router-dom";
import Manage from "./Manage";
import Show from "./Show";

const ConnectedApps = (props): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<Show {...props} />} />
      </Route>
      <Route path="/manage/:id" element={<Outlet />}>
        <Route index element={<Manage {...props} />} />
      </Route>
    </Routes>
  );
};

export default ConnectedApps;
