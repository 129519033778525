import React from "react"
import PropTypes from "prop-types"
import Widget from './Widget'

class WidgetWorkflow extends Widget {
  componentDidMount() {
    this.setState({filter: ['building', 'approving']})
    fetch(this.props.dataURL)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json.projects, loading: false }))
  }

  setFilter = (filter) => (() => this.setState({ filter }))

  renderItem (item, index) {
    let className = `item-status ${item.status} fa fa-circle`
    let href = `${this.props.moreURL}/${item.id}/edit`
    var project_name = item.project_name.substring(0, 30)
    if (item.project_name.length > 30) {
      project_name = project_name.concat('...')
    }
    return(
      <li key={index} className="recent-item">
        <span className={className}/>
        <div className="item-title">
          <a href={href}>{project_name}</a>
        </div>
        <div className="item-description">{item.client.name} / {item.client_manager && item.client_manager.name || '-'}</div>
      </li>
    )
  }

  renderFilter () {
    return (
      <div className="items-filter">
        {
          [
            ['yellow', ['building', 'approving']],
            ['red', ['lost']],
            ['green', ['approved', 'won']]
          ].map((item, index) => {
            return(
              <span key={index} className={['items-filter-switch', item[0]].join(' ')} onClick={this.setFilter(item[1])}>
                <span className={['fa', this.state.filter && this.state.filter.every((v, i) => v === item[1][i]) ? 'fa-circle' : 'fa-circle-thin'].join(' ')}/>
              </span>
            )
          })
        }
      </div>
    )
  }

  renderBody () {
    let filtered = this.state.data.filter((item) => this.state.filter.includes(item.status))
    return(
      <div className="widget-body">
        {this.renderFilter()}
        <ol className="recent-projects">
          {filtered.slice(0, 4).map(this.renderItem, this)}
        </ol>
      </div>
    )
  }

  renderFooter() {
    return(
      <div className="widget-footer text-right">
        <a href={this.props.moreURL || '#'} className="link-more">View All Projects</a>
      </div>
    )
  }
}

export default WidgetWorkflow
