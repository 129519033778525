import React, { useState, useEffect } from "react";
import CardHeader from "@components/CardHeader/CardHeader";
import FormTextField from "@components/Forms/FormTextField";
import { Card, Form, Row, Col } from "react-bootstrap";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import API from "@API";
import { useNavigate } from "react-router-dom";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";

function Outcome({
  account_slug,
  project_id,
  projectStatus,
  outcomes,
  outcomeId,
  account_id,
  setOutcomeCreated,
  setOutcomeUpdated,
  authorizationCode,
}) {
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [outcome, setOutcome] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();

  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  useEffect(() => {
    if (lastWordInLocation === "edit") {
      for (let i = 0; i < outcomes.length; i++) {
        if (outcomes[i].id === id) {
          setOutcome(outcomes[i].attributes.outcome);
        }
      }
    }
  }, [outcomes]);

  const redirectToProjectOutcomes = () => {
    navigate(`/projects/${project_id}/customer_successes`);
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (lastWordInLocation === "new") {
      const newOutcome = {
        data: {
          type: "customer-successes",
          attributes: {
            outcome: outcome,
          },
          relationships: {
            project: { data: { type: "projects", id: project_id } },
          },
        },
      };

      API.Post(
        `${apiHost}/${account_slug}/v1/customer-successes`,
        newOutcome,
        authorizationCode
      )
        .then((response) => {
          if (response.status == 201) {
            setOutcomeCreated(true);
            redirectToProjectOutcomes();
          }
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "outcome"));
          setShowFailAlert(true);
        });
    }

    if (lastWordInLocation === "edit") {
      const updatedOutcome = {
        data: {
          type: "customer-successes",
          id: id,
          attributes: {
            outcome: outcome,
          },
          relationships: {
            project: { data: { type: "projects", id: project_id } },
          },
        },
      };
      API.Patch(
        `${apiHost}/${account_slug}/v1/customer-successes/${id}`,
        updatedOutcome,
        authorizationCode
      )
        .then((response) => {
          if (response.status == 200) {
            setOutcomeUpdated(true);
            setUpdatedAlertIsOpen(true);
            setTimeout(() => {
              setUpdatedAlertIsOpen(false);
            }, 3000);
          }
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "outcome"));
          setShowFailAlert(true);
        });
    }
  };

  const handleUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Updated customer outcome"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleUpdatedAlert()}
      {handleFailAlert()}
      <Card>
        <CardHeader title={"Outcomes And KPIs"} />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <FormTextField
              label="Outcome"
              value={outcome}
              onChange={(e) => setOutcome(e.target.value)}
            />
            <Row>
              <Col xs={6}>
                <SubmitButton />
              </Col>
              <Col xs={6}>
                <BackButton
                  url={`/projects/${project_id}/customer_successes`}
                />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Outcome;
