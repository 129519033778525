import { useListQuestionnairesForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";

interface Questionnaire {
  id: number;
  name: string;
}
const useListQuestionnairesForAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  let customError: string | null = null; // Declare customError
  const {
    data,
    error,
    isLoading: questionnairesLoading,
    refetch,
  } = useListQuestionnairesForAccountQuery({ slug: accountSlug });
  let questionnaires: Questionnaire[] = [];
  if (data?.data) {
    try {
      questionnaires = data.data.map((questionnaire) => {
        if (!questionnaire.id) {
          throw new Error("Missing questionnaire.id");
        }
        if (!questionnaire?.attributes?.name) {
          throw new Error("Missing questionnaire.attributes.name");
        }
        return {
          id: questionnaire.id,
          name: questionnaire.attributes.name,
        };
      });
    } catch (e) {
      customError = "An unknown error occurred";
    }
  }
  return {
    questionnaires,
    questionnairesLoading,
    error: error ?? customError,
  };
};
export default useListQuestionnairesForAccount;
