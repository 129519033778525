import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import ProjectApprovals from "./ProjectApprovals/ProjectApprovals";
import EditApproval from "./EditApproval/EditApproval";
import NewApproval from "./NewApproval/NewApproval";

function ApprovalsMspa({ account_slug, project_id, account_id }) {
  // Set the State
  const [projectApprovals, setProjectApprovals] = useState([]);
  const [approvalSteps, setApprovalSteps] = useState([]);
  const [projectStatus, setProjectStatus] = useState("");
  const [approvalId, setApprovalId] = useState("");
  const [versions, setVersions] = useState([]);
  const [approvalStepId, setApprovalStepId] = useState("");
  const [users, setUsers] = useState([]);

  // Env
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode !== "") {
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setProjectStatus(data.attributes.status);
      });

      // Get Users
      API.Get(`${apiHost}/${account_slug}/v1/users`, authorizationCode).then(
        (response) => {
          let data = response.data.data;
          if (data !== null) {
            setUsers(data);
          }
        }
      );

      // Get Versions with Approval Steps included
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-versions?include=approval-steps,approval-steps.project-approvals,approval-steps.project-approvals.user`,
        authorizationCode
      ).then((res) => {
        let includedData = res.data.included;
        let approvals = includedData.filter(
          (item) => item.type === "approval-steps"
        );
        setApprovalSteps(approvals.reverse());
        setVersions(
          res.data.data.filter(
            (version) => version.relationships["approval-steps"].data.length > 0
          )
        );
        setProjectApprovals(
          includedData.filter((item) => item.type === "project-approvals")
        );
        setIsLoading(false);
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/project_approvals"}
            element={
              <ProjectApprovals
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                versions={versions}
                approvalSteps={approvalSteps}
                projectApprovals={projectApprovals}
                setApprovalId={(id) => setApprovalId(id)}
                setApprovalStepId={(id) => setApprovalStepId(id)}
                users={users}
                isLoading={isLoading}
              />
            }
          />
          <Route
            exact
            path={"/projects/:project_id/project_approvals/:approvalId/edit"}
            element={
              <EditApproval
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                versions={versions}
                approvalSteps={approvalSteps}
                projectApprovals={projectApprovals}
                users={users}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            exact
            path={"/projects/:project_id/project_approvals/new"}
            element={
              <NewApproval
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                versions={versions}
                approvalSteps={approvalSteps}
                projectApprovals={projectApprovals}
                approvalStepId={approvalStepId}
                users={users}
                authorizationCode={authorizationCode}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default ApprovalsMspa;
