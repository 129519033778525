import SlideOutMenuV2 from "@components/SlideOutMenuV2";
import { useState, useEffect } from "react";
import { MultiSelectAutocomplete } from "@components/FormsV2/MultiSelectAutoComplete";
import { DateField } from "@components/FormsV2";
import "./style.css";
import CheckOrMultiSelect from "@components/FormsV2/CheckOrMultiSelect";
import { UserSelectionsState, Option } from "../../types";

interface ServiceFilterProps {
  serviceType: "professional_services" | "managed_services";
  userSelections: UserSelectionsState;
  setUserSelections: (selection: UserSelectionsState) => void;
  onSave: () => void;
  slideoutOpen: boolean;
  setSlideoutOpen: (isOpen: boolean) => void;
  filterCount: number;
  clearAll: boolean;
  setClearAll: (clearAll: boolean) => void;
  options: {
    blueprintOptions: Option[];
    lobOptions: Option[];
    categoryOptions: Option[];
    phaseOptions: Option[];
    frequencyOptions: Option[];
    questionnaireOptions: Option[];
  };
}

const ServiceFilters = ({
  serviceType,
  userSelections,
  setUserSelections,
  onSave,
  slideoutOpen,
  setSlideoutOpen,
  filterCount,
  clearAll,
  setClearAll,
  options,
}: ServiceFilterProps) => {
  const [previousSelections, setPreviousSelections] =
    useState<UserSelectionsState>({
      lobs: [],
      categories: [],
      frequency: [],
      phases: [],
      blueprints: [],
      questionnaires: [],
      startDateCreated: "",
      endDateCreated: "",
      startDateUpdated: "",
      endDateUpdated: "",
    });

  useEffect(() => {
    if (slideoutOpen) {
      setPreviousSelections(userSelections);
    }
  }, [slideoutOpen]);

  const handleCancel = () => {
    setUserSelections(previousSelections);
    setSlideoutOpen(false);
  };
  const handleChange = (type) => (selectedOptions) => {
    //@ts-ignore
    setUserSelections((prev) => ({
      ...prev,
      [type]: selectedOptions || [],
    }));
  };
  const handleDateChange = (field) => (event) => {
    setUserSelections({ ...userSelections, [field]: event.target.value });
  };
  return (
    <SlideOutMenuV2
      title="Filter By"
      isOpen={slideoutOpen}
      onClose={handleCancel}
      onSave={onSave}
      onClearAll={() => {
        setClearAll(true);
        setSlideoutOpen(false);
      }}
      filterCount={filterCount}
    >
      <div className="serviceFilters">
        <section>
          <h3>General</h3>
          <MultiSelectAutocomplete
            label="Line of Business"
            id="lob"
            options={options.lobOptions}
            value={userSelections.lobs}
            onChange={handleChange("lobs")}
          />
          <MultiSelectAutocomplete
            label="Service Category"
            id="category"
            options={options.categoryOptions}
            value={userSelections.categories}
            onChange={handleChange("categories")}
          />
          <CheckOrMultiSelect
            label="Frequency"
            options={options.frequencyOptions}
            selectedOptions={userSelections.frequency}
            setSelectedOptions={(e) =>
              setUserSelections({
                ...userSelections,
                frequency: e as {
                  label: string;
                  value: "one_time" | "monthly" | "quarterly" | "yearly";
                }[],
              })
            }
            clearAll={clearAll}
          />
          {serviceType === "professional_services" && (
            <CheckOrMultiSelect
              label="Phase"
              options={options.phaseOptions}
              selectedOptions={userSelections.phases}
              setSelectedOptions={(e) =>
                setUserSelections({ ...userSelections, phases: e })
              }
              clearAll={clearAll || !userSelections.phases.length}
            />
          )}
        </section>
        <section>
          <h3>Used In</h3>
          <MultiSelectAutocomplete
            label="Blueprints"
            id="blueprints"
            options={options.blueprintOptions}
            value={userSelections.blueprints}
            onChange={handleChange("blueprints")}
          />
          <MultiSelectAutocomplete
            label="Surveys"
            id="surveys"
            options={options.questionnaireOptions}
            value={userSelections.questionnaires}
            onChange={handleChange("questionnaires")}
          />
        </section>
        <section className="createdOn">
          <h3>Created On</h3>
          <div className="dateContainer">
            <div className="dateField">
              <DateField
                label="Start Date"
                value={userSelections.startDateCreated}
                placeholder={"Select start date"}
                onChange={handleDateChange("startDateCreated")}
              />
            </div>
            To
            <div className="dateField">
              <DateField
                label="End Date"
                value={userSelections.endDateCreated}
                placeholder={"Select end date"}
                onChange={handleDateChange("endDateCreated")}
              />
            </div>
          </div>
        </section>
        <section className="lastUpdated">
          <h3>Last Updated</h3>
          <div className="dateContainer">
            <div className="dateField">
              <DateField
                label="Start Date"
                value={userSelections.startDateUpdated}
                placeholder={"Select start date"}
                onChange={handleDateChange("startDateUpdated")}
              />
            </div>
            To
            <div className="dateField">
              <DateField
                label="End Date"
                value={userSelections.endDateUpdated}
                placeholder={"Select end date"}
                onChange={handleDateChange("endDateUpdated")}
              />
            </div>
          </div>
        </section>
      </div>
    </SlideOutMenuV2>
  );
};

export default ServiceFilters;
