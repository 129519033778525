import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { SerializedStyles } from "@emotion/react"; // Import css and SerializedStyles from Emotion
import { useStyles } from "./styles";

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  variant?: "primary" | "secondary" | "outline";
  to?: string;
  customCss?: SerializedStyles[];
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({
  children,
  onClick,
  variant = "primary",
  to,
  disabled,
}) => {
  const navigate = useNavigate();
  const styles = useStyles();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (to) {
      navigate(to);
      window.location.reload();
    }
  };

  return (
    <div>
      <button
        onClick={handleClick}
        className={styles[variant]}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
