import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import ControlledDropDown from "./ControlledDropDown";
import AutoCompleteText from "./AutoCompleteText/AutoCompleteText";
import FormTextField from "./FormTextField";
import FormEmailField from "./FormEmailField";
import FormPhoneField from "./FormPhoneField";
import API from "@API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";

const ContactFormSubmit = ({
  onDataChange,
  id,
  name,
  type,
  title,
  email,
  phone,
  submitted,
  changed,
  authorizationCode,
  account_slug,
  project_id,
}) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [contactSubmitted, setContactSubmitted] = useState(
    submitted ? submitted : false
  );
  const [contactChanged, setContactChanged] = useState(
    changed ? changed : false
  );
  const [contactId, setContactId] = useState(
    id ? id : `new-${Math.floor(Math.random() * 100)}`
  );
  const [contactName, setContactName] = useState(name ? name : "");
  const [contactType, setContactType] = useState(type ? type : "");
  const [contactTitle, setContactTitle] = useState(title ? title : "");
  const [contactEmail, setContactEmail] = useState(email ? email : "");
  const [contactPhone, setContactPhone] = useState(phone ? phone : "");
  const [contactList, setContactList] = useState([]);

  const [newContactTypeId, setNewContactTypeId] = useState("");
  const [newContactId, setNewContactId] = useState(null);
  var chosenName = "";

  const [userTypedName, setUserTypedName] = useState(false);
  const [contactTypes, setContactTypes] = useState([]);
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get Contact Types
      API.Get(
        `${apiHost}/${account_slug}/v1/contact-types`,
        authorizationCode
      ).then((response) => {
        let contactTypes = response.data.data;
        if (contactTypes.length !== 0) {
          setContactTypes(contactTypes);
        }
      });

      // Get Project Info & Set Project Status
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=client`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        if (data.relationships.client !== null) {
          let clientId = data.relationships.client.data.id;
          setClientId(clientId);
        }
      });
    }
    const contactData = {
      contactChanged: contactChanged,
      contactSubmitted: contactSubmitted,
      id: contactId,
      name: contactName,
      type: contactType,
      title: contactTitle,
      email: contactEmail,
      phone: contactPhone,
    };
    updateContact(contactData);
  }, [
    contactId,
    contactChanged,
    contactSubmitted,
    contactName,
    contactType,
    contactTitle,
    contactEmail,
    contactPhone,
  ]);

  const contactTypeOptions = contactTypes.map((contactType) => {
    return (
      <option accessKey={contactType.id} key={contactType.id}>
        {contactType.attributes.name}
      </option>
    );
  });

  const getContactList = (contactName) => {
    if (contactType === null) {
      return [];
    }
    API.Get(
      `${apiHost}/${account_slug}/v1/clients/${clientId}/contacts?filter[name]=${encodeURIComponent(
        contactName
      )}&page[size]=500`,
      authorizationCode
    ).then((res) => {
      let clientContacts = res.data.data;
      setContactList(clientContacts);
    });
  };

  const updateContact = (contactData) => {
    if (userTypedName == true) {
      onDataChange(contactData);
    }
  };

  const handleTypeChange = (event) => {
    setContactType(event.target.value);
    let selected = event.target.options.selectedIndex;
    setNewContactTypeId(event.target.options[selected].accessKey);
    setUserTypedName(true);
  };

  const handleNameChange = (value) => {
    setContactName(value);
    setUserTypedName(true);
  };

  const handleTitleChange = (event) => {
    setContactTitle(event.target.value);
    setUserTypedName(true);
  };

  const handleEmailChange = (event) => {
    setContactEmail(event.target.value);
    setUserTypedName(true);
  };

  const handlePhoneChange = (event) => {
    setContactPhone(event.target.value);
    setUserTypedName(true);
  };

  return (
    <Row style={{ width: "100%" }}>
      <Col>
        <ControlledDropDown
          label="Contact Type"
          value={
            contactType === "Select Contact Type"
              ? "Customer Contact"
              : contactType
          }
          options={contactTypeOptions.filter(
            (option) => option.key.includes("internal") === false
          )}
          onChange={handleTypeChange}
        />
      </Col>
      <Col sm={3}>
        <AutoCompleteText
          label="Name *"
          placeholder="Contact Name"
          value={contactName}
          required={true}
          suggestionList={contactList.map((contact) => contact.attributes.name)}
          monitorValue={(value) => {
            chosenName = value;
            getContactList(chosenName);
            setContactName(chosenName);
            handleNameChange(chosenName);

            for (let i = 0; i < contactList.length; i++) {
              if (
                contactList[i].attributes.name === chosenName &&
                contactTitle === "" &&
                contactEmail === "" &&
                contactPhone === ""
              ) {
                setNewContactId(contactList[i].id);
                setContactEmail(contactList[i].attributes.email);
                setContactTitle(contactList[i].attributes.title);
                setContactPhone(contactList[i].attributes.phone);
              }
              if (
                contactList[i].attributes.name !== chosenName &&
                contactSubmitted === false
              ) {
                setNewContactId("");
                setContactEmail("");
                setContactTitle("");
                setContactPhone("");
              }
            }
          }}
          setTextChanged={(bool) => {
            setUserTypedName(bool);
          }}
        />
      </Col>
      <Col sm={1}>
        <FormTextField
          id="contactTitle"
          onChange={handleTitleChange}
          value={contactTitle}
          placeholder="CTO, etc."
          label="Title"
        />
      </Col>
      <Col sm={3}>
        <FormEmailField
          label="Email"
          placeholder="Contact email"
          value={contactEmail}
          onChange={handleEmailChange}
        />
      </Col>
      <Col xs={3}>
        <FormPhoneField
          label="Phone"
          placeholder="2125553456"
          value={contactPhone}
          onChange={handlePhoneChange}
        />
      </Col>
    </Row>
  );
};

export default ContactFormSubmit;
