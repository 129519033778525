import { useListClientsForAccountQuery } from "@generated";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useEffect, useState } from "react";
import { Client } from "../types";
import { generated } from "@generated";
import { AppDispatch } from "@store/*";

const useGetAllClients = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const dispatch = useDispatch<AppDispatch>();
  const { data, isLoading, error } = useListClientsForAccountQuery({
    slug: accountSlug,
    page: {},
  });
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(isLoading);

  useEffect(() => {
    if (!isLoading && data && data.data && data.meta && !error) {
      setClients(
        data.data.map((client) => ({
          id: client.id?.toString() || "",
          name: client.attributes?.name || "",
        }))
      );
      const pageCount = data.meta["page-count"] || 1;

      if (pageCount > 1) {
        setLoading(true);
        const fetchPages = async () => {
          try {
            for (let page = 2; page <= pageCount; page++) {
              // Trigger additional fetches for each page
              const resultAction = await dispatch(
                generated.endpoints.listClientsForAccount.initiate({
                  slug: accountSlug,
                  page: {
                    number: page,
                  },
                })
              ).unwrap(); // Assuming this is where the 'unwrap()' method is now chained

              // Check if resultAction.data exists and is an array before mapping
              if (resultAction.data && Array.isArray(resultAction.data)) {
                setClients((prevExecutives) => [
                  ...prevExecutives,
                  ...resultAction.data!.map((client) => ({
                    id: client.id?.toString() || "",
                    name: client.attributes?.name || "",
                  })),
                ]);
              }
            }
          } catch (err) {
            console.error("Failed to fetch additional pages:", err);
          } finally {
            setLoading(false);
          }
        };

        fetchPages();
      }
    }
  }, [data, isLoading, error, accountSlug, dispatch]);

  return { clients, loading, error };
};

export default useGetAllClients;
