import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { faPlus, faMinus } from "@fortawesome/pro-solid-svg-icons";
import { snakeToPascalCase, splitPascalCase } from "@utils/helperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ServicesReview = ({ data }) => {
  const [expandedServiceIndexes, setExpandedServiceIndexes] = useState([]);

  const handleSubServicesClick = (index) => {
    if (expandedServiceIndexes.includes(index)) {
      setExpandedServiceIndexes(
        expandedServiceIndexes.filter((i) => i !== index)
      );
    } else {
      setExpandedServiceIndexes([...expandedServiceIndexes, index]);
    }
  };

  const SubServicesList = ({ subServices }) => (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: "-20px" }}
    >
      {subServices.map((subService) => (
        <span key={subService.id}> {subService.attributes.name}</span>
      ))}
    </div>
  );

  return (
    <div className="reviewContainer">
      {data && (
        <>
          <h3 className="reviewHeader" style={{ paddingBottom: "10px" }}>
            Services Added
          </h3>
          <Row
            style={{
              margin: "0px",
              paddingBottom: "5px",
              borderBottom: "1px solid #dee2e6",
            }}
          >
            <Col sm={3}>
              <strong>Service Category</strong>
            </Col>
            <Col sm={5}>
              <strong>Service</strong>
            </Col>
            <Col sm={4}>
              <strong>Subservices</strong>
            </Col>
          </Row>
          {data.map((service, index) => (
            <React.Fragment key={index}>
              <Row
                key={index}
                style={{
                  padding: "10px 0px",
                  margin: "0px",
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                }}
              >
                <Col sm={3}>
                  <span>
                    {splitPascalCase(
                      snakeToPascalCase(service.attributes["service-type"])
                    )}
                  </span>
                </Col>
                <Col sm={5}>
                  <span>{service.attributes.name}</span>
                </Col>
                <Col sm={4}>
                  {service.subservices && (
                    <>
                      <FontAwesomeIcon
                        focusable={true}
                        icon={
                          expandedServiceIndexes.includes(index)
                            ? faMinus
                            : faPlus
                        }
                        onClick={() => handleSubServicesClick(index)}
                        style={{
                          color: "#418172",
                          paddingRight: "10px",
                          marginLeft: "-20px",
                          cursor: "pointer",
                        }}
                      />
                      {expandedServiceIndexes.includes(index) ? (
                        <SubServicesList subServices={service.subservices} />
                      ) : (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSubServicesClick(index)}
                        >
                          {`${service.subservices.length} Subservices`}
                        </span>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

export default ServicesReview;
