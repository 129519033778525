import React, { useState, useRef } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import GovernanceForm from "../GovernanceForm/GovernanceForm";

function Edit({ project_id, account, permission, project, projectPhases }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [governance, setGovernance] = useState(location.state.governance || null);
  const submitRef = useRef<any>();

  return (
    <div className="newGovernancePage">
      <Card>
        <Card.Header
          style={{
            backgroundColor: "#fff",
            color: "#1C2655",
            padding: "0px 16px",
          }}
        >
          <Row style={{ padding: "20px 0 0 20px", alignItems: "center" }}>
            <Col sm={1}>
              <Button
                className="btnSeawhite"
                onClick={(e) =>
                  navigate(`/projects/${project_id}/project_governances`, {
                    state: null,
                  })
                }
              >
                Cancel
              </Button>
            </Col>
            <Col className="text24" sm={9}>
              Edit {governance.description}
            </Col>
            {permission !== "view" &&
              permission !== "create" &&
              project?.data?.attributes?.status === "building" && (
                <Col>
                  <Button
                    style={{ marginLeft: "60px" }}
                    className="squareGreenButton"
                    onClick={() =>
                      submitRef && submitRef.current
                        ? submitRef.current.saveGovernance(false)
                        : null
                    }
                  >
                    Save
                  </Button>
                </Col>
              )}
          </Row>
        </Card.Header>
        <hr style={{ marginLeft: "30px", marginRight: "30px" }} />
        <Card.Body>
          <GovernanceForm
            governanceProp={governance}
            project_id={project_id}
            account={account}
            ref={submitRef}
            permission={permission}
            project={project}
            projectPhases={projectPhases}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default Edit;
