import React from "react"
import PropTypes from "prop-types"
import 'chart.js/auto'
import { Doughnut } from 'react-chartjs-2'
import Widget from './Widget'
import { COLORS } from './config'

class WidgetTopModules extends Widget {
  componentDidMount() {
    fetch(this.props.dataURL)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json.sections, loading: false }))
  }

  renderChart () {
    let { data } = this.state
    if (!data || data.length == 0) return;
    let sorted = Object.entries(data).sort((a, b) => b[1] - a[1])
    let datasets = sorted.slice(0, 4)
    const total = sorted.reduce((sum, item) => sum + item[1], 0)
    let chartData = {
      labels: datasets.map( item => `${item[0]} - ${new Intl.NumberFormat(dashboardLocale, { maximumFractionDigits: 0 } ).format((1.0 * item[1])/total * 100)}%`),
      datasets: [
        {
          label: '1',
          data: datasets.map( item => item[1]),
          backgroundColor: COLORS,
        }
      ],
    }

    return(
      <Doughnut data={chartData} height={200} options={{cutoutPercentage:75}}/>
    )
  }

  renderBody () {
    return(
      <React.Fragment>
      {this.renderChart()}
      </React.Fragment>
    )
  }

  renderFooter () {
    return null
  }
}

export default WidgetTopModules
