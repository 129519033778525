import React, { useState, useEffect } from "react";
import CardHeader from "@components/CardHeader/CardHeader";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import API from "@API";
import { useNavigate } from "react-router-dom";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import {
  capitalizeFirstLetter,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import FormNumberField from "@components/Forms/FormNumberField";
import NavTabs from "@components/NavTabs/NavTabs";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";

function PartnerRequest({
  account_slug,
  project_id,
  projectStatus,
  partnerRequests,
  partnerRequestId,
  account_id,
  setPartnerRequestCreated,
  setPartnerRequestUpdated,
  partners,
  linesOfBusiness,
  currencies,
  requestStatus,
  currencyUnit,
  authorizationCode,
}) {
  // Alerts
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Env
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const location = window.location.href;
  const reviseLocation = `${appHost}/projects/${project_id}/third_party_requests/${id}/revision`;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  // Form Field States
  const [formChanged, setFormChanged] = useState(false);
  const [selectedLob, setSelectedLob] = useState("");
  const [lobId, setLobId] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [partnerId, setPartnerId] = useState(null);
  const [description, setDescription] = useState("");

  const [cost, setCost] = useState("0.0");
  const [markup, setMarkup] = useState("0.0");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [currencyId, setCurrencyId] = useState(null);
  const [status, setStatus] = useState("");
  const [successMessage, setSuccessMessage] = useState(
    "Third Party Request updated"
  );
  const [deriveFromServices, setDeriveFromServices] = useState(false);
  const [terms, setTerms] = useState("0.0");
  const [recurringCost, setRecurringCost] = useState("0.0");
  const [frequency, setFrequency] = useState("");

  // Buttons
  const [statusButtonsClicked, setStatusButtonsClicked] = useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [revisionButtonClicked, setRevisionButtonClicked] = useState(false);

  useEffect(() => {
    if (lastWordInLocation === "edit") {
      if (
        document.referrer === reviseLocation &&
        performance.navigation.type !== 1
      ) {
        setSuccessMessage("Third Party Request has been sent for revision");
        setUpdatedAlertIsOpen(true);
      }

      const getFrequency = (frequency) => {
        if (frequency !== null && frequency.includes("_")) {
          return "None";
        }
        if (frequency !== null) {
          return capitalizeFirstLetter(frequency);
        }
      };

      for (let i = 0; i < partnerRequests.length; i++) {
        if (partnerRequests[i].id == id) {
          let partnerRequest = partnerRequests[i];
          let attr = partnerRequest.attributes;
          setDescription(attr.description);
          setCost(attr.cost);
          setStatus(attr.status);
          setRecurringCost(attr["recurring-cost"]);
          setFrequency(getFrequency(attr["billing-frequency"]));
          setMarkup(attr.markup);
          setDeriveFromServices(attr["service-pricing"]);
          setTerms(attr["mrr-terms"]);

          // Match Partner relationship
          for (let j = 0; j < partners.length; j++) {
            let partnerData = partnerRequest.relationships.subcontractor.data;
            if (partnerData && partnerData.id == partners[j].id) {
              let partnerAttributes = partners[j].attributes;
              setSelectedPartner(partnerAttributes.name);
              setPartnerId(partnerAttributes["subcontractor-id"]);
            }
          }

          // Match Lob relationship
          for (let k = 0; k < linesOfBusiness.length; k++) {
            let lobData = partnerRequest.relationships.lob.data;
            if (lobData && lobData.id == linesOfBusiness[k].id) {
              let lobAttributes = linesOfBusiness[k].attributes;
              setSelectedLob(lobAttributes.name);
              setLobId(linesOfBusiness[k].id);
            }
          }

          // Match Currency relationship
          for (let l = 0; l < currencies.length; l++) {
            let currencyData = partnerRequest.relationships.currency.data;
            if (currencyData && currencyData.id == currencies[l].id) {
              let currencyAttributes = currencies[l].attributes;
              setSelectedCurrency(currencyAttributes.name);
              setCurrencyId(currencies[l].id);
            }
          }
        }
      }
    }
  }, [
    authorizationCode,
    partnerRequests,
    partners,
    linesOfBusiness,
    requestStatus,
    currencies,
  ]);

  const redirectToProjectPartnerRequests = () => {
    navigate(`/projects/${project_id}/third_party_requests`);
    window.location.reload();
  };

  const redirectToRevisePartnerRequest = () => {
    navigate(`/projects/${project_id}/third_party_requests/${id}/revision`);
  };

  const validateForm = () => {
    let isValid = true;
    if (
      description == "" ||
      lobId == null ||
      partnerId == null ||
      selectedCurrency == ""
    ) {
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setHasUnsavedChanges(false);

    const attributes = {
      description: description,
      markup: markup,
    };

    let relationships = {
      account: { data: { type: "accounts", id: account_id } },
      project: { data: { type: "projects", id: project_id } },
      subcontractor: { data: { type: "subcontractors", id: partnerId } },
      lob: { data: { type: "lobs", id: lobId } },
      currency: { data: { type: "currencies", id: currencyId } },
    };

    if (lastWordInLocation === "edit") {
      const updatedPartnerRequestData = {
        data: {
          type: "partner-requests",
          id: id,
          attributes: attributes,
          relationships: relationships,
        },
      };

      if (formChanged == false && revisionButtonClicked) {
        redirectToRevisePartnerRequest();
      }

      if (
        submitButtonClicked ||
        (formChanged === true && statusButtonsClicked)
      ) {
        API.Patch(
          `${apiHost}/${account_slug}/v1/partner-requests/${id}`,
          updatedPartnerRequestData,
          authorizationCode
        )
          .then((response) => {
            if (response.status == 200 && submitButtonClicked) {
              setPartnerRequestUpdated(true);
              setUpdatedAlertIsOpen(true);
              setTimeout(() => {
                setUpdatedAlertIsOpen(false);
              }, 3000);
            }
            if (revisionButtonClicked && response.status == 200) {
              redirectToRevisePartnerRequest();
            }
          })
          .catch((err) => {
            setErrorMessages(
              formatUnprocessibleResponse(err, "partner request details")
            );
            setShowFailAlert(true);
          });
      }
    }

    if (lastWordInLocation === "new") {
      const newPartnerRequest = {
        data: {
          type: "partner-requests",
          attributes: attributes,
          relationships: relationships,
        },
      };

      API.Post(
        `${apiHost}/${account_slug}/v1/partner-requests`,
        newPartnerRequest,
        authorizationCode
      )
        .then((response) => {
          if (response.status == 201) {
            setPartnerRequestCreated(true);
            redirectToProjectPartnerRequests();
          }
        })
        .catch((err) => {
          setErrorMessages(
            formatUnprocessibleResponse(err, "partner request details")
          );
          setShowFailAlert(true);
        });
    }
  };

  const partnerRequestUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text={successMessage}
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  const getActionButtons = () => {
    if (status == "complete" || lastWordInLocation === "new") {
      return (
        <SeafoamButton
          id="Submit"
          type="submit"
          text="Submit"
          onClick={() => {
            setSubmitButtonClicked(true);
          }}
        />
      );
    }
    if (status == "offered") {
      return (
        <div className="submitButtons">
          <SeafoamButton
            style={{
              marginRight: "1em",
            }}
            text="Accept"
            type="submit"
            id="Accept"
            onClick={() => {
              let formIsValid = validateForm();
              setStatusButtonsClicked(true);
              if (formIsValid) {
                API.Put(
                  `${apiHost}/${account_slug}/v1/partner-requests/${id}/complete`,
                  null,
                  authorizationCode
                )
                  .then((response) => {
                    setStatus(response.data.data.attributes.status);
                    setSuccessMessage("Third Party Request accepted");
                    setUpdatedAlertIsOpen(true);
                    setTimeout(() => {
                      setUpdatedAlertIsOpen(false);
                    }, 3000);
                  })
                  .catch((err) => {
                    setErrorMessages(
                      formatUnprocessibleResponse(
                        err,
                        "partner request details"
                      )
                    );
                    setShowFailAlert(true);
                  });
              }
            }}
          />
          <Button
            style={{
              marginRight: "1em",
            }}
            type="submit"
            variant="outline-secondary"
            id="Revise"
            onClick={() => {
              setStatusButtonsClicked(true);
              setRevisionButtonClicked(true);
            }}
          >
            Revise
          </Button>
          <Button
            type="submit"
            variant="outline-danger"
            id="Decline"
            onClick={() => {
              let formIsValid = validateForm();
              setStatusButtonsClicked(true);
              if (formIsValid) {
                API.Put(
                  `${apiHost}/${account_slug}/v1/partner-requests/${id}/decline`,
                  null,
                  authorizationCode
                )
                  .then((response) => {
                    setStatus(response.data.data.attributes.status);
                    setSuccessMessage("Third Party Request declined");
                    setUpdatedAlertIsOpen(true);
                    setTimeout(() => {
                      setUpdatedAlertIsOpen(false);
                    }, 3000);
                  })
                  .catch((err) => {
                    setErrorMessages(
                      formatUnprocessibleResponse(
                        err,
                        "partner request details"
                      )
                    );
                    setShowFailAlert(true);
                  });
              }
            }}
          >
            Decline
          </Button>
        </div>
      );
    }
  };

  return (
    <div>
      {lastWordInLocation !== "new" ? (
        <NavTabs
          tabOneText="Partner Request"
          tabOneUrl={`/projects/${project_id}/third_party_requests/${id}/edit`}
          tabTwoText={
            status !== "change_request" ? "Partner Description" : null
          }
          tabTwoUrl={`/projects/${project_id}/third_party_requests/${id}/external_services`}
          tabThreeText={status == "complete" ? "Our Description" : null}
          tabThreeUrl={`/projects/${project_id}/third_party_requests/${id}/third_party_services`}
          hasUnsavedChanges={hasUnsavedChanges}
        />
      ) : null}
      <div className="col-sm-12" id="sow-workarea">
        <div className="tabContent">
          {partnerRequestUpdatedAlert()}
          {handleFailAlert()}
          <Card>
            <CardHeader
              title={
                lastWordInLocation === "edit"
                  ? "Partner Request"
                  : "New Partner Request"
              }
            />
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={12}>
                    <MultipleLineField
                      required={true}
                      rows={5}
                      label="High level description of work to be done *"
                      value={description}
                      onChange={(e) => {
                        setHasUnsavedChanges(true);
                        setFormChanged(true);
                        setDescription(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Row>
                      <Col>
                        <ControlledDropDown
                          required={true}
                          label="Lines of Business *"
                          value={selectedLob}
                          options={[
                            <option accessKey={null} key={null}></option>,
                            linesOfBusiness.map((lob) => (
                              <option accessKey={lob.id} key={lob.id}>
                                {lob.attributes.name}
                              </option>
                            )),
                          ]}
                          onChange={(e) => {
                            setHasUnsavedChanges(true);
                            setSelectedLob(e.target.value);
                            let selected = e.target.options.selectedIndex;
                            setLobId(e.target.options[selected].accessKey);
                            setFormChanged(true);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ControlledDropDown
                          required={true}
                          label="Partner *"
                          value={selectedPartner}
                          options={[
                            <option accessKey={null} key={null}></option>,
                            partners.map((partner) => (
                              <option
                                accessKey={
                                  partner.attributes["subcontractor-id"]
                                }
                                key={partner.attributes["subcontractor-id"]}
                              >
                                {partner.attributes.name}
                              </option>
                            )),
                          ]}
                          onChange={(e) => {
                            setHasUnsavedChanges(true);
                            setFormChanged(true);
                            setSelectedPartner(e.target.value);
                            let selected = e.target.options.selectedIndex;
                            setPartnerId(e.target.options[selected].accessKey);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {lastWordInLocation === "edit" &&
                  status !== "declined" &&
                  status !== "change_request" ? (
                    <Col sm={6}>
                      <Row>
                        <Col>
                          <Form.Group className="formGroup">
                            <Form.Check
                              style={{ marginTop: "1em" }}
                              type="checkbox"
                              disabled={true}
                              id="percentOfTotalHoursCheck"
                              label="Derive one-time and recurring costs from services?"
                              checked={deriveFromServices}
                              value="derive_from_services"
                              onChange={() => {
                                setHasUnsavedChanges(true);
                                setDeriveFromServices(!deriveFromServices);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormNumberField
                            min="0"
                            step="0.001"
                            label="Markup"
                            value={markup}
                            onChange={(e) => {
                              setHasUnsavedChanges(true);
                              setFormChanged(true);
                              setMarkup(e.target.value);
                            }}
                          />
                        </Col>
                        <Col>
                          <ControlledDropDown
                            required={true}
                            label="Subproject Currency *"
                            value={selectedCurrency}
                            options={[
                              <option accessKey={null} key={null} value="">
                                Please Select
                              </option>,
                              currencies.map((currency) => (
                                <option
                                  value={currency.attributes.name}
                                  accessKey={currency.id}
                                  key={currency.id}
                                >
                                  {currency.attributes.name}
                                </option>
                              )),
                            ]}
                            onChange={(e) => {
                              setHasUnsavedChanges(true);
                              setFormChanged(true);
                              setSelectedCurrency(e.target.value);
                              let selected = e.target.options.selectedIndex;
                              setCurrencyId(
                                e.target.options[selected].accessKey
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <FormNumberField
                            readOnly={true}
                            min="0"
                            step="0.01"
                            label="One Time Cost"
                            value={cost}
                            onChange={(e) => {
                              setHasUnsavedChanges(true);
                              setFormChanged(true);
                              setCost(e.target.value);
                            }}
                            fieldFooter={`Note: the project offer was ${
                              currencyUnit + parseFloat(cost).toFixed(2)
                            }`}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormFieldLabel label="Recurring Cost" />
                          <Form.Group className="formGroup">
                            <Form.Control
                              as="select"
                              value={frequency}
                              readOnly={true}
                              onChange={(e) => {
                                setHasUnsavedChanges(true);
                                setFormChanged(true);
                                setFrequency(e.target.value);
                              }}
                            >
                              <option accessKey="one_time" key="one_time">
                                None
                              </option>
                              <option accessKey="monthly" key="monthly">
                                Monthly
                              </option>
                              <option accessKey="quarterly" key="quarterly">
                                Quarterly
                              </option>
                              <option accessKey="yearly" key="yearly">
                                Yearly
                              </option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col>
                          <FormNumberField
                            label="Per Term"
                            readOnly={true}
                            min="0"
                            step="0.01"
                            value={recurringCost}
                            onChange={(e) => {
                              setHasUnsavedChanges(true);
                              setFormChanged(true);
                              setRecurringCost(e.target.value);
                            }}
                          />
                        </Col>
                        <Col>
                          <FormNumberField
                            label="Term"
                            readOnly={true}
                            min="0"
                            step="1"
                            value={terms}
                            onChange={(e) => {
                              setHasUnsavedChanges(true);
                              setFormChanged(true);
                              setTerms(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ) : null}
                </Row>
                <Row style={{ marginTop: "2%" }}>
                  <Col xs={6}>{getActionButtons()}</Col>
                  <Col xs={6}>
                    <BackButton
                      url={`/projects/${project_id}/third_party_requests`}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PartnerRequest;
