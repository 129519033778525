import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface APIState {
  baseURL: string;
  accessToken: string;
  refreshToken: string;
  initialized: boolean;
}

const initialState: APIState = {
  baseURL: "",
  accessToken: "",
  refreshToken: "",
  initialized: false,
};

export const initializeAPI = createAsyncThunk("api/initialize", async () => {
  // Perform the initialization logic here
  const baseURL = "https://api.scopestack.test";
  const accessToken = "YOUR_ACCESS_TOKEN";
  const refreshToken = "YOUR_REFRESH_TOKEN";

  return { baseURL, accessToken, refreshToken };
});

const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initializeAPI.fulfilled, (state, action) => {
      const { baseURL, accessToken, refreshToken } = action.payload;
      state.baseURL = baseURL;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.initialized = true;
    });
  },
});

export default apiSlice.reducer;
