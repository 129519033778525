import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useGetAccountQuery } from "@generated";

const useGetPlanName = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const { data, isLoading, error } = useGetAccountQuery({ slug: accountSlug });
  return {
    planName: (data?.data?.attributes?.["plan-name"] as string) ?? "",
    isLoading,
    error: error ?? null,
  };
};

export default useGetPlanName;
