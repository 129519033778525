import React from "react";

export const upgradePageData = [
  {
    cardTitle: "Free",
    id: "free",
    minQuantity: 0,
    cardSubtitle:
      "Start scoping and pricing today with ScopeStack’s core functionality.",
    subheader: "Free forever",
    listItems: [
      {
        content: "Service Catalog of 50+ services",
        type: "check",
      },
      { content: "Collaborative Scoping", type: "check" },
      { content: "Standardized Document Templates", type: "check" },
      { content: "Chat Support", type: "check" },
    ],
  },
  {
    cardTitle: "Essentials",
    id: "ScopeStack-Essentials-Plan-USD-Monthly",
    minQuantity: 3,
    cardSubtitle:
      "Perfect for small MSPs. Improve professional services scoping without breaking the bank.",
    subheader: "*Starts @ $120.00 / mo",
    listItems: [
      {
        content: "Customizable Document Templates",
        type: "check",
      },
      { content: "Business Dashboard", type: "check" },
      { content: "Integrations", type: "check" },
      { content: "ScopeStack API", type: "check" },
      { content: "**SSO Support", type: "check" },
      { content: "All Free Features", type: "plus" },
      {
        footer: (
          <div className="essentialsFooter">
            <span>*$35 per user, minimum 3</span>
            <span style={{ fontSize: "12px" }}>**Denotes available add-on</span>
          </div>
        ),
        type: "footer",
      },
    ],
  },
  {
    cardTitle: "Business",
    id: "ScopeStack-Business-Plan-USD-Monthly",
    minQuantity: 5,
    cardSubtitle:
      "For growing MSPs. Combines professional and managed services. Integrates seamlessly into existing workflow.",
    subheader: "*Starts @ $275.00 / mo",
    listItems: [
      {
        content: "Scope Managed Services (recurring payment schedule)",
      },
      { content: "3rd Party Quoting", type: "check" },
      { content: "Product Quoting", type: "check" },
      { content: "Expanded Integrations", type: "check" },
      { content: "E-signature (DocuSign)", type: "check" },
      { content: "Phone Support", type: "check" },
      { content: "All Essentials Features", type: "plus" },
      {
        footer: "*$50 per user, minimum 5",
        type: "footer",
      },
    ],
  },
  {
    cardTitle: "Premium",
    id: "ScopeStack-Premium-Plan-USD-Monthly",
    minQuantity: 10,
    cardSubtitle:
      "Ideal for mature MSPs and VARs. Scale services quickly and effectively with approval workflows, integrations, and programmatic scoping.",
    subheader: "*Starts @ $800.00 / mo",
    listItems: [
      {
        content: (
          <span>
            ScopeStack Recommendation Engine -{" "}
            <a
              style={{ color: "#418172" }}
              href="https://support.scopestack.io/help/overview-of-surveys-and-questionnaires"
              target="_blank"
            >
              What is this?
            </a>
          </span>
        ),
        type: "check",
      },
      { content: "Automated Project Governance Costing", type: "check" },
      { content: "Approval Workflow", type: "check" },
      {
        content: "Multiple Rate Tables & Multi-Currency Support",
        type: "check",
      },
      { content: "Advanced Pricing", type: "check" },
      { content: "All Business Features", type: "plus" },
      {
        footer: "*$75 per user, minimum 10",
        type: "footer",
      },
    ],
  },
];
