export const getSecondaryButtonByStatus = (status) => {
  switch (status) {
    case "building":
      return { class: "btnSeawhite", text: "Request Approval" };
      break;
    case "approval":
      return { class: "btnRedSecondary", text: "Cancel Approval" };
      break;
    case "approvedPsa":
      return { class: "btnSeawhite", text: "Push To PSA" };
      break;
    case "wonPsa":
      return { class: "btnSeawhite", text: "Push To PSA" };
      break;
    default:
      return {
        class: "",
        text: "",
      };
      break;
  }
};

export const getHeaderStatus = (projectStatus) => {
  switch (projectStatus) {
    case "building":
      return {
        class: "buildingStatus",
        text: "Building",
      };
      break;
    case "technical_approval":
      return {
        class: "pendingStatus",
        text: "Pending Approval",
      };
      break;
    case "sales_approval":
      return {
        class: "pendingStatus",
        text: "Pending Approval",
      };
      break;
    case "business_approval":
      return {
        class: "pendingStatus",
        text: "Pending Approval",
      };
      break;
    case "approved":
      return {
        class: "approvedStatus",
        text: "Approved",
      };
      break;
    case "won":
      return {
        class: "wonStatus",
        text: "Won",
      };
      break;
    case "lost":
      return {
        class: "lostStatus",
        text: "Lost",
      };
      break;
    case "canceled":
      return {
        class: "canceledStatus",
        text: "Canceled",
      };
      break;

    default:
      return { class: "building", text: "Building" };
      break;
  }
};

export const getProfit = (project) => {
  return (
    Number(project?.attributes?.["contract-revenue"]) -
    Number(project?.attributes?.["contract-cost"])
  ).toFixed(2);
};

export const getMargin = (project) => {
  const margin = (
    (Number(getProfit(project)) /
      Number(project?.attributes?.["contract-revenue"])) *
    100
  ).toFixed(2);
  return margin == "NaN" ? 0 : margin;
};
