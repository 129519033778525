import React from "react";
import ProjectOverview from "../ProjectOverview/ProjectOverview";
import { useLocation } from "react-router-dom";

function EditProject({ account_id, account_slug, sales_exec_required_flag, hide_msa_date_flag }) {
  const locationHook = useLocation();
  return (
    <ProjectOverview
      project_id={locationHook.state.id}
      account_id={account_id}
      account_slug={account_slug}
      locationHook={locationHook}
      sales_exec_required_flag={sales_exec_required_flag}
      hide_msa_date_flag={hide_msa_date_flag}
    />
  );
}

export default EditProject;
