import React, { useState, useRef, useEffect } from "react";
import { Card, Button, Row, Col, Tabs, Tab, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import QuoteForm from "../common/QuoteForm/QuoteForm";
import "../Show/styles/style.css";
import { Service, Subservice } from "../../Services/types/Service";
import { ColumnDef } from "@tanstack/react-table";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import {
  V1ThirdPartyServiceResource,
  useActivateSubserviceMutation,
  useCreateThirdPartyServiceMutation,
  useDeleteThirdPartyServiceMutation,
  useListLanguageFieldsForAccountQuery,
  useUpdateThirdPartyServiceMutation,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import useOauth from "@utils/customHooks/useOauth";
import API from "../../../utils/API/API";
import { getLanguages } from "../quoteHelpers/quoteHelpers";
import { ServicesTable } from "../../Services/ServicesTable/ServicesTable";

function Edit({
  project_id,
  account,
  permission,
  project,
  revenueCalculationLabel,
}) {
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const navigate = useNavigate();
  const location = useLocation();
  const windowLocation = window.location.href;
  var locationArr = windowLocation.split("/");
  var id = locationArr[locationArr.length - 2];

  const [quote, setQuote] = useState(location.state.quote || null);
  const submitRef = useRef<any>();
  const [currentTab, setCurrentTab] = useState(1);
  const [selectedLanguageField, setSelectedLanguageField] = useState(
    "Service Description"
  );
  const [updateThirdPartyServiceById] = useUpdateThirdPartyServiceMutation();
  const [createThirdPartyService] = useCreateThirdPartyServiceMutation();
  const [deleteThirdPartyService] = useDeleteThirdPartyServiceMutation();
  let [thirdPartyServices, setThirdPartyServices] =
    useState<Service[] | null>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "Something went wrong. Your changes could not be saved at this time."
  );
  const [servicesUpdating, setServicesUpdating] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] =
    useState<Service | Subservice | null>(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [allSubservices, setAllSubservices] = useState<
    V1ThirdPartyServiceResource[] | []
  >([]);

  const onUpdateServices = (services) => {
    setThirdPartyServices(
      thirdPartyServices?.map((s) => {
        services?.forEach((service) => {
          if (service.id == s.id && service.sourceType === "Quote") {
            s = service;
          }
          if (
            service.sourceType === "ThirdPartyService" &&
            s.id == service.sourceId
          ) {
            s.subservices = services;
          }
        });
        return s;
      }) || []
    );
  };

  const phases = project?.included?.filter(
    (item) => item.type === "project-phases"
  );

  const {
    data: languageFields,
    error: languageFieldsError,
    isLoading: languageFieldsLoading,
    refetch: refetchLanguageFields,
  } = useListLanguageFieldsForAccountQuery({
    slug: accountSlug,
  });

  useEffect(() => {
    // Get third party services
    if (authorizationCode !== "") {
      API.Get(
        `${apiHost}/${accountSlug}/v1/third-party-services?filter[source-type]=Quote&filter[source-id]=${quote.id}&include=project-phase,subtasks`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        let includedData = response.data.included;
        let allSubservices = includedData?.filter(
          (item) => item.type === "third-party-services"
        );
        setAllSubservices(allSubservices);
        if (data !== null) {
          const services = data.map((service) => {
            if (!service.id) {
              throw new Error("Missing service.id");
            }

            let phase = { value: 0, label: "Select Phase" };

            let foundPhase = (phases || []).find(
              (phase) =>
                service?.relationships &&
                service?.relationships?.["project-phase"] &&
                service?.relationships?.["project-phase"]?.data?.id == phase.id
            );
            if (foundPhase && foundPhase.id) {
              phase = {
                label: foundPhase.attributes?.["name"] || "",
                value: foundPhase.id,
              };
            }

            let subservices: any[] = [];
            allSubservices?.forEach((subservice) => {
              service?.relationships?.subtasks?.data?.forEach((subtask) => {
                if (subservice.id == subtask.id) {
                  subservices.push({
                    id: subservice?.id || "",
                    type: subservice.type,
                    name: subservice?.attributes?.name || "",
                    quantity: subservice?.attributes?.["quantity"] || 1,
                    cost: subservice?.attributes?.["est-cost"] || 0,
                    hours: subservice?.attributes?.["est-hours"] || 0,
                    rate: subservice?.attributes?.["est-rate"] || 0,
                    originalDescription:
                      subservice?.attributes?.["original-description"],
                    serviceDescription:
                      subservice?.attributes?.["service-description"] || "",
                    sourceType: subservice?.attributes?.["source-type"],
                    sourceId: subservice?.attributes?.["source-id"],
                    position: subservice?.attributes?.position,
                    paymentFrequency:
                      subservice?.attributes?.["payment-frequency"],
                    languages:
                      Object.keys(
                        subservice?.attributes?.["original-languages"]
                      ).length > 0
                        ? subservice?.attributes?.languages
                        : getLanguages(languageFields?.data),
                    originalLanguages:
                      Object.keys(
                        subservice?.attributes?.["original-languages"]
                      ).length > 0
                        ? subservice?.attributes?.["original-languages"]
                        : getLanguages(languageFields?.data),
                    isChanged: false,
                  });
                }
              });
            });
            return {
              id: String(service.id),
              type: service.type,
              name: service?.attributes.name,
              phase: phase,
              quantity: service?.attributes?.quantity || 1,
              originalDescription:
                service?.attributes?.["original-description"],
              serviceDescription: service?.attributes?.["service-description"],
              subservices: subservices,
              sourceType: service?.attributes?.["source-type"],
              sourceId: service?.attributes?.["source-id"],
              cost: service?.attributes?.["est-cost"] || 0,
              hours: service?.attributes?.["est-hours"] || 0,
              rate: service?.attributes?.["est-rate"] || 0,
              position: service?.attributes?.position || 1,
              paymentFrequency: service?.attributes?.["payment-frequency"],
              languages:
                Object.keys(service?.attributes?.["original-languages"])
                  .length > 0
                  ? service?.attributes?.languages
                  : getLanguages(languageFields?.data),
              originalLanguages:
                Object.keys(service?.attributes?.["original-languages"])
                  .length > 0
                  ? service?.attributes?.["original-languages"]
                  : getLanguages(languageFields?.data),
              isChanged: false,
            };
          });
          setThirdPartyServices(services);
          setServicesUpdating(false);
        }
      });
    }
  }, [authorizationCode, forceUpdate]);

  const phaseOptions = phases.map((phase) => (
    <option key={phase.id} value={phase.id}>
      {phase?.attributes?.name}
    </option>
  ));

  const handleFailResponse = (msg) => {
    if (msg) {
      setErrorMessage(msg);
      setShowFailAlert(true);
    } else {
      setErrorMessage("Something went wrong.");
      setShowFailAlert(true);
    }
  };

  const addService = () => {
    const services = thirdPartyServices ? thirdPartyServices : [];
    const newThirdPartyServices = [
      ...services,
      {
        id: `new-${Math.floor(Math.random() * 100)}`,
        name: "",
        phase: {
          label: phaseOptions[0].props.children,
          value: phaseOptions[0].props.value,
        },
        quantity: 1,
        serviceDescription: "",
        subservices: [],
        sourceType: "Quote",
        sourceId: quote.id,
        cost: 0,
        hours: 0,
        rate: 0,
        position: Number(thirdPartyServices?.length || 0) + 2,
        languages: getLanguages(languageFields?.data),
        originalLanguages: getLanguages(languageFields?.data),
      },
    ];
    setThirdPartyServices(newThirdPartyServices);
  };

  const triggerFail = (err) => {
    setServicesUpdating(false);
    handleFailResponse(err?.data?.errors?.[0]?.detail);
    setShowFailAlert(true);
  };

  const triggerSuccess = () => {
    setSuccessMessage("Third Party Services Updated!");
    setShowSuccessAlert(true);
    setForceUpdate(!forceUpdate);
  };

  const getData = (item) => {
    let phaseId = item?.phase?.value;
    if (item.sourceType === "ThirdPartyService") {
      const service = thirdPartyServices?.find(
        (service) => service.id == item.sourceId
      );
      phaseId = service?.phase?.value || phaseOptions[0].props.value;
    }
    let data: any = {
      type: "third-party-services",
      id: Number(item.id),
      attributes: {
        name: item.name || "",
        quantity: item.quantity,
        "original-description": item.originalDescription,
        "service-description": item.serviceDescription,
        "est-cost": item.cost,
        "est-hours": item.hours,
        "est-rate": item.rate,
        "payment-frequency": item.paymentFrequency,
        "source-type": item.sourceType,
        "source-id": item.sourceId,
        position: item.position,
        "original-languages": item.originalLanguages,
        languages: item.languages,
      },
      relationships: {
        "project-phase": {
          data: { type: "project-phases", id: phaseId },
        },
      },
    };
    return data;
  };

  const updateServices = async () => {
    setServicesUpdating(true);
    const thirdPartySubservices = new Set();
    thirdPartyServices?.forEach((service) => {
      service?.subservices?.forEach((subservice) => {
        thirdPartySubservices.add(subservice);
      });
    });
    const thirdPartySubservicesArray: any[] =
      Array.from(thirdPartySubservices) || [];
    const itemsToUpdate: any[] = (
      thirdPartyServices?.concat(thirdPartySubservicesArray) || []
    ).filter((item) => item?.isChanged && item?.sourceId);
    if (itemsToUpdate.length > 0) {
      for (const item of itemsToUpdate) {
        let data = getData(item);
        if (!item.id.includes("new")) {
          await updateThirdPartyServiceById({
            slug: accountSlug,
            id: Number(item.id),
            body: { data: data },
          }).catch((err) => {
            triggerFail(err);
          });
        } else {
          delete data?.id;
          await createThirdPartyService({
            slug: accountSlug,
            body: { data: data },
          })
            .then(async (res) => {
              let resData = res as any;
              const service = resData?.data?.data;
              if (item.subservices && item.subservices.length > 0) {
                const newSubservices = item.subservices.filter((subservice) =>
                  subservice.id.includes("new")
                );
                if (newSubservices) {
                  for (let sub of newSubservices) {
                    sub = { ...sub, sourceId: service.id };
                    let newSubData = getData(sub);
                    delete newSubData.id;
                    await createThirdPartyService({
                      slug: accountSlug,
                      body: { data: newSubData },
                    }).catch((err) => {
                      triggerFail(err);
                    });
                  }
                }
              }
            })
            .catch((err) => {
              triggerFail(err);
            });
        }
      }
      triggerSuccess();
    } else {
      triggerSuccess();
    }
  };

  const setThirdPartyServicesData = (item, isOnBlur) => {
    const services = thirdPartyServices ? thirdPartyServices : [];
    let newlyUpdatedThirdPartyServices = [...services].map((obj) => {
      if (obj?.subservices && obj.subservices.length > 0) {
        obj.subservices.forEach((sub) => {
          if (sub.id == item.id) {
            const newSubservices =
              obj?.subservices?.filter(
                (subservice) => subservice.id !== item.id
              ) || [];
            obj.subservices = [...newSubservices, item];
          }
        });
      }
      if (obj.id === item.id) {
        return item;
      } else {
        return obj;
      }
    });
    if (isOnBlur) {
      thirdPartyServices = newlyUpdatedThirdPartyServices;
    } else {
      setThirdPartyServices(newlyUpdatedThirdPartyServices);
    }
  };

  const addSubservice = (selectedService) => {
    const classList = document.getElementById(
      `multiCollapse${selectedService.id}`
    )?.classList;
    if (!classList?.contains("show")) {
      classList?.add("show");
    }
    setThirdPartyServices(
      thirdPartyServices?.map((service) => {
        const newSubservice: Subservice = {
          id: `new-${Math.floor(Math.random() * 100)}`,
          type: service.type,
          name: "",
          quantity: 1,
          originalDescription: "",
          serviceDescription: "",
          sourceType: "ThirdPartyService",
          sourceId: selectedService.id.toString() || "",
          position: Number(service?.subservices?.length || 0) + 2,
          isChanged: false,
          languages: getLanguages(languageFields?.data),
          originalLanguages: getLanguages(languageFields?.data),
        };
        if (selectedService?.id === service?.id) {
          service.subservices =
            service.subservices && service.subservices.length > 0
              ? ([...service.subservices, newSubservice] as Subservice[])
              : ([newSubservice] as Subservice[]);
        }
        return service;
      }) || []
    );
  };

  const filterOutDeletedServices = (serviceToDelete) => {
    if (serviceToDelete?.sourceType === "Quote") {
      setThirdPartyServices(
        thirdPartyServices?.filter(
          (service) => service.id !== serviceToDelete?.id
        ) || []
      );
    } else {
      setThirdPartyServices(
        thirdPartyServices?.map((service) => {
          if (
            service.id == serviceToDelete?.sourceId ||
            (typeof serviceToDelete.id === "string" &&
              serviceToDelete.id.includes("new"))
          ) {
            service.subservices = service.subservices?.filter(
              (subservice) => subservice.id !== serviceToDelete?.id
            );
          }
          return service;
        }) || []
      );
    }
  };

  const deleteService = (selectedService) => {
    setServiceToDelete(selectedService);
    if (
      selectedService &&
      typeof selectedService.id === "string" &&
      selectedService.id.includes("new")
    ) {
      filterOutDeletedServices(selectedService);
    } else {
      filterOutDeletedServices(selectedService);
      setShowDeleteConfirmModal(true);
    }
  };

  const executeServiceDeletion = () => {
    setShowDeleteConfirmModal(false);
    setServicesUpdating(true);
    if (serviceToDelete) {
      deleteThirdPartyService({
        slug: accountSlug,
        id: Number(serviceToDelete?.id),
      })
        .then((res) => {
          setSuccessMessage("Third Party Service Deleted!");
          setShowSuccessAlert(true);
          setForceUpdate(!forceUpdate);
          setServicesUpdating(false);
        })
        .catch((err) => {
          handleFailResponse(err?.data?.errors?.[0]?.detail);
          setShowFailAlert(true);
          setForceUpdate(!forceUpdate);
          setServicesUpdating(false);
        });
    }
  };

  const getLanguageFieldValue = (item, isOurDescription) => {
    let value = "";
    if (selectedLanguageField === "Service Description") {
      if (
        isOurDescription &&
        item.serviceDescription &&
        item.serviceDescription !== ""
      ) {
        value = item.serviceDescription;
      } else {
        value = item.originalDescription;
      }
    } else {
      languageFields?.data?.forEach((lf) => {
        if (lf?.attributes?.name == selectedLanguageField) {
          if (isOurDescription) {
            value = item.languages?.[lf.attributes["sow-language"]];
          } else {
            value = item.originalLanguages?.[lf.attributes["sow-language"]];
          }
        }
      });
    }
    return value;
  };

  const allLanguageFields = [...(languageFields?.data || [])];
  allLanguageFields?.unshift({
    attributes: {
      name: "Service Description",
      "sow-language": "service-description",
    },
  });

  const getServiceColumns = (isOurDescription: boolean) => {
    let serviceColumns: ColumnDef<Service>[] = [
      {
        header: "Phase",
        cell(props) {
          return props.row.original.id === "noRow" ? (
            <div>{props.row.original.phase?.label}</div>
          ) : (
            <Form.Control
              as="select"
              defaultValue={props.row.original?.phase?.value}
              onChange={(e) => {
                e.stopPropagation();
                const newPhase = {
                  label: e?.target?.["selectedOptions"][0].innerHTML,
                  value: e.target?.value,
                };
                props.row.original.phase = newPhase;
                props.row.original.isChanged = true;
              }}
              readOnly={isOurDescription}
              disabled={isOurDescription}
              onBlur={(e) => {
                setThirdPartyServicesData(props.row.original, true);
              }}
            >
              {phaseOptions}
            </Form.Control>
          );
        },
      },
      {
        header: "Service Name",
        cell(props) {
          return props.row.original.id === "noRow" ? (
            ""
          ) : (
            <Form.Control
              type="text"
              defaultValue={props.row.original.name}
              onChange={(e) => {
                e.stopPropagation();
                props.row.original.name = e.target.value;
                props.row.original.isChanged = true;
              }}
              readOnly={isOurDescription}
              disabled={isOurDescription}
              onBlur={(e) => {
                setThirdPartyServicesData(props.row.original, true);
              }}
            />
          );
        },
      },
      {
        header: "QTY",
        cell(props) {
          return props.row.original.id === "noRow" ? (
            ""
          ) : (
            <Form.Control
              type="number"
              min="1"
              step="1"
              defaultValue={props.row.original.quantity}
              onChange={(e) => {
                e.stopPropagation();
                props.row.original.quantity = Number(e.target.value);
                props.row.original.isChanged = true;
              }}
              readOnly={isOurDescription}
              disabled={isOurDescription}
              onBlur={(e) => {
                setThirdPartyServicesData(props.row.original, true);
              }}
            />
          );
        },
      },
    ];
    if (currentTab !== 4) {
      serviceColumns.push({
        header: selectedLanguageField,
        cell(props) {
          return props.row.original.id === "noRow"
            ? ""
            : allLanguageFields?.map((language, index) => (
                <Form.Control
                  key={language.id}
                  style={
                    language?.attributes?.name !== selectedLanguageField
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  as="textarea"
                  rows={1}
                  defaultValue={getLanguageFieldValue(
                    props.row.original,
                    isOurDescription
                  )}
                  onChange={(e) => {
                    e.stopPropagation();
                    if (selectedLanguageField === "Service Description") {
                      if (isOurDescription) {
                        props.row.original.serviceDescription = e.target.value;
                      } else {
                        props.row.original.originalDescription = e.target.value;
                      }
                    } else {
                      allLanguageFields?.forEach((lf) => {
                        if (lf?.attributes?.name == selectedLanguageField) {
                          if (isOurDescription) {
                            const key = lf.attributes["sow-language"];
                            props.row.original = {
                              ...props.row.original,
                              languages: {
                                ...props.row.original.languages,
                                [key]: e.target.value,
                              },
                            };
                          } else {
                            const key = lf.attributes["sow-language"];
                            props.row.original = {
                              ...props.row.original,
                              originalLanguages: {
                                ...props.row.original.originalLanguages,
                                [key]: e.target.value,
                              },
                            };
                          }
                        }
                      });
                    }
                    props.row.original.isChanged = true;
                  }}
                  onBlur={(e) => {
                    setThirdPartyServicesData(props.row.original, true);
                  }}
                />
              ));
        },
      });
    } else {
      let pricingColumns = [
        {
          header: "Hours",
          cell(props) {
            return props.row.original.id === "noRow" ? (
              ""
            ) : (
              <Form.Control
                type="number"
                min="0"
                step="0.01"
                defaultValue={props.row.original.hours}
                onChange={(e) => {
                  e.stopPropagation();
                  props.row.original.hours = Number(e.target.value);
                  props.row.original.isChanged = true;
                }}
                readOnly={false}
                disabled={false}
                onBlur={(e) => {
                  setThirdPartyServicesData(props.row.original, true);
                }}
              />
            );
          },
        },
        {
          header: "Rate",
          cell(props) {
            return props.row.original.id === "noRow" ? (
              ""
            ) : (
              <Form.Control
                type="number"
                min="0.0"
                step="0.01"
                defaultValue={props.row.original.rate}
                onChange={(e) => {
                  e.stopPropagation();
                  props.row.original.rate = Number(e.target.value);
                  props.row.original.isChanged = true;
                }}
                readOnly={false}
                disabled={false}
                onBlur={(e) => {
                  setThirdPartyServicesData(props.row.original, true);
                }}
              />
            );
          },
        },
        {
          header: "Cost",
          cell(props) {
            return props.row.original.id === "noRow" ? (
              ""
            ) : (
              <Form.Control
                type="number"
                min="0.0"
                step="0.01"
                defaultValue={props.row.original.cost}
                onChange={(e) => {
                  e.stopPropagation();
                  props.row.original.cost = Number(e.target.value);
                  props.row.original.isChanged = true;
                }}
                readOnly={false}
                disabled={false}
                onBlur={(e) => {
                  setThirdPartyServicesData(props.row.original, true);
                }}
              />
            );
          },
        },
        {
          header: "Frequency",
          cell(props) {
            return props.row.original.id === "noRow" ? (
              ""
            ) : (
              <Form.Control
                as="select"
                defaultValue={props.row.original.paymentFrequency}
                onChange={(e) => {
                  e.stopPropagation();
                  props.row.original.paymentFrequency = e.target.value;
                  props.row.original.isChanged = true;
                  setThirdPartyServicesData(props.row.original, false);
                }}
                readOnly={false}
                disabled={false}
              >
                <option value="one_time">One Time</option>
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="yearly">Yearly</option>
              </Form.Control>
            );
          },
        },
      ];
      serviceColumns = serviceColumns.concat(pricingColumns);
    }
    return serviceColumns;
  };

  const getSubserviceColumns = (isOurDescription: boolean) => {
    let subserviceColumns = getServiceColumns(isOurDescription);
    subserviceColumns.shift();
    subserviceColumns.shift();
    subserviceColumns.unshift({
      header: "Service Name",
      cell(props) {
        return props.row.original.id === "noRow" ? (
          props.row.original.name
        ) : (
          <Form.Control
            type="text"
            defaultValue={props.row.original.name}
            onChange={(e) => {
              e.stopPropagation();
              props.row.original.name = e.target.value;
              props.row.original.isChanged = true;
            }}
            readOnly={isOurDescription}
            disabled={isOurDescription}
            onBlur={(e) => {
              setThirdPartyServicesData(props.row.original, true);
            }}
          />
        );
      },
    });
    return subserviceColumns;
  };

  const getDesriptionData = (isOurDescription: boolean) => {
    if (
      !thirdPartyServices ||
      !languageFields ||
      languageFieldsLoading ||
      servicesUpdating
    ) {
      return <SmallSpinner />;
    } else {
      return (
        <div>
          {handleAlert(
            showSuccessAlert,
            successMessage,
            setShowSuccessAlert,
            "success",
            ToastAlert
          )}
          {handleAlert(
            showFailAlert,
            errorMessage,
            setShowFailAlert,
            "warning",
            ToastAlert
          )}
          <Row style={{ alignItems: "center", marginBottom: "20px" }}>
            <Col style={{ marginLeft: "16px" }} sm={7}>
              {thirdPartyServices.length > 0 && currentTab !== 4 ? (
                <Row style={{ alignItems: "center" }}>
                  <Col className="instructionalText" sm={4}>
                    Show / edit content for:
                  </Col>
                  <Col>
                    <div style={{ width: "300px", marginLeft: "-40px" }}>
                      <Form.Control
                        as="select"
                        value={selectedLanguageField}
                        onChange={(e) => {
                          setSelectedLanguageField(e.target.value);
                          thirdPartyServices?.forEach((service) => {
                            setThirdPartyServicesData(service, false);
                            service?.subservices?.forEach((subservice) => {
                              setThirdPartyServicesData(subservice, false);
                            });
                          });
                        }}
                      >
                        <option key={0}>Service Description</option>
                        {languageFields?.data?.map((lf) => (
                          <option key={lf.id}>{lf?.attributes?.name}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Col>
            <Col>
              {!isOurDescription ? (
                <Button
                  style={{ float: "right", marginRight: "16px" }}
                  className="squareGreenButton"
                  onClick={() => addService()}
                >
                  Add Services
                </Button>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <ServicesTable
                  servicesPassed={thirdPartyServices}
                  onUpdateServices={onUpdateServices}
                  serviceColumns={getServiceColumns(isOurDescription)}
                  subserviceColumns={getSubserviceColumns(isOurDescription)}
                  addSubservice={addSubservice}
                  deleteService={deleteService}
                  hasSettings={!isOurDescription}
                  hasDragAndDrop={true}
                />
              </Form>
            </Col>
          </Row>
        </div>
      );
    }
  };

  return (
    <div className="editQuotePage">
      <ConfirmationModal
        show={showDeleteConfirmModal}
        title="Delete Third Party Service"
        message={`Are you sure you want to delete ${serviceToDelete?.name}?`}
        onConfirm={() => executeServiceDeletion()}
        onCancel={() => setShowDeleteConfirmModal(false)}
      />
      <Card>
        <Card.Header className="cardHeaderOverride">
          <div className="headerContainer">
            <div className="actionBtns">
              <Button
                style={{ marginRight: "20px" }}
                className="btnSeawhite"
                onClick={(e) =>
                  navigate(`/projects/${project_id}/quotes`, {
                    state: null,
                  })
                }
              >
                Cancel
              </Button>
              <div>
                <span className="text24">Edit {quote.vendor.label}</span>
              </div>
            </div>
            <div className="actionBtns">
              {permission !== "view" &&
                permission !== "create" &&
                project?.data?.attributes?.status === "building" && (
                  <Button
                    style={{ marginRight: "16px", float: "right" }}
                    className="squareGreenButton"
                    onClick={() => {
                      if (submitRef && submitRef.current && currentTab === 1) {
                        submitRef.current.saveQuote(false);
                      } else {
                        setServicesUpdating(true);
                        updateServices();
                      }
                    }}
                  >
                    Save
                  </Button>
                )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Tabs
            activeKey={currentTab}
            className="mb-3"
            onSelect={(eventKey) => {
              setCurrentTab(Number(eventKey));
              thirdPartyServices?.forEach((service) => {
                setThirdPartyServicesData(service, false);
                service?.subservices?.forEach((subservice) => {
                  setThirdPartyServicesData(subservice, false);
                });
              });
            }}
          >
            <Tab
              eventKey={1}
              title={"Quote Details"}
              tabClassName="quoteDetailsTab"
              className="quoteDetailsContent"
            >
              <QuoteForm
                quoteProp={quote}
                project_id={project_id}
                account={account}
                ref={submitRef}
                permission={permission}
                project={project}
                authorizationCode={authorizationCode}
                revenueCalculationLabel={
                  quote?.revenueCalculationLabel
                    ? quote?.revenueCalculationLabel
                    : revenueCalculationLabel
                }
              />
            </Tab>
            <Tab
              eventKey={2}
              title={"Vendor Description of Services"}
              tabClassName="vendorDescriptionTab"
              className="vendorDescriptionContent"
            >
              {getDesriptionData(false)}
            </Tab>
            <Tab
              eventKey={3}
              title={"Our Description of Services"}
              tabClassName="ourDescriptionTab"
              className="ourDescriptionContent"
            >
              {getDesriptionData(true)}
            </Tab>
            {quote?.deriveFromServices ? (
              <Tab
                eventKey={4}
                title={"Service Pricing"}
                tabClassName="servicePricingTab"
                className="servicePricingContent"
              >
                {getDesriptionData(true)}
              </Tab>
            ) : null}
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Edit;
