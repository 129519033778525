import React, { useState } from "react";
import OnboardingCards from "../reusable/OnboardingCards";
import { Card, Row, Col, Form } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useNavigate } from "react-router";
import AccordionCard from "@components/AccordionCard/AccordionCard";
import { faArrowDown, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import ControlledDropDown from "@components/Forms/ControlledDropDown";

function LanguageFieldsInfo({ setRerender }) {
  let navigate = useNavigate();
  const [lfValue, setLfValue] = useState("zero");

  window.onpopstate = function (e) {
    setRerender(true);
  };

  const getLf = () => {
    let valueToReturn;
    switch (lfValue) {
      case "first":
        valueToReturn = (
          <p>
            Client Responsibilites: Aliquam eget sem ac eros tincidunt
            consequat. Integer congue tellus non diam venenatis, vel pulvinar.
          </p>
        );
        break;
      case "second":
        valueToReturn = (
          <p>
            Key Assumptions : Provident similique accusantium nemo autem.
            Veritatis obcaecati tenetur iure eius earum.
          </p>
        );
        break;
      case "third":
        valueToReturn = (
          <p>
            Deliverables : Impedit sit sunt quaerat, odit, tenetur error, harum
            nesciunt ipsum debitis quas aliquid.
          </p>
        );
        break;
      default:
        valueToReturn = (
          <p>
            Service Descriptions : Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Maxime mollitia, molestiae.
          </p>
        );
        break;
    }
    return valueToReturn;
  };
  return (
    <OnboardingCards
      contentCardHeader={"Let’s tailor your account to the way you work"}
      leftContent={
        <div>
          <p>
            <strong>Language Fields</strong> in ScopeStack assist you in
            describing Services and Subservices more precisely and accurately.
          </p>{" "}
          <p>
            They typically align to sections in your Scope of Work such as
            “Scope of Services”, "Key Assumptions", and  "Client
            Responsibilities."
          </p>
          <p>Here is an example of a Service and its Language Fields. </p>
        </div>
      }
      rightContent={
        <Card style={{ height: "585px" }} className="slideCard">
          <CardHeader className="internalCardHeader">
            Cisco Super Mega Router Install (Project)
          </CardHeader>
          <hr />
          <Card.Body className="internalCardBody">
            <div>
              <AccordionCard
                header={
                  <div
                    style={{ marginRight: "29vw" }}
                    className="accordionPlanHeader"
                  >
                    Plan (Phase)
                  </div>
                }
                body={
                  <div className="serviceContainer">
                    <p>
                      <strong>(Service)</strong> Networking design session
                    </p>
                    <Card className="smallWhiteInfoCard">
                      <Row>
                        <Col
                          sm={7}
                          className="lfInfoCol"
                          style={{ width: "33vw", marginTop: "-20px" }}
                        >
                          <ControlledDropDown
                            value={lfValue}
                            onChange={(e) => setLfValue(e.target.value)}
                            options={[
                              {
                                name: "Service Description",
                                value: "zero",
                              },
                              {
                                name: "Client Responsibilities",
                                value: "first",
                              },
                              {
                                name: "Key Assumptions",
                                value: "second",
                              },
                              {
                                name: "Deliverables",
                                value: "third",
                              },
                            ].map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.name}
                              </option>
                            ))}
                            fieldFooter="(Use dropdown to see all examples)"
                          />
                        </Col>
                        <Col sm={5}>
                          <div className="greyBorderedBox">{getLf()}</div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                }
                className="phaseAccordion"
                iconOpen={faArrowDown}
                iconClosed={faArrowRight}
                closeable={true}
                iconHexCode="#1c2655"
                headerBackgroundHexCode="#fff"
                cardBackgroundHexCode="#fff"
                cardClass="stillAccordion"
                startingAccordionState={"open"}
                setAccordianIsOpen={() => null}
              />
              <AccordionCard
                header={
                  <div
                    style={{ marginRight: "28vw" }}
                    className="accordionDesignHeader"
                  >
                    Design (Phase)
                  </div>
                }
                body={<div></div>}
                className="phaseAccordion"
                iconOpen={faArrowDown}
                iconClosed={faArrowRight}
                closeable={false}
                iconHexCode="#1c2655"
                headerBackgroundHexCode="#fff"
                cardBackgroundHexCode="#fff"
                cardClass="stillAccordion"
                startingAccordionState={"closed"}
                setAccordianIsOpen={() => null}
              />
              <AccordionCard
                header={
                  <div
                    style={{ marginRight: "26vw" }}
                    className="accordionImplementHeader"
                  >
                    Implement (Phase)
                  </div>
                }
                body={<div></div>}
                className="phaseAccordion"
                iconOpen={faArrowDown}
                iconClosed={faArrowRight}
                closeable={false}
                iconHexCode="#1c2655"
                headerBackgroundHexCode="#fff"
                cardBackgroundHexCode="#fff"
                cardClass="stillAccordion"
                startingAccordionState={"closed"}
                setAccordianIsOpen={() => null}
              />
            </div>
          </Card.Body>
        </Card>
      }
      rightButtonText={"Continue"}
      rightButtonOnClick={() => {
        setRerender(true);
        navigate("/onboarding/language-fields-edit");
      }}
      skipSetupButton={true}
    />
  );
}

export default LanguageFieldsInfo;
