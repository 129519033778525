import React, { useState, useEffect } from "react";
import { Row, Col, FormCheck, Form, Table } from "react-bootstrap";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import { useNavigate } from "react-router";
import API from "@API";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import {
  capitalizeFirstLetter,
  formatUnprocessibleResponse,
  removeDuplicates,
  snakeToPascalCase,
  splitPascalCase,
  stringToArray,
  toSnakeCase,
  getDate,
  formatDateForField,
  handleAlert,
} from "@utils/helperFunctions";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import CurrencyFormat from "react-currency-format";
import SearchField from "@components/Forms/SearchField/SearchField";
import PaginationNumbersAPI from "@components/Buttons/PaginationAPI/PaginationNumbersAPI";
import DeconstructedUrl from "@utils/DeconstructedUrl";
import "./ProjectsList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsFilter,
  faTrash,
  faClone,
  faPencil,
  faExternalLink,
  faTableLayout,
  faTrashCan,
  faClockRotateLeft,
} from "@fortawesome/pro-solid-svg-icons";
import { faFileArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { faSort } from "@fortawesome/pro-light-svg-icons";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import SlideOutMenuSectioned from "@components/SlideOutMenu/SlideOutMenuSectioned";
import MultiSelectAutocomplete from "@components/Forms/MultiSelectAutocomplete/MultiSelectAutocomplete";
import FormDateField from "@components/Forms/FormDateField";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import ContextMenu from "@components/ContextMenu/ContextMenu";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";
import FontAwesomeIconTooltipped from "@components/Buttons/FontAwesomeIconTooltipped/FontAwesomeIconTooltipped";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";

function ProjectsList({
  account_id,
  account_slug,
  fullSalesExecsList,
  fullPresalesEngineersList,
  fullClientList,
  fullTeamsList,
  fullTeammateList,
  fullTagsList,
  authorizationCode,
}) {
  // Env & url/location alerts, & error messages
  const navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showMaxColAlert, setShowMaxColAlert] = useState(false);

  // Project States
  const [projects, setProjects] = useState([]);
  const [projectsPerPage, setProjectsPerPage] = useState([]);
  const [projectName, setProjectName] = useState("");

  //Page States
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageArray, setPageArray] = useState([]);
  const [fullPageArray, setFullPageArray] = useState([]);
  const [lastPage, setLastPage] = useState(5);
  const [firstPage, setFirstPage] = useState(1);

  // Loading states
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [forceStateUpdate, setForceStateUpdate] = useState(false);

  // Misc states
  const [sort, setSort] = useState("-created-at");
  const [userViewId, setUserViewId] = useState(null);

  // SlideOutMenu
  const [show, setShow] = useState(false);
  const [columnConfigShow, setColumnConfigShow] = useState(false);

  // Selected states are passed to setQueryParams to go into the query
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedSalesExecs, setSelectedSalesExecs] = useState([]);
  const [selectedEngineers, setSelectedEngineers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTeammates, setSelectedTeammates] = useState([]);
  const [selectedEditStartDate, setSelectedEditStartDate] = useState("");
  const [selectedEditEndDate, setSelectedEditEndDate] = useState("");
  const [selectedCreatedStartDate, setSelectedCreatedStartDate] = useState("");
  const [selectedCreatedEndDate, setSelectedCreatedEndDate] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState([]);

  // Included response data states
  const [clients, setClients] = useState([]);
  const [presalesEngineers, setPresalesEngineers] = useState([]);
  const [salesExecs, setSalesExecs] = useState([]);
  const [tags, setTags] = useState([]);

  // Displayed states are the selected value(s) stored as an array
  // to be mapped over and rendered on the "Filtering By" line above the table
  const [displayedClients, setDisplayedClients] = useState([]);
  const [displayedSalesExecs, setDisplayedSalesExecs] = useState([]);
  const [displayedEngineers, setDisplayedEngineers] = useState([]);
  const [displayedStatus, setDisplayedStatus] = useState([]);
  const [displayedTeams, setDisplayedTeams] = useState([]);
  const [displayedTeammates, setDisplayedTeammates] = useState([]);
  const [displayedEditStartDate, setDisplayedEditStartDate] = useState([]);
  const [displayedEditEndDate, setDisplayedEditEndDate] = useState([]);
  const [displayedCreatedStartDate, setDisplayedCreatedStartDate] = useState(
    []
  );
  const [displayedCreatedEndDate, setDisplayedCreatedEndDate] = useState([]);
  const [displayedTags, setDisplayedTags] = useState([]);

  // Filter states
  const [showMyProjectsOnly, setShowMyProjectsOnly] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [filterList, setFilterList] = useState([]);
  // Filtered states here are the same as displayed states,
  // except they are only set onLoad of the page and when the user clicks "Apply"
  // This is so that we can reset the menu to what it previously was on "Cancel"
  const [filteredClientsData, setFilteredClientsData] = useState([]);
  const [filteredSalesData, setFilteredSalesData] = useState([]);
  const [filteredPresalesData, setFilteredPresalesData] = useState([]);
  const [filteredStatusData, setFilteredStatusData] = useState([]);
  const [filteredTeamsData, setFilteredTeamsData] = useState([]);
  const [filteredTeammateData, setFilteredTeammateData] = useState([]);
  const [filteredTagsData, setFilteredTagsData] = useState([]);
  const [filteredEditStartDate, setFilteredEditStartDate] = useState([]);
  const [filteredEditEndDate, setFilteredEditEndDate] = useState([]);
  const [filteredCreatedStartDate, setFilteredCreatedStartDate] = useState([]);
  const [filteredCreatedEndDate, setFilteredCreatedEndDate] = useState([]);

  // Filters Object
  const [userViewFilters, setUserViewFilters] = useState({});

  // Form control states (selected states are used as values for dates, displayed for dropdowns )
  const [statusChecks, setStatusChecks] = useState([
    { label: "Building", checked: false },
    { label: "Technical Approval", checked: false },
    { label: "Sales Approval", checked: false },
    { label: "Business Approval", checked: false },
    { label: "Approved", checked: false },
    { label: "Lost", checked: false },
    { label: "Won", checked: false },
    { label: "Canceled", checked: false },
  ]);
  const [dateEditedStart, setDateEditedStart] = useState("");
  const [dateEditedEnd, setDateEditedEnd] = useState("");
  const [dateCreatedStart, setDateCreatedStart] = useState("");
  const [dateCreatedEnd, setDateCreatedEnd] = useState("");

  // Column states
  const [headerColumns, setHeaderColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [userViewColumns, setUserViewColumns] = useState([]);
  const [searchedColumns, setSearchedColumns] = useState([]);
  const [columnName, setColumnName] = useState("");
  const [dragItem, setDragItem] = useState(null);
  const [showNoColumnsMatchSearchAlert, setShowNoColumnsMatchSearchAlert] =
    useState(false);

  // Misc
  const [searchedClientOptions, setSearchedClientOptions] = useState(null);

  // Archive/Bulk Delete States
  const [checkedProjects, setCheckedProjects] = useState([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [isActiveTabSelected, setIsActiveTabSelected] = useState(true);
  const [activeTabRecordCount, setActiveTabRecordCount] = useState("");
  const [archiveTabRecordCount, setArchiveTabRecordCount] = useState("");

  // Context Menu
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuX, setContextMenuX] = useState(0);
  const [contextMenuY, setContextMenuY] = useState(0);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  // Project List Export
  const [showExportModal, setShowExportModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Your changes were saved."
  );

  // Privileges
  const [duplicatePrivilege, setDuplicatePrivilege] = useState(false);

  let URL = new DeconstructedUrl(account_slug);
  URL.queryParams.include = "sales-executive,presales-engineer,client";
  URL.queryParams.page.size = itemsPerPage;
  URL.queryParams.page.number = currentPage;
  URL.queryParams.sort = sort;

  const buildOptionsArray = (source) => {
    let optionsArray = [];
    if (source !== null) {
      optionsArray = source.map((item) => {
        return { label: item.attributes.name, value: item.id };
      });
    }
    return optionsArray;
  };

  const clientOptions = buildOptionsArray(fullClientList);
  const salesOptions = buildOptionsArray(fullSalesExecsList);
  const presalesOptions = buildOptionsArray(fullPresalesEngineersList);
  const teamsOptions = buildOptionsArray(fullTeamsList);
  const teammateOptions = buildOptionsArray(fullTeammateList);
  const tagsOptions = buildOptionsArray(fullTagsList);

  function identifySortColumn(columns) {
    let sortColumn = columns.filter((column) => column.sorted != "false");
    if (sortColumn.length == 0) {
      sortColumn = columns.filter((column) => column.label == "Created On");
      if (sortColumn.length == 0) {
        sortColumn = [columns[0]];
      }
      sortColumn[0].sorted = "desc";
    }
    return sortColumn[0];
  }

  const getFilter = (filter, name, defaultVal) => {
    if (typeof filter != "object") {
      return defaultVal;
    }

    return Object.keys(filter).includes(name) ? filter[name] : defaultVal;
  };

  const setColumnsFromUserView = (userView) => {
    let columns = userView.attributes.columns;
    let availableColumns = columns.filter(
      (column) => column.selected === false
    );
    columns = columns.filter((column) => column.selected === true);
    columns = columns.sort((a, b) => a.position - b.position);
    setHeaderColumns(columns);
    setAvailableColumns(availableColumns);
    return columns;
  };

  const setSortFromColumns = (columns) => {
    let sortColumn = identifySortColumn(columns);
    let iconValue = sortColumn.sorted;
    if (iconValue == "asc") iconValue = "";
    if (iconValue == "desc") iconValue = "-";
    let sort = `${iconValue}${getSortValue(sortColumn)}`;

    setSort(sort);
    setQueryParams({ sort: sort });
    return sort;
  };

  const setPagingFromUserView = (userView) => {
    let newPageSize = userView.attributes["page-size"];
    setItemsPerPage(newPageSize);
    URL.queryParams.page.size = newPageSize;
  };

  const getResourceFilters = (filters, resourceName, resourceOptions) => {
    let resourceFilter = getFilter(filters, resourceName, []);
    if (resourceFilter.length > 0) setHasFilters(true);

    let displayed = [];
    resourceOptions.forEach((option) => {
      resourceFilter.forEach((client) => {
        if (client == option.value) {
          displayed.push(option);
        }
      });
    });
    return { resourceFilter: resourceFilter, displayed: displayed };
  };

  const getDateDisplayObjects = (dateFilter) => {
    return stringToArray(dateFilter).map((date) => {
      return { label: getDate(date) };
    });
  };

  const setQueryParamsFromFilters = (filters) => {
    let queryParams = {};

    queryParams.clients = getFilter(filters, "clients", []);
    setSelectedClients(queryParams.clients);

    queryParams.salesExecs = getFilter(filters, "salesExecs", []);
    setSelectedSalesExecs(queryParams.salesExecs);

    queryParams.presalesEngineers = getFilter(filters, "presalesEngineers", []);
    setSelectedEngineers(queryParams.presalesEngineers);

    queryParams.status = getFilter(filters, "status", []);
    setSelectedStatus(queryParams.status);

    queryParams.teams = getFilter(filters, "teams", []);
    setSelectedTeams(queryParams.teams);

    queryParams.teammates = getFilter(filters, "teammates", []);
    setSelectedTeammates(queryParams.teammates);

    queryParams.tags = getFilter(filters, "tags", []);
    setSelectedTags(queryParams.tags);

    queryParams.dateEditedStart = getFilter(filters, "dateEditedStart", "");
    setSelectedEditStartDate(queryParams.dateEditedStart);

    queryParams.dateEditedEnd = getFilter(filters, "dateEditedEnd", "");
    setSelectedEditEndDate(queryParams.dateEditedEnd);

    queryParams.dateCreatedStart = getFilter(filters, "dateCreatedStart", "");
    setSelectedCreatedStartDate(queryParams.dateCreatedStart);

    queryParams.dateCreatedEnd = getFilter(filters, "dateCreatedEnd", "");
    setSelectedCreatedEndDate(queryParams.dateCreatedEnd);

    setQueryParams(queryParams);
  };

  const setStateFromUserView = (userView) => {
    handleSettingFilterList(userView.attributes.filters);
    setUserViewFilters(userView.attributes.filters);
    setUserViewColumns(userView.attributes.columns);
    setAllColumns(userView.attributes.columns);
    let columns = setColumnsFromUserView(userView);
    setSortFromColumns(columns);
    setUserViewId(userView.id);
    setPagingFromUserView(userView);
  };

  useEffect(() => {
    if (
      authorizationCode !== "" &&
      fullClientList !== null &&
      fullSalesExecsList !== null &&
      fullPresalesEngineersList !== null &&
      fullTeamsList !== null &&
      fullTeammateList !== null
    ) {
      API.Get(
        `${apiHost}/${account_slug}/v1/user-views?filter[view-type]=project`,
        authorizationCode
      ).then((res) => {
        let userView = res.data.data[0];
        setStateFromUserView(userView);
        // setUserViewId(userView.id);
        let filters = userView.attributes.filters;
        setSelectedTagIds(filters.tagIds);
        setQueryParams(filters);
        setUserViewFilters(filters);
        let showMine = filters.showMyProjects;
        let userViewColumns = userView.attributes.columns;
        setUserViewColumns(userViewColumns);
        setAllColumns(userViewColumns);
        setAvailableColumns(
          userViewColumns.filter((column) => column.selected == false)
        );
        setQueryParams({
          sort: setSortFromColumns(userViewColumns),
          ...userView.attributes.filters,
          itemsPerPage: userView.attributes["page-size"],
          showMyProjects: showMine,
        });
        setShowMyProjectsOnly(showMine);
        URL.queryParams.filter["my-projects"] = showMine ? "true" : "";

        API.Get(URL.buildUrl(), authorizationCode).then((res) => {
          let resData = res.data;
          let data = resData.data;
          setStateFromProjectsData(resData, firstPage);
          setProjects(data);
          setProjectsPerPage(data);
          setIsLoading(false);
          setActiveTabRecordCount(resData.meta["record-count"]);
        });
      });

      API.Get(`${apiHost}/v1/me`, authorizationCode).then((res) => {
        const privilege = res.data.data.attributes.privileges.find(
          (p) => p.privilege === "projects.duplicate"
        );

        if (
          privilege["privilege"] == "projects.duplicate" &&
          privilege["access-level"] === "manage"
        ) {
          setDuplicatePrivilege(true);
        }
      });
    }
  }, [
    authorizationCode,
    fullClientList,
    fullSalesExecsList,
    fullPresalesEngineersList,
    fullTeamsList,
    fullTeammateList,
    fullTagsList,
  ]);

  const closeContextMenu = () => {
    if (showContextMenu) {
      setShowContextMenu(false);
      setProjectsPerPage(
        projectsPerPage.map((proj) => {
          proj.checked = false;
          return proj;
        })
      );
      setCheckedProjects([]);
    }
  };

  let userViewData = {
    data: {
      id: userViewId,
      type: "user-views",
      attributes: {
        columns: headerColumns,
        filters: { showMyProjects: showMyProjectsOnly },
        "page-size": itemsPerPage,
      },
    },
  };

  const updateUserView = (userViewData) => {
    setIsTableLoading(true);
    return API.Patch(
      `${apiHost}/${account_slug}/v1/user-views/${userViewId}`,
      userViewData,
      authorizationCode
    )
      .then((res) => {
        let userView = res.data.data;
        setStateFromUserView(userView);
        setQueryParams({
          ...userView.attributes.filters,
          sort: setSortFromColumns(userView.attributes.columns),
          itemsPerPage: userView.attributes["page-size"],
          currentPage: 1,
        });
        API.Get(URL.buildUrl(), authorizationCode).then((projectRes) => {
          setProjectsPerPage(projectRes.data.data);
          setStateFromProjectsData(projectRes.data, 1);
          setIsTableLoading(false);
          const recordCount = projectRes.data.meta["record-count"];
          if (isActiveTabSelected) {
            setActiveTabRecordCount(recordCount);
          } else {
            setArchiveTabRecordCount(recordCount);
          }
        });
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "User View"));
        setShowFailAlert(true);
      });
  };

  const titileizeString = (source) => {
    if (source.includes("_")) {
      source = splitPascalCase(snakeToPascalCase(source));
    } else {
      source = capitalizeFirstLetter(source);
    }
    return source;
  };

  const getAttributeValue = (column, project, options) => {
    // data-path looks like attributes.variables.some-thing
    // Bail if we have a data path that does not begin with attributes
    let path = column.data_path.split(".");
    if (path[0] != "attributes") {
      return null;
    }

    let source = project;
    path.forEach((segment) => {
      if (typeof source !== "object") {
        source = {};
      }
      if (source === null) {
        // No-op
      } else if (source.hasOwnProperty(segment)) {
        source = source[segment];
      } else {
        source = {};
      }
      if (segment == "project-variables") {
        source = source.reduce((obj, item) => {
          obj[item.name] = item;
          return obj;
        }, {});
      }
    });
    if (source === null) {
      return null;
    }

    if (column.data_type == "string") {
      if (column.format == "titleize") {
        source = titileizeString(source);
      }
      return source;
    }

    if (column.data_type == "date") {
      if (source === null || source === "") {
        return null;
      } else {
        return new Date(source).toLocaleDateString("en-US", options);
      }
    }

    if (column.data_type == "decimal" && column.format == "decimal") {
      if (typeof source !== "object") {
        return source;
      }
    }

    if (column.data_type == "decimal" && column.format == "percent") {
      return source
        ? (100 * parseFloat(source)).toFixed(2) + "%"
        : parseFloat(0).toFixed(2) + "%";
    }

    if (column.data_type == "decimal" && column.format == "currency") {
      let val = source
        ? parseFloat(source).toFixed(2)
        : parseFloat(0).toFixed(2);
      let currencyUnit = project.attributes["field-labels"].currency_unit;
      return (
        <CurrencyFormat
          displayType="text"
          prefix={currencyUnit}
          isNumericString={true}
          thousandSeparator={true}
          value={val}
        />
      );
    }
  };

  const getRelationshipValue = (column, project) => {
    // data-path looks like attributes.variables.some-thing
    // Bail if we have a data path that does not begin with attributes
    let path = column.data_path.split(".");
    if (path.shift() != "relationships") {
      return null;
    }

    let collection = [];
    let relationship = path.shift();
    switch (relationship) {
      case "client":
        collection = clients;
        break;
      case "sales-executive":
        collection = salesExecs;
        break;
      case "presales-engineer":
        collection = presalesEngineers;
        break;
    }

    let record = collection.filter((item) => {
      let relationshipData = project.relationships[relationship].data;
      return relationshipData ? item.id == relationshipData.id : false;
    });
    return record.length > 0 ? record[0].attributes[path] : "N/A";
  };

  const getAttribute = (headerColumn, project) => {
    var attribute = { class: `col-${headerColumn.width}`, name: "" };
    let projectAttributes = project.attributes;

    const options = { month: "long", day: "numeric", year: "numeric" };

    if (headerColumn.data_path.match(/^attributes\./)) {
      attribute.name = getAttributeValue(headerColumn, project, options);
      if (headerColumn.data_type == "decimal") {
        attribute.class += " text-right";
      }
    } else {
      attribute.name = getRelationshipValue(headerColumn, project);
    }
    return attribute;
  };

  const getTableColumns = (project) => {
    return headerColumns.map((column, index) => {
      if (column.label == "Project Name") {
        return {
          class: "bold",
          name: (
            <span
              className="flexCenter"
              style={{
                marginLeft: "20px",
              }}
            >
              <input
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  e.stopPropagation();
                  project.checked = !project.checked;
                  const copyOfCheckedProjects = [...checkedProjects];
                  const projectClicked = copyOfCheckedProjects.find(
                    (proj) => proj.id === project.id
                  );
                  if (checkedProjects.length == 1 && projectClicked) {
                    setCheckedProjects([]);
                  } else if (checkedProjects.length > 1 && projectClicked) {
                    const checkedProjectIndex =
                      checkedProjects.indexOf(project);
                    copyOfCheckedProjects.splice(checkedProjectIndex, 1);
                    setCheckedProjects(copyOfCheckedProjects);
                  } else {
                    setCheckedProjects([...checkedProjects, project]);
                  }
                }}
                type="checkbox"
                className="form-check-input"
                checked={project.checked}
              ></input>
              <span style={{ marginLeft: "5px" }}>
                {project.attributes["project-name"]}
              </span>
            </span>
          ),
        };
      } else {
        return getAttribute(column, project);
      }
    });
  };

  const duplicateProject = () => {
    closeContextMenu();
    if (window.confirm("Are you user you want to duplicate this project?")) {
      let project = {
        data: {
          type: "projects",
          id: selectedProjectId,
        },
      };
      API.Post(
        `${apiHost}/${account_slug}/v1/projects/${selectedProjectId}/duplicate`,
        project,
        authorizationCode
      )
        .then((response) => {
          if (response.status == 200) {
            window.location.reload();
          }
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "project"));
          setShowFailAlert(true);
        });
    }
  };

  const deleteProject = () => {
    setIsTableLoading(true);
    setShowArchiveModal(false);
    let projectIdsToDelete = [];
    if (selectedProjectId !== null) {
      projectIdsToDelete.push(selectedProjectId);
    } else {
      projectIdsToDelete = checkedProjects.map((project) => project.id);
    }
    let promises = [];
    projectIdsToDelete.forEach((projectId) => {
      let project = {
        data: {
          type: "projects",
          id: projectId,
        },
      };
      promises.push(
        API.Delete(
          `${apiHost}/${account_slug}/v1/projects/${projectId}`,
          project,
          authorizationCode
        )
      );
    });
    closeContextMenu();
    Promise.all(promises)
      .then((responses) => {
        setIsTableLoading(false);
        let allSuccessful = true;
        responses.forEach((res) => {
          if (res.status !== 204) {
            allSuccessful = false;
          }
        });
        if (allSuccessful) {
          getTabView(true);
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "project"));
        setShowFailAlert(true);
      });
  };

  const getTableData = () => {
    if (projectsPerPage.length == 0) {
      return [];
    }
    // Map over projects & return data for ScopeStackTable
    return projectsPerPage.map((project, index) => {
      const projectId = project.id;

      // Render icon buttons under actions on hover if the project status is: building
      const renderActions = () => {
        return (
          <div className="actionsDiv">
            <OnHoverButtonIcon
              className="actions"
              iconClass="fa fa-remove"
              dataConfirm="Are you sure?"
              buttonText="Archive"
              onClick={() => setShowArchiveModal(true)}
            />
          </div>
        );
      };

      const redirectToEditProject = () => {
        navigate(`/projects`);
        return window.location.replace(`${appHost}/projects/${projectId}/edit`);
      };

      const openEditProject = () => {
        return window.open(
          `${appHost}/projects/${projectId}/edit`,
          "_blank",
          "noreferrer,noopener"
        );
      };

      // Map returns a table row for each Project
      return {
        rowClass: "projectsRow",
        onClick: () => redirectToEditProject(),
        onContextMenu: (evt) => {
          evt.preventDefault();
          setCheckedProjects(
            checkedProjects.find((proj) => proj.id == project.id) || []
          );
          setProjectsPerPage(
            projectsPerPage.map((proj) => {
              if (proj.id === project.id) {
                proj.checked = true;
              } else {
                proj.checked = false;
              }
              return proj;
            })
          );
          setContextMenuX(evt.pageX);
          setContextMenuY(evt.pageY);
          setSelectedProjectId(project.id);
          setShowContextMenu(true);
        },
        columns: getTableColumns(project),
        actions: renderActions(),
      };
    });
  };

  const getSortValue = (column) => {
    if (column.sortable == false) return "created-at";

    let sortValue = column.data_path.substring(
      column.data_path.indexOf(".") + 1
    );
    return sortValue;
  };

  const getIconClass = (sort) => {
    if (sort == "desc") {
      return faSortDown;
    }
    if (sort == "asc") {
      return faSortUp;
    }
    if (sort == "false") {
      return faSort;
    }
  };

  const headerData = {
    rowClass: "",
    columns: headerColumns.map((column) => {
      // let sort = column.sorted;

      const handleIconChange = (sort) => {
        if (sort == "desc") {
          return faSortUp;
        }
        if (sort == "asc") {
          return faSortDown;
        }
        if (sort == "false") {
          return faSortDown;
        }
      };

      function changeSortColumn(sortColumnId, columns) {
        let sortColumn = columns.filter(
          (column) => column.label == sortColumnId
        );
        if (!sortColumn[0] || !sortColumn[0].sortable) {
          return columns;
        }

        return columns.map((column) =>
          column.label == sortColumnId
            ? {
                ...column,
                sorted:
                  handleIconChange(column.sorted) == faSortDown
                    ? "desc"
                    : handleIconChange(column.sorted) == faSortUp
                    ? "asc"
                    : "false",
              }
            : {
                ...column,
                sorted: "false",
              }
        );
      }

      const sortByColumn = (e) => {
        e.persist();
        let updatedHeaderColumns = changeSortColumn(e.target.id, headerColumns);
        setHeaderColumns(updatedHeaderColumns);
        setQueryParams({
          currentPage: 1,
          sort: sort,
          showMyProjects: showMyProjectsOnly,
        });
        userViewData.data.attributes.columns =
          updatedHeaderColumns.concat(availableColumns);
        userViewData.data.attributes.filters = userViewFilters;
        updateUserView(userViewData);
      };

      let headerColumn = {
        class:
          "cursorPoint" + (column.data_type == "decimal" ? " text-right" : ""),
        id: column.label,
        name: (
          <div
            onClick={(e) => {
              if (column.sortable) {
                sortByColumn(e);
              }
            }}
            id={column.label}
          >
            {column.label}&nbsp;&nbsp;
            {column.sortable ? (
              <FontAwesomeIcon
                id={column.label}
                style={{ fontSize: "14px" }}
                icon={getIconClass(column.sorted)}
              />
            ) : null}
          </div>
        ),
      };

      if (column.label == "Revenue" || column.label == "Blended Margin") {
        headerColumn.class += " text-right";
      }

      return headerColumn;
    }),
  };

  const setQueryParams = (config) => {
    let keys = Object.keys(config);
    URL.queryParams.page.size = keys.includes("itemsPerPage")
      ? config.itemsPerPage
      : itemsPerPage;
    URL.queryParams.page.number = keys.includes("currentPage")
      ? config.currentPage
      : currentPage;
    URL.queryParams.sort = keys.includes("sort") ? config.sort : sort;
    if (keys.includes("showMyProjects")) {
      URL.queryParams.filter["my-projects"] = config.showMyProjects
        ? config.showMyProjects
        : "";
    }
    URL.queryParams.filter["project-name"] = keys.includes("projectName")
      ? config.projectName
      : projectName;
    URL.queryParams.filter["client.id"] = keys.includes("clients")
      ? config.clients.join(",")
      : selectedClients.join(",");
    URL.queryParams.filter["sales-executive.id"] = keys.includes("salesExecs")
      ? config.salesExecs.join(",")
      : selectedSalesExecs.join(",");
    URL.queryParams.filter["presales-engineer.id"] = keys.includes(
      "presalesEngineers"
    )
      ? config.presalesEngineers.join(",")
      : selectedEngineers.join(",");
    URL.queryParams.filter["status"] = keys.includes("status")
      ? config.status.join(",")
      : selectedStatus.join(",");
    URL.queryParams.filter["team.id"] = keys.includes("teams")
      ? config.teams.join(",")
      : selectedTeams.join(",");
    URL.queryParams.filter["collaborator.id"] = keys.includes("teammates")
      ? config.teammates.join(",")
      : selectedTeammates.join(",");
    URL.queryParams.filter["tag_list"] = keys.includes("tags")
      ? config.tags.join(",")
      : selectedTags.join(",");
    URL.queryParams.filter["updated-at.after"] = keys.includes(
      "dateEditedStart"
    )
      ? config.dateEditedStart
      : selectedEditStartDate;
    URL.queryParams.filter["updated-at.before"] = keys.includes("dateEditedEnd")
      ? config.dateEditedEnd
      : selectedEditEndDate;
    URL.queryParams.filter["created-at.after"] = keys.includes(
      "dateCreatedStart"
    )
      ? config.dateCreatedStart
      : selectedCreatedStartDate;
    URL.queryParams.filter["created-at.before"] = keys.includes(
      "dateCreatedEnd"
    )
      ? config.dateCreatedEnd
      : selectedCreatedEndDate;
    URL.queryParams.filter["active"] = keys.includes("active")
      ? config.active
      : isActiveTabSelected;
  };

  const extractIncludedResources = (resData, resourceType) => {
    if (!resData.included) {
      return [];
    }
    return resData.included.filter((item) => item.type == resourceType);
  };
  const buildPageArrayFromProjectsData = (resData) => {
    let pageCount = parseInt(resData.meta["page-count"]);
    setPageCount(pageCount);
    let fullPageArray = [];
    for (let i = 0; i < parseInt(pageCount); i++) {
      fullPageArray.push(i + 1);
    }
    setFullPageArray(fullPageArray);
    return fullPageArray;
  };

  const setStateFromProjectsData = (resData, firstPage) => {
    setClients(extractIncludedResources(resData, "clients"));
    setPresalesEngineers(
      extractIncludedResources(resData, "presales-engineers")
    );
    setSalesExecs(extractIncludedResources(resData, "sales-executives"));
    setTags(extractIncludedResources(resData, "tags"));

    let fullPageArray = buildPageArrayFromProjectsData(resData);
    let newLastPage = lastPage;
    if (firstPage == 1) {
      if (parseInt(resData.meta["page-count"]) > 5) newLastPage = 5;
      setPageArray(fullPageArray.slice(0, newLastPage));
    } else {
      setPageArray(fullPageArray.slice(firstPage - 1, newLastPage));
    }
  };

  const getPage = (itemsPerPage, currentPage) => {
    setQueryParams({
      itemsPerPage: itemsPerPage,
      currentPage: currentPage,
      showMyProjects: showMyProjectsOnly,
    });
    return API.Get(URL.buildUrl(), authorizationCode);
  };
  const getFooterData = () => {
    let content = (
      <Row style={{ color: "#2f3a7c", alignItems: "center" }}>
        <Col sm={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>Showing</div>
            <div>
              <ControlledDropDown
                value={itemsPerPage}
                onChange={(e) => {
                  let itemsPerPage = e.target.value;
                  setIsTableLoading(true);
                  userViewData.data.attributes.filters = userViewFilters;
                  userViewData.data.attributes["page-size"] = itemsPerPage;
                  setQueryParams({ showMyProjectsOnly: showMyProjectsOnly });
                  updateUserView(userViewData);

                  setItemsPerPage(itemsPerPage);
                  let currentPage = 1;
                  setCurrentPage(currentPage);
                }}
                options={[
                  <option key={10}>10</option>,
                  <option key={20}>20</option>,
                  <option key={50}>50</option>,
                ]}
              />
            </div>
          </div>
        </Col>
        <Col sm={8}>
          <PaginationNumbersAPI
            pageCount={pageCount}
            pageArray={Array.from(Array(pageCount), (_, i) => i + 1)}
            currentPage={currentPage}
            setCurrentPage={(page) => setCurrentPage(page)}
            leftArrowOnClick={() => {
              if (currentPage > 1) {
                let page = currentPage - 1;
                setCurrentPage(page);
                setIsTableLoading(true);
                getPage(itemsPerPage, page).then((res) => {
                  setStateFromProjectsData(res.data, firstPage);
                  setProjectsPerPage(res.data.data);
                  setIsTableLoading(false);
                });
              }
              if (currentPage == firstPage && firstPage !== 1) {
                let newLastPage = currentPage - 1;
                let newFirstPage = newLastPage - 4;
                setLastPage(newLastPage);
                setFirstPage(newFirstPage);
                let page = currentPage - 1;
                setCurrentPage(page);
                setIsTableLoading(true);
                getPage(itemsPerPage, page).then((res) => {
                  let resData = res.data;
                  setProjectsPerPage(resData.data);
                  let fullPageArray = buildPageArrayFromProjectsData(resData);
                  if (newFirstPage == 1) {
                    setPageArray(fullPageArray.slice(0, newLastPage));
                  } else {
                    setPageArray(
                      fullPageArray.slice(newFirstPage - 1, newLastPage)
                    );
                  }
                  setPageCount(resData.meta["page-count"]);
                  setIsTableLoading(false);
                });
              }
            }}
            rightArrowOnClick={() => {
              if (currentPage < pageCount) {
                let page = currentPage + 1;
                setCurrentPage(page);
                setIsTableLoading(true);
                getPage(itemsPerPage, page).then((res) => {
                  setStateFromProjectsData(res.data, firstPage);
                  setProjectsPerPage(res.data.data);
                  setIsTableLoading(false);
                });
              }
              if (currentPage == lastPage && lastPage < pageCount) {
                let newFirstPage = currentPage + 1;
                let newLastPage = newFirstPage + 4;
                setLastPage(newLastPage);
                setFirstPage(newFirstPage);
                let page = currentPage + 1;
                setCurrentPage(page);
                setIsTableLoading(true);
                getPage(itemsPerPage, page).then((res) => {
                  let resData = res.data;
                  setProjectsPerPage(resData.data);
                  let fullPageArray = buildPageArrayFromProjectsData(resData);
                  if (newFirstPage == 1) {
                    setPageArray(fullPageArray.slice(0, newLastPage));
                  } else {
                    setPageArray(
                      fullPageArray.slice(newFirstPage - 1, newLastPage)
                    );
                  }
                  setPageCount(resData.meta["page-count"]);
                  setIsTableLoading(false);
                });
              }
            }}
            itemsPerPage={itemsPerPage}
            onNumberClick={(itemsPerPage, currentPage) => {
              setIsTableLoading(true);
              setCurrentPage(currentPage);
              getPage(itemsPerPage, currentPage).then((res) => {
                setStateFromProjectsData(res.data, firstPage);
                setProjectsPerPage(res.data.data);
                setIsTableLoading(false);
              });
            }}
          />
        </Col>
        <Col sm={2}>
          <div
            style={{ textAlign: "right" }}
          >{`Viewing ${currentPage} of ${pageCount} `}</div>
        </Col>
      </Row>
    );

    return {
      rowClass: "col-12",
      columns: [
        { class: "projectsListFooter", content: content, colSpan: "12" },
      ],
    };
  };

  const tableData = getTableData();
  const footerData = getFooterData();

  let projectsTable = new ScopeStackTable(
    "projects",
    headerData,
    tableData,
    footerData
  );

  const searchProjectName = (projectName) => {
    setIsTableLoading(true);
    setProjectName(projectName);
    setCurrentPage(1);
    setQueryParams({ projectName: projectName, sort: sort, currentPage: 1 });
    API.Get(URL.buildUrl(), authorizationCode).then((res) => {
      let resData = res.data;
      setStateFromProjectsData(resData, firstPage);
      setProjectsPerPage(resData.data);
      setIsTableLoading(false);
    });
  };

  const handleProjectSearch = (e) => {
    e.preventDefault();
    searchProjectName(projectName);
  };

  const handleCancel = () => {
    // Reset displayed states for filters
    setDisplayedClients(filteredClientsData);
    setDisplayedSalesExecs(filteredSalesData);
    setDisplayedEngineers(filteredPresalesData);
    setDisplayedStatus(filteredStatusData);
    setDisplayedTeams(filteredTeamsData);
    setDisplayedTeammates(filteredTeammateData);
    setDisplayedTags(filteredTagsData);
    setDisplayedEditStartDate(filteredEditStartDate);
    setDisplayedEditEndDate(filteredEditEndDate);
    setDisplayedCreatedStartDate(filteredCreatedStartDate);
    setDisplayedCreatedEndDate(filteredCreatedEndDate);

    setDateEditedStart(
      filteredEditStartDate.length !== 0
        ? formatDateForField(
            filteredEditStartDate.map((date) => date.label).join()
          )
        : ""
    );
    setDateEditedEnd(
      filteredEditEndDate.length !== 0
        ? formatDateForField(
            filteredEditEndDate.map((date) => date.label).join()
          )
        : ""
    );
    setDateCreatedStart(
      filteredCreatedStartDate.length !== 0
        ? formatDateForField(
            filteredCreatedStartDate.map((date) => date.label).join()
          )
        : ""
    );
    setDateCreatedEnd(
      formatDateForField(
        filteredCreatedEndDate.length !== 0
          ? filteredCreatedEndDate.map((date) => date.label).join()
          : ""
      )
    );

    // Reset Status Checks to match what it previously was before the user changed and hit cancel
    let resetStatusChecks = [];
    statusChecks.forEach((statusCheck) => {
      filteredStatusData.forEach((status) => {
        if (status.label == statusCheck.label) {
          statusCheck.checked = true;
          resetStatusChecks.push(statusCheck);
        } else {
          statusCheck.checked = false;
          resetStatusChecks.push(statusCheck);
        }
      });
    });
    setStatusChecks(resetStatusChecks);
  };

  const getFilterData = (filterType) => {
    let filterData = [];
    if (filterType == "Client") {
      filterData = filteredClientsData;
    }
    if (filterType == "Sales Exec") {
      filterData = filteredSalesData;
    }
    if (filterType == "Pre-Sales Eng") {
      filterData = filteredPresalesData;
    }
    if (filterType == "Status") {
      filterData = filteredStatusData;
    }
    if (filterType == "Teams") {
      filterData = filteredTeamsData;
    }
    if (filterType == "Teammates") {
      filterData = filteredTeammateData;
    }
    if (filterType == "Tags") {
      filterData = filteredTagsData;
    }
    if (filterType == "Last Edited After") {
      filterData = filteredEditStartDate;
    }
    if (filterType == "Last Edited Before") {
      filterData = filteredEditEndDate;
    }
    if (filterType == "Created After") {
      filterData = filteredCreatedStartDate;
    }
    if (filterType == "Created Before") {
      filterData = filteredCreatedEndDate;
    }
    return filterData;
  };

  const handleSettingFilterList = (filters) => {
    let filterList = [];

    setQueryParamsFromFilters({ ...filters, currentPage: 1 });

    let info = getResourceFilters(filters, "clients", clientOptions);
    if (info.resourceFilter.length > 0) filterList.push("Client");
    setDisplayedClients(info.displayed);
    setFilteredClientsData(info.displayed);

    info = getResourceFilters(filters, "salesExecs", salesOptions);
    if (info.resourceFilter.length > 0) filterList.push("Sales Exec");
    setDisplayedSalesExecs(info.displayed);
    setFilteredSalesData(info.displayed);

    info = getResourceFilters(filters, "presalesEngineers", presalesOptions);
    if (info.resourceFilter.length !== 0) filterList.push("Pre-Sales Eng");
    setDisplayedEngineers(info.displayed);
    setFilteredPresalesData(info.displayed);

    let statusFilter = getFilter(filters, "status", []);
    if (statusFilter.length !== 0) filterList.push("Status");
    let displayedStatus = statusFilter.map((status) => {
      return { label: titileizeString(status), value: true };
    });
    setDisplayedStatus(displayedStatus);
    setFilteredStatusData(displayedStatus);

    info = getResourceFilters(filters, "teams", teamsOptions);
    if (info.resourceFilter.length !== 0) filterList.push("Teams");
    setDisplayedTeams(info.displayed);
    setFilteredTeamsData(info.displayed);

    info = getResourceFilters(filters, "teammates", teammateOptions);
    if (info.resourceFilter.length !== 0) filterList.push("Teammates");
    setDisplayedTeammates(info.displayed);
    setFilteredTeammateData(info.displayed);

    info = getResourceFilters(filters, "tagIds", tagsOptions);
    if (info.resourceFilter.length !== 0) filterList.push("Tags");
    setDisplayedTags(info.displayed);
    setFilteredTagsData(info.displayed);

    if (statusFilter.length !== 0) {
      let statusState = [];
      statusChecks.forEach((status) => {
        displayedStatus.forEach((displayedStatus) => {
          if (displayedStatus.label == status.label) {
            status.checked = true;
            statusState.push(status);
          } else {
            statusState.push(status);
          }
        });
      });
      statusState = removeDuplicates(statusState, (item) => item.label);
      setStatusChecks(statusState);
    }

    let dateEditedStartFilter = getFilter(filters, "dateEditedStart", "");
    if (dateEditedStartFilter != "") filterList.push("Last Edited After");
    setDateEditedStart(dateEditedStartFilter);
    setDisplayedEditStartDate(getDateDisplayObjects(dateEditedStartFilter));
    setFilteredEditStartDate(getDateDisplayObjects(dateEditedStartFilter));

    let dateEditedEndFilter = getFilter(filters, "dateEditedEnd", "");
    if (dateEditedEndFilter != "") filterList.push("Last Edited Before");
    setDateEditedEnd(dateEditedEndFilter);
    setDisplayedEditEndDate(getDateDisplayObjects(dateEditedStartFilter));
    setFilteredEditEndDate(getDateDisplayObjects(dateEditedStartFilter));

    let dateCreatedStartFilter = getFilter(filters, "dateCreatedStart", "");
    if (dateCreatedStartFilter != "") filterList.push("Created After");
    setDateCreatedStart(dateCreatedStartFilter);
    setDisplayedCreatedStartDate(getDateDisplayObjects(dateCreatedStartFilter));
    setFilteredCreatedStartDate(getDateDisplayObjects(dateCreatedStartFilter));

    let dateCreatedEndFilter = getFilter(filters, "dateCreatedEnd", "");
    if (dateCreatedEndFilter != "") filterList.push("Created Before");
    setDateCreatedEnd(dateCreatedEndFilter);
    setDisplayedCreatedEndDate(getDateDisplayObjects(dateCreatedEndFilter));
    setFilteredCreatedEndDate(getDateDisplayObjects(dateCreatedEndFilter));

    if (filterList.length > 0) {
      setHasFilters(true);
    } else {
      setHasFilters(false);
    }
    setFilterList(filterList);
  };

  const handleApplyFilters = (
    selectedClients,
    selectedSalesExecs,
    selectedEngineers,
    selectedStatus,
    selectedTeams,
    selectedTeammates,
    selectedTagIds,
    selectedTags,
    dateEditedStart,
    dateEditedEnd,
    dateCreatedStart,
    dateCreatedEnd
  ) => {
    setShow(false);
    setCurrentPage(1);

    let filters = {
      showMyProjects: showMyProjectsOnly,
      clients: selectedClients,
      salesExecs: selectedSalesExecs,
      presalesEngineers: selectedEngineers,
      status: selectedStatus,
      teams: selectedTeams,
      teammates: selectedTeammates,
      tags: selectedTags,
      tagIds: selectedTagIds,
      dateEditedStart: dateEditedStart,
      dateEditedEnd: dateEditedEnd,
      dateCreatedStart: dateCreatedStart,
      dateCreatedEnd: dateCreatedEnd,
    };

    userViewData.data.attributes.columns = allColumns;
    setQueryParams({ ...filters, currentPage: 1 });
    userViewData.data.attributes.filters = filters;
    setUserViewFilters(filters);
    updateUserView(userViewData);
  };

  //Slide out menu functions
  const clearAllFieldsOnSlideOut = () => {
    let userConfirmsClear = confirm(
      "Are you sure you want to clear all applied filters?"
    );

    // If user confirms
    if (userConfirmsClear) {
      let selectedClients = [];
      let selectedSalesExecs = [];
      let selectedEngineers = [];
      let selectedStatus = [];
      let selectedTeams = [];
      let selectedTeammates = [];
      let selectedTags = [];
      let selectedTagIds = [];
      let dateEditedStart = "";
      let dateEditedEnd = "";
      let dateCreatedStart = "";
      let dateCreatedEnd = "";
      setSelectedClients(selectedClients);
      setSelectedSalesExecs(selectedSalesExecs);
      setSelectedEngineers(selectedEngineers);
      setSelectedStatus(selectedStatus);
      setSelectedTeams(selectedTeams);
      setSelectedTeammates(selectedTeammates);
      setSelectedTags(selectedTags);
      setSelectedTagIds(selectedTagIds);
      setSelectedEditStartDate(dateEditedStart);
      setSelectedEditEndDate(dateEditedEnd);
      setSelectedCreatedStartDate(dateCreatedStart);
      setSelectedCreatedEndDate(dateCreatedEnd);
      setDisplayedClients([]);
      setDisplayedSalesExecs([]);
      setDisplayedEngineers([]);
      setDisplayedStatus([]);
      setDisplayedTeams([]);
      setDisplayedTeammates([]);
      setDisplayedTags([]);
      setDisplayedEditStartDate([]);
      setDisplayedEditEndDate([]);
      setDisplayedCreatedStartDate([]);
      setDisplayedCreatedEndDate([]);

      setDateEditedStart("");
      setDateEditedEnd("");
      setDateCreatedStart("");
      setDateCreatedEnd("");

      // Reset slide-out menu form states
      let newStatusChecks = statusChecks.map((status) => {
        return { ...status, checked: false };
      });
      setStatusChecks(newStatusChecks);

      // Reset userView data & call handleApplyFilters
      setHasFilters(false);
      userViewData.data.attributes.filters = {
        showmyProjects: showMyProjectsOnly,
      };
      setUserViewFilters({ showMyProjects: showMyProjectsOnly });
      URL.queryParams.filter["my-projects"] = showMyProjectsOnly ? "true" : "";
      handleApplyFilters(
        selectedClients,
        selectedSalesExecs,
        selectedEngineers,
        selectedStatus,
        selectedTeams,
        selectedTeammates,
        selectedTagIds,
        selectedTags,
        dateEditedStart,
        dateEditedEnd,
        dateCreatedStart,
        dateCreatedEnd
      );
      updateUserView(userViewData);
    }
  };

  // Filter slide-out menu sections
  const projectInfoSection = {
    sectionTitle: "Project Info",
    sectionClass: "projectInfoSection",
    sectionContent: [
      <MultiSelectAutocomplete
        className="slideOutMultiSelect"
        key="client"
        label="Client Name"
        width="500px"
        options={searchedClientOptions ? searchedClientOptions : clientOptions}
        value={displayedClients}
        onChange={(e) => {
          setDisplayedClients(e);
          let ids = e.map((item) => item.value);
          setSelectedClients(ids);
        }}
        onInputChange={(e) => {
          API.Get(
            `${apiHost}/${account_slug}/v1/clients?filter[active]=true&filter[name]=${encodeURIComponent(
              e
            )}`,
            authorizationCode
          ).then((res) => {
            setSearchedClientOptions(buildOptionsArray(res.data.data));
          });
        }}
      />,
      <MultiSelectAutocomplete
        className="slideOutMultiSelect"
        key="salesExec"
        label="Sales Executive"
        width="500px"
        options={salesOptions}
        value={displayedSalesExecs}
        onChange={(e) => {
          setDisplayedSalesExecs(e);
          let ids = e.map((item) => item.value);
          setSelectedSalesExecs(ids);
        }}
      />,
      <MultiSelectAutocomplete
        className="slideOutMultiSelect"
        key="presalesEngineer"
        label="Pre-Sales Engineer"
        width="500px"
        options={presalesOptions}
        value={displayedEngineers}
        onChange={(e) => {
          setDisplayedEngineers(e);
          let ids = e.map((item) => item.value);
          setSelectedEngineers(ids);
        }}
      />,
      teamsOptions.length !== 0 ? (
        <MultiSelectAutocomplete
          className="slideOutMultiSelect"
          key="teams"
          label="Teams"
          width="500px"
          options={teamsOptions}
          value={displayedTeams}
          onChange={(e) => {
            setDisplayedTeams(e);
            let ids = e.map((item) => item.value);
            setSelectedTeams(ids);
          }}
        />
      ) : null,
      <MultiSelectAutocomplete
        className="slideOutMultiSelect"
        key="teammates"
        label="Teammates"
        width="500px"
        options={teammateOptions}
        value={displayedTeammates}
        onChange={(e) => {
          setDisplayedTeammates(e);
          let ids = e.map((item) => item.value);
          setSelectedTeammates(ids);
        }}
      />,
      <MultiSelectAutocomplete
        className="slideOutMultiSelect"
        key="tags"
        label="Project Tags"
        width="500px"
        options={tagsOptions}
        value={displayedTags}
        onChange={(e) => {
          setDisplayedTags(e);
          let tagNames = e.map((item) => item.label);
          setSelectedTags(tagNames);
          setSelectedTagIds(e.map((item) => item.value));
        }}
      />,
    ],
  };

  const statusSection = {
    sectionTitle: "Status",
    sectionClass: "statusSection",
    sectionContent: (
      <Form style={{ margin: "8px" }}>
        {statusChecks.map((status) => (
          <FormCheck
            // custom={true}
            className="statusCheckboxes"
            inline
            key={status.label}
            label={status.label}
            name="group1"
            type="checkbox"
            checked={status.checked}
            id={status.label}
            onChange={(e) => {
              let newStatusChecks = statusChecks.map((status) =>
                status.label == e.target.id
                  ? {
                      ...status,
                      checked: !status.checked,
                    }
                  : status
              );
              setStatusChecks(newStatusChecks);
              let filteredStatusChecks = newStatusChecks.filter(
                (status) => status.checked == true
              );
              let selectedStatusArray = filteredStatusChecks.map((status) => {
                if (status.label.includes(" ")) {
                  return toSnakeCase(status.label.toLowerCase());
                } else {
                  return status.label.toLowerCase();
                }
              });
              let displayedStatus = filteredStatusChecks.map((status) => {
                return {
                  label: status.label,
                  value: true,
                };
              });
              setDisplayedStatus(displayedStatus);
              setSelectedStatus(selectedStatusArray);
            }}
          />
        ))}
      </Form>
    ),
  };

  const dateEditedSection = {
    sectionTitle: "Date Last Edited",
    sectionClass: "dateEditedSection",
    sectionContent: (
      <Form>
        <Row>
          <Col className="startDate">
            <FormDateField
              label="Start Date"
              value={dateEditedStart}
              onChange={(e) => {
                let startDate = e.target.value;
                setDateEditedStart(startDate);
                setSelectedEditStartDate(startDate);
                setDisplayedEditStartDate(
                  stringToArray(startDate).map((date) => {
                    return { label: getDate(date) };
                  })
                );
              }}
            />
          </Col>
          <div className="to">To</div>
          <Col className="endDate">
            <FormDateField
              label="End Date"
              value={dateEditedEnd}
              onChange={(e) => {
                let endDate = e.target.value;
                setDateEditedEnd(endDate);
                setSelectedEditEndDate(endDate);
                setDisplayedEditEndDate(
                  stringToArray(endDate).map((date) => {
                    return { label: getDate(date) };
                  })
                );
              }}
            />
          </Col>
        </Row>
      </Form>
    ),
  };

  const dateCreatedSection = {
    sectionTitle: "Date Created",
    sectionClass: "dateCreatedSection",
    sectionContent: (
      <Form>
        <Row>
          <Col className="startDate">
            <FormDateField
              label="Start Date"
              value={dateCreatedStart}
              onChange={(e) => {
                let startDate = e.target.value;
                setDateCreatedStart(startDate);
                setSelectedCreatedStartDate(startDate);
                setDisplayedCreatedStartDate(
                  stringToArray(startDate).map((date) => {
                    return { label: getDate(date) };
                  })
                );
              }}
            />
          </Col>
          <div className="to">To</div>
          <Col className="endDate">
            <FormDateField
              label="End Date"
              value={dateCreatedEnd}
              onChange={(e) => {
                let endDate = e.target.value;
                setDateCreatedEnd(endDate);
                setSelectedCreatedEndDate(endDate);
                setDisplayedCreatedEndDate(
                  stringToArray(endDate).map((date) => {
                    return { label: getDate(date) };
                  })
                );
              }}
            />
          </Col>
        </Row>
      </Form>
    ),
  };

  const handleColumnSearch = (e) => {
    e.preventDefault();
    setShowNoColumnsMatchSearchAlert(false);
    let value = columnName;
    let columnsDisplayed = [];
    if (value.length === 0) {
      return clearColumnSearch();
    }
    if (value.length > 0) {
      let regexValue = value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
      const regex = new RegExp(regexValue, "i");
      columnsDisplayed = allColumns.sort().filter((v) => regex.test(v.label));
      if (columnsDisplayed.length == 0) {
        setShowNoColumnsMatchSearchAlert(true);
      }
    }
    setSearchedColumns(columnsDisplayed);
  };

  const clearColumnSearch = () => {
    setColumnName("");
    setSearchedColumns([]);
    setShowNoColumnsMatchSearchAlert(false);
  };

  // Column slide-out menu sections
  const searchColumnSection = {
    sectionTitle: "Search Columns",
    sectionClass: "searchColumnSection",
    containerClass: "searchColumnContainer",
    sectionContent: (
      <Form onSubmit={handleColumnSearch}>
        <SearchField
          placeholder="Search by Column Name"
          value={columnName}
          onChange={(e) => {
            let value = e.target.value;
            setColumnName(value);
          }}
          onClick={clearColumnSearch}
        />
      </Form>
    ),
  };

  const handleDragStart = (index) => {
    setDragItem(index);
  };

  const handleDragEnter = (e, index) => {
    e.target.style.opactiy = "0.5";
    let newList = [...allColumns.filter((column) => column.selected == true)];
    const item = newList[dragItem];
    newList.splice(dragItem, 1);
    newList.splice(index, 0, item);

    setDragItem(index);
    newList = newList.map((column) =>
      column.position <= index
        ? { ...column, position: dragItem == 0 ? dragItem + 1 : dragItem }
        : { ...column, position: dragItem - 1 }
    );
    setAllColumns(newList.concat(availableColumns));
  };

  const handleDragLeave = (e) => {
    e.target.style.opactiy = "0.5";
  };

  const handleDrop = (e) => {
    e.target.style.opactiy = "1";
  };

  const selectedColumnsSection = {
    sectionTitle: (
      <Row>
        <Col
          style={
            showMaxColAlert || showNoColumnsMatchSearchAlert
              ? { color: "#fd0300" }
              : {}
          }
          sm={9}
        >
          {showMaxColAlert
            ? "Max Columns Added"
            : showNoColumnsMatchSearchAlert
            ? "No Columns Match Search"
            : "Selected Columns"}
        </Col>
        <Col
          style={
            showMaxColAlert || showNoColumnsMatchSearchAlert
              ? { fontSize: "12px", color: "#fd0300" }
              : { fontSize: "12px" }
          }
          className="pull-right"
        >
          {allColumns
            .filter((column) => column.selected == true)
            .map((column) => column.width)
            .reduce((prev, curr) => prev + curr, 0)}
          /12
        </Col>
      </Row>
    ),
    sectionClass: "selectedColumnsSection",
    containerClass: "selectedColumnsContainer",
    sectionContent: (
      <Table
        style={showNoColumnsMatchSearchAlert ? { display: "none" } : {}}
        className="selectedColumnsTable"
        striped={true}
        bordered={false}
      >
        <tbody>
          {(searchedColumns.length !== 0 ? searchedColumns : allColumns)
            .filter((column) => column.selected == true)
            .sort((a, b) => a.position - b.position)
            .map((column, index) => {
              return (
                <tr
                  draggable={true}
                  onDragStart={() => handleDragStart(index)}
                  onDragEnter={(e) => handleDragEnter(e, index)}
                  onDragLeave={(e) => handleDragLeave(e)}
                  onDrop={(e) => handleDrop(e)}
                  onDragOver={(e) => e.preventDefault()}
                  key={column.label}
                >
                  <td>
                    <Row>
                      <Col sm={11}>
                        <FormCheck
                          // custom={true}
                          id={column.label}
                          checked={column.selected}
                          label={`${column.label} (${column.width})`}
                          disabled={column.label == "Project Name"}
                          onChange={(e) => {
                            const setColumns = (array) => {
                              return array.map((column) =>
                                column.label == e.target.id
                                  ? {
                                      ...column,
                                      selected: e.target.checked,
                                      position: e.target.checked
                                        ? allColumns.filter(
                                            (column) => column.selected == true
                                          ).length + 1
                                        : 0,
                                      sorted: "false",
                                    }
                                  : column
                              );
                            };
                            let updatedColumns = setColumns(allColumns);
                            setAllColumns(updatedColumns);
                            if (searchedColumns.length !== 0) {
                              setSearchedColumns(setColumns(searchedColumns));
                            }
                            let columnWidthSum = updatedColumns
                              .filter((column) => column.selected == true)
                              .map((column) => column.width)
                              .reduce((prev, curr) => prev + curr, 0);
                            if (columnWidthSum < 12) {
                              setShowMaxColAlert(false);
                            }
                          }}
                        />
                      </Col>
                      <Col sm={1}>
                        <FontAwesomeIcon icon={faBars} />
                      </Col>
                    </Row>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    ),
  };

  const availableColumnsSection = {
    sectionTitle: (
      <Row style={showNoColumnsMatchSearchAlert ? { display: "none" } : {}}>
        <Col sm={9}>Available Columns</Col>
      </Row>
    ),
    sectionClass: "availableColumnsSection",
    containerClass: "availableColumnsContainer",
    sectionContent: (
      <Table
        style={showNoColumnsMatchSearchAlert ? { display: "none" } : {}}
        className="availableColumnsTable"
        striped={true}
        bordered={false}
      >
        <tbody>
          {(searchedColumns.length !== 0 ? searchedColumns : allColumns)
            .filter((column) => column.selected == false)
            .sort((a, b) => a.position - b.position)
            .map((column) => {
              return (
                <tr key={column.label}>
                  <td>
                    <FormCheck
                      // custom={true}
                      id={column.label}
                      checked={column.selected}
                      label={`${column.label} (${column.width})`}
                      onChange={(e) => {
                        let columnWidthSum = allColumns
                          .filter((column) => column.selected == true)
                          .map((column) => column.width)
                          .reduce((prev, curr) => prev + curr, 0);
                        if (columnWidthSum >= 12) {
                          setShowMaxColAlert(true);
                        } else {
                          const setColumns = (array) => {
                            return array.map((column) =>
                              column.label == e.target.id
                                ? {
                                    ...column,
                                    selected: e.target.checked,
                                    position: e.target.checked
                                      ? allColumns.filter(
                                          (column) => column.selected == true
                                        ).length + 1
                                      : 0,
                                    sorted: "false",
                                  }
                                : column
                            );
                          };
                          let updatedColumns = setColumns(allColumns);
                          setAllColumns(updatedColumns);
                          if (searchedColumns.length !== 0) {
                            setSearchedColumns(setColumns(searchedColumns));
                          }
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    ),
  };

  // Coluumn slide-out menu functions
  const handleApplyColumns = () => {
    setColumnConfigShow(false);
    let updatedHeaderColumns = allColumns.filter(
      (column) => column.selected == true
    );
    setHeaderColumns(updatedHeaderColumns);
    setAvailableColumns(
      allColumns.filter((column) => column.selected === false)
    );
    userViewData.data.attributes.columns = allColumns;
    userViewData.data.attributes.filters = {
      ...userViewFilters,
      showMyProjectsOnly: showMyProjectsOnly,
    };
    setUserViewColumns(allColumns);
    setSearchedColumns([]);
    setColumnName("");
    updateUserView(userViewData);
  };

  const handleColumnCancel = () => {
    setColumnConfigShow(false);
    clearColumnSearch();
    setAllColumns(userViewColumns);
  };

  const resetTable = () => {
    setColumnConfigShow(false);
    API.Put(
      `${apiHost}/${account_slug}/v1/user-views/${userViewId}/reset`,
      {},
      authorizationCode
    ).then((res) => {
      setIsTableLoading(true);
      setTimeout(() => {
        setStateFromUserView(res.data.data);
        setIsTableLoading(false);
      }, 1000);
    });
  };

  // Alerts
  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  const archiveModalBody = (
    <div>
      <strong>{`${isActiveTabSelected ? "Archive" : "Un-archive"} ${
        selectedProjectId !== null ? "1" : checkedProjects.length
      } Project${
        selectedProjectId !== null || checkedProjects.length === 1 ? "" : "s"
      }?`}</strong>
      <br></br>
      <br />
      <p>
        {isActiveTabSelected
          ? "Archiving projects can be un-done later from the Archived Projects tab on the Projects page."
          : "These projects will be moved to the active projects tab."}
      </p>
    </div>
  );

  const getTabView = (active) => {
    setIsTableLoading(true);
    setCheckedProjects([]);
    setProjectsPerPage(
      projectsPerPage.map((proj) => {
        proj.checked = false;
        return proj;
      })
    );
    setQueryParams({
      sort: setSortFromColumns(userViewColumns),
      active: active,
      showMyProjects: showMyProjectsOnly,
    });
    URL.queryParams.filter["my-projects"] = showMyProjectsOnly ? "true" : "";
    API.Get(URL.buildUrl(), authorizationCode).then((res) => {
      let resData = res.data;
      setStateFromProjectsData(resData, firstPage);
      setProjectsPerPage(resData.data);
      setIsTableLoading(false);
      const recordCount = resData.meta["record-count"];
      if (active) {
        setActiveTabRecordCount(recordCount);
      } else {
        setArchiveTabRecordCount(recordCount);
      }
    });
  };

  const unarchiveProjects = () => {
    setIsTableLoading(true);
    setShowArchiveModal(false);
    let projectIdsToUnarchive = [];
    if (selectedProjectId !== null) {
      projectIdsToUnarchive.push(selectedProjectId);
    } else {
      projectIdsToUnarchive = checkedProjects.map((project) => project.id);
    }
    closeContextMenu();
    projectIdsToUnarchive.forEach((projectId) => {
      let project = {
        data: {
          type: "projects",
          id: projectId,
          attributes: {
            active: true,
          },
        },
      };
      API.Patch(
        `${apiHost}/${account_slug}/v1/projects/${projectId}`,
        project,
        authorizationCode
      )
        .then((response) => {
          if (response.status == 200) {
            setIsActiveTabSelected(true);
            getTabView(true);
            setArchiveTabRecordCount(
              parseInt(archiveTabRecordCount) - projectIdsToUnarchive.length
            );
            setIsTableLoading(false);
          }
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "project"));
          setShowFailAlert(true);
        });
    });
  };

  const exportProjectsList = () => {
    API.Post(
      `${apiHost}/${account_slug}/v1/projects/export`,
      null,
      authorizationCode
    )
      .then((res) => {
        setShowExportModal(false);
        if (res.status == 202) {
          setSuccessMessage(
            "The project list CSV has been emailed to you! Please check your email."
          );
          setShowSuccessAlert(true);
        }
      })
      .catch((error) => {
        setErrorMessages(formatUnprocessibleResponse(error));
        setShowFailAlert(true);
      });
  };

  return (
    <>
      <SlideOutMenuSectioned
        show={show}
        setShow={(show) => setShow(show)}
        menuHeader="Filter Results By"
        actionButtonText="Apply"
        onActionButtonClick={() =>
          handleApplyFilters(
            selectedClients,
            selectedSalesExecs,
            selectedEngineers,
            selectedStatus,
            selectedTeams,
            selectedTeammates,
            selectedTagIds,
            selectedTags,
            selectedEditStartDate,
            selectedEditEndDate,
            selectedCreatedStartDate,
            selectedCreatedEndDate
          )
        }
        clearFields={hasFilters ? `Clear All (${filterList.length})` : null}
        onCancel={handleCancel}
        onClearFieldsClick={clearAllFieldsOnSlideOut}
        sectionsArray={[
          projectInfoSection,
          statusSection,
          dateEditedSection,
          dateCreatedSection,
        ]}
      />
      <SlideOutMenuSectioned
        show={columnConfigShow}
        setShow={(show) => setColumnConfigShow(show)}
        menuHeader="Customize Table"
        actionButtonText="Apply"
        onActionButtonClick={handleApplyColumns}
        clearFields={`Reset Table`}
        onCancel={handleColumnCancel}
        onClearFieldsClick={resetTable}
        sectionsArray={[
          searchColumnSection,
          selectedColumnsSection,
          availableColumnsSection,
        ]}
      />
      <div
        style={{
          background: "#eeeeee",
          paddingTop: "1em",
          paddingLeft: "2em",
          paddingRight: "2em",
          paddingBottom: "2em",
        }}
        onClick={() => {
          closeContextMenu();
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") closeContextMenu();
        }}
        tabIndex="0"
      >
        {handleFailAlert()}
        {handleAlert(
          showSuccessAlert,
          successMessage,
          setShowSuccessAlert,
          "success",
          ToastAlert
        )}
        <>
          <ScopeStackModal
            modalTitle={isActiveTabSelected ? "Archive" : "Un-archive"}
            modalBody={archiveModalBody}
            button1Text="Cancel"
            handleButton1Click={() => setShowArchiveModal(false)}
            button2Text={isActiveTabSelected ? "Archive" : "Un-archive"}
            handleButton2Click={() =>
              isActiveTabSelected ? deleteProject() : unarchiveProjects()
            }
            show={showArchiveModal}
          />
          <ScopeStackModal
            modalTitle={"Project List Export to CSV"}
            modalBody={
              <p className="instructionalText">
                The project list will be emailed to you in a CSV. Do you want to
                continue?
              </p>
            }
            button1Text="Cancel"
            handleButton1Click={() => setShowExportModal(false)}
            button2Text={"Continue"}
            handleButton2Click={() => exportProjectsList()}
            show={showExportModal}
          />
          <div>
            <Row style={{ alignItems: "center" }}>
              <Col>
                <h2 className="sansTitle">Projects</h2>
              </Col>
              <Col sm={6}>
                <div
                  className="pull-right"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {/* <Button
                    className="btnSeawhite"
                    style={{
                      marginRight: "10px",
                      fontWeight: "bold",
                      borderWidth: "medium",
                      borderRadius: "0",
                    }}
                    variant="secondary"
                  >
                    Create From CRM Opportunity
                  </Button> */}
                  <SeafoamButton
                    className="createProjectButton bold"
                    text="Create New Project"
                    onClick={() => navigate("/projects/new")}
                  />
                </div>
              </Col>
            </Row>
            <hr></hr>
            {projects.length == 0 &&
            filterList.length == 0 &&
            showMyProjectsOnly == false &&
            !isLoading &&
            !isTableLoading ? (
              <div className="noProjectsContainer">
                <div className="noProjects">No Projects Exist</div>
                <p className="clickCreateNewProject">
                  Click "Create New Project" to get started!
                </p>
              </div>
            ) : (
              <div>
                <Row>
                  <Col
                    style={
                      checkedProjects.length > 0 ? { marginLeft: "9%" } : {}
                    }
                    sm={8}
                  >
                    <h5 className="searchProjects">Search Projects</h5>
                  </Col>
                  <Col>
                    {!isLoading && !isTableLoading ? (
                      <FormCheck
                        style={{ color: "#1C2655" }}
                        className="pull-right showProjectsToggle"
                        label="Show My Projects Only"
                        checked={showMyProjectsOnly}
                        onChange={(e) => {
                          let checked = e.target.checked;
                          setShowMyProjectsOnly(checked);
                          setIsTableLoading(true);
                          setCurrentPage(1);
                          setQueryParams({
                            showMyProjectsOnly: checked,
                            currentPage: 1,
                          });
                          userViewData.data.attributes.filters =
                            userViewFilters;
                          userViewData.data.attributes.columns =
                            userViewColumns;
                          userViewData.data.attributes.filters.showMyProjects =
                            checked;
                          setUserViewFilters({
                            ...userViewFilters,
                            showMyProjects: checked,
                          });
                          updateUserView(userViewData);
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
                <Row
                  style={{
                    marginBottom: "20px",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Col sm={11}>
                    <Form onSubmit={handleProjectSearch}>
                      <div className="flexCenter searchRow">
                        {checkedProjects.length > 0 ? (
                          <div className="flexCenter deleteProjectsBox">
                            <div className="projectsSelected">
                              {checkedProjects.length} Selected
                            </div>
                            <div className="deleteProjectsDivider"></div>
                            <FontAwesomeIcon
                              style={{
                                color: "#1C2655",
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              icon={
                                isActiveTabSelected
                                  ? faTrashCan
                                  : faClockRotateLeft
                              }
                              onClick={() => setShowArchiveModal(true)}
                            />
                          </div>
                        ) : null}
                        <SearchField
                          placeholder="Search By Project Name"
                          value={projectName}
                          onChange={(e) => {
                            setProjectName(e.target.value);
                          }}
                          onClick={() => {
                            searchProjectName("");
                          }}
                          disabled={isLoading || isTableLoading}
                        />
                      </div>
                    </Form>
                  </Col>
                  <Col style={{ fontSize: "20px", padding: 0 }}>
                    <Row>
                      <Col style={{ padding: 0 }}>
                        {isLoading || isTableLoading ? null : (
                          <FontAwesomeIconTooltipped
                            onClick={() => setShow(true)}
                            icon={faBarsFilter}
                            style={
                              hasFilters
                                ? { color: "#418172", cursor: "pointer" }
                                : { cursor: "pointer" }
                            }
                            toolTipText={"Filter Results"}
                          />
                        )}
                        {hasFilters && !isLoading && !isTableLoading ? (
                          <div className="filterActive">
                            {`${filterList.length}`} Active
                          </div>
                        ) : null}
                      </Col>
                      <Col style={{ padding: 0 }}>
                        {isLoading || isTableLoading ? null : (
                          <FontAwesomeIconTooltipped
                            onClick={() => setColumnConfigShow(true)}
                            icon={faTableLayout}
                            toolTipText={"Customize Table"}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </Col>
                      {!isLoading && !isTableLoading ? (
                        <Col style={{ padding: 0 }}>
                          <FontAwesomeIconTooltipped
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowExportModal(true)}
                            icon={faFileArrowDown}
                            toolTipText={"Export Active Projects to CSV"}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  </Col>
                </Row>
                {hasFilters && !isLoading && !isTableLoading ? (
                  <Row>
                    <div className="filterList">
                      Filtering By:{" "}
                      {filterList.map((filterType, index) => {
                        let filterData = getFilterData(filterType);
                        return (
                          <span key={filterType}>
                            <strong>{filterType}</strong>
                            {filterData.map((dataSet, i) => {
                              if (filterData.length == 1) {
                                return (
                                  <span key={i}>{` (${dataSet.label})`}</span>
                                );
                              } else if (filterData.length > 1 && i == 0) {
                                return (
                                  <span key={dataSet.value}>{` (${
                                    dataSet.label
                                  } and ${filterData.length - 1} more)`}</span>
                                );
                              }
                            })}
                            {filterList.length <= 1 ||
                            index == filterList.length - 1
                              ? null
                              : ", "}
                          </span>
                        );
                      })}
                    </div>
                  </Row>
                ) : null}
                <div className="projectTabs">
                  <div
                    style={
                      isActiveTabSelected
                        ? {
                            borderBottom: "4px solid #418172",
                            fontWeight: "bold",
                          }
                        : {}
                    }
                    className="activeProjectsTab tab"
                    onClick={() => {
                      setIsActiveTabSelected(true);
                      getTabView(true);
                    }}
                  >
                    Active Projects{" "}
                    {isActiveTabSelected && !isLoading && !isTableLoading
                      ? `(${activeTabRecordCount})`
                      : ""}
                  </div>
                  <div
                    style={
                      !isActiveTabSelected
                        ? {
                            borderBottom: "4px solid #418172",
                            fontWeight: "bold",
                          }
                        : {}
                    }
                    className="tab"
                    onClick={() => {
                      setIsActiveTabSelected(false);
                      getTabView(false);
                    }}
                  >
                    Archived Projects{" "}
                    {isActiveTabSelected || isLoading || isTableLoading
                      ? ""
                      : `(${archiveTabRecordCount})`}
                  </div>
                </div>
                <BaseTable
                  className="sansTable greyBackground"
                  striped={true}
                  hover={true}
                  bordered={true}
                  headerRows={projectsTable.buildHeaderRows()}
                  dataRows={projectsTable.buildDataRows()}
                  footerRows={projectsTable.buildFooterRows()}
                  isLoading={isTableLoading || isLoading}
                />
              </div>
            )}
          </div>
        </>
        {showContextMenu && (
          <ContextMenu
            menuItems={
              duplicatePrivilege
                ? [
                    {
                      href: `${appHost}/projects/${selectedProjectId}/edit`,
                      newTab: false,
                      onClick: null,
                      iconClass: faPencil,
                      style: {},
                      name: <span>&nbsp;Edit Project</span>,
                      hasBreakingLine: false,
                    },
                    {
                      href: `${appHost}/projects/${selectedProjectId}/edit`,
                      newTab: true,
                      onClick: () => {
                        closeContextMenu();
                      },
                      iconClass: faExternalLink,
                      style: { padding: "0 0.5em 0 0" },
                      name: <span>Edit Project In New Tab</span>,
                      hasBreakingLine: false,
                    },
                    {
                      href: "#",
                      newTab: false,
                      onClick: duplicateProject,
                      iconClass: faClone,
                      style: { padding: "0 0.5em 0 0" },
                      name: <span>Duplicate</span>,
                      hasBreakingLine: true,
                    },
                    {
                      href: "#",
                      newTab: false,
                      onClick: () => setShowArchiveModal(true),
                      iconClass: faTrash,
                      style: { padding: "0 0.5em 0 0" },
                      name: (
                        <span>
                          {isActiveTabSelected ? "Archive" : "Un-archive"}
                        </span>
                      ),
                      hasBreakingLine: false,
                    },
                  ]
                : [
                    {
                      href: `${appHost}/projects/${selectedProjectId}/edit`,
                      newTab: false,
                      onClick: null,
                      iconClass: faPencil,
                      style: {},
                      name: <span>&nbsp;Edit Project</span>,
                      hasBreakingLine: false,
                    },
                    {
                      href: `${appHost}/projects/${selectedProjectId}/edit`,
                      newTab: true,
                      onClick: () => {
                        closeContextMenu();
                      },
                      iconClass: faExternalLink,
                      style: { padding: "0 0.5em 0 0" },
                      name: <span>Edit Project In New Tab</span>,
                      hasBreakingLine: true,
                    },
                    {
                      href: "#",
                      newTab: false,
                      onClick: () => setShowArchiveModal(true),
                      iconClass: faTrash,
                      style: { padding: "0 0.5em 0 0" },
                      name: (
                        <span>
                          {isActiveTabSelected ? "Archive" : "Un-archive"}
                        </span>
                      ),
                      hasBreakingLine: false,
                    },
                  ]
            }
            style={{ top: contextMenuY, left: contextMenuX }}
          />
        )}
      </div>
    </>
  );
}

export default ProjectsList;
