import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./IconProgressBar.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { faCheck } from "@fortawesome/pro-light-svg-icons";

function IconProgressBar({ icons, currentPage }) {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    switch (currentPage) {
      case 0:
        setProgress(8);
        break;
      case 1:
        setProgress(23);
        break;
      case 2:
        setProgress(40);
        break;
      case 3:
        setProgress(58);
        break;
      case 4:
        setProgress(75);
        break;
      case 5:
        setProgress(100);
        break;
      default:
        setProgress(10);
    }
    // setProgress(Math.round((currentPage / icons.length) * 100));
  }, [currentPage]);

  return (
    <div>
      <div className="iconProgressBarContainer">
        <ProgressBar percent={progress} filledBackground={"#418172"}>
          {icons.map((icon, index) => (
            <Step accomplished={false} key={index}>
              {({ accomplished }) => (
                <div
                  style={
                    accomplished
                      ? { color: "#418172", borderColor: "#418172" }
                      : {}
                  }
                >
                  {accomplished && (
                    <FontAwesomeIcon className="checkMark" icon={faCheck} />
                  )}

                  <div
                    style={accomplished ? { borderColor: "#418172" } : {}}
                    className="iconProgressBarItem"
                  >
                    <FontAwesomeIcon className={icon.class} icon={icon.name} />
                    <p
                      style={accomplished ? { color: "#418172" } : {}}
                      className="iconPageTitle"
                    >
                      {icon.pageTitle}
                    </p>
                  </div>
                </div>
              )}
            </Step>
          ))}
        </ProgressBar>
      </div>
    </div>
  );
}

export default IconProgressBar;
