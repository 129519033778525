import React, { useEffect } from "react";
import Section from "./Section";
import NavTabs from "@components/NavTabs/NavTabs";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

function ByPhase({
  account_slug,
  project_id,
  phases,
  projectServices,
  projectSubservices,
  lobs,
  resources,
  projectResources,
  projectLocations,
  isLoading,
  rateType,
}) {
  useEffect(() => {}, [isLoading]);

  return (
    <>
      <NavTabs
        tabOneText="By LOB"
        tabOneUrl={`/projects/${project_id}/summary`}
        tabTwoText="By Phase"
        tabTwoUrl={`/projects/${project_id}/summary/phase`}
        tabThreeText={projectLocations.length >= 2 ? "By Location" : null}
        tabThreeUrl={`/projects/${project_id}/summary/location`}
      />
      <div className="col-sm-12" id="sow-workarea">
        {isLoading ? (
          <div style={{ paddingTop: "15px" }}>
            <ScopeStackSpinner />
          </div>
        ) : (
          <Section
            array={phases}
            projectServices={projectServices}
            projectSubservices={projectSubservices}
            relationshipName="project-phase"
            resources={projectResources}
            lobs={lobs}
            rateType={rateType}
          />
        )}
      </div>
    </>
  );
}

export default ByPhase;
