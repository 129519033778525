import React from "react";
import Product from "../Product/Product";

function EditProduct({
  account_slug,
  project_id,
  projectStatus,
  account_id,
  products,
  services,
  projectProducts,
  productUpdated,
  setProductUpdated,
  productCreated,
  productId,
  authorizationCode,
  productPvs,
}) {
  return (
    <Product
      account_slug={account_slug}
      project_id={project_id}
      account_id={account_id}
      projectStatus={projectStatus}
      products={products}
      services={services}
      projectProducts={projectProducts}
      productUpdated={productUpdated}
      setProductUpdated={setProductUpdated}
      productCreated={productCreated}
      productId={productId}
      authorizationCode={authorizationCode}
      productPvs={productPvs}
    />
  );
}

export default EditProduct;
