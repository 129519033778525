import {
  ListProductsForAccountApiArg,
  ListIntegratedProductsApiResponse,
  ListIntegratedProductsApiArg,
  useListProductsForAccountQuery,
  useListIntegratedProductsQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Product } from "../../types";
import { useState, useMemo } from "react";

export interface HookArgs {
  page?:
    | ListProductsForAccountApiArg["page"]
    | ListIntegratedProductsApiArg["page"];
  filter?:
    | ListProductsForAccountApiArg["filter"]
    | ListIntegratedProductsApiArg["filter"];
  source: string;
}

const useListProducts = ({ page, filter, source }: HookArgs) => {
  const [queryParams, setQueryParams] = useState({ page, filter, source });
  const [isRefetching, setIsRefetching] = useState(false);
  const accountSlug = useSelector((state: RootState) => state.slug.accountSlug);

  const queryFilter = useMemo(
    () =>
      queryParams.filter
        ? {
            ...(source !== "standard" && { "account-connection": source }),
            ...queryParams.filter,
          }
        : {
            ...(source !== "standard" && { "account-connection": source }),
            ...filter,
          },
    [filter, source, queryParams.filter]
  );

  const queryHook =
    source === "standard"
      ? useListProductsForAccountQuery
      : useListIntegratedProductsQuery;

  const { data, error, isLoading, refetch } = queryHook({
    slug: accountSlug,
    page: queryParams.page,
    filter: queryFilter,
  });

  const products = useMemo(() => {
    return (
      data?.data?.reduce((acc, product) => {
        if (!product.id) {
          console.error("Missing product.id for a product, skipping...");
          return acc;
        }

        const newProduct: Product = {
          id: product.id,
          type: product.type,
          source: "standard",
          name: product.attributes.name || "",
          vendor: product.attributes["vendor-name"] || "",
          mfrPartNumber: product.attributes["manufacturer-part-number"] || "",
          productId: product.attributes["product-id"] || "",
          units: product.attributes["unit-of-measure"] || "",
          price: String(product.attributes["hardware-price"] || ""),
          description: product.attributes.description || "",
          category: product.attributes.category || "",
          subcategory: product.attributes.subcategory || "",
          sku: product.attributes.sku || "",
          isFavorited: false,
          unitPrice: String(product.attributes["unit-price"] || ""),
          unitCost: String(product.attributes["unit-cost"] || ""),
          markup: String(product.attributes.markup || ""),
          vendorDiscount: String(product.attributes["vendor-discount"] || ""),
          billingFrequency: product.attributes["billing-frequency"],
        };

        return [...acc, newProduct];
      }, [] as Product[]) || []
    );
  }, [data?.data]);

  const pageMeta = useMemo(() => {
    return {
      recordCount: data?.meta?.["record-count"] || 0,
      pageCount: data?.meta?.["page-count"] || 0,
    };
  }, [source, data?.meta]);

  const refetchWithNewParams = (newParams: HookArgs) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      page: newParams.page || { number: 1, size: 10 },
      filter: newParams.filter || {},
      source: newParams.source,
    }));
    refetch();
  };

  return {
    products,
    error: error || null,
    isLoading: isLoading || isRefetching,
    pageMeta,
    refetch: refetchWithNewParams,
  };
};

export default useListProducts;
