import React, { useState, useEffect } from "react";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import NavTabs from "@components/NavTabs/NavTabs";
import { Card, Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import API from "@API";
import ImportButton from "@components/Buttons/ImportButton/ImportButton";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import ArrowDropdownButton from "@components/Buttons/ArrowDropdownButton/ArrowDropdownButton";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import {
  formatUnprocessibleResponse,
  toSnakeCase,
  wordCount,
} from "@utils/helperFunctions";

function AddServices({
  account_slug,
  project_id,
  account_id,
  projectStatus,
  vendorQuoteId,
  languageFields,
  phases,
  deriveFromServices,
  frequencyOptions,
  rateType,
  authorizationCode,
}) {
  // Env
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 3];
  if (lastWordInLocation !== "new") {
    id = locationArr[locationArr.length - 2];
  }

  // Alerts
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Form field states
  const [quoteId, setQuoteId] = useState(null);
  const [languageField, setLanguageField] = useState("");
  let languages = languageFields.map((language) => ({
    [language.attributes["sow-language"]]: "",
  }));
  let languageValues = Object.assign({}, ...languages);
  const [subservices, setSubservices] = useState([]);
  const [newServices, setNewServices] = useState([
    {
      id: 1,
      phase: null,
      name: "",
      quantity: "1",
      hours: "0.0",
      rate: "0.0",
      cost: "0.0",
      frequency: "One Time",
      changed: false,
      languages: languageValues,
    },
    {
      id: 2,
      phase: null,
      name: "",
      quantity: "1",
      hours: "0.0",
      rate: "0.0",
      cost: "0.0",
      frequency: "One Time",
      changed: false,
      languages: languageValues,
    },
    {
      id: 3,
      phase: null,
      name: "",
      quantity: "1",
      hours: "0.0",
      rate: "0.0",
      cost: "0.0",
      frequency: "One Time",
      changed: false,
      languages: languageValues,
    },
    {
      id: 4,
      phase: null,
      name: "",
      quantity: "1",
      hours: "0.0",
      rate: "0.0",
      cost: "0.0",
      frequency: "One Time",
      changed: false,
      languages: languageValues,
    },
    {
      id: 5,
      phase: null,
      name: "",
      quantity: "1",
      hours: "0.0",
      rate: "0.0",
      cost: "0.0",
      frequency: "One Time",
      changed: false,
      languages: languageValues,
    },
    {
      id: 6,
      phase: null,
      name: "",
      quantity: "1",
      hours: "0.0",
      rate: "0.0",
      cost: "0.0",
      frequency: "One Time",
      changed: false,
      languages: languageValues,
    },
    {
      id: 7,
      phase: null,
      name: "",
      quantity: "1",
      hours: "0.0",
      rate: "0.0",
      cost: "0.0",
      frequency: "One Time",
      changed: false,
      languages: languageValues,
    },
    {
      id: 8,
      phase: null,
      name: "",
      quantity: "1",
      hours: "0.0",
      rate: "0.0",
      cost: "0.0",
      frequency: "One Time",
      changed: false,
      languages: languageValues,
    },
    {
      id: 9,
      phase: null,
      name: "",
      quantity: "1",
      hours: "0.0",
      rate: "0.0",
      cost: "0.0",
      frequency: "One Time",
      changed: false,
      languages: languageValues,
    },
    {
      id: 10,
      phase: null,
      name: "",
      quantity: "1",
      hours: "0.0",
      rate: "0.0",
      cost: "0.0",
      frequency: "One Time",
      changed: false,
      languages: languageValues,
    },
  ]);

  useEffect(() => {
    document.querySelector(".actionsData").style.display = "none";
    for (let i = 0; i < languageFields.length; i++) {
      setLanguageField(languageFields[0].attributes.name);
    }

    setQuoteId(id);
  }, [languageFields, phases, deriveFromServices]);

  const languageOptions = languageFields.map((languageField) => {
    return (
      <option accessKey={languageField.id} key={languageField.id}>
        {languageField.attributes.name}
      </option>
    );
  });

  const phaseOptions = phases.map((phase) => {
    return (
      <option accessKey={phase.id} key={phase.id}>
        {phase.attributes.name}
      </option>
    );
  });

  const getTableData = (array) => {
    return array.map((item) => {
      let addSubserviceBtn = (
        <div
          id={item.id}
          className="dropdown-item btn-sm additional-effort"
          onClick={(e) => {
            e.persist();
            let phase = "";
            let selectPhaseAlert =
              "Please select a phase for this service before adding a subservice.";

            for (let i = 0; i < newServices.length; i++) {
              if (newServices[i].id == e.target.id) {
                phase = newServices[i].phase;
                if (phase === "") {
                  alert(selectPhaseAlert);
                  return;
                }
                setSubservices((subservices) => [
                  ...subservices,
                  {
                    serviceId: e.target.id,
                    id: Math.floor(Math.random() * 100),
                    type: "subservice",
                    name: "",
                    quantity: "1",
                    hours: "0.0",
                    rate: "0.0",
                    cost: "0.0",
                    frequency: "One Time",
                    changed: true,
                    languages: languageValues,
                  },
                ]);
              }
            }
          }}
        >
          <i className="fa fa-plus"></i>
          Add Subservice
        </div>
      );

      if (item.type === "subservice") {
        addSubserviceBtn = <div></div>;
      }

      const deleteSubserviceRow = (e) => {
        setSubservices(
          subservices.filter((subservice) => subservice.id != e.target.id)
        );
      };

      const deleteBtn = (
        <div
          id={item.id}
          className="dropdown-item btn-sm additional-effort"
          onClick={(e) => {
            e.persist();
            deleteSubserviceRow(e);
          }}
        >
          <i className="fa fa-remove"></i>
          Delete
        </div>
      );

      const getOptions = () => {
        if (item.type !== "subservice") {
          return <>{addSubserviceBtn}</>;
        }
        if (item.type === "subservice") {
          return <>{deleteBtn}</>;
        }
      };

      const getColumnOne = () => {
        if (item.type === "subservice") {
          return {
            class: "col-2",
            name: "",
          };
        }
        if (item.type !== "subservice") {
          let phase = "";
          for (let i = 0; i < phases.length; i++) {
            let phaseId = item.phase;
            if (phaseId == phases[i].id) {
              phase = phases[i].attributes.name;
            }
          }
          return {
            class: "col-2",
            name: (
              <Form.Control
                as="select"
                id={item.id}
                value={phase}
                onChange={(e) => {
                  setHasUnsavedChanges(true);
                  let selected = e.target.options.selectedIndex;
                  setNewServices(
                    newServices.map((service) =>
                      service.id == e.target.id
                        ? {
                            ...service,
                            phase: e.target.options[selected].accessKey,
                            changed: true,
                          }
                        : service
                    )
                  );
                }}
              >
                {[
                  <option accessKey="blank" key="blank"></option>,
                  phaseOptions,
                ]}
              </Form.Control>
            ),
          };
        }
      };

      const serviceNameField = {
        class: languageField !== "Pricing" ? "col-3" : "col-2",
        name: (
          <Form.Control
            type="text"
            value={item.name}
            id={item.id}
            onChange={(e) => {
              e.persist();
              setHasUnsavedChanges(true);
              if (item.type !== "subservice") {
                setNewServices(
                  newServices.map((service) =>
                    service.id == e.target.id
                      ? {
                          ...service,
                          name: e.target.value,
                          changed: true,
                        }
                      : service
                  )
                );
              }
              if (item.type === "subservice") {
                setSubservices(
                  subservices.map((subservice) =>
                    subservice.id == e.target.id
                      ? {
                          ...subservice,
                          name: e.target.value,
                        }
                      : subservice
                  )
                );
              }
            }}
          />
        ),
      };

      const quantityField = {
        class: "col-1 text-right",
        name: (
          <Form.Control
            type="number"
            min={"0"}
            step={"1"}
            id={item.id}
            value={item.quantity}
            onChange={(e) => {
              e.persist();
              setHasUnsavedChanges(true);
              let propertyToModify = "quantity";
              if (item.type !== "subservice") {
                setNewServices(
                  newServices.map((service) =>
                    service.id == e.target.id
                      ? {
                          ...service,
                          [propertyToModify]: e.target.value,
                          changed: true,
                        }
                      : service
                  )
                );
              }
              if (item.type === "subservice") {
                setSubservices(
                  subservices.map((subservice) =>
                    subservice.id == e.target.id
                      ? {
                          ...subservice,
                          [propertyToModify]: e.target.value,
                        }
                      : subservice
                  )
                );
              }
            }}
          />
        ),
      };

      const hoursField = {
        class: "col-1 text-right",
        name: (
          <Form.Control
            type="number"
            min={"0"}
            step={"0.01"}
            id={item.id}
            value={item.hours}
            onChange={(e) => {
              e.persist();
              setHasUnsavedChanges(true);
              let propertyToModify = "hours";
              if (item.type !== "subservice") {
                setNewServices(
                  newServices.map((service) =>
                    service.id == e.target.id
                      ? {
                          ...service,
                          [propertyToModify]: e.target.value,
                          changed: true,
                        }
                      : service
                  )
                );
              }
              if (item.type === "subservice") {
                setSubservices(
                  subservices.map((subservice) =>
                    subservice.id == e.target.id
                      ? {
                          ...subservice,
                          [propertyToModify]: e.target.value,
                          changed: true,
                        }
                      : subservice
                  )
                );
              }
            }}
          />
        ),
      };

      const descriptionField = {
        class: "col-5",
        name: languageFields.map((language, index) => (
          <Form.Control
            key={index}
            as="textarea"
            rows={2}
            style={
              language.attributes.name !== languageField
                ? { display: "none" }
                : { display: "block" }
            }
            id={item.id}
            value={
              item.languages[language.attributes["sow-language"]] ||
              item.type == "subservice"
                ? item.languages[language.attributes["sow-language"]]
                : ""
            }
            onChange={(e) => {
              e.persist();
              setHasUnsavedChanges(true);
              if (item.type == "subservice") {
                setSubservices(
                  subservices.map((subservice) => {
                    let langArray = languageFields.map((lang) => {
                      if (
                        lang.attributes["sow-language"] ===
                        language.attributes["sow-language"]
                      ) {
                        let key = lang.attributes["sow-language"];
                        return {
                          ...subservice.languages,
                          [key]: e.target.value,
                        };
                      }
                    });
                    let newLangs = Object.assign({}, ...langArray);
                    if (subservice.id == e.target.id) {
                      return {
                        ...subservice,
                        changed: true,
                        languages: newLangs,
                      };
                    } else {
                      return subservice;
                    }
                  })
                );
              }
              if (item.type !== "subservice") {
                setNewServices(
                  newServices.map((service) => {
                    let langArray = languageFields.map((lang) => {
                      if (
                        lang.attributes["sow-language"] ===
                        language.attributes["sow-language"]
                      ) {
                        let key = lang.attributes["sow-language"];
                        return {
                          ...service.languages,
                          [key]: e.target.value,
                        };
                      }
                    });
                    let newLangs = Object.assign({}, ...langArray);
                    if (service.id == e.target.id) {
                      return {
                        ...service,
                        changed: true,
                        languages: newLangs,
                      };
                    } else {
                      return service;
                    }
                  })
                );
              }
            }}
          />
        )),
      };

      const arrowButtonColumn = {
        class: "arrowDropdownColWidth",
        name: (
          <div className="actionsDiv">
            <ArrowDropdownButton id={item.id} options={getOptions()} />
          </div>
        ),
      };

      const rateField = {
        class: "col-1 text-right",
        name: (
          <Form.Control
            readOnly={deriveFromServices === false}
            type="number"
            min="0.0"
            step="0.01"
            id={item.id}
            value={item.rate}
            onChange={(e) => {
              e.persist();
              setHasUnsavedChanges(true);
              if (item.type !== "subservice") {
                setNewServices(
                  newServices.map((service) =>
                    service.id == e.target.id
                      ? {
                          ...service,
                          rate: e.target.value,
                          changed: true,
                        }
                      : service
                  )
                );
              }
              if (item.type === "subservice") {
                setSubservices(
                  subservices.map((subservice) =>
                    subservice.id == e.target.id
                      ? {
                          ...subservice,
                          rate: e.target.value,
                          changed: true,
                        }
                      : subservice
                  )
                );
              }
            }}
          />
        ),
      };

      const costField = {
        class: "col-1 text-right",
        name: (
          <Form.Control
            readOnly={deriveFromServices === false}
            type="number"
            min="0.0"
            step="0.01"
            id={item.id}
            value={
              item.hours !== "0.0" && item.rate !== "0.0"
                ? parseFloat(item.hours * item.rate)
                : item.cost
            }
            onChange={(e) => {
              e.persist();
              setHasUnsavedChanges(true);
              if (item.type !== "subservice") {
                setNewServices(
                  newServices.map((service) =>
                    service.id == e.target.id
                      ? {
                          ...service,
                          cost: e.target.value,
                          changed: true,
                        }
                      : service
                  )
                );
              }
              if (item.type === "subservice") {
                setSubservices(
                  subservices.map((subservice) =>
                    subservice.id == e.target.id
                      ? {
                          ...subservice,
                          cost: e.target.value,
                          changed: true,
                        }
                      : subservice
                  )
                );
              }
            }}
          />
        ),
      };

      const frequencyField = {
        class: "col-3",
        name: (
          <Form.Control
            as="select"
            id={item.id}
            value={item.frequency}
            onChange={(e) => {
              setHasUnsavedChanges(true);
              if (item.type !== "subservice") {
                setNewServices(
                  newServices.map((service) =>
                    service.id == e.target.id
                      ? {
                          ...service,
                          id: e.target.id,
                          frequency: e.target.value,
                          changed: true,
                        }
                      : service
                  )
                );
              }
              if (item.type === "subservice") {
                setSubservices(
                  subservices.map((subservice) =>
                    subservice.id == e.target.id
                      ? {
                          ...subservice,
                          id: e.target.id,
                          frequency: e.target.value,
                          changed: true,
                        }
                      : subservice
                  )
                );
              }
            }}
          >
            {frequencyOptions}
          </Form.Control>
        ),
      };

      const getColumns = () => {
        if (languageField !== "Pricing") {
          return [
            getColumnOne(),
            serviceNameField,
            quantityField,
            descriptionField,
            arrowButtonColumn,
          ];
        } else {
          return [
            getColumnOne(),
            serviceNameField,
            quantityField,
            hoursField,
            rateField,
            costField,
            frequencyField,
            arrowButtonColumn,
          ];
        }
      };

      return {
        rowClass: "newQuotedServiceRow row",
        onClick: null,
        columns: getColumns(),
        actions: null,
      };
    });
  };

  const getHeaderColumns = () => {
    if (languageField == "Pricing") {
      return [
        {
          class: "col-2",
          name: "Phase",
        },
        {
          class: "col-2",
          name: "Service",
        },
        {
          class: "col-1 text-right",
          name: "Qty",
        },
        {
          class: "col-1 text-right",
          name: rateType,
        },
        {
          class: "col-1 text-right",
          name: "Rate",
        },
        {
          class: "col-1 text-right",
          name: "Cost",
        },
        {
          class: "col-3",
          name: "Frequency",
        },
        {
          class: "arrowDropdownColWidth",
          name: "",
        },
      ];
    }
    if (languageField !== "Pricing") {
      return [
        {
          class: "col-2",
          name: "Phase",
        },
        {
          class: "col-3",
          name: "Service",
        },
        {
          class: "col-1 text-right",
          name: "Qty",
        },
        {
          class: "col-5",
          name: "Content",
        },
        {
          class: "arrowDropdownColWidth",
          name: "",
        },
      ];
    }
  };

  const newQuotedServiceHeaderData = {
    rowClass: "newQuotedServiceHeaderRow row",
    columns: getHeaderColumns(),
  };

  const getNewQuotedServicesFooterData = () => {
    let content = "";
    content = (
      <div>
        <SubmitButton />
      </div>
    );
    return {
      rowClass: "col-12",
      columns: [{ class: "thirdPartyServicesFooter", content: content }],
    };
  };

  const arrangeDataForNewServices = () => {
    let array = [];
    newServices.forEach((service) => {
      array.push(service);
      for (let j = 0; j < subservices.length; j++) {
        if (subservices[j].serviceId == service.id) {
          array.push(subservices[j]);
        }
      }
    });
    return array;
  };

  const newQuotedServices = arrangeDataForNewServices();

  let newQuotedServiceTableData = getTableData(newQuotedServices);

  const newQuotedServiceFooterData = getNewQuotedServicesFooterData();

  let newQuotedServiceTable = new ScopeStackTable(
    "tasks or services",
    newQuotedServiceHeaderData,
    newQuotedServiceTableData,
    newQuotedServiceFooterData
  );

  const validateForm = () => {
    let isValid = true;
    let services = newServices.filter((service) => service.changed === true);
    if (services.length == 0) {
      isValid = false;
      setErrorMessages(
        "You have not changed any services. Name and phase are required to submit a service."
      );
      setShowFailAlert(true);
    }
    for (let i = 0; i < services.length; i++) {
      let service = services[i];
      if (service.name == "") {
        isValid = false;
        setErrorMessages(
          "Could not submit services. Service must have a name."
        );
        setShowFailAlert(true);
      }
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setHasUnsavedChanges(false);
    // Validation
    const formIsValid = validateForm();
    if (!formIsValid) {
      return;
    }

    const getUpdatedLanguages = (service) => {
      let languages = service.languages;
      delete service.languages["service_description"];
      return languages;
    };

    const submitSubservices = (serviceId, postedServiceId, phaseId) => {
      subservices.forEach((subservice, index) => {
        if (subservice.serviceId == serviceId) {
          let subserviceData = {
            data: {
              type: "third-party-services",
              attributes: {
                name: subservice.name,
                quantity: subservice.quantity,
                position: index,
                "original-description":
                  subservice.languages["service_description"],
                "est-cost": subservice.cost,
                "est-hours": subservice.hours,
                "est-rate": subservice.rate,
                "payment-frequency":
                  wordCount(subservice.frequency) == 2
                    ? toSnakeCase(subservice.frequency)
                    : subservice.frequency.toLowerCase(),
                "source-type": "ThirdPartyService",
                "source-id": postedServiceId,
              },
              relationships: {
                "project-phase": {
                  data: { type: "project-phases", id: phaseId },
                },
              },
            },
          };
          subserviceData.data.attributes["original-languages"] =
            getUpdatedLanguages(subservice);
          API.Post(
            `${apiHost}/${account_slug}/v1/third-party-services/`,
            subserviceData,
            authorizationCode
          );
        }
      });
    };

    newServices.forEach((service) => {
      if (service.changed === true) {
        let serviceData = {
          data: {
            type: "third-party-services",
            attributes: {
              name: service.name,
              quantity: service.quantity,
              position: service.id,
              "original-description": service.languages["service_description"],
              "original-languages": getUpdatedLanguages(service),
              "est-cost": service.cost,
              "est-hours": service.hours,
              "est-rate": service.rate,
              "payment-frequency":
                wordCount(service.frequency) == 2
                  ? toSnakeCase(service.frequency)
                  : service.frequency.toLowerCase(),
              "source-type": "Quote",
              "source-id": quoteId,
            },
            relationships: {
              "project-phase": {
                data: { type: "project-phases", id: service.phase },
              },
            },
          },
        };
        API.Post(
          `${apiHost}/${account_slug}/v1/third-party-services`,
          serviceData,
          authorizationCode
        )
          .then((response) => {
            if (response.status == 201) {
              if (subservices.length !== 0) {
                submitSubservices(
                  service.id,
                  response.data.data.id,
                  service.phase
                );
              }
              window.location.replace(
                `${appHost}/projects/${project_id}/quotes/${quoteId}/quoted_service`
              );
            }
          })
          .catch((err) => {
            setErrorMessages(
              formatUnprocessibleResponse(err, "Service details")
            );
            setShowFailAlert(true);
          });
      }
    });
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      <NavTabs
        tabOneText="Vendor Quote"
        tabOneUrl={`/projects/${project_id}/quotes/${quoteId}/edit`}
        tabTwoText="Vendor Description"
        tabTwoUrl={`/projects/${project_id}/quotes/${quoteId}/quoted_service/new`}
        tabThreeText="Our Description"
        tabThreeUrl={`/projects/${project_id}/quotes/${quoteId}/third_party_services`}
        hasUnsavedChanges={hasUnsavedChanges}
      />
      <div className="col-sm-12" id="sow-workarea">
        <div className="tabContent">
          {handleFailAlert()}
          <Card>
            <Card.Body>
              <Row>
                <Col style={{ marginTop: "2em" }}>
                  <ImportButton
                    account_slug={account_slug}
                    project_id={project_id}
                    contextId={quoteId !== null ? quoteId : id}
                    contextType="Quote"
                    label="Quoted Services (CSV or Excel)"
                    value="quoted_services"
                  />
                </Col>
                <Col sm={3}>
                  <ControlledDropDown
                    className="pull-right"
                    label="View"
                    value={languageField}
                    onChange={(e) => setLanguageField(e.target.value)}
                    options={languageOptions}
                  />
                </Col>
              </Row>
              <Form onSubmit={handleSubmit}>
                <BaseTable
                  className="newQuotedServiceTable"
                  striped={false}
                  hover={false}
                  bordered={false}
                  headerRows={newQuotedServiceTable.buildHeaderRows()}
                  dataRows={newQuotedServiceTable.buildDataRows()}
                  footerRows={newQuotedServiceTable.buildFooterRows()}
                />
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default AddServices;
