import { OptionType } from "../types/Quote";

export const getLanguages = (languageFields) => {
  let languages =
    languageFields?.map((language: any) => ({
      [language?.attributes?.["sow-language"]]: "",
    })) || [];

  let objOfLanguages = Object.assign({}, ...languages);
  return objOfLanguages;
};

export const getFormattedQuote = (
  quoteFromApi,
  vendors,
  billingFrequencies,
  lobs
) => {
  let vendor: OptionType = {
    value: 0,
    label: "Search and select vendor name",
  };
  let lob: OptionType = {
    value: 0,
    label: "If applicable, assign to a Line of Business",
  };
  let billingFrequency: OptionType = {
    value: 0,
    label: "How often do costs recur",
  };
  let foundVendor = (vendors || []).find(
    (p) =>
      quoteFromApi?.relationships &&
      quoteFromApi?.relationships["vendor"] &&
      quoteFromApi?.relationships?.["vendor"].data?.id.toString() == p.id
  );
  if (foundVendor && foundVendor.id) {
    vendor = {
      label: foundVendor.attributes?.["name"],
      value: foundVendor.id,
    };
  }

  let foundBillingFrequency = billingFrequencies.find(
    (bf) => bf.value === quoteFromApi?.attributes?.["billing-frequency"]
  );
  if (foundBillingFrequency) {
    billingFrequency = foundBillingFrequency;
  }

  let foundLob = (lobs || []).find(
    (lob) =>
      quoteFromApi?.relationships &&
      quoteFromApi?.relationships.lob &&
      quoteFromApi?.relationships?.lob.data?.id == lob.id
  );
  if (foundLob && foundLob.id) {
    lob = {
      label:
        foundLob.attributes?.["nested-name"] || foundLob.attributes?.["name"],
      value: foundLob.id,
    };
  }

  return {
    id: String(quoteFromApi.id),
    description: quoteFromApi?.attributes.description,
    vendor: vendor,
    oneTimeCost: quoteFromApi?.attributes?.cost || "",
    oneTimeRevenue: quoteFromApi?.attributes?.price || "",
    markup: Number(quoteFromApi?.attributes?.markup) || "",
    recurringCost: quoteFromApi?.attributes?.["recurring-cost"] || "",
    recurringRevenue: quoteFromApi?.attributes?.["recurring-price"] || "",
    lob: lob,
    totalRevenue: quoteFromApi?.attributes?.["total-revenue"] || 0,
    deriveFromServices: quoteFromApi?.attributes?.["service-pricing"] || false,
    billingFrequency: billingFrequency,
    billingFrequencies: billingFrequencies,
    terms: quoteFromApi?.attributes?.["mrr-terms"],
    vendorDocument: quoteFromApi?.attributes?.["vendor-document"]
      ? quoteFromApi?.attributes?.["vendor-document"]
      : null,
    vendorDocumentStatus:
      quoteFromApi?.attributes?.["vendor-document-status"] || "",
    documentPending:
      quoteFromApi?.attributes?.["vendor-document-status"] &&
      quoteFromApi?.attributes?.["vendor-document-status"] !==
        "not_applicable" &&
      quoteFromApi?.attributes?.["vendor-document-status"] !== "accepted",
    revenueCalculationLabel: quoteFromApi?.attributes?.["revenue-calculation"],
  };
};
