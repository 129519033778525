import React from "react";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

function Spinner() {
  return (
    <div style={{ paddingTop: "20px" }}>
      <ScopeStackSpinner />
    </div>
  );
}

export default Spinner;
