import {
  FavoriteProduct,
  GetBySlugV1UserFavoritesApiArg,
  useGetBySlugV1UserFavoritesQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Product } from "../../../types";
import { useMemo } from "react";

export interface HookArgs {
  filter?: GetBySlugV1UserFavoritesApiArg["filter"];
}

const useListFavoriteProducts = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const args = {
    slug: accountSlug,
    filter: {
      content_type: "product",
    },
  } as any;
  const { data, error, isLoading, refetch } =
    useGetBySlugV1UserFavoritesQuery(args);

  function isFavoriteProduct(content: any): content is FavoriteProduct {
    return content && "sku" in content;
  }

  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  // Memoizing the transformation of product data
  const products = useMemo(() => {
    if (!data?.data) {
      return [];
    }

    return data.data.reduce((acc, product) => {
      if (
        !product.id ||
        !product.attributes ||
        !isFavoriteProduct(product.attributes.content)
      ) {
        // Handle missing product ID or attributes appropriately
        console.error(
          "Missing product.id, product.attributes, or product content, skipping..."
        );
        return acc;
      }

      const newProduct: Product = {
        id: product.id,
        type: product.type,
        source: (product.attributes.source as any) || "standard",
        name: product.attributes.name || "",
        vendor: product.attributes.content["vendor_name"] || "",
        mfrPartNumber:
          product.attributes.content["manufacturer_part_number"] || "",
        productId: product.attributes.content["product_id"] || "",
        sku: product.attributes.content.sku || "",
        units: product.attributes.content["unit_of_measure"] || "",
        price: String(product.attributes.content["unit_price"] || ""),
        description: product.attributes.content.description || "",
        category: product.attributes.content.category || "",
        subcategory: product.attributes.content.subcategory || "",
        isFavorited: true, // Since these are favorite products
        sourceKey: product.attributes["source-key"] || "",
        unitCost: String(product.attributes.content["unit-cost"] || ""),
      };

      acc.push(newProduct);
      return acc;
    }, [] as Product[]);
  }, [data?.data]);

  return {
    products,
    error: error || customError,
    isLoading,
    pageMeta,
    refetch,
  };
};

export default useListFavoriteProducts;
