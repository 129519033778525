import DataTable from "@components/DataTable";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import "./style.css";
import { DocumentTemplate } from "./types";
import { ColumnDef } from "@tanstack/react-table";
import useListDocumentTemplates from "./api/useListDocumentTemplates";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import {
  useDeleteDocumentTemplateMutation,
  useRestoreDocumentTemplateMutation,
} from "@generated";
import { handleAlert, snakeToText } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockRotateLeft,
  faPencil,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons";

function Show({ account }) {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [selectedIds, setSelectedIds] = useState<string[] | []>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteQueue, setDeleteQueue] = useState<string[] | number[] | []>([]);
  const [deleteDocumentTemplate] = useDeleteDocumentTemplateMutation();
  const [restoreDocumentTemplate] = useRestoreDocumentTemplateMutation();
  const [errorMessages, setErrorMessages] = useState<string | string[]>(
    "Something went wrong! Your changes could not be saved at this time."
  );
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Document Template saved successfully!"
  );
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const location = useLocation();
  const [reloading, setReloading] = useState(false);

  const {
    documentTemplates,
    documentTemplatesError,
    documentTemplatesLoading,
    refetch,
  } = useListDocumentTemplates({
    page: {
      number: currentPage,
      size: pageSize,
    },
  });

  useEffect(() => {
    window.history.replaceState({}, "");
    setReloading(true);
    refetch().then((res) => setReloading(false));
  }, []);

  const documentTemplateColumns: ColumnDef<DocumentTemplate>[] = [
    {
      header: "Name",
      cell(props) {
        return (
          <div>
            {props.row.original.name}&nbsp;
            {props.row.original.id !== 0 && documentTemplates?.length > 0 && (
              <FontAwesomeIcon
                className="cursorPoint"
                icon={faPencil}
                onClick={() => {
                  navigate(
                    `/admin/document_templates/edit/${props.row.original.id}`,
                    {
                      state: { documentTemplate: props.row.original },
                    }
                  );
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      header: "Template Format",
      cell(props) {
        return (
          <>
            {documentTemplates?.length > 0
              ? snakeToText(props.row.original.format)
              : ""}
          </>
        );
      },
    },
  ];

  const handleDelete = () => {
    setDeleteQueue([...selectedIds]);
    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    setIsDeleting(true);
    if (deleteQueue.length === 0) return;

    const promises: any = [];
    deleteQueue.forEach((documentTemplateIdToDelete) => {
      if (currentTab === 1) {
        promises.push(
          deleteDocumentTemplate({
            slug: account?.data?.attributes?.slug,
            id: parseInt(documentTemplateIdToDelete),
          })
        );
      } else {
        promises.push(
          restoreDocumentTemplate({
            slug: account?.data?.attributes?.slug,
            id: parseInt(documentTemplateIdToDelete),
            body: { data: undefined },
          })
        );
      }
    });

    Promise.all(promises)
      .then((res) => {
        setSuccessMessage(
          `Document Templates${deleteQueue.length > 1 ? "s" : ""} ${
            currentTab === 1 ? "archived" : "unarchived"
          } successfully!`
        );
        setShowSuccessAlert(true);
        setIsDeleting(false);
        setShowConfirmationModal(false);
        setSelectedIds([]);
        setDeleteQueue([]);
        refetch();
      })
      .catch((error) => {
        setErrorMessages(
          "One or more document templates could not be deleted at this time. Please try again later."
        );
        setShowFailAlert(true);
      });
  };

  const handleConfirm = () => {
    confirmDelete();
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
    setDeleteQueue([]);
  };

  const getConfirmationMessage = () => {
    return (
      <>
        {currentTab === 1 ? (
          <div
            style={{
              color: "#1C2655",
              fontSize: "14px",
            }}
          >
            {isDeleting ? (
              <SmallSpinner />
            ) : (
              <span style={{ fontWeight: "700" }}>{`You are about to delete ${
                selectedIds.length
              } document templates ${
                selectedIds.length > 1 ? "s" : ""
              }.`}</span>
            )}
            <ul style={{ marginTop: "10px" }}>
              {selectedIds.map((documentTemplateToDelete) => {
                let docTemplateName = documentTemplates?.find(
                  (doc) => doc.id == documentTemplateToDelete
                )?.name;
                return <li>{docTemplateName}</li>;
              })}
            </ul>
            This action cannot be undone. Do you wish to proceed?
          </div>
        ) : (
          <div>
            <p>
              <strong>
                Un-archive {selectedIds.length} document template
                {selectedIds.length > 1 ? "s" : ""}?
              </strong>
              <br />
              <br />
              {selectedIds.length
                ? "These document templates"
                : "This document template"}{" "}
              will be moved to the active document templates tab.
            </p>
          </div>
        )}
      </>
    );
  };

  const activeDocumentTemplates = documentTemplates?.filter(
    (template) => template.active
  );
  const archivedDocumentTemplates = documentTemplates?.filter(
    (template) => !template.active
  );

  return (
    <div className="documentTemplatesPage">
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Card>
        <Card.Header
          style={{
            backgroundColor: "#fff",
            color: "#1C2655",
            padding: "0px 16px",
          }}
        >
          <Row
            style={{
              padding: "20px 0 0 20px",
              alignItems: "center",
            }}
          >
            <Col className="text24" sm={7}>
              Document Templates
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  float: "right",
                }}
              >
                <Button
                  className="squareGreenButton"
                  onClick={() => {
                    navigate(`/admin/document_templates/new`, {
                      state: {},
                    });
                  }}
                  style={{ marginRight: "16px", marginLeft: "10px" }}
                >
                  Add Document Template
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <hr style={{ marginLeft: "30px", marginRight: "30px" }} />
        <Card.Body style={{ marginTop: "-10px" }}>
          {selectedIds.length > 0 ? (
            <Row>
              <Col sm={3}>
                <Button className="deleteBox" onClick={handleDelete}>
                  {selectedIds.length} Selected{" "}
                  <span className="selectedSpan"></span>
                  <FontAwesomeIcon
                    style={{ color: "black" }}
                    icon={currentTab === 2 ? faClockRotateLeft : faTrashCan}
                  />
                </Button>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={3}></Col>
              <div
                style={{ paddingLeft: "2rem" }}
                className="bold multiDeletePlaceholder"
              ></div>
            </Row>
          )}
          <Tabs
            activeKey={currentTab}
            className="mb-3"
            onSelect={(eventKey) => {
              setCurrentTab(Number(eventKey));
              setDeleteQueue([]);
              setSelectedIds([]);
            }}
          >
            <Tab
              eventKey={1}
              title={`Active (${activeDocumentTemplates.length})`}
              tabClassName="activeDocumentTemplatesTab"
              className="activeDocumentTemplatesContent"
            >
              {documentTemplatesLoading || reloading ? (
                <SmallSpinner />
              ) : (
                <DataTable
                  classForTable={`documentTemplatesTable`}
                  data={
                    activeDocumentTemplates.length > 0
                      ? activeDocumentTemplates
                      : [
                          {
                            id: "noRow",
                            name: "No document templates found",
                          },
                        ]
                  }
                  columns={documentTemplateColumns}
                  selectable={activeDocumentTemplates.length > 0}
                  striped={true}
                  selectedIds={selectedIds}
                  setSelectedIds={(ids) => setSelectedIds(ids)}
                  dragAndDrop={false}
                  isCollapsible={false}
                  hasSettings={false}
                />
              )}
            </Tab>
            <Tab
              eventKey={2}
              title={`Archived (${archivedDocumentTemplates.length})`}
              tabClassName="archivedDocumentTemplatesTab"
              className="archivedDocumentTemplatesContent"
            >
              {documentTemplatesLoading ? (
                <SmallSpinner />
              ) : (
                <DataTable
                  classForTable={`documentTemplatesTable`}
                  data={
                    archivedDocumentTemplates.length > 0
                      ? archivedDocumentTemplates
                      : [
                          {
                            id: "noRow",
                            name: "No document templates found",
                          },
                        ]
                  }
                  columns={documentTemplateColumns}
                  selectable={archivedDocumentTemplates.length > 0}
                  striped={true}
                  selectedIds={selectedIds}
                  setSelectedIds={(ids) => setSelectedIds(ids)}
                  dragAndDrop={false}
                  isCollapsible={false}
                  hasSettings={false}
                />
              )}
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
      <ScopeStackModal
        modalTitle={`${
          currentTab === 2 ? "Un-archive" : "Archive"
        } Document Templates`}
        modalBody={getConfirmationMessage()}
        handleButton2Click={isDeleting ? () => null : handleConfirm}
        handleClose={handleCancel}
        show={showConfirmationModal}
        handleButton1Click={handleCancel}
        deleteModal={true}
        button1Text={"Cancel"}
        button2Text={currentTab === 2 ? "Un-archive" : "Archive"}
      />
    </div>
  );
}

export default Show;
