import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import {
  formatUnprocessibleResponse,
  renderResourceName,
} from "@utils/helperFunctions";
import { capitalizeFirstLetter } from "@utils/helperFunctions";
import Select from "react-select";

function Resources({ account_slug, project_id, account_id }) {
  const [projectResources, setProjectResources] = useState([]);
  const [resources, setResources] = useState();
  const [resourceRates, setResourceRates] = useState([]);
  const [newProjectResources, setNewProjectResources] = useState([]);
  const [lobs, setLobs] = useState([]);
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);

  const [resourceRatesChanged, setResourceRatesChanged] = useState([]);
  const [resourceCostsChanged, setResourceCostsChanged] = useState([]);
  const [resourceCostPermission, setResourceCostPermission] = useState("");
  const [resourceRatePermission, setResourceRatePermission] = useState("");
  const [resourceDescriptionsChanged, setResourceDescriptionsChanged] =
    useState([]);
  const [externalNameChanged, setExternalNameChanged] = useState([]);
  const [expenseRateChanged, setExpenseRateChanged] = useState([]);

  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [rateType, setRateType] = useState("Hours");
  const [costType, setCostType] = useState("Hours");

  const [isLoading, setIsLoading] = useState(true);

  const determineRateType = (rateType) => {
    rateType = capitalizeFirstLetter(rateType);
    if (rateType == "Day") return "Daily Rate";
    return rateType + "ly Rate";
  };

  const determineCostType = (costType) => {
    costType = capitalizeFirstLetter(costType);
    if (costType == "Day") return "Daily Cost";
    return costType + "ly Cost";
  };

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get Project Data
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=rate-table,rate-table.resource-rates,project-resources,project-resources.line-of-business,rate-table.resource-rates.resource,rate-table.resource-rates.line-of-business`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        let projectResources = response.data.included
          .filter(
            (item) => item.type == "project-resources" && item.attributes.active
          )
          .sort((a, b) => {
            if (a.attributes.name > b.attributes.name) return 1;
            if (a.attributes.name < b.attributes.name) return -1;
            if (a.attributes["external-name"] > b.attributes["external-name"])
              return 1;
            if (a.attributes["external-name"] < b.attributes["external-name"])
              return -1;
            return 0;
          });
        let lobs = response.data.included.filter(
          (item) => item.type == "line-of-businesses"
        );
        let resourceRates = response.data.included.filter(
          (item) => item.type == "resource-rates"
        );
        setProjectResources(projectResources);
        setLobs(lobs);
        setResourceRates(resourceRates);
        const paymentInfo = data.attributes["payment-info"];

        let rateType = determineRateType(paymentInfo["rate-type"]);
        setRateType(rateType);

        let costType = determineCostType(paymentInfo["rate-type"]);
        setCostType(costType);

        API.Get(
          `${apiHost}/${account_slug}/v1/resources`,
          authorizationCode
        ).then((response) => {
          let resources = response.data.data;
          setResources(resources);
          setIsLoading(false);
        });
      });
      API.Get(
        `${apiHost}/v1/me`,
        authorizationCode
      ).then((res) => {
        const privileges = res.data.data.attributes.privileges;
        const resourceCostPermission = privileges.find((p) => p.privilege === "projects.resource_costs")["access-level"];
        const resourceRatePermission = privileges.find((p) => p.privilege === "projects.resource_rates")["access-level"];
        setResourceCostPermission(resourceCostPermission);
        setResourceRatePermission(resourceRatePermission);
      })
    }
  }, [authorizationCode]);

  const validateNewResources = (newResources) => {
    let isValid = true;

    let addedResources = projectResources.filter((resource) =>
      resource.id.includes("new")
    );
    if (addedResources.length !== 0 && newResources.length == 0) {
      isValid = false;
    }

    newResources.forEach((resource) => {
      if (resource.attributes.name == "") {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let descriptionsAndRateChanged =
      resourceDescriptionsChanged.concat(resourceRatesChanged);

    let externalNameAndExpenseRatesChanged =
      externalNameChanged.concat(expenseRateChanged);

    let resourcesChanged = descriptionsAndRateChanged.concat(
      externalNameAndExpenseRatesChanged
    );

    let allResourcesChanged = resourcesChanged.concat(resourceCostsChanged);

    const getResourceData = (resource) => {
      let attributes = {};
      if (resource.attributes) {
        attributes = resource.attributes;
        delete resource.attributes.name;
      } else {
        attributes = {
          description: resource.description,
          "hourly-rate": resource.rate,
          "external-name": resource["external-name"],
          "expense-rate": resource["expense-rate"],
          "hourly-cost": resource.cost,
          "code": resource.code,
        };
      }
      return {
        data: {
          type: "project-resources",
          id: resource.id,
          attributes: attributes,
        },
      };
    };

    let promises = [];
    allResourcesChanged.forEach((resource) => {
      promises.push(
        API.Patch(
          `${apiHost}/${account_slug}/v1/project-resources/${resource.id}`,
          getResourceData(resource),
          authorizationCode
        )
      );
    });

    // Post new resources if there are any
    let newResources = new Set(newProjectResources);
    newResources = Array.from(newResources);

    const isValid = validateNewResources(newResources);

    if (!isValid) {
      setErrorMessages(
        "You must select a Resource in order to save new Resources"
      );
      setShowFailAlert(true);
      return;
    }

    if (newResources.length !== 0) {
      newResources.forEach((resource) => {
        let data = getResourceData(resource);
        delete data.data.id;
        data.data.relationships = {
          project: { data: { type: "projects", id: project_id } },
          resource: { data: { type: "resources", id: resource.resourceId } },
          "line-of-business": {
            data: {
              type: "line-of-businesses",
              id: resource.lobId ? resource.lobId : null,
            },
          },
        };
        promises.push(
          API.Post(
            `${apiHost}/${account_slug}/v1/project-resources?include=line-of-business`,
            data,
            authorizationCode
          )
        );
      });
    }

    Promise.all(promises)
      .then((responses) => {
        let resources = projectResources.filter(
          (resource) => resource.id.includes("new") == false
        );
        let createdResourceResponses = responses.filter(
          (response) => response.status == 201
        );
        let newResources = createdResourceResponses.map((res) => res.data.data);
        setProjectResources(resources.concat(newResources));
        setUpdatedAlertIsOpen(true);
        setTimeout(() => {
          setUpdatedAlertIsOpen(false);
        }, 3000);
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "Resource details"));
        setShowFailAlert(true);
      });
  };

  const addResource = () => {
    let resource = {
      type: "project-resources",
      id: `new-${Math.floor(Math.random() * 100)}`,
      attributes: {
        description: "",
        "hourly-rate": "",
        "external-name": "",
        "expense-rate": "",
        "hourly-cost": "",
      },
    };

    setProjectResources([...projectResources, resource]);
  };

  const renderResourceOptions = () => {
    let options = resourceRates
      .map((resource) => {
        let id = resource.id;
        let resourceOption = renderResourceName(resource, lobs);
        return { value: id, label: resourceOption };
      })
      .sort((a, b) => {
        return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
      });
    options.unshift({ value: -1, label: "Pick Resource" });
    return options;
  };

  const getTableData = () => {
    if (projectResources.length === 0) {
      return [];
    }
    return projectResources.map((resource, index) => {
      let defaultResourceRate = resource.attributes["hourly-rate"];
      let defaultResourceCost = resource.attributes["hourly-cost"] || "";
      let defaultResourceDescription = resource.attributes.description;
      let isNewResource = resource.id.includes("new");
      let defaultLobName = "";
      if (
        resource.relationships &&
        resource.relationships["line-of-business"].data
      ) {
        let resourceLob = lobs.filter(
          (item) =>
            item.id == resource.relationships["line-of-business"].data.id
        )[0];
        resource.lobName = resourceLob.attributes.name;
      }

      return {
        rowClass: "resourcesRow",
        onClick: null,
        columns: [
          {
            class: "col-2",
            name: !isNewResource ? (
              <Form.Control
                readOnly
                type="text"
                value={resource.attributes.name}
              />
            ) : (
              <Select
                type="text"
                defaultValue={resource.attributes.name}
                onChange={(e) => {
                  resource.attributes.name = e.label;
                  let resourceId = "";
                  // Match resource rate to resource and lob
                  resourceRates.forEach((resourceRate) => {
                    if (resourceRate.id == e.value) {
                      resourceId = resourceRate.relationships.resource.data.id;
                      resource.resourceId = resourceId;
                      lobs.forEach((lob) => {
                        if (
                          resourceRate.relationships["line-of-business"].data &&
                          lob.id ==
                            resourceRate.relationships["line-of-business"].data
                              .id
                        ) {
                          resource.lobName = lob.attributes.name;
                          resource.lobId = lob.id;
                        }
                      });
                      resource.attributes["external-name"] =
                        resourceRate.attributes["external-name"];
                      resource.attributes.description =
                        resourceRate.attributes.description;
                      resource.attributes["hourly-rate"] =
                        resourceRate.attributes["hourly-rate"];
                      resource.attributes["hourly-cost"] =
                        resourceRate.attributes["hourly-cost"];
                      resource.attributes["expense-rate"] =
                        resourceRate.attributes["expense-rate"];
                      resource.attributes["code"] =
                        resourceRate.attributes["code"];
                    }
                  });
                  setNewProjectResources([...newProjectResources, resource]);
                }}
                options={renderResourceOptions()}
              />
            ),
          },
          {
            class: "col-2",
            name: (
              <Form.Control
                type="text"
                id={resource.id}
                defaultValue={resource.attributes["external-name"]}
                onBlur={(e) => {
                  if (isNewResource) {
                    setNewProjectResources(
                      newProjectResources.map((resource) => {
                        if (resource.id == e.target.id) {
                          resource.attributes["external-name"] = e.target.value;
                          return resource;
                        } else {
                          return resource;
                        }
                      })
                    );
                  }
                  if (!isNewResource) {
                    setExternalNameChanged([
                      ...externalNameChanged,
                      {
                        id: resource.id,
                        "external-name": e.target.value,
                      },
                    ]);
                  }
                }}
              />
            ),
          },

          {
            class: resourceCostPermission !== "none" ? "col-3" : "col-4",
            name: (
              <Form.Control
                type="text"
                id={resource.id}
                defaultValue={defaultResourceDescription}
                onBlur={(e) => {
                  if (isNewResource) {
                    setNewProjectResources(
                      newProjectResources.map((resource) => {
                        if (resource.id == e.target.id) {
                          resource.attributes.description = e.target.value;
                          return resource;
                        } else {
                          return resource;
                        }
                      })
                    );
                  }
                  if (!isNewResource) {
                    setResourceDescriptionsChanged([
                      ...resourceDescriptionsChanged,
                      {
                        id: resource.id,
                        description: e.target.value,
                      },
                    ]);
                  }
                }}
              />
            ),
          },

          {
            class: "col-2",
            name: (
              <Form.Control
                type="text"
                defaultValue={resource.lobName ? resource.lobName : ""}
                readOnly
              />
            ),
          },
          {
            class: resourceRatePermission !== "none"
            ? "col-1 text-right"
            : "displayNone",
            name: resourceRatePermission !== "none" ? (
              <Form.Control
                type="number"
                step={0.01}
                id={resource.id}
                defaultValue={defaultResourceRate}
                disabled={resourceRatePermission == "view"}
                onBlur={(e) => {
                  if (isNewResource) {
                    setNewProjectResources(
                      newProjectResources.map((resource) => {
                        if (resource.id == e.target.id) {
                          resource.attributes["hourly-rate"] = e.target.value;
                          return resource;
                        } else {
                          return resource;
                        }
                      })
                    );
                  }
                  if (!isNewResource) {
                    setResourceRatesChanged([
                      ...resourceRatesChanged,
                      {
                        id: resource.id,
                        rate: e.target.value,
                      },
                    ]);
                  }
                }}
              />
            ) : null,
          },
          {
            class:
              resourceCostPermission !== "none"
                ? "col-1 text-right"
                : "displayNone",
            name:
              resourceCostPermission !== "none" ? (
                <Form.Control
                  type="number"
                  step={0.01}
                  id={resource.id}
                  defaultValue={defaultResourceCost}
                  disabled={resourceCostPermission == "view"}
                  onBlur={(e) => {
                    if (isNewResource) {
                      setNewProjectResources(
                        newProjectResources.map((resource) => {
                          if (resource.id == e.target.id) {
                            resource.attributes["hourly-cost"] = e.target.value;
                            return resource;
                          } else {
                            return resource;
                          }
                        })
                      );
                    }
                    if (!isNewResource) {
                      setResourceCostsChanged([
                        ...resourceCostsChanged,
                        {
                          id: resource.id,
                          cost: e.target.value,
                        },
                      ]);
                    }
                  }}
                />
              ) : null,
          },
          {
            class: "col-1 text-right",
            name: (
              <Form.Control
                type="number"
                step={0.01}
                id={resource.id}
                defaultValue={resource.attributes["expense-rate"]}
                onBlur={(e) => {
                  if (isNewResource) {
                    setNewProjectResources(
                      newProjectResources.map((resource) => {
                        if (resource.id == e.target.id) {
                          resource.attributes["expense-rate"] = e.target.value;
                          return resource;
                        } else {
                          return resource;
                        }
                      })
                    );
                  }
                  if (!isNewResource) {
                    setExpenseRateChanged([
                      ...expenseRateChanged,
                      {
                        id: resource.id,
                        "expense-rate": e.target.value,
                      },
                    ]);
                  }
                }}
              />
            ),
          },
        ],
        actions: null,
      };
    });
  };

  const getFooterData = () => {
    let content = (
      <>
        <SeafoamButton
          style={{ marginRight: "20px" }}
          type="submit"
          text="Save"
          // onClick={(e) => handleSubmit(e)}
        />
        <SeafoamButton type="button" text="Add" onClick={() => addResource()} />
      </>
    );
    return {
      rowClass: "col-12",
      columns: [{ class: "resourcesFooter", content: content }],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "col-2",
        name: "Resource",
      },
      {
        class: "col-2",
        name: "External Name",
      },
      {
        class: resourceCostPermission !== "none" ? "col-3" : "col-4",
        name: "Description",
      },
      {
        class: "col-2",
        name: "LOB/Category",
      },
      {
        class: resourceRatePermission !== "none" ? "col-1" : "displayNone",
        name: resourceCostPermission !== "none" ? rateType : null,
      },
      {
        class: resourceCostPermission !== "none" ? "col-1" : "displayNone",
        name: resourceCostPermission !== "none" ? costType : null,
      },
      {
        class: "col-1",
        name: "Travel & Expense Rate",
      },
    ],
  };

  const tableData = getTableData();
  const footerData = getFooterData();

  let resourcesTable = new ScopeStackTable(
    "resources",
    headerData,
    tableData,
    footerData
  );

  const resourceUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="governanceAlerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Project Resource details updated"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleFailAlert()}
      {resourceUpdatedAlert()}
      <Card>
        <CardHeader title="Project Resources" />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <BaseTable
              className="resourcesTable"
              striped={false}
              bordered={false}
              hover={false}
              headerRows={resourcesTable.buildHeaderRows()}
              dataRows={resourcesTable.buildDataRows()}
              footerRows={resourcesTable.buildFooterRows()}
              isLoading={isLoading}
            />
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Resources;
