import React, { useRef } from "react";
import { faUpFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileDrop } from "react-file-drop";
import "./style.css";

function FileUploadDragAndDrop({
  handleFileSelect,
  maxFileSize = 5242880,
  text = "Click or drag and drop to upload",
}) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const onFileInputChange = (event) => {
    const { files } = event.target;
    if (files && files[0]) {
      if (maxFileSize && files[0].size > maxFileSize) {
        alert(`File size should not exceed ${maxFileSize / 1024 / 1024} MB`);
        return;
      }
      handleFileSelect(files[0]);
    }
  };

  //on click on the dropzone
  const onTargetClick = (e) => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
      if (e.target.files?.[0]) {
        handleFileSelect(e.target.files[0]);
      }
    }
  };
  //on dropping file on the drop zone
  const ondrop = function (fileList: any, event) {
    event.preventDefault();
    const file = fileList[0];
    handleFileSelect(file);
  };
  return (
    <FileDrop onDrop={ondrop} onTargetClick={onTargetClick}>
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type="file"
        className="displayNone"
      />
      <div className="fileUploadBox">
        <FontAwesomeIcon
          style={{ width: "48px", height: "48px" }}
          className="instructionalText bold text-center"
          icon={faUpFromBracket}
        />
        <p className="instructionalText bold text-center">{text}</p>
      </div>
    </FileDrop>
  );
}

export default FileUploadDragAndDrop;
