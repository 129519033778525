import { faCirclePlus } from "@fortawesome/pro-light-svg-icons";
import React, { useState, useEffect, useContext } from "react";
import AccordianDrop from "@components/AccordianDrop/AccordianDrop";
import { Row, Col, Card } from "react-bootstrap";
import "../NewProjectDetails/NewProjectDetails.css";
import AddByBlueprint from "./Blueprint/AddByBlueprint";
import ApplyBlueprint from "./Blueprint/ApplyBlueprint";
import Standard from "./Standard/Standard";
import ServicesAdded from "./ServicesAdded/ServicesAdded";
import ApplyStandards from "./Standard/ApplyStandards";
import CustomService from "./CustomService/CustomService";
import { getAccordianHeaderMarkup } from "./serviceHelpers";
import ApplyCustomService from "./CustomService/ApplyCustomService";
import Questionnaire from "./Questionnaire/Questionnaire";
import API from "@API";
import ApplyQuestionnaire from "./Questionnaire/ApplyQuestionnaire";
import { AccountContext } from "../../ProjectsMspa";
import { Button } from "react-bootstrap";
import useProjectServicesData from "./api/useProjectServicesData";

function Services({
  account_slug,
  authorizationCode,
  projectId,
  patchProjectUrl,
  setCurrentPage,
  setPageReturn,
  pageReturn,
  projectServices,
  onData,
  resourceRatesEnabled,
  rateTableId,
}) {
  // Env/ API
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  // States
  const [subpage, setSubpage] = useState("accordians");
  const [phasesOnAccount, setPhasesOnAccount] = useState([]);
  const [servicesOnBlueprint, setServicesOnBlueprint] = useState([]);
  const [servicesNeedingReassignment, setServicesNeedingReassignment] =
    useState([]);
  const [subservicesOnBlueprint, setSubservicesOnBlueprint] = useState([]);
  const [phasesOnBlueprint, setPhasesOnBlueprint] = useState([]);
  const [servicesOnStandard, setServicesOnStandard] = useState([]);
  const [subservicesOnStandard, setSubservicesOnStandard] = useState([]);
  const [phasesOnStandard, setPhasesOnStandard] = useState([]);
  const [servicesHaveBeenAdded, setServicesHaveBeenAdded] = useState(false);
  const [customServices, setCustomServices] = useState([]);
  const [subservicesOnCustomServices, setSubservicesOnCustomServices] =
    useState([]);
  const [phasesOnCustomServices, setPhasesOnCustomServices] = useState([]);
  const [resources, setResources] = useState([]);
  const [resourceRates, setResourceRates] = useState([]);
  const [lobs, setLobs] = useState([]);
  const [projectResources, setProjectResources] = useState([]);
  const [rateType, setRateType] = useState("Unit Hours");

  const [servicesOnQuestionnaire, setServicesOnQuestionnaire] = useState([]);
  const [subservicesOnQuestionnaire, setSubservicesOnQuestionnaire] = useState(
    []
  );
  const [phasesOnQuestionnaire, setPhasesOnQuestionnaire] = useState([]);

  const handleServicesData = (data) => {
    onData(data);
  };

  useEffect(() => {
    if (projectServices) {
      if (projectServices.length !== 0) {
        setSubpage("servicesAdded");
      }
    }

    // Get resources
    API.Get(
      `${apiHost}/${account_slug}/v1/resources?filter[active]=true&page[size]=1000`,
      authorizationCode
    ).then((response) => {
      let data = response.data.data;
      if (data !== null) setResources(data);
    });

    // Get lobs
    API.Get(
      `${apiHost}/${account_slug}/v1/line-of-businesses?filter[active]=true&page[size]=1000`,
      authorizationCode
    ).then((response) => {
      let data = response.data.data;
      if (data !== null) setLobs(data);
    });

    //Project resources
    API.Get(
      `${apiHost}/${account_slug}/v1/project-resources?filter[project]=${projectId}`,
      authorizationCode
    ).then((response) => {
      let data = response.data.data;
      if (data !== null) setProjectResources(data);
    });

    //Resource rates
    API.Get(
      `${apiHost}/${account_slug}/v1/resource-rates?filter[rate-table]=${rateTableId}&page[size]=1000&include=resource`,
      authorizationCode
    ).then((response) => {
      const data = response.data.data;
      setResourceRates(data);
    });

    //Get Phases on Account
    API.Get(`${apiHost}/${account_slug}/v1/phases`, authorizationCode).then(
      (res) => {
        const data = res.data.data;
        setPhasesOnAccount(data);
      }
    );

    // Get Rate Type
    API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
      (response) => {
        const data = response.data.data;
        if (data && data !== "") {
          setRateType(data.attributes["rate-type"] + "s");
        }
      }
    );
    // Add grey background to body
    document.body.setAttribute("style", "background: #eee");
  }, [projectServices]);

  const account = useContext(AccountContext);

  const assignResources = (services) => {
    return services.map((service) => {
      if (
        (service.type === "subservices" ||
          service.type == "project-subservices") &&
        service.resource
      )
        return service;
      const resourceId = service?.relationships?.resource?.data?.id;
      const resourceRate = resourceRates.find(
        (rr) => rr.relationships?.resource?.data?.id === resourceId
      );
      return {
        ...service,
        resource:
          resourceRatesEnabled && resourceRate
            ? `resource-rates-${resourceRate.id}`
            : resourceId
            ? `resources-${resourceId}`
            : undefined,
      };
    });
  };

  const ServiceAccordans = () => {
    return (
      <>
        <Card>
          <Card.Body>
            <Card.Header className="cardHeaderOverride">
              <div className="headerContainer">
                <div className="actionBtns">
                  <Button
                    className="btnSeawhite"
                    onClick={() => backToSummary()}
                  >
                    Back
                  </Button>
                  <h2 style={{ marginLeft: "20px" }}>
                    <span className="text24">About Services</span>
                  </h2>
                </div>
              </div>
            </Card.Header>
            <Row>
              <Col>
                <div className="cardSubtitle">
                  Choose the way that you would like to add services to this
                  project.
                </div>
              </Col>
            </Row>
            <Row></Row>
            {account.planDetails.questionnaires && (
              <Row
                className="accordianRedirect"
                onClick={() => setSubpage("addByQuestionnaire")}
              >
                <Col>
                  <AccordianDrop
                    header={getAccordianHeaderMarkup(
                      "Add Services From Survey",
                      "Fill out a survey, created by your organization, to suggest services for a given solution."
                    )}
                    body={null}
                    iconOpen={faCirclePlus}
                    iconClosed={faCirclePlus}
                    closeable={false}
                    setAccordianIsOpen={null}
                    className="questionnaireAccordian mainAccordion"
                  />
                </Col>
              </Row>
            )}
            <Row
              className="accordianRedirect"
              onClick={() => setSubpage("addBlueprint")}
            >
              <Col>
                <AccordianDrop
                  header={getAccordianHeaderMarkup(
                    "Add Services From Blueprint",
                    "Blueprints are collections of pre-defined services that represent completed solutions."
                  )}
                  body={null}
                  iconOpen={faCirclePlus}
                  iconClosed={faCirclePlus}
                  closeable={false}
                  setAccordianIsOpen={null}
                  className="blueprintAccordian mainAccordion"
                />
              </Col>
            </Row>
            <Row
              className="accordianRedirect"
              onClick={() => setSubpage("addServicesFromStandard")}
            >
              <Col>
                <AccordianDrop
                  header={getAccordianHeaderMarkup(
                    "Add Services From Standards",
                    "Standards are services pre-configured by your organization."
                  )}
                  body={null}
                  iconOpen={faCirclePlus}
                  iconClosed={faCirclePlus}
                  closeable={false}
                  setAccordianIsOpen={null}
                  className="standardServicesAccordian mainAccordion"
                />
              </Col>
            </Row>
            <Row
              className="accordianRedirect"
              onClick={() => setSubpage("addCustomServices")}
            >
              <Col>
                <AccordianDrop
                  header={getAccordianHeaderMarkup(
                    "Add Custom Services",
                    "Create a-la-carte services and add them to your project one-by-one."
                  )}
                  body={null}
                  iconOpen={faCirclePlus}
                  iconClosed={faCirclePlus}
                  closeable={false}
                  setAccordianIsOpen={null}
                  className="standardServicesAccordian mainAccordion"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  const renderSubpages = () => {
    switch (subpage) {
      case "addBlueprint":
        return (
          <AddByBlueprint
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            setServicesOnBlueprint={(array) => setServicesOnBlueprint(array)}
            setServicesNeedingReassignment={(array) =>
              setServicesNeedingReassignment(array)
            }
            setSubservicesOnBlueprint={(array) =>
              setSubservicesOnBlueprint(array)
            }
            resources={resources}
            setPhasesOnBlueprint={(array) => setPhasesOnBlueprint(array)}
            servicesHaveBeenAdded={servicesHaveBeenAdded}
            rateType={rateType}
            phasesOnAccount={phasesOnAccount}
            resourceRatesEnabled={resourceRatesEnabled}
            resourceRates={resourceRates}
            lob={lobs}
          />
        );
      case "applyBlueprint":
        return (
          <ApplyBlueprint
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            services={servicesOnBlueprint}
            servicesNeedingReassignment={servicesNeedingReassignment}
            resourcesOnBlueprints={resources}
            subservicesOnBlueprint={subservicesOnBlueprint}
            phasesOnBlueprint={phasesOnBlueprint}
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            projectId={projectId}
            resources={resources}
            rateType={rateType}
            phasesOnAccount={phasesOnAccount}
            resourceRatesEnabled={resourceRatesEnabled}
            resourceRates={resourceRates}
            lobs={lobs}
          />
        );
      case "addServicesFromStandard":
        return (
          <Standard
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            setServicesOnStandard={(array) => {
              setServicesOnStandard(assignResources(array));
            }}
            setSubservicesOnStandard={(array) =>
              setSubservicesOnStandard(assignResources(array))
            }
            setPhasesOnStandard={(array) => setPhasesOnStandard(array)}
            servicesHaveBeenAdded={servicesHaveBeenAdded}
            resources={resources}
            rateType={rateType}
          />
        );
      case "servicesAdded":
        return (
          <ServicesAdded
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            projectId={projectId}
            setServicesHaveBeenAdded={(bool) => setServicesHaveBeenAdded(bool)}
            onData={onData}
            onServicesData={handleServicesData}
            resources={resources}
            rateType={rateType}
            continueProjectCreationWorkflow={() => setCurrentPage(3)}
            resourceRatesEnabled={resourceRatesEnabled}
            resourceRates={resourceRates}
            lobs={lobs}
          />
        );
      case "applyStandards":
        return (
          <ApplyStandards
            servicesAdded={servicesOnStandard}
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            subservicesOnServicesAdded={subservicesOnStandard}
            projectId={projectId}
            phasesOnStandard={phasesOnStandard}
            resources={resources}
            rateType={rateType}
            resourceRatesEnabled={resourceRatesEnabled}
            resourceRates={resourceRates}
            lobs={lobs}
          />
        );
      case "addCustomServices":
        return (
          <CustomService
            authorizationCode={authorizationCode}
            account_slug={account_slug}
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            setCustomServices={(array) => setCustomServices(array)}
            servicesHaveBeenAdded={servicesHaveBeenAdded}
            setSubservicesOnCustomServices={(array) =>
              setSubservicesOnCustomServices(array)
            }
            setPhasesOnCustomServices={(array) =>
              setPhasesOnCustomServices(array)
            }
            projectId={projectId}
            resources={resources}
            rateType={rateType}
            resourceRatesEnabled={resourceRatesEnabled}
            resourceRates={resourceRates}
            projectResources={projectResources}
            lobs={lobs}
          />
        );
      case "applyCustomServices":
        return (
          <ApplyCustomService
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            services={customServices}
            resources={resources}
            subservices={subservicesOnCustomServices}
            authorizationCode={authorizationCode}
            phases={phasesOnCustomServices}
            account_slug={account_slug}
            projectId={projectId}
            rateType={rateType}
            resourceRatesEnabled={resourceRatesEnabled}
            resourceRates={resourceRates}
            lobs={lobs}
          />
        );
      case "addByQuestionnaire":
        return (
          <Questionnaire
            setSubpage={(subpage) => setSubpage(subpage)}
            servicesHaveBeenAdded={servicesHaveBeenAdded}
            setServices={(services) => {
              setServicesOnQuestionnaire(assignResources(services));
            }}
            setResources={setResources}
            setSubservices={(subservices) => {
              setSubservicesOnQuestionnaire(assignResources(subservices));
            }}
            setPhases={setPhasesOnQuestionnaire}
            projectId={projectId}
            resourceRatesEnabled={resourceRatesEnabled}
            resourceRates={resourceRates}
            projectResources={projectResources}
            lobs={lobs}
          />
        );
      case "applyQuestionnaire":
        return (
          <ApplyQuestionnaire
            subpage={subpage}
            setSubpage={(subpage) => setSubpage(subpage)}
            services={servicesOnQuestionnaire}
            resources={resources}
            subservices={subservicesOnQuestionnaire}
            phases={phasesOnQuestionnaire}
            projectId={projectId}
            rateType={rateType}
            resourceRatesEnabled={resourceRatesEnabled}
            resourceRates={resourceRates}
            lobs={lobs}
          />
        );
      default:
        return <ServiceAccordans />;
    }
  };

  const backToSummary = () => {
    setCurrentPage(1);
    setPageReturn(true);
  };

  return <div className="details">{renderSubpages()}</div>;
}

export default Services;
