import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import { useNavigate } from "react-router-dom";
import AddressForm from "@components/Forms/AddressForm";
import ProjectVariables from "../../ProjectVariables/ProjectVariables";
import {
  formatDate,
  formatErrorResponse,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";

function Location({
  account_slug,
  project_id,
  projectStatus,
  locationId,
  account_id,
  locations,
  setLocationUpdated,
  setLocationCreated,
  projVariables,
  authorizationCode,
}) {
  // Alerts
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [locationErrors, setLocationErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [locationExistsAlertIsOpen, setLocationExistsAlertIsOpen] =
    useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  // Project Variables
  const [showMinCharAlert, setShowMinCharAlert] = useState(false);
  const [showMaxCharAlert, setShowMaxCharAlert] = useState(false);
  const [minValue, setMinValue] = useState(false);
  const [maxValue, setMaxValue] = useState(false);
  const [unrequiredProjectVariables, setUnrequiredProjectVariables] = useState(
    []
  );
  const [requiredProjectVariables, setRequiredProjectVariables] = useState([]);
  const today = new Date();

  // Location
  const [customerSite, setCustomerSite] = useState("");
  const [remoteSite, setRemoteSite] = useState("");
  const [address, setAddress] = useState(null);
  const [street2, setStreet2] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zip, setZip] = useState(null);
  const [country, setCountry] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [remoteLocation, setRemoteLocation] = useState(false);

  // Envs
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  let navigate = useNavigate();

  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  useEffect(() => {
    // Project Variables
    if (projVariables.length !== 0 && lastWordInLocation == "new") {
      let reqProjectVariables = projVariables.filter((variable) => {
        if (variable.required === true) {
          return variable.required;
        }
      });
      reqProjectVariables = reqProjectVariables.map((variable) => {
        if (variable["variable-type"] === "date") {
          return { ...variable, value: formatDate(today) };
        } else {
          return { ...variable, value: "" };
        }
      });
      setRequiredProjectVariables(reqProjectVariables);
      let unreqProjectVariables = projVariables.filter((variable) => {
        if (variable.required === false) {
          return variable;
        }
      });
      unreqProjectVariables = unreqProjectVariables.map((variable) => {
        return { ...variable, value: "" };
      });
      setUnrequiredProjectVariables(unreqProjectVariables);
    }

    if (lastWordInLocation === "edit") {
      for (let i = 0; i < locations.length; i++) {
        if (locations[i].id === id) {
          setCustomerSite(locations[i].attributes.name);
          setRemoteSite(locations[i].attributes.remote);
          setAddress(locations[i].attributes.street);
          setStreet2(locations[i].attributes.street2);
          setCity(locations[i].attributes.city);
          setState(locations[i].attributes.state);
          setZip(locations[i].attributes["postal-code"]);
          setCountry(locations[i].attributes.country);

          // Project Variables
          let projVariables = locations[i].attributes["project-variables"];
          if (projVariables.length !== 0) {
            let reqProjVariables = projVariables.filter((variable) => {
              if (variable.required === true) {
                return variable;
              }
            });
            setRequiredProjectVariables(reqProjVariables);
            let unreqProjVariables = projVariables.filter((variable) => {
              if (variable.required === false) {
                return variable;
              }
            });
            setUnrequiredProjectVariables(unreqProjVariables);
          }
        }
      }
    }
  }, [locations, projVariables]);

  const redirectToProjectLocations = () => {
    navigate(`/projects/${project_id}/project_locations/`);
    window.location.reload();
  };

  const validateVariables = (allProjectVariablesArray) => {
    var projectIsValid = true;
    var maximumValue = "";
    var minimumValue = "";

    for (let i = 0; i < allProjectVariablesArray.length; i++) {
      let variableType = "";
      if (lastWordInLocation === "new") {
        variableType = allProjectVariablesArray[i]["variable-type"];
      }
      if (lastWordInLocation !== "new") {
        variableType = allProjectVariablesArray[i]["variable_type"];
      }
      if (
        variableType === "text" &&
        allProjectVariablesArray[i]["select_options"].length === 0
      ) {
        minimumValue = allProjectVariablesArray[i].minimum;

        maximumValue = allProjectVariablesArray[i].maximum;

        let value = allProjectVariablesArray[i].value;
        if (value && minimumValue != null && value.length < minimumValue) {
          setMinValue(minimumValue);
          setShowMinCharAlert(true);
          projectIsValid = false;
        }
        if (value && maximumValue != null && value.length > maximumValue) {
          setMaxValue(maximumValue);
          setShowMaxCharAlert(true);
          projectIsValid = false;
        }
      }
    } // End for loop

    return projectIsValid;
  };

  const validateForm = () => {
    let isValid = true;
    let errors = [];
    if (lastWordInLocation === "new") {
      for (let i = 0; i < locations.length; i++) {
        if (
          customerSite.toLowerCase() ==
          locations[i].attributes.name.toLowerCase()
        ) {
          isValid = false;
          setLocationExistsAlertIsOpen(true);
        }
      }
    }
    if (customerSite === null || customerSite === "") {
      isValid = false;
      errors.push("Project locations name can't be blank");
    }
    if (!remoteSite) {
      if (address === null || address === "") {
        isValid = false;
        errors.push("Project locations street can't be blank");
      }
      if (city === null || city === "") {
        isValid = false;
        errors.push("Project locations city can't be blank");
      }
      if (zip === null || zip === "") {
        isValid = false;
        errors.push("Project locations postal code can't be blank");
      }
    }
    setLocationErrors(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formIsValid = validateForm();
    if (formIsValid === false) {
      setShowErrors(true);
      return;
    }

    // Project Variable Validation
    const allProjectVariables = requiredProjectVariables.concat(
      unrequiredProjectVariables
    );
    const projectVariablesAreValid = validateVariables(allProjectVariables);
    if (projectVariablesAreValid === false) {
      return;
    }

    const getUpdatedProjectVariables = () => {
      let newProjVariables = allProjectVariables.map((variable) => {
        return { name: variable.name, value: variable.value };
      });
      return newProjVariables;
    };

    // Edit Location Page
    if (lastWordInLocation === "edit") {
      const locationData = {
        data: {
          type: "project-locations",
          id: id,
          attributes: {
            name: customerSite,
            street: address,
            street2: street2,
            city: city,
            state: state,
            "postal-code": zip,
            country: countryId,
            remote: remoteSite,
            "project-variables": getUpdatedProjectVariables(),
          },
          relationships: {
            project: { data: { type: "projects", id: project_id } },
          },
        },
      };
      API.Patch(
        `${apiHost}/${account_slug}/v1/project-locations/${id}`,
        locationData,
        authorizationCode
      )
        .then((response) => {
          setLocationUpdated(true);
          setUpdatedAlertIsOpen(true);
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "location"));
          setShowFailAlert(true);
        });
    }

    // New Location Page
    if (lastWordInLocation === "new") {
      const newLocation = {
        data: {
          type: "project-locations",
          attributes: {
            name: customerSite,
            street: address,
            street2: street2,
            city: city,
            state: state,
            "postal-code": zip,
            country: countryId,
            remote: remoteSite,
            "project-variables": getUpdatedProjectVariables(),
          },
          relationships: {
            project: {
              data: {
                type: "projects",
                id: project_id,
              },
            },
          },
        },
      };

      API.Post(
        `${apiHost}/${account_slug}/v1/project-locations/`,
        newLocation,
        authorizationCode
      )
        .then((response) => {
          setLocationCreated(true);
          redirectToProjectLocations();
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "location"));
          setShowFailAlert(true);
        });
    }
  };

  const locationUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="locationAlerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Location updated"
        />
      );
    }
  };

  const handleInvalidFormAlert = () => {
    if (showErrors === true) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setShowErrors(false)}
          text={
            locationErrors.length > 1
              ? locationErrors.join(", ")
              : locationErrors
          }
        />
      );
    }
  };

  const handleLocationExistsAlert = () => {
    if (locationExistsAlertIsOpen === true) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setLocationExistsAlertIsOpen(false)}
          text="Location name already exists. Please enter a different one."
        />
      );
    }
  };

  // Project Variables
  const handleCharacterAlert = () => {
    if (showMinCharAlert === true) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setShowMinCharAlert(false)}
          text={`Required text is too short (minimum is ${minValue} characters)`}
        />
      );
    } else if (showMaxCharAlert === true) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setShowMaxCharAlert(false)}
          text={`Required text is too long (maximum is ${maxValue} characters)`}
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  if (locationExistsAlertIsOpen && showErrors) {
    setShowErrors(false);
  }

  if (
    (updatedAlertIsOpen && showMinCharAlert) ||
    (updatedAlertIsOpen && showMaxCharAlert)
  ) {
    setShowMaxCharAlert(false);
    setShowMinCharAlert(false);
  }

  return (
    <div>
      {handleFailAlert()}
      {locationUpdatedAlert()}
      {handleLocationExistsAlert()}
      {handleInvalidFormAlert()}
      {handleCharacterAlert()}
      <Card>
        <CardHeader title="Location" />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <AddressForm
              label="Location"
              placeholder="Customer Site"
              siteName={customerSite}
              onSiteChange={(e) => {
                setCustomerSite(e.target.value);
              }}
              remoteLocation={remoteSite}
              onRemoteChanged={(e) => {
                setRemoteSite(e.target.checked);
              }}
              addressText={address}
              onAddressChange={(e) => {
                setAddress(e.target.value);
              }}
              cityText={city}
              onCityChange={(e) => {
                setCity(e.target.value);
              }}
              stateText={state}
              onStateChange={(e) => {
                setState(e.target.value);
              }}
              zipText={zip}
              onZipChange={(e) => {
                setZip(e.target.value);
              }}
              street2Text={street2}
              onStreet2Change={(e) => {
                setStreet2(e.target.value);
              }}
              country={country}
              onCountryChange={(e) => {
                setCountry(e);
                setCountryId(e.id);
              }}
            />
            <Row>
              <ProjectVariables
                arr={requiredProjectVariables}
                account_slug={account_slug}
                project_id={project_id}
                setState={setRequiredProjectVariables}
                setVariableChanged={null}
              />
              <ProjectVariables
                arr={unrequiredProjectVariables}
                account_slug={account_slug}
                project_id={project_id}
                setState={setUnrequiredProjectVariables}
                setVariableChanged={null}
              />
            </Row>
            <Row>
              <Col xs={6}>
                {projectStatus === "building" && <SubmitButton />}
              </Col>
              <Col xs={6}>
                <BackButton url={`/projects/${project_id}/project_locations`} />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Location;
