import React, { useEffect, useState } from "react";
import axios from "axios";
import FormTextField from "@components/Forms/FormTextField";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import Survey from "../SurveyHelpers/Survey";
import { Row, Col, Card, Form } from "react-bootstrap";
import { QuestionnaireType } from "../types/QuestionnareType";
import { QuestionType } from "../types/QuestionType";
import "../style.css";
import { SurveyFromAPI } from "../types/SurveyFromAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import {
  formatUnprocessibleResponse,
  handleAlert,
} from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";

function TakeSharedSurvey({ account_slug }) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 1];
  const [email, setEmail] = useState("");
  const [sharedSurveyData, setSharedSurveyData] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [survey, setSurvey] = useState<SurveyFromAPI>();
  const [surveyResponses, setSurveyResponses] = useState<any[]>([]);
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireType>();
  const [questions, setQuestions] = useState<QuestionType[]>();
  const [calculateClicked, setCalculateClicked] = useState(false);
  const [recalculateClicked, setRecalculateClicked] = useState(false);
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [name, setName] = useState("");

  const retreiveSharedSurveyData = () => {
    setDataLoading(true);
    axios
      .get(
        `${apiHost}/${account_slug}/v1/shared-surveys/${id}?include=questionnaire,questions,survey`,
        {
          headers: { Authorization: `${btoa(email.trim().toLowerCase())}` },
        }
      )
      .then((res) => {
        if (res.data.data.attributes["completed-by"]) {
          setName(res.data.data.attributes["completed-by"]);
        }
        setSurvey(res.data.data);
        setSurveyResponses(res.data.data.attributes.responses);
        setQuestionnaire(
          res.data.included.filter((item) => item.type === "questionnaires")[0]
        );
        setQuestions(
          res.data.included.filter((item) => item.type === "questions")
        );
        setDataLoading(false);
        setShowEmailModal(false);
      })
      .catch((err) => {
        setDataLoading(false);
        setShowEmailModal(true);
        setErrorMessages(
          "Email could not be validated. Please enter the email of the shared survey recipient."
        );
        setShowFailAlert(true);
      });
  };

  useEffect(() => {
    setShowEmailModal(true);
  }, []);

  const handleSurveySubmit = (e) => {
    e.preventDefault();
    const sharedSurveyData = {
      data: {
        type: "shared-surveys",
        id: survey?.id,
        attributes: {
          responses: surveyResponses,
          "completed-by": name,
        },
      },
    };
    axios
      .patch(
        `${apiHost}/${account_slug}/v1/shared-surveys/${id}`,
        sharedSurveyData,
        {
          headers: {
            Authorization: `${btoa(email)}`,
            "Content-Type": "application/vnd.api+json",
          },
        }
      )
      .then((res) => {
        setSurveySubmitted(true);
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "shared survey"));
        setShowFailAlert(true);
      });
  };

  if (dataLoading) {
    return <ScopeStackSpinner />;
  }
  return (
    <main className="takeASharedSurveyPage">
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <ScopeStackModal
        modalTitle="Validate Your Email To Access The Survey"
        modalBody={
          <div className="regularText">
            <p>Enter the email Address the survey was sent to.</p>
            <FormTextField
              id="email"
              placeholder={""}
              label="*Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
              readOnly={false}
            />
          </div>
        }
        button1Text={null}
        handleButton1Click={null}
        button2Text="Validate"
        handleButton2Click={() => retreiveSharedSurveyData()}
        show={showEmailModal}
        handleClose={null}
      />
      {!showEmailModal ? (
        <div>
          <Row className="flexCenter">
            <Col sm={10} className="takeSurveyName">
              {questionnaire?.attributes.name}
            </Col>
            <Col className="pull-right">
              <div className="scopestackLogo"></div>
            </Col>
          </Row>
          <hr />
          {!surveySubmitted ? (
            <div>
              <Card className="whiteBg takeASurveyOuterCard">
                <Card.Header className="cardHeaderOverride takeASurveyCardHeader">
                  <Row className="flexCenter">
                    <Col sm={8}>About This Survey</Col>
                  </Row>
                  <div className="surveyIntro">
                    {questionnaire?.attributes.introduction}
                  </div>
                </Card.Header>
                <hr />
                <Card.Body>
                  <Survey
                    questionnaire={questionnaire}
                    questions={questions}
                    setSurveyResponses={(r) => setSurveyResponses(r)}
                    surveyResponses={surveyResponses}
                    handleSurveySubmit={(e) => handleSurveySubmit(e)}
                    showRecommendations={false}
                    isFromSurveyPage={true}
                    currentSurvey={survey}
                    setRecalculateClicked={(bool) =>
                      setRecalculateClicked(bool)
                    }
                    isNewSurvey={
                      surveyResponses && surveyResponses.length === 0
                    }
                    calculateClicked={calculateClicked}
                    setCalculateClicked={(bool) => setCalculateClicked(bool)}
                    revertClicked={false}
                    originalSurveyResponses={surveyResponses}
                    isTakeSharedSurvey={true}
                    name={name}
                    setName={(n) => setName(n)}
                  />
                </Card.Body>
              </Card>
            </div>
          ) : (
            <div className="surveySuccessContainer">
              <h2 className="surveySuccessTitle">
                <FontAwesomeIcon style={{ color: "#418172" }} icon={faCheck} />
                &nbsp;&nbsp;Survey Submitted Successfully!
              </h2>
            </div>
          )}
        </div>
      ) : null}
    </main>
  );
}

export default TakeSharedSurvey;
