import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProjectNotes from "./ProjectNotes/ProjectNotes";
import NewNote from "./NewNote/NewNote";
import EditNote from "./EditNote/EditNote";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";

function NotesMspa({ account_slug, project_id, account_id }) {
  const [notes, setNotes] = useState([]);
  const [noteId, setNoteId] = useState(null);
  const [noteCreated, setNoteCreated] = useState(false);
  const [noteUpdated, setNoteUpdated] = useState(false);
  const [projectStatus, setProjectStatus] = useState("");
  const [currentUser, setCurrentUser] = useState("");

  const [authorizationCode] = useOauth();

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);

  // When page loads, call API to get Notes and lift it up to the state
  useEffect(() => {
    if (authorizationCode != "") {
      //Get Project Status
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        const status = data.attributes.status;
        if (status !== null) setProjectStatus(status);

        //Get Notes
        API.Get(data.relationships.notes.links.related, authorizationCode).then(
          (response) => {
            let notes = response.data.data;
            setNotes(notes);
            setIsLoading(false);
          }
        );
      });

      // Get current user
      API.Get(
        `${apiHost}/${account_slug}/v1/users/current`,
        authorizationCode
      ).then((response) => {
        setCurrentUser(response.data.attributes.name);
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/notes"}
            element={
              <ProjectNotes
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                noteId={noteId}
                setNoteId={(noteId) => setNoteId(noteId)}
                projectStatus={projectStatus}
                notes={notes}
                noteCreated={noteCreated}
                noteUpdated={noteUpdated}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/notes/new"}
            element={
              <NewNote
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                notes={notes}
                currentUser={currentUser}
                setNoteCreated={(created) => setNoteCreated(created)}
              />
            }
          />
          <Route
            path={"/projects/:project_id/notes/:noteId/edit"}
            element={
              <EditNote
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                notes={notes}
                currentUser={currentUser}
                noteId={noteId}
                setNoteUpdated={(updated) => setNoteUpdated(updated)}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default NotesMspa;
