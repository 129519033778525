import React from "react";
import { Table } from 'react-bootstrap';

function StripedTable({head1, className1, head2, className2, head3, className3, head4, className4, head5, className5, head6, className6, head7, className7, rows, actionComponent }) {
  return (
    <Table striped hover>
      <thead>
        <tr>
          <th className={className1}>{head1}</th>
          <th className={className2}>{head2}</th>
          <th className={className3}>{head3}</th>
          <th className={className4}>{head4}</th>
          <th className={className5}>{head5}</th>
          <th className={className6}>{head6}</th>
          <th className={className7}>{head7}</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="7">
            <div>
              {actionComponent}
            </div>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}

export default StripedTable;
