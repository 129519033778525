import { ChangeEvent, ReactElement } from "react";
import { Form } from "react-bootstrap";
import FormFieldLabel from "./FormFieldLabel/FormFieldLabel";

interface Props {
  label: string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
  value?: string;
  defaultValue?: string;
  options: ReactElement[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required: boolean;
  readOnly: boolean;
  disabled: boolean;
  fieldFooter?: ReactElement | string | null;
  placeholder?: string;
}

const ControlledDropDownV2 = ({
  label,
  value = "",
  defaultValue = "",
  options,
  onChange,
  required,
  readOnly,
  disabled,
  fieldFooter,
  placeholder
}: Props): JSX.Element => {
  return (
    <Form.Group>
      <FormFieldLabel className="" label={label} />
      <Form.Control
        as="select"
        value={value}
        defaultValue={defaultValue}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
      >
        {options}
      </Form.Control>
      {fieldFooter}
    </Form.Group>
  );
};

export default ControlledDropDownV2;
