import { useListTagsForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { Tag } from "../types";
const useListTagsForAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  let customError: string | null = null; // Declare customError
  const {
    data,
    error,
    isLoading: tagsLoading,
  } = useListTagsForAccountQuery({ slug: accountSlug });
  let tags: Tag[] = [];
  if (data?.data) {
    try {
      tags = data.data.map((tag) => {
        if (!tag.id) {
          throw new Error("Missing tag.id");
        }
        if (!tag.attributes.name) {
          throw new Error("Missing tag.attributes.name");
        }
        return {
          id: tag.id,
          name: tag.attributes.name,
        };
      });
    } catch (e) {
      customError = "An unknown error occurred";
    }
  }
  return { tags, isLoading: tagsLoading, error: error ?? customError };
};
export default useListTagsForAccount;
