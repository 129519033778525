import React from "react";
import { Col } from "react-bootstrap";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";

function SubmitAndRequestBtns({ onClick, style, label }) {
  return (
    <>
      <Col>
        <SubmitButton />
      </Col>
      <Col>
        <div style={style} onClick={onClick}>
          <p className="btn btn-success">
            <i className="fa fa-rss"></i> {label}
          </p>
        </div>
      </Col>
    </>
  );
}

export default SubmitAndRequestBtns;
