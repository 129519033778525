import { useListServiceCategorysForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";

interface Option {
  label: string;
  value: string;
}

const useListServiceCategoriesForAccount = (lobs: Option[]) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListServiceCategorysForAccountQuery({
      slug: accountSlug,
      filter: {
        active: true,
        //@ts-ignore
        lob: lobs.length ? lobs.map((lob) => lob.value) : undefined,
      },
    });

  let categories: Option[] = [];

  if (data?.data) {
    try {
      categories = data.data.map((category) => {
        if (!category.id) {
          throw new Error("Missing category.id");
        }
        if (!category.attributes?.["nested-name"]) {
          throw new Error("Missing category.attributes['nested-name']");
        }

        return {
          value: String(category.id),
          label: category.attributes["nested-name"] || "",
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    categories,
    categoriesLoading: isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListServiceCategoriesForAccount;
