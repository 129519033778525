import React from "react"
import PropTypes from "prop-types"
import Loader from './Loader'

class Widget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loading: true, data: []};
  }

  renderHeader () {
    return(<div className="widget-title">{this.props.title}</div>)
  }

  renderBody () {
    return(<div className="widget-body">[Widget Body]</div>)
  }

  renderFooter () {
    return(<div className="widget-Footer">[Widget Footer]</div>)
  }

  renderLoader () {
    return(
      <div className="widget-loading">
        <span className="fa fa-spinner" />
        Loading...
      </div>
    )
  }

  render () {
    return (
      <div className="widget">
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

export default Widget
