import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fat } from "@fortawesome/pro-thin-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconName,
  IconDefinition,
  findIconDefinition,
} from "@fortawesome/fontawesome-svg-core";

library.add(fas, far, fat);

const iconMapping: { [key: string]: [string, string] } = {
  "fa-play-circle-o": ["far", "play-circle"],
  "fa-phone": ["fas", "phone"],
  "fa-user-group": ["fas", "user-friends"],
  "fa-link-simple": ["fas", "link"],
  "fa-cloud-check": ["fas", "cloud-check"],
  "fa-circle-info": ["fas", "info-circle"],
  "fa-star": ["fat", "star"],
  // add more mappings here
};

interface Args {
  iconClass?: string;
  className?: string;
}

const getFontAwesomeIcon = ({ iconClass, className }: Args): JSX.Element => {
  if (!iconClass) {
    return (
      <FontAwesomeIcon
        className={className}
        icon={["fas", "question-circle"]}
      />
    );
  }

  const iconKey = iconClass.replace("fa ", "");
  const icon = iconMapping[iconKey];
  if (icon) {
    const [prefix, iconName] = icon;
    const iconDefinition: IconDefinition = findIconDefinition({
      prefix: prefix as any,
      iconName: iconName as IconName,
    });
    return <FontAwesomeIcon className={className} icon={iconDefinition} />;
  } else {
    return (
      <FontAwesomeIcon
        className={className}
        icon={["fas", "question-circle"]}
      />
    );
  }
};

export default getFontAwesomeIcon;
