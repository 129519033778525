import React from "react";
import { Form } from "react-bootstrap";

function FormFieldLabel({ label, className }) {
  return (
      <Form.Label className={className}>{label}</Form.Label>
  );
}

export default FormFieldLabel;
