import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col, FormControl } from "react-bootstrap";
import NavTabs from "@components/NavTabs/NavTabs";
import CardHeader from "@components/CardHeader/CardHeader";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import PricingTable from "../PricingTable/PricingTable";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import {
  getTotalRow,
  arrangeDataForTable,
} from "../../pricingUtils/pricingHelpers";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";
import PricingCalculator from "../../pricingUtils/PricingCalculator";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import CurrencyFormat from "react-currency-format";
import DropDownField from "@components/Forms/DropDownField";

function PricingByLob({
  account_slug,
  account_id,
  project_id,
  phases,
  resources,
  projectLocations,
  lobs,
  paymentTerms,
  currentPaymentTerm,
  governances,
  currencyUnit,
  projectPricings,
  projectStatus,
  oneTimeAdjustment,
  setOneTimeAdjustment,
  isLoading,
  rateType,
  authorizationCode,
  pricingPrivilege,
  insight_pricing_adjustments,
}) {
  const [paymentTerm, setPaymentTerm] = useState(currentPaymentTerm);
  const [newPaymentTermId, setNewPaymentTermId] = useState(null);
  const [lobsWithPricings, setLobsWithPricings] = useState([]);
  const [governancePricings, setGovernancePricings] = useState([]);
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("Payment Term updated");

  const [professionalServicePricingData, setProfessionalServicePricingData] =
    useState([]);
  const [priceAdjustment, setPriceAdjustment] = useState(0.0);
  const [serviceProjectPricings, setServiceProjectPricings] = useState([]);
  const priceAdjustmentRowPresent = professionalServicePricingData.find(
    (row) => row.type === "priceAdjustment"
  );

  let priceAdjustmentRow = {
    type: "priceAdjustment",
    resource: "Price Adjustment",
    hours: 0,
    rate: "",
    revenueValue: oneTimeAdjustment,
    revenue:
      projectStatus === "building" &&
      (!insight_pricing_adjustments || pricingPrivilege) ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginLeft: "15px",
            }}
          >
            <FormControl
              type="number"
              step="0.01"
              className="priceAdjustmentField"
              defaultValue={parseFloat(oneTimeAdjustment)}
              onChange={(e) => {
                let value = e.target.value;
                if (value && value !== "NaN") {
                  setPriceAdjustment(parseFloat(value));
                } else {
                  setPriceAdjustment(parseFloat(0));
                }
              }}
            />
            <SeafoamButton
              style={{ height: "2.5em" }}
              className="priceAdjustmentSubmit"
              text={<i className="fa fa-check"></i>}
              type="submit"
            />
          </div>
        </>
      ) : (
        <CurrencyFormat
          displayType="text"
          prefix={currencyUnit}
          isNumericString={true}
          thousandSeparator={true}
          value={parseFloat(oneTimeAdjustment).toFixed(2)}
        />
      ),
    effectiveCost: "",
    totalCost: "",
    grossProfit: parseFloat(oneTimeAdjustment),
  };

  useEffect(() => {
    setPriceAdjustment(oneTimeAdjustment);
    // Set array of service-pricings (only used to render ByService tab on this page)
    let professionalServicePricings = projectPricings.filter((service) =>
      service.id.toString().includes("project-services")
    );
    setServiceProjectPricings(
      professionalServicePricings.filter(
        (service) => service.attributes["service-type"] == "professional"
      )
    );

    // Set state for lobs with pricings so an lob card without pricings does not render
    let lobsWithPricings = new Set();
    lobs.forEach((lob) => {
      projectPricings.forEach((projectPricing) => {
        if (
          projectPricing.attributes["lob-name"] === lob.attributes.name &&
          projectPricing.attributes["service-type"] == "professional"
        ) {
          lobsWithPricings.add(lob);
        }
      });
    });
    setLobsWithPricings(Array.from(lobsWithPricings));

    // Set governancePricing items for governance cards
    setGovernancePricings(
      projectPricings.filter(
        (pricing) => pricing.id.split("-")[1] === "governances"
      )
    );

    // Set price adjustment to be one-time-adjustment
    setPriceAdjustment(parseFloat(oneTimeAdjustment));
    // Set data for professional services card
    let professionalServiceTableData = arrangeDataForTable(
      projectPricings.filter(
        (service) => service.attributes["service-type"] == "professional"
      ),
      resources,
      lobs
    ).filter((item) => item.revenue !== 0 || item.totalCost !== 0);

    professionalServiceTableData.push(
      getTotalRow(professionalServiceTableData, oneTimeAdjustment)
    );

    // Calculate margin to be displayed under gross profit column in the last row

    let pricingData = new PricingCalculator(
      "professional",
      oneTimeAdjustment,
      projectPricings.filter(
        (service) => service.attributes["service-type"] == "professional"
      ),
      0
    );

    let marginRow = {
      type: "marginRow",
      resource: "",
      hours: 0,
      revenue: "",
      totalCost: "",
      margin: parseFloat(pricingData.margin),
    };

    professionalServiceTableData.push(marginRow);

    if (parseFloat(oneTimeAdjustment) !== 0.0) {
      professionalServiceTableData.splice(
        professionalServiceTableData.length - 2,
        0,
        priceAdjustmentRow
      );
    }
    setProfessionalServicePricingData(professionalServiceTableData);
    // Set current payment term from project

    if (currentPaymentTerm.attributes) {
      setPaymentTerm(currentPaymentTerm.attributes.name);
    }
  }, [
    paymentTerms,
    currentPaymentTerm,
    currencyUnit,
    resources,
    governances,
    projectPricings,
    lobs,
    projectStatus,
    oneTimeAdjustment,
    isLoading,
  ]);

  const handlePricingAdjustmentSubmit = (e) => {
    e.preventDefault();

    if (oneTimeAdjustment === priceAdjustment) return;

    let updatedProjectData = {
      data: {
        type: "projects",
        id: project_id,
        attributes: {
          "price-adjustment": priceAdjustment,
        },
        relationships: {
          account: { data: { type: "accounts", id: account_id } },
        },
      },
    };
    API.Patch(
      `${apiHost}/${account_slug}/v1/projects/${project_id}`,
      updatedProjectData,
      authorizationCode
    )
      .then((response) => {
        if (response.status == 200) {
          setSuccessMessage("Price Adjustment updated");
          setOneTimeAdjustment(
            parseFloat(response.data.data.attributes["price-adjustment"])
          );
          setUpdatedAlertIsOpen(true);
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "pricing details"));
        setShowFailAlert(true);
      });
  };

  const getTotal = (array) => {
    let totalRow = {
      type: "totalRow",
      label: "TOTAL",
      totalHours: array
        .map((item) => parseFloat(item.hours))
        .reduce((a, b) => a + b, 0),
      totalRevenue: array
        .map((item) => parseFloat(item.revenue))
        .reduce((a, b) => a + b, 0),
      totalCost: array
        .map((item) => parseFloat(item.totalCost))
        .reduce((a, b) => a + b, 0),
      totalGrossProfit: array
        .map((item) => parseFloat(item.grossProfit))
        .reduce((a, b) => a + b, 0),
    };
    return totalRow;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let updatedProjectData = {
      data: {
        type: "projects",
        id: project_id,
        relationships: {
          account: { data: { type: "accounts", id: account_id } },
          "payment-term": {
            data: { id: newPaymentTermId, type: "payment-terms" },
          },
        },
      },
    };
    API.Patch(
      `${apiHost}/${account_slug}/v1/projects/${project_id}`,
      updatedProjectData,
      authorizationCode
    )
      .then((response) => {
        if (response.status == 200) {
          setSuccessMessage("Payment Term updated");
          setUpdatedAlertIsOpen(true);
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "pricing details"));
        setShowFailAlert(true);
      });
  };

  const detailsUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="governanceAlerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text={successMessage}
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  let governanceTableData = arrangeDataForTable(
    governancePricings,
    resources,
    lobs
  ).filter((item) => item.revenue !== 0 || item.totalCost !== 0);

  governanceTableData.push(getTotal(governanceTableData));

  return (
    <>
      <NavTabs
        tabOneText="By LOB"
        tabOneUrl={`/projects/${project_id}/sow_pricings`}
        tabTwoText="By Phase"
        tabTwoUrl={`/projects/${project_id}/sow_pricings/phase`}
        tabThreeText={serviceProjectPricings.length == 0 ? null : "By Service"}
        tabThreeUrl={`/projects/${project_id}/sow_pricings/service`}
        tabFourText={projectLocations.length >= 2 ? "By Location" : null}
        tabFourUrl={`/projects/${project_id}/sow_pricings/locations`}
      />
      {isLoading ? (
        <div className="col-sm-12" id="sow-workarea">
          <div style={{ marginTop: "20px" }}>
            <ScopeStackSpinner />
          </div>
        </div>
      ) : (
        <div className="col-sm-12" id="sow-workarea">
          <div>
            <br />
            {handleFailAlert()}
            {detailsUpdatedAlert()}
            <Card className="marginCard">
              <CardHeader title="Professional Service Terms" />
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Col sm={5}>
                    <DropDownField
                      value={paymentTerm}
                      onChange={(e) => {
                        let selected = e.target.options.selectedIndex;
                        setNewPaymentTermId(
                          e.target.options[selected].accessKey
                        );
                      }}
                      options={paymentTerms.map((term) => {
                        return (
                          <option
                            className="paymentTermOptions"
                            key={term.id}
                            accessKey={term.id}
                          >
                            {term.attributes.name}
                          </option>
                        );
                      })}
                    />
                  </Col>
                  <Row>
                    <Col style={{ marginLeft: "1em" }} xs={6}>
                      <SubmitButton />
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
            <Card className="marginCard">
              <CardHeader title="Professional Service Pricing" />
              <Card.Body>
                <Form onSubmit={handlePricingAdjustmentSubmit}>
                  <PricingTable
                    array={professionalServicePricingData}
                    currencyUnit={currencyUnit}
                    rateType={rateType}
                  />

                  {projectStatus == "building" ? (
                    <div>
                      <SeafoamButton
                        text="Update Rates"
                        style={
                          parseFloat(oneTimeAdjustment) == 0.0 &&
                          !priceAdjustmentRowPresent
                            ? {
                                borderBottomRightRadius: "0",
                                borderTopRightRadius: "0",
                              }
                            : null
                        }
                        onClick={() =>
                          window.location.replace(
                            `${appHost}/projects/${project_id}/project_resources`
                          )
                        }
                      />
                      {parseFloat(oneTimeAdjustment) == 0.0 &&
                      projectStatus !== "approved" &&
                      projectStatus !== "business_approval" &&
                      !priceAdjustmentRowPresent &&
                      (!insight_pricing_adjustments || pricingPrivilege) ? (
                        <SeafoamButton
                          style={{
                            borderBottomLeftRadius: "0",
                            borderTopLeftRadius: "0",
                          }}
                          text="Adjust Price"
                          onClick={(e) => {
                            e.preventDefault();
                            professionalServicePricingData.splice(
                              professionalServicePricingData.length - 2,
                              0,
                              priceAdjustmentRow
                            );
                            setProfessionalServicePricingData([
                              ...professionalServicePricingData,
                            ]);
                          }}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </Form>
              </Card.Body>
            </Card>
            {lobsWithPricings.map((lob, index) => {
              let servicesPerLob = projectPricings.filter(
                (service) =>
                  service.attributes["lob-name"] === lob.attributes.name
              );

              let lobTableData = arrangeDataForTable(
                servicesPerLob,
                resources,
                lobs
              ).filter((item) => item.revenue !== 0 || item.totalCost !== 0);

              lobTableData.push(getTotal(lobTableData));
              return (
                <Card key={lob.id} className="marginCard">
                  <CardHeader title={`${lob.attributes.name} Pricing`} />
                  <Card.Body>
                    <PricingTable
                      array={lobTableData}
                      currencyUnit={currencyUnit}
                      rateType={rateType}
                    />
                  </Card.Body>
                </Card>
              );
            })}
            <Card className="marginCard">
              <CardHeader title="Project Governance Pricing" />
              <Card.Body>
                <PricingTable
                  array={governanceTableData}
                  currencyUnit={currencyUnit}
                  rateType={rateType}
                />
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </>
  );
}

export default PricingByLob;
