import React, { useEffect, useState } from "react";
import NavTabs from "@components/NavTabs/NavTabs";
import Section from "./Section";
import ProjectGovernance from "../GovernanceMspa/ProjectGovernance/ProjectGovernance";
import API from "@API";
import { Row, Col } from "react-bootstrap";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

function ByLob({
  account_slug,
  project_id,
  account_id,
  projectServices,
  lobs,
  resources,
  projectResources,
  phases,
  linesOfBusiness,
  projectLocations,
  projectSubservices,
  isLoading,
  projectStatus,
  totalEffort,
  rateType,
  authorizationCode,
}) {
  // Set the State
  // const [projectStatus, setProjectStatus] = useState("");
  const [governances, setGovernances] = useState([]);
  // const [totalEffort, setTotalEffort] = useState("");
  // const [rateType, setRateType] = useState("Hours");

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  // When page loads, call API to get Governance Info and lift it up to the state
  useEffect(() => {
    if (authorizationCode != "") {
      // Get Governances
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-governances?include=resource,project-phase`,
        authorizationCode
      ).then((response) => {
        // Sets governance state to be the array of governance objects
        if (response.data.data !== null) {
          setGovernances(response.data.data);
        }
      });
    }
  }, [authorizationCode, isLoading]);

  return (
    <>
      <NavTabs
        tabOneText="By LOB"
        tabOneUrl={`/projects/${project_id}/summary`}
        tabTwoText="By Phase"
        tabTwoUrl={`/projects/${project_id}/summary/phase`}
        tabThreeText={projectLocations.length >= 2 ? "By Location" : null}
        tabThreeUrl={`/projects/${project_id}/summary/location`}
      />
      <div className="col-sm-12" id="sow-workarea">
        {isLoading ? (
          <div style={{ paddingTop: "15px" }}>
            <ScopeStackSpinner />
          </div>
        ) : (
          <Section
            array={linesOfBusiness}
            projectServices={projectServices}
            projectSubservices={projectSubservices}
            relationshipName="lob"
            resources={projectResources}
            lobs={lobs}
            rateType={rateType}
          />
        )}
        {governances.length !== 0 ? (
          <ProjectGovernance
            account_slug={account_slug}
            project_id={project_id}
            account_id={account_id}
            projectStatus={projectStatus}
            governances={governances}
            resources={resources}
            lobs={lobs}
            projectResources={projectResources}
            totalEffort={totalEffort}
            phases={phases}
            rateType={rateType}
          />
        ) : null}
      </div>
    </>
  );
}

export default ByLob;
