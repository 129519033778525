import React, { useEffect } from "react";
import "./UpgradeCard.style.css";
import { Card, Button } from "react-bootstrap";
import CustomCardHeader from "../../../components/CardHeader/CustomCardHeader/CustomCardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { upgradePageData } from "../upgradePageData";

function UpgradeCard({
  cardTitle,
  cardSubtitle,
  subheader,
  buttonText,
  listItems,
  currentSubscription,
  plan,
  currentPlan,
  onButtonClick,
  freeTrialClick,
  upgradePermission,
  trialAvailable,
}) {
  useEffect(() => {}, [currentSubscription]);

  const isCurrentPlan = currentPlan.id == plan.id;
  const isFreePlan = plan.id == "free";
  const isLessThanCurrentPlanArray = upgradePageData.slice(
    0,
    upgradePageData.indexOf(currentPlan)
  );

  return (
    <Card
      className={`upgradeCard ${
        cardTitle === "Business" &&
        !isLessThanCurrentPlanArray.includes(plan) &&
        !isCurrentPlan
          ? "recommendedCard"
          : ""
      } ${
        isFreePlan || isLessThanCurrentPlanArray.includes(plan) || isCurrentPlan
          ? "blurredCard"
          : ""
      }`}
    >
      {cardTitle === "Business" &&
      !isLessThanCurrentPlanArray.includes(plan) &&
      !isCurrentPlan ? (
        <div className="ourRecommendation">Our Recommendation</div>
      ) : null}
      <CustomCardHeader
        dividerIsFullWidth={false}
        content={
          <div
            className={`${
              (currentPlan.cardTitle === "Free" ||
                currentPlan.cardTitle === "Essentials") &&
              cardTitle !== "Business"
                ? "nonRecommendedPadding"
                : ""
            }`}
          >
            <div className="cardHeaderTitle">{cardTitle}</div>
            <div className="cardHeaderSubtitle">{cardSubtitle}</div>
          </div>
        }
      />
      <Card.Body className="upgradeCardBody">
        <div
          style={
            cardTitle == "Free" ? { color: "#1c2655" } : { color: "#418172" }
          }
          className="subheader"
        >
          {subheader}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {plan.id !== "free" && upgradePermission ? (
            <>
              {buttonText === "Buy Now" && trialAvailable ? (
                <Button
                  type="button"
                  style={{ marginRight: "10px" }}
                  className={`checkoutBtn ${
                    isCurrentPlan || isLessThanCurrentPlanArray.includes(plan)
                      ? "btnGrey"
                      : "btnSeafoam"
                  }`}
                  variant="secondary"
                  disabled={isLessThanCurrentPlanArray.includes(plan)}
                  onClick={freeTrialClick}
                >
                  Try Free
                </Button>
              ) : null}

              <Button
                type="button"
                className={`checkoutBtn ${
                  isCurrentPlan || isLessThanCurrentPlanArray.includes(plan)
                    ? "btnGrey"
                    : "btnSeafoam"
                }`}
                variant="secondary"
                disabled={
                  isLessThanCurrentPlanArray.includes(plan) || isCurrentPlan
                }
                onClick={onButtonClick}
              >
                {buttonText}
              </Button>
            </>
          ) : buttonText === "Current plan" ? (
            <>
              <Button
                type="button"
                className="checkoutBtn btnGrey"
                variant="secondary"
                disabled={true}
                onClick={null}
              >
                {buttonText}
              </Button>
            </>
          ) : (
            <div style={{ height: "20px" }}></div>
          )}
        </div>
        <ul className="unorderedList">
          {listItems.map((li, index) => {
            return (
              <li key={index} className="listItem">
                {li.type !== "footer" ? (
                  <FontAwesomeIcon
                    style={{ color: "#418172" }}
                    icon={li.type == "plus" ? faPlus : faCheck}
                  />
                ) : null}
                &nbsp;&nbsp;
                <p style={{ marginTop: "10px" }}>{li.content}</p>
              </li>
            );
          })}
        </ul>
        {cardTitle !== "Free" ? (
          <div className="upgradeCardFooter">
            <span>{listItems[listItems.length - 1].footer}</span>
          </div>
        ) : null}
      </Card.Body>
    </Card>
  );
}

export default UpgradeCard;
