import React, { useState, useEffect } from "react";
import Link from "react-router-dom";
import { Card, Form, Row, Col } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import FormNumberField from "@components/Forms/FormNumberField";
import FormDateField from "@components/Forms/FormDateField";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import {
  capitalizeFirstLetter,
  formatUnprocessibleResponse,
  snakeToPascalCase,
  splitPascalCase,
  toSnakeCase,
} from "@utils/helperFunctions";
import CurrencyFormat from "react-currency-format";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";

function MsPricing({
  account_slug,
  account_id,
  project_id,
  insight_pricing_adjustments,
  insight_pupm_enabled,
}) {
  // Env
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  // Form States
  const [projectSubmitUrl, setProjectSubmitUrl] = useState("");
  const [projectPricings, setProjectPricings] = useState([]);
  const [managedServices, setManagedServices] = useState([]);
  const [managedSubservices, setManagedSubservices] = useState([]);
  const [currencyUnit, setCurrencyUnit] = useState("");
  const [adjustment, setAdjustment] = useState("0.0");
  const [terms, setTerms] = useState(0.0);
  const [startDate, setStartDate] = useState("");
  const [billingFrequency, setBillingFrequency] = useState("Monthly");
  const [totalMonths, setTotalMonths] = useState(0);
  const [includePsRevenue, setIncludePsRevenue] = useState(
    "Do Not Include PS Revenue"
  );
  const [cola, setCola] = useState("0.0");

  // Alert States
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [pricingPrivilege, setPricingPrivilege] = useState(false);

  useEffect(() => {
    if (authorizationCode !== "") {
      API.Get(`${apiHost}/v1/me`, authorizationCode).then((res) => {
        const privilege = res.data.data.attributes.privileges.find(
          (p) => p.privilege === "projects.pricing_adjustment"
        );
        if (privilege && privilege["access-level"] === "manage") {
          setPricingPrivilege(true);
        }
      });
      // Get Project Pricings
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-pricings`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        let projectPricings = data.filter(
          (item) => item.attributes["billing-frequency"] !== "one_time"
        );
        setProjectPricings(projectPricings);
        setTotalMonths(parseFloat(response.data.meta["contract-months"]));
      });

      //Get managed services with included data & use to set state
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-services?filter[service-type]=managed_services&include=project-subservices,project-subservices.resource`,
        authorizationCode
      ).then((response) => {
        let includedData = response.data.included;
        let subservices = [];
        if (includedData) {
          for (let i = 0; i < includedData.length; i++) {
            if (includedData[i].type === "project-subservices") {
              subservices.push(includedData[i]);
            }
          }
        }
        setManagedSubservices(
          subservices.filter((service) => service.attributes.active == true)
        );
        setManagedServices(
          response.data.data.filter(
            (service) => service.attributes.active == true
          )
        );
        setIsLoading(false);
      });

      //Get Project Info
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setProjectSubmitUrl(data.links.self);
        setBillingFrequency(
          capitalizeFirstLetter(data.attributes["recurring-billing-frequency"])
        );
        setCurrencyUnit(data.attributes["field-labels"].currency_unit);
        setAdjustment(data.attributes["mrr-adjustment"]);
        setTerms(data.attributes["mrr-terms"]);
        setStartDate(data.attributes["service-start-date"]);
        setIncludePsRevenue(
          splitPascalCase(
            snakeToPascalCase(data.attributes["include-ps-revenue-in-mrr"])
          )
        );
        setCola((parseFloat(data.attributes['cola']) * 100).toFixed(2));
      });
    }
  }, [authorizationCode]);

  const handleMrrAdjustment = (e) => {
    e.preventDefault();

    let updatedMrrAdjustment = {
      data: {
        type: "projects",
        id: project_id,
        attributes: {
          "mrr-adjustment": adjustment,
        },
        relationships: {
          account: { data: { type: "accounts", id: account_id } },
        },
      },
    };

    API.Patch(projectSubmitUrl, updatedMrrAdjustment, authorizationCode)
      .then((response) => {
        if (response.status == 200) {
          let data = response.data.data;
          setAdjustment(data.attributes["mrr-adjustment"]);
          setSuccessMessage(
            `Set MRR adjustment to $${parseFloat(adjustment).toFixed(2)}`
          );
          setShowSuccessAlert(true);
          window.location.reload();
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "pricing details"));
        setShowFailAlert(true);
      });
  };

  const getTableData = (array) => {
    if (array.length == 0) {
      return [];
    }

    return array.map((item) => {
      const getColumnOne = () => {
        if (item.type === "mrrAdjustment") {
          return {
            class: "text-right",
            name: "MRR Adjustment",
          };
        } else {
          return {
            class: "text-right",
            name: "",
          };
        }
      };

      const getColumnTwo = () => {
        if (item.type === "mrrAdjustment") {
          return {
            class: "text-right",
            name: "-",
          };
        } else {
          return {
            class: "text-right",
            name: item.name,
          };
        }
      };

      const getColumnThree = () => {
        if (item.type == "mrrAdjustment") {
          return {
            class: "text-right",
            name: (
              <>
                <Form onSubmit={handleMrrAdjustment}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginLeft: "15px",
                    }}
                  >
                    <Form.Control
                      type="number"
                      step="0.01"
                      key={"adjustment"}
                      className="mrrAdjustmentField"
                      value={adjustment}
                      onChange={(e) => {
                        setAdjustment(e.target.value);
                      }}
                      disabled={insight_pricing_adjustments && !pricingPrivilege}
                    />
                    {(!insight_pricing_adjustments || pricingPrivilege) && (
                      <SeafoamButton
                        className={"btn-sm"}
                        style={{ marginLeft: "1em" }}
                        text="Update"
                        type="submit"
                      />
                    )}
                  </div>
                </Form>
              </>
            ),
          };
        }
        if (item.type === "totalMrr" || item.type === "tcvRow") {
          return {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(item.revenue).toFixed(2)}
              />
            ),
          };
        }
        if (item.type === "profitRow") {
          return {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(item.profit).toFixed(2)}
              />
            ),
          };
        }
        if (item.type === "marginRow") {
          return {
            class: "text-right",
            name: (
              <span>
                {item.margin ? parseFloat(item.margin).toFixed(2) : 0}%
              </span>
            ),
          };
        }
      };

      const getColumnFour = () => {
        if (
          item.type == "mrrAdjustment" ||
          item.type === "profitRow" ||
          item.type === "marginRow"
        ) {
          return {
            class: "text-right",
            name: "",
          };
        }
        if (item.type === "totalMrr" || item.type === "tcvRow") {
          return {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(item.cost).toFixed(2)}
              />
            ),
          };
        }
      };

      if (item.type !== "services" && item.type !== "subservices") {
        return {
          rowClass: "calcRow",
          onClick: null,
          columns: [
            getColumnOne(),
            getColumnTwo(),
            getColumnThree(),
            getColumnFour(),
          ],
          actions: null,
        };
      }

      return {
        rowClass: "msPricingRow",
        onClick: null,
        columns: [
          {
            class: "",
            name:
              item.type == "project-services" ? (
                item.attributes.name
              ) : (
                <div style={{ paddingLeft: "1em" }}>
                  - {item.attributes.name}
                </div>
              ),
          },
          {
            class: "text-right",
            name: item.attributes.quantity,
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(item.attributes.mrr).toFixed(2)}
              />
            ),
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(item.attributes.mrc).toFixed(2)}
              />
            ),
          },
        ],
        actions: null,
      };
    });
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Service",
      },
      {
        class: "text-right",
        name: "Quantity",
      },
      {
        class: "text-right",
        name: "Monthly Revenue",
      },
      {
        class: "text-right",
        name: "Monthly Cost",
      },
    ],
  };

  const arrangeDataForTable = () => {
    let serviceList = [];
    let orderedPricingList = [];
    managedServices.forEach((service) => {
      serviceList.push(service);
      managedSubservices.forEach((subservice) => {
        let serviceRelData = subservice.relationships["project-service"].data;
        if (serviceRelData && serviceRelData.id == service.id) {
          serviceList.push(subservice);
        }
      });
    });
    serviceList.map((service) => {
      projectPricings.forEach((item) => {
        if (item.id.split("-")[2] == service.id) {
          item.attributes.name = service.attributes.name;
          item.type = item.id.split("-")[1];
          orderedPricingList.push(item);
        }
      });
    });
    if (projectPricings.length > 0)
      orderedPricingList.push({ type: "mrrAdjustment" });
    let adjustmentValue = 0;
    if (adjustment !== "") adjustmentValue = parseFloat(adjustment);

    let revenue = orderedPricingList
      .filter((row) => row.type.match("services"))
      .map((row) => parseFloat(row.attributes.mrr))
      .reduce((prev, curr) => prev + curr, adjustmentValue);

    let cost = orderedPricingList
      .filter((row) => row.type.match("services"))
      .map((row) => parseFloat(row.attributes.mrc))
      .reduce((prev, curr) => prev + curr, 0);

    orderedPricingList.push({
      type: "totalMrr",
      revenue: revenue,
      cost: cost,
      name: "TOTAL MRR",
    });

    let tcvRevenue = revenue * totalMonths;
    let tcvCost = cost * totalMonths;
    let profit = tcvRevenue - tcvCost;

    orderedPricingList.push({
      type: "tcvRow",
      name: "TOTAL CONTRACT VALUE",
      revenue: tcvRevenue,
      cost: tcvCost,
    });
    orderedPricingList.push({
      type: "profitRow",
      name: "PROFIT",
      profit: profit,
    });
    orderedPricingList.push({
      type: "marginRow",
      name: "MARGIN",
      margin: (profit / tcvRevenue) * 100,
    });
    return orderedPricingList;
  };

  const tableData = getTableData(arrangeDataForTable());
  const footerData = null;

  let msPricingTable = new ScopeStackTable(
    "pricing",
    headerData,
    tableData,
    footerData
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    
    let updatedData = {
      data: {
        type: "projects",
        id: project_id,
        attributes: {
          cola: (parseFloat(cola) / 100),
          "mrr-terms": terms,
          "recurring-billing-frequency": billingFrequency.toLowerCase(),
          "service-start-date": startDate,
          "include-ps-revenue-in-mrr": toSnakeCase(
            includePsRevenue.toLowerCase()
          ),
        },
        relationships: {
          account: { data: { type: "accounts", id: account_id } },
        },
      },
    };

    API.Patch(projectSubmitUrl, updatedData, authorizationCode)
      .then((response) => {
        if (response.status == 200) {
          setSuccessMessage(
            "Managed Services billing and payment details updated"
          );
          setShowSuccessAlert(true);
          let data = response.data.data;
          setAdjustment(data.attributes["mrr-adjustment"]);
          setBillingFrequency(
            capitalizeFirstLetter(
              data.attributes["recurring-billing-frequency"]
            )
          );
          setIncludePsRevenue(
            splitPascalCase(
              snakeToPascalCase(data.attributes["include-ps-revenue-in-mrr"])
            )
          );
          setStartDate(data.attributes["service-start-date"]);
          setCola(cola);
          window.location.reload();
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "pricing details"));
        setShowFailAlert(true);
      });
  };

  // Handle Form Alerts
  const handleSuccessAlert = () => {
    if (showSuccessAlert === true) {
      return (
        <DismissibleAlert
          variant="info"
          onClose={() => setShowSuccessAlert(false)}
          text={successMessage}
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleSuccessAlert()}
      {handleFailAlert()}
      <Card>
        <CardHeader title={"Managed Services Term"} />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={3}>
                <FormNumberField
                  label="Length of contract (months)"
                  step="1"
                  min="0"
                  value={terms}
                  onChange={(e) => setTerms(e.target.value)}
                />
              </Col>
              <Col sm={2}>
                <FormDateField
                  label="Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col sm={2}>
                <ControlledDropDown
                  label="Billing Frequency"
                  value={billingFrequency}
                  onChange={(e) => setBillingFrequency(e.target.value)}
                  options={[
                    <option key="monthly">Monthly</option>,
                    <option key="quarterly">Quarterly</option>,
                    <option key="yearly">Yearly</option>,
                  ]}
                />
              </Col>
              <Col sm={3}>
                <ControlledDropDown
                  label="Include PS Revenue in Recurring Payments?"
                  value={includePsRevenue}
                  onChange={(e) => setIncludePsRevenue(e.target.value)}
                  options={[
                    <option key="monthly">Do Not Include PS Revenue</option>,
                    <option key="quarterly">Add To First Month</option>,
                    <option key="yearly">Amortize Over All Months</option>,
                  ]}
                />
                <div className="text-center">
                  <a
                    href={`/projects/${project_id}/project_services/payment_methods`}
                    style={{ color: "#a8a8a8", fontSize: "0.9em" }}
                  >
                    &mdash;Or Select Individual Services&mdash;
                  </a>
                </div>
              </Col>
            </Row>
            {insight_pupm_enabled && (
            <Row>
              <Col sm={3}>
                <FormNumberField
                  label="Cost of Living Adjustment (%) *"
                  required={true}
                  step="0.01"
                  min="0"
                  value={cola}
                  onChange={(e) => setCola(e.target.value)}
                />
              </Col>
            </Row>
            )}
            <Row>
              <Col>
                <SeafoamButton text="Save" type="submit" />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Card>
        <CardHeader title={"Managed Services Summary"} />
        <Card.Body>
          <BaseTable
            className="scopestack msPricingTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={msPricingTable.buildHeaderRows()}
            dataRows={msPricingTable.buildDataRows()}
            footerRows={msPricingTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default MsPricing;
