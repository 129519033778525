import {
  ListThirdPartyServicesForAccountApiArg,
  V1PhaseResource,
  V1ProjectPhaseResource,
  useListThirdPartyServicesForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Service } from "../../../Services/types/Service";

export interface HookArgs {
  page?: ListThirdPartyServicesForAccountApiArg["page"];
  include?: ListThirdPartyServicesForAccountApiArg["include"];
  filter?: ListThirdPartyServicesForAccountApiArg["filter"];
  phases?: V1ProjectPhaseResource[] | V1PhaseResource[] | [];
  updatedThirdPartyServices?: Service[] | null;
}

const useListThirdPartyServices = ({
  page,
  include,
  filter,
  phases,
  updatedThirdPartyServices,
}: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } =
    useListThirdPartyServicesForAccountQuery({
      slug: accountSlug,
      page,
      include,
      filter,
    });

  let thirdPartyServices: Service[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };
  let allSubservices = data?.included?.filter(
    (item) => item.type === "third-party-services"
  );

  if (data?.data) {
    try {
      thirdPartyServices = data.data.map((service) => {
        if (!service.id) {
          throw new Error("Missing service.id");
        }

        let phase = { value: 0, label: "Select Phase" };

        let foundPhase = (phases || []).find(
          (phase) =>
            service?.relationships &&
            service?.relationships?.["project-phase"] &&
            service?.relationships?.["project-phase"]?.data?.id == phase.id
        );
        if (foundPhase && foundPhase.id) {
          phase = {
            label: foundPhase.attributes?.["name"] || "",
            value: foundPhase.id,
          };
        }

        let subservices: any[] = [];
        allSubservices?.forEach((subservice) => {
          service?.relationships?.subtasks?.data?.forEach((subtask) => {
            if (subservice.id == subtask.id) {
              subservices.push({
                id: subservice?.id || "",
                type: subservice.type,
                name: subservice?.attributes?.name || "",
                quantity: subservice?.attributes?.["quantity"],
                originalDescription:
                  subservice?.attributes?.["original-description"],
                serviceDescription:
                  subservice?.attributes?.["service-description"] || "",
                sourceType: subservice?.attributes?.["source-type"],
                sourceId: subservice?.attributes?.["source-id"],
                position: subservice?.attributes?.position,
                isChanged: false,
              });
            }
          });
        });

        return {
          id: String(service.id),
          type: service.type,
          name: service?.attributes.name,
          phase: phase,
          quantity: service?.attributes?.quantity,
          originalDescription: service?.attributes?.["original-description"],
          serviceDescription: service?.attributes?.["service-description"],
          subservices: subservices || [],
          sourceType: service?.attributes?.["source-type"],
          sourceId: service?.attributes?.["source-id"],
          cost: service?.attributes?.["est-cost"],
          hours: service?.attributes?.["est-hours"],
          rate: service?.attributes?.["est-rate"],
          position: service?.attributes?.position || 1,
          isChanged: false,
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta) {
        throw new Error("Missing data.meta");
      } else {
        pageMeta = {
          recordCount: data?.meta?.["record-count"] || 0,
          pageCount: data?.meta?.["page-count"] || 0,
        };
      }
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    thirdPartyServices: updatedThirdPartyServices
      ? updatedThirdPartyServices.sort((a, b) =>
          a && b ? Number(a.position) - Number(b.position) : 1
        )
      : thirdPartyServices.sort((a, b) =>
          a && b ? Number(a.position) - Number(b.position) : 1
        ),
    thirdPartyServicesError: error || customError,
    thirdPartyServicesLoading: isLoading,
    pageMeta,
    refetch,
  };
};

export default useListThirdPartyServices;
