import { useFlags as useLDFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import flags from "../../../../../config/flag_values.yml";

const toCamelCase = (str: string) => {
  return str.replace(/[-.](.)/g, (match, group1) => group1.toUpperCase());
};

const convertKeysToCamelCase = (obj): Record<string, boolean> => {
  return Object.keys(obj).reduce((acc, key) => {
    const camelCaseKey = toCamelCase(key);
    acc[camelCaseKey] = obj[key];
    return acc;
  }, {});
};

const inDevelopment =
  process.env.NODE_ENV === "development" ||
  window.location.hostname === "scopestack.test";

const useDevEnvironmentFlags = () => {
  const [devFlags] = useState({
    ...convertKeysToCamelCase(flags.flagValues),
  });
  return devFlags;
};

const getProperHook = () => {
  if (inDevelopment) {
    return useDevEnvironmentFlags;
  }
  return useLDFlags;
};

const useFlags = getProperHook();

export default useFlags;
