import React, { useState, useEffect } from "react";
import CardHeader from "@components/CardHeader/CardHeader";
import { Card, Row, Col } from "react-bootstrap";
import NavTabs from "@components/NavTabs/NavTabs";
import PricingTable from "../PricingTable/PricingTable";
import {
  getTotalRow,
  arrangeDataForTable,
} from "../../pricingUtils/pricingHelpers";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

function PricingByLocation({
  account_slug,
  project_id,
  phases,
  lobs,
  resources,
  projectLocations,
  governances,
  currencyUnit,
  projectPricings,
  projectStatus,
  oneTimeAdjustment,
  isLoading,
  rateType,
}) {
  const [locationsWithPricings, setLocationsWithPricings] = useState([]);
  useEffect(() => {
    // Set state for locations with pricings so a location card without pricings does not render
    let locationsWithPricings = new Set();
    projectLocations.forEach((location) => {
      projectPricings.forEach((projectPricing) => {
        if (
          projectPricing.attributes.location &&
          projectPricing.attributes.location.id == location.id
        ) {
          locationsWithPricings.add(location);
        }
      });
    });
    setLocationsWithPricings(Array.from(locationsWithPricings));
  }, [
    currencyUnit,
    resources,
    governances,
    projectPricings,
    projectLocations,
    isLoading,
  ]);

  return (
    <>
      <NavTabs
        tabOneText="By LOB"
        tabOneUrl={`/projects/${project_id}/sow_pricings`}
        tabTwoText="By Phase"
        tabTwoUrl={`/projects/${project_id}/sow_pricings/phase`}
        tabThreeText="By Service"
        tabThreeUrl={`/projects/${project_id}/sow_pricings/service`}
        tabFourText={projectLocations.length >= 2 ? "By Location" : null}
        tabFourUrl={`/projects/${project_id}/sow_pricings/locations`}
      />
      {isLoading ? (
        <div className="col-sm-12" id="sow-workarea">
          <div style={{ marginTop: "20px" }}>
            <ScopeStackSpinner />
          </div>
        </div>
      ) : (
        <div className="col-sm-12" id="sow-workarea">
          {locationsWithPricings.map((location) => {
            let address = location.attributes.street;
            let street2 = location.attributes.street2;
            let city = location.attributes.city;
            let state = location.attributes.state;
            let zip = location.attributes["postal-code"];
            let country = location.attributes.country;
            let title = `${location.attributes.name}: ${
              address !== null ? address : ""
            },  ${street2 !== null ? street2 : ""} ${
              city !== null ? city : ""
            } ${state !== null ? state : ""} ${zip !== null ? zip : ""} ${
              country !== null ? country.toUpperCase() : ""
            }`;

            let servicesPerLocation = projectPricings.filter(
              (service) =>
                service.attributes.location !== null &&
                service.attributes.location.id == location.id
            );

            let locationTableData = arrangeDataForTable(
              servicesPerLocation,
              resources,
              lobs
            ).filter((item) => item.revenue !== 0 || item.totalCost !== 0);

            locationTableData.push(getTotalRow(locationTableData, 0.0));
            return (
              <Card key={location.id} className="marginCard">
                <CardHeader title={title} />
                <Card.Body>
                  <PricingTable
                    array={locationTableData}
                    currencyUnit={currencyUnit}
                    rateType={rateType}
                  />
                </Card.Body>
              </Card>
            );
          })}
        </div>
      )}
    </>
  );
}

export default PricingByLocation;
