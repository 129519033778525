import React, { useEffect, useState } from "react";
import { Card, Row, Col, Table } from "react-bootstrap";
import SurveyRecommendationsTable from "@components/Tables/SurveyRecommendationsTable/SurveyRecommendationsTable";
import {
  useListResourcesForAccountQuery,
  useListGovernancesForAccountQuery,
  useListPhasesForAccountQuery,
  useListProductsForAccountQuery,
  useListServiceCategorysForAccountQuery,
} from "@generated";
import StaticBanner from "@components/Alerts/StaticBanner/StaticBanner";
import "./style.css";
import { snakeToPascalCase, splitPascalCase } from "@utils/helperFunctions";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import AccordianDrop from "@components/AccordianDrop/AccordianDrop";
import { faCircleMinus, faCirclePlus } from "@fortawesome/pro-light-svg-icons";

function SurveyResults({
  recommendations,
  nonServiceRecommendations,
  showRecommendations,
  setShowRecommendations,
  account_slug,
  resources,
  phases,
  currentSurvey,
  calculations,
  recalculateClicked,
  revertClicked,
  hasRecommendationsInAccordion = false,
  hasCalculationsInAccordion = false,
  hasStatusBanner = true,
  hasSurveyResponsesInAccordion = false,
  surveyResponses,
  accessToken,
  calculationsAtTop,
}) {
  const { data: allResources, isLoading: resourcesLoading } =
    useListResourcesForAccountQuery({
      slug: account_slug,
    });

  const { data: allGovernances, isLoading: governancesLoading } =
    useListGovernancesForAccountQuery({
      slug: account_slug,
    });

  const { data: allProducts, isLoading: productsLoading } =
    useListProductsForAccountQuery({
      slug: account_slug,
    });

  const { data: allPhases, isLoading: phasesLoading } =
    useListPhasesForAccountQuery({
      slug: account_slug,
    });

  const { data: serviceCategories, isLoading: serviceCategoriesLoading } =
    useListServiceCategorysForAccountQuery({
      slug: account_slug,
    });

  const anyItemsLoading =
    resourcesLoading ||
    governancesLoading ||
    phasesLoading ||
    productsLoading ||
    serviceCategoriesLoading;

  let nonServiceRecommendationTypes: any[] = [];

  const nonServiceRecommendationsExist = nonServiceRecommendations.length > 0;
  if (nonServiceRecommendationsExist) {
    nonServiceRecommendationTypes = nonServiceRecommendations.map(
      (rec) => rec.item_type
    );
  }

  const hasRelatedProject =
    currentSurvey &&
    currentSurvey?.data?.relationships.project.data &&
    currentSurvey?.data?.relationships.project.data.id;

  useEffect(() => {
    if (
      currentSurvey &&
      currentSurvey?.data?.attributes?.status === "applied"
    ) {
      setShowRecommendations(true);
    }
  }, [
    allResources,
    allPhases,
    allProducts,
    allGovernances,
    calculations,
    serviceCategories,
    recommendations,
    nonServiceRecommendations,
    revertClicked,
  ]);

  const getBannerText = () => {
    const types: any[] = [];
    nonServiceRecommendationTypes.forEach((type, index: number) => {
      if (type === "Material") {
        type = "Product";
      }
      types.push(type);
      if (
        index === nonServiceRecommendationTypes.length - 2 &&
        nonServiceRecommendationTypes.length > 2
      ) {
        types.push(", and ");
      }
      if (
        index !== nonServiceRecommendationTypes.length - 1 &&
        nonServiceRecommendationTypes.length > 2
      ) {
        types.push(", ");
      }
      if (
        nonServiceRecommendationTypes.length == 2 &&
        index !== nonServiceRecommendationTypes.length - 1
      ) {
        types.push(" and ");
      }
    });
    return (
      <span>
        <strong>Heads up!</strong>&nbsp;These recommendations will also require
        changes to {types.join("")}.
      </span>
    );
  };

  const getNonServiceRecommendationDetails = (
    type,
    calcType,
    item,
    recommendation
  ) => {
    if (type === "Governance" && calcType === "Percent Of Total") {
      return (
        <>
          <span>
            Originally: {calcType}, {Number(item.attributes.rate) * 100}% &nbsp;
            &gt;
          </span>{" "}
          <span className="warningText">
            Now: {calcType}, {recommendation.quantity}%
          </span>
        </>
      );
    }
    if (type === "Governance" && calcType === "Fixed Hours") {
      return (
        <>
          <span>
            Originally: {calcType}, {item.attributes["fixed-hours"]}
          </span>
          <span className="warningText">
            Now: {calcType}, {recommendation.quantity}
          </span>
        </>
      );
    }
    if (type === "Material") {
      return `Quantity: ${recommendation.quantity}`;
    }
  };

  const renderCalculationsOrResponseData = (array, titleLeft, titleRight) => {
    const typeIsResponse = array[0].question;
    return (
      <Table
        className="sansTable calculationsTable"
        bordered={true}
        hover={true}
        striped={true}
      >
        <thead>
          <tr>
            <th>{titleLeft}</th>
            <th>{titleRight}</th>
          </tr>
        </thead>
        <tbody>
          {array.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  {!typeIsResponse ? (
                    splitPascalCase(snakeToPascalCase(item["calculation_id"]))
                  ) : (
                    <span>
                      {index + 1}. {item.question}
                    </span>
                  )}
                </td>
                <td>{!typeIsResponse ? item.value : item.answer}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  if (!showRecommendations) {
    return null;
  }
  if (showRecommendations && anyItemsLoading) {
    return <SmallSpinner />;
  } else {
    return (
      <div>
        {calculations.length > 0 && !hasCalculationsInAccordion ? (
          <Card className="whiteBg takeASurveyOuterCard">
            <Card.Header className="cardHeaderOverride takeASurveyCardHeader">
              Calculated Results
            </Card.Header>
            <hr />
            <Card.Body>
              <div className="flexCenter spaceBetween">
                {renderCalculationsOrResponseData(
                  calculations,
                  "Calculation Name",
                  "Calculated Value"
                )}
              </div>
            </Card.Body>
          </Card>
        ) : null}
        {calculations.length > 0 &&
        hasCalculationsInAccordion &&
        calculationsAtTop ? (
          <AccordianDrop
            header={
              <div
                style={{ marginLeft: "15px" }}
                className="takeASurveyCardHeader"
              >
                <p>Calculations</p>
                <p>
                  <small>
                    Expand to view calculations that resulted in the
                    recommendations
                  </small>
                </p>
              </div>
            }
            body={renderCalculationsOrResponseData(
              calculations,
              "Calculation Name",
              "Calculated Value"
            )}
            className=""
            iconOpen={faCircleMinus}
            iconClosed={faCirclePlus}
            closeable={true}
            setAccordianIsOpen={() => null}
            startingAccordionStateIsOpen={false}
          />
        ) : null}
        {recommendations && recommendations.length > 0 ? (
          <Card className="whiteBg takeASurveyOuterCard">
            {hasStatusBanner ? (
              <>
                <Card.Header className="cardHeaderOverride recommendationsCardHeader">
                  <Row className="flexCenter">
                    <Col sm={9}>
                      <strong>Recommendations</strong>
                    </Col>
                    <Col sm={3}>
                      <StaticBanner
                        bannerText={
                          hasRelatedProject &&
                          (!recalculateClicked || revertClicked)
                            ? "Applied Recommendations"
                            : "Pending Recommendations"
                        }
                        variant={
                          hasRelatedProject &&
                          (!recalculateClicked || revertClicked)
                            ? "success"
                            : "warning"
                        }
                      />
                    </Col>
                  </Row>
                </Card.Header>
                <hr />
              </>
            ) : null}
            <Card.Body>
              {nonServiceRecommendationsExist ? (
                <Card>
                  <Card.Header className="cardHeaderOverride">
                    {currentSurvey?.data?.attributes.status !== "applied" ||
                    recalculateClicked ? (
                      <StaticBanner
                        bannerText={getBannerText()}
                        variant="warning"
                      />
                    ) : null}
                  </Card.Header>
                  <Card.Body>
                    {nonServiceRecommendations.map((recommendation) => {
                      let calcType;
                      const type = recommendation.item_type;
                      let item;
                      if (
                        recommendation.item_type === "Governance" &&
                        allGovernances &&
                        allGovernances.data
                      ) {
                        allGovernances.data.forEach((gov) => {
                          if (recommendation.item_id == gov.id) {
                            item = gov;
                            calcType = splitPascalCase(
                              snakeToPascalCase(
                                item.attributes["calculation-type"]
                              )
                            );
                          }
                        });
                      } else if (allProducts && allProducts.data) {
                        allProducts.data.forEach((product) => {
                          if (recommendation.item_id == product.id) {
                            item = product;
                          }
                        });
                      }
                      return (
                        <div key={recommendation.id} className="greyRecCard">
                          <span className="standardTextSizeNavy">
                            <strong>
                              {type === "Material" ? "Product" : type}
                            </strong>
                            &nbsp; ({item.attributes.name})
                          </span>
                          <span className="standardTextSizeNavy">
                            {getNonServiceRecommendationDetails(
                              type,
                              calcType,
                              item,
                              recommendation
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </Card.Body>
                </Card>
              ) : null}

              {hasRecommendationsInAccordion ? (
                <Card className="accordionCard">
                  <AccordianDrop
                    header={
                      <div
                        style={{ marginLeft: "15px" }}
                        className="takeASurveyCardHeader"
                      >
                        <p>Recommended Services</p>
                        <p>
                          <small>
                            Expand to view services that will be applied to
                            project
                          </small>
                        </p>
                      </div>
                    }
                    body={
                      <SurveyRecommendationsTable
                        recommendations={recommendations}
                        resources={allResources?.data}
                        phases={phases}
                        serviceCategories={serviceCategories?.data}
                        account_slug={account_slug}
                        authorizationCode={accessToken}
                        currentSurvey={currentSurvey?.data}
                      />
                    }
                    className="surveyResultsAccordion"
                    iconOpen={faCircleMinus}
                    iconClosed={faCirclePlus}
                    closeable={true}
                    setAccordianIsOpen={() => null}
                    startingAccordionStateIsOpen={true}
                  />
                </Card>
              ) : (
                <SurveyRecommendationsTable
                  recommendations={recommendations}
                  resources={allResources?.data}
                  phases={phases}
                  serviceCategories={serviceCategories?.data}
                  account_slug={account_slug}
                  authorizationCode={accessToken}
                  currentSurvey={currentSurvey?.data}
                />
              )}

              {calculations.length > 0 &&
              hasCalculationsInAccordion &&
              !calculationsAtTop ? (
                <Card className="accordionCard">
                  <AccordianDrop
                    header={
                      <div
                        style={{ marginLeft: "15px" }}
                        className="takeASurveyCardHeader"
                      >
                        <p>Calculations</p>
                        <p>
                          <small>
                            Expand to view calculations that resulted in the
                            recommendations
                          </small>
                        </p>
                      </div>
                    }
                    body={renderCalculationsOrResponseData(
                      calculations,
                      "Calculation Name",
                      "Calculated Value"
                    )}
                    className=""
                    iconOpen={faCircleMinus}
                    iconClosed={faCirclePlus}
                    closeable={true}
                    setAccordianIsOpen={() => null}
                    startingAccordionStateIsOpen={false}
                  />
                </Card>
              ) : null}

              {surveyResponses.length > 0 && hasSurveyResponsesInAccordion ? (
                <Card className="accordionCard">
                  <AccordianDrop
                    header={
                      <div
                        style={{ marginLeft: "15px" }}
                        className="takeASurveyCardHeader"
                      >
                        <p>Responses</p>
                        <p>
                          <small>
                            Expand to view the responses to the survey.
                          </small>
                        </p>
                      </div>
                    }
                    body={
                      <div className="flexCenter spaceBetween">
                        {renderCalculationsOrResponseData(
                          surveyResponses,
                          "Question",
                          "Answer"
                        )}
                      </div>
                    }
                    className=""
                    iconOpen={faCircleMinus}
                    iconClosed={faCirclePlus}
                    closeable={true}
                    setAccordianIsOpen={() => null}
                    startingAccordionStateIsOpen={false}
                  />
                </Card>
              ) : null}
            </Card.Body>
          </Card>
        ) : null}
      </div>
    );
  }
}

export default SurveyResults;
