import React from "react";

function IconTextButton({ url, dataMethod, className, iconClass, buttonText, uniqueStyles }) {

  return (
    <a data-method={dataMethod} style ={uniqueStyles} rel="nofollow" href={url}>
      <p className={className}>
        <i className={iconClass}></i> {buttonText}
      </p>
    </a>
  );
}

export default IconTextButton;
