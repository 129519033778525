import React, { useEffect, useState } from "react";
import {
  faCirclePhone,
  faCirclePlay,
  faCirclePlus,
  faCircleCheck,
} from "@fortawesome/pro-light-svg-icons";
import AccordianDrop from "@components/AccordianDrop/AccordianDrop";
import { getAccordianHeaderMarkup } from "../../Projects/CreateProjectMspa/Services/serviceHelpers";
import filePencilCheck from "../images/filePencilCheck.png";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Row, Col } from "react-bootstrap";
import "./YoureAllSet.css";
import "../../Projects/CreateProjectMspa/NewProjectDetails/NewProjectDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOauth from "@utils/customHooks/useOauth";
import API from "@utils/API/API";

function YoureAllSetPage({ account_slug, setRerender }) {
  window.onpopstate = function (e) {
    setRerender(true);
  };
  const [authorizationCode] = useOauth();

  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  useEffect(() => {
    if (!authorizationCode) return;

    API.Get(`${apiHost}/v1/me`, authorizationCode).then((response) => {
      API.Patch(
        `${apiHost}/${account_slug}/v1/users/${response.data.data.id}`,
        {
          data: {
            id: response.data.data.id,
            type: "users",
            attributes: {
              "guided-onboarding": {
                onboarding_status: "complete",
              },
            },
          },
        },
        authorizationCode
      ).then((response) => {
        console.log(response);
      });
    });
  }, [authorizationCode]);

  const headerForCard = (
    <>
      <CardHeader className="slideCardHeader">You're all set!</CardHeader>
    </>
  );
  return (
    <>
      <div className="allSetPage">
        {headerForCard}
        <div className="sectionCard shadow">
          <h3 className="sectionHeading">Jump in and get scoping.</h3>
          <div className="sectionContainer">
            <div className="contentSection">
              <div>
                <Row
                  className="accordianRedirect"
                  onClick={() =>
                    window.location.replace(`${appHost}/projects/new`)
                  }
                >
                  <Col>
                    <AccordianDrop
                      header={getAccordianHeaderMarkup(
                        "Create a New Project",
                        "Jump straight in and create a project. Generate your first Scope of Work in 5 easy steps."
                      )}
                      body={null}
                      iconOpen={faCirclePlus}
                      iconClosed={faCirclePlus}
                      closeable={false}
                      setAccordianIsOpen={null}
                      className="mainAccordion"
                    />
                  </Col>
                </Row>
                <Row
                  className="accordianRedirect"
                  onClick={() => window.location.replace(`${appHost}/projects`)}
                >
                  <Col>
                    <AccordianDrop
                      header={getAccordianHeaderMarkup(
                        "Explore Sample Projects",
                        "Your account has several sample projects already, explore these to see how projects are created within ScopeStack."
                      )}
                      body={null}
                      iconOpen={faCirclePlus}
                      iconClosed={faCirclePlus}
                      closeable={false}
                      setAccordianIsOpen={null}
                      className="mainAccordion"
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <h3 className="sectionSubHeading">
                  Learn more about our platform.
                </h3>
                <Row className="accordianRedirect">
                  <Col
                    onClick={() => window.open("https://youtu.be/WoQ6iUWgDrg")}
                  >
                    <AccordianDrop
                      header={getAccordianHeaderMarkup(
                        "ScopeStack 101 Video",
                        "Watch a deeper dive into the ScopeStack process with Alex Reynolds."
                      )}
                      body={null}
                      iconOpen={faCirclePlay}
                      iconClosed={faCirclePlay}
                      closeable={false}
                      setAccordianIsOpen={null}
                      className="mainAccordion"
                    />
                  </Col>
                </Row>
                <Row className="accordianRedirect">
                  <Col
                    onClick={() =>
                      window.open(
                        "https://demo.scopestack.io/meetings/jacob563/scopestack-discovery"
                      )
                    }
                  >
                    <AccordianDrop
                      header={getAccordianHeaderMarkup(
                        "Book a Demo",
                        "Setup a time to on-board your organization with a ScopeStack expert."
                      )}
                      body={null}
                      iconOpen={faCirclePhone}
                      iconClosed={faCirclePhone}
                      closeable={false}
                      setAccordianIsOpen={null}
                      className="mainAccordion"
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="imageContainer ">
              <div className="ssImage shadow">
                <img
                  src={filePencilCheck}
                  alt="files with pencil and check mark"
                />
              </div>
            </div>
          </div>
        </div>
        <Row className="youreAllSetFooter"></Row>
      </div>
    </>
  );
}
export default YoureAllSetPage;
