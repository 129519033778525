import React from "react";

export default class ScopeStackTable {
  constructor(tableName, headerData, tableData, footerData) {
    (this.tableName = tableName), (this.headerData = headerData);
    this.tableData = tableData;
    this.footerData = footerData;
  }

  buildHeaderRows() {
    if (this.headerData === null) {
      return null;
    }
    return (
      <tr className={this.headerData.rowClass}>
        {this.headerData.columns.map((header, index) => (
          <th
            colSpan={header.colSpan ? header.colSpan : null}
            className={header.class}
            key={index}
          >
            {header.name}
          </th>
        ))}
      </tr>
    );
  }

  buildDataRows() {
    if (this.tableData === null) {
      return null;
    }
    if (this.tableData.length === 0) {
      return (
        <tr className="noRow">
          <td
            colSpan={this.headerData.columns.length}
          >{`No ${this.tableName} found`}</td>
        </tr>
      );
    }
    return this.tableData.map((dataObj, index) => {
      return (
        <tr className={dataObj.rowClass} onClick={dataObj.onClick} key={index}>
          {dataObj.columns.map((d, index) => (
            <td
              colSpan={d.colSpan ? d.colSpan : null}
              className={d.class}
              key={index}
              onClick={dataObj.onClick}
              onContextMenu={dataObj.onContextMenu}
            >
              {d.name}
            </td>
          ))}
          <td
            key="actions"
            align="right"
            className="text-right actions actionsData col"
          >
            {dataObj.actions !== null ? dataObj.actions : ""}
          </td>
        </tr>
      );
    });
  }

  buildFooterRows() {
    if (this.footerData === null) {
      return null;
    }
    return (
      <tr className={this.footerData.rowClass}>
        {this.footerData.columns.map((footer, index) => (
          <td
            colSpan={footer.colSpan ? footer.colSpan : null}
            className={footer.class}
            key={index}
          >
            {footer.content}
          </td>
        ))}
      </tr>
    );
  }
}
