import React, { useState, useEffect } from "react";
import CardHeader from "@components/CardHeader/CardHeader";
import FormTextField from "@components/Forms/FormTextField";
import FormDateField from "@components/Forms/FormDateField";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import { Card, Form, Row, Col } from "react-bootstrap";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import API from "@API";
import { useNavigate } from "react-router-dom";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import {
  formatErrorResponse,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";

function KPI({
  account_slug,
  project_id,
  projectStatus,
  outcomes,
  outcomeId,
  account_id,
  indicators,
  setIndicatorCreated,
  setIndicatorUpdated,
  statuses,
  currentStatus,
  authorizationCode,
}) {
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const [performanceIndicator, setPerformanceIndicator] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [status, setStatus] = useState("");
  const [newStatusId, setNewStatusId] = useState("pending");
  const [notes, setNotes] = useState("");

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();

  var locationArr = location.split("/");
  var indicatorId = locationArr[locationArr.length - 2];
  var customerSuccessId = locationArr[locationArr.length - 3];
  if (lastWordInLocation === "edit") {
    customerSuccessId = locationArr[locationArr.length - 4];
  }

  useEffect(() => {
    if (lastWordInLocation === "edit") {
      if (currentStatus !== "") {
        setStatus(currentStatus);
      }
      for (let i = 0; i < indicators.length; i++) {
        if (indicators[i].id === indicatorId) {
          let attributes = indicators[i].attributes;
          setPerformanceIndicator(attributes.name);
          if (attributes["due-on"] !== null) setDueDate(attributes["due-on"]);
          for (let j = 0; j < statuses.length; j++) {
            if (indicators[i].attributes.status === statuses[j].id) {
              setStatus(statuses[j].attributes.name);
              setNewStatusId(statuses[j].id);
            }
          }
          setNotes(attributes.notes);
        }
      }
    }
  }, [indicators]);

  const statusOptions = statuses.map((status) => {
    return (
      <option accessKey={status.id} key={status.id}>
        {status.attributes.name}
      </option>
    );
  });

  const redirectToProjectOutcomes = () => {
    navigate(`/projects/${project_id}/customer_successes`);
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (lastWordInLocation === "new") {
      const newPerformanceIndicator = {
        data: {
          type: "performance-indicators",
          attributes: {
            "due-on": dueDate,
            status: newStatusId,
            name: performanceIndicator,
            notes: notes,
          },
          relationships: {
            "customer-success": {
              data: { type: "customer-successes", id: customerSuccessId },
            },
          },
        },
      };

      API.Post(
        `${apiHost}/${account_slug}/v1/performance-indicators`,
        newPerformanceIndicator,
        authorizationCode
      )
        .then((response) => {
          if (response.status == 201) {
            setIndicatorCreated(true);
            redirectToProjectOutcomes();
          }
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "outcome"));
          setShowFailAlert(true);
        });
    }

    if (lastWordInLocation === "edit") {
      const updatedPerformanceIndicator = {
        data: {
          type: "performance-indicators",
          id: indicatorId,
          attributes: {
            "due-on": dueDate,
            status: newStatusId,
            name: performanceIndicator,
            notes: notes,
          },
          relationships: {
            "customer-success": {
              data: { type: "customer-successes", id: customerSuccessId },
            },
          },
        },
      };
      API.Patch(
        `${apiHost}/${account_slug}/v1/performance-indicators/${indicatorId}`,
        updatedPerformanceIndicator,
        authorizationCode
      )
        .then((response) => {
          if (response.status == 200) {
            setUpdatedAlertIsOpen(true);
            setTimeout(() => {
              setUpdatedAlertIsOpen(false);
            }, 3000);
          }
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "outcome"));
          setShowFailAlert(true);
        });
    }
  };

  const handleUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Updated Performance Indicator"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleUpdatedAlert()}
      {handleFailAlert()}
      <Card>
        <CardHeader title={"Performance Indicator"} />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={12}>
                <FormTextField
                  label="Performance Indicator"
                  value={performanceIndicator}
                  onChange={(e) => setPerformanceIndicator(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormDateField
                  label="Due On"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </Col>
              <Col sm={6}>
                <ControlledDropDown
                  label="Status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    let selected = e.target.options.selectedIndex;
                    setNewStatusId(e.target.options[selected].accessKey);
                  }}
                  options={statusOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <MultipleLineField
                  label="Notes"
                  rows={3}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubmitButton />
              </Col>
              <Col xs={6}>
                <BackButton
                  url={`/projects/${project_id}/customer_successes`}
                />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default KPI;
