import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import CardHeader from "@components/CardHeader/CardHeader";
import RouterButton from "@components/Buttons/RouterButton/RouterButton";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

function ProjectPaymentCredits({
  account_slug,
  project_id,
  account_id,
  projectStatus,
  projectCredits,
  creditId,
  setCreditId,
  paymentCreditCreated,
  paymentCreditUpdated,
  currencyUnit,
  isLoading,
  paymentCredits,
  authorizationCode,
}) {
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  useEffect(() => {
    if (paymentCreditCreated === true) {
      setCreatedAlertIsOpen(true);
    }
  }, [isLoading, projectCredits]);

  const getTableData = () => {
    if (projectCredits.length === 0) {
      return [];
    }
    return projectCredits.map((credit) => {
      let source = "";
      let description = "";

      let paymentCreditData = credit.relationships["payment-credit"].data;
      paymentCredits.forEach((paymentCredit) => {
        if (paymentCreditData && paymentCredit.id == paymentCreditData.id) {
          source = paymentCredit.attributes["payment-source"];
          description = paymentCredit.attributes.description;
        }
      });

      const deletePaymentCredit = (e) => {
        e.stopPropagation();
        let userConfirms = confirm(
          "Are you sure you want to delete this credit?"
        );
        if (userConfirms) {
          let creditData = {
            data: {
              type: "project-credits",
              id: credit.id,
            },
          };
          API.Delete(
            `${apiHost}/${account_slug}/v1/project-credits/${credit.id}`,
            creditData,
            authorizationCode
          )
            .then((response) => {
              if (response.status == 204) {
                window.location.reload();
              }
            })
            .catch((err) => {
              setErrorMessages(
                formatUnprocessibleResponse(err, "Payment Credit")
              );
              setShowFailAlert(true);
            });
        }
      };

      const renderActions = () => {
        if (projectStatus === "building") {
          return (
            <OnHoverButtonIcon
              dataConfirm="Are you sure?"
              iconClass="fa fa-remove"
              onClick={(e) => deletePaymentCredit(e)}
              className="deleteBtn"
              buttonText="Delete"
            />
          );
        } else {
          return null;
        }
      };

      const redirectToEditPaymentCredit = () => {
        let id = credit.id;
        setCreditId(id);
        return navigate(`/projects/${project_id}/project_credits/${id}/edit`);
      };

      // Map returns data for ScopeStackTable Class
      return {
        rowClass: "paymentCreditsRow",
        onClick: redirectToEditPaymentCredit,
        columns: [
          {
            class: "",
            name: source,
          },
          {
            class: "",
            name: description,
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(credit.attributes.amount).toFixed(2)}
              />
            ),
          },
          {
            class: "",
            name: credit.attributes.criteria,
          },
        ],
        actions: renderActions(),
      };
    });
  };

  const getTotalRow = () => {
    const sum = projectCredits
      .map((credit) => parseFloat(credit.attributes.amount))
      .reduce((partialSum, a) => partialSum + a, 0);
    return {
      rowClass: "totalRow",
      onClick: null,
      columns: [
        {
          class: "",
          name: "TOTAL",
        },
        {
          class: "",
          name: "",
        },
        {
          class: "text-right",
          name: `${currencyUnit}${sum.toFixed(2)}`,
        },
        {
          class: "",
          name: "",
        },
      ],
      actions: null,
    };
  };

  const getFooterData = () => {
    let content = "";
    if (projectStatus === "building") {
      content = (
        <RouterButton
          path={`/projects/${project_id}/project_credits/new`}
          className="newResource"
          iconClass="fa fa-plus"
          buttonText="Add Credit"
        />
      );
    }
    return {
      rowClass: "col-12",
      columns: [{ class: "paymentCreditsFooter", content: content }],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Source/Reason",
      },
      {
        class: "",
        name: "Description",
      },
      {
        class: "text-right",
        name: "Amount",
      },
      {
        class: "",
        name: "Criteria",
      },
    ],
  };

  let tableData = getTableData();
  if (tableData.length !== 0) tableData.push(getTotalRow());
  const footerData = getFooterData();

  let paymentCreditsTable = new ScopeStackTable(
    "credits",
    headerData,
    tableData,
    footerData
  );

  const detailsCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="paymentCreditsAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Payment Credit created"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {detailsCreatedAlert()}
      {handleFailAlert()}
      <Card>
        <CardHeader title="Payment Credits" />
        <Card.Body>
          <BaseTable
            className="scopestack paymentCreditsTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={paymentCreditsTable.buildHeaderRows()}
            dataRows={paymentCreditsTable.buildDataRows()}
            footerRows={paymentCreditsTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProjectPaymentCredits;
