import "./style/style.css";

export interface TileProps {
  logo?: string;
  name: string;
  summary?: string;
  buttonText?: string;
  onClick?: () => void;
  width?: string;
  height?: string;
}

const IntegrationTile = ({
  logo,
  name,
  summary,
  buttonText,
  onClick,
  width,
  height,
}: TileProps): JSX.Element => {
  return (
    <div
      className="tileContainer"
      style={{ width: width || "32%", height: height || "300px" }}
    >
      <div className="logoBox">
        {logo && <img className="smallLogoImg" src={logo} />}
      </div>
      <div className="info">
        <div className="name">{name}</div>
        {summary && <div className="summary">{summary}</div>}
      </div>
      <div className="actionButton" onClick={onClick}>
        {buttonText || "View Integration"}
      </div>
    </div>
  );
};

export default IntegrationTile;
