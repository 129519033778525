import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import EditExpense from "./EditExpense/EditExpense";
import ProjectExpenses from "./ProjectExpenses/ProjectExpenses";
import NewExpense from "./NewExpense/NewExpense";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";

function ExpenseMspa({ account_slug, project_id, account_id }) {
  const [expenseId, setExpenseId] = useState(null);
  const [projectStatus, setProjectStatus] = useState("");
  const [project, setProject] = useState(null);
  const [paymentTerm, setPaymentTerm] = useState(null);
  const [expenses, setExpenses] = useState([]);
  const [lobs, setLobs] = useState([]);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [resources, setResources] = useState([]);
  const [services, setServices] = useState([]);
  const [projectResources, setProjectResources] = useState([]);
  const [phases, setPhases] = useState([]);
  const [expenseCreated, setExpenseCreated] = useState(false);
  const [expenseUpdated, setExpenseUpdated] = useState(false);
  const [currencyUnit, setCurrencyUnit] = useState("");
  const [rateTableId, setRateTableId] = useState(null);
  const [isResourcesFromRates, setIsResourcesFromRates] = useState(false);

  const [authorizationCode] = useOauth();

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode != "") {
      // Get Project Phases, Resources, & Expense Categories from account
      API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
        (response) => {
          let accountData = response.data.data;
          API.Get(
            accountData.links["expense-categories"] + "?include=phase",
            authorizationCode
          ).then((response) => {
            setExpenseCategories(response.data.data);
          });

          // Get Project Data & Project Resources & set Project Status
          API.Get(
            `${apiHost}/${account_slug}/v1/projects/${project_id}?include=rate-table,project-phases,project-phases.phase,project-expenses,project-expenses.project-phase,project-expenses.expense-category,project-expenses.resource,project-expenses.project-service,project-expenses.project-resource,payment-term,project-services`,
            authorizationCode
          ).then((response) => {
            const data = response.data.data;
            const rateTableData = data.relationships["rate-table"].data;
            if (rateTableData !== null) {
              setRateTableId(rateTableData.id);
            }

            const paymentTermData = response.data.included.filter(
              (item) => item.type == "payment-terms"
            )[0];
            if (paymentTermData !== null) {
              setPaymentTerm(paymentTermData);
            }

            let projectPhases = response.data.included.filter(
              (item) => item.type === "project-phases"
            );
            setPhases(projectPhases);

            let projectExpenses = response.data.included.filter(
              (item) => item.type === "project-expenses"
            );
            setExpenses(
              projectExpenses.filter((expense) => expense.attributes.active)
            );

            setServices(
              response.data.included.filter(
                (item) =>
                  item.type === "project-services" && item.attributes.active
              )
            );

            setProject(data);
            if (data.attributes.status !== null)
              setProjectStatus(data.attributes.status);
            setCurrencyUnit(data.attributes["field-labels"].currency_unit);

            // Get Project Resources
            let resourcesUrl =
              data.relationships["project-resources"].links.related +
              "?include=line-of-business";
            API.Get(resourcesUrl, authorizationCode).then((response) => {
              let resources = response.data.data;
              setProjectResources(resources);
              setIsLoading(false);
            });

            if (!accountData.attributes.settings["project_resource_rates"]) {
              // Get resources
              API.Get(
                `${apiHost}/${account_slug}/v1/resources?page[size]=1000&filter[active]=true,false`,
                authorizationCode
              ).then((resourceResponse) => {
                let resources = resourceResponse.data.data.filter(
                  (resource) => resource.attributes.active
                );
                setResources(resources);
              });
            } else {
              setIsResourcesFromRates(true);
              // Get Resource Rates
              API.Get(
                `${apiHost}/${account_slug}/v1/resource-rates?include=resource,rate-table,line-of-business&filter[rate-table]=${rateTableData.id}&page[size]=1000`,
                authorizationCode
              ).then((resRateResponse) => {
                let resources = resRateResponse.data.included.filter(
                  (item) => item.type == "resources" && item.attributes.active
                );
                let allResourceRates = resRateResponse.data.data;
                let resourceRatesToReturn = new Set();
                allResourceRates.forEach((resourceRate) => {
                  resources.forEach((resource) => {
                    if (
                      resource.id == resourceRate.relationships.resource.data.id
                    ) {
                      resourceRatesToReturn.add(resourceRate);
                    }
                  });
                });

                setResources(Array.from(resourceRatesToReturn));
              });
            }
          });
        }
      );

      // Get Service Categories
      API.Get(
        `${apiHost}/${account_slug}/v1/service-categories?page[size]=250`,
        authorizationCode
      ).then((response) => {
        setLobs(response.data.data);
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/project_expenses"}
            element={
              <ProjectExpenses
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                expenseId={expenseId}
                setExpenseId={(expenseId) => setExpenseId(expenseId)}
                project={project}
                expenses={expenses}
                resources={resources}
                projectResources={projectResources}
                phases={phases}
                services={services}
                expenseCategories={expenseCategories}
                expenseCreated={expenseCreated}
                expenseUpdated={expenseUpdated}
                currencyUnit={currencyUnit}
                rateTableId={rateTableId}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
                lobs={lobs}
                paymentTerm={paymentTerm}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_expenses/new"}
            element={
              <NewExpense
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                expenses={expenses}
                resources={resources}
                projectResources={projectResources}
                expenseCategories={expenseCategories}
                phases={phases}
                services={services}
                setExpenseCreated={(created) => setExpenseCreated(created)}
                expenseCreated={expenseCreated}
                expenseUpdated={expenseUpdated}
                currencyUnit={currencyUnit}
                rateTableId={rateTableId}
                authorizationCode={authorizationCode}
                lobs={lobs}
                isResourcesFromRates={isResourcesFromRates}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_expenses/:expenseId/edit"}
            element={
              <EditExpense
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                expenses={expenses}
                resources={resources}
                projectResources={projectResources}
                expenseId={expenseId}
                expenseCategories={expenseCategories}
                phases={phases}
                services={services}
                setExpenseUpdated={(updated) => setExpenseUpdated(updated)}
                expenseCreated={expenseCreated}
                expenseUpdated={expenseUpdated}
                currencyUnit={currencyUnit}
                rateTableId={rateTableId}
                authorizationCode={authorizationCode}
                lobs={lobs}
                isResourcesFromRates={isResourcesFromRates}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default ExpenseMspa;
