import React from "react";
import IconTextButton from "@components/Buttons/IconTextButton/IconTextButton";

function CreateProjectBlueprintBtn({ accountSlug, projectId, component }) {
  return (
    <IconTextButton
      className="btn btn-success createProjectBlueprintBtn"
      url={`/projects/${projectId}/sow_blueprints/new`}
      iconClass="fa fa-sitemap"
      buttonText="Create Project Blueprint"
    />
  );
}

export default CreateProjectBlueprintBtn;
