import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import BackButton from "@components/Buttons/BackButton/BackButton";
import API from "@API";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import { useLocation } from "react-router-dom";

function EditApproval({
  account_slug,
  project_id,
  account_id,
  versions,
  projectApprovals,
  approvalSteps,
  users,
  authorizationCode,
}) {
  const [comment, setComment] = useState("");
  const [approvalLink, setApprovalLink] = useState("");
  const [declineLink, setDeclineLink] = useState("");
  const [cancelApprovalLink, setCancelApprovalLink] = useState("");
  const [adjustScopeLink, setAdjustScopeLink] = useState("");

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const windowLocation = window.location.href;
  var locationArr = windowLocation.split("/");
  var id = locationArr[locationArr.length - 2];
  let location = useLocation();

  const [showFailAlert, setShowFailAlert] = useState("");
  const [errorMessages, setErrorMessages] = useState("");

  useEffect(() => {
    projectApprovals.forEach((approval) => {
      if (approval.id == id) {
        setApprovalLink(approval.links.approve);
        setDeclineLink(approval.links.decline);
        setCancelApprovalLink(approval.links.cancel);
        setAdjustScopeLink(approval.links.rescope);
      }
    });
  }, [projectApprovals]);

  const renderButtons = () => {
    if (location.state.approvalStep !== "business_approval") {
      return (
        <Button
          style={{ marginLeft: "5px" }}
          variant="secondary"
          onClick={() => {
            let commentData = getCommentData();
            API.Put(declineLink, commentData, authorizationCode)
              .then((res) => {
                if (res.status == 200) {
                  window.location.replace(
                    `${appHost}/projects/${project_id}/project_approvals`
                  );
                }
              })
              .catch((err) => {
                setErrorMessages(formatUnprocessibleResponse(err, "Approval"));
                setShowFailAlert(true);
              });
          }}
        >
          <i className="fa fa-thumbs-down"></i> Decline
        </Button>
      );
    }

    if (location.state.approvalStep === "business_approval") {
      return (
        <>
          <Button
            style={{ marginLeft: "5px" }}
            variant="secondary"
            onClick={() => {
              let commentData = getCommentData();
              API.Put(adjustScopeLink, commentData, authorizationCode)
                .then((res) => {
                  if (res.status == 200) {
                    window.location.replace(
                      `${appHost}/projects/${project_id}/project_approvals`
                    );
                  }
                })
                .catch((err) => {
                  setErrorMessages(
                    formatUnprocessibleResponse(err, "Approval")
                  );
                  setShowFailAlert(true);
                });
            }}
          >
            <i className="fa fa-thumbs-down"></i> Adjust Scope
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            variant="secondary"
            onClick={() => {
              let commentData = getCommentData();
              API.Put(declineLink, commentData, authorizationCode)
                .then((res) => {
                  if (res.status == 200) {
                    window.location.replace(
                      `${appHost}/projects/${project_id}/project_approvals`
                    );
                  }
                })
                .catch((err) => {
                  setErrorMessages(
                    formatUnprocessibleResponse(err, "Approval")
                  );
                  setShowFailAlert(true);
                });
            }}
          >
            <i className="fa fa-thumbs-down"></i> Adjust Price
          </Button>
          <a data-confirm="Are you sure? This will cancel the project.">
            <Button
              style={{ marginLeft: "5px" }}
              variant="danger"
              onClick={() => {
                let commentData = getCommentData();
                API.Put(cancelApprovalLink, commentData, authorizationCode)
                  .then((res) => {
                    if (res.status == 200) {
                      window.location.replace(
                        `${appHost}/projects/${project_id}/project_approvals`
                      );
                    }
                  })
                  .catch((err) => {
                    setErrorMessages(
                      formatUnprocessibleResponse(err, "Approval")
                    );
                    setShowFailAlert(true);
                  });
              }}
            >
              <i className="fa fa-trash"></i> Cancel
            </Button>
          </a>
        </>
      );
    }
  };

  const getCommentData = () => {
    let approvalStepId = "";
    let approverId = "";
    projectApprovals.forEach((approval) => {
      if (approval.id == id) {
        approvalStepId = approval.relationships["approval-step"].data.id;
      }
      users.forEach((user) => {
        if (user.attributes.name == approval.attributes["approver-name"]) {
          approverId = user.id;
        }
      });
    });

    let commentData = {
      data: {
        type: "project-approvals",
        id: id,
        attributes: {
          comment: comment,
        },
        relationships: {
          "approval-step": {
            data: { type: "approval-steps", id: approvalStepId },
          },
          user: {
            data: { type: "users", id: approverId },
          },
        },
      },
    };
    return commentData;
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleFailAlert()}
      <Card>
        <CardHeader title="Approve/Decline" />
        <Card.Body>
          <Row>
            <Col>
              <MultipleLineField
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                label="Comments"
                rows={3}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "2%" }}>
            <Col
              xs={location.state.approvalStep === "technical_approval" ? 6 : 8}
            >
              <Button
                variant="primary"
                onClick={() => {
                  let commentData = getCommentData();
                  API.Put(approvalLink, commentData, authorizationCode)
                    .then((res) => {
                      if (res.status == 200) {
                        window.location.replace(
                          `${appHost}/projects/${project_id}/project_approvals`
                        );
                      }
                    })
                    .catch((err) => {
                      setErrorMessages(
                        formatUnprocessibleResponse(err, "Approval")
                      );
                      setShowFailAlert(true);
                    });
                }}
              >
                <i className="fa fa-thumbs-up"></i> Approve
              </Button>
              {renderButtons()}
            </Col>
            <Col
              xs={location.state.approvalStep === "technical_approval" ? 6 : 4}
            >
              <BackButton url={`/projects/${project_id}/project_approvals`} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default EditApproval;
