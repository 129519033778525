import FEPaginatedTable from "../../../components/Tables/FEPaginatedTable/FEPaginatedTable";
import { useNavigate } from "react-router";
import { getSurveyTags } from "../SurveyHelpers/surveyHelpers";

function ArchivedSurveys({ surveysData, filtering }): JSX.Element {
  const navigate = useNavigate();

  const columnHeaders = [
    {
      label: "Survey Name",
      width: 2,
      sorted: "false",
      sortable: true,
    },
    {
      label: "Description",
      width: 2,
      sorted: "false",
      sortable: true,
    },
    {
      label: "Archived By",
      width: 2,
      sorted: "false",
      sortable: true,
    },
    {
      label: "Archived On",
      width: 2,
      sorted: "desc",
      sortable: true,
    },
    {
      label: "Teams",
      width: 2,
      sorted: "desc",
      sortable: true,
    },
    {
      label: "Tags",
      width: 2,
      sorted: "desc",
      sortable: true,
    },
  ];

  interface ArchivedSurvey {
    id: number;
    attributes: {
      surveyName: JSX.Element;
      description: string;
      archivedBy: string;
      archivedOn: string;
      teams: string;
      tags: string;
    };
  }

  const tableItems: ArchivedSurvey[] = [];

  surveysData.data.forEach((survey, i) => {
    const surveyName = (
      <span
        className="surveyName"
        onClick={() => navigate(`/surveys/${survey.id}/take`)}
      >
        {survey.attributes.surveyName}
      </span>
    );

    tableItems.push({
      id: i,
      attributes: {
        surveyName: surveyName,
        description: "",
        archivedBy: "",
        archivedOn: "",
        teams: "",
        tags: "",
      },
    });
  });

  return (
    <>
      <FEPaginatedTable
        tableType="surveys"
        tableItemsData={{
          ...surveysData,
          data: tableItems,
        }}
        columnHeaders={columnHeaders}
        checkboxes={false}
        filtered={filtering}
        checkedItems={[]}
        setCheckedItems={() => null}
        pageSizeEnabled
      />
    </>
  );
}

export default ArchivedSurveys;
