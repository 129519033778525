import React from "react";
import Expense from "../Expense/Expense";

function NewExpense({
  account_slug,
  project_id,
  account_id,
  projectStatus,
  expenses,
  resources,
  projectResources,
  expenseCategories,
  phases,
  services,
  setExpenseCreated,
  expenseCreated,
  expenseUpdated,
  currencyUnit,
  rateTableId,
  authorizationCode,
  lobs,
  isResourcesFromRates
}) {
  return (
    <Expense
      account_slug={account_slug}
      project_id={project_id}
      account_id={account_id}
      projectStatus={projectStatus}
      expenses={expenses}
      resources={resources}
      projectResources={projectResources}
      expenseCategories={expenseCategories}
      phases={phases}
      services={services}
      setExpenseCreated={setExpenseCreated}
      expenseCreated={expenseCreated}
      expenseUpdated={expenseUpdated}
      currencyUnit={currencyUnit}
      rateTableId={rateTableId}
      authorizationCode={authorizationCode}
      lobs={lobs}
      isResourcesFromRates={isResourcesFromRates}
    />
  );
}

export default NewExpense;
