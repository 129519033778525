import React, { useEffect, useState } from "react";
import ScopeStackTable from "../ScopeStackTable";
import BaseTable from "../BaseTable/BaseTable";
import { Form } from "react-bootstrap";
import Select from "react-select";
import "./style.css";
import { Phase } from "../../../types/phase";
import {
  useListSurveyRecommendationsForAccountQuery,
  useUpdateSurveyRecommendationMutation,
} from "@generated";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";

function SurveyRecommendationsTable({
  recommendations,
  resources,
  phases,
  serviceCategories,
  account_slug,
  authorizationCode,
  currentSurvey,
}) {
  const [updateRecommendation] = useUpdateSurveyRecommendationMutation();
  const [allServices, setAllServices] = useState(
    recommendations.map((rec) => rec.service)
  );
  if (currentSurvey?.data) {
    currentSurvey = currentSurvey?.data;
  }

  const {
    data: surveyRecommendations,
    error: surveysRecommendationsError,
    isLoading: surveysRecommendationsLoading,
    refetch,
  } = useListSurveyRecommendationsForAccountQuery({
    page: {
      size: 1000,
    },
    slug: account_slug,
    include: ["survey", "resource"],
    filter: {
      survey: currentSurvey.id,
    },
  });

  useEffect(() => {
    setAllServices(recommendations.map((rec) => rec.service));
    checkAllServicesResources();
  }, [recommendations, resources]);

  const checkAllServicesResources = () => {
    let subservices: any[] = [];
    recommendations.forEach((rec) => {
      if (rec.subservices?.length > 0) {
        subservices = subservices.concat(rec.subservices);
      }
    });
    let allResourcesHaveAResource = true;
    allServices.forEach((service) => {
      if (
        service?.relationships?.resource?.data?.id
      ) {
        const resource = resources.find(
          (res) => res.id == service?.relationships?.resource?.data?.id
        );
        if (!resource) {
          allResourcesHaveAResource = false;
        }
      } else {
        allResourcesHaveAResource = false;
      }
    });
    if (subservices.length > 0) {
      subservices.forEach((subservice) => {
        let resource;
        if (
          subservice &&
          subservice.relationships &&
          subservice.relationships &&
          subservice.relationships.resource &&
          subservice.relationships.resource.data
        ) {
          resource = resources.find(
            (res) => res.id == subservice.relationships.resource.data.id
          );
        }
        if (!resource) {
          let serviceForSubservice;
          allServices.forEach((s) => {
            if (s?.id == subservice?.relationships?.service?.data?.id) {
              serviceForSubservice = s;
            }
          });
          if (serviceForSubservice) {
            resource = resources.find(
              (r) => r?.id == serviceForSubservice?.relationships?.resource?.data?.id
            );
          }
          if (!resource) {
            allResourcesHaveAResource = false;
          }
        }
      });
    }
    let element = document.getElementById("surveysContinue");
    if (allResourcesHaveAResource) {
      let warning = document.getElementById("recommendationWarning");
      if (element && element.innerHTML !== "View Related Project") {
        element.removeAttribute("disabled");
        if (warning) {
          warning.style.display = "none";
        }
      }
    } else {
      if (element && element.innerHTML !== "View Related Project") {
        element.setAttribute("disabled", "true");
        if (
          element.parentElement &&
          !document.getElementById("recommendationWarning")
        ) {
          let alertContainer = document.createElement("div");
          alertContainer.setAttribute("id", "recommendationWarning");
          let alertText = document.createElement("p");
          alertText.innerText = "See Recommendations Below to Continue";
          alertText.style.padding = "10px";
          alertText.style.textAlign = "center";
          alertText.style.color = "#F2F2F2";
          alertContainer.style.background = "#CC672F";
          alertContainer.style.marginTop = "50px";
          alertContainer.appendChild(alertText);
          element.parentElement.appendChild(alertContainer);
        }
      }
    }
  };

  const handleRecommendationUpdate = (attributeToUpdate, value, item) => {
    const foundRecommendation = surveyRecommendations?.data?.find(
      (rec) =>
        (item.type == "services" &&
          rec &&
          rec.attributes &&
          rec.attributes["item-id"] == item.id) ||
        (item.type == "subservices" &&
          rec &&
          rec.attributes &&
          rec.attributes["item-id"] == item.relationships.service.data.id)
    );

    const data = {
      id: Number(foundRecommendation?.id),
      type: "survey-recommendations",
    };

    let attributes = { ...foundRecommendation?.attributes };
    let refinements: any[] = [];

    if (
      item.type == "subservices" &&
      foundRecommendation &&
      foundRecommendation["attributes"] &&
      foundRecommendation["attributes"]["refinements"]
    ) {
      (foundRecommendation["attributes"]["refinements"] || []).forEach(
        (refinement) => {
          if (refinement["item_id"] == item.id) {
            if (attributeToUpdate == "quantity") {
              refinement = {
                ...refinement,
                quantity: value,
              };
            } else {
              refinement = {
                ...refinement,
                resource_id: value,
              };
            }
          }
          refinements.push(refinement);
        }
      );
      attributes = { refinements: refinements };
      data["attributes"] = attributes;
    }

    if (foundRecommendation) {
      if (attributeToUpdate !== "resource") {
        let attributes = {};
        attributes[attributeToUpdate] = value;
        data["attributes"] = attributes;
      } else if (item.type === "services") {
        data["relationships"] = {
          resource: {
            data: {
              type: "resources",
              id: value,
            },
          },
        };
      }
      // Re-enable the continue button if all services have a resource
      checkAllServicesResources();
      updateRecommendation({
        slug: account_slug,
        id: Number(foundRecommendation?.id) || 0,
        body: {
          data: data,
        },
      }).then((res) => {
        refetch();
      });
    }
  };

  const getSubserviceTable = (subservices) => {
    let subserviceTable = new ScopeStackTable(
      "Subservices",
      subserviceHeaderData,
      getTableData(subservices),
      null
    );
    return subserviceTable;
  };

  const getTableData = (services) => {
    if (services.length === 0) {
      return [];
    }

    const dataToReturn = services.map((item) => {
      let resource = {
        attributes: {
          name: "",
        },
      };
      let resourceNotFound = false;
      let serviceCategory = {
        attributes: {
          name: item.type == "subservices" ? "" : "N/A",
        },
      };
      if (item.relationships.resource && item.relationships.resource.data) {
        resource = resources.find(
          (res) => res.id == item.relationships.resource.data.id
        );
      }

      if (
        item.type == "subservices" &&
        (!resource || resource.attributes.name === "")
      ) {
        let serviceForSubservice;

        allServices.forEach((s) => {
          if (s.id == item.relationships.service.data.id) {
            serviceForSubservice = s;
          }
        });

        if (serviceForSubservice) {
          resource = resources.find(
            (r) => r.id == serviceForSubservice.relationships.resource.data.id
          );
        }
      }
      if (!resource || resource.attributes.name === "") {
        resourceNotFound = true;
      }
      if (
        item.relationships["service-category"] &&
        item.relationships["service-category"].data
      ) {
        const category = serviceCategories.find(
          (sc) => sc.id == item.relationships["service-category"].data.id
        );
        if (category) {
          serviceCategory = category;
        }
      }
      // Map returns a table row for each item
      return {
        id: item.id,
        rowClass: item.recalculated
          ? "recommendationsRow recalculatedRow"
          : "recommendationsRow",
        onClick: null,
        columns: [
          {
            class: item.type !== "services" ? "displayNone" : "",
            name: (
              <strong>
                {serviceCategory.attributes["nested-name"]
                  ? serviceCategory.attributes["nested-name"]
                  : serviceCategory.attributes.name}
              </strong>
            ),
          },
          {
            class: item.type == "services" ? "" : "subserviceName",
            name:
              item.type === "services" ? (
                <strong>{item.attributes.name}</strong>
              ) : (
                item.attributes.name
              ),
          },
          {
            class: "",
            name: (
              <Form.Control
                id={item.id}
                type="number"
                value={
                  item.type === "services"
                    ? parseInt(item.quantity)
                    : parseInt(item.attributes.quantity)
                }
                min="1"
                step="1"
                onChange={(e) => {
                  item.type === "services"
                    ? (item.quantity = e.target.value)
                    : (item.attributes.quantity = e.target.value);
                }}
                onBlur={(e) => {
                  handleRecommendationUpdate("quantity", e.target.value, item);
                }}
                disabled={true} // functionality was mocked up to allow the change and I wrote the code for it, but it was decided in the end to disable these to match the current functionality, but I am leaving the code in case we want to allow the user to change quantity in the future
              />
            ),
          },
          {
            class: "",
            name: (
              <Form.Control
                id={item.id}
                type="number"
                defaultValue={
                  item.type === "services"
                    ? item.attributes["total-hours"]
                    : item.attributes["suggested-hours"]
                }
                min="1"
                step="1"
                onChange={(e) => {
                  item.attributes["override-hours"] = e.target.value;
                }}
                disabled={true} // There is no hours attribute on recommendations to update in api so we disble this field
              />
            ),
          },
          {
            class: "",
            name: (
              <>
                <Select
                  id={item.id}
                  defaultValue={{
                    label: resource ? resource.attributes.name : "",
                    value:
                      item.relationships.resource &&
                      item.relationships.resource.data
                        ? item.relationships.resource.data.id
                        : null,
                  }}
                  onChange={(e) => {
                    let resourceId = e?.value;
                    item.relationships.resource.data = {
                      type: "resources",
                      id: resourceId,
                    };
                    const newServices = allServices.map((service) => {
                      if (service.id == item.id) {
                        service = item;
                      }
                      return service;
                    });
                    setAllServices(newServices);
                    handleRecommendationUpdate("resource", e?.value, item);
                  }}
                  options={resources.map((resource) => {
                    return {
                      label: resource.attributes.name,
                      value: resource.id,
                    };
                  })}
                />
                {resourceNotFound ? (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    You must select a resource to continue.
                  </small>
                ) : null}
              </>
            ),
          },
        ],
        actions: null,
      };
    });

    if (services[0].type === "services") {
      dataToReturn.forEach((dataItem, index) => {
        let subservicesToRender: any[] = [];
        recommendations
          .map((rec) => rec.subservices)
          .forEach((listOfSubs) => {
            listOfSubs.forEach((sub) => {
              if (sub && sub.relationships.service.data.id == dataItem.id) {
                subservicesToRender.push(sub);
              }
            });
          });
        if (subservicesToRender.length > 0) {
          const subservicesTable = getSubserviceTable(subservicesToRender);
          dataToReturn.splice(index + 1, 0, {
            rowClass: "recommendationsRow",
            onClick: null,
            columns: [
              {
                class: "",
                name: "",
              },
              {
                class: "",
                colSpan: "4",
                name: (
                  <BaseTable
                    className="sansTable greyBackground"
                    striped={true}
                    hover={false}
                    bordered={true}
                    headerRows={subservicesTable.buildHeaderRows()}
                    dataRows={subservicesTable.buildDataRows()}
                    footerRows={subservicesTable.buildFooterRows()}
                    isLoading={false}
                  />
                ),
              },
            ],
            actions: null,
          });
        }
      });
    }

    return dataToReturn;
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Service Category",
      },
      {
        class: "",
        name: "Service and Subservices",
      },
      {
        class: "",
        name: "Quantity",
      },
      {
        class: "",
        name: "Unit Hours",
      },
      {
        class: "",
        name: "Resource",
      },
    ],
  };

  const subserviceHeaderData = {
    rowClass: "displayNone",
    columns: headerData.columns.map((column) => {
      return {
        class: "displayNone",
        name: "",
      };
    }),
  };

  const phasesWithRecommendations = new Set();

  phases.forEach((phase: Phase) => {
    recommendations.forEach((rec) => {
      if (
        rec &&
        rec.service &&
        rec.service.relationships.phase.data.id == phase.id
      ) {
        phasesWithRecommendations.add(phase);
      }
    });
  });

  const phaseIds = Array.from(phasesWithRecommendations).map((p: any) => p.id);
  const filteredPhases = Array.from(phasesWithRecommendations).filter(
    (phase: any, index: number) => !phaseIds.includes(phase.id, index + 1)
  );

  if (surveysRecommendationsLoading) {
    return <SmallSpinner />;
  }

  return (
    <div>
      {filteredPhases.map((phase: any) => {
        let serviceTable = new ScopeStackTable(
          "services",
          headerData,
          getTableData(
            allServices.filter(
              (service) => phase.id == service?.relationships?.phase?.data?.id
            )
          ),
          null
        );
        return (
          <React.Fragment key={phase.id}>
            <div className="genericMediumHeader">{phase.attributes.name}</div>
            <BaseTable
              className="sansTable greyBackground nestedServiceTable"
              striped={true}
              hover={false}
              bordered={true}
              headerRows={serviceTable.buildHeaderRows()}
              dataRows={serviceTable.buildDataRows()}
              footerRows={serviceTable.buildFooterRows()}
              isLoading={false}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default SurveyRecommendationsTable;
