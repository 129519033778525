import React, { useState } from "react";
import VendorQuote from "../VendorQuote/VendorQuote";

function NewVendorQuote({
  account_slug,
  project_id,
  projectStatus,
  vendorQuotes,
  vendorQuoteId,
  setVendorQuoteId,
  account_id,
  setVendorQuoteCreated,
  vendors,
  linesOfBusiness,
  deriveFromServices,
  setDeriveFromServices,
  authorizationCode,
}) {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  return (
    <div className="col-sm-12" id="sow-workarea">
      <div className="tabContent">
        <VendorQuote
          account_slug={account_slug}
          project_id={project_id}
          projectStatus={projectStatus}
          vendorQuotes={vendorQuotes}
          vendorQuoteId={vendorQuoteId}
          setVendorQuoteId={setVendorQuoteId}
          account_id={account_id}
          setVendorQuoteCreated={setVendorQuoteCreated}
          vendors={vendors}
          linesOfBusiness={linesOfBusiness}
          deriveFromServices={deriveFromServices}
          setDeriveFromServices={setDeriveFromServices}
          hasUnsavedChanges={hasUnsavedChanges}
          setHasUnsavedChanges={(bool) => setHasUnsavedChanges(bool)}
          authorizationCode={authorizationCode}
        />
      </div>
    </div>
  );
}

export default NewVendorQuote;
