import React from "react";
import Product from "../Product/Product";

function NewCustomProduct({
  account_slug,
  project_id,
  projectStatus,
  account_id,
  products,
  services,
  setProductCreated,
  productId,
  authorizationCode,
}) {
  return (
    <Product
      account_slug={account_slug}
      project_id={project_id}
      account_id={account_id}
      projectStatus={projectStatus}
      products={products}
      services={services}
      setProductCreated={setProductCreated}
      productId={productId}
      authorizationCode={authorizationCode}
    />
  );
}

export default NewCustomProduct;
