import { Route, Routes } from "react-router-dom";
import EditAccount from "./EditAccount/EditAccount";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { useAuthorization } from "@common/providers";

const AccountMspa = (): JSX.Element => {
  const authorized = useAuthorization();

  if (!authorized) {
    return <ScopeStackSpinner />;
  }

  return (
    <Routes>
      <Route path="/:user_id/edit" element={<EditAccount />} />
    </Routes>
  );
};

export default AccountMspa;
