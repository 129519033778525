import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import { capitalizeFirstLetter } from "@utils/helperFunctions";
import CurrencyFormat from "react-currency-format";

function MsPricing({ account_slug, account_id, project_id }) {
  // Env
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  // Form States
  const [vendors, setVendors] = useState([]);
  const [thirdPartyServices, setThirdPartyServices] = useState([]);
  const [currencyUnit, setCurrencyUnit] = useState("");
  const [adjustment, setAdjustment] = useState("0.0");
  const [terms, setTerms] = useState(0.0);
  const [billingFrequency, setBillingFrequency] = useState("Monthly");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get Vendors
      API.Get(`${apiHost}/${account_slug}/v1/vendors`, authorizationCode).then(
        (response) => {
          let data = response.data.data;
          setVendors(data);
        }
      );

      //Get third party services with included data & use to set state
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/quotes?include=vendor`,
        authorizationCode
      ).then((response) => {
        let includedData = response.data.included;
        let subservices = [];
        if (includedData) {
          for (let i = 0; i < includedData.length; i++) {
            if (includedData[i].type === "project-subservices") {
              subservices.push(includedData[i]);
            }
          }
        }
        setThirdPartyServices(response.data.data);
        setIsLoading(false);
      });

      //Get Project Info
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setBillingFrequency(
          capitalizeFirstLetter(data.attributes["recurring-billing-frequency"])
        );
        setCurrencyUnit(data.attributes["field-labels"].currency_unit);
        setAdjustment(data.attributes["mrr-adjustment"]);
        setTerms(data.attributes["mrr-terms"]);
      });
    }
  }, [authorizationCode]);

  const getTableData = (array) => {
    if (array.length == 0) {
      return [];
    }

    return array.map((item) => {
      let vendorName = "";

      let recurringRevenue = 0;
      let recurringCost = 0;

      let terms = parseFloat(item.attributes["mrr-terms"]);
      let oneTimeRevenue = parseFloat(item.attributes.price);
      let totalRevenue = parseFloat(item.attributes["total-revenue"]);
      let oneTimeCost = parseFloat(item.attributes.cost);
      let totalCost = parseFloat(item.attributes["total-cost"]);
      let profit = parseFloat(item.attributes["profit"]);
      let margin = (profit / totalRevenue) * 100;

      if (item.type == "totalRow") {
        recurringRevenue = parseFloat(item.attributes["recurring-price"]);
        recurringCost = parseFloat(item.attributes["recurring-cost"]);
      }

      if (item.type !== "totalRow") {
        // Multiply recurring values by terms to get correct values to display
        recurringRevenue =
          parseFloat(item.attributes["recurring-price"]) * terms;
        recurringCost = parseFloat(item.attributes["recurring-cost"]) * terms;

        // Match vendor relationship to get vendor name
        vendors.forEach((vendor) => {
          let vendorData = item.relationships.vendor.data;
          if (vendorData && vendor.id == vendorData.id) {
            vendorName = vendor.attributes.name;
          }
        });
      }

      return {
        rowClass:
          item.type === "totalRow"
            ? "borderTopTotalRow"
            : "thirdPartyPricingRow",
        onClick: null,
        columns: [
          {
            class: "col-2",
            name: item.type === "totalRow" ? "" : vendorName,
          },
          {
            class: item.type === "totalRow" ? "col-3 text-right" : "col-3",
            name:
              item.type === "totalRow" ? "TOTAL" : item.attributes.description,
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={oneTimeRevenue.toFixed(2)}
              />
            ),
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={recurringRevenue.toFixed(2)}
              />
            ),
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={totalRevenue.toFixed(2)}
              />
            ),
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={oneTimeCost.toFixed(2)}
              />
            ),
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={recurringCost.toFixed(2)}
              />
            ),
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(profit).toFixed(2)}
              />
            ),
          },
          {
            class: "text-right",
            name: margin ? (
              <span>{parseFloat(margin).toFixed(2)}%</span>
            ) : (
              <span>{parseFloat(0).toFixed(2)}%</span>
            ),
          },
        ],
        actions: null,
      };
    });
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "col-2",
        name: "Partner / Vendor",
      },
      {
        class: "col-3",
        name: "Description",
      },
      {
        class: "text-right",
        name: "One Time Revenue",
      },
      {
        class: "text-right",
        name: "Recurring Revenue",
      },
      {
        class: "text-right",
        name: "Total Value",
      },
      {
        class: "text-right",
        name: "One Time Cost",
      },
      {
        class: "text-right",
        name: "Recurring Cost",
      },
      {
        class: "text-right",
        name: "Profit",
      },
      {
        class: "text-right",
        name: "Margin",
      },
    ],
  };

  const arrangeDataForTable = () => {
    let serviceList = [];
    thirdPartyServices.forEach((service) => {
      serviceList.push(service);
    });

    let price = thirdPartyServices
      .map((service) => service.attributes.price)
      .reduce((prev, curr) => prev + curr, 0);
    let recurringPrice = thirdPartyServices
      .map(
        (service) =>
          parseFloat(service.attributes["recurring-price"]) *
          parseFloat(service.attributes["mrr-terms"])
      )
      .reduce((prev, curr) => prev + curr, 0);
    let cost = thirdPartyServices
      .map((service) => parseFloat(service.attributes.cost))
      .reduce((prev, curr) => prev + curr, 0);
    let recurringCost = thirdPartyServices
      .map(
        (service) =>
          parseFloat(service.attributes["recurring-cost"]) *
          parseFloat(service.attributes["mrr-terms"])
      )
      .reduce((prev, curr) => prev + curr, 0);

    let totalRevenue = thirdPartyServices
      .map((service) => parseFloat(service.attributes["total-revenue"]))
      .reduce((prev, curr) => prev + curr, 0);

    let profit = thirdPartyServices
      .map((service) => parseFloat(service.attributes["profit"]))
      .reduce((prev, curr) => prev + curr, 0);

    let totalCost = thirdPartyServices
      .map((service) => parseFloat(service.attributes["total-cost"]))
      .reduce((prev, curr) => prev + curr, 0);

    let totalRow = {
      type: "totalRow",
      attributes: {
        price: price,
        "recurring-price": recurringPrice,
        cost: cost,
        "recurring-cost": recurringCost,
        "total-revenue": totalRevenue,
        profit: profit,
        "total-cost": totalCost,
      },
    };
    serviceList.push(totalRow);

    return serviceList;
  };

  const tableData = getTableData(arrangeDataForTable());
  const footerData = null;

  let thirdPartyPricingTable = new ScopeStackTable(
    "pricing",
    headerData,
    tableData,
    footerData
  );

  return (
    <div>
      <Card>
        <CardHeader title={"Third Party Services Summary"} />
        <Card.Body>
          <BaseTable
            className="scopestack thirdPartyPricingTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={thirdPartyPricingTable.buildHeaderRows()}
            dataRows={thirdPartyPricingTable.buildDataRows()}
            footerRows={thirdPartyPricingTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default MsPricing;
