import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import FormTextField from "@components/Forms/FormTextField";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import BackButton from "@components/Buttons/BackButton/BackButton";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import AutoCompleteText from "@components/Forms/AutoCompleteText/AutoCompleteText";
import {
  toSnakeCase,
  splitPascalCase,
  snakeToPascalCase,
  formatUnprocessibleResponse,
  formatDate,
} from "@utils/helperFunctions";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import { useNavigate } from "react-router-dom";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import FormEmailField from "@components/Forms/FormEmailField";
import FormPhoneField from "@components/Forms/FormPhoneField";
import ProjectVariables from "../../ProjectVariables/ProjectVariables";
import useUnloadWarning from "@utils/customHooks/useUnloadWarning";

function Contact({
  account_slug,
  project_id,
  projectStatus,
  contacts,
  contactId,
  account_id,
  setContactUpdated,
  setContactCreated,
  contactTypes,
  clientContacts,
  accountContacts,
  clientId,
  projVariables,
}) {
  const [name, setName] = useState("");
  const [title, setTitle] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [contactType, setContactType] = useState("Primary Customer Contact");
  const [newContactTypeId, setNewContactTypeId] = useState(null);

  const [errorMessages, setErrorMessages] = useState([]);

  // Project Variables
  const [showMinCharAlert, setShowMinCharAlert] = useState(false);
  const [showMaxCharAlert, setShowMaxCharAlert] = useState(false);
  const [minValue, setMinValue] = useState(false);
  const [maxValue, setMaxValue] = useState(false);
  const [unrequiredProjectVariables, setUnrequiredProjectVariables] = useState(
    []
  );
  const [requiredProjectVariables, setRequiredProjectVariables] = useState([]);

  const [contactList, setContactList] = useState([]);

  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);

  var chosenName = "";
  const [newContactId, setNewContactId] = useState(null);
  const [userTypedName, setUserTypedName] = useState(false);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  const [authorizationCode] = useOauth();
  let navigate = useNavigate();

  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  const today = new Date();
  const [isFormDirty, setFormDirty] = useState(false);
  useUnloadWarning(isFormDirty);

  useEffect(() => {
    // Project Variables
    if (projVariables.length !== 0 && lastWordInLocation == "new") {
      let reqProjectVariables = projVariables.filter((variable) => {
        if (variable.required === true) {
          return variable.required;
        }
      });
      reqProjectVariables = reqProjectVariables.map((variable) => {
        if (variable["variable-type"] === "date") {
          return { ...variable, value: formatDate(today) };
        } else {
          return { ...variable, value: "" };
        }
      });
      setRequiredProjectVariables(reqProjectVariables);
      let unreqProjectVariables = projVariables.filter((variable) => {
        if (variable.required === false) {
          return variable;
        }
      });
      unreqProjectVariables = unreqProjectVariables.map((variable) => {
        return { ...variable, value: "" };
      });
      setUnrequiredProjectVariables(unreqProjectVariables);
    }

    // If on edit, set state to render values in fields
    if (lastWordInLocation === "edit") {
      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].id === id) {
          setName(contacts[i].attributes.name);
          setEmail(contacts[i].attributes.email);
          let type = splitPascalCase(
            snakeToPascalCase(contacts[i].attributes["contact-type"])
          );
          setContactType(type);
          setTitle(contacts[i].attributes.title);
          setPhone(contacts[i].attributes.phone);

          // Project Variables
          let projVariables = contacts[i].attributes["project-variables"];
          if (projVariables.length !== 0) {
            let reqProjVariables = projVariables.filter((variable) => {
              if (variable.required === true) {
                return variable;
              }
            });
            setRequiredProjectVariables(reqProjVariables);
            let unreqProjVariables = projVariables.filter((variable) => {
              if (variable.required === false) {
                return variable;
              }
            });
            setUnrequiredProjectVariables(unreqProjVariables);
          }
        }
      }
    }
  }, [contacts, contactTypes, clientId, projVariables]);

  const contactTypeOptions = contactTypes.map((contactType) => {
    return (
      <option accessKey={contactType.id} key={contactType.id}>
        {contactType.attributes.name}
      </option>
    );
  });

  const getContactList = (contactName) => {
    if (contactType === null) {
      return [];
    }
    if (
      contactType === "Internal Contact" ||
      contactType === "Primary Internal Contact"
    ) {
      API.Get(
        `${apiHost}/${account_slug}/v1/contacts?filter[name]=${encodeURIComponent(
          contactName
        )}&page[size]=500`,
        authorizationCode
      ).then((res) => {
        let accountContacts = res.data.data;
        setContactList(accountContacts);
      });
    } else if (
      contactType === "Customer Contact" ||
      contactType === "Primary Customer Contact"
    ) {
      API.Get(
        `${apiHost}/${account_slug}/v1/clients/${clientId}/contacts?filter[name]=${encodeURIComponent(
          contactName
        )}&page[size]=500`,
        authorizationCode
      ).then((res) => {
        let clientContacts = res.data.data;
        setContactList(clientContacts);
      });
    }
  };

  const redirectToProjectContacts = () => {
    navigate(`/projects/${project_id}/project_contacts/`);
    window.location.reload();
  };

  const handleFormChange = (event) => {
    setFormDirty(true);
  };

  const validateVariables = (allProjectVariablesArray) => {
    var projectIsValid = true;
    var maximumValue = "";
    var minimumValue = "";

    for (let i = 0; i < allProjectVariablesArray.length; i++) {
      let variableType = "";
      if (lastWordInLocation === "new") {
        variableType = allProjectVariablesArray[i]["variable-type"];
      }
      if (lastWordInLocation !== "new") {
        variableType = allProjectVariablesArray[i]["variable_type"];
      }
      if (
        variableType === "text" &&
        allProjectVariablesArray[i]["select_options"].length === 0
      ) {
        minimumValue = allProjectVariablesArray[i].minimum;

        maximumValue = allProjectVariablesArray[i].maximum;

        let value = allProjectVariablesArray[i].value;
        if (value && minimumValue != null && value.length < minimumValue) {
          setMinValue(minimumValue);
          setShowMinCharAlert(true);
          projectIsValid = false;
        }
        if (value && maximumValue != null && value.length > maximumValue) {
          setMaxValue(maximumValue);
          setShowMaxCharAlert(true);
          projectIsValid = false;
        }
      }
    } // End for loop

    return projectIsValid;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setFormDirty(false);

    // Project Variable Validation
    const allProjectVariables = requiredProjectVariables.concat(
      unrequiredProjectVariables
    );
    const projectVariablesAreValid = validateVariables(allProjectVariables);
    if (projectVariablesAreValid === false) {
      return;
    }

    async function getContact() {
      // Check for existence of contact by name
      // in case user did not pick contact in typeahead
      if (newContactId === null) {
        return API.Get(
          `${apiHost}/${account_slug}/v1/contacts?filter[name]=${encodeURIComponent(
            name
          )}`,
          authorizationCode
        ).then((resp) => {
          let match = resp.data.data.filter(
            (item) => item.attributes.name === name
          );
          if (match && match.size > 0) {
            setNewContactId(match.id);
            return { data: { type: "contacts", id: match[0].id } };
          } else {
            return null;
          }
        });
      } else {
        return { data: { type: "contacts", id: newContactId } };
      }
    }

    const getUpdatedProjectVariables = () => {
      let newProjVariables = allProjectVariables.map((variable) => {
        return { name: variable.name, value: variable.value };
      });
      return newProjVariables;
    };

    const getContactType = () => {
      if (
        contactType === "Internal Contact" ||
        contactType === "Primary Internal Contact"
      ) {
        return "account";
      } else if (
        contactType === "Customer Contact" ||
        contactType === "Primary Customer Contact"
      ) {
        return "client";
      }
    };

    const attributes = {
      name: name,
      email: email,
      phone: phone,
      title: title,
      "contact-type": toSnakeCase(contactType),
      "project-variables": getUpdatedProjectVariables(),
    };

    const newContactData = {
      data: {
        type: "contacts",
        attributes: { name: name, email: email, phone: phone, title: title },
      },
    };

    if (lastWordInLocation === "edit" && authorizationCode !== "") {
      let contactInfo = await getContact();
      if (contactInfo) {
        setNewContactId(contactInfo.id);
      }

      const updatedContactData = {
        data: {
          type: "project-contacts",
          id: id,
          attributes: attributes,
          relationships: {
            project: { data: { type: "projects", id: project_id } },
            contact: contactInfo,
          },
        },
      };

      API.Patch(
        `${apiHost}/${account_slug}/v1/project-contacts/${id}`,
        updatedContactData,
        authorizationCode
      )
        .then((response) => {
          setContactUpdated(true);
          setUpdatedAlertIsOpen(true);
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "contact"));
          setShowFailAlert(true);
        });

      if (
        newContactId === null &&
        userTypedName === true &&
        getContactType() === "account"
      ) {
        API.Post(
          `${apiHost}/${account_slug}/v1/contacts`,
          newContactData,
          authorizationCode
        )
          .then((response) => {
            if (response.status === 201) {
              let contactId = response.data.data.id;
              let contact = {
                data: {
                  type: "project-contacts",
                  id: id,
                  attributes: attributes,
                  relationships: {
                    project: { data: { type: "projects", id: project_id } },
                    contact: { data: { type: "contacts", id: contactId } },
                  },
                },
              };
              API.Patch(
                `${apiHost}/${account_slug}/v1/project-contacts/${id}`,
                contact,
                authorizationCode
              ).then((response) => {
                setContactUpdated(true);
                setUpdatedAlertIsOpen(true);
                setTimeout(() => {
                  setUpdatedAlertIsOpen(false);
                }, 3000);
              });
            }
          })
          .catch((err) => {
            setErrorMessages(formatUnprocessibleResponse(err, "contact"));
            setShowFailAlert(true);
          });
      }

      if (
        newContactId === null &&
        userTypedName === true &&
        getContactType() === "client"
      ) {
        API.Post(
          `${apiHost}/${account_slug}/v1/clients/${clientId}/contacts`,
          newContactData,
          authorizationCode
        )
          .then((response) => {
            if (response.status === 201) {
              let contactId = response.data.data.id;
              let contact = {
                data: {
                  type: "project-contacts",
                  id: id,
                  attributes: attributes,
                  relationships: {
                    project: { data: { type: "projects", id: project_id } },
                    contact: { data: { type: "contacts", id: contactId } },
                  },
                },
              };
              API.Patch(
                `${apiHost}/${account_slug}/v1/project-contacts/${id}`,
                contact,
                authorizationCode
              ).then((response) => {
                setContactUpdated(true);
                setUpdatedAlertIsOpen(true);
                setTimeout(() => {
                  setUpdatedAlertIsOpen(false);
                }, 3000);
              });
            }
          })
          .catch((err) => {
            setErrorMessages(formatUnprocessibleResponse(err, "contact"));
            setShowFailAlert(true);
          });
      }
    }

    if (lastWordInLocation === "new" && authorizationCode !== "") {
      let contactInfo = await getContact();
      if (contactInfo) {
        setNewContactId(contactInfo.id);
      }

      const newProjectContactData = {
        data: {
          type: "project-contacts",
          attributes: attributes,
          relationships: {
            project: { data: { type: "projects", id: project_id } },
            contact: contactInfo,
          },
        },
      };

      if (newContactId !== null || contactInfo !== null) {
        API.Post(
          `${apiHost}/${account_slug}/v1/project-contacts`,
          newProjectContactData,
          authorizationCode
        )
          .then((response) => {
            setContactCreated(true);
            redirectToProjectContacts();
          })
          .catch((err) => {
            setErrorMessages(formatUnprocessibleResponse(err, "contact"));
            setShowFailAlert(true);
          });
      }

      if (
        newContactId === null &&
        userTypedName === true &&
        getContactType() === "account"
      ) {
        API.Post(
          `${apiHost}/${account_slug}/v1/contacts`,
          newContactData,
          authorizationCode
        )
          .then((response) => {
            if (response.status === 201) {
              let contactId = response.data.data.id;
              let contact = {
                data: {
                  type: "project-contacts",
                  attributes: attributes,
                  relationships: {
                    project: { data: { type: "projects", id: project_id } },
                    contact: { data: { type: "contacts", id: contactId } },
                  },
                },
              };
              API.Post(
                `${apiHost}/${account_slug}/v1/project-contacts`,
                contact,
                authorizationCode
              ).then((response) => {
                if (response.status == 201) {
                  setContactCreated(true);
                  redirectToProjectContacts();
                }
              });
            }
          })
          .catch((err) => {
            setErrorMessages(formatUnprocessibleResponse(err, "contact"));
            setShowFailAlert(true);
          });
      }

      if (
        newContactId === null &&
        userTypedName === true &&
        getContactType() === "client"
      ) {
        API.Post(
          `${apiHost}/${account_slug}/v1/clients/${clientId}/contacts`,
          newContactData,
          authorizationCode
        )
          .then((response) => {
            if (response.status === 201) {
              let contactId = response.data.data.id;
              let contact = {
                data: {
                  type: "project-contacts",
                  attributes: attributes,
                  relationships: {
                    project: { data: { type: "projects", id: project_id } },
                    contact: { data: { type: "contacts", id: contactId } },
                  },
                },
              };
              API.Post(
                `${apiHost}/${account_slug}/v1/project-contacts`,
                contact,
                authorizationCode
              ).then((response) => {
                if (response.status == 201) {
                  setContactCreated(true);
                  redirectToProjectContacts();
                }
              });
            }
          })
          .catch((err) => {
            setErrorMessages(formatUnprocessibleResponse(err, "contact"));
            setShowFailAlert(true);
          });
      }
    }
  }

  const detailsUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="contactAlerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Contact details updated"
        />
      );
    }
  };

  // Project Variables
  const handleCharacterAlert = () => {
    if (showMinCharAlert === true) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setShowMinCharAlert(false)}
          text={`Required text is too short (minimum is ${minValue} characters)`}
        />
      );
    } else if (showMaxCharAlert === true) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setShowMaxCharAlert(false)}
          text={`Required text is too long (maximum is ${maxValue} characters)`}
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {detailsUpdatedAlert()}
      {handleFailAlert()}
      {handleCharacterAlert()}
      <Card>
        <CardHeader title="Contact" />
        <Card.Body>
          <Form onChange={handleFormChange} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col xs={6}></Col>
              <Col xs={6}>
                <ControlledDropDown
                  label="Contact Type"
                  value={contactType}
                  options={contactTypeOptions}
                  onChange={(e) => {
                    setContactType(e.target.value);
                    let selected = e.target.options.selectedIndex;
                    setNewContactTypeId(e.target.options[selected].accessKey);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <AutoCompleteText
                  label="Name *"
                  placeholder="John Smith"
                  value={name}
                  required={true}
                  suggestionList={contactList.map(
                    (contact) => contact.attributes.name
                  )}
                  monitorValue={(value) => {
                    chosenName = value;
                    getContactList(value);
                    setName(chosenName);
                    for (let i = 0; i < contactList.length; i++) {
                      if (contactList[i].attributes.name === chosenName) {
                        setNewContactId(contactList[i].id);
                        setEmail(contactList[i].attributes.email);
                        setTitle(contactList[i].attributes.title);
                        setPhone(contactList[i].attributes.phone);
                      }
                    }
                  }}
                  setTextChanged={(bool) => {
                    setUserTypedName(bool);
                  }}
                />
              </Col>
              <Col xs={6}>
                <FormTextField
                  label="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <FormEmailField
                  label="Email"
                  placeholder="john.smith@customer.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
              <Col xs={6}>
                <FormPhoneField
                  label="Phone"
                  placeholder="2125553456"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <ProjectVariables
                arr={requiredProjectVariables}
                account_slug={account_slug}
                project_id={project_id}
                setState={setRequiredProjectVariables}
                setVariableChanged={null}
              />
              <ProjectVariables
                arr={unrequiredProjectVariables}
                account_slug={account_slug}
                project_id={project_id}
                setState={setUnrequiredProjectVariables}
                setVariableChanged={null}
              />
            </Row>
            <Row>
              <Col xs={6}>
                <SubmitButton />
              </Col>
              <Col xs={6}>
                <BackButton url={`/projects/${project_id}/project_contacts`} />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Contact;
