import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProjectGovernance from "./ProjectGovernance/ProjectGovernance";
import NewGovernance from "./Governance/Governance";
import EditGovernance from "./EditGovernance/EditGovernance";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import { capitalizeFirstLetter } from "@utils/helperFunctions";

function GovernanceMspa({
  account_slug,
  project_id,
  account_id,
  insight_lob_filtering,
}) {
  const insightLobFiltering = insight_lob_filtering;

  const [governanceId, setGovernanceId] = useState(null);

  // Set the State
  const [projectStatus, setProjectStatus] = useState("");
  const [governances, setGovernances] = useState([]);
  const [resources, setResources] = useState([]);
  const [lobs, setLobs] = useState([]);
  const [projectResources, setProjectResources] = useState([]);
  const [allocationMethods, setAllocationMethods] = useState([]);
  const [phases, setPhases] = useState([]);
  const [calculationTypes, setCalculationTypes] = useState([]);
  const [totalEffort, setTotalEffort] = useState("");
  const [authorizationCode] = useOauth();
  const [governanceCreated, setGovernanceCreated] = useState(false);
  const [governanceUpdated, setGovernanceUpdated] = useState(false);
  const [isResourcesFromRates, setIsResourcesFromRates] = useState(false);
  const [rateTableId, setRateTableId] = useState(null);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);
  const [rateType, setRateType] = useState("Hours");

  // When page loads, call API to get Governance Info and lift it up to the state
  useEffect(() => {
    if (authorizationCode != "") {
      // Get Project Phases & Resources from account
      API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
        (response) => {
          let data = response.data.data;

          // Get Project Info
          API.Get(
            `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-phases,project-governances,project-governances.resource,project-governances.project-phase,project-governances.project-resource,project-governances.service-category,rate-table,project-services,project-services.service-category`,
            authorizationCode
          ).then((projectResponse) => {
            const projectData = projectResponse.data.data;
            const rateTableId = projectData.relationships["rate-table"].data.id;
            setRateTableId(rateTableId);
            const status = projectData.attributes.status;
            const effort = projectData.attributes["total-effort"];
            const paymentInfo = projectData.attributes["payment-info"];
            let rateType =
              paymentInfo["rate-type"] !== "hours"
                ? capitalizeFirstLetter(paymentInfo["rate-type"]) + "s"
                : capitalizeFirstLetter(paymentInfo["rate-type"]);
            if (status !== null) setProjectStatus(status);
            if (effort !== null) setTotalEffort(effort);
            setRateType(rateType);

            let phases = projectResponse.data.included.filter(
              (item) => item.type == "project-phases"
            );
            setPhases(phases);

            let governances = projectResponse.data.included.filter(
              (item) =>
                item.attributes.active && item.type == "project-governances"
            );
            setGovernances(governances);

            const serviceCategoryIds = projectResponse.data.included
              .filter((item) => item.type == "service-categories")
              .map((serviceCatgeory) => serviceCatgeory.id);

            // Get Project Resources
            let projectResourcesUrl =
              projectData.relationships["project-resources"].links.related +
              "?include=line-of-business";
            if (insightLobFiltering) {
              projectResourcesUrl =
                projectResourcesUrl +
                `&filter[governed-categories]=${serviceCategoryIds.join(",")}`;
            }
            API.Get(projectResourcesUrl, authorizationCode).then((response) => {
              let resources = response.data.data;
              setProjectResources(resources);
              setIsLoading(false);
            });

            if (!data.attributes.settings["project_resource_rates"]) {
              let resourcesUrl = `${apiHost}/${account_slug}/v1/resources?page[size]=1000`;
              // Get resources
              API.Get(resourcesUrl, authorizationCode).then(
                (resourceResponse) => {
                  let resources = resourceResponse.data.data.filter(
                    (resource) => resource.attributes.active
                  );
                  setResources(resources);
                }
              );
            } else {
              setIsResourcesFromRates(true);
              // Get Resource Rates
              let resourceRatesUrl = `${apiHost}/${account_slug}/v1/resource-rates?include=resource,rate-table,line-of-business&filter[rate-table]=${rateTableId}&page[size]=1000`;
              if (insightLobFiltering) {
                resourceRatesUrl =
                  resourceRatesUrl +
                  `&filter[governed-categories]=${serviceCategoryIds.join(
                    ","
                  )}`;
              }
              API.Get(resourceRatesUrl, authorizationCode).then(
                (resRateResponse) => {
                  let resources = resRateResponse.data.included.filter(
                    (item) => item.type == "resources" && item.attributes.active
                  );
                  let allResourceRates = resRateResponse.data.data;
                  let resourceRatesToReturn = new Set();
                  allResourceRates.forEach((resourceRate) => {
                    resources.forEach((resource) => {
                      if (
                        resource.id ==
                        resourceRate.relationships.resource.data.id
                      ) {
                        resourceRatesToReturn.add(resourceRate);
                      }
                    });
                  });

                  setResources(Array.from(resourceRatesToReturn));
                }
              );
            }
          });
        }
      );

      // Get Service Categories
      API.Get(
        `${apiHost}/${account_slug}/v1/service-categories?page[size]=250`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        if (data !== null) {
          let lobs = data
            .filter((item) => !item.attributes["has-parent?"])
            .sort((a, b) => a.attributes.position - b.attributes.position);

          lobs = lobs.map((item) => {
            let children = data
              .filter((child) => child.attributes["lob-id"] == item.id)
              .sort((a, b) => a.attributes.position - b.attributes.position);
            item.categories = children;
            return item;
          });

          setLobs(lobs);
        }
      });

      // Get Allocation Methods
      API.Get(
        `${apiHost}/${account_slug}/v1/governance-allocation-methods`,
        authorizationCode
      ).then((response) => {
        if (response.data.data != null)
          setAllocationMethods(response.data.data);
      });

      // Get Calculation Types
      API.Get(
        `${apiHost}/${account_slug}/v1/governance-calculation-types`,
        authorizationCode
      ).then((response) => {
        if (response.data.data != null) setCalculationTypes(response.data.data);
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/project_governances"}
            element={
              <ProjectGovernance
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                governanceId={governanceId}
                setGovernanceId={(govId) => setGovernanceId(govId)}
                projectStatus={projectStatus}
                governances={governances}
                resources={resources}
                projectResources={projectResources}
                totalEffort={totalEffort}
                phases={phases}
                governanceCreated={governanceCreated}
                governanceUpdated={governanceUpdated}
                isLoading={isLoading}
                rateType={rateType}
                authorizationCode={authorizationCode}
                lobs={lobs}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_governances/new"}
            element={
              <NewGovernance
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                governances={governances}
                resources={resources}
                projectResources={projectResources}
                totalEffort={totalEffort}
                allocationMethods={allocationMethods}
                calculationTypes={calculationTypes}
                phases={phases}
                setGovernanceCreated={(created) =>
                  setGovernanceCreated(created)
                }
                setGovernanceUpdated={(updated) =>
                  setGovernanceUpdated(updated)
                }
                rateType={rateType}
                authorizationCode={authorizationCode}
                lobs={lobs}
                isResourcesFromRates={isResourcesFromRates}
                rateTableId={rateTableId}
              />
            }
          />
          <Route
            path={
              "/projects/:project_id/project_governances/:governanceId/edit"
            }
            element={
              <EditGovernance
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                governances={governances}
                resources={resources}
                projectResources={projectResources}
                totalEffort={totalEffort}
                governanceId={governanceId}
                allocationMethods={allocationMethods}
                calculationTypes={calculationTypes}
                phases={phases}
                setGovernanceCreated={(created) =>
                  setGovernanceCreated(created)
                }
                setGovernanceUpdated={(updated) =>
                  setGovernanceUpdated(updated)
                }
                rateType={rateType}
                authorizationCode={authorizationCode}
                lobs={lobs}
                isResourcesFromRates={isResourcesFromRates}
                rateTableId={rateTableId}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default GovernanceMspa;
