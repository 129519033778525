import React, { useState, useEffect } from "react";
import OnboardingCards from "../reusable/OnboardingCards";
import { useNavigate } from "react-router";
import logisticsMonitorWifi from "../images/logisticsMonitorWifi.png";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import {
  faCheck,
  faX,
  faPencil,
  faTrashCan,
  faGripDotsVertical,
} from "@fortawesome/pro-solid-svg-icons";
import API from "@utils/API/API";
import {
  formatUnprocessibleResponse,
  handleAlert,
} from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CurrencyFormat from "react-currency-format";
import FormNumberField from "@components/Forms/FormNumberField";

function Resources({
  resourceRates,
  isLoading,
  account_slug,
  account_id,
  authorizationCode,
  currencyUnit,
  resources,
  rateTableId,
  setRerender,
}) {
  let navigate = useNavigate();
  const [resourceRatesToDisplay, setResourceRatesToDisplay] = useState([]);
  const [errorMessages, setErrorMessages] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showResourceRateConfirmation, setShowResourceRateConfirmation] =
    useState(false);
  const [resourceRateToDelete, setResourceRateToDelete] = useState({});
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  useEffect(() => {
    let newResourceRates = [...resourceRates].map((resourceRate) => {
      resourceRate.userIsEditing = false;
      resourceRate.resourceRateNameChanged = false;
      resourceRate.detailsChanged = false;
      return resourceRate;
    });
    setResourceRatesToDisplay(newResourceRates);
  }, [resourceRates, isLoading, rateTableId]);

  useEffect(() => {
    if (!authorizationCode) return;

    API.Get(`${apiHost}/v1/me`, authorizationCode).then((response) => {
      API.Patch(
        `${apiHost}/${account_slug}/v1/users/${response.data.data.id}`,
        {
          data: {
            id: response.data.data.id,
            type: "users",
            attributes: {
              "guided-onboarding": {
                onboarding_status: "resources",
              },
            },
          },
        },
        authorizationCode
      ).then((response) => {
        console.log(response);
      });
    });
  }, [authorizationCode]);

  window.onpopstate = function (e) {
    setRerender(true);
  };

  const resourceRateText = (
    <div>
      <p>
        ScopeStack calculates the revenue and cost for most{" "}
        <strong>Services</strong> by multiplying the hourly rate of a{" "}
        <strong>Resource</strong> by the level of effort for that service. 
      </p>
      <p>
        You can think of a Resource as the level of skill or knowledge required
        to perform the action or task. Below are some sample rates included with
        your account.
      </p>
    </div>
  );

  const addResourceRate = () => {
    let newResourceRate = {
      id: `newResourceRate-${Math.floor(Math.random() * 100)}`,
      userIsEditing: true,
      resourceRateNameChanged: false,
      detailsChanged: false,
      attributes: {
        name: "",
        "hourly-rate": "",
        "hourly-cost": "",
      },
    };
    if (
      resourceRatesToDisplay.filter((resRate) => resRate.userIsEditing)
        .length == 0
    ) {
      let newResourceRates = [...resourceRatesToDisplay];
      newResourceRates.splice(0, 0, newResourceRate);
      setResourceRatesToDisplay(newResourceRates);
    }
  };

  const matchRateToResource = (resourceRate) => {
    let resourceToReturn = [];
    resources.forEach((resource) => {
      if (resource.id == resourceRate.relationships.resource.data.id) {
        resourceToReturn.push(resource);
      }
    });
    return resourceToReturn[0];
  };

  const deleteResourceRate = (resourceRateToDelete) => {
    setShowResourceRateConfirmation(false);
    if (resourceRateToDelete) {
      if (resourceRateToDelete.id.includes("newResourceRate")) {
        setResourceRatesToDisplay(
          resourceRatesToDisplay.filter(
            (resourceRate) => resourceRate.id !== resourceRateToDelete.id
          )
        );
        return;
      } else if (resourceRatesToDisplay.length > 1) {
        let resourceRateData = {
          data: {
            type: "resource-rates",
            id: resourceRateToDelete.id,
          },
        };
        let resourceData = {
          data: {
            type: "resources",
            id: resourceRateToDelete.relationships.resource.data.id,
          },
        };
        API.Delete(
          `${apiHost}/${account_slug}/v1/resource-rates/${resourceRateToDelete.id}`,
          resourceRateData,
          authorizationCode
        )
          .then((res) => {
            setResourceRatesToDisplay(
              resourceRatesToDisplay.filter(
                (resourceRate) => resourceRate.id !== resourceRateToDelete.id
              )
            );
          })
          .catch((err) => {
            setErrorMessages(formatUnprocessibleResponse(err, "resource"));
            setShowFailAlert(true);
          });
        API.Delete(
          `${apiHost}/${account_slug}/v1/resources/${resourceRateToDelete.relationships.resource.data.id}`,
          resourceData,
          authorizationCode
        )
          .then((res) => {
            setRerender(true);
          })
          .catch((err) => {
            setErrorMessages(formatUnprocessibleResponse(err, "resource"));
            setShowFailAlert(true);
          });
      } else if (resourceRatesToDisplay.length == 1) {
        setErrorMessages(
          "You must have at least one resource to be able to create services."
        );
        setShowFailAlert(true);
      }
    }
  };

  const submitResourceRate = (resourceRate) => {
    const submit = (submitMethod, url, submissionData, authorizationCode) => {
      API.Submit(submitMethod, url, submissionData, authorizationCode)
        .then((res) => {
          setResourceRatesToDisplay(
            resourceRatesToDisplay.map((resRate) => {
              resRate.userIsEditing = false;
              resRate.resourceRateNameChanged = false;
              resRate.detailsChanged = false;
              if (resourceRate.id.includes("newResourceRate")) {
                resRate.id = res.data.data.id;
                resRate.attributes.name = res.data.data.attributes.name;
                resRate.relationships = {
                  ...submissionData.data.relationships,
                };
                resRate.attributes["hourly-cost"] =
                  res.data.data.attributes["hourly-cost"];
                resRate.attributes["hourly-rate"] =
                  res.data.data.attributes["hourly-rate"];
              }
              if (
                resRate.id == res.data.data.id ||
                res.data.data.id == resRate.relationships.resource.data.id
              ) {
                resRate.attributes.name = res.data.data.attributes.name;
                if (res.data.data.type !== "resources") {
                  resRate.attributes["hourly-cost"] =
                    res.data.data.attributes["hourly-cost"];
                  resRate.attributes["hourly-rate"] =
                    res.data.data.attributes["hourly-rate"];
                }
              }
              return resRate;
            })
          );
          setRerender(true);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "resource"));
          setShowFailAlert(true);
        });
    };

    if (
      !resourceRate.id.includes("newResourceRate") &&
      resourceRate.resourceRateNameChanged
    ) {
      let resourceId = matchRateToResource(resourceRate)
        ? matchRateToResource(resourceRate).id
        : resourceRate.relationships.resource.data.id;
      let resourcePatchData = {
        data: {
          id: resourceId,
          type: "resources",
          attributes: {
            name: resourceRate.newResourceRateName
              ? resourceRate.newResourceRateName
              : resourceRate.attributes.name,
          },
        },
      };
      submit(
        "PATCH",
        `${apiHost}/${account_slug}/v1/resources/${resourceId}`,
        resourcePatchData,
        authorizationCode
      );
    }
    if (
      !resourceRate.id.includes("newResourceRate") &&
      resourceRate.detailsChanged
    ) {
      let resRatePatchData = {
        data: {
          type: "resource-rates",
          id: resourceRate.id,
          attributes: {
            "hourly-rate": resourceRate.newRate
              ? resourceRate.newRate
              : resourceRate.attributes["hourly-rate"],
            "hourly-cost": resourceRate.newCost
              ? resourceRate.newCost
              : resourceRate.attributes["hourly-cost"],
          },
          relationships: {
            "rate-table": {
              data: {
                type: "rate-tables",
                id: rateTableId,
              },
            },
            resource: {
              data: {
                type: "resources",
                id: matchRateToResource(resourceRate)
                  ? matchRateToResource(resourceRate).id
                  : resourceRate.relationships.resource.data.id,
              },
            },
          },
        },
      };
      submit(
        "PATCH",
        `${apiHost}/${account_slug}/v1/resource-rates/${resourceRate.id}`,
        resRatePatchData,
        authorizationCode
      );
    }
    if (resourceRate.id.includes("newResourceRate")) {
      let resourcePostData = {
        data: {
          type: "resources",
          attributes: {
            name: resourceRate.newResourceRateName
              ? resourceRate.newResourceRateName
              : resourceRate.attributes.name,
          },
        },
      };
      API.Submit(
        "POST",
        `${apiHost}/${account_slug}/v1/resources`,
        resourcePostData,
        authorizationCode
      ).then((res) => {
        let newResourceId = res.data.data.id;
        API.Get(
          `${apiHost}/${account_slug}/v1/resource-rates?filter[resource]=${newResourceId}&filter[rate_table]=${rateTableId}`,
          authorizationCode
        ).then((resourceRatesRes) => {
          let resRate = resourceRatesRes.data.data[0];
          let resRateData = {
            data: {
              type: "resource-rates",
              id: resRate.id,
              attributes: {
                "hourly-rate": resourceRate.newRate
                  ? resourceRate.newRate
                  : resourceRate.attributes["hourly-rate"],
                "hourly-cost": resourceRate.newCost
                  ? resourceRate.newCost
                  : resourceRate.attributes["hourly-cost"],
              },
              relationships: {
                "rate-table": {
                  data: {
                    type: "rate-tables",
                    id: rateTableId,
                  },
                },
                resource: {
                  data: {
                    type: "resources",
                    id: newResourceId,
                  },
                },
              },
            },
          };
          submit(
            "PATCH",
            `${apiHost}/${account_slug}/v1/resource-rates/${resRate.id}`,
            resRateData,
            authorizationCode
          );
        });
      });
    }
  };

  const dragResourceRateEnd = (result) => {
    let isValid = validateDrop(result);
    if (!isValid) return;
    const { source, destination } = result;
    const copyListItems = [...resourceRatesToDisplay];
    const dragItemContent = copyListItems[source.index];
    if (destination.droppableId !== "ResourceRateDropZone") return;
    copyListItems.splice(source.index, 1);
    copyListItems.splice(destination.index, 0, dragItemContent);
    let newResourceRates = resourceRatesToDisplay.filter(
      (o1) => !copyListItems.some((o2) => o1.id === o2.id)
    );
    setResourceRatesToDisplay(newResourceRates.concat(copyListItems));
    let resourceRateToUpdate = dragItemContent;
    resourceRateToUpdate.type = "resource-rates";
    delete resourceRateToUpdate.userIsEditing;
    delete resourceRateToUpdate.resourceRateNameChanged;
    delete resourceRateToUpdate.detailsChanged;
    delete resourceRateToUpdate.newResourceRateName;
    delete resourceRateToUpdate.links;
    delete resourceRateToUpdate.newCost;
    delete resourceRateToUpdate.newRate;
    resourceRateToUpdate.attributes = {
      name: dragItemContent.attributes.name,
      position:
        destination.index == 0 ? 1 : copyListItems.indexOf(dragItemContent) + 1,
    };
    resourceRateToUpdate.relationships = {
      account: { data: { id: account_id, type: "accounts" } },
    };
    API.Patch(
      `${apiHost}/${account_slug}/v1/resource-rates/${resourceRateToUpdate.id}/move-to`,
      { data: resourceRateToUpdate },
      authorizationCode
    ).catch((err) => {
      setErrorMessages(formatUnprocessibleResponse(err, "resource"));
      setShowFailAlert(true);
    });
  };

  const getResourceRates = () => {
    if (isLoading) {
      return <SmallSpinner />;
    } else {
      return (
        <>
          <Row
            style={{
              marginLeft: "1px",
              display: "flex",
              alignItems: "center",
            }}
            className="cursorPoint whiteBar"
            onClick={() => addResourceRate()}
          >
            <Col style={{ paddingLeft: "5px" }}>
              <strong
                style={
                  resourceRatesToDisplay.filter(
                    (resRate) => resRate.userIsEditing
                  ).length == 0
                    ? { color: "#418172" }
                    : { color: "darkgray" }
                }
              >
                Add a resource +
              </strong>
            </Col>
          </Row>
          <DragDropContext onDragEnd={dragResourceRateEnd}>
            <Droppable droppableId="ResourceRateDropZone">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {provided.placeholder}
                  {resourceRatesToDisplay.map((resourceRate, index) => (
                    <Draggable
                      key={resourceRate.id}
                      draggableId={resourceRate.id}
                      index={index}
                      isDragDisabled={true}
                    >
                      {(provided) => (
                        <div
                          key={resourceRate.id}
                          id={resourceRate.id}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {provided.placeholder}
                          <Row
                            key={resourceRate.id}
                            style={
                              resourceRate.userIsEditing
                                ? {
                                    padding: "4px 10px 4px 4px",
                                    marginLeft: "1px",
                                    display: "flex",
                                    alignItems: "center",
                                    height: "140px",
                                  }
                                : {
                                    marginLeft: "1px",
                                    display: "flex",
                                    alignItems: "center",
                                    height: "64px",
                                  }
                            }
                            className={"whiteBar"}
                          >
                            <Col style={{ paddingLeft: "5px" }}>
                              {resourceRate.userIsEditing ? (
                                <Form>
                                  <Form.Control
                                    type="text"
                                    value={
                                      resourceRate.resourceRateNameChanged
                                        ? resourceRate.newResourceRateName
                                        : resourceRate.attributes.name
                                    }
                                    placeholder={"Enter resource name"}
                                    onChange={(e) => {
                                      resourceRate.newResourceRateName =
                                        e.target.value;
                                      resourceRate.resourceRateNameChanged = true;
                                      setResourceRatesToDisplay(
                                        resourceRatesToDisplay.map(
                                          (resRate) => {
                                            if (resRate.id == resourceRate.id) {
                                              resRate.newResourceRateName =
                                                e.target.value;
                                              resRate.resourceRateNameChanged = true;
                                            }
                                            return resRate;
                                          }
                                        )
                                      );
                                    }}
                                    style={{
                                      marginBottom: "10px",
                                      marginTop: "10px",
                                    }}
                                  />
                                  <Row>
                                    <Col>
                                      <FormNumberField
                                        label="Hourly Rate"
                                        value={
                                          resourceRate.newRate !== undefined
                                            ? resourceRate.newRate
                                            : resourceRate.attributes[
                                                "hourly-rate"
                                              ]
                                        }
                                        placeholder={"Enter hourly rate"}
                                        onChange={(e) => {
                                          resourceRate.newRate = e.target.value;
                                          resourceRate.detailsChanged = true;
                                          setResourceRatesToDisplay(
                                            resourceRatesToDisplay.map(
                                              (resRate) => {
                                                if (
                                                  resRate.id == resourceRate.id
                                                ) {
                                                  resRate.newRate =
                                                    e.target.value;
                                                  resRate.detailsChanged = true;
                                                }
                                                return resRate;
                                              }
                                            )
                                          );
                                        }}
                                        step="0.01"
                                      />{" "}
                                    </Col>
                                    <Col>
                                      <FormNumberField
                                        label="Hourly Cost"
                                        value={
                                          resourceRate.newCost !== undefined
                                            ? resourceRate.newCost
                                            : resourceRate.attributes[
                                                "hourly-cost"
                                              ]
                                        }
                                        placeholder={"Enter hourly cost"}
                                        onChange={(e) => {
                                          resourceRate.newCost = e.target.value;
                                          resourceRate.detailsChanged = true;
                                          setResourceRatesToDisplay(
                                            resourceRatesToDisplay.map(
                                              (resRate) => {
                                                if (
                                                  resRate.id == resourceRate.id
                                                ) {
                                                  resRate.newCost =
                                                    e.target.value;
                                                  resRate.detailsChanged = true;
                                                }
                                                return resRate;
                                              }
                                            )
                                          );
                                        }}
                                        step="0.01"
                                      />{" "}
                                    </Col>
                                  </Row>
                                </Form>
                              ) : (
                                <div>
                                  <span
                                    style={{
                                      display: "flex",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {/* <FontAwesomeIcon
                                    className="gripIcon"
                                    icon={faGripDotsVertical}
                                  /> */}
                                    <strong>
                                      {resourceRate.attributes.name}
                                    </strong>
                                  </span>

                                  <Row
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Col className="resourceDetails">
                                      Hourly Rate:&nbsp;&nbsp;
                                      <CurrencyFormat
                                        displayType="text"
                                        prefix={currencyUnit}
                                        isNumericString={false}
                                        thousandSeparator={true}
                                        value={parseFloat(
                                          resourceRate.attributes["hourly-rate"]
                                        ).toFixed(2)}
                                      />
                                    </Col>
                                    <Col
                                      style={{ marginLeft: "-170px" }}
                                      className="resourceDetails"
                                    >
                                      Hourly Cost:&nbsp;&nbsp;
                                      <CurrencyFormat
                                        displayType="text"
                                        prefix={currencyUnit}
                                        isNumericString={false}
                                        thousandSeparator={true}
                                        value={parseFloat(
                                          resourceRate.attributes["hourly-cost"]
                                        ).toFixed(2)}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </Col>

                            <div
                              className="flexCenter"
                              style={
                                resourceRate.userIsEditing
                                  ? { marginTop: "-85px" }
                                  : { marginTop: "-20px" }
                              }
                            >
                              {!resourceRate.userIsEditing &&
                              resourceRatesToDisplay.filter(
                                (resRate) => resRate.userIsEditing
                              ).length > 0 ? null : (
                                <FontAwesomeIcon
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  icon={
                                    resourceRate.userIsEditing
                                      ? faCheck
                                      : faPencil
                                  }
                                  onClick={() => {
                                    if (
                                      !resourceRate.userIsEditing &&
                                      resourceRatesToDisplay.filter(
                                        (resRate) => resRate.userIsEditing
                                      ).length == 0
                                    ) {
                                      resourceRate.userIsEditing = true;
                                      setResourceRatesToDisplay(
                                        resourceRatesToDisplay.map(
                                          (resRate) => {
                                            if (
                                              resRate.id == resourceRate.id &&
                                              !resourceRate.userIsEditing
                                            ) {
                                              resRate.userIsEditing = true;
                                            }
                                            return resRate;
                                          }
                                        )
                                      );
                                    }
                                    if (
                                      resourceRate.userIsEditing &&
                                      (resourceRate.resourceRateNameChanged ||
                                        resourceRate.detailsChanged)
                                    ) {
                                      submitResourceRate(resourceRate);
                                    } else if (
                                      resourceRate.userIsEditing &&
                                      resourceRate.attributes.name ==
                                        "Enter resource name"
                                    ) {
                                      deleteResourceRate(resourceRate);
                                    }
                                  }}
                                />
                              )}
                              {!resourceRate.userIsEditing &&
                              resourceRatesToDisplay.filter(
                                (resRate) => resRate.userIsEditing
                              ).length > 0 ? null : (
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer" }}
                                  icon={
                                    resourceRate.userIsEditing
                                      ? faX
                                      : faTrashCan
                                  }
                                  onClick={() => {
                                    if (
                                      resourceRate.id.includes(
                                        "newResourceRate"
                                      )
                                    ) {
                                      deleteResourceRate(resourceRate);
                                    }
                                    if (
                                      resourceRate.userIsEditing &&
                                      !resourceRate.id.includes(
                                        "newResourceRate"
                                      )
                                    ) {
                                      setResourceRatesToDisplay(
                                        resourceRatesToDisplay.map(
                                          (resRate) => {
                                            if (resRate.id == resourceRate.id) {
                                              resRate.userIsEditing = false;
                                              resRate.resourceRateNameChanged = false;
                                              resRate.detailsChanged = false;
                                              delete resRate.newResourceRateName;
                                              delete resRate.newCost;
                                              delete resRate.newRate;
                                            }
                                            return resRate;
                                          }
                                        )
                                      );
                                    } else if (
                                      !resourceRate.userIsEditing &&
                                      !resourceRate.id.includes(
                                        "newResourceRate"
                                      )
                                    ) {
                                      setResourceRateToDelete(resourceRate);
                                      setShowResourceRateConfirmation(true);
                                    }
                                  }}
                                />
                              )}
                            </div>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      );
    }
  };

  return (
    <>
      <ConfirmationModal
        show={showResourceRateConfirmation}
        title="Delete Resource"
        message="Are you sure you want to delete this resource?"
        onConfirm={() => deleteResourceRate(resourceRateToDelete)}
        onCancel={() => {
          setResourceRateToDelete({});
          setShowResourceRateConfirmation(false);
        }}
      />
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <OnboardingCards
        contentCardHeader={"Let’s tailor your account to the way you work"}
        leftContent={
          <>
            {resourceRateText}
            {getResourceRates()}
          </>
        }
        rightContent={
          <div>
            <img
              src={logisticsMonitorWifi}
              alt="caricature of person icon on a monitor screen, yellow wifi symbol, yellow servers and green money icon"
              style={{ width: "100%" }}
            />
          </div>
        }
        rightButtonText={"Continue"}
        rightButtonOnClick={() => {
          setRerender(true);
          navigate("/onboarding/governances");
        }}
        disabledRightButton={
          resourceRatesToDisplay.filter((resRate) => resRate.userIsEditing)
            .length !== 0
        }
        skipSetupButton={true}
      />
    </>
  );
}

export default Resources;
