import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Form } from "react-bootstrap";
import "./PlanningDetails.css";
import "../ResourcePlans/ResourcePlans.css";
import ScopeStackPieChart from "@components/ScopeStackPieChart/ScopeStackPieChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faArrowAltCircleLeft,
} from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import FormTextField from "@components/Forms/FormTextField";
import FormNumberField from "@components/Forms/FormNumberField";
import FormDateField from "@components/Forms/FormDateField";
import API from "@API";
import {
  formatUnprocessibleResponse,
  getDate,
  renderResourceName,
} from "@utils/helperFunctions";
import { useNavigate } from "react-router-dom";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";

function PlanningDetails({
  account_slug,
  project_id,
  account_id,
  authorizationCode,
  scenarios,
  setScenarios,
  phases,
  resources,
  isLoading,
  lobs,
}) {
  //Env
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  var locationArr = location.split("/");
  var id = locationArr.pop();
  const navigate = useNavigate();

  //States
  const [currentScenario, setCurrentScenario] = useState({});
  const [scenarioId, setScenarioId] = useState("");
  const [phasesOnScenario, setPhasesOnScenario] = useState([]);
  const [assignedHours, setAssignedHours] = useState(0);
  const [scenarioName, setScenarioName] = useState([]);
  const [scenarioStartDate, setScenarioStartDate] = useState([]);
  const [numberOfWeeks, setNumberOfWeeks] = useState([]);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [newlyCreatedAllocationId, setNewlyCreatedAllocationId] =
    useState(null);

  const [showEditModal, setShowEditModal] = useState(false);

  //Alerts
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Scenario details updated"
  );
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isStillLoading, setIsStillLoading] = useState(true);

  useEffect(() => {
    setResourceTypes(resources);
    scenarios.forEach((scenario) => {
      if (scenario.id == id) {
        setScenarioId(scenario.id);
        setCurrentScenario(scenario.attributes);
        setScenarioName(scenario.attributes.name);
        setScenarioStartDate(scenario.attributes["start-on"]);
        setNumberOfWeeks(scenario.attributes["weeks"]);
        if (scenario.attributes["phase-mapping"].length !== 0) {
          let phasesOnScenario = scenario.attributes["phase-mapping"];
          phasesOnScenario = phasesOnScenario.map((phaseMap) => {
            phases.forEach((phase) => {
              if (phase.id == phaseMap["project_phase_id"]) {
                phaseMap.name = phase.attributes.name;
              }
            });
            return phaseMap;
          });
          setPhasesOnScenario(phasesOnScenario);
        }
        let allocatedHours = scenario.attributes["allocated-hours"];
        setAssignedHours(
          allocatedHours
            .map((allocation) => parseFloat(allocation.hours))
            .reduce((a, b) => a + b, 0)
        );
        API.Get(
          scenario.relationships["resource-allocations"].links.related +
            "?include=project-resource,resource-plan",
          authorizationCode
        ).then((res) => {
          let allocations = res.data.data;
          setAllocations(allocations);
          setIsStillLoading(false);
        });
      }
    });
  }, [scenarios, resources, phases]);

  useEffect(() => {
    arrangeDataForPhaseTable();
  }, [resourceTypes, allocations]);

  const resourceTotalsSum = resourceTypes
    .filter((resource) => resource.type !== "add")
    .map((resource) => parseFloat(resource.attributes["total-hours"]))
    .reduce((a, b) => a + b, 0);

  const availableHours = (
    parseFloat(resourceTotalsSum) - parseFloat(assignedHours)
  ).toFixed(2);

  let scenarioData = {
    data: {
      type: "resource-plans",
      attributes: {
        name: scenarioName,
        "start-on": scenarioStartDate,
        weeks: numberOfWeeks,
      },
      relationships: {
        project: { data: { type: "projects", id: project_id } },
      },
    },
  };

  let allocationData = {
    data: {
      id: null,
      type: "resource-allocations",
      attributes: {
        personnel: null,
        "assigned-hours": phasesOnScenario.map((phase) => ""),
      },
      relationships: {
        "resource-plan": {
          data: { id: scenarioId, type: "resource-plans" },
        },
        "project-resource": {
          data: { id: null, type: "project-resources" },
        },
      },
    },
  };

  const handleSuccess = (successMessage) => {
    setSuccessMessage(successMessage);
    setShowSuccessAlert(true);
    setTimeout(() => {
      setShowSuccessAlert(false);
    }, 3000);
  };

  const handleErr = (err, setShow) => {
    setErrorMessages(formatUnprocessibleResponse(err, "scenario"));
    setShow(false);
    setShowFailAlert(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setScenarioName(currentScenario.name);
    setScenarioStartDate(currentScenario["start-on"]);
    setNumberOfWeeks(currentScenario.weeks);
  };

  const editScenario = () => {
    scenarioData.data.id = scenarioId;
    API.Patch(
      `${apiHost}/${account_slug}/v1/resource-plans/${scenarioId}`,
      scenarioData,
      authorizationCode
    )
      .then((res) => {
        if (res.status == 200) {
          let attr = res.data.data.attributes;
          closeEditModal();
          handleSuccess("Scenario details updated");
          setCurrentScenario(attr);
          setPhasesOnScenario(attr["phase-mapping"]);
          let updatedScenarios = scenarios.map((scenario) => {
            if (scenarioId == scenario.id) {
              scenario.attributes = attr;
              return scenario;
            } else {
              return scenario;
            }
          });
          setScenarios(updatedScenarios);
          setForceUpdate(!forceUpdate);
        }
      })
      .catch((err) => {
        handleErr(err, setShowEditModal);
      });
  };

  const addPersonnelRow = (e, resourceId) => {
    e.persist();

    let emptyAllocation = {
      id: null,
      type: "resource-allocations",
      attributes: {
        personnel: null,
        "assigned-hours": phasesOnScenario.map((phase) => ""),
      },
      relationships: {
        "resource-plan": {
          data: { id: scenarioId, type: "resource-plans" },
        },
        "project-resource": {
          data: { id: resourceId, type: "project-resources" },
        },
      },
    };
    let resources = resourceTypes.map((resource) => {
      if (e.target.id == resource.id) {
        let allocation = { ...emptyAllocation };
        allocation.type = "resource-row";
        allocation.id = resourceId;
        allocation.relationships["project-resource"].data.id = resource.id;
        allocation.resourceName = resource.attributes.name;
        resource = emptyAllocation;
        return resource;
      } else {
        return resource;
      }
    });

    for (let i = 0; i < resources.length; i++) {
      if (resources[i].id == e.target.id) {
        resources.splice(i + 1, 0, {
          id: "add",
          resourceId: resources[i].id,
          type: "add",
          attributes: {
            name: "",
          },
        });
        setResourceTypes(resources);
      }
    }
    setAllocations([...allocations, emptyAllocation]);
  };

  const removePersonnelRow = (e, row, index) => {
    e.persist();
    let resourceIds = resources.map((resource) => resource.id);
    if (row.id !== index && !resourceIds.includes(row.id) && row.id !== null) {
      const item = {
        data: {
          type: "resource-allocations",
          id: row.id,
        },
      };
      API.Delete(
        `${apiHost}/${account_slug}/v1/resource-allocations/${row.id}`,
        item,
        authorizationCode
      )
        .then((res) => {
          setAllocations(
            allocations.filter((allocation) => allocation.id !== row.id)
          );
        })
        .catch((err) => {
          setErrorMessages(
            formatUnprocessibleResponse(err, "Resource Allocation")
          );
          setShowFailAlert(true);
        });
    }

    if (row.resourceName) {
      let newResourceType = resources.filter(
        (resource) =>
          (row.relationships["project-resource"] &&
            resource.id == row.relationships["project-resource"].data.id) ||
          resource.id == row.id
      );
      newResourceType[0].type = "project-resources";
      let newResourceTypes = resourceTypes.map((resourceType) => {
        if (
          resourceType.id == newResourceType[0].id ||
          (resourceType.relationships["project-resource"] &&
            resourceType.relationships["project-resource"].data.id ==
              newResourceType[0].id)
        ) {
          return newResourceType[0];
        } else {
          return resourceType;
        }
      });
      setResourceTypes(newResourceTypes);
    }
    if (row.type == "resource-allocations") {
      let newTableRows = [...tableRows];
      newTableRows.splice(index, 1);
      setTableRows(newTableRows);
      setAllocations(
        newTableRows.filter((row) => row.type == "resource-allocations")
      );
    }
  };

  const createResourceAllocation = (e, row) => {
    e.persist();
    let rowData = { ...row };
    let data = { data: rowData };
    data.data.type = "resource-allocations";
    if (data.data.resourceName) {
      delete data.data.resourceName;
    }
    delete data.data.id;
    data.data.attributes.personnel = e.target.value;
    API.Post(
      `${apiHost}/${account_slug}/v1/resource-allocations`,
      data,
      authorizationCode
    )
      .then((res) => {
        row.id = res.data.data.id;
        setForceUpdate(!forceUpdate);
        setNewlyCreatedAllocationId(res.data.data.id);
      })
      .catch((err) => {
        setErrorMessages(
          formatUnprocessibleResponse(err, "Resource Allocation")
        );
        setShowFailAlert(true);
      });
  };

  const updateResourceAllocation = (e, row, index, change) => {
    e.persist();
    let data = allocationData.data;
    let id = row.id;
    if (row.id == "" || row.id == "add" || row.id == undefined) {
      id = newlyCreatedAllocationId;
    }
    let resourceId = "";
    if (row.relationships["project-resource"]) {
      resourceId = row.relationships["project-resource"].data.id;
    } else {
      resourceId = row.id;
    }
    data.attributes.personnel = row.attributes.personnel;
    if (row.attributes["assigned-hours"]) {
      if (change == "hours") {
        row.attributes["assigned-hours"].splice(index, 1, e.target.value);
      }
      data.attributes["assigned-hours"] = row.attributes["assigned-hours"];
    } else {
      data.attributes["assigned-hours"].splice(index, 1, e.target.value);
    }

    data.id = id;
    data.relationships["project-resource"].data.id = resourceId;

    data = { data: data };
    API.Patch(
      `${apiHost}/${account_slug}/v1/resource-allocations/${id}`,
      data,
      authorizationCode
    )
      .then((res) => {
        data.id = res.data.data.id;
        calculateAssignedHours();
        setForceUpdate(!forceUpdate);
      })
      .catch((err) => {
        setErrorMessages(
          formatUnprocessibleResponse(err, "Resource Allocation")
        );
        setShowFailAlert(true);
      });
  };

  const updatePhaseOnScenario = (e, phase, index) => {
    e.persist();

    // Use a copy of the current scenario so we can remove sprints
    let updatatbleScenario = Object.assign({}, currentScenario);

    let data = {
      data: {
        id: scenarioId,
        type: "resource-plans",
        attributes: updatatbleScenario,
        relationships: {
          project: { data: { id: project_id, type: "projects" } },
        },
      },
    };
    delete data.data.attributes.sprints;
    delete data.data.attributes["allocated-hours"];
    data.data.attributes["phase-mapping"][index] = phase;
    data.data.attributes["phase-mapping"].map((phase) => {
      delete phase.name;
      return phase;
    });
    API.Patch(
      `${apiHost}/${account_slug}/v1/resource-plans/${scenarioId}`,
      data,
      authorizationCode
    )
      .then((res) => {
        let phasesOnScenario = [...phasesOnScenario];
        phasesOnScenario = res.data.data.attributes["phase-mapping"];

        phasesOnScenario = phasesOnScenario.map((phaseMap) => {
          phases.forEach((phase) => {
            if (phase.id == phaseMap["project_phase_id"]) {
              phaseMap.name = phase.attributes.name;
            }
          });
          return phaseMap;
        });
        setPhasesOnScenario(phasesOnScenario);
        setForceUpdate(!forceUpdate);
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "Phase Mapping"));
        setShowFailAlert(true);
      });
  };

  const calculateAssignedHours = () => {
    let allocations = tableRows.filter(
      (row) => row.type !== "add" && row.type !== "project-resources"
    );
    let allHoursonResource = allocations.map(
      (allocation) => allocation.attributes["assigned-hours"]
    );
    let hoursToAdd = allHoursonResource.map((arrayOfHours) =>
      arrayOfHours
        .filter((hour) => hour !== null && hour !== "")
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    );
    let assignedHours = hoursToAdd.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    setAssignedHours(assignedHours);
    return assignedHours;
  };

  const calculateTotalAssignedOnResource = (currentRow) => {
    let allocations = tableRows.filter(
      (row) =>
        row.type !== "add" &&
        row.type !== "project-resources" &&
        currentRow.relationships["project-resource"] &&
        row.relationships["project-resource"] &&
        currentRow.relationships["project-resource"].data.id ==
          row.relationships["project-resource"].data.id
    );
    let allHoursonResource = allocations.map(
      (allocation) => allocation.attributes["assigned-hours"]
    );
    let hoursToAdd = allHoursonResource.map((arrayOfHours) =>
      arrayOfHours
        .filter((hour) => hour !== null && hour !== "")
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    );
    return hoursToAdd.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  };

  const chartData = {
    labels: resourceTypes.map((resource) => resource.attributes.name),
    datasets: [
      {
        label: "Total Hours per Resource",
        data: resourceTypes.map((resource) =>
          parseFloat(resource.attributes["total-hours"])
        ),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const editModalBody = (
    <div>
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <FormTextField
            label="Scenario Name"
            placeholder={"What do you want to call this scenario"}
            value={scenarioName}
            onChange={(e) => setScenarioName(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormDateField
            label="Start Date"
            value={scenarioStartDate}
            onChange={(e) => setScenarioStartDate(e.target.value)}
          />
        </Col>
        <Col>
          <FormNumberField
            label="Number of Weeks"
            placeholder={"Choose length of Project"}
            value={numberOfWeeks}
            min={0}
            step={1}
            onChange={(e) => setNumberOfWeeks(e.target.value)}
          />
        </Col>
      </Row>
    </div>
  );

  const arrangeDataForPhaseTable = () => {
    let array = [];
    resourceTypes.forEach((resourceType) => {
      let allocationsOnResource = allocations.filter(
        (allocation) =>
          allocation.relationships["project-resource"].data &&
          allocation.relationships["project-resource"].data.id ==
            resourceType.id
      );
      let attr = resourceType.attributes;
      if (allocationsOnResource.length == 0) {
        resourceType.name = renderResourceName(resourceType, lobs);
      }
      if (allocationsOnResource.length !== 0) {
        resourceType.type = "resource-row";
      } else {
        array.push(resourceType);
      }
      allocationsOnResource.forEach((allocation, index) => {
        let allocationRelData =
          allocation.relationships["project-resource"].data;
        if (allocationRelData && allocationRelData.id == resourceType.id) {
          if ((resourceType.type = "resource-row") && index == 0) {
            allocation.resourceName = renderResourceName(resourceType, lobs);
          }
          array.push(allocation);
        }
      });
      if (allocationsOnResource.length !== 0) {
        array.push({
          id: "add",
          resourceId: resourceType.id,
          type: "add",
          attributes: {
            name: "",
          },
        });
      }
    });
    setTableRows(array);
  };

  //Handle Alerts
  const successAlert = () => {
    if (showSuccessAlert == true) {
      return (
        <DismissibleAlert
          className="scenarioAlerts"
          variant="info"
          onClose={() => setShowSuccessAlert(false)}
          text={successMessage}
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div className="col-sm-12" id="sow-workarea">
      <div className="tab-content">
        <div className="tab-pane show active">
          {successAlert()}
          {handleFailAlert()}
          <Card className="cardContainer">
            <ScopeStackModal
              modalTitle={"Edit Scneario"}
              modalBody={editModalBody}
              button1Text="Cancel"
              handleButton1Click={() => setShowEditModal(false)}
              button2Text={"Edit"}
              handleButton2Click={editScenario}
              show={showEditModal}
              handleClose={() => closeEditModal()}
            />
            <Row className="scenarioHeader">
              <Col>
                <div className="planningScenarios">
                  <span
                    style={{ color: "#418172", textDecoration: "underline" }}
                  >
                    Planning Scenarios /
                  </span>
                  &nbsp;
                  <span>{currentScenario.name}</span>
                </div>
              </Col>
            </Row>
            <hr style={{ width: "100%", marginBottom: "30px" }}></hr>
            <Card
              style={{
                border: "1px solid #eee",
                boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.15",
                marginBottom: "30px",
                marginLeft: "2%",
                width: "95%",
                padding: "20px",
              }}
            >
              <Row>
                <Col sm={3} className="planningScenarios detailsHeader">
                  Scenario Overview
                </Col>
                <Col sm={6}>
                  <Button
                    onClick={() => setShowEditModal(true)}
                    variant="primary"
                    className="seafoamBgButton text-right"
                  >
                    Edit
                  </Button>
                </Col>
                <Col
                  sm={3}
                  onClick={() => {
                    let resetResources = resources.map((resource) => {
                      resource.type = "project-resources";
                      return resource;
                    });
                    setResourceTypes(resetResources);
                    navigate(`/projects/${project_id}/resource_plans/`);
                  }}
                  className="backToScenarios"
                >
                  <span>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                  </span>
                  &nbsp;
                  <span>Back To Scenarios</span>
                </Col>
              </Row>
              <hr style={{ marginBottom: "30px" }}></hr>
              <Row>
                <Col>
                  <div className="scenarioDetails">
                    <strong>{currentScenario.weeks}</strong> Weeks
                  </div>
                  <hr style={{ marginBottom: "30px" }}></hr>
                  <div className="scenarioDetails">
                    <strong>{currentScenario.sprints}</strong> Sprints
                  </div>
                </Col>
                <Col>
                  {isLoading || isStillLoading ? (
                    <SmallSpinner />
                  ) : (
                    <>
                      <div className="scenarioDetails">
                        <strong>{assignedHours}</strong> Assigned Hours
                      </div>
                      <hr style={{ marginBottom: "30px" }}></hr>
                      <div className="scenarioDetails">
                        <strong>{availableHours}</strong> Available Hours
                      </div>
                    </>
                  )}
                </Col>
                <Col>
                  <ScopeStackPieChart
                    width="68px"
                    height="109px"
                    data={chartData}
                  />
                </Col>
                <Col>
                  <ul>
                    {resources.map((resource, index) => (
                      <Row key={index} className="resourceInfo">
                        <Col>
                          <li>
                            {resource.attributes.name}
                            <span>{` (${(
                              (parseFloat(resource.attributes["total-hours"]) /
                                resourceTotalsSum) *
                              100
                            ).toFixed(2)}% / ${
                              resource.attributes["total-hours"]
                            } Hours)`}</span>
                          </li>
                        </Col>
                      </Row>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Card>
            <Row style={{ padding: "20px" }}>
              <Col sm={4}>
                {isLoading || isStillLoading ? (
                  <SmallSpinner />
                ) : (
                  <table className="sansTable greyBackground table table-striped resourcePlanningTable">
                    <thead>
                      <tr style={{ height: "72px" }}>
                        <th colSpan={2}>
                          <div className="resourcePlanner">
                            Resource Planner
                          </div>
                        </th>
                      </tr>
                      <tr className="resourceInfo">
                        <td style={{ width: "40%" }}>Resource</td>
                        <td style={{ width: "60%" }}>Name</td>
                      </tr>
                    </thead>
                    <tbody>
                      {tableRows.map((row, index) => {
                        return (
                          <tr key={index}>
                            {row.type == "project-resources" ||
                            row.type == "resource-row" ||
                            row.resourceName ? (
                              <td>
                                <span>
                                  {row.resourceName
                                    ? row.resourceName
                                    : row.name}
                                </span>
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td>
                              {row.type == "project-resources" ||
                              row.type == "add" ? (
                                <span
                                  id={row.id}
                                  onClick={(e) =>
                                    addPersonnelRow(
                                      e,
                                      row.resourceId ? row.resourceId : row.id
                                    )
                                  }
                                  className="scenarioTileButton"
                                >
                                  <FontAwesomeIcon
                                    className="scenarioTileButton"
                                    icon={faPlus}
                                  />
                                  &nbsp;Add
                                </span>
                              ) : (
                                <div>
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Form.Control
                                      style={{ width: "90%", height: "25px" }}
                                      type="text"
                                      id={row.id}
                                      defaultValue={
                                        row.attributes.personnel
                                          ? row.attributes.personnel
                                          : ""
                                      }
                                      onBlur={(e) => {
                                        let allocationIds = allocations.map(
                                          (allocation) => allocation.id
                                        );
                                        if (
                                          allocationIds.includes(row.id) &&
                                          row.id !== null
                                        ) {
                                          updateResourceAllocation(
                                            e,
                                            row,
                                            index,
                                            "name"
                                          );
                                        }
                                        if (row.id == null) {
                                          createResourceAllocation(e, row);
                                        }
                                      }}
                                      onChange={(e) => {
                                        setTableRows(
                                          tableRows.map((tableRow) => {
                                            if (tableRow.id == e.target.id) {
                                              tableRow.attributes.personnel =
                                                e.target.value;
                                              return tableRow;
                                            } else {
                                              return tableRow;
                                            }
                                          })
                                        );
                                      }}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <div
                                      id={
                                        row.type !== "resource-allocations"
                                          ? row.id
                                          : index
                                      }
                                      onClick={(e) =>
                                        removePersonnelRow(e, row, index)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        style={{ color: "red" }}
                                        icon={faTrashCan}
                                      />
                                    </div>
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </Col>
              <Col sm={7}>
                {isLoading || isStillLoading ? (
                  <SmallSpinner />
                ) : (
                  <table className="sansTable greyBackground table table-striped resourcePlanningTable phasesOnScenarioTable">
                    <thead>
                      <tr className="phaseDropdownRow firstHeaderRow">
                        {phasesOnScenario.map((phaseOnScenario, index) => (
                          <th key={index}>
                            <Form.Control
                              className="phaseColumns"
                              id={index}
                              as="select"
                              label=""
                              value={
                                phaseOnScenario.name
                                  ? phaseOnScenario.name
                                  : "Phase"
                              }
                              onBlur={(e) => {
                                let selected = e.target.options.selectedIndex;
                                phaseOnScenario["project_phase_id"] =
                                  e.target.options[selected].accessKey;
                                updatePhaseOnScenario(
                                  e,
                                  phaseOnScenario,
                                  index
                                );
                              }}
                              onChange={(e) => {
                                let phases = [...phasesOnScenario];
                                phases[index].name = e.target.value;
                                setPhasesOnScenario(phases);
                              }}
                            >
                              {[
                                <option accessKey={null} key="blank">
                                  Phase
                                </option>,
                                phases.map((phase, index) => (
                                  <option accessKey={phase.id} key={index}>
                                    {phase.attributes.name}
                                  </option>
                                )),
                              ]}
                            </Form.Control>
                          </th>
                        ))}
                      </tr>
                      <tr>
                        {phasesOnScenario.map((phase, index) => {
                          return <th key={index}>{getDate(phase.date)}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {tableRows.map((row, index) => {
                        return (
                          <tr key={index}>
                            {phasesOnScenario.map((phase, index) => {
                              if (
                                row.type == "resource-allocations" ||
                                row.type == "resource-row"
                              ) {
                                let value = "";
                                if (row.attributes["assigned-hours"]) {
                                  value =
                                    row.attributes["assigned-hours"][index];
                                }
                                return (
                                  <td key={index}>
                                    <Form.Control
                                      id={index}
                                      style={{ width: "70px", height: "25px" }}
                                      type="number"
                                      min="0"
                                      step="1"
                                      defaultValue={value !== null ? value : ""}
                                      onBlur={(e) => {
                                        updateResourceAllocation(
                                          e,
                                          row,
                                          index,
                                          "hours"
                                        );
                                      }}
                                    />
                                  </td>
                                );
                              } else {
                                return <td key={index}></td>;
                              }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </Col>
              <Col sm={1}>
                {isLoading || isStillLoading ? (
                  <SmallSpinner />
                ) : (
                  <table className="sansTable greyBackground table table-striped resourcePlanningTable">
                    <thead>
                      <tr style={{ height: "72px" }}>
                        <td>&nbsp;</td>
                      </tr>
                      <tr className="resourceInfo">
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      {tableRows.map((row, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-right">
                              {row.resourceName
                                ? calculateTotalAssignedOnResource(row)
                                : ""}
                            </td>
                          </tr>
                        );
                        // }
                      })}
                    </tbody>
                  </table>
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PlanningDetails;
