import React from "react";

function ArrowDropdownButton({ options }) {
  return (
    <div className="dropdown text-center arrowButton">
      <span
        className="dropdown-toggle"
        role="button"
        data-toggle="dropdown"
      ></span>
      <div className="dropdown-menu dropdown-menu-right">
        {options}
      </div>
    </div>
  );
}

export default ArrowDropdownButton;
