import { useListQuestionnairesForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { ManageSurvey } from "../types";
import { getFormattedDate } from "@utils/helperFunctions";
const useListManageSurveys = ({
  pageSize,
  pageNumber,
  filterParams,
  sortBy,
  sortOrder,
}) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListQuestionnairesForAccountQuery({
      slug: accountSlug,
      filter: Object.keys(filterParams).length ? filterParams : undefined,
      page: {
        number: pageNumber,
        size: pageSize,
      },
      sort: sortBy
        ? [sortOrder == "asc" ? sortBy : `-${sortBy}`]
        : ["-created_at"],
    });

  let manageSurveys: ManageSurvey[] = [];

  if (data?.data) {
    try {
      manageSurveys = data.data.map((questionnaire) => {
        if (!questionnaire.id) {
          throw new Error("Missing questionnaire.id");
        }
        if (!questionnaire?.attributes?.name) {
          throw new Error("Missing questionnaire.attributes.name");
        }

        return {
          id: String(questionnaire.id),
          checked: false,
          attributes: {
            name: questionnaire.attributes.name || "",
            status: questionnaire.attributes?.published ? "Published" : "Draft",
            createdOn: questionnaire.attributes?.["created-at"]
              ? getFormattedDate(
                  new Date(questionnaire.attributes["created-at"])
                )
              : "",
            tags: questionnaire.attributes?.["tag-list"]
              ? questionnaire.attributes["tag-list"]
              : [],
          },
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    manageSurveys,
    isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListManageSurveys;
