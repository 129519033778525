import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import Markdown from "./Markdown";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import {
  removeDuplicates,
  formatUnprocessibleResponse,
  handleAlert,
} from "@utils/helperFunctions";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import useUnloadWarning from "@utils/customHooks/useUnloadWarning";
import { GenericToggle } from "@components/FormsV2/GenericToggle";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import FontAwesomeIconTooltipped from "@components/Buttons/FontAwesomeIconTooltipped/FontAwesomeIconTooltipped";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";

function ProjectConditions({ account_slug, project_id, account_id }) {
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [projectConditions, setProjectConditions] = useState([]);
  const [conditionsChanged, setConditionsChanged] = useState([]);
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [projectStatus, setProjectStatus] = useState("");

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const successMessage = "Your changes were saved!";
  const [isFormDirty, setFormDirty] = useState(false);

  useUnloadWarning(isFormDirty);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get project conditions
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-conditions`,
        authorizationCode
      ).then((res) => {
        const data = res.data.data;
        setProjectStatus(data.attributes.status);

        if (res.data.included) {
          let conditions = res.data.included.filter(
            (item) => item.type === "project-conditions"
          );
          setProjectConditions(
            conditions.map((c) => {
              if (c.attributes.status == "active") {
                c.attributes.skip = false;
              } else {
                c.attributes.skip = true;
              }
              return c;
            })
          );
        }
        setIsLoading(false);
      });
    }
  }, [authorizationCode]);

  const handleToggleCondition = (condition) => {
    let baseUrl = `${apiHost}/${account_slug}/v1/project-conditions/${condition.id}/`;
    if (condition.attributes.skip === true) {
      baseUrl += "skip";
    } else {
      baseUrl += "restore";
    }
    API.Put(baseUrl, {}, authorizationCode)
      .then((res) => {
        if (res.status == 200) {
          setShowSuccessAlert(true);
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "condition"));
        setShowFailAlert(true);
      });
  };

  const getLabel = (condition) => {
    return (
      <div className="flexCenter">
        <div style={{ width: "42vw" }}>{condition.attributes.name}</div>
        <div className="spaceRight">
          <GenericToggle
            hasText={true}
            onText="Included"
            offText="Excluded"
            initialValueOfIsOn={!condition.attributes.skip}
            onToggle={() => {
              const newProjectConditions = projectConditions.map((c) => {
                if (c.id == condition.id) {
                  c.attributes.skip = !c.attributes.skip;
                }
                return c;
              });
              handleToggleCondition(condition);
              setProjectConditions(newProjectConditions);
            }}
          />
        </div>
        <FontAwesomeIconTooltipped
          icon={faCircleInfo}
          style={{ color: "#418172", fontSize: "16px" }}
          toolTipText={
            condition.attributes.skip
              ? "You can toggle this on to include this condition in the generated document. It is currently excluded."
              : "You can toggle this off to exclude this condition from the generated document."
          }
        />
      </div>
    );
  };

  const renderProjectConditions = () => {
    return projectConditions.map((condition) => {
      return condition.attributes.skip ? (
        <s>
          <MultipleLineField
            id={condition.id}
            key={condition.id}
            label={getLabel(condition)}
            rows={5}
            defaultValue={condition.attributes.content}
            onBlur={(e) => {
              setConditionsChanged([
                ...conditionsChanged,
                { id: condition.id, content: e.target.value },
              ]);
            }}
            disabled={condition.attributes.skip}
          />
        </s>
      ) : (
        <MultipleLineField
          id={condition.id}
          key={condition.id}
          label={getLabel(condition)}
          rows={5}
          defaultValue={condition.attributes.content}
          onBlur={(e) => {
            setConditionsChanged([
              ...conditionsChanged,
              { id: condition.id, content: e.target.value },
            ]);
          }}
          disabled={condition.attributes.skip}
        />
      );
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormDirty(false);
    let projectPromises = [];
    let conditions = removeDuplicates(conditionsChanged, (item) => item.id);
    if (conditions.length !== 0) {
      conditions.forEach((condition) => {
        const conditionData = {
          data: {
            type: "project-conditions",
            id: condition.id,
            attributes: {
              content: condition.content,
            },
            relationships: {
              project: { data: { type: "projects", id: project_id } },
            },
          },
        };

        projectPromises.push(
          API.Patch(
            `${apiHost}/${account_slug}/v1/project-conditions/${condition.id}`,
            conditionData,
            authorizationCode
          )
        );
      });

      Promise.all(projectPromises)
        .then((res) => {
          setConditionsChanged([]);
          setUpdatedAlertIsOpen(true);
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "condition"));
          setShowFailAlert(true);
        });
    }
  };

  const handleFormChange = (event) => {
    setFormDirty(true);
  };

  const conditionsUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="conditionsAlert"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Terms and Conditions updated"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {conditionsUpdatedAlert()}
      {handleFailAlert()}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Card>
        <CardHeader title="Terms and Conditions" />
        <Card.Body>
          {isLoading ? (
            <ScopeStackSpinner />
          ) : (
            <Form onSubmit={handleSubmit} onChange={handleFormChange}>
              <Row>
                <Col sm={8}>{renderProjectConditions()}</Col>
                <Col sm={4}>
                  <Markdown />
                </Col>
              </Row>
              {projectConditions.length !== 0 ? (
                <Row>
                  <Col xs={12}>
                    {projectStatus === "building" && <SubmitButton />}
                  </Col>
                </Row>
              ) : null}
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProjectConditions;
