import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import { useNavigate } from "react-router-dom";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";

function RevisePartnerRequest({
  account_slug,
  project_id,
  account_id,
  projectStatus,
  partnerRequestId,
  partners,
  languageFields,
  phases,
  thirdPartyServices,
  subtasks,
  requestStatus,
  setRequestStatus,
  currencyUnit,
  authorizationCode,
}) {
  // Env
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  const [revision, setRevision] = useState("");
  const [successMessage, setSuccessMessage] = useState(
    "Third Party Request returned to partner for revision"
  );
  const [errorMessages, setErrorMessages] = useState("");
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);

  const redirectToEditPartnerRequest = () => {
    navigate(`/projects/${project_id}/third_party_requests/${id}/edit`);
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let revisedData = {
      data: { attributes: { "revision-description": revision } },
    };
    API.Put(
      `${apiHost}/${account_slug}/v1/partner-requests/${id}/revise`,
      revisedData,
      authorizationCode
    )
      .then((response) => {
        if (response.status == 200) {
          setRequestStatus(response.data.data.attributes.status);
          redirectToEditPartnerRequest();
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "partner request"));
        setShowFailAlert(true);
      });
  };

  const partnerRequestUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text={successMessage}
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div className="col-sm-12" id="sow-workarea">
      <div className="tabContent">
        {partnerRequestUpdatedAlert()}
        {handleFailAlert()}
        <Card>
          <CardHeader title="Revise Partner Request" />
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <MultipleLineField
                required={true}
                rows={5}
                label="How does the proposal need to be revised?"
                value={revision}
                onChange={(e) => {
                  setRevision(e.target.value);
                }}
              />
              <Row>
                <Col xs={6}>
                  <SubmitButton />
                </Col>
                <Col xs={6}>
                  <BackButton
                    url={`/projects/${project_id}/third_party_requests`}
                  />
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default RevisePartnerRequest;
