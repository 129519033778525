import React, { useState, useEffect } from "react";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import useOauth from "@utils/customHooks/useOauth";
import { useNavigate } from "react-router-dom";
import API from "@API";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import {
  capitalizeFirstLetter,
  capitalizeWords,
  formatUnprocessibleResponse,
  replaceUnderscoresWithSpaces,
} from "@utils/helperFunctions";
import { Button, Row, Col } from "react-bootstrap";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import PaginationArrows from "@components/Buttons/PaginationArrows/PaginationArrows";
import ControlledDropDown from "@components/Forms/ControlledDropDown";

function Requests({ arr, partners, authorizationCode }) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const location = window.location.href;
  let navigate = useNavigate();
  const serviceRequestsUrl = `${appHost}/external_requests`;
  const partnerRequestsUrl = `${appHost}/partner_requests`;
  const [view, setView] = useState("");

  const [serviceRequests, setServiceRequests] = useState(arr);
  const [partnerRequests, setPartnerRequests] = useState(arr);
  const [successAlertIsOpen, setSuccessAlertIsOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);

  useEffect(() => {
    if (location == serviceRequestsUrl) {
      setView("Requests Received");
    } else if (location == partnerRequestsUrl) {
      setView("Requests Sent");
    }
  }, [arr, partners]);

  const getTableData = (array) => {
    if (array.length === 0) {
      return [];
    }

    // Map over service requests & return data for ScopeStackTable
    return array.map((request, index) => {
      const requestId = request.id;
      const requestStatus = request.attributes.status;
      let requestedBy = "";
      var partner = "";

      //Match partner with sevice-request
      partners.forEach((partner) => {
        let requestData = request.relationships.account.data;
        if (requestData && requestData.id == partner.id) {
          requestedBy = partner.attributes.name;
        }
      });

      for (let i = 0; i < partners.length; i++) {
        let partnerData = request.relationships.subcontractor.data;
        if (partnerData && partners[i].id == partnerData.id) {
          partner = partners[i].attributes.name;
        }
      }

      // Render icon buttons under actions on hover if request status is not pending
      const renderActions = () => {
        const getVariant = (requestStatus) => {
          if (location === serviceRequestsUrl) {
            if (
              requestStatus == "accepted" ||
              requestStatus == "offered" ||
              requestStatus == "complete" ||
              requestStatus == "declined"
            ) {
              return "btn btn-outline-secondary";
            } else {
              return "btn btn-primary";
            }
          }
          if (location === partnerRequestsUrl) {
            if (requestStatus == "offered") {
              return "btn btn-primary";
            } else {
              return "btn btn-outline-secondary";
            }
          }
        };
        if (requestStatus == "pending" && location === serviceRequestsUrl) {
          return (
            <div style={{ display: "flex" }} className="actionsDiv">
              <SeafoamButton
                style={{ width: "9em" }}
                onClick={() => {
                  API.Put(request.links.accept, null, authorizationCode)
                    .then((response) => {
                      if (response.status == 200) {
                        let data = response.data.data;
                        window.location.replace(
                          `${appHost}/projects/${data.relationships.subproject.data.id}/edit`
                        );
                      }
                    })
                    .catch((err) => {
                      setErrorMessages(
                        formatUnprocessibleResponse(err, "Request")
                      );
                      setShowFailAlert(true);
                    });
                }}
                text="Create Project"
              />
              <Button
                onClick={() => {
                  API.Put(request.links.decline, null, authorizationCode)
                    .then((response) => {
                      if (response.status == 200) {
                        let data = response.data.data;
                        setServiceRequests(
                          serviceRequests.map((request) => {
                            if (request.id == data.id) {
                              request.attributes.status =
                                data.attributes.status;
                              return request;
                            } else {
                              return request;
                            }
                          })
                        );
                        setSuccessAlertIsOpen(true);
                        setTimeout(() => {
                          setSuccessAlertIsOpen(false);
                        }, 3000);
                      }
                    })
                    .catch((err) => {
                      setErrorMessages(
                        formatUnprocessibleResponse(err, "Request")
                      );
                      setShowFailAlert(true);
                    });
                }}
                style={{ marginLeft: "0.5em" }}
                variant="danger"
              >
                Decline
              </Button>
            </div>
          );
        } else if (
          (request.relationships.subproject &&
            request.relationships.subproject.data) ||
          (request.relationships.project && request.relationships.project.data)
        ) {
          return (
            <Button
              onClick={() => {
                if (location == serviceRequestsUrl) {
                  window.location.replace(
                    `${appHost}/projects/${request.relationships.subproject.data.id}/edit`
                  );
                }
                if (location == partnerRequestsUrl) {
                  window.location.replace(
                    `${appHost}/projects/${request.relationships.project.data.id}/edit`
                  );
                }
              }}
              className="viewProjectBtn"
              variant={getVariant(requestStatus)}
            >
              View Project
            </Button>
          );
        }
      };

      const requestedAt = new Date(request.attributes["requested-at"]);
      const respondedAt = new Date(request.attributes["responded-at"]);
      const options = { month: "long", day: "numeric", year: "numeric" };

      // Map returns a table row for each request
      return {
        rowClass: "serviceRequestsRow",
        onClick: null,
        columns: [
          {
            class: "col-2",
            name: location === serviceRequestsUrl ? requestedBy : partner,
          },
          {
            class: "col-2",
            name: requestStatus.includes("_")
              ? capitalizeWords(replaceUnderscoresWithSpaces(requestStatus))
              : capitalizeFirstLetter(requestStatus),
          },

          {
            class: "col-4",
            name: request.attributes.description,
          },
          {
            class: "col",
            name:
              location === serviceRequestsUrl
                ? requestedAt.toLocaleDateString("en-US", options)
                : respondedAt.toLocaleDateString("en-US", options),
          },
        ],
        actions: renderActions(),
      };
    });
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "col-2",
        name: location === serviceRequestsUrl ? "Requested By" : "Partner",
      },
      {
        class: "col-2",
        name: "Status",
      },
      {
        class: "col-4",
        name: "Description",
      },
      {
        class: "col",
        name: "Last Action",
      },
    ],
  };

  const getFooterData = () => {
    let content = (
      <PaginationArrows
        array={arr}
        setArrayToRender={
          location == serviceRequestsUrl
            ? (arr) => setServiceRequests(arr)
            : (arr) => setPartnerRequests(arr)
        }
        itemsPerPage={15}
      />
    );

    return {
      rowClass: "col-12",
      columns: [
        { class: "serviceRequestsFooter", content: content, colSpan: "12" },
      ],
    };
  };

  const tableData =
    location == serviceRequestsUrl
      ? getTableData(serviceRequests)
      : getTableData(partnerRequests);
  const footerData = getFooterData();

  let serviceRequestsTable = new ScopeStackTable(
    "Requests Received",
    headerData,
    tableData,
    footerData
  );

  const successAlert = () => {
    if (successAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setSuccessAlertIsOpen(false)}
          text="Partner Request has been declined"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div
      style={{
        paddingTop: "1em",
        paddingLeft: "2em",
        paddingRight: "2em",
        paddingBottom: "2em",
      }}
    >
      {successAlert()}
      {handleFailAlert()}
      <Row>
        <Col>
          <h2>Requests for Third Party Services</h2>
        </Col>
        <Col sm={3}>
          <ControlledDropDown
            className="pull-right"
            label="View"
            value={view}
            onChange={(e) => {
              let val = e.target.value;
              setView(val);
              if (val === "Requests Received") {
                navigate("/external_requests");
              }
              if (val === "Requests Sent") {
                navigate("/partner_requests");
              }
            }}
            options={[
              <option key={1}>Requests Received</option>,
              <option key={2}>Requests Sent</option>,
            ]}
          />
        </Col>
      </Row>
      <BaseTable
        className="scopestack serviceRequestsTable"
        striped={false}
        hover={false}
        bordered={false}
        headerRows={serviceRequestsTable.buildHeaderRows()}
        dataRows={serviceRequestsTable.buildDataRows()}
        footerRows={serviceRequestsTable.buildFooterRows()}
      />
    </div>
  );
}

export default Requests;
