import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewStandardTask from "./StandardTasks/NewStandardTask";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import { capitalizeFirstLetter } from "@utils/helperFunctions";

function ServicesMspa({ account_slug, project_id, account_id }) {
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [projectStatus, setProjectStatus] = useState("");
  const [phases, setPhases] = useState([]);
  const [projectPhases, setProjectPhases] = useState([]);
  const [resources, setResources] = useState([]);
  const [projectResources, setProjectResources] = useState([]);
  const [linesOfBusiness, setLinesOfBusiness] = useState([]);
  const [blueprints, setBlueprints] = useState([]);
  const [rateType, setRateType] = useState("Hours");
  const [useResourceRates, setUseResourceRates] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  // When page loads, call API to get Notes and lift it up to the state
  useEffect(() => {
    if (authorizationCode != "") {
      //Get Project Status
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-phases,project-phases.phase,account,resource-rates,resource-rates.line-of-business,resource-rates.resource`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        const status = data.attributes.status;
        if (status !== null) setProjectStatus(status);
        const paymentInfo = data.attributes["payment-info"];
        let rateType =
          paymentInfo["rate-type"] !== "hours"
            ? capitalizeFirstLetter(paymentInfo["rate-type"]) + "s"
            : capitalizeFirstLetter(paymentInfo["rate-type"]);
        setRateType(rateType);

        let projectPhases = response.data.included.filter(
          (item) => item.type === "project-phases"
        );
        setProjectPhases(projectPhases);

        let account = response.data.included.filter(
          (item) => item.type === "accounts"
        )[0];
        let useResourceRates =
          account.attributes.settings.project_resource_rates;

        // Get phases
        API.Get(`${apiHost}/${account_slug}/v1/phases`, authorizationCode).then(
          (response) => {
            let data = response.data.data;
            if (data !== null) setPhases(data);
          }
        );

        // Get resources
        if (useResourceRates) {
          let resources = response.data.included.filter(
            (item) => item.type === "resource-rates"
          );
          setUseResourceRates(useResourceRates);
          setResources(resources);
        } else {
          API.Get(
            `${apiHost}/${account_slug}/v1/resources?page[size]=1000`,
            authorizationCode
          ).then((response) => {
            let data = response.data.data;
            if (data !== null) setResources(data);
          });
        }

        // Get Project Resources
        let resourcesUrl =
          data.relationships["project-resources"].links.related +
          "?include=line-of-business,resource";
        API.Get(resourcesUrl, authorizationCode).then((response) => {
          let resources = response.data.data;
          setProjectResources(resources);
        });

        // Get lines of business
        API.Get(
          `${apiHost}/${account_slug}/v1/line-of-businesses`,
          authorizationCode
        ).then((response) => {
          let data = response.data.data;
          if (data !== null) setLinesOfBusiness(data);
        });

        // Get blueprints
        API.Get(
          `${apiHost}/${account_slug}/v1/blueprints`,
          authorizationCode
        ).then((response) => {
          let data = response.data.data;
          if (data !== null) setBlueprints(data);
          setIsLoading(false);
        });
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/projects/:project_id/standard_tasks/new"}
            element={
              <NewStandardTask
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                phases={phases}
                projectPhases={projectPhases}
                linesOfBusiness={linesOfBusiness}
                blueprints={blueprints}
                resources={resources}
                projectResources={projectResources}
                isLoading={isLoading}
                rateType={rateType}
                authorizationCode={authorizationCode}
                useResourceRates={useResourceRates}
              />
            }
          />
          <Route
            path={
              "/projects/:project_id/standard_tasks/new?service_type=managed_services"
            }
            element={
              <NewStandardTask
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                projectPhases={projectPhases}
                linesOfBusiness={linesOfBusiness}
                blueprints={blueprints}
                resources={resources}
                isLoading={isLoading}
                rateType={rateType}
                authorizationCode={authorizationCode}
                useResourceRates={useResourceRates}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default ServicesMspa;
