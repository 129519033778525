import {
  useListServicesForAccountQuery,
  V1PhaseResource,
  V1ServiceCategoryResource,
} from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { getFormattedDate } from "@utils/helperFunctions";
import { ProfessionalService } from "../types";

const useListServices = ({
  serviceType,
  pageSize,
  pageNumber,
  filterParams,
  sortBy,
  sortOrder,
}) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };
  const { data, error, isLoading, refetch, isFetching } =
    useListServicesForAccountQuery({
      slug: accountSlug,
      filter: { ...filterParams, "service-type": serviceType },
      page: {
        number: pageNumber,
        size: pageSize,
      },
      include: ["phase", "subservices", "service-category"],
      sort: sortBy ? [sortOrder == "asc" ? sortBy : `-${sortBy}`] : ["name"],
    });

  let services: ProfessionalService[] = [];

  if (data?.data) {
    try {
      services = data.data.map((service, i) => {
        if (!service.id) {
          throw new Error("Missing service.id");
        }
        if (!service.attributes.name) {
          throw new Error("Missing service.attributes.name");
        }

        const phaseId = service?.relationships?.phase?.data?.id;
        const phase = data.included?.find(
          (item) => item.type === "phases" && item.id === phaseId
        ) as V1PhaseResource;

        const categoryId =
          service?.relationships?.["service-category"]?.data?.id;
        const category = data.included?.find(
          (item) => item.type === "service-categories" && item.id === categoryId
        ) as V1ServiceCategoryResource;

        return {
          id: String(service.id),
          attributes: {
            name: service.attributes.name,
            phase: phase?.attributes?.name || "",
            category: category?.attributes?.["nested-name"] || "",
            updated: service.attributes?.["updated-at"]
              ? getFormattedDate(new Date(service.attributes["updated-at"]))
              : "",
            estimatedHRs: service.attributes?.["suggested-hours"] || "",
            actualHRs: service.attributes?.["actual-hours"] || "",
            deviation: service.attributes?.deviation || undefined,
            state: service.attributes?.state || "",
            created: service.attributes?.["created-at"]
              ? getFormattedDate(new Date(service.attributes["created-at"]))
              : "",
          },
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    services,
    isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListServices;
