import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import FormTextField from "@components/Forms/FormTextField";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import DisabledTextField from "@components/Forms/DisabledTextField";
import "./Governance.css";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import {
  splitPascalCase,
  snakeToPascalCase,
  toSnakeCase,
  formatUnprocessibleResponse,
  renderResourceOptions,
  renderResourceName,
} from "@utils/helperFunctions";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Select2 from "@components/Forms/Select2/Select2";
import useUnloadWarning from "@utils/customHooks/useUnloadWarning";
import { findResourceByNameAndReturnPromise } from "../../../utils/helperFunctions";

function Governance({
  account_slug,
  project_id,
  projectStatus,
  governances,
  resources,
  projectResources,
  totalEffort,
  governanceId,
  allocationMethods,
  calculationTypes,
  phases,
  account_id,
  setGovernanceUpdated,
  setGovernanceCreated,
  rateType,
  authorizationCode,
  lobs,
  isResourcesFromRates,
  rateTableId,
}) {
  const [calculationType, setCalculationType] = useState("percent_of_total");
  const [hours, setHours] = useState("0.0");
  const [resource, setResource] = useState({
    value: -1,
    label: "Pick Resource",
  });
  const [filteredResources, setFilteredResources] = useState([]);
  const [newResourceId, setNewResourceId] = useState(null);
  const [rate, setRate] = useState("0.0");
  const [fixedHours, setFixedHours] = useState("0.0");
  const [fixedHoursDisabled, setFixedHoursDisabled] = useState(true);
  const [percentDisabled, setPercentDisabled] = useState(false);
  const [phase, setPhase] = useState("Pick Project Phase");
  const [newPhaseId, setNewPhaseId] = useState(null);
  const [description, setDescription] = useState("");
  const [allocationMethod, setAllocationMethod] = useState(
    "Prorate Phases By Effort"
  );
  const [effortAssignment, setEffortAssignment] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [selectedLob, setSelectedLob] = useState("Pick a Service Category");
  const [lobId, setLobId] = useState(null);
  const [isFormDirty, setFormDirty] = useState(false);

  const filterTypes = [
    { value: "all_services", key: "all_services", label: "All Services" },
    { value: "Phase", key: "filter_by_phase", label: "Filter by Phase" },
    { value: "Section", key: "filter_by_lob", label: "Filter by Category" },
  ];
  const [filterType, setFilterType] = useState("all_services");
  const [selectedFilterType, setSelectedFilterType] = useState(filterTypes[0]);
  const [filterName, setFilterName] = useState(null);
  const [filterId, setFilterId] = useState(null);

  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  let navigate = useNavigate();
  useUnloadWarning(isFormDirty);

  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  const renderLobName = (lob) => {
    if (lob.attributes["has-parent?"])
      return "- " + lob.attributes["nested-name"];
    return lob.attributes.name;
  };

  const renderLobOptions = () => {
    let lobsWithCategories = [];
    if (lobs !== undefined) {
      lobs.forEach((lob) => {
        if (lob && lob.attributes.active) {
          lobsWithCategories.push(lob);
          if (lob.categories) {
            lob.categories.forEach((cat) => {
              if (cat.attributes.active) lobsWithCategories.push(cat);
            });
          }
        }
      });
    }

    return lobsWithCategories.map((lob) => (
      <option accessKey={lob.id} key={lob.id}>
        {renderLobName(lob)}
      </option>
    ));
  };

  useEffect(() => {
    if (isResourcesFromRates) {
      setFilteredResources(resources);
    } else {
      setFilteredResources(
        resources.filter((resource) => resource.attributes.active === true)
      );
    }

    // If we are on the edit governance page, set the states accordingly if the governance id matches what was passed from the project-governance table row click through mspa parent state (passed through prop drill)
    if (lastWordInLocation === "edit") {
      let selectedFilterType = filterTypes[0];

      for (let i = 0; i < governances.length; i++) {
        if (governances[i].id === id) {
          // Set Hours
          const calcType = governances[i].attributes["calculation-type"];
          if (calcType === "percent_of_total") {
            setHours(parseFloat(governances[i].attributes.hours).toFixed(2));
            setCalculationType("percent_of_total");
            setPercentDisabled(false);
            setFixedHoursDisabled(true);
            selectedFilterType = filterTypes.filter((item) => {
              return item.key === governances[i].attributes["filter-type"];
            })[0];
            setFilterId(governances[i].attributes["filter-id"]);
          } else if (calcType === "fixed_hours") {
            setHours(parseFloat(governances[i].attributes.hours).toFixed(2));
            setFixedHours(governances[i].attributes["fixed-hours"]);
            setCalculationType("fixed_hours");
            setPercentDisabled(true);
            setFixedHoursDisabled(false);
          }

          setSelectedFilterType(selectedFilterType);
          setFilterType(selectedFilterType.value);

          // Set description
          setDescription(governances[i].attributes.description);

          // Set Rate
          setRate(governances[i].attributes.rate);

          //  Set allocation
          let allocationMethod = governances[i].attributes["allocation-method"];
          setAllocationMethod(
            splitPascalCase(snakeToPascalCase(allocationMethod))
          );

          // Prepare to set the appropriate filter dropdown below
          let filterId = governances[i].attributes["filter-id"];

          // Match Lob relationship
          lobs.forEach((currentLob) => {
            let lobData =
              governances[i].relationships["service-category"] &&
              governances[i].relationships["service-category"].data
                ? governances[i].relationships["service-category"].data
                : null;
            if (lobData) {
              if (lobData.id == currentLob.id) {
                setSelectedLob(renderLobName(currentLob));
                setLobId(currentLob.id);
              } else {
                if (currentLob.categories) {
                  currentLob.categories.forEach((cat) => {
                    if (cat.id == lobData.id)
                      setSelectedLob(renderLobName(cat));
                    setLobId(lobData.id);
                  });
                }
              }
            }
            if (selectedFilterType.value === "Section" && filterId) {
              if (filterId.toString() === currentLob.id) {
                setFilterName(renderLobName(currentLob));
              } else {
                if (currentLob.categories) {
                  currentLob.categories.forEach((cat) => {
                    if (cat.id == filterId.toString())
                      setFilterName(renderLobName(cat));
                  });
                }
              }
            }
          });

          // Set effort assignment
          let assignmentMethod = governances[i].attributes[
            "assign-effort-to-service"
          ]
            ? governances[i].attributes["assign-effort-to-service"]
            : false;
          setEffortAssignment(assignmentMethod);

          let phase =
            governances[i].relationships["project-phase"] &&
            governances[i].relationships["project-phase"].data
              ? governances[i].relationships["project-phase"].data
              : null;
          phases.forEach((currentPhase) => {
            if (allocationMethod === "select_phase" && phases && phase) {
              let phaseId = phase.id;
              if (phaseId == currentPhase.id) {
                setPhase(currentPhase.attributes.name);
                setNewPhaseId(phaseId);
              }
            }
            if (
              selectedFilterType.value === "Phase" &&
              filterId &&
              filterId.toString() === currentPhase.id
            ) {
              setFilterName(currentPhase.attributes.name);
            }
          });

          // Iterate over resources and match them with the governance based on id
          let resourceRel = governances[i].relationships["project-resource"];
          let identified = false;
          for (let i = 0; i < projectResources.length; i++) {
            if (
              resourceRel &&
              resourceRel.data &&
              resourceRel.data.id == projectResources[i].id
            ) {
              identified = true;
              setResource({
                value: projectResources[i].id,
                label: renderResourceName(projectResources[i], lobs),
              });
            }
          }

          if (!identified) {
            let resourceId =
              governances[i].relationships.resource &&
              governances[i].relationships.resource.data
                ? governances[i].relationships.resource.data.id
                : null;
            for (let i = 0; i < resources.length; i++) {
              let attr = resources[i].attributes;
              let rel = resources[i].relationships;
              if (resourceId == resources[i].id) {
                setResource({
                  value: resources[i].id,
                  label: renderResourceName(resources[i], lobs),
                });
              }
            }
          }
        }
      }
    }
  }, [
    governances,
    phases,
    resources,
    projectResources,
    lobs,
    isResourcesFromRates,
  ]);

  const redirectToProjectGovernance = () => {
    navigate(`/projects/${project_id}/project_governances/`);
    window.location.reload();
  };

  const getErrorMessageForValidation = (alertType) => {
    return `Could not create Project management details. ${alertType} must exist`;
  };

  const validateForm = () => {
    var isValid = true;
    if (resource.label === "Pick Resource" || newResourceId === -1) {
      isValid = false;
      setErrorMessages(getErrorMessageForValidation("Resource"));
      setShowFailAlert(true);
    }
    if (
      (allocationMethod === "Select Phase" &&
        (newPhaseId === null || newPhaseId == 0)) ||
      (filterType === "Phase" && !filterName)
    ) {
      isValid = false;
      setErrorMessages(getErrorMessageForValidation("Phase"));
      setShowFailAlert(true);
    }
    if (
      (allocationMethod === "Select Category" &&
        (lobId === null || lobId == 0)) ||
      (filterType === "Section" && !filterName)
    ) {
      isValid = false;
      setErrorMessages(getErrorMessageForValidation("Category"));
      setShowFailAlert(true);
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormDirty(false);

    const formIsValid = validateForm();
    if (formIsValid === false) {
      return;
    }

    const setResourceRelationship = (governanceData) => {
      if (newResourceId !== null || 0) {
        if (newResourceId.includes("resources")) {
          governanceData.data.relationships.resource = {
            data: { type: "resources", id: newResourceId.split("-")[1] },
          };
        }
        if (newResourceId.includes("rate")) {
          governanceData.data.relationships["resource-rate"] = {
            data: { type: "resource-rates", id: newResourceId.split("-")[2] },
          };
        }
        if (newResourceId.includes("project")) {
          delete governanceData.data.relationships.resource;
          governanceData.data.relationships["project-resource"] = {
            data: {
              type: "project-resources",
              id: newResourceId.split("-")[2],
            },
          };
        }
      }
    };

    const getPhase = () => {
      if (newPhaseId !== null || 0) {
        return { data: { type: "project-phases", id: newPhaseId } };
      }
    };

    const getServiceCategory = () => {
      if (lobId !== null || 0) {
        return { data: { type: "service-categories", id: lobId } };
      }
    };

    if (lastWordInLocation === "edit") {
      let updatedGovernanceData = {
        data: {
          type: "project-governances",
          id: id,
          attributes: {
            description: description,
            rate: rate,
            "fixed-hours": fixedHours,
            "calculation-type": calculationType,
            "allocation-method": toSnakeCase(allocationMethod),
            "assign-effort-to-service": effortAssignment,
            "filter-type": filterType,
            "filter-id": filterId,
          },
          relationships: {
            project: { data: { type: "projects", id: project_id } },
            "project-phase": getPhase(),
            "service-category": getServiceCategory(),
          },
        },
      };

      setResourceRelationship(updatedGovernanceData);

      API.Patch(
        `${apiHost}/${account_slug}/v1/project-governances/${id}`,
        updatedGovernanceData,
        authorizationCode
      )
        .then((response) => {
          setGovernanceUpdated(true);
          setUpdatedAlertIsOpen(true);
          const data = response.data.data;
          setHours(parseFloat(data.attributes.hours).toFixed(2));
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "governance"));
          setShowFailAlert(true);
        });
    }

    if (lastWordInLocation === "new") {
      let newGovernanceData = {
        data: {
          type: "project-governances",
          attributes: {
            description: description,
            rate: rate,
            "fixed-hours": fixedHours,
            "calculation-type": calculationType,
            "allocation-method": toSnakeCase(allocationMethod),
            "filter-type": filterType,
            "filter-id": filterId,
            "assign-effort-to-service": effortAssignment,
          },
          relationships: {
            project: { data: { type: "projects", id: project_id } },
            "project-phase": getPhase(),
            "service-category": getServiceCategory(),
          },
        },
      };

      setResourceRelationship(newGovernanceData);

      API.Post(
        `${apiHost}/${account_slug}/v1/project-governances`,
        newGovernanceData,
        authorizationCode
      )
        .then((response) => {
          setGovernanceCreated(true);
          redirectToProjectGovernance();
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "governance"));
          setShowFailAlert(true);
        });
    }
  };
  const handleFormChange = (event) => {
    setFormDirty(true);
  };

  const detailsUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="governanceAlerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Project management details updated"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleFailAlert()}
      {detailsUpdatedAlert()}
      <Card>
        <CardHeader title="Project Governance" />
        <Card.Body>
          <Form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Row>
              <Col xs={6}>
                <FormTextField
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  label="Description *"
                />
              </Col>
              <Col xs={3}>
                <Select2
                  label="Resource *"
                  className="basic-single"
                  value={resource}
                  onInputChange={(e) => {
                    if (e !== "") {
                      findResourceByNameAndReturnPromise(
                        resources[0].type,
                        e,
                        account_slug,
                        authorizationCode,
                        `resource,rate-table,line-of-business&filter[rate-table]=${rateTableId}`
                      ).then((response) => {
                        if (response.data.data) {
                          let newResources = response.data.data;
                          if (newResources[0].type == "resources") {
                            setFilteredResources(
                              newResources.filter(
                                (res) => res.attributes.active
                              )
                            );
                          } else {
                            let resourcesToMatch =
                              response.data.included.filter(
                                (item) =>
                                  item.type == "resources" &&
                                  item.attributes.active
                              );
                            let resourceRatesToReturn = new Set();
                            newResources.forEach((resourceRate) => {
                              resourcesToMatch.forEach((resource) => {
                                if (
                                  resource.id ==
                                  resourceRate.relationships.resource.data.id
                                ) {
                                  resourceRatesToReturn.add(resourceRate);
                                }
                              });
                            });
                            setFilteredResources(
                              Array.from(resourceRatesToReturn)
                            );
                          }
                        }
                      });
                    }
                  }}
                  onChange={(e) => {
                    setResource({
                      value: e.value,
                      label: e.label,
                    });
                    setNewResourceId(e.value);
                    setShowFailAlert(false);
                  }}
                  options={renderResourceOptions(
                    projectResources,
                    filteredResources,
                    lobs
                  )}
                />
              </Col>
            </Row>
            <section>
              <h3 style={{ margin: "1em 0 0.25em" }}>
                Set Fixed Governance Effort
              </h3>
              <hr style={{ margin: "0 0 1em" }} />
              <Row>
                <Col xs={3}>
                  <Form.Check
                    type="radio"
                    id="fixedHoursCheck"
                    label={`Fixed Number of ${rateType}`}
                    name="group1"
                    checked={calculationType === "fixed_hours"}
                    value="fixed_hours"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setCalculationType(e.target.value);
                        setFixedHoursDisabled(false);
                        setPercentDisabled(true);
                        setFilterId(null);
                        setFilterType("all_services");
                      }
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Control
                    disabled={fixedHoursDisabled}
                    value={fixedHours}
                    onChange={(e) => setFixedHours(e.target.value)}
                    id="fixedHours"
                  />
                </Col>
              </Row>
            </section>

            <section>
              <h3 style={{ margin: "1em 0 0.25em" }}>
                Calculate Governance Effort
              </h3>
              <hr style={{ margin: "0 0 1em" }} />
              <Row>
                <Col xs={2}>
                  <Form.Check
                    type="radio"
                    id="percentOfTotalHoursCheck"
                    label={`Percent of Total ${rateType}`}
                    name="group1"
                    checked={calculationType === "percent_of_total"}
                    value="percent_of_total"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        if (lastWordInLocation === "edit") {
                          for (let i = 0; i < governances.length; i++) {
                            if (governances[i].id === id) {
                              setHours(
                                parseFloat(
                                  governances[i].attributes.hours
                                ).toFixed(2)
                              );
                            }
                          }
                        }
                        setCalculationType(e.target.value);
                        setPercentDisabled(false);
                        setFixedHoursDisabled(true);
                      }
                    }}
                  />
                </Col>
                <Col xs={4}>
                  <Form.Control
                    min="0"
                    max="1"
                    step=".01"
                    type="range"
                    value={rate}
                    onChange={(e) => {
                      setRate(e.target.value);
                    }}
                    id="governanceRate"
                    disabled={percentDisabled}
                  />
                </Col>
                <Col xs={1}>{(parseFloat(rate) * 100).toFixed() + " %"}</Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <Select2
                    label="Which Services Impact Governance"
                    value={selectedFilterType}
                    defaultValue={selectedFilterType}
                    disabled={calculationType === "fixed_hours"}
                    options={filterTypes}
                    onChange={(e) => {
                      setSelectedFilterType({ value: e.value, label: e.label });
                      setFilterType(e.value);
                      setShowFailAlert(false);
                    }}
                  />
                </Col>
                <Col xs={3}>
                  {filterType === "Phase" ? (
                    <ControlledDropDown
                      label="Pick Phase *"
                      value={filterName}
                      disabled={filterType !== "Phase"}
                      onChange={(e) => {
                        setFilterName(e.target.value);
                        let selected = e.target.options.selectedIndex;
                        setFilterId(e.target.options[selected].accessKey);
                        setShowFailAlert(false);
                      }}
                      options={[
                        <option accessKey={0} key={0}>
                          Pick Project Phase
                        </option>,
                        phases.map((phase) => {
                          return (
                            <option accessKey={phase.id} key={phase.id}>
                              {phase.attributes.name}
                            </option>
                          );
                        }),
                      ]}
                    />
                  ) : null}

                  {filterType === "Section" ? (
                    <ControlledDropDown
                      label="Pick Category *"
                      value={filterName}
                      options={[
                        <option accessKey={0} key={0}>
                          Pick Category
                        </option>,
                        renderLobOptions(),
                      ]}
                      onChange={(e) => {
                        setFilterName(e.target.value);
                        let selected = e.target.options.selectedIndex;
                        setFilterId(e.target.options[selected].accessKey);
                        setShowFailAlert(false);
                      }}
                    />
                  ) : null}
                </Col>
              </Row>
            </section>

            <section>
              <h3 style={{ margin: "1em 0 0.25em" }}>
                Assign Governance Effort to Project
              </h3>
              <hr style={{ margin: "0 0 1em" }} />
              <Row>
                <Col xs={3}>
                  <ControlledDropDown
                    label="Allocate Governance by"
                    value={allocationMethod}
                    options={allocationMethods.map((method) => {
                      return (
                        <option value={method.attributes.name} key={method.id}>
                          {method.attributes.name}
                        </option>
                      );
                    })}
                    onChange={(e) => {
                      setAllocationMethod(e.target.value);
                      setShowFailAlert(false);
                    }}
                  />
                </Col>
                {allocationMethod === "Select Phase" ||
                allocationMethod === "Select Category" ? (
                  <Col xs={3}>
                    {allocationMethod === "Select Phase" ? (
                      <ControlledDropDown
                        label="Phase *"
                        value={
                          allocationMethod !== "Select Phase"
                            ? "Pick Project Phase"
                            : phase
                        }
                        disabled={
                          allocationMethod === "Select Phase" ? false : true
                        }
                        onChange={(e) => {
                          setPhase(e.target.value);
                          let selected = e.target.options.selectedIndex;
                          setNewPhaseId(e.target.options[selected].accessKey);
                          setShowFailAlert(false);
                        }}
                        options={[
                          <option accessKey={0} key={0}>
                            Pick Project Phase
                          </option>,
                          phases.map((phase) => {
                            return (
                              <option accessKey={phase.id} key={phase.id}>
                                {phase.attributes.name}
                              </option>
                            );
                          }),
                        ]}
                      />
                    ) : null}
                    {allocationMethod === "Select Category" ? (
                      <ControlledDropDown
                        label="Category *"
                        value={selectedLob}
                        options={[
                          <option accessKey={0} key={0}>
                            Pick Service Category
                          </option>,
                          renderLobOptions(),
                        ]}
                        onChange={(e) => {
                          setSelectedLob(e.target.value);
                          let selected = e.target.options.selectedIndex;
                          setLobId(e.target.options[selected].accessKey);
                          setShowFailAlert(false);
                        }}
                      />
                    ) : null}
                  </Col>
                ) : null}
                <Col xs={3} style={{ paddingTop: "2.6em" }}>
                  <Form.Check
                    label="Assign Effort to Service?"
                    onChange={(e) => {
                      setEffortAssignment(e.target.checked);
                    }}
                    checked={effortAssignment}
                  />
                  <br />
                  <small>
                    When checked, the effort and pricing from this governance
                    will be added to the services on the project rather than be
                    reported separately.
                  </small>
                </Col>
              </Row>
            </section>
            <Row>
              <Col xs={6}>
                {projectStatus === "building" && <SubmitButton />}
              </Col>
              <Col xs={6}>
                <BackButton
                  url={`/projects/${project_id}/project_governances`}
                />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Governance;
