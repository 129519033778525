import {
  GetTeamApiArg,
  V1PagingMeta,
  V1Relationships,
  useGetTeamQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Team } from "../../common/types";

const useGetTeam = (projectId) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } = useGetTeamQuery({
    slug: accountSlug,
    id: Number(projectId),
    include: ["users"],
  });

  let team: Team | null = null;
  let customError: string | null = null;

  if (data?.data) {
    try {
      team = {
        id: data.data.id,
        createdAt: data.data.attributes["created-at"],
        name: data.data.attributes.name,
        users: data.included
          ? data.included
              .filter((item) => item.type == "users")
              .map((u) => {
                return {
                  id: String(u.id),
                  name: u.attributes?.name ? u.attributes?.name : "",
                };
              })
          : [],
        services: data.data.attributes.services
          ? data.data.attributes.services.map((s) => {
              return {
                id: s.id || "",
                name: s.name || "",
                category: s["service-category"] || "",
                phase: s.phase || "",
              };
            })
          : [],
        questionnaires: data.data.attributes.questionnaires
          ? data.data.attributes.questionnaires.map((s) => {
              return {
                id: s.id || "",
                name: s.name || "",
                description: s.introduction || "",
              };
            })
          : [],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return { data: team, error: error || customError, isLoading, refetch };
};

export default useGetTeam;
