import React from "react";
import PaymentCredit from "../PaymentCredit/PaymentCredit";

function NewPaymentCredit({
  account_slug,
  project_id,
  account_id,
  projectStatus,
  projectCredits,
  setPaymentCreditCreated,
  paymentCreditCreated,
  paymentCreditUpdated,
  currencyUnit,
  paymentCredits,
  authorizationCode,
}) {
  return (
    <PaymentCredit
      account_slug={account_slug}
      project_id={project_id}
      account_id={account_id}
      projectStatus={projectStatus}
      projectCredits={projectCredits}
      setPaymentCreditCreated={setPaymentCreditCreated}
      paymentCreditCreated={paymentCreditCreated}
      paymentCreditUpdated={paymentCreditUpdated}
      currencyUnit={currencyUnit}
      paymentCredits={paymentCredits}
      authorizationCode={authorizationCode}
    />
  );
}

export default NewPaymentCredit;
