import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./NavTabs.css";

function NavTabs({
  tabOneText,
  tabOneUrl,
  tabTwoText,
  tabTwoUrl,
  tabThreeText,
  tabThreeUrl,
  tabFourText,
  tabFourUrl,
  hasUnsavedChanges,
}) {
  const location = window.location.pathname;
  let navigate = useNavigate();

  useEffect(() => {}, [hasUnsavedChanges]);

  const unSavedChangesMessage =
    "You have unsaved changes on this page. Click OK to continue or Cancel to stay and submit.";

  return (
    <div className="navbar">
      <ul className="nav nav-tabs">
        {tabOneText ? (
          <li
            className={`nav-item list-group-item d-flex ${
              location === tabOneUrl ? "active" : "inactive"
            }`}
            onClick={() => {
              if (hasUnsavedChanges) {
                let userRes = confirm(unSavedChangesMessage);
                if (userRes) {
                  return navigate(tabOneUrl);
                }
              }
            }}
          >
            <a
              style={hasUnsavedChanges ? { pointerEvents: "none" } : null}
              className="flex-fill"
              href={tabOneUrl}
            >
              {tabOneText}
            </a>
          </li>
        ) : null}
        {tabTwoText ? (
          <li
            className={`nav-item list-group-item d-flex ${
              location === tabTwoUrl ? "active" : "inactive"
            }`}
            onClick={() => {
              if (hasUnsavedChanges) {
                let userRes = confirm(unSavedChangesMessage);
                if (userRes) {
                  return navigate(tabTwoUrl);
                }
              }
            }}
          >
            <a
              style={hasUnsavedChanges ? { pointerEvents: "none" } : null}
              className="flex-fill"
              href={tabTwoUrl}
            >
              {tabTwoText}
            </a>
          </li>
        ) : null}
        {tabThreeText ? (
          <li
            className={`nav-item list-group-item d-flex ${
              location === tabThreeUrl ? "active" : "inactive"
            }`}
            onClick={() => {
              if (hasUnsavedChanges) {
                let userRes = confirm(unSavedChangesMessage);
                if (userRes) {
                  return navigate(tabThreeUrl);
                }
              }
            }}
          >
            <a
              style={hasUnsavedChanges ? { pointerEvents: "none" } : null}
              className="flex-fill"
              href={tabThreeUrl}
            >
              {tabThreeText}
            </a>
          </li>
        ) : null}
        {tabFourText ? (
          <li
            className={`nav-item list-group-item d-flex ${
              location === tabFourUrl ? "active" : "inactive"
            }`}
            onClick={() => {
              if (hasUnsavedChanges) {
                let userRes = confirm(unSavedChangesMessage);
                if (userRes) {
                  return navigate(tabFourUrl);
                }
              }
            }}
          >
            <a
              style={hasUnsavedChanges ? { pointerEvents: "none" } : null}
              className="flex-fill"
              href={tabFourUrl}
            >
              {tabFourText}
            </a>
          </li>
        ) : null}
      </ul>
    </div>
  );
}

export default NavTabs;
