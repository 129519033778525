import { useEffect, useState } from "react";
import DataTable from "@components/DataTable";
import useListFavoriteProducts from "./api";
import { Product } from "../../types";
import { ColumnDef } from "@tanstack/react-table";
import CurrencyFormat from "react-currency-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { useGetAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useDeleteUserFavoriteMutation } from "@generated";
import ProductSlideout from "../../common/ProductSlideOut";

type FavoriteProductsProps = {
  setFavoriteProductCount: (count: number) => void;
  triggerRefetch: boolean;
  setTriggerRefetch: (trigger: boolean) => void;
  setFavoriteProductIds: (
    favorites: {
      favoriteId: string;
      sourceKey: string;
    }[]
  ) => void;
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
};

const FavoriteProducts = ({
  setFavoriteProductCount,
  triggerRefetch,
  setTriggerRefetch,
  setFavoriteProductIds,
  selectedIds,
  setSelectedIds,
}: FavoriteProductsProps): JSX.Element => {
  const { products, isLoading, refetch } = useListFavoriteProducts();
  const [deleteUserFavorite] = useDeleteUserFavoriteMutation();

  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data: account } = useGetAccountQuery({ slug: accountSlug });
  const [slideoutOpen, setSlideoutOpen] = useState<boolean>(false);
  const [productToPreview, setProductToPreview] =
    useState<Product | null>(null);

  const currencyUnit =
    account?.data?.attributes?.["field-labels"]?.["currency_unit"] ?? "$";

  const columns: ColumnDef<Product>[] = [
    {
      header: "Name",
      cell(props) {
        return (
          <div className="displayFlex">
            {props.row.original.name}{" "}
            {props.row.original.name !== "No Favorited Products found" && (
              <FontAwesomeIcon
                className="productPreview"
                icon={faEye}
                onClick={() => {
                  const productSelected = products.find(
                    (product) =>
                      String(product.id) === String(props.row.original.id)
                  );
                  if (productSelected) {
                    setProductToPreview(productSelected);
                    setSlideoutOpen(true);
                  }
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      header: "MFR Part",
      cell(props) {
        return <>{props.row.original.mfrPartNumber}</>;
      },
    },
    {
      header: "SKU",
      cell(props) {
        return <>{props.row.original.sku}</>;
      },
    },
    {
      header: "Int Prod Id",
      cell(props) {
        return <>{props.row.original.productId}</>;
      },
    },
    {
      header: "Units",
      cell(props) {
        return <>{props.row.original.units}</>;
      },
    },
    {
      header: "Category",
      cell(props) {
        return <>{props.row.original.category}</>;
      },
    },
    {
      header: "Unit Price",
      cell(props) {
        return (
          <>
            {props.row.original.name !== "No Favorited Products found" && (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString
                thousandSeparator
                value={(Number(props.row.original.price) || 0).toFixed(2)}
              />
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setFavoriteProductCount(products?.length ?? 0);
    setFavoriteProductIds(
      products
        ?.map((product) => {
          return {
            favoriteId: product.id || "", // here, product.id is coming from user favorites, so its actually a favorite id
            sourceKey: product.sourceKey || "",
          };
        })
        .filter(
          (key): key is { favoriteId: string; sourceKey: string } =>
            key !== undefined
        ) ?? []
    );
  }, [products]);

  useEffect(() => {
    if (triggerRefetch) {
      refetch();
      setTriggerRefetch(false);
    }
  }, [triggerRefetch, setTriggerRefetch, refetch]);

  const handleFavoriteClick = (resource) => {
    deleteUserFavorite({
      slug: accountSlug,
      id: resource.id,
    });

    setTriggerRefetch(true);
  };

  return (
    <div>
      <ProductSlideout
        product={productToPreview}
        slideoutOpen={slideoutOpen}
        setSlideoutOpen={setSlideoutOpen}
      />
      {isLoading ? (
        <ScopeStackSpinner />
      ) : (
        <DataTable
          data={
            products?.length > 0
              ? products
              : [
                  {
                    id: 0,
                    source: "standard",
                    name: "No Favorited Products found",
                    vendor: "",
                    mfrPartNumber: "",
                    productId: "",
                    units: "",
                    price: "",
                    isFavorited: false,
                  },
                ]
          }
          columns={columns}
          favoriteable={products?.length > 0 ? true : false}
          onFavoriteClick={handleFavoriteClick}
          striped
          hover
          selectable={products?.length > 0}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      )}
    </div>
  );
};

export default FavoriteProducts;
