import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import NavTabs from "@components/NavTabs/NavTabs";
import PricingTable from "../PricingTable/PricingTable";
import API from "@API";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import {
  getTotalRow,
  getServiceTotalRow,
  arrangeDataForTable,
} from "../../pricingUtils/pricingHelpers";

function PricingByService({
  account_slug,
  project_id,
  phases,
  lobs,
  resources,
  projectLocations,
  governances,
  currencyUnit,
  projectPricings,
  projectStatus,
  oneTimeAdjustment,
  isLoading,
  rateType,
  authorizationCode,
}) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [services, setServices] = useState([]);
  const [subservices, setSubservices] = useState([]);
  const [pricingServices, setPricingServices] = useState([]);
  const [pricingSubservices, setPricingSubservices] = useState([]);
  const [phasesWithServices, setPhasesWithServices] = useState([]);
  const [governancePricings, setGovernancePricings] = useState([]);

  useEffect(() => {
    // Set governancePricing items for governance cards
    setGovernancePricings(
      projectPricings.filter(
        (pricing) => pricing.id.split("-")[1] === "governances"
      )
    );

    // Set State for pricing services and subservices
    let serviceProjectPricings = projectPricings.filter((service) =>
      service.id.toString().includes("project-services")
    );
    let subserviceProjectPricings = projectPricings.filter((service) =>
      service.id.toString().includes("project-subservices")
    );
    setPricingServices(serviceProjectPricings);
    setPricingSubservices(subserviceProjectPricings);

    // Set state for phases with services so a phase card without services does not render
    let phasesWithServices = new Set();
    phases.forEach((phase) => {
      serviceProjectPricings
        .concat(subserviceProjectPricings)
        .forEach((service) => {
          if (
            service.attributes.phase &&
            service.attributes.phase.name === phase.attributes.name
          ) {
            phasesWithServices.add(phase);
          }
        });
    });
    setPhasesWithServices(Array.from(phasesWithServices));

    if (authorizationCode !== "") {
      //Get services with included data & use to set state
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-services?filter[service-type]=professional_services&include=project-location,lob,project-phase,resource,project-subservices,project-subservices.resource`,
        authorizationCode
      ).then((response) => {
        let includedData = response.data.included;
        let subservices = [];
        if (includedData) {
          for (let i = 0; i < includedData.length; i++) {
            if (includedData[i].type === "project-subservices") {
              subservices.push(includedData[i]);
            }
          }
        }
        setSubservices(subservices);
        setServices(response.data.data);
      });
    }
  }, [
    authorizationCode,
    currencyUnit,
    resources,
    governances,
    projectPricings,
    phases,
    projectStatus,
    isLoading,
  ]);

  const arrangeServiceDataForTable = (array) => {
    let totRevenue = "";
    let totCost = "";
    let totHours = "";
    return array.map((item) => {
      let serviceName = "";

      if (item.id.split("-")[1] == "services") {
        totRevenue = parseFloat(item.attributes["contract-revenue"]);
        totCost = parseFloat(item.attributes["service-cost"]);
        totHours = parseFloat(item.attributes["extended-hours"]);
      }

      if (item.id.split("-")[1] == "subservices") {
        totRevenue += parseFloat(item.attributes["contract-revenue"]);
        totCost += parseFloat(item.attributes["service-cost"]);
        totHours += parseFloat(item.attributes["extended-hours"]);
      }

      if (item.type == "serviceTotal" || item.type == "serviceTotalHide") {
        return {
          type:
            item.type == "serviceTotal"
              ? "serviceTotalRow"
              : "hiddenServiceTotalRow",
          label: `Total: ${item.name}`,
          totalHours: totHours,
          totalRevenue: totRevenue,
          totalCost: totCost,
          totalGrossProfit: parseFloat(totRevenue - totCost),
        };
      }

      if (item.type !== "serviceTotal" && item.type !== "serviceTotalHide") {
        services.concat(subservices).forEach((service) => {
          if (service.id == item.attributes["source-id"]) {
            serviceName = service.attributes.name;
          }
        });

        let hours = parseFloat(item.attributes["extended-hours"]);
        let revenue = parseFloat(item.attributes["service-revenue"]);
        let cost = parseFloat(item.attributes["service-cost"]);

        let effectiveCost = parseFloat(cost) / parseFloat(hours);

        let rate = parseFloat(revenue) / parseFloat(hours);

        let totalCost = cost;
        let grossProfit = parseFloat(revenue) - parseFloat(cost);
        return {
          resource:
            item.id.split("-")[1] == "subservices" ? (
              <span style={{ display: "block", paddingLeft: "2em" }}>
                - {serviceName}
              </span>
            ) : (
              <span>{serviceName}</span>
            ),
          hours: hours,
          rate: rate,
          revenue: revenue,
          effectiveCost: effectiveCost,
          totalCost: totalCost,
          grossProfit: grossProfit,
        };
      }
    });
  };

  let governanceTableData = arrangeDataForTable(
    governancePricings,
    resources,
    lobs
  ).filter((item) => item.revenue !== 0 || item.totalCost !== 0);

  governanceTableData.push(getTotalRow(governanceTableData, oneTimeAdjustment));

  return (
    <>
      <NavTabs
        tabOneText="By LOB"
        tabOneUrl={`/projects/${project_id}/sow_pricings`}
        tabTwoText="By Phase"
        tabTwoUrl={`/projects/${project_id}/sow_pricings/phase`}
        tabThreeText="By Service"
        tabThreeUrl={`/projects/${project_id}/sow_pricings/service`}
        tabFourText={projectLocations.length >= 2 ? "By Location" : null}
        tabFourUrl={`/projects/${project_id}/sow_pricings/locations`}
      />
      {isLoading ? (
        <div className="col-sm-12" id="sow-workarea">
          <div style={{ marginTop: "20px" }}>
            <ScopeStackSpinner />
          </div>
        </div>
      ) : (
        <div className="col-sm-12" id="sow-workarea">
          {phasesWithServices.map((phase) => {
            let phaseName = phase.attributes.name;

            let pricingsPerPhase = projectPricings.filter(
              (service) =>
                service.attributes.phase !== null &&
                service.attributes.phase.name === phaseName
            );

            let serviceList = [];
            let serviceName = "";
            services.forEach((service) => {
              serviceList.push(service);
              serviceName = service.attributes.name;
              let subserviceCount = 0;
              subservices.forEach((subservice) => {
                let serviceRelData =
                  subservice.relationships["project-service"].data;
                if (serviceRelData && serviceRelData.id == service.id) {
                  serviceList.push(subservice);
                  subserviceCount += 1;
                }
              });
              if (subserviceCount > 0) {
                serviceList.push({
                  type: "serviceTotal",
                  name: service.attributes.name,
                  id: service.id,
                });
              } else {
                serviceList.push({
                  type: "serviceTotalHide",
                  name: service.attributes.name,
                  id: service.id,
                });
              }
            });

            let pricingServiceList = new Set();
            serviceList.forEach((service) => {
              pricingsPerPhase.forEach((pricingService) => {
                if (
                  (service.type == "serviceTotal" ||
                    service.type == "serviceTotalHide") &&
                  pricingService.attributes["source-id"] == service.id
                ) {
                  pricingServiceList.add(service);
                }
                if (pricingService.attributes["source-id"] == service.id) {
                  pricingServiceList.add(pricingService);
                }
              });
            });
            pricingServiceList = Array.from(pricingServiceList);

            const filteredServicesPerPhase = pricingServiceList.filter(
              (item) => item.type !== "serviceTotalHide"
            );

            let phaseTableData = arrangeServiceDataForTable(
              filteredServicesPerPhase
            );

            let phaseCalcData = arrangeServiceDataForTable(pricingServiceList);

            let phaseTotalArray = phaseCalcData.filter(
              (row) =>
                row.type == "totalRow" ||
                row.type == "serviceTotalRow" ||
                row.type == "hiddenServiceTotalRow"
            );

            phaseTableData.push(
              getServiceTotalRow(
                phaseTotalArray,
                phaseName + " Total",
                "phaseTotalRow"
              )
            );

            return (
              <Card key={phase.id} className="marginCard">
                <CardHeader title={`${phase.attributes.name} Pricing`} />
                <Card.Body>
                  {isLoading ? (
                    <ScopeStackSpinner />
                  ) : (
                    <PricingTable
                      array={phaseTableData}
                      currencyUnit={currencyUnit}
                      rateType={rateType}
                      firstColumnName={"Service"}
                    />
                  )}
                </Card.Body>
              </Card>
            );
          })}
          <Card key="governanceCard" className="marginCard">
            <CardHeader title="Project Governance Pricing" />
            <Card.Body>
              <PricingTable
                array={governanceTableData}
                currencyUnit={currencyUnit}
                rateType={rateType}
              />
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
}

export default PricingByService;
