import useGetPlanName from "@common/hooks/useGetPlanName";
import { useListLandingPageContentQuery } from "@generated";

const useConditionalLandingPageContent = () => {
  const { data: landingPageContent, isLoading: isContentLoading } =
    useListLandingPageContentQuery({});

  return {
    data: landingPageContent,
    isLoading: isContentLoading,
  };
};

export default useConditionalLandingPageContent;
