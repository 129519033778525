import React from "react";
import "./CustomCardHeader.style.css";

function CustomCardHeader({ content, dividerIsFullWidth }) {
  return (
    <div className="customCardHeader">
      {content}
      <hr
        style={
          dividerIsFullWidth
            ? { marginBottom: "20px", marginTop: "20px" }
            : { margin: "20px" }
        }
      />
    </div>
  );
}

export default CustomCardHeader;
