const getUpsellText = (currentPlan: string) => {
  if (
    typeof currentPlan === "string" &&
    currentPlan.toLowerCase().includes("businesss")
  ) {
    return "<strong>Limited time offer: </strong>Try our Premium Tier Plan free for 30 days and downgrade at any time.";
  }

  if (
    typeof currentPlan === "string" &&
    currentPlan.toLowerCase().includes("essentials")
  ) {
    return "<strong>Limited time offer: </strong>Try our Business Tier Plan free for 30 days and downgrade at any time.";
  }

  if (
    typeof currentPlan === "string" &&
    currentPlan.toLowerCase().includes("free")
  ) {
    return "<strong>We’re glad to have you! </strong>As a special thank you, try our Business Tier Plan free for 30 days and downgrade at any time.";
  }

  return null;
};

export default getUpsellText;
