import React, { useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import BaseTable from "@components/Tables/BaseTable/BaseTable";

function PricingTable({ array, currencyUnit, rateType, firstColumnName }) {
  useEffect(() => {}, [array, currencyUnit]);

  const getTableData = () => {
    if (array.length == 0) {
      return [];
    }

    return array.map((item, index) => {
      const getRate = () => {
        if (item.type === "priceAdjustment" || item.type === "marginRow") {
          return "";
        }
        if (
          parseFloat(item.revenue) == 0.0 ||
          parseFloat(item.hours) == 0.0 ||
          item.rate == "NaN" ||
          parseInt(item.rate) == NaN
        ) {
          return "---";
        } else {
          return (
            <CurrencyFormat
              displayType="text"
              prefix={currencyUnit}
              isNumericString={true}
              thousandSeparator={true}
              value={item.rate.toFixed(2)}
            />
          );
        }
      };

      const getEffectiveCost = () => {
        if (item.type === "priceAdjustment" || item.type === "marginRow") {
          return "";
        }
        if (
          parseFloat(item.cost) == 0 ||
          parseFloat(item.hours) == 0 ||
          item.effectiveCost == "NaN" ||
          parseInt(item.effectiveCost == NaN)
        ) {
          return "---";
        } else {
          return (
            <CurrencyFormat
              displayType="text"
              prefix={currencyUnit}
              isNumericString={true}
              thousandSeparator={true}
              value={item.effectiveCost.toFixed(2)}
            />
          );
        }
      };

      if (
        item.type == "totalRow" ||
        item.type == "phaseTotalRow" ||
        item.type == "serviceTotalRow"
      ) {
        const getTotalRowClass = () => {
          if (item.type == "phaseTotalRow") {
            return "borderTopTotalRow largeTotalRow";
          }
          if (item.type == "serviceTotalRow") {
            return "boldTotalRow";
          }
          if (item.type == "totalRow") {
            return "totalRow";
          }
        };
        return {
          rowClass: getTotalRowClass(),
          onClick: null,
          columns: [
            {
              class: "",
              name: item.label,
            },
            {
              class: "text-right",
              name: parseFloat(item.totalHours).toFixed(2),
            },
            {
              class: "text-right",
              name: "",
            },
            {
              class: "text-right",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={item.totalRevenue.toFixed(2)}
                />
              ),
            },
            {
              class: "text-right",
              name: "",
            },
            {
              class: "text-right",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={item.totalCost.toFixed(2)}
                />
              ),
            },
            {
              class: "text-right",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={item.totalGrossProfit.toFixed(2)}
                />
              ),
            },
          ],
          actions: null,
        };
      }
      // Map returns data for ScopeStackTable Class
      if (
        item.type !== "totalRow" &&
        item.type !== "phaseTotalRow" &&
        item.type !== "serviceTotalRow"
      ) {
        return {
          rowClass: "pricingRow",
          onClick: null,
          columns: [
            {
              class: "",
              name: item.resource,
            },
            {
              class: "text-right",
              name: item.hours !== 0 ? item.hours.toFixed(2) : "",
            },
            {
              class: "text-right",
              name: getRate(),
            },
            {
              class: "text-right",
              name:
                item.type === "priceAdjustment" || item.type === "marginRow" ? (
                  item.revenue
                ) : (
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={item.revenue.toFixed(2)}
                  />
                ),
            },
            {
              class: "text-right",
              name: getEffectiveCost(),
            },
            {
              class: "text-right",
              name:
                item.totalCost !== "" ? (
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={item.totalCost.toFixed(2)}
                  />
                ) : (
                  ""
                ),
            },
            {
              class: "text-right",
              name:
                item.type === "marginRow" ? (
                  <span>{item.margin ? item.margin : 0}%</span>
                ) : (
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={item.grossProfit.toFixed(2)}
                  />
                ),
            },
          ],
          actions: null,
        };
      }
    });
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: firstColumnName ? firstColumnName : "Resource",
      },
      {
        class: "text-right",
        name: rateType !== "Hours" ? rateType : "Extended Hours",
      },
      {
        class: "text-right",
        name: "Effective Rate",
      },
      {
        class: "text-right",
        name: "Revenue",
      },
      {
        class: "text-right",
        name: "Effective Cost",
      },
      {
        class: "text-right",
        name: "Total Cost",
      },
      {
        class: "text-right",
        name: "Gross Profit",
      },
    ],
  };

  const tableData = getTableData();
  const footerData = null;

  let pricingTable = new ScopeStackTable(
    "pricing",
    headerData,
    tableData,
    footerData
  );

  return (
    <BaseTable
      className="scopestack pricingTable"
      striped={true}
      hover={true}
      bordered={false}
      headerRows={pricingTable.buildHeaderRows()}
      dataRows={pricingTable.buildDataRows()}
      footerRows={pricingTable.buildFooterRows()}
    />
  );
}

export default PricingTable;
