import React from "react";
import "./StationaryModal.css";

function StationaryModal({ header, body }) {
  return (
    <div className="stationaryModalContainer">
      <div className="stationaryModalHeader">{header}</div>
      <hr />
      <div>{body}</div>
    </div>
  );
}

export default StationaryModal;
