import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import FormNumberField from "@components/Forms/FormNumberField";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import CardHeader from "@components/CardHeader/CardHeader";
import RouterButton from "@components/Buttons/RouterButton/RouterButton";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import { useNavigate } from "react-router-dom";
import "../../common.css";
import CurrencyFormat from "react-currency-format";
import {
  formatUnprocessibleResponse,
  renderResourceName,
} from "@utils/helperFunctions";

function Expense({
  account_slug,
  project_id,
  account_id,
  project,
  expenses,
  resources,
  projectResources,
  phases,
  expenseId,
  setExpenseId,
  expenseCreated,
  expenseUpdated,
  expenseCategories,
  currencyUnit,
  rateTableId,
  isLoading,
  authorizationCode,
  lobs,
  paymentTerm,
}) {
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);
  const [projectStatus, setProjectStatus] = useState("building");
  const [travelLimit, setTravelLimit] = useState("0");

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);

  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  var totals = [];

  useEffect(() => {
    if (project) {
      setProjectStatus(project.attributes.status);
      setTravelLimit(project.attributes["travel-limit"]);
    }
    if (expenseCreated === true) {
      setCreatedAlertIsOpen(true);
    }
    if (expenseUpdated === true) {
      setUpdatedAlertIsOpen(true);
    }
  }, [isLoading]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let projectAttr = {
      data: {
        id: project.id,
        type: project.type,
        attributes: {
          "travel-limit": travelLimit,
        },
      },
    };
    let projectUrl = project.links["self"];

    API.Patch(projectUrl, projectAttr, authorizationCode)
      .then((response) => {})
      .catch((err) => {});
  };

  const getTableData = () => {
    if (expenses.length === 0) {
      return [];
    }
    return expenses.map((expense) => {
      // Match up resources with the resource id listed on expense
      var resource = "";

      for (let i = 0; i < projectResources.length; i++) {
        let attr = projectResources[i].attributes;
        if (
          expense.relationships["project-resource"].data &&
          expense.relationships["project-resource"].data.id ===
            projectResources[i].id
        ) {
          resource = renderResourceName(projectResources[i], lobs);
        }
      }

      if (resource == "") {
        for (let i = 0; i < resources.length; i++) {
          let attr = resources[i].attributes;
          if (
            expense.relationships.resource.data &&
            expense.relationships.resource.data.id === resources[i].id
          ) {
            resource = renderResourceName(resources[i], lobs);
          }
        }
      }

      // Match up project phases with the phase id listed on expense
      var projectPhase = "";
      for (let i = 0; i < phases.length; i++) {
        if (
          expense.relationships["project-phase"].data &&
          expense.relationships["project-phase"].data.id === phases[i].id
        ) {
          projectPhase = phases[i].attributes.name;
        }
      }

      // Match up expense category with the expense category id listed on expense
      var expenseCategory = "";
      for (let i = 0; i < expenseCategories.length; i++) {
        if (
          expense.relationships["expense-category"].data &&
          expense.relationships["expense-category"].data.id ===
            expenseCategories[i].id
        ) {
          expenseCategory = expenseCategories[i].attributes.name;
        }
      }

      const deleteExpense = (e) => {
        e.stopPropagation();
        let userConfirms = confirm(
          "Are you sure you want to delete this expense item?"
        );
        if (userConfirms) {
          let expenseData = {
            data: {
              type: "project-expenses",
              id: expense.id,
            },
          };
          API.Delete(
            `${apiHost}/${account_slug}/v1/project-expenses/${expense.id}`,
            expenseData,
            authorizationCode
          )
            .then((response) => {
              if (response.status == 204) {
                window.location.reload();
              }
            })
            .catch((err) => {
              setErrorMessages(formatUnprocessibleResponse(err, "expense"));
              setShowFailAlert(true);
            });
        }
      };

      const renderActions = () => {
        if (projectStatus === "building") {
          return (
            <OnHoverButtonIcon
              dataConfirm="Are you sure?"
              iconClass="fa fa-remove"
              onClick={(e) => deleteExpense(e)}
              className="deleteBtn"
              buttonText="Delete"
            />
          );
        } else {
          return null;
        }
      };

      const redirectToEditExpense = () => {
        let id = expense.id;
        setExpenseId(id);
        return navigate(`/projects/${project_id}/project_expenses/${id}/edit`);
      };

      let total =
        parseFloat(expense.attributes.rate) *
        parseFloat(expense.attributes.quantity);

      totals.push(total);

      // Map returns data for ScopeStackTable Class
      return {
        rowClass: "expenseRow",
        onClick: redirectToEditExpense,
        columns: [
          {
            class: "",
            name: resource,
          },
          {
            class: "",
            name: `${expenseCategory}: ${expense.attributes.description}`,
          },
          {
            class: "",
            name: projectPhase,
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(expense.attributes.rate).toFixed(2)}
              />
            ),
          },
          {
            class: "text-right",
            name: expense.attributes.quantity,
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={total.toFixed(2)}
              />
            ),
          },
        ],
        actions: renderActions(),
      };
    });
  };

  const getTotalRow = () => {
    const sum = totals.reduce((partialSum, a) => partialSum + a, 0);
    return {
      rowClass: "totalRow",
      onClick: null,
      columns: [
        {
          class: "",
          name: "",
        },
        {
          class: "",
          name: "",
        },
        {
          class: "",
          name: "",
        },
        {
          class: "",
          name: "",
        },
        {
          class: "text-right",
          name: "Total: ",
        },
        {
          class: "text-right",
          name: `${currencyUnit}${sum.toFixed(2)}`,
        },
      ],
      actions: null,
    };
  };

  const getFooterData = () => {
    let content = "";
    if (projectStatus === "building") {
      content = (
        <RouterButton
          path={`/projects/${project_id}/project_expenses/new`}
          className="newResource"
          iconClass="fa fa-plus"
          buttonText="Add Travel/Expense"
        />
      );
    }
    return {
      rowClass: "col-12",
      columns: [{ class: "expenseFooter", content: content }],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Resource",
      },
      {
        class: "",
        name: "Expense",
      },
      {
        class: "",
        name: "Phase",
      },
      {
        class: "text-right",
        name: "Unit Cost",
      },
      {
        class: "text-right",
        name: "Quantity",
      },
      {
        class: "text-right",
        name: "Total Cost",
      },
      {
        class: "text-right",
        name: "Actions",
      },
    ],
  };

  let tableData = getTableData();
  if (tableData.length !== 0) tableData.push(getTotalRow());
  const footerData = getFooterData();

  let expenseTable = new ScopeStackTable(
    "expenses",
    headerData,
    tableData,
    footerData
  );

  const detailsCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="expenseAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Travel/Expense details created"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {detailsCreatedAlert()}
      {handleFailAlert()}
      {paymentTerm && paymentTerm.attributes["travel-limit"] && (
        <Card>
          <CardHeader title="Travel/Expense Limit" />
          <Card.Body>
            <h3>
              The Payment Term for this Project Requires a Travel/Expense limit
            </h3>
            <Form className={"row"} onSubmit={handleSubmit}>
              <Col sm={2}>
                <FormNumberField
                  label="Travel Limit"
                  required={true}
                  min={0}
                  step={0.01}
                  value={travelLimit}
                  onChange={(e) => setTravelLimit(e.target.value)}
                />
              </Col>
              <Col sm={2} style={{ marginTop: "2em" }}>
                {projectStatus === "building" && <SubmitButton />}
              </Col>
            </Form>
          </Card.Body>
        </Card>
      )}
      <Card>
        <CardHeader title="Travel/Expense" />
        <Card.Body>
          <BaseTable
            className="scopestack expenseTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={expenseTable.buildHeaderRows()}
            dataRows={expenseTable.buildDataRows()}
            footerRows={expenseTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default Expense;
