import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

function ImportButton({
  account_slug,
  project_id,
  contextId,
  contextType,
  label,
  value
}) {
  const [authenticityToken, setAuthenticityToken] = useState("");

  useEffect(() => {
    setAuthenticityToken(
      document.head.querySelector("[name~=csrf-token][content]").content
    );
  }, []);

  return (
    <Row>
      <Col>
        <div className="dropdown" style={{ marginTop: "-2em" }}>
          <Button
            variant="outline-secondary"
            className="dropdown-toggle"
            style={{ marginTop: "2em" }}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Import
          </Button>
          <div
            className="dropdown-menu"
            style={{ width: "600px", padding: "16px" }}
          >
            <Form
              className="form-vertical"
              id="new_data_import"
              padding="8px;"
              encType="multipart/form-data"
              action={`/projects/${project_id}/project_imports`}
              acceptCharset="UTF-8"
              method="post"
            >
              <Form.Control name="utf8" type="hidden" value="&#x2713;" />
              <Form.Control
                type="hidden"
                name="authenticity_token"
                value={authenticityToken}
              />
              <Row>
                <Col>
                  <Form.Group className="formGroup">
                    <Form.Label
                      className="control-label"
                      htmlFor="data_import_data_file"
                    >
                      {label}
                    </Form.Label>
                    <Form.Control
                      className="form-control"
                      type="file"
                      name="data_import[data_file]"
                      id="data_import_data_file"
                      accept="csv,.xls,.xlsx,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                    />
                    <Form.Control
                      type="hidden"
                      value={value}
                      name="data_import[import_type]"
                      id="data_import_import_type"
                    />
                    <Form.Control
                      type="hidden"
                      value={contextType}
                      name="data_import[context_type]"
                      id="data_import_context_type"
                    />
                    <Form.Control
                      type="hidden"
                      value={contextId}
                      name="data_import[context_id]"
                      id="data_import_context_id"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    type="submit"
                    name="commit"
                    variant="primary"
                    data-disable-with="Import"
                  >
                    Import
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ImportButton;
