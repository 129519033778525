import React, { useEffect, useRef } from "react";
import Select, { Props as SelectProps, SelectInstance } from "react-select";
import { Form } from "react-bootstrap";

// Define the shape of the options
interface OptionType {
  value: string;
  label: string;
}

// Define the props based on the SelectProps generic type
interface MultiSelectAutocompleteProps extends SelectProps<OptionType> {
  label: string;
  className?: string;
  width?: string;
  updateRef?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
}

const MultiSelectAutocomplete: React.FC<MultiSelectAutocompleteProps> = ({
  value,
  onChange,
  onInputChange,
  options,
  label,
  className,
  width = "100%", // Provide a default width if none is passed
  updateRef,
  placeholder,
  isDisabled,
}) => {
  // Use the SelectInstance with the correct OptionType
  const multiSelectRef = useRef<SelectInstance<OptionType>>(null);

  useEffect(() => {
    // Check if the current ref object is not null before calling methods on it
    if (updateRef && multiSelectRef.current) {
      // Clear the current value of the select input
      multiSelectRef.current.clearValue();
    }
  }, [updateRef]);

  return (
    <Form.Group className="formGroup">
      <Form.Label>{label}</Form.Label>
      <Select<OptionType>
        className={className}
        placeholder={placeholder || "Search and Select Multiple"}
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        isMulti={true as any} // This is a hack to get around a bug in react-select
        ref={updateRef ? multiSelectRef : undefined}
        isDisabled={isDisabled}
      />
    </Form.Group>
  );
};

export default MultiSelectAutocomplete;
