import React, { ReactNode } from "react";
import Select, { Props as SelectProps } from "react-select";
import { Form } from "react-bootstrap";
const errorMessage: string = require("../../../images/error-message-white.png");

// Define the shape of the options
interface OptionType {
  value: string | number;
  label: string;
}

// Define the props based on the SelectProps generic type
interface RequiredReactSelectProps extends SelectProps<OptionType> {
  label: ReactNode;
  className?: string;
  width?: string;
  updateRef?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  required?: boolean;
  value?: OptionType;
  defaultValue?: OptionType;
  isInvalid?: boolean;
  isDisabled?: boolean;
}

const RequiredReactSelect: React.FC<RequiredReactSelectProps> = ({
  value,
  onChange,
  onInputChange,
  options,
  label,
  className,
  width = "100%",
  placeholder,
  required,
  defaultValue,
  isInvalid,
  onFocus,
  isDisabled = false,
}) => {
  return (
    <Form.Group
      className="formGroup"
      style={isInvalid ? { marginBottom: "0px" } : {}}
    >
      <Form.Label>{label}</Form.Label>
      <Select<OptionType>
        className={className}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        isMulti={false as any}
        required={required}
        onFocus={onFocus}
        isDisabled={isDisabled}
      />
      {required && isInvalid ? (
        <div>
          <img
            src={errorMessage}
            alt="Please fill out this field error message"
          />
        </div>
      ) : null}
    </Form.Group>
  );
};

export default RequiredReactSelect;
