import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InfoComponent from "./InfoComponent/InfoComponent";
import API from "@utils/API/API";
import useOauth from "@utils/customHooks/useOauth";
import PhaseComponent from "./PhaseComponent/PhaseComponent";
import YoureAllSetPage from "./YoureAllSet/YoureAllSet";
import StandardsComponent from "./StandardsComponent/StandardsComponent";
import LanguageFieldsInfo from "./LanguageFields/LanguageFieldsInfo";
import LanguageFieldsEdit from "./LanguageFields/LanguageFieldsEdit";
import Resources from "./Resources/Resources";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import Governances from "./Governances/Governances";

function GuidedOnboardingMspa({ account_slug, account_id }) {
  // Env/API
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [authorizationCode] = useOauth();
  // States
  const [resources, setResources] = useState([]);
  const [resourceRates, setResourceRates] = useState([]);
  const [phases, setPhases] = useState([]);
  const [allPhases, setAllPhases] = useState([]);
  const [languageFields, setLanguageFields] = useState([]);
  const [allLanguageFields, setAllLanguageFields] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currencyUnit, setCurrencyUnit] = useState("$");
  const [rateTableId, setRateTableId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStandards, setSelectedStandards] = useState([]);

  //Toast Alerts
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [governances, setGovernances] = useState([]);
  const [reRender, setRerender] = useState(false);

  useEffect(() => {
    if (authorizationCode != "") {
      API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
        (response) => {
          let data = response.data.data;
          setCurrencyUnit(data.attributes["field-labels"].currency_unit);

          // Get phases
          API.Get(
            data.links.phases + "?filter[active]=true,false",
            authorizationCode
          ).then((phaseRes) => {
            setPhases(
              phaseRes.data.data.filter((phase) => phase.attributes.active)
            );
            setAllPhases(phaseRes.data.data);
          });

          // Get Governances
          API.Get(
            data.links.governances +
              "?include=resource&filter[active]=true,false",
            authorizationCode
          ).then((govRes) => {
            setGovernances(
              govRes.data.data.filter((gov) => gov.attributes.active)
            );
          });
        }
      );
      // Get language fields
      API.Get(
        `${apiHost}/${account_slug}/v1/language-fields?filter[active]=true,false`,
        authorizationCode
      ).then((lfRes) => {
        let lfData = lfRes.data.data;
        setLanguageFields(lfData.filter((lf) => lf.attributes.active));
        setAllLanguageFields(lfData);
      });

      // Get resources
      API.Get(
        `${apiHost}/${account_slug}/v1/resources?page[size]=1000&filter[active]=true,false`,
        authorizationCode
      ).then((resourceResponse) => {
        let resources = resourceResponse.data.data.filter(
          (resource) => resource.attributes.active
        );
        setResources(resources);

        // Get Resource Rates
        API.Get(
          `${apiHost}/${account_slug}/v1/rate-tables?filter[default]=true&include=resource-rates`,
          authorizationCode
        ).then((res) => {
          setRateTableId(res.data.data[0].id);
          let resourceRatesOnRateTable =
            res.data.data[0].relationships["resource-rates"].data;
          API.Get(
            `${apiHost}/${account_slug}/v1/resource-rates?include=resource,rate-table&page[size]=1000`,
            authorizationCode
          ).then((response) => {
            let allResourceRates = response.data.data;
            let resourceRatesToReturn = new Set();
            resourceRatesOnRateTable.forEach((resourceRateOnRateTable) => {
              allResourceRates.forEach((resourceRate) => {
                resources.forEach((resource) => {
                  if (
                    resourceRateOnRateTable.id == resourceRate.id &&
                    resource.id == resourceRate.relationships.resource.data.id
                  ) {
                    resourceRatesToReturn.add(resourceRate);
                  }
                });
              });
            });
            setResourceRates(Array.from(resourceRatesToReturn));
            setIsLoading(false);
          });
        });
      });
    }
  }, [authorizationCode, reRender]);

  const handlePostStandardsAlert = (successfulResponse) => {
    if (successfulResponse) {
      setSuccessMessage("Services added.");
      setShowSuccessAlert(true);
    } else {
      setErrorMessage(
        "Somthing went wrong, the selected services could not be saved."
      );
      setShowFailAlert(true);
    }
  };

  return (
    <main>
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/onboarding"}
            element={
              <InfoComponent
                currentPage={currentPage}
                setCurrentPage={(cp) => setCurrentPage(cp)}
              />
            }
          />
          <Route
            path={"/onboarding/phases"}
            element={
              <PhaseComponent
                phases={phases}
                allPhases={allPhases}
                isLoading={isLoading}
                account_slug={account_slug}
                account_id={account_id}
                authorizationCode={authorizationCode}
                setRerender={(r) => setRerender(r)}
              />
            }
          />
          <Route
            path={"/onboarding/language-fields-info"}
            element={<LanguageFieldsInfo setRerender={(r) => setRerender(r)} />}
          />
          <Route
            path={"/onboarding/language-fields-edit"}
            element={
              <LanguageFieldsEdit
                languageFields={languageFields}
                allLanguageFields={allLanguageFields}
                isLoading={isLoading}
                account_slug={account_slug}
                account_id={account_id}
                authorizationCode={authorizationCode}
                setRerender={(r) => setRerender(r)}
              />
            }
          />
          <Route
            path={"/onboarding/resources"}
            element={
              <Resources
                resourceRates={resourceRates}
                isLoading={isLoading}
                account_slug={account_slug}
                account_id={account_id}
                authorizationCode={authorizationCode}
                currencyUnit={currencyUnit}
                resources={resources}
                rateTableId={rateTableId}
                setRerender={(r) => setRerender(r)}
              />
            }
          />
          <Route
            path={"/onboarding/standard-services"}
            element={
              <StandardsComponent
                selectedStandards={selectedStandards}
                setSelectedStandards={setSelectedStandards}
                account_slug={account_slug}
                authorizationCode={authorizationCode}
                postStandardsAlert={handlePostStandardsAlert}
              />
            }
          />
          <Route
            path={"/onboarding/governances"}
            element={
              <Governances
                governances={governances}
                isLoading={isLoading}
                account_slug={account_slug}
                authorizationCode={authorizationCode}
                account_id={account_id}
                resources={resources}
                setRerender={(r) => setRerender(r)}
              />
            }
          />
          <Route
            path={"/onboarding/complete"}
            element={
              <YoureAllSetPage
                account_slug={account_slug}
                setRerender={(r) => setRerender(r)}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default GuidedOnboardingMspa;
