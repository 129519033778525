import SlideOutMenuV2 from "@components/SlideOutMenuV2";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import { TextField } from "@components/FormsV2";
import { useEffect, useState, useMemo } from "react";
import "./style";
import { useCreateVendorMutation } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import useGetAccount from "../../../Admin/Teams/common/api/useGetAccount";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import Select from "react-select";
import countryList from "react-select-country-list";

const AddVendorSlideout = ({
  slideoutOpen,
  setSlideoutOpen,
  setNewVendorId,
  refetch,
}) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [createVendor] = useCreateVendorMutation();
  const { account } = useGetAccount();

  const [name, setName] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [country, setCountry] =
    useState<{ value: string; label: string } | null>(null);

  const countryOptions = useMemo(() => countryList().getData(), []);

  const [errorMessage, setErrorMessage] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("Vendor Added");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  useEffect(() => {
    if (!slideoutOpen) {
      handleCancel();
    }
  }, [slideoutOpen]);

  const handleSave = () => {
    const data = {
      type: "vendors",
      attributes: {
        name,
        "street-address": addressLine1,
        street2: addressLine2,
        city,
        state,
        "postal-code": zip,
        country: country?.value?.toLowerCase() || "",
      },
      relationships: {
        account: {
          data: { id: account?.id, type: "accounts" },
        },
      },
    };

    createVendor({ slug: accountSlug, body: { data } })
      .unwrap()
      .then((res) => {
        if (res?.data?.id) {
          refetch();
          const vendorId = res.data.id;
          setShowSuccessAlert(true);
          setNewVendorId(vendorId);
          handleCancel();
        }
      })
      .catch((error) => {
        const msg = error?.data?.errors?.[0]?.detail;
        if (msg) {
          setErrorMessage(msg);
          setShowFailAlert(true);
        } else {
          setErrorMessage("Something went wrong.");
          setShowFailAlert(true);
        }
      });
  };

  const handleCancel = () => {
    setName("");
    setAddressLine1("");
    setAddressLine2("");
    setCity("");
    setState("");
    setZip("");
    setCountry(null);
    setSlideoutOpen(false);
  };

  return (
    <SlideOutMenuV2
      title="Add a Vendor"
      isOpen={slideoutOpen}
      onClose={() => setSlideoutOpen(false)}
      onSave={handleSave}
      onClearAll={handleCancel}
    >
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <div className="addVendor">
        <div className="vendorInfo">
          <h3>Vendor Info</h3>
          <TextField
            label="Vendor Name"
            id="vendorName"
            placeholder="Enter vendor name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="vendorLocation">
          <h3>Vendor Location</h3>
          <TextField
            label="Street Address Line 1"
            id="addressLine1"
            placeholder="Enter vendor street address"
            value={addressLine1}
            onChange={(e) => setAddressLine1(e.target.value)}
          />
          <TextField
            label="Street Address Line 2"
            id="addressLine2"
            placeholder="Suite number, P.O. box, etc"
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
          />
          <div className="displayFlex">
            <TextField
              label="City"
              id="city"
              placeholder="Enter city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <TextField
              label="State/Territory"
              id="stateTerritory"
              placeholder="Enter state"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            <TextField
              label="Zip/Postcode"
              id="zip"
              placeholder="Enter zip"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
            <div className="formGroup">
              <FormFieldLabel className="" label="Country" />
              <Select
                options={countryOptions}
                value={country}
                onChange={(value) => setCountry(value)}
              />
            </div>
          </div>
        </div>
      </div>
    </SlideOutMenuV2>
  );
};

export default AddVendorSlideout;
