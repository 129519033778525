import React from "react";
import CardHeader from "react-bootstrap/esm/CardHeader";
import "../GuidedOnboarding.css";
import OnboardingFooter from "./OnboardingFooter";
import { Card, Row, Col } from "react-bootstrap";

function OnboardingCards({
  contentCardHeader,
  leftContent,
  rightContent,
  rightButtonText,
  rightButtonOnClick,
  disabledRightButton,
  skipSetupButton,
  currentPage,
}) {
  return (
    <div className="infoPage">
      <Card className="slideCard tall">
        <>
          <CardHeader className="slideCardHeader">
            How To Work In ScopeStack
          </CardHeader>
        </>
        <Card.Body>
          <Card className="contentCard">
            <CardHeader className="contentCardHeader">
              {contentCardHeader}
            </CardHeader>
            <hr />
            <Card.Body>
              <Row>
                <Col sm={6}>{leftContent}</Col>
                <Col sm={6}>{rightContent}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Card.Body>
        <OnboardingFooter
          skipSetupButton={skipSetupButton}
          rightButtonText={rightButtonText}
          rightButtonOnClick={rightButtonOnClick}
          disabledRightButton={disabledRightButton}
          currentPage={currentPage}
        />
      </Card>
    </div>
  );
}

export default OnboardingCards;
