import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import FormNumberField from "@components/Forms/FormNumberField";

function CustomFields({
  customUnitPrice,
  setCustomUnitPrice,
  customUnitCost,
  setCustomUnitCost,
  customListPrice,
  setCustomListPrice,
  customVendorDiscount,
  setCustomVendorDiscount,
  customMarkup,
  setCustomMarkup,
  unitPriceChecked,
  setUnitPriceChecked,
  listPriceChecked,
  setListPriceChecked,
}) {
  return (
    <div style={{ padding: "1em" }}>
      <Row>
        <Col className="form-group control-label">
          <Form.Check
            type="radio"
            label="Manage Unit Price"
            checked={unitPriceChecked}
            onChange={(e) => {
              setUnitPriceChecked(true);
              setListPriceChecked(false);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginLeft: "24px" }}>
        <Col className="form-group" sm={3}>
          <FormNumberField
            label="Unit Price"
            min="0"
            step="0.01"
            value={customUnitPrice}
            readOnly={unitPriceChecked ? false : true}
            onChange={(e) => {
              setCustomUnitPrice(e.target.value);
            }}
          />
        </Col>
        <Col className="form-group" sm={3}>
          <FormNumberField
            label="Unit Cost"
            min="0"
            step="0.01"
            value={customUnitCost}
            readOnly={unitPriceChecked ? false : true}
            onChange={(e) => {
              setCustomUnitCost(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="form-group control-label">
          <Form.Check
            type="radio"
            label="Manage List Price"
            checked={listPriceChecked}
            onChange={(e) => {
              setListPriceChecked(true);
              setUnitPriceChecked(false);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginLeft: "24px" }}>
        <Col className="form-group">
          <label className="control-label">List price</label>
          <input
            type="number"
            label="List Price"
            min="0"
            step="0.01"
            className="form-control"
            readOnly={listPriceChecked ? false : true}
            value={customListPrice}
            onChange={(e) => {
              setCustomListPrice(e.target.value);
            }}
          />
          <small className="textMuted">
            You may specify list price or unit price but not both
          </small>
        </Col>
        <Col className="form-group">
          <FormNumberField
            label="Vendor Discount (%)"
            value={customVendorDiscount}
            min="0"
            step="0.01"
            readOnly={listPriceChecked ? false : true}
            onChange={(e) => {
              setCustomVendorDiscount(e.target.value);
            }}
          />
        </Col>
        <Col className="form-group">
          <FormNumberField
            label="Markup (%)"
            value={customMarkup}
            min="0"
            step="0.01"
            readOnly={listPriceChecked ? false : true}
            onChange={(e) => {
              setCustomMarkup(e.target.value);
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default CustomFields;
