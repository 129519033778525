import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import CardHeader from "@components/CardHeader/CardHeader";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import RouterButton from "@components/Buttons/RouterButton/RouterButton";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";

function ProjectVendorQuotes({
  account_slug,
  project_id,
  projectStatus,
  vendorQuotes,
  vendorQuoteId,
  account_id,
  vendorQuoteCreated,
  vendorQuoteUpdated,
  setVendorQuoteId,
  vendors,
  currencyUnit,
  isLoading,
  authorizationCode,
}) {
  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);

  useEffect(() => {
    if (vendorQuoteCreated === true) {
      setCreatedAlertIsOpen(true);
    }
  }, [vendorQuotes, isLoading]);

  const getTableData = () => {
    if (vendorQuotes.length === 0) {
      return [];
    }
    // Map over vendorQuotes & return data for ScopeStackTable
    return vendorQuotes.map((quote, index) => {
      const quoteId = quote.id;
      let vendor = "";

      const deleteQuote = (e) => {
        e.stopPropagation();
        let userConfirms = confirm(
          "Are you sure you want to delete this quote?"
        );
        if (userConfirms) {
          let quote = {
            data: {
              type: "quotes",
              id: quoteId,
            },
          };
          API.Delete(
            `${apiHost}/${account_slug}/v1/quotes/${quoteId}`,
            quote,
            authorizationCode
          )
            .then((response) => {
              if (response.status == 204) {
                window.location.reload();
              }
            })
            .catch((err) => {
              setErrorMessages(
                formatUnprocessibleResponse(err, "Vendor Quote")
              );
              setShowFailAlert(true);
            });
        }
      };

      // Render icon buttons under actions on hover if the project status is: building
      const renderActions = () => {
        if (projectStatus === "building") {
          return (
            <div className="actionsDiv">
              <OnHoverButtonIcon
                className="actions"
                iconClass="fa fa-remove"
                dataConfirm="Are you sure?"
                buttonText="Delete"
                onClick={(e) => deleteQuote(e)}
              />
            </div>
          );
        } else {
          return null;
        }
      };

      const redirectToEditQuote = () => {
        setVendorQuoteId(quoteId);
        return navigate(`/projects/${project_id}/quotes/${quoteId}/edit`);
      };

      for (let i = 0; i < vendors.length; i++) {
        let vendorData = quote.relationships.vendor.data;
        if (vendorData && vendors[i].id == vendorData.id) {
          vendor = vendors[i].attributes.name;
        }
      }

      // Map returns a table row for each Quote
      return {
        rowClass: "quotesRow",
        onClick: redirectToEditQuote,
        columns: [
          {
            class: "",
            name: vendor,
          },
          {
            class: "",
            name: quote.attributes.description,
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(quote.attributes.cost).toFixed(2)}
              />
            ),
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(quote.attributes["recurring-cost"]).toFixed(
                  2
                )}
              />
            ),
          },
          {
            class: "text-right",
            name: parseFloat(quote.attributes.markup).toFixed(2) + "%",
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(quote.attributes.price).toFixed(2)}
              />
            ),
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(quote.attributes["recurring-price"]).toFixed(
                  2
                )}
              />
            ),
          },
        ],
        actions: renderActions(),
      };
    });
  };

  const getFooterData = () => {
    let content = "";
    if (projectStatus === "building") {
      content = (
        <RouterButton
          path={`/projects/${project_id}/quotes/new`}
          className="newResource"
          iconClass="fa fa-plus"
          buttonText="Add Vendor Quote"
        />
      );
    }
    return {
      rowClass: "col-12",
      columns: [{ class: "quotesFooter", content: content }],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Vendor",
      },
      {
        class: "",
        name: "Description",
      },
      {
        class: "text-right",
        name: "One Time Cost",
      },
      {
        class: "text-right",
        name: "Recurring Cost",
      },
      {
        class: "text-right",
        name: "Markup",
      },
      {
        class: "text-right",
        name: "One Time Revenue",
      },
      {
        class: "text-right",
        name: "Recurring Revenue",
      },
    ],
  };
  const tableData = getTableData();
  const footerData = getFooterData();

  let quotesTable = new ScopeStackTable(
    "Vendor Quotes",
    headerData,
    tableData,
    footerData
  );

  const detailsCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="quoteAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Vendor Quote created"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div className="col-sm-12" id="sow-workarea">
      <div className="tabContent">
        {detailsCreatedAlert()}
        {handleFailAlert()}
        <Card>
          <CardHeader title="Vendor Quotes" />
          <Card.Body>
            <BaseTable
              className="scopestack quotesTable"
              striped={true}
              hover={true}
              bordered={false}
              headerRows={quotesTable.buildHeaderRows()}
              dataRows={quotesTable.buildDataRows()}
              footerRows={quotesTable.buildFooterRows()}
              isLoading={isLoading}
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default ProjectVendorQuotes;
