import React from "react";
import { Card } from "react-bootstrap";

function CardHeader({ title }) {
  return (
    <Card.Header as="h5">
      <div className="fold"></div>
      {title}
    </Card.Header>
  );
}

export default CardHeader;
