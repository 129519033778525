import React from "react";
import { useAuthorization } from "@common/providers";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { Route, Routes } from "react-router";
import Show from "./Show";
import { useGetAccountQuery, useWhoAmIQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import Edit from "./Edit/Edit";
import New from "./New/New";

const DocumentTemplatesMspa = (): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  // Get Account
  const {
    data: account,
    error: accountError,
    isLoading: accountLoading,
    refetch: accountRefetch,
  } = useGetAccountQuery({
    slug: accountSlug,
    include: ["phases", "resources"],
  });

  // Get permissions for current user
  const {
    data: currentUser,
    error: currentUserError,
    isLoading: currentUserLoading,
    refetch: currentUserRefetch,
  } = useWhoAmIQuery();

  const authorized = useAuthorization();
  if (
    !authorized ||
    !account ||
    accountLoading ||
    !currentUser ||
    currentUserLoading
  ) {
    return <ScopeStackSpinner />;
  }

  const permission = currentUser?.data?.attributes?.privileges;
  const documentTemplatePermission = permission?.find(
    (privilege) => privilege.privilege === "settings.sow_templates"
  )?.["access-level"];

  return (
    <Routes>
      <Route path="/" element={<Show account={account} />} />
      <Route
        path="/edit/:documentTemplateId"
        element={<Edit account={account} permission={documentTemplatePermission} />}
      />
      <Route
        path="/new"
        element={<New account={account} permission={documentTemplatePermission} />}
      />
    </Routes>
  );
};

export default DocumentTemplatesMspa;
