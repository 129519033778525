import React from "react";
import CloseButtonX from "../CloseButtonX/CloseButtonX";
import "./DissmissibleAlert.css";

function DismissibleAlert({ variant, text, onClose, className }) {
  return (
    <div
      className={`alert alert-${variant} alert-dismissible fade show ${className}`}
      role="alert"
    >
      {text}
      <CloseButtonX onClose={onClose} />
    </div>
  );
}

export default DismissibleAlert;
