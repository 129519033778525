import React, { useState, useEffect } from "react";
import CardHeader from "@components/CardHeader/CardHeader";
import { Card, Form, Row, Col } from "react-bootstrap";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import FormTextField from "@components/Forms/FormTextField";
import FormNumberField from "@components/Forms/FormNumberField";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import BackButton from "@components/Buttons/BackButton/BackButton";

function EditProductPricing({
  account_slug,
  account_id,
  project_id,
  projectStatus,
  products,
  projectProducts,
  currencyUnit,
  productPricings,
}) {
  const location = window.location.href;

  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  const [product, setProduct] = useState("");
  const [description, setDescription] = useState("");
  const [mfrPart, setMfrPart] = useState("");
  const [sku, setSku] = useState("");
  const [productIdField, setProductIdField] = useState("");
  const [category, setCategory] = useState("");
  const [quantity, setQuantity] = useState("0.0");
  const [unitOfMeasure, setUnitOfMeasure] = useState("");
  const [productPrice, setProductPrice] = useState("0.0");
  const [productCost, setProductCost] = useState("0.0");

  useEffect(() => {
    projectProducts.forEach((product) => {
      if (product.id == id) {
        const attr = product.attributes;
        if (attr.name !== null) setProduct(attr.name);
        if (attr.description !== null) setDescription(attr.description);
        if (attr["manufacturer-part-number"] !== null)
          setMfrPart(attr["manufacturer-part-number"]);
        if (attr.sku !== null) setSku(attr.sku);
        if (attr["product-id"] !== null) setProductIdField(attr["product-id"]);
        if (attr.category !== null) setSku(attr.category);
        if (attr.quantity !== null) setQuantity(attr.quantity);
        if (attr["unit-of-measure"] !== null)
          setUnitOfMeasure(attr["unit-of-measure"]);
        if (attr["unit-price"] !== null) setProductPrice(attr["unit-price"]);
        if (attr["unit-cost"] !== null) setProductCost(attr["unit-cost"]);
      }
    });
  }, [projectProducts, productPricings]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <Card>
        <CardHeader title="Product" />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={4}>
                <ControlledDropDown
                  readOnly={true}
                  label="Product *"
                  value={product}
                  options={projectProducts.map((product) => (
                    <option value={product.attributes.name} key={product.id}>
                      {product.attributes.name}
                    </option>
                  ))}
                  onChange={(e) => setProduct(e.target.value)}
                />
              </Col>
              <Col sm={8}>
                <FormTextField
                  readOnly={true}
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <FormTextField
                  readOnly={true}
                  label="Manufacturer Part Num"
                  value={mfrPart}
                  onChange={(e) => setMfrPart(e.target.value)}
                />
              </Col>
              <Col sm={3}>
                <FormTextField
                  readOnly={true}
                  label="SKU"
                  value={sku}
                  onChange={(e) => setSku(e.target.value)}
                />
              </Col>
              <Col sm={3}>
                <FormTextField
                  readOnly={true}
                  label="Product ID"
                  value={productIdField}
                  onChange={(e) => setProductIdField(e.target.value)}
                />
              </Col>
              <Col sm={3}>
                <FormTextField
                  readOnly={true}
                  label="Category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <FormNumberField
                  readOnly={true}
                  label="Quantity *"
                  value={quantity}
                  step="1"
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </Col>
              <Col sm={3}>
                <FormTextField
                  readOnly={true}
                  label="Unit of Measure"
                  value={unitOfMeasure}
                  onChange={(e) => setUnitOfMeasure(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <FormNumberField
                  readOnly={true}
                  label="Product Price"
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                />
              </Col>
              <Col sm={3}>
                <FormNumberField
                  readOnly={true}
                  label="Product Cost"
                  value={productCost}
                  onChange={(e) => setProductCost(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>{/* <SubmitButton /> */}</Col>
              <Col xs={6}>
                <BackButton
                  url={`/projects/${project_id}/project_material_pricings`}
                />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default EditProductPricing;
