import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProjectLocations from "./ProjectLocations/ProjectLocations";
import NewLocation from "./NewLocation/NewLocation";
import EditLocation from "./EditLocation/EditLocation";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";

function LocationsMspa({ account_slug, project_id, account_id }) {
  const [locationId, setLocationId] = useState(null);

  // Set the State
  const [projectStatus, setProjectStatus] = useState("");
  const [locations, setLocations] = useState([]);
  const [authorizationCode] = useOauth();
  const [locationCreated, setLocationCreated] = useState(false);
  const [locationUpdated, setLocationUpdated] = useState(false);
  const [projVariables, setProjVariables] = useState([]);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);

  // When page loads, call API to get Location Info and lift it up to the state
  useEffect(() => {
    if (authorizationCode != "") {
      // Get Project Info
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        const status = data.attributes.status;
        if (status !== null) setProjectStatus(status);
      });

      // Get Project Variables
      API.Get(
        `${apiHost}/${account_slug}/v1/project-variables?filter[variable-context]=service_location`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        let variables = data.map((pv) => {
          let attr = pv.attributes;
          let options = attr["select-options"];
          delete attr["select-options"];
          return { ...attr, select_options: options };
        });
        if (data.length !== 0) setProjVariables(variables);
      });

      // Get Locations
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-locations?page[size]=250`,
        authorizationCode
      ).then((response) => {
        if (response.data.data !== null) {
          setLocations(response.data.data);
        }
        setIsLoading(false);
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/project_locations"}
            element={
              <ProjectLocations
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                locationId={locationId}
                locations={locations}
                setLocationId={(locId) => setLocationId(locId)}
                projectStatus={projectStatus}
                locationCreated={locationCreated}
                locationUpdated={locationUpdated}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_locations/new"}
            element={
              <NewLocation
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                locations={locations}
                setLocationCreated={(created) => setLocationCreated(created)}
                projVariables={projVariables}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_locations/:locationId/edit"}
            element={
              <EditLocation
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                locations={locations}
                locationId={locationId}
                setLocationUpdated={(updated) => setLocationUpdated(updated)}
                projVariables={projVariables}
                authorizationCode={authorizationCode}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default LocationsMspa;
