import React, { useEffect, useRef } from "react";
import Select from "react-select";
import "./MultiSelectAutocomplete.css";
import FormFieldLabel from "../FormFieldLabel/FormFieldLabel";

function MultiSelectAutocomplete({
  value,
  onChange,
  onInputChange,
  options,
  label,
  className,
  width,
  updateRef,
}) {
  const multiSelectRef = useRef();

  useEffect(() => {
    if (updateRef) {
      multiSelectRef.current.clearValue();
    }
  }, [updateRef]);

  return (
    <>
      <FormFieldLabel className="filterMenuLabel" label={label} />
      <div
        style={{ margin: "0px 10px 24px 24px", width: width }}
        className="input-group"
      >
        <Select
          className={className}
          placeholder={"Search and Select Multiple"}
          value={value}
          onChange={onChange}
          onInputChange={onInputChange}
          options={options}
          isMulti={true}
          ref={updateRef ? multiSelectRef : undefined}
        />
      </div>
    </>
  );
}
export default MultiSelectAutocomplete;
