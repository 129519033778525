import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import "../../Projects/CreateProjectMspa/Services/Questionnaire/Survey.css";
import { SurveyResponseType } from "../types/SurveyResponseType";
import FormTextField from "@components/Forms/FormTextField";
import RequiredReactSelect from "@components/FormsV2/RequiredReactSelect/RequiredReactSelect";

const Survey = ({
  questionnaire,
  questions,
  setSurveyResponses,
  surveyResponses,
  handleSurveySubmit,
  showRecommendations,
  isFromSurveyPage = false,
  currentSurvey,
  setRecalculateClicked,
  isNewSurvey,
  calculateClicked,
  setCalculateClicked,
  revertClicked,
  originalSurveyResponses,
  isTakeSharedSurvey = false,
  name = "",
  setName,
  showNoAccessError = false,
}): JSX.Element => {
  const [responsesChanged, setResponsesChanged] = useState(false);
  const [questionsState, setQuestionsState] = useState(questions);

  if (
    !isNewSurvey ||
    (currentSurvey && currentSurvey?.attributes?.status === "shared")
  ) {
    isNewSurvey =
      surveyResponses.filter((sr) => sr["survey-response-id"]).length === 0;
  }

  useEffect(() => {
    if (!isNewSurvey) {
      let surveyResponsesToRender = revertClicked
        ? originalSurveyResponses
        : surveyResponses;
      surveyResponsesToRender = surveyResponsesToRender.map((sr) => {
        questions.forEach((q) => {
          if (sr["question-id"] == q.id) {
            sr = {
              ...sr,
              settings: q.attributes ? q.attributes.settings : q.settings,
            };
          }
        });
        return sr;
      });
      setSurveyResponses(surveyResponsesToRender);
      setQuestionsState(
        surveyResponsesToRender.map((question) => {
          let selectOptions = question.attributes
            ? question.attributes["select-options"]
            : question["select-options"];

          if (selectOptions.length > 0) {
            let defaultValue = {
              label: "Select An Option",
              value: "select",
            };

            selectOptions.forEach((option) => {
              if (option.default && !showRecommendations) {
                defaultValue = {
                  value: option.value,
                  label: option.key,
                };
              }
              if (
                question.answer !== "" &&
                (showRecommendations ||
                  (currentSurvey &&
                    currentSurvey?.type === "shared-surveys")) &&
                question.answer == option.value
              ) {
                defaultValue = {
                  value: option.value,
                  label: option.key,
                };
              }
            });

            if (question.attributes) {
              question = {
                ...question,
                attributes: {
                  ...question.attributes,
                  id: question.id,
                  answer: defaultValue,
                },
              };
              question = question.attributes;
            } else {
              question = { ...question, answer: defaultValue };
            }

            return question;
          }
          return question;
        })
      );
    }
  }, [
    showRecommendations,
    calculateClicked,
    revertClicked,
    currentSurvey,
    originalSurveyResponses,
  ]);

  useEffect(() => {
    if (isNewSurvey) {
      const newQuestionsState = (questions || [])
        .filter((question) => question.attributes.active)
        .sort((a, b) => a.attributes.position - b.attributes.position)
        .map((question) => {
          let selectOptions = question.attributes
            ? question.attributes["select-options"]
            : question["select-options"];
          if (selectOptions.length > 0) {
            let defaultValue = {
              label: "Select An Option",
              value: "select",
            };
            selectOptions.forEach((option) => {
              if (option.default && !showRecommendations) {
                defaultValue = {
                  value: option.value,
                  label: option.key,
                };
              }
            });
            question = {
              ...question,
              attributes: {
                ...question.attributes,
                id: question.id,
                answer: defaultValue,
              },
            };
            question = question.attributes;
            return question;
          } else {
            question = {
              ...question,
              attributes: {
                ...question.attributes,
                id: question.id,
                answer: "",
              },
            };
            question = question.attributes;
            return question;
          }
        });
      setQuestionsState(newQuestionsState);
      setSurveyResponses(
        newQuestionsState.map((question) => {
          return {
            question: question.question,
            "question-id": question.id,
            answer: question.answer.value ? question.answer.value : "",
          };
        })
      );
    }
  }, []);

  useEffect(() => {}, [showNoAccessError]);

  const customValidateReactSelect = () => {
    let isValid = true;
    const newQuestionState = questionsState.map((question) => {
      if (
        question["select-options"] &&
        question["select-options"].length > 0 &&
        question.answer.value == "select" &&
        question.required
      ) {
        isValid = false;
        question = { ...question, showInvalid: true };
      } else {
        question = { ...question, showInvalid: false };
      }
      return question;
    });
    setQuestionsState(newQuestionState);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = customValidateReactSelect();
    if (showRecommendations && currentSurvey && !isNewSurvey && isValid) {
      setRecalculateClicked(true);
      handleSurveySubmit(e, false, true);
    } else if (isValid) {
      setCalculateClicked(true);
      handleSurveySubmit(e, false, false);
    }
  };

  const buildInputElement = (question) => {
    let response: SurveyResponseType = {
      question: "",
      answer: "",
      surveyResponseId: 0,
      questionId: 0,
    };
    surveyResponses.forEach((res) => {
      if (question.id == res["question-id"]) {
        response = res;
      }
    });
    if (question["select-options"] && question["select-options"].length > 0) {
      return (
        <Col key={question.id} xs={6}>
          <Form.Group controlId={question.id}>
            <RequiredReactSelect
              label={
                <span>
                  {isFromSurveyPage && question.required ? (
                    <span style={{ color: "#FD0300" }}>* </span>
                  ) : null}
                  <span>{question.question}</span>
                </span>
              }
              required={question.required}
              isInvalid={question.showInvalid}
              key={question.id || question["survey-response-id"]}
              value={question.answer}
              onChange={(e: any) => {
                setResponsesChanged(true);
                setQuestionsState(
                  questionsState.map((ques) => {
                    if (
                      (isNewSurvey && ques.id == question.id) ||
                      (!isNewSurvey &&
                        ques["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...ques,
                        answer: e,
                        showInvalid: false,
                      };
                    } else {
                      return ques;
                    }
                  })
                );
                const updatedSurveyResponses = surveyResponses.map(
                  (response) => {
                    if (
                      (isNewSurvey && response["question-id"] == question.id) ||
                      (!isNewSurvey &&
                        response["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...response,
                        answer: e?.value,
                      };
                    }
                    return response;
                  }
                );
                setSurveyResponses(updatedSurveyResponses);
              }}
              options={question["select-options"].map((option) => {
                return {
                  label: option.key,
                  value: option.value,
                };
              })}
            />
          </Form.Group>
        </Col>
      );
    } else if (
      question["value-type"] === "text" ||
      question["value-type"] === "email"
    ) {
      return (
        <Col key={question.id || question["survey-response-id"]} xs={6}>
          <Form.Group controlId={question.id}>
            <Form.Label>
              {isFromSurveyPage && question.required ? (
                <span style={{ color: "#FD0300" }}>* </span>
              ) : null}
              {question.question}
            </Form.Label>
            <Form.Control
              required={question.required}
              type={question["value-type"]}
              value={question.answer}
              onChange={(e) => {
                setResponsesChanged(true);
                setQuestionsState(
                  questionsState.map((ques) => {
                    if (
                      (isNewSurvey && ques.id == question.id) ||
                      (!isNewSurvey &&
                        ques["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...ques,
                        answer: e.target.value,
                        showInvalid: false,
                      };
                    } else {
                      return ques;
                    }
                  })
                );
                const updatedSurveyResponses = surveyResponses.map(
                  (response) => {
                    if (
                      (isNewSurvey && response["question-id"] == question.id) ||
                      (!isNewSurvey &&
                        response["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...response,
                        answer: e.target.value,
                      };
                    }
                    return response;
                  }
                );
                setSurveyResponses(updatedSurveyResponses);
              }}
            />
          </Form.Group>
        </Col>
      );
    } else if (question["value-type"] === "number") {
      return (
        <Col key={question.id || question["survey-response-id"]} xs={6}>
          <Form.Group controlId={question.id}>
            <Form.Label>
              {isFromSurveyPage && question.required ? (
                <span style={{ color: "#FD0300" }}>* </span>
              ) : null}
              {question.question}
            </Form.Label>
            <Form.Control
              required={question.required}
              type="number"
              value={question.answer}
              min={
                question.settings && question.settings.min
                  ? parseInt(question.settings.min)
                  : 0
              }
              max={
                question.settings && question.settings.max
                  ? parseInt(question.settings.max)
                  : undefined
              }
              step={question?.settings?.step || 1}
              onChange={(e) => {
                setResponsesChanged(true);
                setQuestionsState(
                  questionsState.map((ques) => {
                    if (
                      (isNewSurvey && ques.id == question.id) ||
                      (!isNewSurvey &&
                        ques["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...ques,
                        answer: e.target.value,
                      };
                    } else {
                      return ques;
                    }
                  })
                );
                const updatedSurveyResponses = surveyResponses.map(
                  (response) => {
                    if (
                      (isNewSurvey && response["question-id"] == question.id) ||
                      (!isNewSurvey &&
                        response["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...response,
                        answer: e.target.value,
                      };
                    }
                    return response;
                  }
                );
                setSurveyResponses(updatedSurveyResponses);
              }}
            />
          </Form.Group>
        </Col>
      );
    } else if (question["value-type"] === "checkbox") {
      return (
        <Col key={question.id || question["survey-response-id"]} xs={6}>
          <Form.Group controlId={question.id}>
            <Form.Check
              type="checkbox"
              value={question.answer}
              label={
                <span>
                  {isFromSurveyPage && question.required ? (
                    <span style={{ color: "#FD0300" }}>* </span>
                  ) : null}
                  {question.question}
                </span>
              }
              required={question.required}
              onChange={(e) => {
                setResponsesChanged(true);
                setQuestionsState(
                  questionsState.map((ques) => {
                    if (
                      (isNewSurvey && ques.id == question.id) ||
                      (!isNewSurvey &&
                        ques["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...ques,
                        answer: e.target.checked,
                      };
                    } else {
                      return ques;
                    }
                  })
                );
                const updatedSurveyResponses = surveyResponses.map(
                  (response) => {
                    if (
                      (isNewSurvey && response["question-id"] == question.id) ||
                      (!isNewSurvey &&
                        response["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...response,
                        answer: e.target.checked,
                      };
                    }
                    return response;
                  }
                );
                setSurveyResponses(updatedSurveyResponses);
              }}
            />
          </Form.Group>
        </Col>
      );
    } else if (question["value-type"] === "date") {
      return (
        <Col key={question.id || question["survey-response-id"]} xs={6}>
          <Form.Group controlId={question.id}>
            <Form.Label>
              {isFromSurveyPage && question.required ? (
                <span style={{ color: "#FD0300" }}>* </span>
              ) : null}
              {question.question}
            </Form.Label>
            <Form.Control
              required={question.required}
              value={question.answer}
              onChange={(date) => {
                setResponsesChanged(true);
                setQuestionsState(
                  questionsState.map((ques) => {
                    if (
                      (isNewSurvey && ques.id == question.id) ||
                      (!isNewSurvey &&
                        ques["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...ques,
                        answer: date?.target.value,
                      };
                    } else {
                      return ques;
                    }
                  })
                );
                const updatedSurveyResponses = surveyResponses.map(
                  (response) => {
                    if (
                      (isNewSurvey && response["question-id"] == question.id) ||
                      (!isNewSurvey &&
                        response["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...response,
                        answer: date?.target.value,
                      };
                    }
                    return response;
                  }
                );
                setSurveyResponses(updatedSurveyResponses);
              }}
              min={question.settings.min}
              max={question.settings.max}
              type="date"
            />
          </Form.Group>
        </Col>
      );
    } else if (question["value-type"] === "phone") {
      return (
        <Col key={question.id || question["survey-response-id"]} xs={6}>
          <Form.Group controlId={question.id}>
            <Form.Label>
              {isFromSurveyPage && question.required ? (
                <span style={{ color: "#FD0300" }}>* </span>
              ) : null}
              {question.question}
            </Form.Label>
            <input
              className="form-control"
              required={question.required}
              value={question.answer}
              placeholder="2125553456"
              pattern="^\+?(?:[0-9]●?){6,14}[0-9]$"
              type="tel"
              onChange={(e) => {
                setResponsesChanged(true);
                setQuestionsState(
                  questionsState.map((ques) => {
                    if (
                      (isNewSurvey && ques.id == question.id) ||
                      (!isNewSurvey &&
                        ques["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...ques,
                        answer: e.target.value,
                        showInvalid: false,
                      };
                    } else {
                      return ques;
                    }
                  })
                );
                const updatedSurveyResponses = surveyResponses.map(
                  (response) => {
                    if (
                      (isNewSurvey && response["question-id"] == question.id) ||
                      (!isNewSurvey &&
                        response["survey-response-id"] ==
                          question["survey-response-id"])
                    ) {
                      return {
                        ...response,
                        answer: e.target.value,
                      };
                    }
                    return response;
                  }
                );
                setSurveyResponses(updatedSurveyResponses);
              }}
            />
          </Form.Group>
        </Col>
      );
    }
  };
  return (
    <>
      <Row className="mb-3">
        <Col xs={isFromSurveyPage ? 12 : 6}>
          {!isFromSurveyPage ? (
            <>
              <div
                style={{ fontSize: "20px", margin: "0 0 30px 0" }}
                className="accordianSubtitle"
              >
                Complete Survey to Continue. When finished, click “Calculate”.
              </div>
              <div style={{ marginBottom: "20px" }}>
                {questionnaire.attributes.introduction}
              </div>
            </>
          ) : null}

          <Form
            onSubmit={(e) => handleSubmit(e)}
            id={isFromSurveyPage ? "takeSurveyForm" : "surveyForm"}
          >
            {isTakeSharedSurvey ? (
              <div>
                <Row>
                  <Col>
                    <div className="subheading">Contact Details</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormTextField
                      label="Your Name"
                      id="name"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required={true}
                      readOnly={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="subheading">Survey Details</div>
                  </Col>
                </Row>
              </div>
            ) : null}
            <Row className="mb-3">
              {(questionsState || []).map((question) => {
                return buildInputElement(question);
              })}
            </Row>
            <hr
              className="sectionDivider"
              style={{ width: "100%", margin: "18px 0px 24px" }}
            ></hr>
            <Button
              style={{ marginRight: "10px" }}
              id="surveyCalcButton"
              className="seafoamBgButton"
              type="submit"
              disabled={!responsesChanged || showNoAccessError}
              form={isFromSurveyPage ? "takeSurveyForm" : "surveyForm"}
            >
              {(showRecommendations &&
                currentSurvey &&
                currentSurvey?.attributes?.status !== "pending" &&
                currentSurvey?.attributes?.status !== "shared") ||
              (calculateClicked && !isTakeSharedSurvey)
                ? "Re-Calculate"
                : isTakeSharedSurvey
                ? "Submit"
                : "Calculate"}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Survey;
