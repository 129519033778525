import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../reducers/rootReducer";

type AuthState = {
  authorized: boolean;
  token: string | null;
  refreshToken: string | null;
};

const slice = createSlice({
  name: "auth",
  initialState: { token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { token, authorized },
      }: PayloadAction<{ token: string; authorized: boolean }>
    ) => {
      state.token = token;
      state.authorized = authorized;
    },
  },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;
