import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { getSurveyTags } from "../SurveyHelpers/surveyHelpers";
import useListManageSurveys from "./api/useListManageSurveys";
import DataTable from "@components/DataTable";
import type { ColumnDef } from "@tanstack/react-table";
import { ManageSurvey } from "./types";
import { faSortDown, faSortUp, faSort } from "@fortawesome/pro-solid-svg-icons";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";

const ManageSurveys = ({
  filterParams,
  selected,
  setSelected,
  refetchSurveys,
  setRefetchSurveys,
}): JSX.Element => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const { manageSurveys, isLoading, pageMeta, isFetching, refetch } =
    useListManageSurveys({
      pageSize,
      pageNumber,
      filterParams,
      sortBy,
      sortOrder,
    });

  useEffect(() => {
    if (refetchSurveys) {
      refetch();
      setRefetchSurveys(false);
    }
  }, [refetchSurveys]);

  const handleSort = (columnId: string) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  const renderSortIcon = (columnId: string) => {
    if (sortBy === columnId) {
      return sortOrder === "asc" ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      );
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const columns: ColumnDef<ManageSurvey>[] = [
    {
      id: "name",
      header: () => (
        <div className="surveysColumnHeader">
          Survey Name
          <div className="sortIcon" onClick={() => handleSort("name")}>
            {renderSortIcon("name")}
          </div>
        </div>
      ),
      cell(props) {
        const survey = props.row.original;
        const name = survey.attributes.name;
        return name === "No surveys found" ? (
          <>{name}</>
        ) : (
          <div className="surveyName" onClick={() => null}>
            <FontAwesomeIcon className="tabIcon" icon={faPencil} />
            <span>{props.row.original.attributes.name}</span>
          </div>
        );
      },
    },
    {
      id: "published",
      header: () => (
        <div className="surveysColumnHeader">
          Status{" "}
          <div className="sortIcon" onClick={() => handleSort("published")}>
            {renderSortIcon("published")}
          </div>
        </div>
      ),
      cell(props) {
        const status = props.row.original.attributes?.status;
        return status ? (
          <span
            className={`surveyStatus ${
              status == "Published" ? "completed" : "pending"
            }`}
          >
            {status}
          </span>
        ) : (
          <></>
        );
      },
    },
    {
      id: "created_at",
      header: () => (
        <div className="surveysColumnHeader">
          Created On{" "}
          <div className="sortIcon" onClick={() => handleSort("created_at")}>
            {renderSortIcon("created_at")}
          </div>
        </div>
      ),
      cell(props) {
        return <>{props.row.original.attributes?.createdOn || ""}</>;
      },
    },
    {
      id: "tag_list",
      header: "Tags",
      cell(props) {
        return getSurveyTags(props.row.original.attributes?.tags || []);
      },
    },
  ];

  return isLoading || isFetching ? (
    <SmallSpinner />
  ) : (
    <DataTable
      data={
        manageSurveys.length
          ? manageSurveys
          : [
              {
                id: "0",
                attributes: { name: "No surveys found" },
              },
            ]
      }
      columns={columns}
      bordered
      striped
      hover
      selectable={manageSurveys.length ? true : false}
      selectedIds={selected}
      setSelectedIds={setSelected}
      totalPages={pageMeta.pageCount}
      totalRows={pageMeta.recordCount}
      currentPage={pageNumber}
      setCurrentPage={setPageNumber}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      paginationEnabled
      pageSizeEnabled
    />
  );
};

export default ManageSurveys;
