import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import FormFieldLabel from "../FormFieldLabel/FormFieldLabel";

function Select2({ defaultValue, options, label, onChange, onInputChange, value }) {
  return (
    <Form.Group className="formGroup">
      <FormFieldLabel label={label} />
      <Select onChange={onChange} onInputChange={onInputChange} className="basic-single" value={value} defaultValue={defaultValue} options={options} />
    </Form.Group>
  );
}
export default Select2;
