import React from "react";
import "./CustomDropdownToggle.css";

function CustomDropdownToggle({ options, value, onChange }) {
  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className="selectdiv"
    >
      <label className="customDropdownLabel">
        <div style={{ display: "flex", alignItems: "center" }}>
          <select value={value} onChange={onChange}>
            {options}
          </select>
          <div className="iconDiv">
            <i className="fa fa-sort-desc"></i>
          </div>
        </div>
      </label>
    </div>
  );
}

export default CustomDropdownToggle;
