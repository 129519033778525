import React from "react";
import FormCheck from "react-bootstrap/FormCheck";
import Button from "react-bootstrap/Button";

const getTableData = (standards, handleSelectStandard, checked) => {
  if (!standards || standards.length === 0) {
    return [];
  }

  return standards.map((standard) => {
    const standardName = standard.name || "";
    const lineOfBusiness = standard.lob || "";
    const serviceCategory = standard.category || "";
    const phase =
      standard.phase.attributes && standard.phase.attributes.name
        ? standard.phase.attributes.name
        : "";
    const tags = standard.tags || [];

    return {
      rowClass: "",
      columns: [
        {
          class: "checkbox",
          name: (
            <FormCheck
              className=""
              inline
              key={standard.name}
              value={standard.id}
              name="group1"
              type="checkbox"
              checked={checked}
              id={standard.id}
              onChange={handleSelectStandard}
            />
          ),
        },
        {
          class: "",
          name: standardName,
        },
        {
          class: "",
          name: serviceCategory,
        },
      ],
    };
  });
};

export default getTableData;
