import React, { useEffect, useState } from "react";
import API from "@API";

const formatData = ({ data, included }) => {
  const lobs = included.filter((item) => item.type === "lobs");
  const phases = included.filter(
    (item) => item.type === "project-phases" && item.attributes.active
  );
  const services = included.filter((item) => item.type === "services");
  const subservices = included.filter((item) => item.type === "subservices");
  const projectSubservices = included.filter(
    (item) => item.type === "project-subservices"
  );
  const resources = included.filter((item) => item.type === "resources");
  const serviceCategories = included.filter(
    (item) => item.type === "service-categories"
  );

  const dataToReturn = {
    data: data.map((s) => {
      const resourceId = s.relationships?.["project-resource"]?.data?.id;
      return {
        ...s,
        resource: resourceId ? `project-resources-${resourceId}` : "",
      };
    }),
    services: services,
    subservices: subservices,
    projectSubservices: projectSubservices,
    serviceCategories: serviceCategories,
    lobs: lobs,
    phases: phases,
    resources: resources,
  };
  return dataToReturn;
};

const useProjectServicesData = ({
  account_slug,
  authorizationCode,
  projectId,
}) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadProjectServices = async () => {
      const url = new URL(
        `/${account_slug}/v1/projects/${projectId}/project-services`,
        apiHost
      );
      url.searchParams.append(
        "include",
        "service-category,project,project-subservices.subservice,project-subservices.resource,lob,service,project-phase,resource,service.service-category,service.resource,service.subservices,service.phase,project-resource,project-resource.resource,project-subservices.project-resource"
      );
      url.searchParams.append("filter[active]", "true");
      try {
        const response = await API.Get(url.toString(), authorizationCode);

        const data = formatData({
          data: response.data.data,
          included: response.data.included,
        });
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoaded(true);
      }
    };

    loadProjectServices();
  }, []);

  return { data, error, loaded };
};

export default useProjectServicesData;
