import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AccordianDrop({
  header,
  body,
  iconClosed,
  iconOpen,
  closeable,
  setAccordianIsOpen,
  className,
  startingAccordionStateIsOpen = false,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (startingAccordionStateIsOpen) {
      document.getElementById("openCloseToggle").click();
    }
  }, []);

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setIsOpen(!isOpen);
      setAccordianIsOpen(!isOpen);
    });

    return (
      <Row className={className} style={{ flexWrap: "nowrap" }}>
        <div id="openCloseToggle" onClick={closeable ? decoratedOnClick : null}>
          <FontAwesomeIcon
            style={{ color: "#418172", fontSize: "30px", height: "36px" }}
            icon={isOpen ? iconOpen : iconClosed}
          />
        </div>
        <div>{children}</div>
      </Row>
    );
  }

  return (
    <Accordion className={className} eventkey="0" flush>
      <Card style={{ padding: "20px" }}>
        {header !== null ? (
          <Card.Header
            style={{
              backgroundColor: "#fff",
              color: "#1C2655",
            }}
          >
            <CustomToggle eventKey="0">{header}</CustomToggle>
          </Card.Header>
        ) : null}
        {body !== null ? (
          <Accordion.Collapse className="accordianCollapse" eventKey="0">
            <Card.Body>{body}</Card.Body>
          </Accordion.Collapse>
        ) : null}
      </Card>
    </Accordion>
  );
}

export default AccordianDrop;
