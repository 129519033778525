import React from "react";

function DoubleIconButton({
  dataMethod1,
  dataMethod2,
  id1,
  id2,
  url1,
  url2,
  iconClass1,
  iconClass2,
  buttonText1,
  buttonText2,
  uniqueStyles1,
  uniqueStyles2,
}) {
  return (
    <>
      <a
        rel="nofollow"
        data-method={dataMethod1}
        style={{ uniqueStyles1 }}
        id={id1}
        className="btn btn-success button1"
        href={url1}
      >
        <i className={iconClass1}></i>
        {buttonText1}
      </a>
      <a
        rel="nofollow"
        data-method={dataMethod2}
        style={{ uniqueStyles2 }}
        id={id2}
        className="btn btn-success button2"
        href={url2}
      >
        <i className={iconClass2}></i>
        {buttonText2}
      </a>
    </>
  );
}

export default DoubleIconButton;
