import React from "react";
import { Form } from "react-bootstrap";

function ScopestackFrequencyDropdown({ id, value, onChange }) {
  return (
    <div>
      <Form.Control as="select" id={id} value={value} onChange={onChange}>
        <option accessKey="one_time" key="one_time">
          One Time
        </option>
        <option accessKey="monthly" key="monthly">
          Monthly
        </option>
        <option accessKey="quarterly" key="quarterly">
          Quarterly
        </option>
        <option accessKey="yearly" key="yearly">
          Yearly
        </option>
      </Form.Control>
    </div>
  );
}

export default ScopestackFrequencyDropdown;
