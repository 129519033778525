import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import ProjectProducts from "./ProjectProducts/ProjectProducts";
import NewProduct from "./NewProduct/NewProduct";
import EditProduct from "./EditProduct/EditProduct";
import NewCustomProduct from "./CustomProducts/NewCustomProduct";
import API from "@API";

function ProductsMspa({ account_slug, project_id, account_id }) {
  // Set the State
  const [projectStatus, setProjectStatus] = useState("");

  const [authorizationCode] = useOauth();
  const [productCreated, setProductCreated] = useState(false);
  const [productUpdated, setProductUpdated] = useState(false);
  const [productId, setProductId] = useState(null);
  const [products, setProducts] = useState([]);
  const [projectProducts, setProjectProducts] = useState([]);
  const [projectServices, setProjectServices] = useState([]);
  const [currencyUnit, setCurrencyUnit] = useState("");
  const [productPvs, setProductPvs] = useState([]);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get Project Info with products included
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-products,project-products.project-service,project-services`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        const includedData = response.data.included;
        setProjectStatus(data.attributes.status);
        setCurrencyUnit(data.attributes["field-labels"].currency_unit);
        if (includedData) {
          setProjectProducts(
            includedData.filter(
              (product) =>
                product.type === "project-products" &&
                product.attributes.active === true
            )
          );
          setProjectServices(
            includedData.filter(
              (product) =>
                product.type === "project-services" &&
                product.attributes.active === true
            )
          );
        }
      });

      API.Get(
        `${apiHost}/${account_slug}/v1/products?page[size]=1000`,
        authorizationCode
      ).then((response) => {
        let products = response.data.data;
        let meta = response.data.meta;
        const getNextPage = (nextLink, products) => {
          API.Get(nextLink, authorizationCode).then((res) => {
            products = products.concat(res.data.data);
            if (res.data.links.next) {
              getNextPage(res.data.links.next, products);
            } else if (
              !res.data.links.next &&
              res.data.meta["record-count"] < products.length
            ) {
              getNextPage(res.data.links.last, products);
            } else {
              setProducts(
                products.filter((product) => product.attributes.active === true)
              );
            }
          });
        };
        if (meta["page-count"] > 1) {
          getNextPage(response.data.links.next, products);
        } else {
          setProducts(
            products.filter((product) => product.attributes.active === true)
          );
        }
        setIsLoading(false);
      });

      // Project Variables that are connected to products
      API.Get(
        `${apiHost}/${account_slug}/v1/project-variables?filter[variable-context]=product`,
        authorizationCode
      ).then((res) => {
        setProductPvs(
          res.data.data.map((pv) => {
            let attr = pv.attributes;
            let options = attr["select-options"];
            delete attr["select-options"];
            return { ...attr, select_options: options };
          })
        );
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/project_materials"}
            element={
              <ProjectProducts
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                productId={productId}
                setProductId={(productId) => setProductId(productId)}
                projectStatus={projectStatus}
                products={products}
                services={projectServices}
                projectProducts={projectProducts}
                productCreated={productCreated}
                productUpdated={productUpdated}
                currencyUnit={currencyUnit}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_materials/new"}
            element={
              <NewProduct
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                products={products}
                services={projectServices}
                productCreated={productCreated}
                setProductCreated={(created) => setProductCreated(created)}
                productId={productId}
                authorizationCode={authorizationCode}
                productPvs={productPvs}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_materials/:materialId/edit"}
            element={
              <EditProduct
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                products={products}
                services={projectServices}
                projectProducts={projectProducts}
                productUpdated={productUpdated}
                productCreated={productCreated}
                setProductUpdated={(updated) => setProductUpdated(updated)}
                productId={productId}
                authorizationCode={authorizationCode}
                productPvs={productPvs}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_materials/new?custom=true"}
            element={
              <NewCustomProduct
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                products={products}
                services={projectServices}
                setProductCreated={(created) => setProductCreated(created)}
                productId={productId}
                authorizationCode={authorizationCode}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default ProductsMspa;
