import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/rootReducer";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "./reducers/rootReducer";
import { scopeStackApi } from "./services/ScopeStackAPI";
import slugMiddleware from "./middleware/slugMiddleware";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(scopeStackApi.middleware),
});

export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>;

export default store;
