import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import SlideOutMenu from "@components/SlideOutMenuV2";
import { Col, Form, Row } from "react-bootstrap";
import { TextField, DateField } from "@components/FormsV2";
import { useStyles } from "./styles";
import {
  useGetAllSalesExecutives,
  useGetAllClients,
  useGetAllPresalesEngineers,
  useGetAllUsers,
  useGetAllAccountTags,
} from "@common/hooks";
import MultiSelectAutocomplete from "@components/FormsV2/MultiSelectAutoComplete/MultiSelectAutocomplete";
import { useUpdateUserViewResource } from "./api";
import { useGetUserViewResource } from "../api";
import { set } from "lodash";

export interface AtAGlanceRef {
  open: () => void;
  close: () => void;
  save: () => void;
}

type UserSelectionsState = {
  clients: Option[];
  executives: Option[];
  presalesEngineers: Option[];
  teammates: Option[];
  tags: Option[];
  status: string[];
};

interface Option {
  value: string;
  label: string;
}

const AtAGlance = forwardRef<
  AtAGlanceRef,
  { onClose: () => void; onFilterCountChange: (count: number) => void }
>(({ onClose, onFilterCountChange }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const styles = useStyles();
  const { triggerUpdateUserView } = useUpdateUserViewResource();
  const { userViews, isLoading: userViewsLoading } = useGetUserViewResource();
  const { executives } = useGetAllSalesExecutives();
  const { clients } = useGetAllClients();
  const { presalesEngineers } = useGetAllPresalesEngineers();
  const { users } = useGetAllUsers();
  const { tags } = useGetAllAccountTags();

  const [userSelections, setUserSelections] = useState<UserSelectionsState>({
    clients: [],
    executives: [],
    presalesEngineers: [],
    teammates: [],
    tags: [],
    status: [],
  });

  const [dateFields, setDateFields] = useState({
    startDateEdited: "",
    endDateEdited: "",
    startDateCreated: "",
    endDateCreated: "",
  });

  const [hasUserChanged, setHasUserChanged] = useState({
    clients: false,
    executives: false,
    presalesEngineers: false,
    teammates: false,
    tags: false,
  });

  useEffect(() => {
    if (!userViewsLoading && userViews.length > 0) {
      const {
        dateCreatedStart: startDateCreated,
        dateCreatedEnd: endDateCreated,
        dateEditedStart: startDateEdited,
        dateEditedEnd: endDateEdited,
      } = userViews[0].filters;

      setDateFields({
        startDateCreated: startDateCreated || "",
        endDateCreated: endDateCreated || "",
        startDateEdited: startDateEdited || "",
        endDateEdited: endDateEdited || "",
      });

      setUserSelections((prev) => ({
        ...prev,
        status: userViews[0].filters.status,
      }));
    }
  }, [userViewsLoading]);

  useEffect(() => {
    // Check if clients have been fetched
    if (!userViewsLoading && clients.length > 0) {
      // Create an array of Option objects based on the client data
      const clientOptions = clients
        .filter((client) => {
          return userViews[0].filters.clients.includes(client.id);
        })
        .map((client) => ({
          value: client.id.toString(), // Convert the client ID to a string
          label: client.name || "Unknown",
        }));

      // Set the initial state for clients
      setUserSelections((prev) => ({
        ...prev,
        clients: clientOptions,
      }));
    }
  }, [clients, userViewsLoading]);

  useEffect(() => {
    // Check if executives have been fetched
    if (!userViewsLoading && executives.length > 0) {
      // Create an array of Option objects based on the executive data
      const executiveOptions = executives
        .filter((exec) => {
          return userViews[0].filters.salesExecs.includes(exec.id);
        })
        .map((exec) => ({
          value: exec.id.toString(), // Convert the executive ID to a string
          label: exec.name || "Unknown",
        }));

      // Set the initial state for executives
      setUserSelections((prev) => ({
        ...prev,
        executives: executiveOptions,
      }));
    }
  }, [executives, userViewsLoading]);

  useEffect(() => {
    // Check if presales engineers have been fetched
    if (!userViewsLoading && presalesEngineers.length > 0) {
      // Create an array of Option objects based on the presales engineer data
      const presalesEngineerOptions = presalesEngineers
        .filter((eng) => {
          return userViews[0].filters.presalesEngineers.includes(eng.id);
        })
        .map((eng) => ({
          value: eng.id.toString(), // Convert the presales engineer ID to a string
          label: eng.name || "Unknown",
        }));

      // Set the initial state for presales engineers
      setUserSelections((prev) => ({
        ...prev,
        presalesEngineers: presalesEngineerOptions,
      }));
    }
  }, [presalesEngineers, userViewsLoading]);

  useEffect(() => {
    // Check if users have been fetched
    if (!userViewsLoading && users.length > 0) {
      // Create an array of Option objects based on the user data
      const userOptions = users
        .filter((user) => {
          return userViews[0].filters.teammates.includes(user.id);
        })
        .map((user) => ({
          value: user.id.toString(), // Convert the user ID to a string
          label: user.name || "Unknown",
        }));

      // Set the initial state for users
      setUserSelections((prev) => ({
        ...prev,
        teammates: userOptions,
      }));
    }
  }, [users, userViewsLoading]);

  useEffect(() => {
    // Check if tags have been fetched
    if (!userViewsLoading && tags.length > 0) {
      // Create an array of Option objects based on the tag data
      const tagOptions = tags
        .filter((tag) => {
          return userViews[0].filters.tags.includes(tag.id);
        })
        .map((tag) => ({
          value: tag.id.toString(), // Convert the tag ID to a string
          label: tag.name || "Unknown",
        }));

      // Set the initial state for tags
      setUserSelections((prev) => ({
        ...prev,
        tags: tagOptions,
      }));
    }
  }, [tags, userViewsLoading]);

  useEffect(() => {
    let count = 0;

    // Add count for userSelections filters
    Object.values(userSelections).forEach((selection) => {
      if (selection.length > 0) {
        count += 1;
      }
    });

    // Add count for dateFields filters
    Object.entries(dateFields).forEach(([key, value]) => {
      if (value) {
        count += 1;
      }
    });

    setFilterCount(count);
  }, [userSelections, dateFields]);

  useEffect(() => {
    onFilterCountChange(filterCount);
  }, [filterCount, onFilterCountChange]);

  const handleChange = (type) => (selectedOptions) => {
    setUserSelections((prev) => ({ ...prev, [type]: selectedOptions || [] }));
    setHasUserChanged((prev) => ({ ...prev, [type]: true }));
  };

  const handleDateChange = (field) => (event) => {
    setDateFields((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleSave = async () => {
    if (userViews.length === 0) {
      console.error("No user view available to update.");
      return;
    }

    try {
      const updatedUserView = {
        id: userViews[0].id,
        type: userViews[0].type,
        attributes: {
          name: userViews[0].name,
          filters: {
            clients: userSelections.clients.map((client) => client.value),
            salesExecs: userSelections.executives.map((exec) => exec.value),
            presalesEngineers: userSelections.presalesEngineers.map(
              (engineer) => engineer.value
            ),
            teammates: userSelections.teammates.map(
              (teammate) => teammate.value
            ),
            tags: userSelections.tags.map((tag) => tag.value),
            "created-at.after": dateFields.startDateCreated,
            "created-at.before": dateFields.endDateCreated,
            "updated-at.after": dateFields.startDateEdited,
            "updated-at.before": dateFields.endDateEdited,
            status: userSelections.status,
          },
        },
      };

      await triggerUpdateUserView(userViews[0].id, updatedUserView);
      setIsOpen(false);
      onClose();
    } catch (e) {
      console.error("Error saving user view:", e);
    }
  };

  const handleCancel = async () => {
    setDateFields({
      startDateEdited: "",
      endDateEdited: "",
      startDateCreated: "",
      endDateCreated: "",
    });
    setUserSelections({
      clients: [],
      executives: [],
      presalesEngineers: [],
      teammates: [],
      tags: [],
      status: [],
    });
    setFilterCount(0);
    await triggerUpdateUserView(userViews[0].id, {
      id: userViews[0].id,
      type: userViews[0].type,
      attributes: {
        name: userViews[0].name,
        filters: {},
      },
    });
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    },
    close: () => {
      setIsOpen(false);
      onClose();
    },
    save: handleSave,
  }));

  const statuses = {
    building: "Building",
    technical_approval: "Technical Approval",
    sales_approval: "Sales Approval",
    business_approval: "Business Approval",
    approved: "Approved",
    lost: "Lost",
    won: "Won",
  };

  return (
    <SlideOutMenu
      title="Your work at a glance"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onSave={handleSave}
      onClearAll={handleCancel}
      filterCount={filterCount}
    >
      {/* Temoorarily commented out */}
      {/* <div>
        <h3>Include Metrics</h3>
        <div className={styles.checkboxGroup}>
          <Form.Check
            type="checkbox"
            id="includeMetrics"
            label="Total Revenue Scoped"
          />
          <Form.Check
            type="checkbox"
            id="includeMetrics"
            label="Revenue Per Project"
          />
          <Form.Check
            type="checkbox"
            id="includeMetrics"
            label="Sales Approval"
          />
        </div>
        <hr className={styles.line}></hr>
      </div> */}
      <div className={styles.wrapper}>
        <h3>Project Info</h3>
        <MultiSelectAutocomplete
          label="Client Name"
          id="clientName"
          options={clients.map((client) => ({
            value: client.id,
            label: client.name,
          }))}
          value={userSelections.clients}
          onChange={handleChange("clients")}
        />
        <MultiSelectAutocomplete
          label="Sales Executive"
          id="salesExecutive"
          options={executives.map((exec) => ({
            value: exec.id,
            label: exec.name,
          }))}
          value={userSelections.executives}
          onChange={handleChange("executives")}
        />
        <MultiSelectAutocomplete
          label="Pre-Sales Engineer"
          id="preSalesEngineer"
          options={presalesEngineers.map((eng) => ({
            value: eng.id,
            label: eng.name,
          }))}
          value={userSelections.presalesEngineers}
          onChange={handleChange("presalesEngineers")}
        />
        <MultiSelectAutocomplete
          label="Teammates"
          id="teamnmates"
          options={users.map((user) => ({
            value: user.id,
            label: user.name,
          }))}
          value={userSelections.teammates}
          onChange={handleChange("teammates")}
        />
        <MultiSelectAutocomplete
          label="Tags"
          id="tags"
          options={tags.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }))}
          value={userSelections.tags}
          onChange={handleChange("tags")}
        />
        <hr className={styles.line}></hr>
      </div>
      <div className={styles.wrapper}>
        <h3>Status </h3>
        <div className={styles.checkboxGroup}>
          {Object.keys(statuses).map((status) => (
            <Form.Check
              type="checkbox"
              id="status"
              label={statuses[status]}
              key={status}
              checked={userSelections.status.includes(status)}
              onChange={(e) => {
                if (e.target.checked) {
                  setUserSelections((prev) => ({
                    ...prev,
                    status: [...prev.status, status],
                  }));
                } else {
                  setUserSelections((prev) => ({
                    ...prev,
                    status: prev.status.filter((s) => s !== status),
                  }));
                }
              }}
            />
          ))}
        </div>
        <hr className={styles.line}></hr>
      </div>
      <div className={styles.wrapper}>
        <h3>Date Edited</h3>
        <Row>
          <Col>
            <DateField
              label="Start Date"
              value={dateFields.startDateEdited}
              onChange={handleDateChange("startDateEdited")}
            />
          </Col>
          <Col>
            <DateField
              label="End Date"
              value={dateFields.endDateEdited}
              onChange={handleDateChange("endDateEdited")}
            />
          </Col>
        </Row>
        <hr className={styles.line}></hr>
      </div>
      <div className={styles.wrapper}>
        <h3>Date Created</h3>
        <Row>
          <Col>
            <DateField
              label="Start Date"
              value={dateFields.startDateCreated}
              placeholder={"mm/dd/yyyy"}
              onChange={handleDateChange("startDateCreated")}
            />
          </Col>
          <Col>
            <DateField
              label="End Date"
              value={dateFields.endDateCreated}
              placeholder={"mm/dd/yyyy"}
              onChange={handleDateChange("endDateCreated")}
            />
          </Col>
        </Row>
        <hr className={styles.line}></hr>
      </div>
    </SlideOutMenu>
  );
});

export default AtAGlance;
