import React, { useState, useEffect } from "react";
import CardHeader from "@components/CardHeader/CardHeader";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import { Card, Form, Row, Col } from "react-bootstrap";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import "./Note.css";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import { useNavigate } from "react-router-dom";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";
import FormTextField from "@components/Forms/FormTextField";

function Note({
  account_slug,
  project_id,
  projectStatus,
  notes,
  noteId,
  account_id,
  setNoteCreated,
  setNoteUpdated,
  currentUser,
}) {
  const [noteName, setNoteName] = useState(null);
  const [content, setContent] = useState(null);
  const [userOnNote, setUserOnNote] = useState("");
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showUnauthorizedAlert, setShowUnauthorizedAlert] = useState(false);
  const [noteIsEditable, setNoteIsEditable] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();
  const [authorizationCode] = useOauth();

  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  useEffect(() => {
    if (lastWordInLocation === "edit") {
      for (let i = 0; i < notes.length; i++) {
        if (notes[i].id === id) {
          setNoteName(notes[i].attributes.name);
          setContent(notes[i].attributes.content);
          setUserOnNote(notes[i].attributes.username);
          setNoteIsEditable(notes[i].attributes.editable);
        }
      }
    }
  }, [notes]);

  const redirectToProjectNotes = () => {
    navigate(`/projects/${project_id}/notes/`);
    window.location.reload();
  };

  const validateForm = () => {
    let isValid = true;
    if (noteIsEditable === false) {
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (lastWordInLocation === "edit") {
      // Validate if user can edit note
      const formIsValid = validateForm();
      if (formIsValid === false) {
        setShowUnauthorizedAlert(true);
        return;
      }

      const updatedNote = {
        data: {
          type: "notes",
          id: id,
          attributes: {
            name: noteName,
            content: content,
          },
        },
      };

      API.Patch(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/notes/${id}`,
        updatedNote,
        authorizationCode
      )
        .then((response) => {
          setNoteUpdated(true);
          setUpdatedAlertIsOpen(true);
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "note"));
          setShowFailAlert(true);
        });
    }

    if (lastWordInLocation === "new") {
      const newNote = {
        data: {
          type: "notes",
          attributes: {
            name: noteName,
            content: content,
          },
        },
      };

      API.Post(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/notes`,
        newNote,
        authorizationCode
      )
        .then((response) => {
          if (response.status == 201) {
            setNoteCreated(true);
            redirectToProjectNotes();
          }
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "note"));
          setShowFailAlert(true);
        });
    }
  };

  const noteUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Note updated"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  const handleUnauthorizedAlert = () => {
    if (showUnauthorizedAlert === true) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setShowUnauthorizedAlert(false)}
          text="You are unauthorized to edit this note. Only the person who created the note can edit it."
        />
      );
    }
  };

  return (
    <div>
      {noteUpdatedAlert()}
      {handleFailAlert()}
      {handleUnauthorizedAlert()}
      <Card>
        <CardHeader
          title={
            lastWordInLocation === "edit"
              ? `Note by ${userOnNote}`
              : `Note by ${currentUser}`
          }
        />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <FormTextField
              label="Note Name"
              value={noteName}
              onChange={(e) => setNoteName(e.target.value)}
            />
            <MultipleLineField
              label="Note Content"
              rows={5}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <Row>
              <Col xs={6}>
                <SeafoamButton
                  type="submit"
                  text="Save"
                  className="saveButton"
                />
              </Col>
              <Col xs={6}>
                <BackButton url={`/projects/${project_id}/notes`} />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Note;
