import { useListSurveysForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { SharedSurvey } from "../types";
import { getFormattedDate } from "@utils/helperFunctions";
import { V1SurveyResource } from "@generated";

const useListSharedSurveys = ({
  pageSize,
  pageNumber,
  filterParams,
  sortBy,
  sortOrder,
}) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListSurveysForAccountQuery({
      slug: accountSlug,
      filter: { ...filterParams, shared: true },
      page: {
        number: pageNumber,
        size: pageSize,
      },
      include: ["project", "questionnaire"],
      sort: sortBy
        ? [sortOrder == "asc" ? sortBy : `-${sortBy}`]
        : ["-created_at"],
    });

  let sharedSurveys: SharedSurvey[] = [];

  if (data?.data) {
    try {
      sharedSurveys = data.data.map((survey: V1SurveyResource) => {
        if (!survey.id) {
          throw new Error("Missing survey.id");
        }
        if (!survey.attributes.name) {
          throw new Error("Missing survey.attributes.name");
        }

        const projectId = String(survey.relationships.project?.data?.id);
        const questionnaireId = survey.relationships.questionnaire.data
          ?.id as number;

        return {
          id: String(survey.id),
          checked: false,
          attributes: {
            name: survey?.attributes?.name || "",
            status: survey?.attributes?.status || "",
            sentBy: survey?.attributes?.sender || "",
            sentTo: survey?.attributes?.emails || "",
            sentOn: survey.attributes?.["sent-at"]
              ? getFormattedDate(new Date(survey.attributes["sent-at"]))
              : "",
            questionnaireId,
            projectId,
          },
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    sharedSurveys,
    isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListSharedSurveys;
