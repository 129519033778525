import React, { useEffect } from "react";
import ServicesByPhase from "../ServicesByPhase";

function ApplyCustomService({
  subpage,
  setSubpage,
  services,
  resources,
  subservices,
  authorizationCode,
  phases,
  account_slug,
  projectId,
  rateType,
  resourceRatesEnabled,
  resourceRates,
  lobs,
}) {
  useEffect(() => {
    // Add grey background to body
    document.body.setAttribute("style", "background: #eee");

    // Remove screenoverlay from slideout on previous page
    if (document.querySelector(".screenOverlay")) {
      document.querySelector(".screenOverlay").remove();
    }
  }, []);
  let phasesWithServices = new Set();
  services.forEach((service) => {
    if (
      service.relationships["project-phase"] &&
      service.relationships["project-phase"].data
    ) {
      phases.forEach((phase) => {
        if (phase.id == service.relationships["project-phase"].data.id) {
          phasesWithServices.add(phase);
        }
      });
    }
  });

  return (
    <ServicesByPhase
      authorizationCode={authorizationCode}
      account_slug={account_slug}
      subpage={subpage}
      setSubpage={setSubpage}
      projectId={projectId}
      data={{
        data: services,
        resources,
        projectSubservices: subservices.data,
        phases: Array.from(phasesWithServices),
      }}
      resources={resources}
      rateType={rateType}
      resourceRatesEnabled={resourceRatesEnabled}
      resourceRates={resourceRates}
      lobs={lobs}
    />
  );
}

export default ApplyCustomService;
