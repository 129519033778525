import React from "react";
import Contact from "../Contact/Contact";

function EditContact({
  account_slug,
  project_id,
  projectStatus,
  contacts,
  contactId,
  account_id,
  setContactUpdated,
  contactTypes,
  clientContacts,
  accountContacts,
  clientId,
  projVariables
}) {
  return (
    <Contact
      account_slug={account_slug}
      project_id={project_id}
      projectStatus={projectStatus}
      contacts={contacts}
      contactId={contactId}
      account_id={account_id}
      setContactUpdated={setContactUpdated}
      contactTypes={contactTypes}
      clientContacts={clientContacts}
      accountContacts={accountContacts}
      clientId={clientId}
      projVariables={projVariables}
    />
  );
}

export default EditContact;