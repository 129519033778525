import SlideOutMenuV2 from "@components/SlideOutMenuV2";
import { useEffect, useState } from "react";
import DataTable from "@components/DataTable";
import {
  faPlus,
  faCheck,
  faX,
  faPencil,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";
import { Form } from "react-bootstrap";
import { TaskRate } from "../../types";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import { useListLOEs, useListTaskRates } from "../../api";
import {
  useCreateLevelOfEffortForServiceMutation,
  useUpdateLevelOfEffortForServiceMutation,
  useDeleteLevelOfEffortFromServiceMutation,
  useCreateTaskRateForServiceMutation,
  useUpdateTaskRateForServiceMutation,
  useDeleteTaskRateFromServiceMutation,
  V1TaskRateResource,
  useCreateLevelOfEffortForSubserviceMutation,
  useUpdateLevelOfEffortForSubserviceMutation,
  useDeleteLevelOfEffortFromSubserviceMutation,
  useCreateTaskRateForSubserviceMutation,
  useUpdateTaskRateForSubserviceMutation,
  useDeleteTaskRateFromSubserviceMutation,
} from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import UnsavedChangesModal from "@components/UnsavedChangesModal";

const AdvancedPricingSlideout = ({
  service,
  slideoutOpen,
  setSlideoutOpen,
}) => {
  const isService = service.type === "service";
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { loes, loesLoading, loesFetching, refetchLOEs } = useListLOEs(service);
  const [createLOE] = isService
    ? useCreateLevelOfEffortForServiceMutation()
    : useCreateLevelOfEffortForSubserviceMutation();
  const [updateLOE] = isService
    ? useUpdateLevelOfEffortForServiceMutation()
    : useUpdateLevelOfEffortForSubserviceMutation();
  const [deleteLOE] = isService
    ? useDeleteLevelOfEffortFromServiceMutation()
    : useDeleteLevelOfEffortFromSubserviceMutation();

  const { taskRates, taskRatesLoading, taskRatesFetching, refetchTaskRates } =
    useListTaskRates(service);
  const [createTaskRate] = isService
    ? useCreateTaskRateForServiceMutation()
    : useCreateTaskRateForSubserviceMutation();
  const [updateTaskRate] = isService
    ? useUpdateTaskRateForServiceMutation()
    : useUpdateTaskRateForSubserviceMutation();
  const [deleteTaskRate] = isService
    ? useDeleteTaskRateFromServiceMutation()
    : useDeleteTaskRateFromSubserviceMutation();

  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);

  const [initialVariableHrs, setInitialVariableHrs] = useState<TaskRate[]>([]);
  const [variableHrs, setVariableHrs] = useState<TaskRate[]>([]);

  const [initialFixedPrices, setInitialFixedPrices] = useState<TaskRate[]>([]);
  const [fixedPrices, setFixedPrices] = useState<TaskRate[]>([]);

  const [initialFixedCosts, setInitialFixedCosts] = useState<TaskRate[]>([]);
  const [fixedCosts, setFixedCosts] = useState<TaskRate[]>([]);

  const [focusedId, setFocusedId] = useState<string | null>(null);
  const [itemToDelete, setItemToDelete] =
    useState<{ type: string; id: string } | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
    useState<boolean>(false);

  const unsavedItem: boolean = [
    ...variableHrs,
    ...fixedPrices,
    ...fixedCosts,
  ].some((i) => i.editing && i.isChanged);

  useEffect(() => {
    if (service.id) {
      refetchLOEs();
      refetchTaskRates();
    }
  }, [service]);

  useEffect(() => {
    const prices = taskRates.filter((rate) => rate.type === "service_price");
    const costs = taskRates.filter((rate) => rate.type === "service_cost");

    if (
      !loesLoading &&
      !loesFetching &&
      !taskRatesLoading &&
      !taskRatesFetching
    ) {
      setInitialVariableHrs(loes);
      setVariableHrs(loes);

      setInitialFixedPrices(prices);
      setFixedPrices(prices);

      setInitialFixedCosts(costs);
      setFixedCosts(costs);
    }
  }, [loesLoading, loesFetching, taskRatesLoading, taskRatesFetching]);

  const getStateItems = (type) => {
    if (type === "service_price") {
      return {
        state: fixedPrices,
        setState: setFixedPrices,
        initial: initialFixedPrices,
        setInitial: setInitialFixedPrices,
      };
    } else if (type === "service_cost") {
      return {
        state: fixedCosts,
        setState: setFixedCosts,
        initial: initialFixedCosts,
        setInitial: setInitialFixedCosts,
      };
    }
    return {
      state: variableHrs,
      setState: setVariableHrs,
      initial: initialVariableHrs,
      setInitial: setInitialVariableHrs,
    };
  };

  const updateItem = (item) => {
    const { state, setState } = getStateItems(item.type);
    setState(
      state.map((i) => {
        return i.id === item.id ? item : i;
      })
    );
  };

  const cancelItem = (item) => {
    const { state, setState, initial } = getStateItems(item.type);
    if (!initial.find((i) => i.id === item.id)) {
      setState(state.filter((i) => i.id !== item.id));
    } else {
      setState(initial);
    }
  };

  const confirmItem = async (item) => {
    let data: V1TaskRateResource = {
      type: item.type === "variable_hour" ? "level-of-efforts" : "task-rates",
      attributes: {
        "minimum-quantity": item.minQty,
        "base-amount": item.fixedAmt,
        "unit-amount": item.unitAmt,
      },
    };

    if (item.type !== "variable_hour")
      data.attributes!["rate-type"] = item.type;

    try {
      const arg = {
        slug: accountSlug,
        [service.type === "service" ? "serviceId" : "subserviceId"]: service.id,
        body: { data },
      };

      const create = item.type === "variable_hour" ? createLOE : createTaskRate;
      const update = item.type === "variable_hour" ? updateLOE : updateTaskRate;

      const res = item.id.includes("new")
        ? // @ts-ignore
          await create(arg)
        : // @ts-ignore
          await update({
            ...arg,
            id: Number(item.id),
            body: { data: { ...arg.body.data, id: Number(item.id) } },
          });

      //@ts-ignore
      const id = res?.data?.data?.id;
      if (id) {
        setSuccessMessage(
          `${
            item.type == "variable_hour"
              ? "Variable hour"
              : item.type === "service_price"
              ? "Fixed price"
              : "Fixed cost"
          } ${item.id.includes("new") ? "created" : "updated"}`
        );
        setShowSuccessAlert(true);

        item.type === "variable_hour" ? refetchLOEs() : refetchTaskRates();
      } else {
        const message =
          //@ts-ignore
          res?.error?.data?.errors?.[0]?.detail ||
          "Something when wrong saving this item";
        setErrorMessage(message);
        setShowFailAlert(true);
        return;
      }
    } catch {
      setErrorMessage("Something went wrong");
      setShowFailAlert(true);
    }
  };

  const deleteItem = async () => {
    if (itemToDelete?.id) {
      const type = itemToDelete.type;
      const deleteFromAPI =
        type === "variable_hour" ? deleteLOE : deleteTaskRate;

      //@ts-ignore
      await deleteFromAPI({
        slug: accountSlug,
        [service.type === "service" ? "serviceId" : "subserviceId"]: service.id,
        id: Number(itemToDelete.id),
      });

      setSuccessMessage(
        `${
          type === "variable_hour"
            ? "Variable hours"
            : type === "fixed_price"
            ? "Fixed price"
            : "Fixed cost"
        } deleted`
      );
      setShowSuccessAlert(true);
      setShowConfirmDelete(false);
      type === "variable_hour" ? refetchLOEs() : refetchTaskRates();
    }
  };

  const tableColumns = [
    {
      header: "Min Qty",
      cell(props) {
        const item = props.row.original;
        return item.editing ? (
          <Form.Control
            type="number"
            step={1}
            defaultValue={item.minQty}
            onChange={(e) =>
              updateItem({
                ...item,
                minQty: e.target.value,
                isChanged: true,
              })
            }
            autoFocus={focusedId === item.id + item.type + "minQty"}
            onFocus={(e) => setFocusedId(item.id + item.type + "minQty")}
          />
        ) : (
          <div className="edit">
            <FontAwesomeIcon
              icon={faPencil}
              onClick={() => {
                if (unsavedItem) {
                  setShowUnsavedChangesModal(true);
                  return;
                }
                setFocusedId(item.id + item.type + "minQty");
                const { state, setState } = getStateItems(item.type);
                setState(
                  state.map((i) => {
                    return i.id === item.id
                      ? { ...i, editing: true }
                      : { ...i, editing: false };
                  })
                );
              }}
            />
            {item.minQty}
          </div>
        );
      },
    },
    {
      header: "Fixed Amt",
      cell(props) {
        const item = props.row.original;
        return item.editing ? (
          <Form.Control
            type="number"
            step={0.01}
            defaultValue={item.fixedAmt}
            onChange={(e) =>
              updateItem({
                ...item,
                fixedAmt: e.target.value,
                isChanged: true,
              })
            }
            autoFocus={focusedId === item.id + item.type + "fixedAmt"}
            onFocus={(e) => setFocusedId(item.id + item.type + "fixedAmt")}
          />
        ) : (
          item.fixedAmt
        );
      },
    },
    {
      header: "Unit Amt",
      cell(props) {
        const item = props.row.original;
        return item.editing ? (
          <>
            <Form.Control
              type="number"
              step={0.01}
              defaultValue={item.unitAmt}
              onChange={(e) =>
                updateItem({
                  ...item,
                  unitAmt: e.target.value,
                  isChanged: true,
                })
              }
              autoFocus={focusedId === item.id + item.type + "unitAmt"}
              onFocus={(e) => setFocusedId(item.id + item.type + "unitAmt")}
            />
            <div className="saveCancel">
              <FontAwesomeIcon
                icon={faCheck}
                onClick={() => confirmItem(item)}
              />
              <FontAwesomeIcon onClick={() => cancelItem(item)} icon={faX} />
            </div>
          </>
        ) : (
          <>
            {item.unitAmt}
            <FontAwesomeIcon
              icon={faTrashCan}
              onClick={() => {
                if (unsavedItem) {
                  setShowUnsavedChangesModal(true);
                  return;
                }
                setItemToDelete({
                  type: item.type,
                  id: item.id,
                });
                setShowConfirmDelete(true);
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <SlideOutMenuV2
      title="Advanced Cost & Effort"
      isOpen={slideoutOpen}
      onClose={() => {
        if (unsavedItem) {
          setShowUnsavedChangesModal(true);
          return;
        }
        setSlideoutOpen(false);
        setVariableHrs([]);
        setInitialVariableHrs([]);
        setFixedPrices([]);
        setInitialFixedPrices([]);
        setFixedCosts([]);
        setInitialFixedCosts([]);
      }}
    >
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <div className="advancedPricingSlideout">
        <section>
          <h3>Variable Hours</h3>
          {variableHrs.length ? (
            <DataTable
              data={variableHrs}
              columns={tableColumns}
              striped
              bordered
              hover
            />
          ) : (
            <></>
          )}
          <div
            className="add"
            onClick={() => {
              if (unsavedItem) {
                setShowUnsavedChangesModal(true);
                return;
              }
              const id = `new-${Date.now()}`;
              setFocusedId(id + "variable_hour" + "minQty");
              setVariableHrs([
                ...variableHrs.map((i) => {
                  return { ...i, editing: false };
                }),
                {
                  id,
                  type: "variable_hour",
                  minQty: 1,
                  fixedAmt: 0,
                  unitAmt: 0,
                  editing: true,
                  isChanged: true,
                },
              ]);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="addText">Add variable hours</span>
          </div>
        </section>
        <section>
          <h3>Fixed Price</h3>
          {fixedPrices.length ? (
            <DataTable
              data={fixedPrices}
              columns={tableColumns}
              striped
              bordered
              hover
            />
          ) : (
            <></>
          )}
          <div
            className="add"
            onClick={() => {
              if (unsavedItem) {
                setShowUnsavedChangesModal(true);
                return;
              }
              const id = `new-${Date.now()}`;
              setFocusedId(id + "service_price" + "minQty");
              setFixedPrices([
                ...fixedPrices.map((i) => {
                  return { ...i, editing: false };
                }),
                {
                  id,
                  type: "service_price",
                  minQty: 1,
                  fixedAmt: 0,
                  unitAmt: 0,
                  editing: true,
                  isChanged: true,
                },
              ]);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="addText">Add fixed price</span>
          </div>
        </section>
        <section>
          <h3>Fixed Cost</h3>
          {fixedCosts.length ? (
            <DataTable
              data={fixedCosts}
              columns={tableColumns}
              striped
              bordered
              hover
            />
          ) : (
            <></>
          )}
          <div
            className="add"
            onClick={() => {
              if (unsavedItem) {
                setShowUnsavedChangesModal(true);
                return;
              }
              const id = `new-${Date.now()}`;
              setFocusedId(id + "service_cost" + "minQty");
              setFixedCosts([
                ...fixedCosts.map((i) => {
                  return { ...i, editing: false };
                }),
                {
                  id,
                  type: "service_cost",
                  minQty: 1,
                  fixedAmt: 0,
                  unitAmt: 0,
                  editing: true,
                  isChanged: true,
                },
              ]);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="addText">Add fixed cost</span>
          </div>
        </section>
        <ConfirmationModal
          show={showConfirmDelete}
          title={`Delete ${
            itemToDelete?.type === "variable_hour"
              ? "Variable Hour"
              : itemToDelete?.type === "service_price"
              ? "Fixed Price"
              : "Fixed Cost"
          }`}
          message={"Are you sure you want to delete this item?"}
          onConfirm={deleteItem}
          onCancel={() => setShowConfirmDelete(false)}
        />
        <UnsavedChangesModal
          show={showUnsavedChangesModal}
          handleCancel={() => setShowUnsavedChangesModal(false)}
          handleDontSave={() => {
            cancelItem(
              [...variableHrs, ...fixedPrices, ...fixedCosts].find(
                (i) => i.isChanged
              )
            );
            setShowUnsavedChangesModal(false);
          }}
          handleSave={() => {
            confirmItem(
              [...variableHrs, ...fixedPrices, ...fixedCosts].find(
                (i) => i.isChanged
              )
            );
            setShowUnsavedChangesModal(false);
          }}
        />
      </div>
    </SlideOutMenuV2>
  );
};

export default AdvancedPricingSlideout;
