import React from "react";

function IconButton({ className, dataMethod, url, iconClass, buttonText }) {
  return (
      <a
        className={className}
        rel="nofollow"
        data-method={dataMethod}
        href={url}
      >
        <i className={iconClass}></i>
        {buttonText}
      </a>
  );
}

export default IconButton;
