import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import PricingTable from "../PricingTable/PricingTable";
import NavTabs from "@components/NavTabs/NavTabs";
import {
  getTotalRow,
  arrangeDataForTable,
} from "../../pricingUtils/pricingHelpers";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

function PricingByPhase({
  account_slug,
  project_id,
  phases,
  lobs,
  resources,
  projectLocations,
  governances,
  currencyUnit,
  projectPricings,
  projectStatus,
  oneTimeAdjustment,
  isLoading,
  rateType,
}) {
  const [phasesWithPricings, setPhasesWithPricings] = useState([]);
  const [governancePricings, setGovernancePricings] = useState([]);
  const [serviceProjectPricings, setServiceProjectPricings] = useState([]);

  useEffect(() => {
    // Set array of service-pricings
    let professionalServicePricings = projectPricings.filter((service) =>
      service.id.toString().includes("project-services")
    );
    setServiceProjectPricings(
      professionalServicePricings.filter(
        (service) => service.attributes["service-type"] == "professional"
      )
    );

    // Set state for phases with pricings so a phase card without pricings does not render
    let phasesWithPricings = new Set();
    phases.forEach((phase) => {
      projectPricings.forEach((projectPricing) => {
        if (
          projectPricing.attributes.phase &&
          projectPricing.attributes.phase.name === phase.attributes.name &&
          projectPricing.attributes["service-type"] == "professional"
        ) {
          phasesWithPricings.add(phase);
        }
      });
    });
    setPhasesWithPricings(Array.from(phasesWithPricings));

    // Set governancePricing items for governance cards
    setGovernancePricings(
      projectPricings.filter(
        (pricing) => pricing.id.split("-")[1] === "governances"
      )
    );
  }, [
    currencyUnit,
    resources,
    governances,
    projectPricings,
    phases,
    isLoading,
  ]);

  let governanceTableData = arrangeDataForTable(
    governancePricings,
    resources,
    lobs
  ).filter((item) => item.revenue !== 0 || item.totalCost !== 0);

  governanceTableData.push(getTotalRow(governanceTableData, oneTimeAdjustment));

  return (
    <>
      <NavTabs
        tabOneText="By LOB"
        tabOneUrl={`/projects/${project_id}/sow_pricings`}
        tabTwoText="By Phase"
        tabTwoUrl={`/projects/${project_id}/sow_pricings/phase`}
        tabThreeText={serviceProjectPricings.length == 0 ? null : "By Service"}
        tabThreeUrl={`/projects/${project_id}/sow_pricings/service`}
        tabFourText={projectLocations.length >= 2 ? "By Location" : null}
        tabFourUrl={`/projects/${project_id}/sow_pricings/locations`}
      />
      {isLoading ? (
        <div className="col-sm-12" id="sow-workarea">
          <div style={{ marginTop: "20px" }}>
            <ScopeStackSpinner />
          </div>
        </div>
      ) : (
        <div className="col-sm-12" id="sow-workarea">
          {phasesWithPricings.map((phase) => {
            let servicesPerPhase = [];
            projectPricings.forEach((service) => {
              let phaseData = service.attributes.phase;
              if (
                phaseData !== null &&
                phaseData.name === phase.attributes.name &&
                service.attributes["service-type"] == "professional"
              ) {
                servicesPerPhase.push(service);
              }
            });

            let phaseTableData = arrangeDataForTable(
              servicesPerPhase,
              resources,
              lobs
            ).filter((item) => item.revenue !== 0 || item.totalCost !== 0);

            phaseTableData.push(getTotalRow(phaseTableData, 0.0));
            return (
              <Card key={phase.id} className="marginCard">
                <CardHeader title={`${phase.attributes.name} Pricing`} />
                <Card.Body>
                  <PricingTable
                    array={phaseTableData}
                    currencyUnit={currencyUnit}
                    rateType={rateType}
                  />
                </Card.Body>
              </Card>
            );
          })}
          <Card className="marginCard">
            <CardHeader title="Project Governance Pricing" />
            <Card.Body>
              <PricingTable
                array={governanceTableData}
                currencyUnit={currencyUnit}
                rateType={rateType}
              />
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
}

export default PricingByPhase;
