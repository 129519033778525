import { Row, Col, FormCheck, Form } from "react-bootstrap";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import { normalizeCasing } from "./helpers";

export const buildConnectedAppAuthForm = (fields, setFields) => {
  return fields.map((field, i) => {
    return (
      <div key={i}>
        {field.input === "checkbox" ? (
          <div className="authSelect">
            <FormCheck
              id={field.label}
              key={field.label}
              checked={field.value === "1"}
              inline
              onChange={(e) => {
                const checked = e.target.checked;
                setFields(
                  fields.map((f) =>
                    field.label == f.label
                      ? {
                          ...f,
                          value: checked ? "1" : "0",
                        }
                      : f
                  )
                );
              }}
            />
            <div className={`selectInfo ${field?.help ? "help" : ""}`}>
              <div>{field.label}</div>
              {field?.help && <span className="helpText">{field.help}</span>}
            </div>
          </div>
        ) : (
          <Form.Group className="formGroup">
            <FormFieldLabel className={""} label={`* ${field.label}`} />
            <Form.Control
              type={field.input}
              placeholder={`Enter ${field.label}`}
              defaultValue={field.value}
              onChange={(e) =>
                setFields(
                  fields.map((f) =>
                    field.label == f.label
                      ? {
                          ...f,
                          value: e.target.value,
                        }
                      : f
                  )
                )
              }
              required
            />
            {field?.help && <span className="helpText">{field.help}</span>}
          </Form.Group>
        )}
      </div>
    );
  });
};

export const buildConnectedAppFeatures = (categories, setCategories) => {
  return categories.map((option, i) => {
    return (
      <div
        key={i}
        className={`integrationOption ${option.checked ? "active" : ""}`}
      >
        <FormCheck
          required={false}
          id={option.category}
          className="option"
          inline
          key={option.category}
          checked={option.checked}
          onChange={(e) => {
            const updated = categories.map((c) =>
              c.category == e.target.id ? { ...c, checked: !c.checked } : c
            );
            setCategories(updated);
          }}
        />{" "}
        {normalizeCasing(option.category)}
      </div>
    );
  });
};
