import React from "react";
import { Card } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import { renderResourceName } from "@utils/helperFunctions";

function Section({
  projectServices,
  projectSubservices,
  lobs,
  resources,
  array,
  relationshipName,
  rateType,
}) {
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);

  const renderDecimal = (val) => {
    let output = parseFloat(val);
    if (isNaN(output)) return val;
    return output.toFixed(2).toString();
  };

  const getTableData = (arr) => {
    return arr.map((service) => {
      let quantity = service.attributes.quantity;
      // If the service has custom hours make the hours = the total
      let hours = 0;
      if (service.attributes["custom-hours?"] == true){
        hours = service.attributes["extended-hours"];
      }
      else {
        hours = service.attributes["override-hours"];
      }
      let total = service.attributes["extended-hours"];
      let resource = "";

      // If the source is third-party, use external resource name for resource
      if (service.attributes["task-source"] === "third_party") {
        resource = service.attributes["external-resource-name"];
      }

      // Loop over resources and match by id to get name
      for (let i = 0; i < resources.length; i++) {
        if (
          resource == "" &&
          service.relationships["project-resource"] &&
          service.relationships["project-resource"].data &&
          service.relationships["project-resource"].data.id == resources[i].id
        ) {
          resource = renderResourceName(resources[i], lobs);
        }
      }
      let serviceName = "";
      if (service.type === "project-subservices") {
        serviceName = <span>&nbsp;&nbsp;- {service.attributes.name}</span>;
      }
      if (service.type === "project-services") {
        serviceName = service.attributes.name;
      }

      return {
        rowClass: "summariesRow",
        onClick: null,
        columns: [
          {
            class: "",
            name: serviceName,
          },
          {
            class: "",
            name: resource,
          },
          {
            class: "text-right",
            name: quantity,
          },
          {
            class: "text-right",
            name: renderDecimal(hours),
          },
          {
            class: "text-right",
            name: renderDecimal(total),
          },
        ],
        actions: null,
      };
    });
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Service",
      },
      {
        class: "",
        name: "Resource",
      },
      {
        class: "text-right",
        name: "Quantity",
      },
      {
        class: "text-right",
        name: rateType,
      },
      {
        class: "text-right",
        name: "Total",
      },
    ],
  };
  const footerData = null;

  const renderCards = () => {
    return array.map((item, index) => {
      let services = [];
      let grandTotal = [];
      for (let i = 0; i < projectServices.length; i++) {
        if (
          projectServices[i].relationships[`${relationshipName}`].data &&
          projectServices[i].relationships[`${relationshipName}`].data.id ===
            item.id
        ) {
          services.push(projectServices[i]);
          grandTotal.push(projectServices[i].attributes["total-hours"]);
          let totalRow = {
            attributes: {
              name: "",
              quantity: "",
              "override-hours": "Total: ",
              "extended-hours": projectServices[i].attributes["total-hours"],
            },
            relationships: {
              resource: "",
            },
          };
          for (let j = 0; j < projectSubservices.length; j++) {
            let count = 0;
            count += 1;
            if (
              projectServices[i].id ===
              projectSubservices[j].relationships["project-service"].data.id
            ) {
              services.push(projectSubservices[j]);
            }
            if (j + count === projectSubservices.length) {
              services.push(totalRow);
            }
          }
        }
      }
      let total = 0;
      if (grandTotal.length !== 0) {
        total = grandTotal.reduce((a, b) => parseFloat(a) + parseFloat(b));
      }
      let grandTotalRow = {
        attributes: {
          name: "",
          quantity: "",
          "override-hours": "Grand Total: ",
          "extended-hours": total,
        },
        relationships: {
          resource: "",
        },
      };
      services.push(grandTotalRow);

      const tableData = getTableData(services);

      let summariesByItemTable = new ScopeStackTable(
        "services",
        headerData,
        tableData,
        footerData
      );
      const getTitle = () => {
        if (lastWordInLocation === "location") {
          let address = item.attributes.street;
          let street2 = item.attributes.street2;
          let city = item.attributes.city;
          let state = item.attributes.state;
          let zip = item.attributes["postal-code"];
          let country = item.attributes.country;
          return `${item.attributes.name}: ${
            address !== null ? address : ""
          },  ${street2 !== null ? street2 : ""} ${city !== null ? city : ""} ${
            state !== null ? state : ""
          } ${zip !== null ? zip : ""} ${
            country !== null ? country.toUpperCase() : ""
          }`;
        } else {
          return item.attributes.name;
        }
      };
      return (
        <Card key={index} className="marginCard">
          <CardHeader title={getTitle()} />
          <Card.Body>
            <BaseTable
              className="scopestack summariesTable"
              striped={true}
              hover={true}
              bordered={false}
              headerRows={summariesByItemTable.buildHeaderRows()}
              dataRows={summariesByItemTable.buildDataRows()}
              footerRows={summariesByItemTable.buildFooterRows()}
            />
          </Card.Body>
        </Card>
      );
    });
  };

  return <>{renderCards()}</>;
}

export default Section;
