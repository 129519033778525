import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import ProductPricing from "./ProductPricing";
import EditProductPricing from "./EditProductPricing";

function ProductsMspa({ account_slug, project_id, account_id }) {
  // Set the State
  const [projectStatus, setProjectStatus] = useState("");

  const [authorizationCode] = useOauth();
  const [productId, setProductId] = useState(null);
  const [products, setProducts] = useState([]);
  const [projectProducts, setProjectProducts] = useState([]);
  const [currencyUnit, setCurrencyUnit] = useState("");
  const [productPricings, setProductPricings] = useState([]);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get Project Pricings
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-pricings`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setProductPricings(
          data.filter((item) => item.attributes["service-type"] == "materials")
        );
      });

      // Get Project Info with products included
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-products`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        const includedData = response.data.included;
        setProjectStatus(data.attributes.status);
        setCurrencyUnit(data.attributes["field-labels"].currency_unit);
        if (includedData) {
          setProjectProducts(
            includedData.filter((product) => product.attributes.active === true)
          );
        }
      });

      API.Get(`${apiHost}/${account_slug}/v1/products`, authorizationCode).then(
        (response) => {
          let data = response.data.data;
          setProducts(data);
          setIsLoading(false);
        }
      );
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/project_material_pricings"}
            element={
              <ProductPricing
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                productId={productId}
                setProductId={(productId) => setProductId(productId)}
                projectStatus={projectStatus}
                products={products}
                projectProducts={projectProducts}
                currencyUnit={currencyUnit}
                productPricings={productPricings}
                isLoading={isLoading}
              />
            }
          />
          <Route
            path={
              "/projects/:project_id/project_material_pricings/:productId/edit"
            }
            element={
              <EditProductPricing
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                products={products}
                projectProducts={projectProducts}
                productId={productId}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default ProductsMspa;
