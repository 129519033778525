import API from "@API";

const loadProjectPhases = async ({
  account_slug,
  authorizationCode,
  projectId,
}) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const url = new URL(
    `/${account_slug}/v1/projects/${projectId}/project-phases`,
    apiHost
  );

  try {
    const response = await API.Get(url.toString(), authorizationCode);

    return { data: response.data.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export default loadProjectPhases;
