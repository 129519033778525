import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import "../NewProjectDetails/NewProjectDetails.css";

function CreateProjectFooter({
  continueButtonType,
  continueButtonOnClick,
  disabled,
  previousTabName,
  backButtonOnClick,
  continueButtonText,
}) {
  return (
    <div className="whiteBg createProjectFooter">
      <Row>
        <Col sm={11}>
          <Button
            onClick={backButtonOnClick}
            variant="btn-secondary"
            className="underlinedBackButton"
          >{`< Back To ${previousTabName}`}</Button>
        </Col>
        <Col sm={1}>
          <Button
            variant="btn-secondary"
            className="btnSeafoam"
            type={continueButtonType}
            onClick={continueButtonOnClick}
            disabled={disabled}
          >
            {continueButtonText ? continueButtonText : "Continue"}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CreateProjectFooter;
