import React, { useState, useEffect, useImperativeHandle } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  V1AccountResource,
  useCreateDocumentTemplateMutation,
  useGetFilenameFormatOptionsQuery,
  useUpdateDocumentTemplateMutation,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { handleAlert, snakeToText } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { DocumentTemplate } from "../Show/types";
import FormTextField from "@components/Forms/FormTextField";
import { OptionType } from "../../../VendorQuotesV2/types/Quote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { FileUploadDragAndDrop } from "@components/FormsV2/FileUploadDragAndDrop";
import { MultiValue } from "react-select";
import "../Show/style.css";
import fileToBase64 from "@utils/fileToBase64";
import CreatableSelect from "react-select/creatable";

interface Props {
  documentTemplateProp?: DocumentTemplate;
  account: {
    data: V1AccountResource;
  };
  permission: string;
}

const DocumentTemplateForm = React.forwardRef(
  ({ documentTemplateProp, account, permission }: Props, ref) => {
    // Redux / ENV
    const { accountSlug } = useSelector((state: RootState) => state.slug);

    // Get Filename Format Options
    const {
      data: filenameFormats,
      error: filenameFormatsError,
      isLoading: filenameFormatsLoading,
      refetch: filenameFormatsRefetch,
    } = useGetFilenameFormatOptionsQuery({
      slug: accountSlug,
    });

    // Location States / React Router
    const location = window.location.href;
    const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
    var locationArr = location.split("/");
    var id = locationArr[locationArr.length - 1];
    let navigate = useNavigate();

    // Mutations
    const [createDocumentTemplate] = useCreateDocumentTemplateMutation();
    const [updateDocumentTemplate] = useUpdateDocumentTemplateMutation();

    useImperativeHandle(ref, () => ({
      saveDocumentTemplate,
    }));

    // Alert States
    const [errorMessages, setErrorMessages] = useState<string | string[]>(
      "Something went wrong! Your changes could not be saved at this time."
    );
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState(
      "Document Template saved successfully!"
    );
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    // Form Field States
    const [templateName, setTemplateName] = useState(
      documentTemplateProp?.name || ""
    );
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [selectedFilename, setSelectedFilename] =
      useState<null | MultiValue<OptionType>>(
        documentTemplateProp?.filenameFormat?.[0]?.split(",").map((item) => {
          return { label: snakeToText(item), value: item };
        }) || null
      );
    const [typedFilename, setTypedFilename] =
      useState<undefined | string>(undefined);
      const [mergeTemplateFilename, setMergeTemplateFilename] = useState<string>(documentTemplateProp?.mergeTemplateFilename || "")

    const validateForm = () => {
      let isValid = true;
      if (templateName === "") {
        setErrorMessages(
          "You must add a template name before you can save the document template!"
        );
        setShowFailAlert(true);
        isValid = false;
      }
      if (!documentTemplateProp && uploadedFile === null) {
        setErrorMessages(
          "You must upload a file before you can save the document template!"
        );
        setShowFailAlert(true);
        isValid = false;
      }
      if (selectedFilename === null) {
        setErrorMessages(
          "You must select a filename before you can save the document template!"
        );
        setShowFailAlert(true);
        isValid = false;
      }
      return isValid;
    };

    const saveDocumentTemplate = async () => {
      const formIsValid = validateForm();
      if (!formIsValid) {
        return;
      }
      const encodedFile = uploadedFile ? await fileToBase64(uploadedFile) : "";
      let documentTemplateData: any = {
        type: "document-templates",
        id: id,
        attributes: {
          name: templateName,
          "filename-format": selectedFilename?.map(
            (filename) => filename.value
          ),
          "merge-template": encodedFile,
          "merge-template-filename": mergeTemplateFilename,
        },
        relationships: {
          account: {
            data: {
              type: "accounts",
              id: account?.data?.attributes?.["account-id"],
            },
          },
        },
      };

      if (lastWordInLocation !== "new") {
        updateDocumentTemplate({
          slug: accountSlug,
          id: Number(documentTemplateData.id),
          body: { data: documentTemplateData },
        })
          .unwrap()
          .then((response) => {
            if (response?.data?.id) {
              setSuccessMessage("Document Template updated successfully!");
              setShowSuccessAlert(true);
            }
          })
          .catch((err) => {
            let msg = err?.data?.errors?.[0]?.detail;
            setErrorMessages(
              msg
                ? msg
                : "Something went wrong. Your changes could not be saved at this time."
            );
            setShowFailAlert(true);
          });
      } else {
        delete documentTemplateData.id;
        createDocumentTemplate({
          slug: accountSlug,
          body: { data: documentTemplateData },
        })
          .unwrap()
          .then((response) => {
            if (response?.data?.id) {
              const template = response.data;
              navigate(`/admin/document_templates/edit/${response?.data?.id}`, {
                state: {
                  new: true,
                  documentTemplate: {
                    id: template.id,
                    name: template.attributes.name,
                    active: template.attributes?.["active"],
                    format: template.attributes.format,
                    filenameFormat: template.attributes?.["filename-format"],
                    mergeTemplate: template.attributes?.["merge-template"],
                    mergeTemplateFilename: template.attributes?.["merge-template-filename"],
                  },
                },
              });
            }
          })
          .catch((err) => {
            let msg = err?.data?.errors?.[0]?.detail;
            setErrorMessages(
              msg
                ? msg
                : "Something went wrong. Your changes could not be saved at this time."
            );
            setShowFailAlert(true);
          });
      }
    };

    const filenameFormatOptions = filenameFormats?.["filename_format"]?.map(
      (format) => {
        return { label: snakeToText(format), value: format };
      }
    );

    const handleFileSelect = (file: File) => {
      if (!file.type.includes("doc")) {
        setErrorMessages("The uploaded file must be a word document.");
        setShowFailAlert(true);
      } else if (!(file.size > 5242880)) {
        setUploadedFile(file);
        setMergeTemplateFilename(file.name);
      }
    };

    if (!account || !filenameFormats) {
      return <SmallSpinner />;
    }

    return (
      <Form style={{ marginLeft: "16px" }} className="documentTemplateForm">
        {handleAlert(
          showFailAlert,
          errorMessages,
          setShowFailAlert,
          "warning",
          ToastAlert
        )}
        {handleAlert(
          showSuccessAlert,
          successMessage,
          setShowSuccessAlert,
          "success",
          ToastAlert
        )}
        <Row>
          <Col>
            <FormTextField
              label="* Name"
              id="templateName"
              placeholder=""
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              required={true}
              readOnly={permission === "view"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="labelLike">
              {uploadedFile
                ? "Uploaded Merge Template"
                : "*  Upload Merge Template"}
            </label>
            {uploadedFile || (mergeTemplateFilename && mergeTemplateFilename !== "") || permission === "view" ? (
              <div>
                <span>
                  {mergeTemplateFilename}{" "}
                  <FontAwesomeIcon
                    style={{
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    icon={faTrashCan}
                    onClick={() => setUploadedFile(null)}
                  />
                </span>
              </div>
            ) : (
              <FileUploadDragAndDrop
                handleFileSelect={handleFileSelect}
                text={
                  documentTemplateProp
                    ? "Click or drag and drop a file to replace the current template"
                    : undefined
                }
              />
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <Form.Group className="formGroup">
              <Form.Label>* Filename</Form.Label>
              <p style={{ marginTop: "-4px" }} className="instructionalText14">
                Use the field below to configure how your generated files will
                be named. You may choose standard items that represent project
                values or add custom text by creating your own token.
              </p>
              <CreatableSelect
                onChange={(e) =>
                  setSelectedFilename(e as MultiValue<OptionType>)
                }
                onInputChange={(e) => {
                  setTypedFilename(e);
                }}
                className="basic-single"
                options={filenameFormatOptions}
                isDisabled={permission === "view"}
                placeholder={""}
                isMulti={true}
                value={selectedFilename}
                inputValue={typedFilename}
                onMenuOpen={() => null}
                onMenuClose={() => null}
                isSearchable={true}
                name={typedFilename}
              />
            </Form.Group>
          </Col>
        </Row>
        {selectedFilename ? (
          <Row>
            <Col>
              <div className="exampleFilename flexCenter">
                <div>
                  <strong>Example Filename: </strong>
                </div>
                &nbsp;
                <div>
                  {selectedFilename
                    .map((filename) => {
                      const foundFilename = filenameFormatOptions.find(
                        (option) => option.value === filename.value
                      );
                      let itemToRender = filename.value;
                      const today = new Date();
                      if (filename.value === "current_time") {
                        itemToRender = today
                          .toLocaleTimeString()
                          .replace(" ", "");
                      } else if (filename.value === "current_date") {
                        itemToRender = today.toJSON().slice(0, 10);
                      } else if (!foundFilename) {
                        itemToRender = filename.value;
                      } else {
                        itemToRender = `my-${filename.value
                          .toString()
                          .replace("_", "-")}`;
                      }
                      return itemToRender;
                    })
                    .join("-")}
                  .docx
                </div>
              </div>
            </Col>
          </Row>
        ) : null}
      </Form>
    );
  }
);

export default DocumentTemplateForm;
