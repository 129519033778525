import React from "react";
import Version from "../Version/Version";

function EditVersion({
  account_slug,
  project_id,
  projectStatus,
  versions,
  versionId,
  account_id,
  setVersionUpdated,
  currentUser,
  authorizationCode,
}) {
  return (
    <Version
      account_slug={account_slug}
      project_id={project_id}
      projectStatus={projectStatus}
      versions={versions}
      currentUser={currentUser}
      versionId={versionId}
      account_id={account_id}
      setVersionUpdated={setVersionUpdated}
      authorizationCode={authorizationCode}
    />
  );
}

export default EditVersion;
