import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import {
  useGetServiceTaskRatesQuery,
  useGetSubserviceTaskRatesQuery,
} from "@generated";
import { TaskRate } from "../types";

const useListTaskRates = (service) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError

  const { data, error, isLoading, refetch, isFetching } =
    service.type == "service"
      ? useGetServiceTaskRatesQuery({
          slug: accountSlug,
          serviceId: service.id,
        })
      : useGetSubserviceTaskRatesQuery({
          slug: accountSlug,
          subserviceId: service.id,
        });

  let taskRates: TaskRate[] = [];

  if (data?.data) {
    try {
      taskRates = data.data.map((taskRate) => {
        if (!taskRate.id) {
          throw new Error("Missing taskRate.id");
        }

        return {
          id: String(taskRate.id),
          type: taskRate?.attributes?.["rate-type"] as
            | "service_price"
            | "service_cost",
          minQty: taskRate?.attributes?.["minimum-quantity"] || 1,
          fixedAmt: taskRate?.attributes?.["base-amount"] || 0,
          unitAmt: taskRate?.attributes?.["unit-amount"] || 0,
        };
      });
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    taskRates,
    taskRatesLoading: isLoading,
    error: error ?? customError,
    refetchTaskRates: refetch,
    taskRatesFetching: isFetching,
  };
};
export default useListTaskRates;
