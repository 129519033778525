import React from "react";
import IconTextButton from "@components/Buttons/IconTextButton/IconTextButton";

function OfferServicesBtn({ onClick, style }) {
  return (
    // <IconTextButton
    // className="btn btn-success offerServicesBtn"
    // dataMethod="put"
    // // url={`/external_requests/${requestId}/offer`}
    // onClick={onClick}
    // iconClass="fa fa-share-alt"
    // buttonText="Offer Services"
    // />
    <span style={style} onClick={onClick}>
      <p className="btn btn-success offerServicesBtn">
        <i className="fa fa-share-alt"></i> Offer Services
      </p>
    </span>
  );
}

export default OfferServicesBtn;
