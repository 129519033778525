import { css } from "@emotion/css";
import useTheme from "@common/hooks/useTheme";

const useStyles = () => {
  const theme = useTheme();

  const baseButton = css`
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    padding: 8px 16px;
    align-items: flex-start;
  `;

  const primary = css`
    ...${baseButton}
    color: ${theme.colors.white};
    background-color: ${theme.colors.teal["1"]};
    border: 2px solid ${theme.colors.teal["1"]};
  `;

  const secondary = css`
    ...${baseButton}
    color: ${theme.colors.teal["1"]};
    background-color: ${theme.colors.white};
    border: 2px solid ${theme.colors.teal["1"]};
  `;

  const outline = css`
    ...${baseButton}
    color: ${theme.colors.white};
    background-color: ${theme.colors.teal["1"]};
    border: 2px solid ${theme.colors.white};
  `;

  return {
    primary,
    secondary,
    outline,
  };
};

export default useStyles;
