import { useListFreeTrialsForAccountQuery } from "../../../../services/generated";
import { camelizeHyphenCaseKeys } from "@utils/helpers";
import { Trial } from "app/javascript/src/types";

const useGetFreeTrials = (slug: string) => {
  const { data, error, isLoading } = useListFreeTrialsForAccountQuery({ slug });
  let trials: Trial[] = [];

  if (data?.data) {
    trials = camelizeHyphenCaseKeys(data.data);
  }

  return { data: trials, error, isLoading };
};

export default useGetFreeTrials;
