import React from "react";
import { Table } from "react-bootstrap";
import { Droppable, DragDropContext } from "react-beautiful-dnd";
import SmallSpinner from "../SmallSpinner/SmallSpinner";


function DraggableTable({
  headerRows,
  dataRows,
  footerRows,
  striped,
  hover,
  bordered,
  className,
  isLoading,
  onDragEnd,
  droppableId,
  onDragStart
}) {
  if (isLoading) {
    return <SmallSpinner />;
  } else {
    return (
      <Table
        className={className}
        striped={striped}
        hover={hover}
        bordered={bordered}
      >
        {headerRows !== null ? <thead>{headerRows}</thead> : null}
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Droppable droppableId={droppableId}>
            {(provided) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {provided.placeholder}
                {dataRows !== null ? dataRows : null}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
        {footerRows !== null ? <tfoot>{footerRows}</tfoot> : null}
      </Table>
    );
  }
}

export default DraggableTable;
