import React, { useCallback, useContext, useEffect, useState } from "react";
import BaseTable from "../../../../../components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "../../../../../components/Tables/ScopeStackTable";
import FormFieldLabel from "../../../../../components/Forms/FormFieldLabel/FormFieldLabel";
import SearchField from "../../../../../components/Forms/SearchField/SearchField";
import loadQuestionnaires from "./api/loadQuestionnaires";
import { Form, Button } from "react-bootstrap";
import "./SelectSurvey.css";
import { AccountContext } from "../../../ProjectsMspa";

const SelectSurvey = ({ setStep, setQuestionnaire, setQuestions }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [surveyToSearch, setSurveyToSearch] = useState("");
  const [allQuestions, setAllQuestions] = useState({});
  const account = useContext(AccountContext);

  const fetchData = useCallback(async () => {
    if (!isLoading) {
      return;
    }

    const data = await loadQuestionnaires({
      account,
      surveyToSearch,
    });
    setQuestionnaires(data.data);
    setAllQuestions(
      data.included
        .filter((included) => included.type === "questions")
        .sort((a, b) => a.attributes.position - b.attributes.position)
    );
    setIsLoading(false);
  }, [isLoading, surveyToSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const headerData = {
    rowClass: "serviceHeaderRow",
    columns: [
      {
        class: "col-3",
        name: "Survey Name",
      },
      {
        class: "col-7",
        name: "Description",
      },
      {
        class: "col-4",
        name: "Tags",
      },
    ],
  };

  const buildTableData = () => {
    if (!questionnaires || questionnaires.length === 0) {
      return [];
    }

    return questionnaires.map((questionnaire) => {
      return {
        rowClass: "surveyRow",
        onClick: () => {
          setQuestionnaire(questionnaire);
          setQuestions(
            questionnaire.relationships.questions.data.map((q) => {
              return allQuestions.find((question) => {
                return q.id === question.id;
              });
            })
          );
          setStep(2);
        },
        columns: [
          {
            class: "col-3 surveyLink",
            name: questionnaire.attributes.name,
          },
          {
            class: "col-7 ",
            name: questionnaire.attributes.introduction,
          },
          {
            class: "",
            name: questionnaire.attributes["tag-list"] && (
              <div>
                {questionnaire.attributes["tag-list"].map((tag) => (
                  <Button
                    key={tag}
                    style={{
                      color: "#fff",
                      backgroundColor: "#63c4ab",
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                  >
                    {tag}
                  </Button>
                ))}
              </div>
            ),
          },
        ],
      };
    });
  };

  const surveyTable = new ScopeStackTable(
    "surveys",
    headerData,
    buildTableData(),
    null
  );

  return (
    <>
      <div
        style={{ fontSize: "20px", margin: "0 0 30px 0" }}
        className="accordianSubtitle"
      >
        Select a Survey
      </div>
      <FormFieldLabel label="Search Surveys" />
      <Form
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            setIsLoading(true);
          }
        }}
        onSubmit={(e) => {
          e.preventDefault();
          setIsLoading(true);
        }}
      >
        <SearchField
          placeholder="Search by survey name"
          value={surveyToSearch}
          onChange={(e) => setSurveyToSearch(e.target.value)}
          onClick={() => {
            setSurveyToSearch("");
          }}
          fullWidth={true}
        />
      </Form>
      <div style={{ marginTop: "40px" }}>
        <BaseTable
          className={"sansTable"}
          striped={true}
          hover={true}
          bordered={true}
          headerRows={surveyTable.buildHeaderRows()}
          dataRows={surveyTable.buildDataRows()}
          footerRows={surveyTable.buildFooterRows()}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default SelectSurvey;
