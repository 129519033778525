import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import CardHeader from "@components/CardHeader/CardHeader";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import { useNavigate } from "react-router-dom";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import {
  removeDuplicates,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";

function UpdateComponent({
  account_slug,
  project_id,
  projectStatus,
  outcomes,
  outcomeId,
  account_id,
  type,
  secondHeader,
  authorizationCode,
}) {
  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);

  const [outcomesChanged, setOutcomesChanged] = useState([]);

  const location = window.location.href;
  const adoptionLocation = `${appHost}/projects/${project_id}/adoption_barriers`;
  const strategiesLocation = `${appHost}/projects/${project_id}/success_strategies`;

  const getTableData = () => {
    if (outcomes.length === 0) {
      return [];
    }
    // Map over outcomes & return data for ScopeStackTable
    return outcomes.map((outcome) => {
      let outcomeId = outcome.id;

      // Map returns a table row for each Outcome
      return {
        rowClass: "outomesRow",
        onClick: null,
        columns: [
          {
            class: "col-4",
            name: outcome.attributes.outcome,
          },
          {
            class: "col-8",
            name: (
              <Form>
                <Form.Control
                  as="textarea"
                  defaultValue={
                    location === adoptionLocation
                      ? outcome.attributes["adoption-barriers"]
                      : outcome.attributes["ongoing-strategy"]
                  }
                  onBlur={(e) => {
                    setOutcomesChanged([
                      ...outcomesChanged,
                      {
                        id: outcomeId,
                        outcome: outcome.attributes.outcome,
                        value: e.target.value,
                      },
                    ]);
                  }}
                />
              </Form>
            ),
          },
        ],
        actions: null,
      };
    });
  };

  const getFooterData = () => {
    let content = (
      <SeafoamButton
        type="submit"
        text="Update"
        onClick={(e) => handleSubmit(e)}
      />
    );
    return {
      rowClass: "col-12",
      columns: [{ class: "outcomesFooter", content: content }],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Outcome",
      },
      {
        class: "",
        name: secondHeader,
      },
    ],
  };
  const tableData = getTableData();
  const footerData = getFooterData();

  let outcomesTable = new ScopeStackTable(
    "outcomes",
    headerData,
    tableData,
    footerData
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let projectPromises = [];

    const renderAttributeToUpdate = (outcome) => {
      if (location === adoptionLocation) {
        let attributeToUpdate = {
          outcome: outcome.outcome,
          "adoption-barriers": outcome.value,
        };
        return attributeToUpdate;
      }
      if (location === strategiesLocation) {
        let attributeToUpdate = {
          outcome: outcome.outcome,
          "ongoing-strategy": outcome.value,
        };
        return attributeToUpdate;
      }
    };

    let outcomes = removeDuplicates(outcomesChanged, (item) => item.id);
    if (outcomes.length !== 0) {
      outcomes.forEach((outcome) => {
        const outcomeData = {
          data: {
            type: "customer-successes",
            id: outcome.id,
            attributes: renderAttributeToUpdate(outcome),
            relationships: {
              project: { data: { type: "projects", id: project_id } },
            },
          },
        };

        projectPromises.push(
          API.Patch(
            `${apiHost}/${account_slug}/v1/customer-successes/${outcome.id}`,
            outcomeData,
            authorizationCode
          )
        );
      });

      Promise.all(projectPromises)
        .then((res) => {
          setOutcomesChanged([]);
          setUpdatedAlertIsOpen(true);
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "outcome"));
          setShowFailAlert(true);
        });
    }
  };

  const handleUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      let messageType = "";
      if (location === strategiesLocation) {
        messageType = "Success strategy for";
      }
      if (location === adoptionLocation) {
        messageType = "Adoption barrier for";
      }
      return (
        <DismissibleAlert
          className="governanceAlerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text={`${messageType} 'Customer Satisfaction' updated`}
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleFailAlert()}
      {handleUpdatedAlert()}
      <Card>
        <CardHeader title={type} />
        <Card.Body>
          <BaseTable
            className="scopestack outcomesTable"
            striped={true}
            hover={false}
            bordered={false}
            headerRows={outcomesTable.buildHeaderRows()}
            dataRows={outcomesTable.buildDataRows()}
            footerRows={outcomesTable.buildFooterRows()}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default UpdateComponent;
