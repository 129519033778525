import React from "react";
import { Row, Col } from "react-bootstrap";

function CancelApprovalRequestBtn({ onClick, style }) {
  return (
    <>
      <Col xs={4}></Col>
      <Col xs={6}>
        <Row>
        <div style={style} onClick={onClick}>
          <p className="btn btn-danger">
            <i className="fa fa-ban"></i> Cancel Approval
          </p>
        </div>
        </Row>
      </Col>
    </>
  );
}

export default CancelApprovalRequestBtn;
