import "./styles.css";
import Menu from "./Menu";
import { MenuOption } from "./types";
import { checkPrivilege } from "@utils/helpers";
import { Privileges, AccessLevels } from "../../../types";
import useWhoAmI from "./api/useWhoAmI";

const EditAccount = (): JSX.Element => {
  const { data: user, error, isLoading } = useWhoAmI();

  if (isLoading || !user || error) {
    return <></>;
  }

  const { privileges } = user.attributes;

  const menuOptionsMap = {
    [MenuOption.Contact]: {
      privilege: Privileges.SETTINGS_ACCOUNT,
      accessLevel: AccessLevels.MANAGE,
    },
    [MenuOption.Password]: {},
    [MenuOption.Billing]: {
      privilege: Privileges.SETTINGS_BILLING,
      accessLevel: AccessLevels.MANAGE,
    },
    [MenuOption.Licenses]: {
      privilege: Privileges.SETTINGS_USERS,
      accessLevel: AccessLevels.MANAGE,
    },
    [MenuOption.Advanced]: {
      privilege: Privileges.SETTINGS_ACCOUNT,
      accessLevel: AccessLevels.MANAGE,
    },
  };

  const menuOptions = Object.entries(menuOptionsMap)
    .filter(([option]) => {
      const { privilege, accessLevel } = menuOptionsMap[option];
      return (
        !privilege ||
        checkPrivilege({
          userPrivileges: privileges || [],
          privilege,
          accessLevel,
        })
      );
    })
    .map(([option]) => option as MenuOption);
  return (
    <div>
      <h1 data-testid="account-header" className="header">
        My Account
      </h1>
      <Menu options={menuOptions} />
    </div>
  );
};

export default EditAccount;
