import React, { useState, useEffect } from "react";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import NavTabs from "@components/NavTabs/NavTabs";
import { Card, Row, Col, Form } from "react-bootstrap";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import {
  snakeToPascalCase,
  splitPascalCase,
  capitalizeFirstLetter,
} from "@utils/helperFunctions";
import CurrencyFormat from "react-currency-format";

function PartnerDescription({
  account_slug,
  project_id,
  account_id,
  projectStatus,
  partnerRequestId,
  partners,
  languageFields,
  phases,
  thirdPartyServices,
  partnerRequests,
  subtasks,
  requestStatus,
  currencyUnit,
  rateType,
  authorizationCode,
}) {
  // Env
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  // Form/table states
  const [requestId, setRequestId] = useState(null);
  const [services, setServices] = useState([]);
  const [subservices, setSubservices] = useState([]);
  const [languageField, setLanguageField] = useState("");

  useEffect(() => {
    for (let i = 0; i < languageFields.length; i++) {
      setLanguageField(languageFields[0].attributes.name);
    }

    const getLanguages = (service) => {
      let newLanguages = languageFields.map((language) => {
        return {
          ...service.attributes["original-languages"],
          [language.attributes["sow-language"]]: "",
        };
      });

      let objOfLanguages = Object.assign({}, ...newLanguages);
      objOfLanguages["service_description"] =
        service.attributes["original-description"] != null
          ? service.attributes["original-description"]
          : "";
      return objOfLanguages;
    };

    const getFrequency = (service) => {
      let frequency = service.attributes["payment-frequency"];
      if (frequency.includes("_")) {
        return splitPascalCase(snakeToPascalCase(frequency));
      }
      return capitalizeFirstLetter(frequency);
    };
    setServices(
      thirdPartyServices.map((service) => {
        let phaseData = service.relationships["project-phase"].data;
        return {
          id: service.id,
          type: "service",
          phase: phaseData && phaseData.id !== null ? phaseData.id : "noPhase",
          name: service.attributes.name,
          quantity: service.attributes.quantity,
          hours:
            service.attributes["est-hours"] !== null
              ? service.attributes["est-hours"]
              : "0.0",
          rate: service.attributes["est-rate"],
          cost: service.attributes["est-cost"],
          frequency: getFrequency(service),
          languages: getLanguages(service),
          changed: false,
          subtasks: service.relationships.subtasks
            ? service.relationships.subtasks
            : [],
        };
      })
    );
    setSubservices(
      subtasks.map((subservice) => {
        return {
          id: subservice.id,
          type: "subservice",
          name: subservice.attributes.name,
          quantity: subservice.attributes.quantity,
          hours:
            subservice.attributes["est-hours"] !== null
              ? subservice.attributes["est-hours"]
              : "0.0",
          rate: subservice.attributes["est-rate"],
          cost: subservice.attributes["est-cost"],
          languages: getLanguages(subservice),
          changed: false,
        };
      })
    );

    setRequestId(id);
  }, [
    authorizationCode,
    languageFields,
    phases,
    thirdPartyServices,
    partnerRequests,
    subtasks,
    requestStatus,
    currencyUnit,
  ]);

  const languageOptions = languageFields.map((languageField) => {
    return (
      <option accessKey={languageField.id} key={languageField.id}>
        {languageField.attributes.name}
      </option>
    );
  });

  const getTableData = (array) => {
    return array.map((item) => {
      const getColumns = () => {
        if (item.type == "project-phase") {
          return [
            { class: "col-12 newPhase", name: <strong>{item.name}</strong> },
          ];
        } else {
          const serviceNameField = {
            class: languageField === "Pricing" ? "col-4" : "col",
            name: item.name,
          };

          const descriptionField = {
            class: "col-6",
            name: languageFields.map((language, index) => (
              <span
                key={index}
                style={
                  language.attributes.name !== languageField
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                {item.languages[language.attributes["sow-language"]]
                  ? `${item.languages[language.attributes["sow-language"]]}`
                  : ""}
              </span>
            )),
          };

          const hoursField = {
            class: "col-2 text-right",
            name: `${item.hours}`,
          };

          const rateField = {
            class: "col-2 text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(item.rate).toFixed(2)}
              />
            ),
          };

          const costField = {
            class: "col-2 text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={parseFloat(item.cost).toFixed(2)}
              />
            ),
          };

          const frequencyField =
            item.type == "service"
              ? {
                  class: "col",
                  name: `${item.frequency}`,
                }
              : {
                  class: "col",
                  name: "",
                };

          if (languageField !== "Pricing") {
            return [serviceNameField, descriptionField];
          } else {
            return [
              serviceNameField,
              hoursField,
              rateField,
              costField,
              frequencyField,
            ];
          }
        }
      };
      return {
        rowClass:
          item.type !== "subservice"
            ? "thirdPartyServicesRow row"
            : "thirdPartyServicesRow row subserviceRow",
        onClick: null,
        columns: getColumns(),
        actions: null,
      };
    });
  };

  let thirdPartyServicesHeaderData = {
    rowClass: "thirdPartyServicesHeaderRow row",
    columns: [
      {
        class: "col",
        name: "Service",
      },
      {
        class: "col-6",
        name: "Content",
      },
    ],
  };

  if (languageField === "Pricing") {
    thirdPartyServicesHeaderData = {
      rowClass: "thirdPartyServicesHeaderRow row",
      columns: [
        {
          class: "col-4",
          name: "Service",
        },
        {
          class: "col-2 text-right",
          name: rateType,
        },
        {
          class: "col-2 text-right",
          name: "Rate",
        },
        {
          class: "col-2 text-right",
          name: "Cost",
        },
        {
          class: "col",
          name: "Frequency",
        },
      ],
    };
  }

  const arrangeDataForTable = () => {
    let array = [];
    let phasesWithServices = new Set();
    let noPhaseServices = services.filter((service) => {
      if (service.phase == null) service.phase = "noPhase";
      return service.phase == "noPhase";
    });
    if (noPhaseServices.length !== 0) {
      phasesWithServices.add({
        id: "noPhase",
        attributes: { name: "No Phase" },
      });
    }
    for (let i = 0; i < phases.length; i++) {
      services.forEach((service) => {
        if (service.phase == phases[i].id) {
          phasesWithServices.add(phases[i]);
        }
      });
    }

    phasesWithServices = Array.from(phasesWithServices);

    for (let i = 0; i < phasesWithServices.length; i++) {
      array.push({
        type: "project-phase",
        name: phasesWithServices[i].attributes.name,
      });
      for (let k = 0; k < services.length; k++) {
        let phaseId = services[k].phase;
        if (phaseId == phasesWithServices[i].id) {
          array.push(services[k]);
          let tasks = services[k].subtasks;
          let subservicesToRender = "";
          if (tasks) {
            subservicesToRender = tasks.data;
          }
          if (
            lastWordInLocation == "external_services" &&
            requestStatus !== "complete" &&
            tasks
          ) {
            subservicesToRender = tasks;
          }

          for (let j = 0; j < subservices.length; j++) {
            for (let k = 0; k < subservicesToRender.length; k++) {
              if (subservices[j].id == subservicesToRender[k].id) {
                array.push(subservices[j]);
              }
            }
          }
        }
      }
    }
    return array;
  };

  const adjustedServices = arrangeDataForTable();

  const thirdPartyServicesTableData = getTableData(adjustedServices);
  const thirdPartyServicesFooterData = null;

  let thirdPartyServicesTable = new ScopeStackTable(
    "services",
    thirdPartyServicesHeaderData,
    thirdPartyServicesTableData,
    thirdPartyServicesFooterData
  );

  return (
    <div>
      <NavTabs
        tabOneText="Partner Request"
        tabOneUrl={`/projects/${project_id}/third_party_requests/${requestId}/edit`}
        tabTwoText="Partner Description"
        tabTwoUrl={`/projects/${project_id}/third_party_requests/${requestId}/external_services`}
        tabThreeText={requestStatus == "complete" ? "Our Description" : null}
        tabThreeUrl={`/projects/${project_id}/third_party_requests/${requestId}/third_party_services`}
      />
      <div className="col-sm-12" id="sow-workarea">
        <div className="tabContent">
          <Card>
            <Card.Body className="no-gutters">
              <Row>
                <Col></Col>
                <Col sm={3}>
                  <ControlledDropDown
                    className="pull-right"
                    label="View"
                    value={languageField}
                    onChange={(e) => setLanguageField(e.target.value)}
                    options={languageOptions}
                  />
                </Col>
              </Row>
              <BaseTable
                className="thirdPartyServicesTable"
                striped={false}
                hover={false}
                bordered={false}
                headerRows={thirdPartyServicesTable.buildHeaderRows()}
                dataRows={thirdPartyServicesTable.buildDataRows()}
                footerRows={thirdPartyServicesTable.buildFooterRows()}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PartnerDescription;
