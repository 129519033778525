import React, { useEffect } from "react";
import "./SearchField.css";

function SearchField({
  placeholder,
  value,
  onChange,
  onClick,
  fullWidth = false,
  disabled = false,
}) {
  return (
    <div className="inputGroup">
      <span className="input-group-append searchBtn">
        <button
          style={
            disabled
              ? { backgroundColor: "#e9ecef", paddingRight: "0", width: "2em" }
              : { paddingRight: "0", width: "2em" }
          }
          className={disabled ? "btn ms-n3" : "btn bg-white ms-n3"}
          type="submit"
        >
          <i style={{ color: "#1c2655" }} className="fa fa-search"></i>
        </button>
      </span>
      <input
        style={
          value !== ""
            ? { borderLeft: "none", borderRight: "none" }
            : { borderLeft: "none" }
        }
        className={
          fullWidth ? "form-control searchBoxFull" : "form-control searchBox"
        }
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <span
        style={
          value !== ""
            ? {
                color: "#a1a1a1",
                fontSize: "24px",
                border: "solid 1px #d7d7d7",
                borderLeft: "none",
                padding: "2px",
                cursor: "pointer",
              }
            : { display: "none" }
        }
        className="input-group-append clearSearch bg-white"
        onClick={onClick}
      >
        <i style={{ marginTop: "4px" }} className="fa fa-times-circle-o"></i>
      </span>
    </div>
  );
}

export default SearchField;
