import React, { useState } from "react";
import "./ToggleOnOff.css";

const ToggleOnOff = ({
  initialValue,
  onToggle,
  button1Text = "On",
  button2Text = "Off",
}) => {
  const [isOn, setIsOn] = useState(initialValue);

  const handleToggle = (e) => {
    e.preventDefault();
    const newValue = !isOn;
    setIsOn(newValue);
    if (onToggle) {
      onToggle(newValue);
    }
  };

  return (
    <div className="toggle-switch">
      <button
        className={`toggle-switch-button on ${isOn ? "selected" : ""}`}
        onClick={handleToggle}
      >
        {button1Text}
      </button>
      <button
        className={`toggle-switch-button off ${isOn ? "" : "selected"}`}
        onClick={handleToggle}
      >
        {button2Text}
      </button>
    </div>
  );
};

export default ToggleOnOff;
