import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  splitPascalCase,
  snakeToPascalCase,
  getDate,
} from "@utils/helperFunctions";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import CurrencyFormat from "react-currency-format";
import PricingCalculator from "../../../../Pricing/pricingUtils/PricingCalculator";

const PricingReview = ({
  data,
  projectServices,
  managedServicePricings,
  professionalServicePricings,
  oneTimeAdjustment,
  recurringAdjustment,
  currencyUnit,
  terms,
  isLoading,
}) => {
  const professionalServices = projectServices.some(
    (service) => service.attributes["service-type"] === "professional_services"
  );
  const managedServices = projectServices.some(
    (service) => service.attributes["service-type"] === "managed_services"
  );

  const getTableData = (array) => {
    if (array.length == 0) {
      return [];
    }
    return array.map((item, index) => {
      let revenue = 0;
      if (item.revenue && item.revenue !== "NaN") {
        revenue = item.revenue;
      }

      let adjustment = 0;
      if (item.adjustment && item.adjustment !== "NaN") {
        adjustment = item.adjustment;
      }

      let net = 0;
      if (item.net && item.net !== "NaN") {
        net = item.net;
      }

      let tcv = 0;
      if (item.tcv && item.tcv !== "NaN") {
        tcv = item.tcv;
      }

      let cost = 0;
      if (item.cost && item.cost !== "NaN") {
        cost = item.cost;
      }

      let profit = 0;
      if (item.profit && item.profit !== "NaN") {
        profit = item.profit;
      }

      let margin = 0;
      if (item.margin && item.margin !== "NaN") {
        margin = item.margin;
      }
      // Map returns a table row for each item
      return {
        rowClass: "row pricingRow",
        onClick: null,
        columns: [
          {
            class: "text-right col",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={revenue && revenue !== "NaN" ? revenue : 0}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            ),
          },
          {
            class: "text-right col",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={adjustment && adjustment !== "NaN" ? adjustment : 0}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            ),
          },
          {
            class: "text-right col",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={net && net !== "NaN" ? net : 0}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            ),
          },
          {
            class: "text-right col",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={tcv && tcv !== "NaN" ? tcv : 0}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            ),
          },
          {
            class: "text-right col",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={cost && cost !== "NaN" ? cost : 0}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            ),
          },
          {
            class: "text-right col",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={profit && profit !== "NaN" ? profit : 0}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            ),
          },
          {
            class: "text-right col",
            name: <span>{revenue == 0.0 ? "---" : margin + "%"}</span>,
          },
        ],
        actions: null,
      };
    });
  };

  const headerData = {
    rowClass: "row ",
    columns: [
      {
        class: "text-right col",
        name: "Revenue",
      },
      {
        class: "text-right col",
        name: "Adjustment",
      },
      {
        class: "text-right col",
        name: "Net",
      },
      {
        class: "text-right col",
        name: "TCV",
      },
      {
        class: "text-right col",
        name: "Cost",
      },
      {
        class: "text-right col",
        name: "Profit",
      },
      {
        class: "text-right col",
        name: "Margin",
      },
      {
        class: "text-right col",
        name: <span>&nbsp;&nbsp;&nbsp;</span>,
      },
    ],
  };

  const professionalRow = new PricingCalculator(
    "professional",
    0,
    professionalServicePricings,
    terms
  );

  const managedRow = new PricingCalculator(
    "managed",
    0,
    managedServicePricings,
    terms
  );

  let professionalPricingTable = new ScopeStackTable(
    "services",
    headerData,
    getTableData([professionalRow]),
    null
  );

  let managedPricingTable = new ScopeStackTable(
    "services",
    headerData,
    getTableData([managedRow]),
    null
  );

  return (
    <div className="reviewContainer">
      {(data.contractLength || data.professionalTerms) && (
        <div>
          {professionalServices && (
            <>
              <h3 className="reviewHeader">Professional Service Pricing</h3>
              <Row>
                <Col lg={3}>
                  <div className="reviewColumn">
                    <strong>Term</strong>
                    <span>{data.professionalTerms}</span>
                  </div>
                </Col>
                {data.schedule && (
                  <Col lg={9}>
                    <div className="reviewColumn">
                      <strong>Schedule</strong>
                      <span>
                        {data.schedule.map((item, index) => (
                          <span key={index}>{`${item.description} (${
                            item.type
                          })${data.schedule[index + 1] ? ", " : ""}`}</span>
                        ))}
                      </span>
                    </div>
                  </Col>
                )}
              </Row>
              <div
                style={{
                  marginTop: "15px",
                  // height: managedServices ? "100px" : "200px",
                  // backgroundColor: "#D9D9D9",
                }}
              >
                <BaseTable
                  className="scopestack pricingTable"
                  striped={true}
                  hover={true}
                  bordered={false}
                  headerRows={professionalPricingTable.buildHeaderRows()}
                  dataRows={professionalPricingTable.buildDataRows()}
                  footerRows={professionalPricingTable.buildFooterRows()}
                  isLoading={isLoading}
                />
              </div>
            </>
          )}

          {managedServices && (
            <>
              <h3 className="reviewHeader">Managed Services Pricing</h3>
              <Row>
                <Col>
                  <div className="reviewColumn">
                    <strong>Contract Length</strong>
                    <span>{data.contractLength}</span>
                  </div>
                </Col>
                <Col>
                  <div className="reviewColumn">
                    <strong>Start Date</strong>
                    <span>{getDate(data.startDate)}</span>
                  </div>
                </Col>
                <Col>
                  <div className="reviewColumn">
                    <strong>Billing Frequency</strong>
                    <span>{snakeToPascalCase(data.frequency)}</span>
                  </div>
                </Col>
                <Col>
                  <div className="reviewColumn">
                    <strong>Include PS Revenue</strong>
                    <span>
                      {splitPascalCase(
                        snakeToPascalCase(
                          data.includeRevenue.includes("ps")
                            ? "No"
                            : data.includeRevenue
                        )
                      )}
                    </span>
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  marginTop: "15px",
                  // height: professionalServices ? "100px" : "200px",
                  // backgroundColor: "#D9D9D9",
                }}
              >
                <BaseTable
                  className="scopestack pricingTable"
                  striped={true}
                  hover={true}
                  bordered={false}
                  headerRows={managedPricingTable.buildHeaderRows()}
                  dataRows={managedPricingTable.buildDataRows()}
                  footerRows={managedPricingTable.buildFooterRows()}
                  isLoading={isLoading}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PricingReview;
