import React from "react";
import IconTextButton from "@components/Buttons/IconTextButton/IconTextButton";

function CreatePsaBtn({ accountSlug, projectId, className }) {
  return (
    <IconTextButton
      className={`btn btn-success createPsaBtn ${className}`}
      dataMethod="put"
      url={`/projects/${projectId}/sales_order_for`}
      iconClass="fa fa-share-square"
      buttonText="Create Sales Order"
    />
  );
}

export default CreatePsaBtn;
