import React from "react";
import PartnerRequest from "../PartnerRequest/PartnerRequest";

function NewPartnerRequest({
  account_slug,
  project_id,
  projectStatus,
  partnerRequests,
  partners,
  partnerRequestId,
  account_id,
  setPartnerRequestCreated,
  linesOfBusiness,
  authorizationCode,
}) {
  return (
    <PartnerRequest
      account_slug={account_slug}
      project_id={project_id}
      projectStatus={projectStatus}
      partnerRequests={partnerRequests}
      partners={partners}
      partnerRequestId={partnerRequestId}
      account_id={account_id}
      setPartnerRequestCreated={setPartnerRequestCreated}
      linesOfBusiness={linesOfBusiness}
      authorizationCode={authorizationCode}
    />
  );
}

export default NewPartnerRequest;
