import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { snakeToPascalCase, splitPascalCase } from "@utils/helperFunctions";

const SummaryReview = ({ data }) => {
  const [showExecutiveAll, setShowExecutiveAll] = useState(false);
  const [showSolutionAll, setShowSolutionAll] = useState(false);

  const renderSummary = (summary, isExec) => {
    if (!summary) {
      return;
    } else if (
      summary.length <= 245 ||
      (isExec ? showExecutiveAll : showSolutionAll)
    ) {
      return (
        <>
          {summary}
          {summary.length > 245 && (
            <button
              className="viewAllClose viewAllReview"
              onClick={() => {
                if (isExec) {
                  setShowExecutiveAll(false);
                } else {
                  setShowSolutionAll(false);
                }
              }}
            >
              -
            </button>
          )}
        </>
      );
    }
    const truncated = summary.substring(0, 245) + "...";
    return (
      <>
        {truncated}
        <button
          onClick={() => {
            if (isExec) {
              setShowExecutiveAll(true);
            } else {
              setShowSolutionAll(true);
            }
          }}
          className="viewAllReview viewAll "
        >
          View All
        </button>
      </>
    );
  };

  return (
    <div className="reviewContainer">
      <div>
        <>
          <h3 className="reviewHeader">Executive Summary</h3>
          {!data.executiveSummary || data.executiveSummary == "" ? (
            <span style={{ fontStyle: "italic" }}>
              No Executive Summary Saved
            </span>
          ) : (
            <span>{renderSummary(data.executiveSummary, true)}</span>
          )}
        </>

        <>
          <h3 className="reviewHeader">Solution Summary</h3>
          {!data.solutionSummary || data.solutionSummary == "" ? (
            <span style={{ fontStyle: "italic" }}>
              No Solution Summary Saved
            </span>
          ) : (
            <span>{renderSummary(data.solutionSummary, false)}</span>
          )}
        </>

        {data.contacts && (
          <>
            <h3 className="reviewHeader">Contacts</h3>
            <Row
              style={{
                margin: "0px",
                paddingBottom: "5px",
                borderBottom: "1px solid #dee2e6",
              }}
            >
              <Col sm={2}>
                <strong>Contact Type</strong>
              </Col>
              <Col sm={3} style={{ paddingLeft: "40px" }}>
                <strong>Name</strong>
              </Col>
              <Col sm={2}>
                <strong>Title</strong>
              </Col>
              <Col sm={3}>
                <strong>Email</strong>
              </Col>
              <Col sm={2}>
                <strong>Phone</strong>
              </Col>
            </Row>
            {data.contacts
              .sort((a, b) =>
                b.attributes["contact-type"].localeCompare(
                  a.attributes["contact-type"]
                )
              )
              .map((contact, index) => (
                <Row
                  key={index}
                  style={{
                    padding: "10px 0px",
                    margin: "0px",
                    backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                  }}
                >
                  <Col sm={2} style={{ padding: "0px 0px 0px 15px" }}>
                    <span>
                      {contact.attributes["contact-type"] ===
                      "primary_customer_contact"
                        ? "Primary Contact"
                        : splitPascalCase(
                            snakeToPascalCase(
                              contact.attributes["contact-type"]
                            )
                          )}
                    </span>
                  </Col>
                  <Col sm={3} style={{ paddingLeft: "40px" }}>
                    <span>{contact.attributes.name}</span>
                  </Col>
                  <Col sm={2}>
                    <span>{contact.attributes.title}</span>
                  </Col>
                  <Col sm={3}>
                    <span>{contact.attributes.email}</span>
                  </Col>
                  <Col sm={2}>
                    <span>{contact.attributes.phone}</span>
                  </Col>
                </Row>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default SummaryReview;
