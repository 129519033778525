import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CardHeader from "@components/CardHeader/CardHeader";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import BackButton from "@components/Buttons/BackButton/BackButton";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import { snakeToPascalCase, splitPascalCase } from "@utils/helperFunctions";
import API from "@API";

function Changes({ versions, project_id, account_slug, authorizationCode }) {
  //Env

  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  const changesSinceLoc = "audit_logs?changes=true";
  const changesInLoc = "audit_logs";

  const [versionName, setVersionName] = useState("");
  const [changeList, setChangeList] = useState([]);
  const [changesSince, setChangesSince] = useState([]);
  const [changesIn, setChangesIn] = useState([]);
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  useEffect(() => {
    let changeList = [];

    versions.forEach((version) => {
      if (version.id == id) {
        setVersionName(version.attributes.name);
        changeList.push(version);
        // Get audit-logs for changesSince
        // If completed-at is null then we are looking at the 'current' version
        if (version.attributes["completed-at"] !== null) {
          API.Get(
            `${apiHost}/${account_slug}/v1/projects/${project_id}/audit-logs?filter[after]=${version.attributes["completed-at"]}`,
            authorizationCode
          ).then((response) => {
            let changesSince = response.data.data;
            setChangesSince(changesSince);
            if (lastWordInLocation == changesSinceLoc) {
              changesSince.forEach((change) => {
                changeList.push(change);
              });
            }
          });
        }

        // Get Audit logs for changesIn
        let changesInUrl = `${apiHost}/${account_slug}/v1/projects/${project_id}/audit-logs?filter[after]=${version.attributes["started-at"]}`;
        if (version.attributes["completed-at"] !== null)
          changesInUrl += `&filter[before]=${version.attributes["completed-at"]}`;

        API.Get(changesInUrl, authorizationCode).then((response) => {
          let changesIn = response.data.data;
          setChangesIn(changesIn);
          if (lastWordInLocation == changesInLoc) {
            changesIn.forEach((change) => {
              changeList.push(change);
            });
          }
        });
      }
    });
    changeList = changeList.map((el) => {
      let o = Object.assign({}, el);
      o.changesAreOpen = false;
      return o;
    });
    setChangeList(changeList);
  }, [versions]);

  const getTableData = () => {
    if (changeList.length === 0) {
      return [];
    }
    // Map over versions & return data for ScopeStackTable
    return changeList.map((item, index) => {
      const changeId = index;

      const displayActivity = (id) => {
        let newChangeList = [];
        changeList.forEach((obj, i) => {
          // If changes are open is false, remove the activity items from the array to render
          if (obj.parentId == id && obj.changesAreOpen == true) {
            return setChangeList(
              changeList.map((change) => {
                if (change.parentId !== id) {
                  return item;
                }
              })
            );
          }
          let changes = [];
          if (obj.id == id) {
            if (obj.attributes.changes) {
              changes = obj.attributes.changes;
            }
            obj.changesAreOpen = !obj.changesAreOpen;
            newChangeList.push(obj);
            if (
              changes &&
              changes.length > 0 &&
              obj.changesAreOpen === true &&
              obj.type !== "changeRow" &&
              obj.type !== "changeHeaderRow"
            ) {
              newChangeList.push({
                parentId: obj.id,
                changesAreOpen: true,
                type: "changeHeaderRow",
                attribute: "Attribute",
                changed_from: "Changed From",
                changed_to: "Changed To",
              });
              changes.forEach((change) => {
                change.type = "changeRow";
                change.parentId = obj.id;
                change.changesAreOpen = true;
                newChangeList.push(change);
              });
            }
          } else {
            newChangeList.push(obj);
          }
        });
        setChangeList(newChangeList);
      };

      // Render icon buttons under actions on hover if the project status is: building
      const renderActions = () => {
        return (
          <OnHoverButtonIcon
            className="actions"
            iconClass={item.changesAreOpen ? "fa fa-minus" : "fa fa-plus"}
            buttonText={item.changesAreOpen ? "Hide Activity" : "Show Activity"}
            onClick={() => displayActivity(item.id)}
          />
        );
      };

      const redirectToEditChange = () => {
        return navigate(
          `/projects/${project_id}/project_versions/${id}/audit_logs/${changeId}`
        );
      };

      if (item.type == "project-versions") {
        return {
          rowClass: "changesRow",
          onClick: null,
          columns: [
            {
              class: "",
              name: <strong>{item.attributes.name}</strong>,
            },
            {
              class: "",
              name: "",
            },
            {
              class: "",
              name: "",
            },
            {
              class: "",
              name: "",
            },
            {
              class: "",
              name: "",
            },
          ],
          actions: null,
        };
      }

      const options = { month: "long", day: "numeric", year: "numeric" };

      const simplifyObjectChanges = (changeSet) => {
        changeSet = Object.entries(changeSet);
        changeSet = changeSet.map((arr) => {
          arr.splice(1, 0, ": ");
          return arr.join("");
        });
        return changeSet.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              {item}
              <br />
            </React.Fragment>
          );
        });
      };

      if (item.type == "changeHeaderRow" || item.type == "changeRow") {
        let changedFrom = item.changed_from;
        let changedTo = item.changed_to;
        if (changedFrom && changedFrom.toString().includes("-")) {
          let date = new Date(changedFrom);
          changedFrom = date.toLocaleDateString("en-US", options);
        }
        if (changedTo && changedTo.toString().includes("-")) {
          let date = new Date(changedTo);
          changedTo = date.toLocaleDateString("en-US", options);
        }
        if (changedFrom && typeof changedFrom === "object") {
          changedFrom = simplifyObjectChanges(changedFrom);
        }
        if (changedTo && typeof changedTo === "object") {
          let objectHasArray = false;
          Object.entries(changedTo).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              objectHasArray = true;
              changedTo[key].forEach((item) => {
                changedTo = simplifyObjectChanges(item);
              });
            }
          });
          if (!objectHasArray) {
            changedTo = simplifyObjectChanges(changedTo);
          }
        }
        return {
          rowClass: "changesRow",
          onClick: null,
          columns: [
            {
              class: "",
              name: "",
            },
            {
              class: "",
              name:
                item.type == "changeHeaderRow" ? (
                  <strong>{item.attribute}</strong>
                ) : (
                  splitPascalCase(snakeToPascalCase(item.attribute))
                ),
            },
            {
              class: "text-right",
              name:
                item.type == "changeHeaderRow" ? (
                  <strong>{changedFrom}</strong>
                ) : (
                  <p>{changedFrom}</p>
                ),
            },
            {
              class: "text-right",
              name:
                item.type == "changeHeaderRow" ? (
                  <strong>{changedTo}</strong>
                ) : (
                  <p>{changedTo}</p>
                ),
            },
            {
              class: "",
              name: "",
            },
          ],
          actions: null,
        };
      }

      // Map returns a table row for each Version
      if (item.type === "audit-logs") {
        const createdOn = new Date(item.attributes["created-at"]);
        const time = new Date(item.attributes["created-at"]).toLocaleTimeString(
          "en",
          { timeStyle: "short", hour12: true, timeZone: "UTC" }
        );
        return {
          rowClass: "changesRow",
          onClick: () => null,
          columns: [
            {
              class: "",
              name: (
                <span style={{ marginLeft: "1em" }}>
                  - {item.attributes.name}
                </span>
              ),
            },
            {
              class: "",
              name: item.attributes["changed-by"],
            },
            {
              class: "text-right",
              name: createdOn.toLocaleDateString("en-US", options),
            },
            {
              class: "text-right",
              name: time,
            },
            {
              class: "",
              name: "",
            },
          ],
          actions: renderActions(),
        };
      }
    });
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Details",
      },
      {
        class: "",
        name: "Collaborator",
      },
      {
        class: "text-right",
        name: "Date",
      },
      {
        class: "text-right",
        name: "Time",
      },
    ],
  };

  const tableData = getTableData();
  const footerData = null;

  let changesTable = new ScopeStackTable(
    "changes",
    headerData,
    tableData,
    footerData
  );

  return (
    <div>
      <Card>
        <CardHeader
          title={
            lastWordInLocation === changesSinceLoc
              ? `Changes since ${versionName}`
              : `Changes included in ${versionName}`
          }
        />
        <Card.Body>
          <BaseTable
            className="scopestack changesTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={changesTable.buildHeaderRows()}
            dataRows={changesTable.buildDataRows()}
            footerRows={changesTable.buildFooterRows()}
          />
          <Col>
            <BackButton url={`/projects/${project_id}/project_versions`} />
          </Col>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Changes;
