import React, { useState, useEffect } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import { capitalizeFirstLetter } from "@utils/helperFunctions";
import { useNavigate } from "react-router-dom";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

function ProjectApprovals({
  account_slug,
  project_id,
  account_id,
  versions,
  projectApprovals,
  approvalSteps,
  setApprovalId,
  setApprovalStepId,
  users,
  isLoading,
}) {
  let navigate = useNavigate();
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  useEffect(() => {}, [
    versions,
    projectApprovals,
    approvalSteps,
    users,
    isLoading,
  ]);

  if (isLoading) {
    return <ScopeStackSpinner />;
  } else {
    return (
      <Card>
        <CardHeader title="Approvals" />
        <Card.Body>
          {versions.map((version, index) => {
            return (
              <Card key={index}>
                <Card.Header>
                  <strong style={{ fontSize: "16px" }}>
                    {version.attributes.name}
                  </strong>
                  <span
                    className="pull-right versionChangesBtn"
                    onClick={() =>
                      window.location.replace(
                        `${appHost}/projects/${project_id}/project_versions/${version.id}/audit_logs`
                      )
                    }
                  >
                    <i className="fa fa-search-plus"></i>See Version Changes
                  </span>
                </Card.Header>
                {approvalSteps.map((approvalStep) => {
                  let description = "";
                  let status = "";
                  let approvalId = "";
                  let step = "";
                  let versionApprovalsRel =
                    version.relationships["approval-steps"].data;
                  return versionApprovalsRel.map((appr) => {
                    if (approvalStep.id == appr.id) {
                      description = approvalStep.attributes.description;
                      status = approvalStep.attributes.status;
                      approvalId = approvalStep.id;
                      step = approvalStep.attributes.step;
                      return (
                        <div
                          key={approvalStep.id}
                          style={{ paddingLeft: "75px" }}
                        >
                          <Row className="sectionTitle">
                            <Col>{description}</Col>
                            {status === "pending" ? (
                              <span
                                className="pull-right addApproverBtn"
                                id={approvalId}
                                onClick={(e) => {
                                  setApprovalStepId(e.target.id);
                                  navigate(
                                    `/projects/${project_id}/project_approvals/new`,
                                    {
                                      state: {
                                        approvalStepId: approvalId,
                                        approvalStep: step,
                                      },
                                    }
                                  );
                                }}
                              >
                                <i className="fa fa-plus"></i>Add Approver
                              </span>
                            ) : null}
                          </Row>
                          <Table
                            className="scopestackTable"
                            striped={true}
                            hover={true}
                            bordered={false}
                          >
                            <thead>
                              <tr>
                                <th className="col-1">Status</th>
                                <th className="col-2">User</th>
                                <th className="col-2">Date</th>
                                <th className="col-3">Reason</th>
                                <th className="col-4">Comment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectApprovals.map((projectApproval) => {
                                let projectApprovalId = projectApproval.id;
                                let projectApprovalStatus = "";
                                let date = "";
                                let reason = "";
                                let comment = "";
                                let approverName = "";
                                let canApprove = false;
                                let approvalStepRelationship =
                                  projectApproval.relationships["approval-step"]
                                    .data;

                                if (projectApproval.links.approve) {
                                  canApprove = true;
                                }
                                let attr = projectApproval.attributes;
                                projectApprovalStatus = attr.status;
                                date = attr["completed-at"];
                                reason = attr.reason;
                                comment = attr.comment;
                                approverName = attr["approver-name"];

                                const redirectToEditApproval = () => {
                                  setApprovalId(approvalId);
                                  return navigate(
                                    `/projects/${project_id}/project_approvals/${projectApprovalId}/edit`,
                                    {
                                      state: {
                                        approvalStepId: approvalId,
                                        approvalStep: step,
                                      },
                                    }
                                  );
                                };

                                // Render icon button to review approval if the links in the api are present to do so
                                const renderActions = () => {
                                  if (canApprove === true) {
                                    return (
                                      <OnHoverButtonIcon
                                        style={{ display: "inline-block" }}
                                        iconClass="fa fa-arrow-right"
                                        buttonText="Review"
                                        onClick={redirectToEditApproval}
                                      />
                                    );
                                  }
                                };

                                const completedAt = new Date(date);
                                const options = {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                };
                                if (
                                  approvalStepRelationship &&
                                  approvalStepRelationship.id == approvalId
                                ) {
                                  return (
                                    <tr
                                      className="projectApprovalRow"
                                      key={projectApprovalId}
                                    >
                                      <td className="col-1">
                                        {capitalizeFirstLetter(
                                          projectApprovalStatus
                                        )}
                                      </td>
                                      <td className="col-2">{approverName}</td>
                                      <td className="col-2">
                                        {projectApprovalStatus !== "required" &&
                                        projectApprovalStatus !== "optional"
                                          ? completedAt.toLocaleDateString(
                                              "en-US",
                                              options
                                            )
                                          : ""}
                                      </td>
                                      <td className="col-3">{reason}</td>
                                      <td className="col-4">{comment}</td>
                                      <td className="col">{renderActions()}</td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </Table>
                        </div>
                      );
                    }
                  });
                })}
              </Card>
            );
          })}
        </Card.Body>
      </Card>
    );
  }
}

export default ProjectApprovals;
