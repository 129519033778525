import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import ServiceRequests from "./ServiceRequests";
import PartnerRequests from "./PartnerRequests";

function ServiceRequestsMspa({ account_slug, account_id }) {
  const [authorizationCode] = useOauth();

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const [serviceRequests, setServiceRequests] = useState([]);
  const [partnerRequests, setPartnerRequests] = useState([]);
  const [partners, setPartners] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode !== "") {
      //Get all external requests for account
      API.Get(
        `${apiHost}/${account_slug}/v1/external-requests?include=subproject,account`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setServiceRequests(data);
      });

      // Get Partner Requests
      API.Get(
        `${apiHost}/${account_slug}/v1/partner-requests?include=lob,project,subcontractor,third-party-services,currency`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        let includedData = response.data.included;
        setPartnerRequests(data);
        setIsLoading(false);
      });

      // Get Partners
      API.Get(
        `${apiHost}/${account_slug}/v1/subcontractors`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setPartners(data);
      });
    }
  }, [authorizationCode]);
  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/external_requests"}
            element={
              <ServiceRequests
                account_slug={account_slug}
                account_id={account_id}
                serviceRequests={serviceRequests}
                partners={partners}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/partner_requests"}
            element={
              <PartnerRequests
                account_slug={account_slug}
                account_id={account_id}
                partnerRequests={partnerRequests}
                partners={partners}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default ServiceRequestsMspa;
