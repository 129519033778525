import { useUpdateUserViewMutation } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { V1UserViewResource } from "@generated";

const useUpdateUserViewResource = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [updateUserView, { isLoading, isError, data, error }] =
    useUpdateUserViewMutation();

  const triggerUpdateUserView = async (
    userViewId: number,
    userViewData: V1UserViewResource
  ) => {
    try {
      const updateResult = await updateUserView({
        slug: accountSlug,
        id: userViewId,
        body: {
          data: userViewData,
        },
      }).unwrap();

      return updateResult;
    } catch (updateError) {
      console.error("Update failed", updateError);
      throw updateError;
    }
  };

  // Return the trigger function and any state needed
  return { triggerUpdateUserView, isLoading, isError, data, error };
};

export default useUpdateUserViewResource;
