import React, { useRef, useEffect } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import FormFieldLabel from "./FormFieldLabel/FormFieldLabel";

function FormDateField({
  label,
  placeholder,
  value,
  onChange,
  defaultValue,
  required,
  updateRef,
}) {
  const selectRef = useRef();

  useEffect(() => {
    if (updateRef) {
      selectRef.current.value = defaultValue;
    }
  }, [updateRef]);

  return (
    <FormGroup className="formGroup">
      <FormFieldLabel label={label} />
      <FormControl
        required={required}
        defaultValue={defaultValue}
        value={value === null ? "" : value}
        placeholder={placeholder}
        onChange={onChange}
        type="date"
        ref={updateRef ? selectRef : undefined}
      />
    </FormGroup>
  );
}

export default FormDateField;
