import { useListUsersForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";

interface User {
  id: number;
  name: string;
}

const useListUsersForAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  let customError: string | null = null; // Declare customError
  const {
    data,
    error,
    isLoading: usersLoading,
  } = useListUsersForAccountQuery({ slug: accountSlug });
  let users: User[] = [];
  if (data?.data) {
    try {
      users = data.data.map((user) => {
        if (!user.id) {
          throw new Error("Missing user.id");
        }
        if (!user.attributes.name) {
          throw new Error("Missing user.attributes.name");
        }
        return {
          id: user.id,
          name: user.attributes.name,
        };
      });
    } catch (e) {
      customError = "An unknown error occurred";
    }
  }
  return { users, usersLoading, error: error ?? customError };
};
export default useListUsersForAccount;
