import TeamInfo from "../common";
const Create = () => {
  const team = {
    name: "",
    users: [],
    services: [],
    surveys: [],
  };
  return <TeamInfo mode="create" data={team} />;
};
export default Create;
