import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Card, Form, Row, Col, FormControl } from "react-bootstrap";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import FormTextField from "@components/Forms/FormTextField";
import API from "@API";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import BackButton from "@components/Buttons/BackButton/BackButton";
import {
  formatUnprocessibleResponse,
  renderCategoryResourceOptions,
  renderResourceName,
} from "@utils/helperFunctions";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import Select from "react-select";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

function NewStandardTask({
  account_slug,
  project_id,
  account_id,
  phases,
  projectPhases,
  resources,
  projectResources,
  linesOfBusiness,
  blueprints,
  isLoading,
  rateType,
  authorizationCode,
  useResourceRates,
}) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  let navigate = useNavigate();

  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  const [services, setServices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [changedLocations, setChangedLocations] = useState([]);
  const [serviceType, setServiceType] = useState("professional");

  const [phaseId, setPhaseId] = useState(null);
  const [lobId, setLobId] = useState(null);
  const [blueprintId, setBlueprintId] = useState(null);

  const [selectedPhase, setSelectedPhase] = useState("In Phase");
  const [selectedLob, setSelectedLob] = useState("In Line of Business");
  const [selectedBlueprint, setSelectedBlueprint] = useState("In Blueprint");

  const [checkedServices, setCheckedServices] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const [changedQuantities, setChangedQuantities] = useState([]);
  const [changedResources, setChangedResources] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [changedHours, setChangedHours] = useState([]);

  const [selectedServices, setSelectedServices] = useState([]);

  const [searchServicesText, setSearchServicesText] = useState(null);
  const [searchSku, setSearchSku] = useState(null);

  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    if (lastWordInLocation == "new?service_type=managed_services") {
      setServiceType("managed");
    }
    let findServicesNoRow = document.querySelector(
      ".findServicesTable tbody .noRow td"
    );
    let selectedServicesNoRow = document.querySelector(
      ".selectedServicesTable tbody .noRow td"
    );
    if (services.length === 0 && isLoading === false) {
      findServicesNoRow.innerHTML = "Use Filter to find services";
    }
    if (selectedServices.length === 0 && isLoading === false) {
      selectedServicesNoRow.innerHTML = "No services selected";
    }

    if (authorizationCode !== "") {
      // Get Locations
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-locations`,
        authorizationCode
      ).then((response) => {
        if (response.data.data !== null) {
          let locations = response.data.data;
          setLocations(
            locations.filter((loc) => loc.attributes.active == true)
          );
        }
      });
    }
  }, [authorizationCode, isLoading]);

  const handleSearch = (e) => {
    e.preventDefault();
    const getParam = (param) => {
      if (param !== null) {
        return encodeURIComponent(param);
      }
      return encodeURIComponent("");
    };

    let type = getParam(null);
    if (serviceType === "professional") {
      type = getParam("professional_services");
    }
    if (serviceType === "managed") {
      type = getParam("managed_services");
    }
    API.Get(
      `${apiHost}/${account_slug}/v1/services?filter[service-type]=${type}&filter[name]=${getParam(
        searchServicesText
      )}&filter[phase]=${getParam(phaseId)}&filter[lob]=${getParam(
        lobId
      )}&filter[blueprint]=${getParam(blueprintId)}&filter[sku]=${getParam(
        searchSku
      )}&include=phase,resource,service-category`,
      authorizationCode
    ).then((response) => {
      let data = response.data.data;
      setServices(data);

      let categories = new Set();
      let includedData = response.data.included || [];
      includedData.forEach((item) => {
        if (item.type == "service-categories") {
          categories.add(item);
        }
      });
      categories = Array.from(categories);
      setServiceCategories(categories);

      if (data.length == 0) {
        let findServicesNoRow = document.querySelector(
          ".findServicesTable tbody .noRow td"
        );
        findServicesNoRow.innerHTML = "No services found";
      }
      if (data.length !== 0) {
        setCheckedServices(
          data.map((service) => ({ id: service.id, checked: false }))
        );
        handleCheck(data[0].id);
      }
    });
  };

  const handleCheck = (id) => {
    if (checkedServices.length === 0) {
      return false;
    }
    for (let i = 0; i < checkedServices.length; i++) {
      if (checkedServices[i].id === id) {
        return checkedServices[i].checked;
      }
    }
  };

  const getTableData = (array) => {
    return array.map((service) => {
      let phase = "";
      let lob = "";
      let serviceCategory = "";
      let resource = "";
      let resourceId = "";
      let projectResource = "";
      let projectResourceId = "";
      let location = "";
      let locationId = "";
      let serviceId = service.id;
      let serviceKey = service.key;

      for (let i = 0; i < phases.length; i++) {
        let phaseData =
          service.relationships["phase"] && service.relationships["phase"].data
            ? service.relationships["phase"].data
            : null;
        if (phaseData && phases[i].id === phaseData.id) {
          phase = phases[i].attributes.name;
        }
      }

      let resourceData = service.relationships["resource"].data;
      if (resourceData) {
        for (let i = 0; i < resources.length; i++) {
          if (
            resources[i].type == "resources" &&
            resources[i].id === resourceData.id
          ) {
            resource = resources[i].attributes.name;
            resourceId = `${resources[i].type}-${resources[i].id}`;
          }
          if (
            resources[i].type == "resource-rates" &&
            resources[i].relationships["resource"].data.id === resourceData.id
          ) {
            let categoryIds = [
              service.relationships["service-category"].data.id,
              service.attributes["lob-id"].toString(),
            ];
            let resourceCategory =
              resources[i].relationships["line-of-business"].data;
            if (resourceCategory && categoryIds.includes(resourceCategory.id)) {
              resource = renderResourceName(resources[i], linesOfBusiness);
              resourceId = `${resources[i].type}-${resources[i].id}`;
            }
          }
        }
      }

      for (let i = 0; i < projectResources.length; i++) {
        let projectResourceRel = service.relationships["project-resource"];
        if (
          projectResourceRel &&
          projectResources[i].id === projectResourceRel.data.id
        ) {
          projectResource = renderResourceName(
            projectResources[i],
            linesOfBusiness
          );
          projectResourceId = projectResources[i].id;
        }
      }

      for (let i = 0; i < linesOfBusiness.length; i++) {
        let lobId = service.attributes["lob-id"];
        if (lobId !== null && linesOfBusiness[i].id == lobId) {
          lob = linesOfBusiness[i].attributes.name;
        }
      }

      serviceCategories.forEach((category) => {
        if (category.id === service.relationships["service-category"].data.id) {
          serviceCategory = category.attributes.name;
        }
      });

      const getColumnOne = () => {
        if (array === services) {
          return {
            class: "col-1",
            name: (
              <Form.Control
                style={{ height: "1em", width: "1em" }}
                type="checkbox"
                value={service.id}
                checked={handleCheck(service.id)}
                onChange={(e) => {
                  let id = e.target.value;
                  setCheckedServices(
                    checkedServices.map((service) =>
                      service.id === id
                        ? { ...service, checked: !service.checked }
                        : { ...service }
                    )
                  );
                }}
              />
            ),
          };
        }
        if (array === selectedServices) {
          return {
            class: locations.length < 2 ? "col-5" : "col-3",
            name: service.attributes.name,
          };
        }
      };

      const getLocationsColumn = () => {
        if (array === selectedServices && serviceType === "professional") {
          const renderLocationOptions = () => {
            let options = locations.map((location) => {
              let id = location.id;
              let locationOption = (
                <option accessKey={id} key={id}>
                  {location.attributes.name}
                </option>
              );
              return locationOption;
            });
            options.unshift(
              <option accessKey="-1" key="-1">
                Select Location
              </option>
            );
            return options;
          };
          return {
            class: "col-2",
            name: (
              <FormControl
                as="select"
                className="basic-single"
                defaultValue={""}
                onChange={(e) => {
                  let locationId = e.target.id;
                  let selected = e.target.options.selectedIndex;
                  locationId = e.target.options[selected].accessKey;
                  setChangedLocations((changedLocations) => {
                    return [
                      ...changedLocations,
                      {
                        service: serviceKey,
                        locationId: locationId,
                      },
                    ];
                  });
                }}
              >
                {renderLocationOptions()}
              </FormControl>
            ),
          };
        }
        if (array === selectedServices && serviceType === "managed") {
          return {
            class: "col-2",
            name: "",
          };
        }
      };

      const getColumnTwo = () => {
        if (array === services) {
          return {
            class: "col-5",
            name: service.attributes.name,
          };
        }
        if (array === selectedServices) {
          return {
            class: "col-2",
            name: (
              <Form.Control
                type="number"
                defaultValue="1"
                step="1"
                min={service.attributes["minumum-quantity"]}
                onChange={(e) => {
                  let value = e.target.value;
                  setChangedQuantities((changedQuantities) => {
                    e.persist();
                    return [
                      ...changedQuantities,
                      {
                        service: serviceKey,
                        quantity: value,
                      },
                    ];
                  });
                }}
              />
            ),
          };
        }
      };

      const getColumnThree = () => {
        if (array === services) {
          return {
            class: "col-2",
            name: lob,
          };
        }
        if (array === selectedServices && serviceType == "professional") {
          return {
            class: "col-2",
            name: (
              <Form.Control
                type="number"
                defaultValue={
                  service.attributes["suggested-hours"]
                    ? service.attributes["suggested-hours"]
                    : "0.0"
                }
                step="0.01"
                min="0"
                onChange={(e) => {
                  let value = e.target.value;
                  setChangedHours((changedHours) => {
                    e.persist();
                    return [
                      ...changedHours,
                      {
                        service: serviceKey,
                        hours: value,
                      },
                    ];
                  });
                }}
              />
            ),
          };
        }
        if (array === selectedServices && serviceType == "managed") {
          return {
            class: "col-2",
            name: "",
          };
        }
      };

      const getColumnFour = () => {
        if (array === services) {
          return {
            class: "col-2",
            name: serviceCategory,
          };
        }
        if (array === selectedServices && serviceType === "professional") {
          return {
            class: "col-2",
            name: (
              <Select
                style={{ width: "13%" }}
                className="basic-single"
                defaultValue={
                  projectResourceId !== ""
                    ? { value: projectResourceId, label: projectResource }
                    : resourceId !== ""
                    ? { value: resourceId, label: resource }
                    : { value: -1, label: "Select Resource" }
                }
                onChange={(e) => {
                  let resourceId = e.value;
                  setSelectedResources([
                    ...selectedResources,
                    {
                      value: resourceId,
                      label: e.label,
                    },
                  ]);
                  setChangedResources((changedResources) => {
                    return [
                      ...changedResources,
                      {
                        service: serviceKey,
                        resourceId: resourceId,
                      },
                    ];
                  });
                }}
                options={renderCategoryResourceOptions(
                  projectResources,
                  resources,
                  linesOfBusiness,
                  [
                    service.attributes["lob-id"].toString(),
                    service.relationships["service-category"].data.id,
                  ]
                )}
              />
            ),
          };
        }
        if (array === selectedServices && serviceType === "managed") {
          return {
            class: "col-2",
            name: "",
          };
        }
      };

      const getColumnFive = () => {
        if (array === services && serviceType == "professional") {
          return {
            class: "col-2",
            name: phase,
          };
        }
        if (array === services && serviceType == "managed") {
          return {
            class: "col-2",
            name: "",
          };
        }
        if (array === selectedServices) {
          return {
            class: "col-1",
            name: (
              <span
                onClick={(e) => {
                  setSelectedServices(
                    selectedServices.filter(
                      (service) => service.id !== e.target.id
                    )
                  );
                  setCheckedAll(false);
                  setCheckedServices(
                    checkedServices.map((service) => ({
                      ...service,
                      checked: false,
                    }))
                  );
                }}
                id={service.id}
                className="removeSelectedService"
              >
                Remove
              </span>
            ),
          };
        }
      };

      let columns = [
        getColumnOne(),
        getLocationsColumn(),
        getColumnTwo(),
        getColumnThree(),
        getColumnFour(),
        getColumnFive(),
      ];

      if (locations.length < 2 || array !== selectedServices) {
        columns.splice(1, 1);
      }

      return {
        rowClass: "findServicesRow row",
        onClick: null,
        columns: columns,
        actions: null,
      };
    });
  };

  const findServicesHeaderData = {
    rowClass: "findServicesHeaderRow row",
    columns: [
      {
        class: "col-1",
        name: (
          <Form.Control
            style={{ height: "1em", width: "1em" }}
            type="checkbox"
            name="select-all"
            id="select-all"
            value="selectAll"
            checked={checkedAll}
            onChange={(e) => {
              setCheckedServices(
                checkedServices.map((service) => {
                  if (checkedAll) {
                    return { ...service, checked: false };
                  }
                  if (!checkedAll) {
                    return { ...service, checked: true };
                  }
                })
              );
              setCheckedAll(!checkedAll);
            }}
          />
        ),
      },
      {
        class: "col-5",
        name: "Service",
      },
      {
        class: "col-2",
        name: "Line of Business",
      },
      {
        class: "col-2",
        name: "Service Category",
      },
      {
        class: "col-2",
        name: serviceType == "professional" ? "Phase" : "",
      },
    ],
  };

  let headerColumns = [
    {
      class: locations.length < 2 ? "col-5" : "col-3",
      name: "Service",
    },
    {
      class: "col-2",
      name: serviceType == "professional" ? "Service Location" : "",
    },
    {
      class: "col-2",
      name: "Quantity",
    },
    {
      class: "col-2",
      name: serviceType == "professional" ? rateType : "",
    },
    {
      class: "col-2",
      name: serviceType == "professional" ? "Resource" : "",
    },
    {
      class: "col-1",
      name: "",
    },
  ];

  if (locations.length < 2) {
    headerColumns.splice(1, 1);
  }

  const selectedServicesHeaderData = {
    rowClass: "findServicesHeaderRow row",
    columns: headerColumns,
  };

  const getFindServicesFooterData = () => {
    let content = "";
    content = (
      <div>
        <SeafoamButton
          onClick={(e) => {
            let servicesToAdd = [];
            for (let i = 0; i < services.length; i++) {
              for (let j = 0; j < checkedServices.length; j++) {
                if (
                  services[i].id === checkedServices[j].id &&
                  checkedServices[j].checked === true
                ) {
                  servicesToAdd.push(services[i]);
                }
              }
            }
            let servicesArray = selectedServices.concat(servicesToAdd);
            servicesArray = servicesArray.map((service, index) => {
              var o = Object.assign({}, service);
              o.key = index;
              return o;
            });
            setSelectedServices(servicesArray);
            setCheckedAll(false);
            setCheckedServices(
              checkedServices.map((service) => ({ ...service, checked: false }))
            );
          }}
          type="button"
          text="Include Selected"
        />
      </div>
    );
    return {
      rowClass: "col-12",
      columns: [{ class: "findServicesFooter", content: content }],
    };
  };

  const findServicesTableData = getTableData(services);
  const findServicesFooterData = getFindServicesFooterData();

  let findServicesTable = new ScopeStackTable(
    "services",
    findServicesHeaderData,
    findServicesTableData,
    findServicesFooterData
  );

  const selectedServicesTableData = getTableData(selectedServices);
  const selectedServicesFooterData = null;

  let selectedServicesTable = new ScopeStackTable(
    "services",
    selectedServicesHeaderData,
    selectedServicesTableData,
    selectedServicesFooterData
  );

  const validateSubmittedService = (service) => {
    let isValid = true;
    let resourceId = "";
    let locationId = "";
    for (let i = 0; i < resources.length; i++) {
      let resourceData = service.relationships.resource.data;
      if (
        resources[i].type == "resources" &&
        resourceData &&
        resourceData.id == resources[i].id
      ) {
        resourceId = service.relationships.resource.data.id;
      }
      if (
        resources[i].type == "resource-rates" &&
        resourceData &&
        resourceData.id == resources[i].relationships["resource"].data.id
      ) {
        let categoryIds = [
          service.relationships["service-category"].data.id,
          service.attributes["lob-id"].toString(),
        ];
        let resourceCategory =
          resources[i].relationships["line-of-business"].data;
        if (resourceCategory && categoryIds.includes(resourceCategory.id)) {
          resourceId = `${resources[i].type}-${resources[i].id}`;
        }
      }
    }
    let serviceId = service.key;

    for (let i = 0; i < changedResources.length; i++) {
      if (changedResources[i].service == serviceId) {
        resourceId = changedResources[i].resourceId;
      }
    }

    if (
      (resourceId == "" || resourceId == -1) &&
      serviceType == "professional"
    ) {
      isValid = false;
      setErrorMessages("You must pick a resource for each service.");
      setShowFailAlert(true);
    }

    for (let i = 0; i < changedLocations.length; i++) {
      if (changedLocations[i].service == serviceId) {
        locationId = changedLocations[i].locationId;
      }
    }

    if (
      locationId == "" &&
      serviceType == "professional" &&
      locations.length > 1
    ) {
      isValid = false;
      setErrorMessages("You must pick a location for each service.");
      setShowFailAlert(true);
    }

    return [isValid, resourceId, serviceId, locationId];
  };

  const prepareServiceData = (service) => {
    let resourceId = validateSubmittedService(service)[1];
    let serviceId = validateSubmittedService(service)[2];
    let locationId = validateSubmittedService(service)[3];
    let quantity = service.attributes.quantity;
    let hours = service.attributes["suggested-hours"];
    for (let i = 0; i < changedQuantities.length; i++) {
      if (changedQuantities[i].service == serviceId) {
        quantity = changedQuantities[i].quantity;
      }
    }
    for (let i = 0; i < changedHours.length; i++) {
      if (changedHours[i].service == serviceId) {
        hours = changedHours[i].hours;
      }
    }

    let serviceData = {
      data: {
        type: "project-services",
        attributes: {
          "task-source": "standard",
          quantity: quantity,
          "override-hours": hours,
        },
        relationships: {
          project: { data: { type: "projects", id: project_id } },
          service: { data: { type: "services", id: service.id } },
          // resource: { data: { type: "resources", id: resourceId } },
          "project-location": {
            data: { type: "project-locations", id: locationId },
          },
        },
      },
    };

    if (resourceId.includes("project")) {
      serviceData.data.relationships["project-resource"] = {
        data: { type: "project-resources", id: resourceId.split("-")[2] },
      };
    } else if (resourceId.includes("resource-rates")) {
      serviceData.data.attributes["resource-rate-id"] = resourceId
        .split("-")
        .pop();
    } else if (resourceId.includes("resources")) {
      serviceData.data.relationships["resource"] = {
        data: { type: "resources", id: resourceId.split("-").pop() },
      };
    } else {
      serviceData.data.relationships["resource"] = {
        data: { type: "resources", id: resourceId },
      };
    }

    if (serviceType === "managed") {
      delete serviceData.data.relationships.resource;
      delete serviceData.data.relationships["project-location"];
    }

    if (locations.length <= 1) {
      delete serviceData.data.relationships["project-location"];
    }

    return serviceData;
  };

  const processServiceData = (options) => {
    let services = options["data"];
    let successful = options["success"];

    if (services.length == 0) {
      if (successful) {
        if (serviceType == "professional") {
          window.location.replace(
            `${appHost}/projects/${project_id}/project_editor`
          );
        }
        if (serviceType == "managed") {
          window.location.replace(
            `${appHost}/projects/${project_id}/managed_service`
          );
        }
      }
    }

    if (services.length !== 0) {
      let serviceData = services.shift();
      API.Post(
        `${apiHost}/${account_slug}/v1/project-services`,
        serviceData,
        authorizationCode
      )
        .then((response) => {
          successful =
            successful && response.status >= 200 && response.status <= 299;
          processServiceData({ data: services, success: successful });
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "Service details"));
          setShowFailAlert(true);
          processServiceData({ data: services, successful: false });
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let preparedValidServices = [];
    let formIsValid = true;
    selectedServices.forEach((service) => {
      formIsValid &&= validateSubmittedService(service)[0];
    });

    if (formIsValid === false) return;
    selectedServices.forEach((service) => {
      let serviceData = prepareServiceData(service);
      preparedValidServices.push(serviceData);
    });
    processServiceData({ data: preparedValidServices, success: true });
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleFailAlert()}
      <Card>
        <Card.Body>
          {isLoading ? (
            <ScopeStackSpinner />
          ) : (
            <>
              <Form onSubmit={handleSearch}>
                <Row style={{ alignItems: "center" }}>
                  <Col sm={3}>
                    <FormTextField
                      placeholder="Name of Service includes.."
                      value={searchServicesText}
                      onChange={(e) => setSearchServicesText(e.target.value)}
                    ></FormTextField>
                  </Col>
                  <Col sm={2}>
                    <FormTextField
                      placeholder="SKU of service includes..."
                      value={searchSku}
                      onChange={(e) => setSearchSku(e.target.value)}
                    ></FormTextField>
                  </Col>
                  <Col sm={2}>
                    <ControlledDropDown
                      value={selectedLob}
                      options={[
                        <option accessKey={null} key={null}>
                          In Line of Business
                        </option>,
                        linesOfBusiness.map((lob) => (
                          <option accessKey={lob.id} key={lob.id}>
                            {lob.attributes.name}
                          </option>
                        )),
                      ]}
                      onChange={(e) => {
                        setSelectedLob(e.target.value);
                        let selected = e.target.options.selectedIndex;
                        setLobId(e.target.options[selected].accessKey);
                      }}
                    />
                  </Col>
                  {serviceType === "professional" ? (
                    <Col sm={2}>
                      <ControlledDropDown
                        value={selectedPhase}
                        options={[
                          <option accessKey={null} key={null}>
                            In Phase
                          </option>,
                          phases.map((phase) => (
                            <option accessKey={phase.id} key={phase.id}>
                              {phase.attributes.name}
                            </option>
                          )),
                        ]}
                        onChange={(e) => {
                          setSelectedPhase(e.target.value);
                          let selected = e.target.options.selectedIndex;
                          setPhaseId(e.target.options[selected].accessKey);
                        }}
                      />
                    </Col>
                  ) : null}
                  <Col sm={2}>
                    <ControlledDropDown
                      value={selectedBlueprint}
                      options={[
                        <option accessKey={null} key={null}>
                          In Blueprint
                        </option>,
                        blueprints.map((blueprint) => (
                          <option accessKey={blueprint.id} key={blueprint.id}>
                            {blueprint.attributes.name}
                          </option>
                        )),
                      ]}
                      onChange={(e) => {
                        setSelectedBlueprint(e.target.value);
                        let selected = e.target.options.selectedIndex;
                        setBlueprintId(e.target.options[selected].accessKey);
                      }}
                    />
                  </Col>
                  <Col sm={1}>
                    <SeafoamButton
                      type="submit"
                      text="Search"
                      onKeyPress={(e) =>
                        e.key === "Enter" ? handleSearch() : null
                      }
                    />
                  </Col>
                </Row>
              </Form>
              <Form onSubmit={handleSubmit}>
                <BaseTable
                  className="findServicesTable"
                  striped={false}
                  hover={false}
                  bordered={false}
                  headerRows={findServicesTable.buildHeaderRows()}
                  dataRows={findServicesTable.buildDataRows()}
                  footerRows={findServicesTable.buildFooterRows()}
                />
                <BaseTable
                  className="selectedServicesTable"
                  striped={false}
                  hover={false}
                  bordered={false}
                  headerRows={selectedServicesTable.buildHeaderRows()}
                  dataRows={selectedServicesTable.buildDataRows()}
                  footerRows={selectedServicesTable.buildFooterRows()}
                />
                <Row>
                  <Col xs={6}>
                    <SeafoamButton
                      disabled={selectedServices.length === 0 ? true : false}
                      type="submit"
                      text="Add Services to Project"
                    />
                  </Col>
                  <Col xs={6}>
                    <BackButton
                      url={`/projects/${project_id}/project_editor`}
                    />
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default NewStandardTask;
