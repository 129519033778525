import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import ProjectPartnerRequests from "./ProjectPartnerRequests/ProjectPartnerRequests";
import NewPartnerRequest from "./NewPartnerRequest/NewPartnerRequest";
import API from "@API";
import EditPartnerRequest from "./EditPartnerRequest/EditPartnerRequest";
import { capitalizeFirstLetter } from "@utils/helperFunctions";
import RevisePartnerRequest from "./PartnerServices/RevisePartnerRequest";
import OurDescription from "./PartnerServices/OurDescription";
import PartnerDescription from "./PartnerServices/PartnerDescription";

function PartnerRequestsMspa({ account_slug, project_id, account_id }) {
  // Env
  const [authorizationCode] = useOauth();
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  // Set the State
  const [projectStatus, setProjectStatus] = useState("");
  const [partnerRequestCreated, setPartnerRequestCreated] = useState(false);
  const [partnerRequestUpdated, setPartnerRequestUpdated] = useState(false);
  const [partnerRequestId, setPartnerRequestId] = useState(null);
  const [partnerRequests, setPartnerRequests] = useState([]);
  const [partners, setPartners] = useState([]);
  const [linesOfBusiness, setLinesOfBusiness] = useState([]);
  const [currencyUnit, setCurrencyUnit] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [languageFields, setLanguageFields] = useState([]);
  const [phases, setPhases] = useState([]);
  const [thirdPartyServices, setThirdPartyServices] = useState([]);
  const [subtasks, setSubtasks] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [requests, setRequests] = useState("");
  const [requestStatus, setRequestStatus] = useState("");
  const [ourDescriptionLanguageFields, setOurDescriptionLanguageFields] =
    useState([]);
  const [rateType, setRateType] = useState("Hours");

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get Project Info
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-phases,project-phases.phase`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setProjectStatus(data.attributes.status);
        setCurrencyUnit(data.attributes["field-labels"].currency_unit);

        const paymentInfo = data.attributes["payment-info"];
        let rateType =
          paymentInfo["rate-type"] !== "hours"
            ? capitalizeFirstLetter(paymentInfo["rate-type"]) + "s"
            : capitalizeFirstLetter(paymentInfo["rate-type"]);
        setRateType(rateType);

        let projectPhases = response.data.included.filter(
          (item) => item.type === "project-phases"
        );
        setPhases(projectPhases);
      });

      // Get Partners
      API.Get(
        `${apiHost}/${account_slug}/v1/subcontractors`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setPartners(data);
      });

      // Get lines of business
      API.Get(
        `${apiHost}/${account_slug}/v1/line-of-businesses`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        if (data !== null) setLinesOfBusiness(data);
      });

      // Get Partner Requests
      API.Get(
        `${apiHost}/${account_slug}/v1/partner-requests?include=lob,subcontractor,third-party-services,third-party-services.project-phase,currency&filter[project]=${project_id}`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        let includedData = response.data.included;
        setPartnerRequests(data);
        setIsLoading(false);
        setRequests(data);
        let status = "";
        for (let j = 0; j < data.length; j++) {
          if (data[j].id == id) {
            status = data[j].attributes.status;
            setRequestStatus(status);
          }
        }

        if (status == "complete") {
          API.Get(
            `${apiHost}/${account_slug}/v1/third-party-services?filter[source-type]=ThirdPartyRequest&filter[source-id]=${id}&include=subtasks,project-phase`,
            authorizationCode
          ).then((response) => {
            let data = response.data.data;
            let includedData = response.data.included;
            if (data !== null) {
              setThirdPartyServices(data);
              if (includedData) {
                setSubtasks(
                  includedData.filter(
                    (service) =>
                      service.attributes["source-type"] == "ThirdPartyService"
                  )
                );
              }
            }
          });
        }

        if (status !== "complete" && status !== "") {
          API.Get(
            `${apiHost}/${account_slug}/v1/partner-requests/${id}/external-services?include=subtasks,project-phase`,
            authorizationCode
          ).then((response) => {
            let data = response.data.data;
            let includedData = response.data.included;
            if (data !== null) {
              setThirdPartyServices(data);
              if (includedData) {
                setSubtasks(
                  includedData.filter(
                    (item) => item.type == "third-party-services"
                  )
                );
              }
            }
          });
        }
      });

      // Get Language Fields
      API.Get(
        `${apiHost}/${account_slug}/v1/language-fields`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        let pricing = {
          id: -1,
          type: "language-fields",
          attributes: {
            name: "Pricing",
            "sow-language": "pricing",
          },
        };
        data.push(pricing);
        let serviceDescription = {
          id: 0,
          type: "language-fields",
          attributes: {
            name: "Service Description",
            "sow-language": "service_description",
          },
        };
        data.unshift(serviceDescription);
        setLanguageFields(data);
        setOurDescriptionLanguageFields(
          data.filter((language) => language.attributes.name !== "Pricing")
        );
      });

      // Get Currencies
      API.Get(
        `${apiHost}/${account_slug}/v1/currencies`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setCurrencies(data);
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/third_party_requests"}
            element={
              <ProjectPartnerRequests
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                partnerRequestId={partnerRequestId}
                setPartnerRequestId={(id) => setPartnerRequestId(id)}
                projectStatus={projectStatus}
                partnerRequests={partnerRequests}
                partners={partners}
                partnerRequestCreated={partnerRequestCreated}
                partnerRequestUpdated={partnerRequestUpdated}
                linesOfBusiness={linesOfBusiness}
                currencyUnit={currencyUnit}
                isLoading={isLoading}
              />
            }
          />
          <Route
            path={"/projects/:project_id/third_party_requests/new"}
            element={
              <NewPartnerRequest
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                partnerRequests={partnerRequests}
                partners={partners}
                partnerRequestCreated={partnerRequestCreated}
                setPartnerRequestCreated={(created) =>
                  setPartnerRequestCreated(created)
                }
                partnerRequestId={partnerRequestId}
                linesOfBusiness={linesOfBusiness}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={
              "/projects/:project_id/third_party_requests/:partnerRequestId/edit"
            }
            element={
              <EditPartnerRequest
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                partnerRequests={partnerRequests}
                partners={partners}
                partnerRequestUpdated={partnerRequestUpdated}
                partnerRequestCreated={partnerRequestCreated}
                setPartnerRequestUpdated={(updated) =>
                  setPartnerRequestUpdated(updated)
                }
                partnerRequestId={partnerRequestId}
                linesOfBusiness={linesOfBusiness}
                thirdPartyServices={thirdPartyServices}
                currencies={currencies}
                requestStatus={requestStatus}
                currencyUnit={currencyUnit}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={
              "/projects/:project_id/third_party_requests/:partnerRequestId/external_services"
            }
            element={
              <PartnerDescription
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                partnerRequestId={partnerRequestId}
                partners={partners}
                languageFields={languageFields}
                phases={phases}
                thirdPartyServices={thirdPartyServices}
                partnerRequests={requests}
                requestStatus={requestStatus}
                subtasks={subtasks}
                currencyUnit={currencyUnit}
                rateType={rateType}
              />
            }
          />
          <Route
            path={
              "/projects/:project_id/third_party_requests/:partnerRequestId/third_party_services"
            }
            element={
              <OurDescription
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                partnerRequestId={partnerRequestId}
                partners={partners}
                languageFields={ourDescriptionLanguageFields}
                phases={phases}
                thirdPartyServices={thirdPartyServices}
                subtasks={subtasks}
                partnerRequests={requests}
                requestStatus={requestStatus}
                currencyUnit={currencyUnit}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={
              "/projects/:project_id/third_party_requests/:partnerRequestId/revision"
            }
            element={
              <RevisePartnerRequest
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                partnerRequestId={partnerRequestId}
                partners={partners}
                languageFields={languageFields}
                phases={phases}
                thirdPartyServices={thirdPartyServices}
                subtasks={subtasks}
                partnerRequests={requests}
                requestStatus={requestStatus}
                setRequestStatus={(status) => setRequestStatus(status)}
                currencyUnit={currencyUnit}
                authorizationCode={authorizationCode}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default PartnerRequestsMspa;
