import { useListProjectsForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { PreviewTableItem } from "../types";

interface Params {
  serviceId: string;
  currentPage: number;
}

const useListProjectsForService = ({ serviceId, currentPage }: Params) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError

  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListProjectsForAccountQuery({
      slug: accountSlug,
      filter: { "service-id": serviceId },
      page: {
        number: currentPage,
        size: 5,
      },
    });

  let projects: PreviewTableItem[] = [];

  if (data?.data) {
    try {
      projects = data.data.map((project, i) => {
        if (!project.id) {
          throw new Error("Missing project.id");
        }
        if (!project.attributes?.["project-name"]) {
          throw new Error("Missing project.attributes.project-name");
        }

        return {
          id: String(project.id),
          name: project.attributes["project-name"],
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    projects,
    projectsLoading: isLoading,
    projectsError: error ?? customError,
    projectsPageMeta: pageMeta,
    projectsRefetch: refetch,
    projectsFetching: isFetching,
  };
};
export default useListProjectsForService;
