import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import "./Survey.css";
import StaticBanner from "@components/Alerts/StaticBanner/StaticBanner";
import RequiredReactSelect from "@components/FormsV2/RequiredReactSelect/RequiredReactSelect";

const Survey = ({
  questionnaire,
  questions,
  setSurveyResponses,
  surveyResponses,
  handleSurveySubmit,
  showRecommendations,
  recommendations,
  isFromSurveyPage = false,
  currentSurvey,
  showNoAccessError = false,
}) => {
  const [questionsState, setQuestionsState] = useState(
    questions
      .filter((question) => question.attributes.active)
      .map((question) => {
        let selectOptions = question.attributes
          ? question.attributes["select-options"]
          : question["select-options"];
        if (selectOptions.length > 0) {
          let defaultValue = {
            value: "select",
            label: "Select An Option",
          };
          selectOptions.forEach((option) => {
            if (option.default) {
              defaultValue = {
                value: option.value,
                label: option.key,
              };
            }
          });
          question = { ...question, answer: defaultValue };
        }
        return question;
      })
  );

  useEffect(() => {
    if (currentSurvey) {
      setSurveyResponses(currentSurvey.attributes.responses);
    } else {
      setSurveyResponses(
        questions
          .filter((question) => question.attributes.active)
          .sort((a, b) => a.attributes.position - b.attributes.position)
          .map((question) => {
            let selectOptions = question.attributes
              ? question.attributes["select-options"]
              : question["select-options"];
            const defaultValue = selectOptions.find(
              (option) => option.default
            )?.value;
            return {
              question: question.attributes.question,
              "question-id": parseInt(question.id),
              answer: defaultValue
                ? defaultValue
                : questionnaire.attributes["value-type"] === "checkbox"
                ? "off"
                : "",
            };
          })
      );
    }
  }, [currentSurvey]);

  useEffect(() => {}, [showNoAccessError]);

  const customValidateReactSelect = () => {
    let isValid = true;
    const newQuestionState = questionsState.map((question) => {
      let selectOptions = question.attributes
        ? question.attributes["select-options"]
        : question["select-options"];
      if (
        selectOptions &&
        selectOptions.length > 0 &&
        question.answer.value == "select" &&
        question.attributes.required
      ) {
        isValid = false;
        question = { ...question, showInvalid: true };
      } else {
        question = { ...question, showInvalid: false };
      }
      return question;
    });
    setQuestionsState(newQuestionState);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = customValidateReactSelect();
    if (isValid) {
      handleSurveySubmit();
    }
  };

  const buildInputElement = (question) => {
    let selectOptions = question.attributes
      ? question.attributes["select-options"]
      : question["select-options"];
    if (selectOptions.length > 0) {
      let defaultValue = {
        value: "select",
        label: "Select An Option",
      };
      selectOptions.forEach((option) => {
        if (option.default) {
          defaultValue = {
            value: option.value,
            label: option.key,
          };
        }
      });
      return (
        <Col key={question.id} xs={6}>
          <Form.Group controlId={question.id}>
            <RequiredReactSelect
              label={
                <span>
                  {question.attributes.required ? (
                    <span style={{ color: "#FD0300" }}>* </span>
                  ) : null}
                  <span>{question.attributes.question}</span>
                </span>
              }
              required={question.attributes.required}
              isInvalid={question.showInvalid}
              id={question.id}
              key={question.id}
              defaultValue={defaultValue}
              onChange={(e) => {
                setQuestionsState(
                  questionsState.map((ques) => {
                    if (ques.id == question.id) {
                      ques = {
                        ...ques,
                        answer: e,
                        showInvalid: false,
                      };
                    }
                    return ques;
                  })
                );
                const updatedSurveyResponses = surveyResponses.map(
                  (response) => {
                    if (response["question-id"] == question.id) {
                      return {
                        ...response,
                        answer: e.value,
                      };
                    }
                    return response;
                  }
                );
                setSurveyResponses(updatedSurveyResponses);
              }}
              options={selectOptions.map((option) => {
                return {
                  label: option.key.key ? option.key.key : option.key,
                  value: option.key.value ? option.key.value : option.value,
                };
              })}
            />
          </Form.Group>
        </Col>
      );
    } else if (
      question.attributes["value-type"] === "text" ||
      question.attributes["value-type"] === "email"
    ) {
      return (
        <Col key={question.id} xs={6}>
          <Form.Group controlId={question.id}>
            <Form.Label>
              {question.attributes.required ? (
                <span style={{ color: "#FD0300" }}>* </span>
              ) : null}
              {question.attributes.question}
            </Form.Label>
            <Form.Control
              required={question.attributes.required}
              type={question.attributes["value-type"]}
              onChange={(e) => {
                const updatedSurveyResponses = surveyResponses.map(
                  (response) => {
                    if (response["question-id"] == question.id) {
                      return {
                        ...response,
                        answer: e.target.value,
                      };
                    }
                    return response;
                  }
                );
                setSurveyResponses(updatedSurveyResponses);
              }}
            />
          </Form.Group>
        </Col>
      );
    } else if (question.attributes["value-type"] === "number") {
      return (
        <Col key={question.id} xs={6}>
          <Form.Group controlId={question.id}>
            <Form.Label>
              {question.attributes.required ? (
                <span style={{ color: "#FD0300" }}>* </span>
              ) : null}
              {question.attributes.question}
            </Form.Label>
            <Form.Control
              required={question.attributes.required}
              type="number"
              min={
                question.settings && question.settings.min
                  ? parseInt(question.settings.min)
                  : 0
              }
              max={
                question.settings && question.settings.max
                  ? parseInt(question.settings.max)
                  : undefined
              }
              step={
                question.settings && question.settings.step
                  ? parseInt(question.settings.step)
                  : 1
              }
              onChange={(e) => {
                const updatedSurveyResponses = surveyResponses.map(
                  (response) => {
                    if (response["question-id"] == question.id) {
                      return {
                        ...response,
                        answer: e.target.value,
                      };
                    }
                    return response;
                  }
                );
                setSurveyResponses(updatedSurveyResponses);
              }}
            />
          </Form.Group>
        </Col>
      );
    } else if (question.attributes["value-type"] === "checkbox") {
      return (
        <Col key={question.id} xs={6}>
          <Form.Group controlId={question.id}>
            <Form.Check
              type="checkbox"
              label={
                <span>
                  {question.attributes.required ? (
                    <span style={{ color: "#FD0300" }}>* </span>
                  ) : null}
                  {question.attributes.question}
                </span>
              }
              required={question.attributes.required}
              onChange={(e) => {
                const updatedSurveyResponses = surveyResponses.map(
                  (response) => {
                    if (
                      Number(response["question-id"]) === Number(question.id)
                    ) {
                      return {
                        ...response,
                        answer: e.target.checked ? "on" : "off",
                      };
                    }
                    return response;
                  }
                );
                setSurveyResponses(updatedSurveyResponses);
              }}
            />
          </Form.Group>
        </Col>
      );
    } else if (question.attributes["value-type"] === "date") {
      return (
        <Col key={question.id} xs={6}>
          <Form.Group controlId={question.id}>
            <Form.Label>
              {question.attributes.required ? (
                <span style={{ color: "#FD0300" }}>* </span>
              ) : null}
              {question.attributes.question}
            </Form.Label>
            <Form.Control
              required={question.attributes.required}
              onChange={(date) => {
                const updatedSurveyResponses = surveyResponses.map(
                  (response) => {
                    if (response["question-id"] === question.id) {
                      return {
                        ...response,
                        answer: date.target.value,
                      };
                    }
                    return response;
                  }
                );
                setSurveyResponses(updatedSurveyResponses);
              }}
              min={new Date(question.attributes.settings.min)}
              max={new Date(question.attributes.settings.max)}
              type="date"
            />
          </Form.Group>
        </Col>
      );
    } else if (question.attributes["value-type"] === "phone") {
      return (
        <Col key={question.id || question["survey-response-id"]} xs={6}>
          <Form.Group controlId={question.id}>
            <Form.Label>
              {question.attributes.required ? (
                <span style={{ color: "#FD0300" }}>* </span>
              ) : null}
              {question.attributes.question}
            </Form.Label>
            <input
              className="form-control"
              required={question.required}
              placeholder="2125553456"
              value={question.answer}
              pattern="^\+?(?:[0-9]●?){6,14}[0-9]$"
              type="tel"
              onChange={(e) => {
                  const updatedSurveyResponses = surveyResponses.map(
                    (response) => {
                      if (response["question-id"] === question.id) {
                        return {
                          ...response,
                          answer: e.target.value,
                        };
                      }
                      return response;
                    }
                  );
                  setSurveyResponses(updatedSurveyResponses);
              }}
            />
          </Form.Group>
        </Col>
      );
    }
  };
  return (
    <>
      <Row className="mb-3">
        <Col xs={isFromSurveyPage ? 12 : 6}>
          {!isFromSurveyPage ? (
            <>
              <div
                style={{ fontSize: "20px", margin: "0 0 30px 0" }}
                className="accordianSubtitle"
              >
                Complete Survey to Continue. When finished, click “Calculate”.
              </div>
              <div style={{ marginBottom: "20px" }}>
                {questionnaire.attributes.introduction}
              </div>
            </>
          ) : null}

          <Form
            id={isFromSurveyPage ? "takeSurveyForm" : "surveyForm"}
            onSubmit={handleSubmit}
          >
            <Row className="mb-3">
              {questionsState
                .filter((question) => question.attributes.active)
                .map((question) => {
                  return buildInputElement(question);
                })}
            </Row>
            <hr
              className="sectionDivider"
              style={{ width: "100%", margin: "18px 0px 24px" }}
            ></hr>
            {showNoAccessError ? (
              <div style={{ height: "50px", marginBottom: "20px" }}>
                <StaticBanner
                  bannerText={
                    "You do not have access to one or more services that have been recommended for this survey."
                  }
                  variant="warning"
                />
              </div>
            ) : null}
            <Button
              style={{ marginRight: "10px" }}
              className="seafoamBgButton"
              type="submit"
              form={isFromSurveyPage ? "takeSurveyForm" : "surveyForm"}
              disabled={showNoAccessError}
            >
              {showRecommendations ? "Update Responses" : "Calculate"}
            </Button>
          </Form>
        </Col>
        {showRecommendations && !showNoAccessError ? (
          <Col xs={6}>
            <div
              style={{ fontSize: "20px", margin: "0 0 30px 0" }}
              className="accordianSubtitle"
            >
              Suggested Services
            </div>
            <div style={{ marginBottom: "20px" }}>
              Based on your entries, these services have been suggested. If
              something seems wrong, check your entries and recalculate.
            </div>
            <div className="suggestionCard">
              {recommendations.length > 0 ? (
                <>
                  <div className="suggestionCardHeader">
                    <div>Services / Subservices</div>
                    <div style={{ paddingRight: "58px" }}>Qty</div>
                  </div>
                  <hr
                    className="sectionDivider"
                    style={{ width: "100%", margin: "18px 0px 24px" }}
                  ></hr>
                  <ul style={{ padding: "0px" }}>
                    {recommendations.map((recommendation) => (
                      <React.Fragment key={recommendation.service.id}>
                        <li className="suggestedService">
                          <div className="recommendation">
                            <div>{recommendation.service.attributes.name}</div>
                            <div>{recommendation.service.quantity}</div>
                          </div>
                        </li>
                        {recommendation.subservices.map((subservice) => (
                          <li
                            className="suggestedSubservice"
                            key={subservice.id}
                          >
                            <div className="recommendation">
                              <div>
                                &#8226; &emsp;{subservice.attributes.name}
                              </div>
                              <div>{subservice.attributes.quantity}</div>
                            </div>
                          </li>
                        ))}
                      </React.Fragment>
                    ))}
                  </ul>
                </>
              ) : (
                <div>No recommendations</div>
              )}
            </div>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Survey;
