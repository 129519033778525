import DataTable from "@components/DataTable";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import useListVersionsForProject from "./api/useListVersionsForProject";
import { Version } from "../types/Version";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { useRevertProjectVersionMutation } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
const strftime = require("strftime");

function Show({
  auditLogPermission,
  projectLoading,
  currentUserLoading,
  project,
  account,
}) {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const {
    data: versions,
    versionsLoading,
    versionsError,
    refetch,
  } = useListVersionsForProject({ filter: { project: project?.data?.id } });
  const [revertToVersion] = useRevertProjectVersionMutation();
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showConfirmRevertModal, setShowConfirmRevertModal] = useState(false);
  const [versionId, setVersionId] = useState<number | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  const revert = () => {
    if (versionId) {
      revertToVersion({ slug: accountSlug, id: versionId })
        .unwrap()
        .then(() => {
          setSuccessMessage("Version reverted successfully!");
          setShowSuccessAlert(true);
          setShowConfirmRevertModal(false);
          refetch();
        })
        .catch((err) => {
          const error = err?.data?.error;
          setErrorMessage(
            `Version could not be reverted due to ${error} error! Please try again later.`
          );
          setShowFailAlert(true);
          setShowConfirmRevertModal(false);
        });
    }
  };

  const openRevertConfirmModal = (versionId) => {
    setVersionId(Number(versionId));
    setShowConfirmRevertModal(true);
  };

  const getVersionColumns = () => {
    let versionColumns: ColumnDef<Version>[] = [
      {
        header: "Version Name",
        cell(props) {
          return (
            <div
              style={{ textDecoration: "underline" }}
              onClick={() => {
                const id = props.row.original.id;
                navigate(`${id}/edit`, {
                  state: { version: props.row.original },
                });
              }}
            >
              {props.row.original.name}
            </div>
          );
        },
      },
      {
        header: "Description",
        cell(props) {
          return props.row.original.description;
        },
      },
      {
        header: "Created By",
        cell(props) {
          return props.row.original.createdBy;
        },
      },
      {
        header: "Created On",
        cell(props) {
          const createdOn: Date = props.row.original?.createdOn
            ? new Date(props.row.original.createdOn)
            : new Date();
          return strftime(account?.attributes?.["long-date-format"] + " @ " + account?.attributes?.["time-format"], createdOn);
        },
      },
    ];
    if (auditLogPermission === "manage") {
      versionColumns.push({
        header: " ",
        cell(props) {
          return (
            <FontAwesomeIcon
              icon={faClockRotateLeft}
              onClick={() => openRevertConfirmModal(props.row.original.id)}
            />
          );
        },
      });
    }
    return versionColumns;
  };
  return (
    <Card>
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Card.Header
        style={{ marginLeft: "16px", marginTop: "16px" }}
        className="cardHeaderOverride headerText"
      >
        Project Versions
      </Card.Header>
      <Card.Body>
        {auditLogPermission === "none" ? (
          <div style={{ marginLeft: "16px" }}>
            You do not have permission to view the version history of this
            project. Please contact your administrator to gain access.
          </div>
        ) : (
          <div>
            {projectLoading || currentUserLoading ? (
              <SmallSpinner />
            ) : (
              <DataTable
                classForTable="versionsTable"
                data={
                  versions.length && versions.length > 0
                    ? versions
                    : [
                        {
                          id: "noRow",
                          name: "No versions found",
                        },
                      ]
                }
                columns={getVersionColumns()}
                selectable={false}
                striped={true}
                selectedIds={[]}
                setSelectedIds={() => null}
                dragAndDrop={false}
                isCollapsible={false}
                hasSettings={false}
              />
            )}
          </div>
        )}
      </Card.Body>
      <ConfirmationModal
        show={showConfirmRevertModal}
        title="Revert Version"
        message="Are you sure you want to revert this Version?"
        onConfirm={revert}
        onCancel={() => setShowConfirmRevertModal(false)}
      />
    </Card>
  );
}

export default Show;
