import React from "react";
import MultiSelectAutocomplete from "@components/Forms/MultiSelectAutocomplete/MultiSelectAutocomplete";
import API from "@API";
import buildOptionsArray from "./buildOptionsArray";
import buildNestedOptionsArray from "./buildNestedOptionsArray";

const buildTextFilterSection = (
  account_slug,
  authorizationCode,
  setSearched,
  searched,
  label,
  type,
  setSelected,
  setDisplayed,
  displayed
) => {
  const url = new URL(
    `/${account_slug}/v1/${type}`,
    process.env.REACT_APP_SCOPESTACK_API_HOST
  );
  if (type !== "tags") {
    url.searchParams.append("filter[active]", "true");
  }
  if (searched.length === 0) {
    API.Get(url.toString(), authorizationCode).then((res) => {
      if (type === "service-categories") {
        setSearched(buildNestedOptionsArray(res.data.data));
      } else {
        setSearched(buildOptionsArray(res.data.data));
      }
    });
  }

  return {
    sectionClass: "projectInfoSection",
    sectionContent: [
      <MultiSelectAutocomplete
        className="slideOutMultiSelect"
        key="client"
        label={label}
        width="500px"
        value={displayed ? displayed : []}
        options={searched ? searched : []}
        onChange={(e) => {
          setDisplayed(e);
          let ids = e.map((item) => item.value);
          setSelected(ids);
        }}
        onInputChange={(e) => {
          url.searchParams.append("filter[name]", e);

          API.Get(url.toString(), authorizationCode).then((res) => {
            if (type === "service-categories") {
              setSearched(buildNestedOptionsArray(res.data.data));
            } else {
              setSearched(buildOptionsArray(res.data.data));
            }
          });
        }}
      />,
    ],
  };
};

export default buildTextFilterSection;
