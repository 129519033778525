interface Typography {
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  p: string;
}

interface Colors {
  blue: {
    "1": string;
  };
  gray: {
    primary: string;
    "1": string;
    "2": string;
    "3": string;
    "4": string;
    "5": string;
    "6": string;
  };
  navy: {
    "1": string;
    "2": string;
    "3": string;
  };
  red: {
    primary: string;
    "1": string;
    "2": string;
    "3": string;
  };
  seafoam: {
    "1": string;
    "2": string;
  };
  teal: {
    "1": string;
    "2": string;
    "3": string;
  };
  white: string;
  yellow: {
    primary: string;
    "1": string;
    "2": string;
  };
}

export interface Theme {
  colors: Colors;
  typography: Typography;
}

const theme: Theme = {
  colors: {
    blue: {
      "1": "#007BFF",
    },
    gray: {
      primary: "#8F8F8F",
      "1": "#A1A1A1",
      "2": "#C4C4C4",
      "3": "#D7D7D7",
      "4": "#D7D7D7",
      "5": "#EEE",
      "6": "#F2F2F2",
    },
    navy: {
      "1": "#1C2655",
      "2": "#212957",
      "3": "#596086",
    },
    red: {
      primary: "#B90200",
      "1": "#FD0300",
      "2": "#EF4939",
      "3": "#F15825",
    },
    seafoam: {
      "1": "#94D6D8",
      "2": "rgba(99, 196, 171, 0.30)",
    },
    teal: {
      "1": "#418172",
      "2": "#63C4AB",
      "3": "#63C4AB",
    },
    white: "#FFF",
    yellow: {
      primary: "#D68B1C",
      "1": "#FCD22E",
      "2": "#FCCE2B",
    },
  },
  typography: {
    h1: `
      color: #000;
      font-family: Open Sans;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    `,
    h2: `
      color: #000;
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    `,
    h3: `
      color: #000;
      font-family: Open Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    `,
    h4: `
      color: #000;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    `,
    h5: `
      color: #000;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    `,
    p: `
      color: #000;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    `,
  },
};

export default theme;
