import formatData from "./formatData";
import API from "@API";
import loadLobs from "./loadLobs";

const loadStandardServices = async ({
  account_slug,
  authorizationCode,
  filters,
  pageSize,
  pageNumber,
  selectedIds = [],
}) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const url = new URL(`/${account_slug}/v1/services`, apiHost);
  url.searchParams.append(
    "include",
    "service-category,phase,subservices,resource,subservices.resource"
  );
  url.searchParams.append("filter[state]", "active");
  url.searchParams.append("filter[exclude]", selectedIds.join(","));
  url.searchParams.append("page[size]", pageSize);
  url.searchParams.append("page[number]", pageNumber);
  if (filters) {
    for (const [key, value] of Object.entries(filters)) {
      url.searchParams.append(`filter[${key}]`, value);
    }
  }

  try {
    const response = await API.Get(url.toString(), authorizationCode);
    const lobs = await loadLobs({ account_slug, authorizationCode });

    const data = formatData({
      data: response.data.data,
      included: [...response.data.included, ...lobs.data],
    });
    return { data: data, error: null, meta: response.data.meta };
  } catch (error) {
    return { data: null, error };
  }
};

export default loadStandardServices;
