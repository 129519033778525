import React from "react";
import "./NoneExistScreen.css";

function NoneExistScreen({ noneExistText, createNewText }) {
  return (
    <div className="noneContainer">
      <div className="noneExist">{noneExistText}</div>
      <p className="clickCreateNew">{createNewText}</p>
    </div>
  );
}

export default NoneExistScreen;
