import { useListProductsForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { Product } from "../types/Product";

const useListProductsForAccount = ({ page, filter }) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListProductsForAccountQuery({
      slug: accountSlug,
      filter,
      page,
    });

  let products: Product[] = [];

  if (data?.data) {
    try {
      products = data.data.map((product, i) => {
        if (!product.id) {
          throw new Error("Missing product.id");
        }
        if (!product.attributes?.name) {
          throw new Error("Missing product.attributes.name");
        }

        return {
          id: String(product.id),
          name: product.attributes.name || "",
          mfrPart: product.attributes?.["manufacturer-part-nummber"] || "",
          sku: product.attributes?.sku || "",
          price: product.attributes?.["unit-cost"]
            ? String(product.attributes?.["unit-cost"])
            : "",
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    products,
    productsLoading: isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListProductsForAccount;
