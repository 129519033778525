import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import {
  renderResourceName,
  snakeToPascalCase,
  splitPascalCase,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import CardHeader from "@components/CardHeader/CardHeader";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import "./ProjectGovernance.css";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import RouterButton from "@components/Buttons/RouterButton/RouterButton";
import { useNavigate } from "react-router-dom";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ProjectGovernance({
  account_slug,
  project_id,
  setGovernanceId,
  projectStatus,
  governances,
  resources,
  projectResources,
  totalEffort,
  phases,
  governanceCreated,
  governanceUpdated,
  governanceId,
  isLoading,
  rateType,
  authorizationCode,
  lobs,
}) {
  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let totals = [];

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);

  useEffect(() => {
    if (governanceCreated === true) {
      setCreatedAlertIsOpen(true);
    }
  }, [isLoading]);

  const getTableData = () => {
    if (governances.length === 0) {
      return [];
    }

    // Map over the governance state and render a table row for each one with appropriate table data from API (return the mao from the renderTableRows function)
    return governances.map((governance) => {
      const allocationMethod = governance.attributes["allocation-method"];
      const calculationType = governance.attributes["calculation-type"];
      const resourceId =
        governance.relationships.resource &&
        governance.relationships.resource.data
          ? governance.relationships.resource.data.id
          : null;
      const governanceId = governance.id;
      var resource = "";
      var projectPhase = "";

      // Iterate over resources and match them with the governance based on id
      for (let i = 0; i < projectResources.length; i++) {
        let attr = projectResources[i].attributes;
        if (
          governance.relationships["project-resource"] &&
          governance.relationships["project-resource"].data &&
          projectResources[i].id ==
            governance.relationships["project-resource"].data.id
        ) {
          resource = renderResourceName(projectResources[i], lobs);
        }
      }

      if (resource == "") {
        for (let i = 0; i < resources.length; i++) {
          let attr = resources[i].attributes;
          if (resources[i].id == resourceId) {
            resource = renderResourceName(resources[i], lobs);
          }
        }
      }

      const deleteGovernance = (e) => {
        e.stopPropagation();
        let userConfirms = confirm(
          "Are you sure you want to delete this governance item?"
        );
        if (userConfirms) {
          let governance = {
            data: {
              type: "project-governances",
              id: governanceId,
            },
          };
          API.Delete(
            `${apiHost}/${account_slug}/v1/project-governances/${governanceId}`,
            governance,
            authorizationCode
          )
            .then((response) => {
              if (response.status == 204) {
                window.location.reload();
              }
            })
            .catch((err) => {
              setErrorMessages(formatUnprocessibleResponse(err, "governance"));
              setShowFailAlert(true);
            });
        }
      };

      // Gets the rate based on calculationType
      const getRate = () => {
        if (calculationType === "percent_of_total") {
          return (parseFloat(governance.attributes.rate) * 100).toFixed(1);
        }
      };

      const getPhase = () => {
        let phase =
          governance.relationships["project-phase"] &&
          governance.relationships["project-phase"].data
            ? governance.relationships["project-phase"].data
            : null;
        if (phase && allocationMethod === "select_phase") {
          let phaseId = phase.id;
          for (let i = 0; i < phases.length; i++) {
            if (phases[i].id === phaseId) {
              projectPhase = phases[i].attributes.name;
            }
          }
          return projectPhase;
        } else {
          return splitPascalCase(snakeToPascalCase(allocationMethod));
        }
      };

      // Render icon buttons under actions on hover if the project status is: building
      const renderActions = () => {
        if (projectStatus === "building" && lastWordInLocation !== "summary") {
          return (
            <div className="actionsDiv">
              <OnHoverButtonIcon
                className="actions"
                iconClass="fa fa-remove"
                dataConfirm="Are you sure?"
                buttonText="Delete"
                onClick={(e) => deleteGovernance(e)}
              />
            </div>
          );
        } else {
          return null;
        }
      };

      const redirectToEditGovernance = () => {
        setGovernanceId(governanceId);
        return navigate(
          `/projects/${project_id}/project_governances/${governanceId}/edit`
        );
      };

      const findCategoryById = (catId) => {
        let retVal = null;

        lobs.forEach((lob) => {
          if (lob.id == catId) retVal = lob;
          if (lob.categories && retVal === null) {
            lob.categories.forEach((cat) => {
              if (cat.id == catId) retVal = cat;
            });
          }
        });
        return retVal;
      };

      const getNoPhaseAlert = (type, id) => {
        return (
          <div className="phaseAlert">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            {type} not selected
            <span>(select now)</span>
          </div>
        );
      };

      const getRateData = () => {
        if (calculationType === "fixed_hours") return "Fixed Hours";

        let rate = getRate() + "%";
        if (governance.attributes["filter-type"] === "all_services") {
          return `${rate} of Total hours`;
        } else if (governance.attributes["filter-type"] === "filter_by_phase") {
          let filterId = governance.attributes["filter-id"];
          let phase = phases.find((item) => item.id == filterId);
          if (!phase) {
            return getNoPhaseAlert("Phase", governance.id);
          }
          return `${rate} of ${phase.attributes.name} hours`;
        } else if (governance.attributes["filter-type"] === "filter_by_lob") {
          let filterId = governance.attributes["filter-id"];
          let category = findCategoryById(filterId);
          if (!category) {
            return getNoPhaseAlert("Category", governance.id);
          }
          return `${rate} of ${category.attributes["nested-name"]} hours`;
        }

        return `${
          rate + " " + splitPascalCase(snakeToPascalCase(calculationType))
        }`;
      };

      if (lastWordInLocation === "summary") {
        let hours = parseFloat(governance.attributes.hours).toFixed(2);
        totals.push(hours);
        return {
          rowClass: "summariesRow",
          onClick: null,
          columns: [
            {
              class: "",
              name: governance.attributes.description,
            },
            {
              class: "",
              name: resource,
            },
            {
              class: "text-right",
              name: "1",
            },
            {
              class: "text-right",
              name: hours,
            },
            {
              class: "text-right",
              name: hours,
            },
          ],
          actions: renderActions(),
        };
      }

      // Map returns an array of objects for the ScopeStackTable to accept
      return {
        rowClass: "governanceRow",
        onClick: redirectToEditGovernance,
        columns: [
          {
            class: "",
            name: governance.attributes.description,
          },
          {
            class: "",
            name: getPhase(),
          },
          {
            class: "",
            name: resource,
          },
          {
            class: "",
            name: getRateData(),
          },
          {
            class: "text-right",
            name: parseFloat(governance.attributes.hours).toFixed(2),
          },
        ],
        actions: renderActions(),
      };
    });
  };

  const getFooterData = () => {
    let content = "";
    if (projectStatus === "building" && lastWordInLocation !== "summary") {
      content = (
        <div>
          <RouterButton
            path={`/projects/${project_id}/project_governances/new`}
            className="newResource"
            iconClass="fa fa-plus"
            buttonText="Add Governance"
          />
        </div>
      );
    }
    return {
      rowClass: "col-12",
      columns: [{ class: "governanceFooter", content: content }],
    };
  };

  const getTotalRow = () => {
    const sum = totals.reduce((a, b) =>
      (parseFloat(a) + parseFloat(b)).toFixed(2)
    );
    return {
      rowClass: "totalRow",
      onClick: null,
      columns: [
        {
          class: "",
          name: "",
        },
        {
          class: "",
          name: "",
        },
        {
          class: "",
          name: "",
        },
        {
          class: "text-right",
          name: "Total: ",
        },
        {
          class: "text-right",
          name: sum,
        },
      ],
      actions: null,
    };
  };

  const getHeaderData = () => {
    if (lastWordInLocation === "summary") {
      return {
        rowClass: "",
        columns: [
          {
            class: "",
            name: "Description",
          },
          {
            class: "",
            name: "Resource",
          },
          {
            class: "text-right",
            name: "Quantity",
          },
          {
            class: "text-right",
            name: rateType,
          },
          {
            class: "text-right",
            name: "Total",
          },
        ],
      };
    }
    return {
      rowClass: "",
      columns: [
        {
          class: "",
          name: "Description",
        },
        {
          class: "",
          name: "Phase",
        },
        {
          class: "",
          name: "Resource",
        },
        {
          class: "",
          name: "Calculate Hours By",
        },
        {
          class: "text-right",
          name: rateType,
        },
        {
          class: "text-right",
          name: "Actions",
        },
      ],
    };
  };
  const headerData = getHeaderData();
  const tableData = getTableData();
  if (tableData.length !== 0 && lastWordInLocation === "summary")
    tableData.push(getTotalRow());
  const footerData = getFooterData();

  let governanceTable = new ScopeStackTable(
    "governances",
    headerData,
    tableData,
    footerData
  );

  const detailsCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="governanceAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Project management details created"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  // Return the JSX for the ProjectGovernance Component
  return (
    <div>
      {detailsCreatedAlert()}
      {handleFailAlert()}
      <Card className={lastWordInLocation === "summary" ? "marginCard" : null}>
        <CardHeader title="Project Governance" />
        <Card.Body>
          {lastWordInLocation !== "summary" ? (
            <Row>
              <Col xs={12}>
                <h2 className="text-right totalEffort">
                  Total Effort:{" "}
                  {totalEffort === ""
                    ? "0.00"
                    : parseFloat(totalEffort).toFixed(2)}{" "}
                  {rateType}
                </h2>
              </Col>
            </Row>
          ) : null}
          <BaseTable
            className="scopestack governanceTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={governanceTable.buildHeaderRows()}
            dataRows={governanceTable.buildDataRows()}
            footerRows={governanceTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProjectGovernance;
