import API from "@API";

const loadPhases = async ({ account_slug, authorizationCode }) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const url = new URL(`/${account_slug}/v1/service-categories`, apiHost);
  url.searchParams.append("filter[active]", "true");

  try {
    const response = await API.Get(url.toString(), authorizationCode);

    return { data: response.data.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export default loadPhases;
