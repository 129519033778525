import React, { useState, useEffect } from "react";
import CardHeader from "@components/CardHeader/CardHeader";
import { Card, Form, Row, Col } from "react-bootstrap";
import BackButton from "@components/Buttons/BackButton/BackButton";
import API from "@API";
import { useNavigate } from "react-router-dom";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import {
  capitalizeFirstLetter,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import AutoCompleteText from "@components/Forms/AutoCompleteText/AutoCompleteText";
import AddressForm from "@components/Forms/AddressForm";
import FormNumberField from "@components/Forms/FormNumberField";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";

function VendorQuote({
  account_slug,
  project_id,
  projectStatus,
  vendorQuotes,
  vendorQuoteId,
  setVendorQuoteId,
  account_id,
  setVendorQuoteCreated,
  setVendorQuoteUpdated,
  vendors,
  linesOfBusiness,
  deriveFromServices,
  setDeriveFromServices,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  authorizationCode,
}) {
  // Alerts
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("Vendor Quote updated");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  // Env
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  // Form Field States
  const [cost, setCost] = useState("0.0");
  const [recurringCost, setRecurringCost] = useState("0.0");
  const [frequency, setFrequency] = useState("None");
  const [markup, setMarkup] = useState("0.0");
  const [vendor, setVendor] = useState("");
  const [vendorChanged, setVendorChanged] = useState(false);
  var chosenVendor = "";
  const [selectedLob, setSelectedLob] = useState("");
  const [lobId, setLobId] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [description, setDescription] = useState("");
  const [terms, setTerms] = useState("0");

  // Address states
  const [address, setAddress] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [addressChanged, setAddressChanged] = useState(false);
  const [countryId, setCountryId] = useState(null);

  useEffect(() => {
    // If we are on te edit page, set the state so the info displays
    if (lastWordInLocation === "edit") {
      const getFrequency = (frequency) => {
        if (frequency.includes("_")) {
          return "None";
        }
        return capitalizeFirstLetter(frequency);
      };

      for (let i = 0; i < vendorQuotes.length; i++) {
        if (vendorQuotes[i].id === id) {
          let quote = vendorQuotes[i];
          let attr = quote.attributes;
          setDescription(attr.description);
          setCost(attr.cost);
          setRecurringCost(attr["recurring-cost"]);
          setFrequency(getFrequency(attr["billing-frequency"]));
          setMarkup(attr.markup);
          setDeriveFromServices(attr["service-pricing"]);
          setTerms(attr["mrr-terms"]);

          // Match Vendor relationship
          for (let j = 0; j < vendors.length; j++) {
            let vendorData = quote.relationships.vendor.data;
            if (vendorData && vendorData.id == vendors[j].id) {
              let vendorAttributes = vendors[j].attributes;
              setVendor(vendorAttributes.name);
              setVendorId(vendorData.id);
              setAddress(vendorAttributes["street-address"]);
              setStreet2(vendorAttributes.street2);
              setCity(vendorAttributes.city);
              setState(vendorAttributes.state);
              setZip(vendorAttributes["postal-code"]);
              setCountry(vendorAttributes.country);
            }
          }

          // Match Lob relationship
          for (let k = 0; k < linesOfBusiness.length; k++) {
            let lobData = quote.relationships.lob.data;
            if (lobData) {
              if (lobData.id == linesOfBusiness[k].id) {
                setSelectedLob(renderLobName(linesOfBusiness[k]));
                setLobId(lobData.id);
              } else {
                linesOfBusiness[k].categories.forEach((cat) => {
                  if (cat.id == lobData.id) {
                    setSelectedLob(renderLobName(cat));
                    setLobId(lobData.id);
                  }
                });
              }
            }
          }
        }
      }
    }
  }, [
    vendorQuotes,
    vendors,
    vendorQuoteId,
    linesOfBusiness,
    authorizationCode,
  ]);

  const redirectToEditVendorQuote = (newId) => {
    navigate(`/projects/${project_id}/quotes/${newId}/edit`, {
      from: `/projects/${project_id}/quotes/new`,
    });
    window.location.reload();
  };

  const renderLobName = (lob) => {
    if (lob.attributes["has-parent?"])
      return "- " + lob.attributes["nested-name"];
    return lob.attributes.name;
  };

  const renderLobOptions = () => {
    let lobsWithCategories = [];
    linesOfBusiness.forEach((lob) => {
      if (lob.attributes.active) {
        lobsWithCategories.push(lob);
        lob.categories.forEach((cat) => {
          if (cat.attributes.active) lobsWithCategories.push(cat);
        });
      }
    });

    return lobsWithCategories.map((lob) => (
      <option accessKey={lob.id} key={lob.id}>
        {renderLobName(lob)}
      </option>
    ));
  };

  function newResourceByName(resourceType, name) {
    return {
      data: {
        type: resourceType,
        attributes: {
          name: name,
          "street-address": address,
          street2: street2,
          city: city,
          state: state,
          "postal-code": zip,
          country: countryId,
        },
        relationships: {
          account: { data: { type: "accounts", id: account_id } },
        },
      },
    };
  }

  async function createResourceByName(resourceType, name) {
    return API.Post(
      `${apiHost}/${account_slug}/v1/${resourceType}`,
      newResourceByName(resourceType, name),
      authorizationCode
    )
      .then((response) => {
        return response.data.data.id;
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "Vendor Quote"));
        setShowFailAlert(true);
      });
  }

  async function findResourceByName(resourceType, name) {
    return API.Get(
      `${apiHost}/${account_slug}/v1/${resourceType}?filter[name]=${encodeURIComponent(
        name
      )}`,
      authorizationCode
    ).then(async (res) => {
      let data = res.data.data;
      let resourceId = null;

      // Assign resourceId to the response data with an exact match on name
      for (let i = 0; i < data.length; i++) {
        if (data[i].attributes.name === name) resourceId = data[i].id;
      }
      return resourceId;
    });
  }

  async function upsertVendorId(vendor) {
    let vendorId = await findResourceByName("vendors", vendor);
    if (vendorId !== null && addressChanged) {
      var vendorData = {
        data: {
          type: "vendors",
          id: vendorId,
          attributes: {
            name: vendor,
            "street-address": address,
            street2: street2,
            city: city,
            state: state,
            "postal-code": zip,
            country: countryId,
          },
          relationships: {
            account: { data: { type: "accounts", id: account_id } },
          },
        },
      };

      API.Patch(
        `${apiHost}/${account_slug}/v1/vendors/${vendorId}`,
        vendorData,
        authorizationCode
      ).catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "Vendor Quote"));
        setShowFailAlert(true);
      });
    }
    if (vendorId === null)
      vendorId = await createResourceByName("vendors", vendor);
    return { type: "vendors", id: vendorId };
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setHasUnsavedChanges(false);

    const attributes = {
      description: description,
      cost: cost,
      "recurring-cost": recurringCost,
      "billing-frequency":
        frequency === "None" ? "one_time" : frequency.toLowerCase(),
      markup: markup,
      "service-pricing": deriveFromServices,
      "mrr-terms": terms,
    };

    let relationships = {
      project: { data: { type: "projects", id: project_id } },
      vendor: { data: { type: "vendors", id: vendorId } },
      lob: { data: { type: "lobs", id: lobId } },
    };

    upsertVendorId(vendor).then((res) => {
      relationships.vendor = { data: res };
      if (lastWordInLocation === "edit") {
        const updatedQuote = {
          data: {
            type: "quotes",
            id: id,
            attributes: attributes,
            relationships: relationships,
          },
        };

        API.Patch(
          `${apiHost}/${account_slug}/v1/quotes/${id}`,
          updatedQuote,
          authorizationCode
        )
          .then((response) => {
            let data = response.data.data;
            let dataAttr = data.attributes;
            setCost(dataAttr.cost);
            setMarkup(dataAttr.markup);
            setRecurringCost(dataAttr["recurring-cost"]);
            setTerms(dataAttr["mrr-terms"]);
            setVendorQuoteUpdated(true);
            setSuccessMessage("Vendor Quote updated");
            setUpdatedAlertIsOpen(true);
            setTimeout(() => {
              setUpdatedAlertIsOpen(false);
            }, 3000);
          })
          .catch((err) => {
            setErrorMessages(formatUnprocessibleResponse(err, "Vendor Quote"));
            setShowFailAlert(true);
          });
      }

      if (lastWordInLocation === "new") {
        const newQuote = {
          data: {
            type: "quotes",
            attributes: attributes,
            relationships: relationships,
          },
        };

        API.Post(
          `${apiHost}/${account_slug}/v1/quotes`,
          newQuote,
          authorizationCode
        )
          .then((response) => {
            if (response.status == 201) {
              setVendorQuoteCreated(true);
              setVendorId(id);
              setSuccessMessage("Vendor Quote created");
              setUpdatedAlertIsOpen(true);
              setTimeout(() => {
                setUpdatedAlertIsOpen(false);
                redirectToEditVendorQuote(response.data.data.id);
              }, 2000);
            }
          })
          .catch((err) => {
            setErrorMessages(formatUnprocessibleResponse(err, "Vendor Quote"));
            setShowFailAlert(true);
          });
      }
    });
  };

  const quoteUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text={successMessage}
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {quoteUpdatedAlert()}
      {handleFailAlert()}
      <Card>
        <CardHeader
          title={
            lastWordInLocation === "edit" ? "Vendor Quote" : "New Vendor Quote"
          }
        />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={12}>
                <MultipleLineField
                  rows={5}
                  label="High level description of work to be done"
                  value={description}
                  onChange={(e) => {
                    setHasUnsavedChanges(true);
                    setDescription(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Row>
                  <Col>
                    <ControlledDropDown
                      label="Lines of Business"
                      value={selectedLob}
                      options={[
                        <option accessKey={null} key={null}></option>,
                        renderLobOptions(),
                      ]}
                      onChange={(e) => {
                        setHasUnsavedChanges(true);
                        setSelectedLob(e.target.value);
                        let selected = e.target.options.selectedIndex;
                        setLobId(e.target.options[selected].accessKey);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AutoCompleteText
                      required={true}
                      label="Vendor *"
                      placeholder="Acme, Inc"
                      value={vendor}
                      suggestionList={vendors.map(
                        (vendor) => vendor.attributes.name
                      )}
                      monitorValue={(value) => {
                        setHasUnsavedChanges(true);
                        chosenVendor = value;
                        setVendor(chosenVendor);
                        for (let i = 0; i < vendors.length; i++) {
                          if (vendors[i].attributes.name === value) {
                            setVendorId(vendors[i].id);
                            const attr = vendors[i].attributes;
                            setAddress(attr["street-address"]);
                            setStreet2(attr.street2);
                            setCity(attr.city);
                            setState(attr.state);
                            setZip(attr["postal-code"]);
                            setCountry(attr.country);
                          }
                        }
                      }}
                      setTextChanged={(bool) => setVendorChanged(true)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AddressForm
                      label="Street Address"
                      siteName={null}
                      addressText={address}
                      onAddressChange={(e) => {
                        setAddress(e.target.value);
                        setAddressChanged(true);
                        setHasUnsavedChanges(true);
                      }}
                      street2Text={street2}
                      onStreet2Change={(e) => {
                        setStreet2(e.target.value);
                        setAddressChanged(true);
                        setHasUnsavedChanges(true);
                      }}
                      cityText={city}
                      onCityChange={(e) => {
                        setCity(e.target.value);
                        setAddressChanged(true);
                        setHasUnsavedChanges(true);
                      }}
                      stateText={state}
                      onStateChange={(e) => {
                        setState(e.target.value);
                        setAddressChanged(true);
                        setHasUnsavedChanges(true);
                      }}
                      zipText={zip}
                      onZipChange={(e) => {
                        setZip(e.target.value);
                        setAddressChanged(true);
                        setHasUnsavedChanges(true);
                      }}
                      country={country}
                      onCountryChange={(e) => {
                        setCountry(e);
                        setCountryId(e.id);
                        setAddressChanged(true);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <Col>
                  <Form.Group className="formGroup">
                    <Form.Check
                      style={{ marginTop: "1em" }}
                      type="checkbox"
                      id="percentOfTotalHoursCheck"
                      label="Derive one-time and recurring costs from services?"
                      checked={deriveFromServices}
                      value="derive_from_services"
                      onChange={() => {
                        setHasUnsavedChanges(true);
                        setDeriveFromServices(!deriveFromServices);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={4} style={{ marginTop: "7%" }}>
                  <FormNumberField
                    min="0"
                    step="0.001"
                    label="Markup"
                    value={markup}
                    onChange={(e) => {
                      setMarkup(e.target.value);
                      setHasUnsavedChanges(true);
                    }}
                  />
                </Col>
                <Col xs={4}>
                  <FormNumberField
                    readOnly={deriveFromServices}
                    min="0"
                    step="0.01"
                    label="One Time Cost"
                    value={cost}
                    onChange={(e) => {
                      setCost(e.target.value);
                      setHasUnsavedChanges(true);
                    }}
                  />
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <FormFieldLabel label="Recurring Cost" />
                      <Form.Group className="formGroup">
                        <Form.Control
                          as="select"
                          value={frequency}
                          onChange={(e) => {
                            setHasUnsavedChanges(true);
                            setFrequency(e.target.value);
                          }}
                        >
                          <option accessKey="one_time" key="one_time">
                            None
                          </option>
                          <option accessKey="monthly" key="monthly">
                            Monthly
                          </option>
                          <option accessKey="quarterly" key="quarterly">
                            Quarterly
                          </option>
                          <option accessKey="yearly" key="yearly">
                            Yearly
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <FormNumberField
                        label="Per Term"
                        readOnly={
                          deriveFromServices === true || frequency === "None"
                            ? true
                            : false
                        }
                        min="0"
                        step="0.01"
                        value={recurringCost}
                        onChange={(e) => {
                          setHasUnsavedChanges(true);
                          setRecurringCost(e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <FormNumberField
                        label="Term"
                        readOnly={frequency === "None" ? true : false}
                        min="0"
                        step="1"
                        value={terms}
                        onChange={(e) => {
                          setHasUnsavedChanges(true);
                          setTerms(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubmitButton />
              </Col>
              <Col xs={6}>
                <BackButton url={`/projects/${project_id}/quotes`} />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default VendorQuote;
