import SlideOutMenuV2 from "@components/SlideOutMenuV2";
import DisabledTextField from "@components/Forms/DisabledTextField";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import { Product } from "../types";
import { snakeToText } from "@utils/helperFunctions";

interface ProductSlideoutProps {
  product: Product | null;
  slideoutOpen: boolean;
  setSlideoutOpen: (isOpen: boolean) => void;
}

const ProductSlideout = ({
  product,
  slideoutOpen,
  setSlideoutOpen,
}: ProductSlideoutProps) => {
  return (
    <SlideOutMenuV2
      title="Product Details"
      isOpen={slideoutOpen}
      onClose={() => setSlideoutOpen(false)}
    >
      <div>
        <FormFieldLabel label="Description" className="" />
        <div className="productDescription">
          {product?.description || "N/A"}
        </div>
        <DisabledTextField
          value={product?.mfrPartNumber || "N/A"}
          label="Manufacturer Part Number"
          placeholder={undefined}
          onChange={undefined}
          children={undefined}
        />
        <DisabledTextField
          value={product?.productId || "N/A"}
          label="Internal Product ID"
          placeholder={undefined}
          onChange={undefined}
          children={undefined}
        />
        <DisabledTextField
          value={product?.category || "N/A"}
          label="Category"
          placeholder={undefined}
          onChange={undefined}
          children={undefined}
        />
        <DisabledTextField
          value={product?.subcategory || "N/A"}
          label="Subcategory"
          placeholder={undefined}
          onChange={undefined}
          children={undefined}
        />
        <DisabledTextField
          value={snakeToText(product?.billingFrequency) || "N/A"}
          label="Billing Frequency"
          placeholder={undefined}
          onChange={undefined}
          children={undefined}
        />
      </div>
    </SlideOutMenuV2>
  );
};

export default ProductSlideout;
