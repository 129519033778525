import React from "react";
import { SpinnerInfinity } from "spinners-react";

function ScopeStackSpinner() {
  return (
    <div
      data-testid="scopestackSpinner"
      className="scopestackSpinner"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px",
      }}
    >
      <SpinnerInfinity
        color="#418172"
        secondaryColor="#1C2655"
        thickness={200}
        size={100}
      />
    </div>
  );
}

export default ScopeStackSpinner;
