import { useListLineOfBusinessesForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";

interface LOB {
  label: string;
  value: string;
}

const useListLOBsForAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListLineOfBusinessesForAccountQuery({
      slug: accountSlug,
      filter: { active: true },
    });

  let lobs: LOB[] = [];

  if (data?.data) {
    try {
      lobs = data.data.map((lob) => {
        if (!lob.id) {
          throw new Error("Missing lob.id");
        }
        if (!lob.attributes?.name) {
          throw new Error("Missing lob.attributes.name");
        }

        return {
          value: String(lob.id),
          label: lob.attributes.name || "",
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    lobs,
    lobsLoading: isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListLOBsForAccount;
