import React, { useState, useRef } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import DocumentTemplateForm from "../DocumentTemplateForm/DocumentTemplateForm";

function New({ account, permission }) {
  const navigate = useNavigate();
  const submitRef = useRef<any>();

  return (
    <div className="newDocumentTemplatesPage">
      <Card>
        <Card.Header className="headerOverride">
          <div className="headerContainer headerPadding">
            <div className="actionBtns">
              <Button
                className="btnSeawhite marginRight10"
                onClick={(e) =>
                  navigate(`/admin/document_templates`, {
                    state: null,
                  })
                }
              >
                Cancel
              </Button>
              <h2 className="headerText">Add Document Template</h2>
            </div>

            <div className="actionBtns">
              {permission !== "view" && (
                <Button
                  className="squareGreenButton"
                  onClick={() => {
                    submitRef.current?.saveDocumentTemplate();
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <DocumentTemplateForm
            account={account}
            ref={submitRef}
            permission={permission}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default New;
