import { Button, Modal } from "react-bootstrap";
import CloseButtonX from "@components/CloseButtonX/CloseButtonX";
import "./style.css";

interface Props {
  show: boolean;
  handleCancel: () => void;
  handleDontSave?: () => void;
  handleSave: () => void;
}

const UnsavedChangesModal = ({
  show,
  handleCancel,
  handleSave,
  handleDontSave,
}: Props) => {
  return (
    <Modal show={show} onHide={handleCancel} className="unsavedChangesModal">
      <Modal.Header closeButton>
        <Modal.Title className="ssModalTitle">
          You have unsaved changes
        </Modal.Title>
        <CloseButtonX onClose={handleCancel} />
      </Modal.Header>
      <Modal.Footer className="ssModalFooter">
        <Button
          variant="secondary"
          className="btnSeawhite"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        {handleDontSave && (
          <Button variant="danger" className="delete" onClick={handleDontSave}>
            Don't Save
          </Button>
        )}
        <Button
          variant="primary"
          className="seafoamBgButton"
          onClick={handleSave}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnsavedChangesModal;
