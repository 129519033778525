import { scopeStackApi as api } from "./ScopeStackAPI";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    whoAmI: build.query<WhoAmIApiResponse, WhoAmIApiArg>({
      query: () => ({ url: `/v1/me` }),
    }),
    getAccount: build.query<GetAccountApiResponse, GetAccountApiArg>({
      query: (queryArg) => ({
        url: `/v1/accounts/${queryArg.slug}`,
        params: { include: queryArg.include },
      }),
    }),
    getAccountLogo: build.query<
      GetAccountLogoApiResponse,
      GetAccountLogoApiArg
    >({
      query: (queryArg) => ({ url: `/${queryArg.slug}/v1/account-logo` }),
    }),
    updateAccountLogo: build.mutation<
      UpdateAccountLogoApiResponse,
      UpdateAccountLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/account-logo`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    listApprovalStepsForAccount: build.query<
      ListApprovalStepsForAccountApiResponse,
      ListApprovalStepsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/approval-steps`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getApprovalStep: build.query<
      GetApprovalStepApiResponse,
      GetApprovalStepApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/approval-steps/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    listAuditLogs: build.query<ListAuditLogsApiResponse, ListAuditLogsApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/audit-logs`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          sort: queryArg.sort,
        },
      }),
    }),
    listProjectAuditLogs: build.query<
      ListProjectAuditLogsApiResponse,
      ListProjectAuditLogsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/audit-logs`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          sort: queryArg.sort,
        },
      }),
    }),
    getAuditLogById: build.query<
      GetAuditLogByIdApiResponse,
      GetAuditLogByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/audit-logs/${queryArg.id}`,
      }),
    }),
    revertAuditLogById: build.mutation<
      RevertAuditLogByIdApiResponse,
      RevertAuditLogByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/audit-logs/${queryArg.id}/revert`,
        method: "PUT",
      }),
    }),
    listBlueprintsForAccount: build.query<
      ListBlueprintsForAccountApiResponse,
      ListBlueprintsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/blueprints`,
        params: {
          filter: queryArg.filter,
          sort: queryArg.sort,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createBlueprint: build.mutation<
      CreateBlueprintApiResponse,
      CreateBlueprintApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/blueprints`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    createBlueprintFromProject: build.mutation<
      CreateBlueprintFromProjectApiResponse,
      CreateBlueprintFromProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/blueprints/create_from_project`,
        method: "POST",
        params: { project_id: queryArg.projectId },
      }),
    }),
    getBlueprint: build.query<GetBlueprintApiResponse, GetBlueprintApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/blueprints/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateBlueprint: build.mutation<
      UpdateBlueprintApiResponse,
      UpdateBlueprintApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/blueprints/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteBlueprint: build.mutation<
      DeleteBlueprintApiResponse,
      DeleteBlueprintApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/blueprints/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listBlueprintItemsForAccount: build.query<
      ListBlueprintItemsForAccountApiResponse,
      ListBlueprintItemsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/blueprint-items`,
        params: { page: queryArg.page },
      }),
    }),
    createBlueprintItem: build.mutation<
      CreateBlueprintItemApiResponse,
      CreateBlueprintItemApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/blueprint-items`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getBlueprintItem: build.query<
      GetBlueprintItemApiResponse,
      GetBlueprintItemApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/blueprint-items/${queryArg.id}`,
      }),
    }),
    updateBlueprintItem: build.mutation<
      UpdateBlueprintItemApiResponse,
      UpdateBlueprintItemApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/blueprint-items/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteBlueprintItem: build.mutation<
      DeleteBlueprintItemApiResponse,
      DeleteBlueprintItemApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/blueprint-items/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listBusinessUnitsForAccount: build.query<
      ListBusinessUnitsForAccountApiResponse,
      ListBusinessUnitsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/business-units`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getBusinessUnit: build.query<
      GetBusinessUnitApiResponse,
      GetBusinessUnitApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/business-units/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    listClientsForAccount: build.query<
      ListClientsForAccountApiResponse,
      ListClientsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/clients`,
        params: {
          filter: queryArg.filter,
          sort: queryArg.sort,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createClient: build.mutation<CreateClientApiResponse, CreateClientApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/clients`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getClient: build.query<GetClientApiResponse, GetClientApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/clients/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateClient: build.mutation<UpdateClientApiResponse, UpdateClientApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/clients/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteClient: build.mutation<DeleteClientApiResponse, DeleteClientApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/clients/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listContactsForAccount: build.query<
      ListContactsForAccountApiResponse,
      ListContactsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/contacts`,
        params: { filter: queryArg.filter, page: queryArg.page },
      }),
    }),
    createContact: build.mutation<
      CreateContactApiResponse,
      CreateContactApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/contacts`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getContactById: build.query<
      GetContactByIdApiResponse,
      GetContactByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/contacts/${queryArg.id}`,
      }),
    }),
    updateContact: build.mutation<
      UpdateContactApiResponse,
      UpdateContactApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/contacts/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteContact: build.mutation<
      DeleteContactApiResponse,
      DeleteContactApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/contacts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listCrmOpportunitiesForAccount: build.query<
      ListCrmOpportunitiesForAccountApiResponse,
      ListCrmOpportunitiesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/crm-opportunities`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    listCurrencysForAccount: build.query<
      ListCurrencysForAccountApiResponse,
      ListCurrencysForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/currencies`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createCurrency: build.mutation<
      CreateCurrencyApiResponse,
      CreateCurrencyApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/currencies`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getCurrency: build.query<GetCurrencyApiResponse, GetCurrencyApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/currencies/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateCurrency: build.mutation<
      UpdateCurrencyApiResponse,
      UpdateCurrencyApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/currencies/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteCurrency: build.mutation<
      DeleteCurrencyApiResponse,
      DeleteCurrencyApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/currencies/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listCustomerSuccesses: build.query<
      ListCustomerSuccessesApiResponse,
      ListCustomerSuccessesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/customer-successes`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createCustomerSuccess: build.mutation<
      CreateCustomerSuccessApiResponse,
      CreateCustomerSuccessApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/customer-successes`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getCustomerSuccess: build.query<
      GetCustomerSuccessApiResponse,
      GetCustomerSuccessApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/customer-successes/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateCustomerSuccess: build.mutation<
      UpdateCustomerSuccessApiResponse,
      UpdateCustomerSuccessApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/customer-successes/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteCustomerSuccess: build.mutation<
      DeleteCustomerSuccessApiResponse,
      DeleteCustomerSuccessApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/customer-successes/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listDocumentTemplatesForAccount: build.query<
      ListDocumentTemplatesForAccountApiResponse,
      ListDocumentTemplatesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/document-templates`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createDocumentTemplate: build.mutation<
      CreateDocumentTemplateApiResponse,
      CreateDocumentTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/document-templates`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getDocumentTemplate: build.query<
      GetDocumentTemplateApiResponse,
      GetDocumentTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/document-templates/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateDocumentTemplate: build.mutation<
      UpdateDocumentTemplateApiResponse,
      UpdateDocumentTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/document-templates/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteDocumentTemplate: build.mutation<
      DeleteDocumentTemplateApiResponse,
      DeleteDocumentTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/document-templates/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    restoreDocumentTemplate: build.mutation<
      RestoreDocumentTemplateApiResponse,
      RestoreDocumentTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/document-templates/${queryArg.id}/restore`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getFilenameFormatOptions: build.query<
      GetFilenameFormatOptionsApiResponse,
      GetFilenameFormatOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/document-templates/filename-format-options`,
      }),
    }),
    listExpenseCategoriessForAccount: build.query<
      ListExpenseCategoriessForAccountApiResponse,
      ListExpenseCategoriessForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/expense-categories`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
          sort: queryArg.sort,
        },
      }),
    }),
    getExpenseCategoryById: build.query<
      GetExpenseCategoryByIdApiResponse,
      GetExpenseCategoryByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/expense-categories/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    listGovernancesForAccount: build.query<
      ListGovernancesForAccountApiResponse,
      ListGovernancesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/governances`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getGovernanceById: build.query<
      GetGovernanceByIdApiResponse,
      GetGovernanceByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/governances/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    changeGovernancePositionById: build.mutation<
      ChangeGovernancePositionByIdApiResponse,
      ChangeGovernancePositionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/governances/${queryArg.id}/move-to`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    listIntegratedProducts: build.query<
      ListIntegratedProductsApiResponse,
      ListIntegratedProductsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/integrated-products`,
        params: { filter: queryArg.filter, page: queryArg.page },
      }),
    }),
    listLanguageFieldsForAccount: build.query<
      ListLanguageFieldsForAccountApiResponse,
      ListLanguageFieldsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/language-fields`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getLanguageFieldById: build.query<
      GetLanguageFieldByIdApiResponse,
      GetLanguageFieldByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/language-fields/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    changeLanguageFieldPositionById: build.mutation<
      ChangeLanguageFieldPositionByIdApiResponse,
      ChangeLanguageFieldPositionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/language-fields/${queryArg.id}/move-to`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    listLineOfBusinessesForAccount: build.query<
      ListLineOfBusinessesForAccountApiResponse,
      ListLineOfBusinessesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/line-of-businesses`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createLineOfBusiness: build.mutation<
      CreateLineOfBusinessApiResponse,
      CreateLineOfBusinessApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/line-of-businesses`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getLineOfBusiness: build.query<
      GetLineOfBusinessApiResponse,
      GetLineOfBusinessApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/line-of-businesses/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateLineOfBusiness: build.mutation<
      UpdateLineOfBusinessApiResponse,
      UpdateLineOfBusinessApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/line-of-businesses/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteLineOfBusiness: build.mutation<
      DeleteLineOfBusinessApiResponse,
      DeleteLineOfBusinessApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/line-of-businesses/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listNotes: build.query<ListNotesApiResponse, ListNotesApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/notes`,
        params: { page: queryArg.page, include: queryArg.include },
      }),
    }),
    createNote: build.mutation<CreateNoteApiResponse, CreateNoteApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/notes`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getNote: build.query<GetNoteApiResponse, GetNoteApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/notes/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateNote: build.mutation<UpdateNoteApiResponse, UpdateNoteApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/notes/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteNote: build.mutation<DeleteNoteApiResponse, DeleteNoteApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/notes/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listPartnerRequestsForAccount: build.query<
      ListPartnerRequestsForAccountApiResponse,
      ListPartnerRequestsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/partner-requests`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createPartnerRequest: build.mutation<
      CreatePartnerRequestApiResponse,
      CreatePartnerRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/partner-requests`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getPartnerRequest: build.query<
      GetPartnerRequestApiResponse,
      GetPartnerRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/partner-requests/${queryArg.id}`,
      }),
    }),
    updatePartnerRequest: build.mutation<
      UpdatePartnerRequestApiResponse,
      UpdatePartnerRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/partner-requests/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deletePartnerRequest: build.mutation<
      DeletePartnerRequestApiResponse,
      DeletePartnerRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/partner-requests/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    completePartnerRequest: build.mutation<
      CompletePartnerRequestApiResponse,
      CompletePartnerRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/partner-requests/${queryArg.id}/complete`,
        method: "PUT",
      }),
    }),
    revisePartnerRequest: build.mutation<
      RevisePartnerRequestApiResponse,
      RevisePartnerRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/partner-requests/${queryArg.id}/revise`,
        method: "PUT",
      }),
    }),
    declinePartnerRequest: build.mutation<
      DeclinePartnerRequestApiResponse,
      DeclinePartnerRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/partner-requests/${queryArg.id}/decline`,
        method: "PUT",
      }),
    }),
    countPartnerRequests: build.query<
      CountPartnerRequestsApiResponse,
      CountPartnerRequestsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/partner-requests/count`,
      }),
    }),
    listExternalRequestsForAccount: build.query<
      ListExternalRequestsForAccountApiResponse,
      ListExternalRequestsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/external-requests`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getExternalRequest: build.query<
      GetExternalRequestApiResponse,
      GetExternalRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/external-requests/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    acceptExternalRequest: build.mutation<
      AcceptExternalRequestApiResponse,
      AcceptExternalRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/external-requests/${queryArg.id}/accept`,
        method: "PUT",
      }),
    }),
    offerExternalRequest: build.mutation<
      OfferExternalRequestApiResponse,
      OfferExternalRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/external-requests/${queryArg.id}/offer`,
        method: "PUT",
      }),
    }),
    declineExternalRequest: build.mutation<
      DeclineExternalRequestApiResponse,
      DeclineExternalRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/external-requests/${queryArg.id}/decline`,
        method: "PUT",
      }),
    }),
    listPartnerRequestExternalServices: build.query<
      ListPartnerRequestExternalServicesApiResponse,
      ListPartnerRequestExternalServicesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/partner-requests/${queryArg.id}/external-services`,
        params: { page: queryArg.page, include: queryArg.include },
      }),
    }),
    listPaymentCreditsForAccount: build.query<
      ListPaymentCreditsForAccountApiResponse,
      ListPaymentCreditsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/payment-credits`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createPaymentCredit: build.mutation<
      CreatePaymentCreditApiResponse,
      CreatePaymentCreditApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/payment-credits`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getPaymentCredit: build.query<
      GetPaymentCreditApiResponse,
      GetPaymentCreditApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/payment-credits/${queryArg.id}`,
      }),
    }),
    updatePaymentCredit: build.mutation<
      UpdatePaymentCreditApiResponse,
      UpdatePaymentCreditApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/payment-credits/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deletePaymentCredit: build.mutation<
      DeletePaymentCreditApiResponse,
      DeletePaymentCreditApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/payment-credits/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listPaymentTermsForAccount: build.query<
      ListPaymentTermsForAccountApiResponse,
      ListPaymentTermsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/payment-terms`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getPaymentTerm: build.query<
      GetPaymentTermApiResponse,
      GetPaymentTermApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/payment-terms/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    listPerformanceIndicatorsForAccount: build.query<
      ListPerformanceIndicatorsForAccountApiResponse,
      ListPerformanceIndicatorsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/performance-indicators`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createPerformanceIndicator: build.mutation<
      CreatePerformanceIndicatorApiResponse,
      CreatePerformanceIndicatorApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/performance-indicators`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getPerformanceIndicator: build.query<
      GetPerformanceIndicatorApiResponse,
      GetPerformanceIndicatorApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/performance-indicators/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updatePerformanceIndicator: build.mutation<
      UpdatePerformanceIndicatorApiResponse,
      UpdatePerformanceIndicatorApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/performance-indicators/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deletePerformanceIndicator: build.mutation<
      DeletePerformanceIndicatorApiResponse,
      DeletePerformanceIndicatorApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/performance-indicators/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listPhasesForAccount: build.query<
      ListPhasesForAccountApiResponse,
      ListPhasesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/phases`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getPhaseById: build.query<GetPhaseByIdApiResponse, GetPhaseByIdApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/phases/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    listPresalesEngineersForAccount: build.query<
      ListPresalesEngineersForAccountApiResponse,
      ListPresalesEngineersForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/presales-engineers`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getPresalesEngineerById: build.query<
      GetPresalesEngineerByIdApiResponse,
      GetPresalesEngineerByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/presales-engineers/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    listPricingAdjustmentsForAccount: build.query<
      ListPricingAdjustmentsForAccountApiResponse,
      ListPricingAdjustmentsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/pricing-adjustments`,
        params: { page: queryArg.page, include: queryArg.include },
      }),
    }),
    createPricingAdjustment: build.mutation<
      CreatePricingAdjustmentApiResponse,
      CreatePricingAdjustmentApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/pricing-adjustments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getPricingAdjustmentById: build.query<
      GetPricingAdjustmentByIdApiResponse,
      GetPricingAdjustmentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/pricing-adjustments/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updatePricingAdjustmentById: build.mutation<
      UpdatePricingAdjustmentByIdApiResponse,
      UpdatePricingAdjustmentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/pricing-adjustments/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deletePricingAdjustmentById: build.mutation<
      DeletePricingAdjustmentByIdApiResponse,
      DeletePricingAdjustmentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/pricing-adjustments/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listProductsForAccount: build.query<
      ListProductsForAccountApiResponse,
      ListProductsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/products`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProduct: build.mutation<
      CreateProductApiResponse,
      CreateProductApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/products`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProduct: build.query<GetProductApiResponse, GetProductApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/products/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProduct: build.mutation<
      UpdateProductApiResponse,
      UpdateProductApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/products/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProduct: build.mutation<
      DeleteProductApiResponse,
      DeleteProductApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/products/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listProjectsForAccount: build.query<
      ListProjectsForAccountApiResponse,
      ListProjectsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects`,
        params: {
          filter: queryArg.filter,
          sort: queryArg.sort,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProject: build.mutation<
      CreateProjectApiResponse,
      CreateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectById: build.query<
      GetProjectByIdApiResponse,
      GetProjectByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProject: build.mutation<
      UpdateProjectApiResponse,
      UpdateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProject: build.mutation<
      DeleteProjectApiResponse,
      DeleteProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    requestApprovalForProjectById: build.mutation<
      RequestApprovalForProjectByIdApiResponse,
      RequestApprovalForProjectByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/:id/request_approval`,
        method: "PUT",
      }),
    }),
    cancelApprovalRequestForProjectById: build.mutation<
      CancelApprovalRequestForProjectByIdApiResponse,
      CancelApprovalRequestForProjectByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/:id/cancel_approval`,
        method: "PUT",
      }),
    }),
    restartScopingForProjectById: build.mutation<
      RestartScopingForProjectByIdApiResponse,
      RestartScopingForProjectByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/:id/rescope`,
        method: "PUT",
      }),
    }),
    updateProjectToWonById: build.mutation<
      UpdateProjectToWonByIdApiResponse,
      UpdateProjectToWonByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/:id/deal_won`,
        method: "PUT",
      }),
    }),
    updateProjectToLostById: build.mutation<
      UpdateProjectToLostByIdApiResponse,
      UpdateProjectToLostByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/:id/deal_lost`,
        method: "PUT",
      }),
    }),
    syncProjectRevenueToCrm: build.mutation<
      SyncProjectRevenueToCrmApiResponse,
      SyncProjectRevenueToCrmApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/:id/sync_crm`,
        method: "POST",
      }),
    }),
    duplicateProjectById: build.mutation<
      DuplicateProjectByIdApiResponse,
      DuplicateProjectByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/:id/duplicate`,
        method: "POST",
      }),
    }),
    exportProjects: build.mutation<
      ExportProjectsApiResponse,
      ExportProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/export`,
        method: "POST",
      }),
    }),
    listProjectApprovalsForAccount: build.query<
      ListProjectApprovalsForAccountApiResponse,
      ListProjectApprovalsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-approvals`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectApproval: build.mutation<
      CreateProjectApprovalApiResponse,
      CreateProjectApprovalApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-approvals`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectApproval: build.query<
      GetProjectApprovalApiResponse,
      GetProjectApprovalApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-approvals/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProjectApproval: build.mutation<
      UpdateProjectApprovalApiResponse,
      UpdateProjectApprovalApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-approvals/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectApproval: build.mutation<
      DeleteProjectApprovalApiResponse,
      DeleteProjectApprovalApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-approvals/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    approveProjectApprovalById: build.mutation<
      ApproveProjectApprovalByIdApiResponse,
      ApproveProjectApprovalByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-approvals/${queryArg.id}/approve`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    cancelProjectApprovalById: build.mutation<
      CancelProjectApprovalByIdApiResponse,
      CancelProjectApprovalByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-approvals/${queryArg.id}/cancel`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    declineProjectApprovalById: build.mutation<
      DeclineProjectApprovalByIdApiResponse,
      DeclineProjectApprovalByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-approvals/${queryArg.id}/decline`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    rescopeProjectApprovalById: build.mutation<
      RescopeProjectApprovalByIdApiResponse,
      RescopeProjectApprovalByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-approvals/${queryArg.id}/rescope`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    listProjectAttachmentsForAccount: build.query<
      ListProjectAttachmentsForAccountApiResponse,
      ListProjectAttachmentsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-attachments`,
        params: { page: queryArg.page, include: queryArg.include },
      }),
    }),
    getProjectAttachmentById: build.query<
      GetProjectAttachmentByIdApiResponse,
      GetProjectAttachmentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-attachments/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    countProjectAttachments: build.query<
      CountProjectAttachmentsApiResponse,
      CountProjectAttachmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-attachments/count`,
      }),
    }),
    listAttachmentTypesForAccount: build.query<
      ListAttachmentTypesForAccountApiResponse,
      ListAttachmentTypesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/attachment-types`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getAttachmentType: build.query<
      GetAttachmentTypeApiResponse,
      GetAttachmentTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/attachment-types/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    applyBlueprintToProject: build.mutation<
      ApplyBlueprintToProjectApiResponse,
      ApplyBlueprintToProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-blueprints`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    listProjectCollaborators: build.query<
      ListProjectCollaboratorsApiResponse,
      ListProjectCollaboratorsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-collaborators`,
        params: { page: queryArg.page, include: queryArg.include },
      }),
    }),
    createProjectCollaborator: build.mutation<
      CreateProjectCollaboratorApiResponse,
      CreateProjectCollaboratorApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-collaborators`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectCollaboratorById: build.query<
      GetProjectCollaboratorByIdApiResponse,
      GetProjectCollaboratorByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-collaborators/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProjectCollaborator: build.mutation<
      UpdateProjectCollaboratorApiResponse,
      UpdateProjectCollaboratorApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-collaborators/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectCollaborator: build.mutation<
      DeleteProjectCollaboratorApiResponse,
      DeleteProjectCollaboratorApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-collaborators/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    countProjectCollaborators: build.query<
      CountProjectCollaboratorsApiResponse,
      CountProjectCollaboratorsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-collaborators/count`,
      }),
    }),
    listProjectConditionsForAccount: build.query<
      ListProjectConditionsForAccountApiResponse,
      ListProjectConditionsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-conditions`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getProjectCondition: build.query<
      GetProjectConditionApiResponse,
      GetProjectConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-conditions/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProjectCondition: build.mutation<
      UpdateProjectConditionApiResponse,
      UpdateProjectConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-conditions/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectCondition: build.mutation<
      DeleteProjectConditionApiResponse,
      DeleteProjectConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-conditions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    skipProjectCondition: build.mutation<
      SkipProjectConditionApiResponse,
      SkipProjectConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-conditions/${queryArg.id}/skip`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    restoreProjectCondition: build.mutation<
      RestoreProjectConditionApiResponse,
      RestoreProjectConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-conditions/${queryArg.id}/restore`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    countProjectConditions: build.query<
      CountProjectConditionsApiResponse,
      CountProjectConditionsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-conditions/count`,
      }),
    }),
    listProjectContacts: build.query<
      ListProjectContactsApiResponse,
      ListProjectContactsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-contacts`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectContact: build.mutation<
      CreateProjectContactApiResponse,
      CreateProjectContactApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-contacts`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectContact: build.query<
      GetProjectContactApiResponse,
      GetProjectContactApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-contacts/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProjectContact: build.mutation<
      UpdateProjectContactApiResponse,
      UpdateProjectContactApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-contacts/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectContact: build.mutation<
      DeleteProjectContactApiResponse,
      DeleteProjectContactApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-contacts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    countProjectContacts: build.query<
      CountProjectContactsApiResponse,
      CountProjectContactsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-contacts/count`,
      }),
    }),
    listProjectCredits: build.query<
      ListProjectCreditsApiResponse,
      ListProjectCreditsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-credits`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectCredit: build.mutation<
      CreateProjectCreditApiResponse,
      CreateProjectCreditApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-credits`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectCreditById: build.query<
      GetProjectCreditByIdApiResponse,
      GetProjectCreditByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-credits/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProjectCredit: build.mutation<
      UpdateProjectCreditApiResponse,
      UpdateProjectCreditApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-credits/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectCredit: build.mutation<
      DeleteProjectCreditApiResponse,
      DeleteProjectCreditApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-credits/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listProjectDocumentsForAccount: build.query<
      ListProjectDocumentsForAccountApiResponse,
      ListProjectDocumentsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-documents`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectDocument: build.mutation<
      CreateProjectDocumentApiResponse,
      CreateProjectDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-documents`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectDocument: build.query<
      GetProjectDocumentApiResponse,
      GetProjectDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-documents/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    listDocumentDeliveriesForAccount: build.query<
      ListDocumentDeliveriesForAccountApiResponse,
      ListDocumentDeliveriesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/document-deliveries`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getDocumentDelivery: build.query<
      GetDocumentDeliveryApiResponse,
      GetDocumentDeliveryApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/document-deliveries/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    listProjectDownloadTypesForProject: build.query<
      ListProjectDownloadTypesForProjectApiResponse,
      ListProjectDownloadTypesForProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-download-types`,
        params: { filter: queryArg.filter },
      }),
    }),
    listProjectExpenses: build.query<
      ListProjectExpensesApiResponse,
      ListProjectExpensesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-expenses`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
          sort: queryArg.sort,
        },
      }),
    }),
    createProjectExpense: build.mutation<
      CreateProjectExpenseApiResponse,
      CreateProjectExpenseApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-expenses`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectExpenseById: build.query<
      GetProjectExpenseByIdApiResponse,
      GetProjectExpenseByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-expenses/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProjectExpense: build.mutation<
      UpdateProjectExpenseApiResponse,
      UpdateProjectExpenseApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-expenses/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectExpense: build.mutation<
      DeleteProjectExpenseApiResponse,
      DeleteProjectExpenseApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-expenses/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    countProjectExpenses: build.query<
      CountProjectExpensesApiResponse,
      CountProjectExpensesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-expenses/count`,
      }),
    }),
    listProjectGovernances: build.query<
      ListProjectGovernancesApiResponse,
      ListProjectGovernancesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-governances`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectGovernance: build.mutation<
      CreateProjectGovernanceApiResponse,
      CreateProjectGovernanceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-governances`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectGovernanceById: build.query<
      GetProjectGovernanceByIdApiResponse,
      GetProjectGovernanceByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-governances/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProjectGovernance: build.mutation<
      UpdateProjectGovernanceApiResponse,
      UpdateProjectGovernanceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-governances/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectGovernance: build.mutation<
      DeleteProjectGovernanceApiResponse,
      DeleteProjectGovernanceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-governances/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    changeProjectGovernancePositionById: build.mutation<
      ChangeProjectGovernancePositionByIdApiResponse,
      ChangeProjectGovernancePositionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-governances/${queryArg.id}/move-to`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    countProjectGovernances: build.query<
      CountProjectGovernancesApiResponse,
      CountProjectGovernancesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-governances/count`,
      }),
    }),
    listProjectLocations: build.query<
      ListProjectLocationsApiResponse,
      ListProjectLocationsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-locations`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectLocation: build.mutation<
      CreateProjectLocationApiResponse,
      CreateProjectLocationApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-locations`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectLocationById: build.query<
      GetProjectLocationByIdApiResponse,
      GetProjectLocationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-locations/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProjectLocation: build.mutation<
      UpdateProjectLocationApiResponse,
      UpdateProjectLocationApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-locations/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectLocation: build.mutation<
      DeleteProjectLocationApiResponse,
      DeleteProjectLocationApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-locations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    countProjectLocations: build.query<
      CountProjectLocationsApiResponse,
      CountProjectLocationsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-locations/count`,
      }),
    }),
    listProjectPhasesForAccount: build.query<
      ListProjectPhasesForAccountApiResponse,
      ListProjectPhasesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-phases`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectPhase: build.mutation<
      CreateProjectPhaseApiResponse,
      CreateProjectPhaseApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-phases`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectPhaseById: build.query<
      GetProjectPhaseByIdApiResponse,
      GetProjectPhaseByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-phases/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProjectPhase: build.mutation<
      UpdateProjectPhaseApiResponse,
      UpdateProjectPhaseApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-phases/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectPhase: build.mutation<
      DeleteProjectPhaseApiResponse,
      DeleteProjectPhaseApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-phases/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listProjectProductsForAccount: build.query<
      ListProjectProductsForAccountApiResponse,
      ListProjectProductsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-products`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectProduct: build.mutation<
      CreateProjectProductApiResponse,
      CreateProjectProductApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-products`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectProduct: build.query<
      GetProjectProductApiResponse,
      GetProjectProductApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-products/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProjectProduct: build.mutation<
      UpdateProjectProductApiResponse,
      UpdateProjectProductApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-products/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectProduct: build.mutation<
      DeleteProjectProductApiResponse,
      DeleteProjectProductApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-products/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    countProjectProducts: build.query<
      CountProjectProductsApiResponse,
      CountProjectProductsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-products/count`,
      }),
    }),
    listProjectPricings: build.query<
      ListProjectPricingsApiResponse,
      ListProjectPricingsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.id}/project-pricings`,
      }),
    }),
    listProjectResourcesForAccount: build.query<
      ListProjectResourcesForAccountApiResponse,
      ListProjectResourcesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-resources`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectResource: build.mutation<
      CreateProjectResourceApiResponse,
      CreateProjectResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-resources`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectResource: build.query<
      GetProjectResourceApiResponse,
      GetProjectResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-resources/${queryArg.id}`,
      }),
    }),
    updateProjectResource: build.mutation<
      UpdateProjectResourceApiResponse,
      UpdateProjectResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-resources/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    countProjectResources: build.query<
      CountProjectResourcesApiResponse,
      CountProjectResourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-resources/count`,
      }),
    }),
    listProjectServices: build.query<
      ListProjectServicesApiResponse,
      ListProjectServicesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-services`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectService: build.mutation<
      CreateProjectServiceApiResponse,
      CreateProjectServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-services`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectServiceById: build.query<
      GetProjectServiceByIdApiResponse,
      GetProjectServiceByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-services/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateProjectService: build.mutation<
      UpdateProjectServiceApiResponse,
      UpdateProjectServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-services/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectService: build.mutation<
      DeleteProjectServiceApiResponse,
      DeleteProjectServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-services/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    syncrhonizeStandardProjectServiceById: build.mutation<
      SyncrhonizeStandardProjectServiceByIdApiResponse,
      SyncrhonizeStandardProjectServiceByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-services/${queryArg.id}/synchronize-standard`,
        method: "POST",
      }),
    }),
    countProjectServices: build.query<
      CountProjectServicesApiResponse,
      CountProjectServicesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-services/count`,
      }),
    }),
    listProjectSubservices: build.query<
      ListProjectSubservicesApiResponse,
      ListProjectSubservicesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-subservices`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectSubservice: build.mutation<
      CreateProjectSubserviceApiResponse,
      CreateProjectSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-subservices`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectSubserviceById: build.query<
      GetProjectSubserviceByIdApiResponse,
      GetProjectSubserviceByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-subservices/${queryArg.id}`,
      }),
    }),
    updateProjectSubservice: build.mutation<
      UpdateProjectSubserviceApiResponse,
      UpdateProjectSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-subservices/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteProjectSubservice: build.mutation<
      DeleteProjectSubserviceApiResponse,
      DeleteProjectSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-subservices/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    syncrhonizeStandardProjectSubserviceById: build.mutation<
      SyncrhonizeStandardProjectSubserviceByIdApiResponse,
      SyncrhonizeStandardProjectSubserviceByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-subservices/${queryArg.id}/synchronize-standard`,
        method: "POST",
      }),
    }),
    listProjectVariables: build.query<
      ListProjectVariablesApiResponse,
      ListProjectVariablesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-variables`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getProjectVariableById: build.query<
      GetProjectVariableByIdApiResponse,
      GetProjectVariableByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-variables/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    listProjectVersions: build.query<
      ListProjectVersionsApiResponse,
      ListProjectVersionsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-versions`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createProjectVersion: build.mutation<
      CreateProjectVersionApiResponse,
      CreateProjectVersionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-versions`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProjectVersionById: build.query<
      GetProjectVersionByIdApiResponse,
      GetProjectVersionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-versions/${queryArg.id}`,
      }),
    }),
    updateProjectVersion: build.mutation<
      UpdateProjectVersionApiResponse,
      UpdateProjectVersionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-versions/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    revertProjectVersion: build.mutation<
      RevertProjectVersionApiResponse,
      RevertProjectVersionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/project-versions/${queryArg.id}/revert`,
        method: "PUT",
      }),
    }),
    countProjectVersions: build.query<
      CountProjectVersionsApiResponse,
      CountProjectVersionsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/project-versions/count`,
      }),
    }),
    listQuestionsForAccount: build.query<
      ListQuestionsForAccountApiResponse,
      ListQuestionsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/questions`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getQuestionById: build.query<
      GetQuestionByIdApiResponse,
      GetQuestionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/questions/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    listQuestionnairesForAccount: build.query<
      ListQuestionnairesForAccountApiResponse,
      ListQuestionnairesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/questionnaires`,
        params: {
          filter: queryArg.filter,
          sort: queryArg.sort,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createQuestionnaire: build.mutation<
      CreateQuestionnaireApiResponse,
      CreateQuestionnaireApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/questionnaires`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getQuestionnaireById: build.query<
      GetQuestionnaireByIdApiResponse,
      GetQuestionnaireByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/questionnaires/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateQuestionnaire: build.mutation<
      UpdateQuestionnaireApiResponse,
      UpdateQuestionnaireApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/questionnaires/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteQuestionnaire: build.mutation<
      DeleteQuestionnaireApiResponse,
      DeleteQuestionnaireApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/questionnaires/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listQuotesForAccount: build.query<
      ListQuotesForAccountApiResponse,
      ListQuotesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/quotes`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createQuote: build.mutation<CreateQuoteApiResponse, CreateQuoteApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/quotes`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getQuote: build.query<GetQuoteApiResponse, GetQuoteApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/quotes/${queryArg.id}`,
      }),
    }),
    updateQuote: build.mutation<UpdateQuoteApiResponse, UpdateQuoteApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/quotes/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteQuote: build.mutation<DeleteQuoteApiResponse, DeleteQuoteApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/quotes/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    recalculateQuote: build.mutation<
      RecalculateQuoteApiResponse,
      RecalculateQuoteApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/quotes/${queryArg.id}/recalculate`,
        method: "POST",
      }),
    }),
    acceptVendorDocumentForQuote: build.mutation<
      AcceptVendorDocumentForQuoteApiResponse,
      AcceptVendorDocumentForQuoteApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/quotes/${queryArg.id}/accept_document`,
        method: "PUT",
      }),
    }),
    countQuotes: build.query<CountQuotesApiResponse, CountQuotesApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/projects/${queryArg.projectId}/quotes/count`,
      }),
    }),
    listRateTablesForAccount: build.query<
      ListRateTablesForAccountApiResponse,
      ListRateTablesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/rate-tables`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createRateTable: build.mutation<
      CreateRateTableApiResponse,
      CreateRateTableApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/rate-tables`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getRateTable: build.query<GetRateTableApiResponse, GetRateTableApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/rate-tables/${queryArg.id}`,
      }),
    }),
    updateRateTable: build.mutation<
      UpdateRateTableApiResponse,
      UpdateRateTableApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/rate-tables/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteRateTable: build.mutation<
      DeleteRateTableApiResponse,
      DeleteRateTableApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/rate-tables/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    duplicateRateTable: build.mutation<
      DuplicateRateTableApiResponse,
      DuplicateRateTableApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/rate-tables/${queryArg.id}/duplicate`,
        method: "POST",
      }),
    }),
    listResourcesForAccount: build.query<
      ListResourcesForAccountApiResponse,
      ListResourcesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resources`,
        params: { filter: queryArg.filter, include: queryArg.include },
      }),
    }),
    createResource: build.mutation<
      CreateResourceApiResponse,
      CreateResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resources`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getResourceById: build.query<
      GetResourceByIdApiResponse,
      GetResourceByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resources/${queryArg.id}`,
      }),
    }),
    updateResource: build.mutation<
      UpdateResourceApiResponse,
      UpdateResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resources/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteResource: build.mutation<
      DeleteResourceApiResponse,
      DeleteResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resources/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listResourcePlans: build.query<
      ListResourcePlansApiResponse,
      ListResourcePlansApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-plans`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createResourcePlan: build.mutation<
      CreateResourcePlanApiResponse,
      CreateResourcePlanApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-plans`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getResourcePlanById: build.query<
      GetResourcePlanByIdApiResponse,
      GetResourcePlanByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-plans/${queryArg.id}`,
      }),
    }),
    updateResourcePlan: build.mutation<
      UpdateResourcePlanApiResponse,
      UpdateResourcePlanApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-plans/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteResourcePlan: build.mutation<
      DeleteResourcePlanApiResponse,
      DeleteResourcePlanApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-plans/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    duplicateResourcePlan: build.mutation<
      DuplicateResourcePlanApiResponse,
      DuplicateResourcePlanApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-plans/${queryArg.id}/duplicate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    listResourceAllocations: build.query<
      ListResourceAllocationsApiResponse,
      ListResourceAllocationsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-allocations`,
        params: { page: queryArg.page, include: queryArg.include },
      }),
    }),
    createResourceAllocation: build.mutation<
      CreateResourceAllocationApiResponse,
      CreateResourceAllocationApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-allocations`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getResourceAllocationById: build.query<
      GetResourceAllocationByIdApiResponse,
      GetResourceAllocationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-allocations/${queryArg.id}`,
      }),
    }),
    updateResourceAllocation: build.mutation<
      UpdateResourceAllocationApiResponse,
      UpdateResourceAllocationApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-allocations/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteResourceAllocation: build.mutation<
      DeleteResourceAllocationApiResponse,
      DeleteResourceAllocationApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-allocations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listResourceRatesForAccount: build.query<
      ListResourceRatesForAccountApiResponse,
      ListResourceRatesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-rates`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createResourceRate: build.mutation<
      CreateResourceRateApiResponse,
      CreateResourceRateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-rates`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getResourceRateById: build.query<
      GetResourceRateByIdApiResponse,
      GetResourceRateByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-rates/${queryArg.id}`,
      }),
    }),
    updateResourceRate: build.mutation<
      UpdateResourceRateApiResponse,
      UpdateResourceRateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-rates/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteResourceRate: build.mutation<
      DeleteResourceRateApiResponse,
      DeleteResourceRateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/resource-rates/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listSalesExecutivesForAccount: build.query<
      ListSalesExecutivesForAccountApiResponse,
      ListSalesExecutivesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/sales-executives`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createSalesExecutive: build.mutation<
      CreateSalesExecutiveApiResponse,
      CreateSalesExecutiveApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/sales-executives`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getSalesExecutiveById: build.query<
      GetSalesExecutiveByIdApiResponse,
      GetSalesExecutiveByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/sales-executives/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateSalesExecutive: build.mutation<
      UpdateSalesExecutiveApiResponse,
      UpdateSalesExecutiveApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/sales-executives/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteSalesExecutive: build.mutation<
      DeleteSalesExecutiveApiResponse,
      DeleteSalesExecutiveApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/sales-executives/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listServicesForAccount: build.query<
      ListServicesForAccountApiResponse,
      ListServicesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services`,
        params: {
          filter: queryArg.filter,
          sort: queryArg.sort,
          include: queryArg.include,
          page: queryArg.page,
        },
      }),
    }),
    createService: build.mutation<
      CreateServiceApiResponse,
      CreateServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getServiceById: build.query<
      GetServiceByIdApiResponse,
      GetServiceByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateService: build.mutation<
      UpdateServiceApiResponse,
      UpdateServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteService: build.mutation<
      DeleteServiceApiResponse,
      DeleteServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    activateService: build.mutation<
      ActivateServiceApiResponse,
      ActivateServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.id}/activate`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    restoreService: build.mutation<
      RestoreServiceApiResponse,
      RestoreServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.id}/restore`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    changeServicePositionById: build.mutation<
      ChangeServicePositionByIdApiResponse,
      ChangeServicePositionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.id}/move-to`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    duplicateService: build.mutation<
      DuplicateServiceApiResponse,
      DuplicateServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.id}/duplicate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    downloadService: build.mutation<
      DownloadServiceApiResponse,
      DownloadServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.id}/download`,
        method: "POST",
      }),
    }),
    downloadListOfServices: build.mutation<
      DownloadListOfServicesApiResponse,
      DownloadListOfServicesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/download`,
        method: "POST",
        params: { filter: queryArg.filter },
      }),
    }),
    listSubservicesForAccount: build.query<
      ListSubservicesForAccountApiResponse,
      ListSubservicesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createSubservice: build.mutation<
      CreateSubserviceApiResponse,
      CreateSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getSubserviceById: build.query<
      GetSubserviceByIdApiResponse,
      GetSubserviceByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateSubservice: build.mutation<
      UpdateSubserviceApiResponse,
      UpdateSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteSubservice: build.mutation<
      DeleteSubserviceApiResponse,
      DeleteSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    activateSubservice: build.mutation<
      ActivateSubserviceApiResponse,
      ActivateSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.id}/activate`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    changeSubservicePositionById: build.mutation<
      ChangeSubservicePositionByIdApiResponse,
      ChangeSubservicePositionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.id}/move-to`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    listServiceCategorysForAccount: build.query<
      ListServiceCategorysForAccountApiResponse,
      ListServiceCategorysForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/service-categories`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
          sort: queryArg.sort,
        },
      }),
    }),
    getServiceCategory: build.query<
      GetServiceCategoryApiResponse,
      GetServiceCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/service-categories/${queryArg.id}`,
      }),
    }),
    listServiceConditionsForAccount: build.query<
      ListServiceConditionsForAccountApiResponse,
      ListServiceConditionsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/service-conditions`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createServiceCondition: build.mutation<
      CreateServiceConditionApiResponse,
      CreateServiceConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/service-conditions`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getServiceCondition: build.query<
      GetServiceConditionApiResponse,
      GetServiceConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/service-conditions/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateServiceCondition: build.mutation<
      UpdateServiceConditionApiResponse,
      UpdateServiceConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/service-conditions/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteServiceCondition: build.mutation<
      DeleteServiceConditionApiResponse,
      DeleteServiceConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/service-conditions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listSubcontractorsForAccount: build.query<
      ListSubcontractorsForAccountApiResponse,
      ListSubcontractorsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subcontractors`,
        params: { page: queryArg.page },
      }),
    }),
    getSubcontractor: build.query<
      GetSubcontractorApiResponse,
      GetSubcontractorApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subcontractors/${queryArg.id}`,
      }),
    }),
    listSurveysForAccount: build.query<
      ListSurveysForAccountApiResponse,
      ListSurveysForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/surveys`,
        params: {
          filter: queryArg.filter,
          sort: queryArg.sort,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createSurvey: build.mutation<CreateSurveyApiResponse, CreateSurveyApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/surveys`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getSurveyById: build.query<GetSurveyByIdApiResponse, GetSurveyByIdApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/surveys/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateSurvey: build.mutation<UpdateSurveyApiResponse, UpdateSurveyApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/surveys/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteSurvey: build.mutation<DeleteSurveyApiResponse, DeleteSurveyApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/surveys/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    calculateSurveyRecommendations: build.mutation<
      CalculateSurveyRecommendationsApiResponse,
      CalculateSurveyRecommendationsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/surveys/${queryArg.id}/calculate`,
        method: "PUT",
      }),
    }),
    applySurveyRecommendations: build.mutation<
      ApplySurveyRecommendationsApiResponse,
      ApplySurveyRecommendationsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/surveys/${queryArg.id}/apply`,
        method: "PUT",
      }),
    }),
    shareSurvey: build.mutation<ShareSurveyApiResponse, ShareSurveyApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/surveys/${queryArg.id}/share`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    listSurveyResponsesForAccount: build.query<
      ListSurveyResponsesForAccountApiResponse,
      ListSurveyResponsesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/survey-responses`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createSurveyResponse: build.mutation<
      CreateSurveyResponseApiResponse,
      CreateSurveyResponseApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/survey-responses`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getSurveyResponseById: build.query<
      GetSurveyResponseByIdApiResponse,
      GetSurveyResponseByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/survey-responses/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateSurveyResponse: build.mutation<
      UpdateSurveyResponseApiResponse,
      UpdateSurveyResponseApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/survey-responses/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteSurveyResponse: build.mutation<
      DeleteSurveyResponseApiResponse,
      DeleteSurveyResponseApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/survey-responses/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listSurveyRecommendationsForAccount: build.query<
      ListSurveyRecommendationsForAccountApiResponse,
      ListSurveyRecommendationsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/survey-recommendations`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    getSurveyRecommendationById: build.query<
      GetSurveyRecommendationByIdApiResponse,
      GetSurveyRecommendationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/survey-recommendations/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateSurveyRecommendation: build.mutation<
      UpdateSurveyRecommendationApiResponse,
      UpdateSurveyRecommendationApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/survey-recommendations/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteSurveyRecommendation: build.mutation<
      DeleteSurveyRecommendationApiResponse,
      DeleteSurveyRecommendationApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/survey-recommendations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    applySurveyRecommendation: build.mutation<
      ApplySurveyRecommendationApiResponse,
      ApplySurveyRecommendationApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/survey-recommendations/${queryArg.id}/apply`,
        method: "PUT",
      }),
    }),
    listTagsForAccount: build.query<
      ListTagsForAccountApiResponse,
      ListTagsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/tags`,
        params: { page: queryArg.page, filter: queryArg.filter },
      }),
    }),
    getTag: build.query<GetTagApiResponse, GetTagApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/tags/${queryArg.id}`,
      }),
    }),
    getServiceLevelsOfEffort: build.query<
      GetServiceLevelsOfEffortApiResponse,
      GetServiceLevelsOfEffortApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.serviceId}/level-of-efforts`,
        params: { page: queryArg.page },
      }),
    }),
    createLevelOfEffortForService: build.mutation<
      CreateLevelOfEffortForServiceApiResponse,
      CreateLevelOfEffortForServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.serviceId}/level-of-efforts`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getServiceLevelOfEffort: build.query<
      GetServiceLevelOfEffortApiResponse,
      GetServiceLevelOfEffortApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.serviceId}/level-of-efforts/${queryArg.id}`,
        params: { page: queryArg.page },
      }),
    }),
    updateLevelOfEffortForService: build.mutation<
      UpdateLevelOfEffortForServiceApiResponse,
      UpdateLevelOfEffortForServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.serviceId}/level-of-efforts/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteLevelOfEffortFromService: build.mutation<
      DeleteLevelOfEffortFromServiceApiResponse,
      DeleteLevelOfEffortFromServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.serviceId}/level-of-efforts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getSubserviceLevelsOfEffort: build.query<
      GetSubserviceLevelsOfEffortApiResponse,
      GetSubserviceLevelsOfEffortApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.subserviceId}/level-of-efforts`,
        params: { page: queryArg.page },
      }),
    }),
    createLevelOfEffortForSubservice: build.mutation<
      CreateLevelOfEffortForSubserviceApiResponse,
      CreateLevelOfEffortForSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.subserviceId}/level-of-efforts`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getSubserviceLevelOfEffort: build.query<
      GetSubserviceLevelOfEffortApiResponse,
      GetSubserviceLevelOfEffortApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.subserviceId}/level-of-efforts/${queryArg.id}`,
        params: { page: queryArg.page },
      }),
    }),
    updateLevelOfEffortForSubservice: build.mutation<
      UpdateLevelOfEffortForSubserviceApiResponse,
      UpdateLevelOfEffortForSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.subserviceId}/level-of-efforts/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteLevelOfEffortFromSubservice: build.mutation<
      DeleteLevelOfEffortFromSubserviceApiResponse,
      DeleteLevelOfEffortFromSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.subserviceId}/level-of-efforts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getServiceTaskRates: build.query<
      GetServiceTaskRatesApiResponse,
      GetServiceTaskRatesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.serviceId}/task-rates`,
        params: { page: queryArg.page },
      }),
    }),
    createTaskRateForService: build.mutation<
      CreateTaskRateForServiceApiResponse,
      CreateTaskRateForServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.serviceId}/task-rates`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getServiceTaskRate: build.query<
      GetServiceTaskRateApiResponse,
      GetServiceTaskRateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.serviceId}/task-rates/${queryArg.id}`,
        params: { page: queryArg.page },
      }),
    }),
    updateTaskRateForService: build.mutation<
      UpdateTaskRateForServiceApiResponse,
      UpdateTaskRateForServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.serviceId}/task-rates/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteTaskRateFromService: build.mutation<
      DeleteTaskRateFromServiceApiResponse,
      DeleteTaskRateFromServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/services/${queryArg.serviceId}/task-rates/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getSubserviceTaskRates: build.query<
      GetSubserviceTaskRatesApiResponse,
      GetSubserviceTaskRatesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.subserviceId}/task-rates`,
        params: { page: queryArg.page },
      }),
    }),
    createTaskRateForSubservice: build.mutation<
      CreateTaskRateForSubserviceApiResponse,
      CreateTaskRateForSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.subserviceId}/task-rates`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getSubserviceTaskRate: build.query<
      GetSubserviceTaskRateApiResponse,
      GetSubserviceTaskRateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.subserviceId}/task-rates/${queryArg.id}`,
        params: { page: queryArg.page },
      }),
    }),
    updateTaskRateForSubservice: build.mutation<
      UpdateTaskRateForSubserviceApiResponse,
      UpdateTaskRateForSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.subserviceId}/task-rates/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteTaskRateFromSubservice: build.mutation<
      DeleteTaskRateFromSubserviceApiResponse,
      DeleteTaskRateFromSubserviceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/subservices/${queryArg.subserviceId}/task-rates/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listTeamsForAccount: build.query<
      ListTeamsForAccountApiResponse,
      ListTeamsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/teams`,
        params: {
          page: queryArg.page,
          include: queryArg.include,
          filter: queryArg.filter,
        },
      }),
    }),
    createTeam: build.mutation<CreateTeamApiResponse, CreateTeamApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/teams`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getTeam: build.query<GetTeamApiResponse, GetTeamApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/teams/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateTeam: build.mutation<UpdateTeamApiResponse, UpdateTeamApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/teams/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteTeam: build.mutation<DeleteTeamApiResponse, DeleteTeamApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/teams/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listTermsForAccount: build.query<
      ListTermsForAccountApiResponse,
      ListTermsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/terms`,
        params: { page: queryArg.page, include: queryArg.include },
      }),
    }),
    createTerm: build.mutation<CreateTermApiResponse, CreateTermApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/terms`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getTerm: build.query<GetTermApiResponse, GetTermApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/terms/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateTerm: build.mutation<UpdateTermApiResponse, UpdateTermApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/terms/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteTerm: build.mutation<DeleteTermApiResponse, DeleteTermApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/terms/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listThirdPartyServicesForAccount: build.query<
      ListThirdPartyServicesForAccountApiResponse,
      ListThirdPartyServicesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/third-party-services`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createThirdPartyService: build.mutation<
      CreateThirdPartyServiceApiResponse,
      CreateThirdPartyServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/third-party-services`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getThirdPartyService: build.query<
      GetThirdPartyServiceApiResponse,
      GetThirdPartyServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/third-party-services/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateThirdPartyService: build.mutation<
      UpdateThirdPartyServiceApiResponse,
      UpdateThirdPartyServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/third-party-services/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteThirdPartyService: build.mutation<
      DeleteThirdPartyServiceApiResponse,
      DeleteThirdPartyServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/third-party-services/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    moveThirdPartyService: build.mutation<
      MoveThirdPartyServiceApiResponse,
      MoveThirdPartyServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/third-party-services/${queryArg.id}/move-to`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    listUsersForAccount: build.query<
      ListUsersForAccountApiResponse,
      ListUsersForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/users`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/users`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    showCurrentUserForAccount: build.query<
      ShowCurrentUserForAccountApiResponse,
      ShowCurrentUserForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/users/current`,
        params: { include: queryArg.include },
      }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/users/${queryArg.id}`,
      }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/users/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/users/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listVendorsForAccount: build.query<
      ListVendorsForAccountApiResponse,
      ListVendorsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/vendors`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          include: queryArg.include,
        },
      }),
    }),
    createVendor: build.mutation<CreateVendorApiResponse, CreateVendorApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/vendors`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getVendor: build.query<GetVendorApiResponse, GetVendorApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/vendors/${queryArg.id}`,
      }),
    }),
    updateVendor: build.mutation<UpdateVendorApiResponse, UpdateVendorApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/vendors/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteVendor: build.mutation<DeleteVendorApiResponse, DeleteVendorApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/vendors/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listWebhookSubscriptionsForAccount: build.query<
      ListWebhookSubscriptionsForAccountApiResponse,
      ListWebhookSubscriptionsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/webhook-subscriptions`,
        params: { include: queryArg.include, page: queryArg.page },
      }),
    }),
    createWebhookSubscription: build.mutation<
      CreateWebhookSubscriptionApiResponse,
      CreateWebhookSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/webhook-subscriptions`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getWebhookSubscription: build.query<
      GetWebhookSubscriptionApiResponse,
      GetWebhookSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/webhook-subscriptions/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateWebhookSubscription: build.mutation<
      UpdateWebhookSubscriptionApiResponse,
      UpdateWebhookSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/webhook-subscriptions/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteWebhookSubscription: build.mutation<
      DeleteWebhookSubscriptionApiResponse,
      DeleteWebhookSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/webhook-subscriptions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listAccountConnections: build.query<
      ListAccountConnectionsApiResponse,
      ListAccountConnectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/account-connections`,
        params: { filter: queryArg.filter },
      }),
    }),
    createAccountConnection: build.mutation<
      CreateAccountConnectionApiResponse,
      CreateAccountConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/account-connections`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getAccountConnection: build.query<
      GetAccountConnectionApiResponse,
      GetAccountConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/account-connections/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateAccountConnection: build.mutation<
      UpdateAccountConnectionApiResponse,
      UpdateAccountConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/account-connections/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteAccountConnection: build.mutation<
      DeleteAccountConnectionApiResponse,
      DeleteAccountConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/account-connections/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    reconnectAccountConnection: build.mutation<
      ReconnectAccountConnectionApiResponse,
      ReconnectAccountConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/account-connections/${queryArg.id}/reconnect`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    enableAccountConnection: build.mutation<
      EnableAccountConnectionApiResponse,
      EnableAccountConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/account-connections/${queryArg.id}/enable`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    disableAccountConnection: build.mutation<
      DisableAccountConnectionApiResponse,
      DisableAccountConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/account-connections/${queryArg.id}/disable`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    listContentTemplatesForAccount: build.query<
      ListContentTemplatesForAccountApiResponse,
      ListContentTemplatesForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/content-templates`,
        params: {
          filter: queryArg.filter,
          sort: queryArg.sort,
          page: queryArg.page,
        },
      }),
    }),
    getContentTemplate: build.query<
      GetContentTemplateApiResponse,
      GetContentTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/content-templates/${queryArg.id}`,
      }),
    }),
    importContentTemplate: build.mutation<
      ImportContentTemplateApiResponse,
      ImportContentTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/content-templates/${queryArg.id}/import`,
        method: "PUT",
      }),
    }),
    listIntegrationLogsForAccount: build.query<
      ListIntegrationLogsForAccountApiResponse,
      ListIntegrationLogsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/account-connections/${queryArg.accountConnectionId}/integration-logs`,
        params: { page: queryArg.page },
      }),
    }),
    getIntegrationLog: build.query<
      GetIntegrationLogApiResponse,
      GetIntegrationLogApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/account-connections/${queryArg.accountConnectionId}/integration-logs/${queryArg.id}`,
      }),
    }),
    getAccountFinancials: build.query<
      GetAccountFinancialsApiResponse,
      GetAccountFinancialsApiArg
    >({
      query: (queryArg) => ({ url: `/${queryArg.slug}/v1/account-financials` }),
    }),
    listDataImportsForAccount: build.query<
      ListDataImportsForAccountApiResponse,
      ListDataImportsForAccountApiArg
    >({
      query: (queryArg) => ({ url: `/${queryArg.slug}/v1/data-imports` }),
    }),
    createDataImport: build.mutation<
      CreateDataImportApiResponse,
      CreateDataImportApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/data-imports`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getDataImportById: build.query<
      GetDataImportByIdApiResponse,
      GetDataImportByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/data-imports/${queryArg.id}`,
      }),
    }),
    listFreeTrialsForAccount: build.query<
      ListFreeTrialsForAccountApiResponse,
      ListFreeTrialsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/free-trials`,
        params: { page: queryArg.page, include: queryArg.include },
      }),
    }),
    createFreeTrial: build.mutation<
      CreateFreeTrialApiResponse,
      CreateFreeTrialApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/free-trials`,
        method: "POST",
      }),
    }),
    getFreeTrial: build.query<GetFreeTrialApiResponse, GetFreeTrialApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/free-trials/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    cancelFreeTrial: build.mutation<
      CancelFreeTrialApiResponse,
      CancelFreeTrialApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/free-trials/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listLandingPageContent: build.query<
      ListLandingPageContentApiResponse,
      ListLandingPageContentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/landing-page-contents`,
        params: { filter: queryArg.filter, page: queryArg.page },
      }),
    }),
    listSubscriptionsForAccount: build.query<
      ListSubscriptionsForAccountApiResponse,
      ListSubscriptionsForAccountApiArg
    >({
      query: () => ({ url: `/v1/subscriptions` }),
    }),
    createSubscription: build.mutation<
      CreateSubscriptionApiResponse,
      CreateSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/subscriptions`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getSubscription: build.query<
      GetSubscriptionApiResponse,
      GetSubscriptionApiArg
    >({
      query: (queryArg) => ({ url: `/v1/subscriptions/${queryArg.id}` }),
    }),
    updateSubscription: build.mutation<
      UpdateSubscriptionApiResponse,
      UpdateSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/subscriptions/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteSubscription: build.mutation<
      DeleteSubscriptionApiResponse,
      DeleteSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/subscriptions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getBySlugV1UserFavorites: build.query<
      GetBySlugV1UserFavoritesApiResponse,
      GetBySlugV1UserFavoritesApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-favorites`,
        params: { filter: queryArg.filter },
      }),
    }),
    createUserFavorite: build.mutation<
      CreateUserFavoriteApiResponse,
      CreateUserFavoriteApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-favorites`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    deleteUserFavorite: build.mutation<
      DeleteUserFavoriteApiResponse,
      DeleteUserFavoriteApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-favorites/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listUserFiltersForAccount: build.query<
      ListUserFiltersForAccountApiResponse,
      ListUserFiltersForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-filters`,
        params: { filter: queryArg.filter },
      }),
    }),
    createUserFilter: build.mutation<
      CreateUserFilterApiResponse,
      CreateUserFilterApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-filters`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUserFilter: build.query<GetUserFilterApiResponse, GetUserFilterApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-filters/${queryArg.id}`,
      }),
    }),
    updateUserFilter: build.mutation<
      UpdateUserFilterApiResponse,
      UpdateUserFilterApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-filters/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteUserFilter: build.mutation<
      DeleteUserFilterApiResponse,
      DeleteUserFilterApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-filters/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listUserViewsForAccount: build.query<
      ListUserViewsForAccountApiResponse,
      ListUserViewsForAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-views`,
        params: { filter: queryArg.filter, include: queryArg.include },
      }),
    }),
    createUserView: build.mutation<
      CreateUserViewApiResponse,
      CreateUserViewApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-views`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUserView: build.query<GetUserViewApiResponse, GetUserViewApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-views/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateUserView: build.mutation<
      UpdateUserViewApiResponse,
      UpdateUserViewApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-views/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteUserView: build.mutation<
      DeleteUserViewApiResponse,
      DeleteUserViewApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/user-views/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getSharedSurveyById: build.query<
      GetSharedSurveyByIdApiResponse,
      GetSharedSurveyByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/shared-surveys/${queryArg.id}`,
        params: { include: queryArg.include },
      }),
    }),
    updateSharedSurvey: build.mutation<
      UpdateSharedSurveyApiResponse,
      UpdateSharedSurveyApiArg
    >({
      query: (queryArg) => ({
        url: `/${queryArg.slug}/v1/shared-surveys/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    listConnectedApp: build.query<
      ListConnectedAppApiResponse,
      ListConnectedAppApiArg
    >({
      query: (queryArg) => ({ url: `/${queryArg.slug}/v1/connected-apps/` }),
    }),
    createAccount: build.mutation<
      CreateAccountApiResponse,
      CreateAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accounts`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generated };
export type WhoAmIApiResponse = /** status 200 Who Am I response */ {
  data?: V1MeResource;
  links?: V1Paging;
};
export type WhoAmIApiArg = void;
export type GetAccountApiResponse = /** status 200 Account response */ {
  data?: V1AccountResource;
  included?: (
    | V1BusinessUnitResource
    | V1ClientResource
    | V1CrmOpportunityResource
    | V1DocumentTemplateResource
    | V1GovernanceResource
    | V1LineOfBusinessResource
    | V1PaymentCreditResource
    | V1PaymentTermResource
    | V1PhaseResource
    | V1ProductResource
    | V1ProjectVariableResource
    | V1ProjectResource
    | V1RateTableResource
    | V1ResourceResource
    | V1ServiceResource
    | V1TeamResource
    | V1UserResource
  )[];
};
export type GetAccountApiArg = {
  /** Slug of Account */
  slug: string;
  /** Associated data that may be loaded with the account */
  include?: (
    | "business-units"
    | "clients"
    | "crm-opportunities"
    | "document-templates"
    | "governances"
    | "line-of-businesses"
    | "payment-credits"
    | "payment-terms"
    | "phases"
    | "products"
    | "project-variables"
    | "projects"
    | "rate-tables"
    | "resources"
    | "services"
    | "teams"
    | "users"
  )[];
};
export type GetAccountLogoApiResponse = /** status 200 Project response */ {
  data?: V1AccountLogoResource;
};
export type GetAccountLogoApiArg = {
  /** Slug of Account */
  slug: string;
};
export type UpdateAccountLogoApiResponse =
  /** status 200 Update AccountLogo response */ {
    data?: V1AccountLogoResource;
  };
export type UpdateAccountLogoApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1AccountLogoResource;
  };
};
export type ListApprovalStepsForAccountApiResponse =
  /** status 200 Approval Step list response */ {
    data?: V1ApprovalStepResource[];
    included?: (
      | V1ProjectResource
      | V1ProjectVersionResource
      | V1ProjectApprovalResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListApprovalStepsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each approval step */
  include?: ("project" | "project-version" | "project-approvals")[];
};
export type GetApprovalStepApiResponse = /** status 200 Project response */ {
  data?: V1ApprovalStepResource;
  included?: (
    | V1ProjectResource
    | V1ProjectVersionResource
    | V1ProjectApprovalResource
  )[];
};
export type GetApprovalStepApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each approval step */
  include?: ("project" | "project-version" | "project-approvals")[];
};
export type ListAuditLogsApiResponse =
  /** status 200 Audit Log list response */ {
    data?: V1AuditLogResource[];
    links?: V1Paging;
  };
export type ListAuditLogsApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    "context-type"?: string;
    "context-id"?: string;
    after?: string;
    before?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: ("created-at" | "-created-at")[];
};
export type ListProjectAuditLogsApiResponse =
  /** status 200 Audit Log list response */ {
    data?: V1AuditLogResource[];
    links?: V1Paging;
  };
export type ListProjectAuditLogsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  filter?: {
    after?: string;
    before?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: ("created-at" | "-created-at")[];
};
export type GetAuditLogByIdApiResponse = /** status 200 Audit log response */ {
  data?: V1AuditLogResource;
};
export type GetAuditLogByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type RevertAuditLogByIdApiResponse =
  /** status 204 Audit log object was successfulll reverted to its prior state */ undefined;
export type RevertAuditLogByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListBlueprintsForAccountApiResponse =
  /** status 200 Blueprint list response */ {
    data?: V1BlueprintResource[];
    included?: (V1AccountResource | V1BlueprintItemResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListBlueprintsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    tag_list?: string;
    "service-id"?: string;
  };
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: (
    | "id"
    | "-id"
    | "name"
    | "-name"
    | "code"
    | "-code"
    | "tag-list"
    | "-tag-list"
  )[];
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each blueprint */
  include?: ("account" | "blueprint-items")[];
};
export type CreateBlueprintApiResponse =
  /** status 201 Create Blueprint response */ {
    data?: V1BlueprintResource;
  };
export type CreateBlueprintApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1BlueprintResource;
  };
};
export type CreateBlueprintFromProjectApiResponse =
  /** status 200 Create blueprint from project response */ {
    data?: V1BlueprintResource;
  };
export type CreateBlueprintFromProjectApiArg = {
  /** Slug of Account */
  slug: string;
  projectId: number;
};
export type GetBlueprintApiResponse = /** status 200 Project response */ {
  data?: V1BlueprintResource;
  included?: (V1AccountResource | V1BlueprintItemResource)[];
};
export type GetBlueprintApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each blueprint */
  include?: ("account" | "blueprint-items")[];
};
export type UpdateBlueprintApiResponse =
  /** status 200 Update Blueprint response */ {
    data?: V1BlueprintResource;
  };
export type UpdateBlueprintApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1BlueprintResource;
  };
};
export type DeleteBlueprintApiResponse =
  /** status 204 The Delete Blueprint was deleted successfully */ undefined;
export type DeleteBlueprintApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListBlueprintItemsForAccountApiResponse =
  /** status 200 Blueprint Item list response */ {
    data?: V1BlueprintItemResource[];
    links?: V1Paging;
  };
export type ListBlueprintItemsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  page?: {
    number?: number;
    size?: number;
  };
};
export type CreateBlueprintItemApiResponse =
  /** status 201 Create Blueprint Item response */ {
    data?: V1BlueprintItemResource;
  };
export type CreateBlueprintItemApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1BlueprintItemResource;
  };
};
export type GetBlueprintItemApiResponse = /** status 200 Project response */ {
  data?: V1BlueprintItemResource;
};
export type GetBlueprintItemApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateBlueprintItemApiResponse =
  /** status 200 Update Blueprint Item response */ {
    data?: V1BlueprintItemResource;
  };
export type UpdateBlueprintItemApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1BlueprintItemResource;
  };
};
export type DeleteBlueprintItemApiResponse =
  /** status 204 The Delete Blueprint Item was deleted successfully */ undefined;
export type DeleteBlueprintItemApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListBusinessUnitsForAccountApiResponse =
  /** status 200 Business Unit list response */ {
    data?: V1BusinessUnitResource[];
    included?: V1AccountResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListBusinessUnitsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each business unit */
  include?: "account"[];
};
export type GetBusinessUnitApiResponse = /** status 200 Project response */ {
  data?: V1BusinessUnitResource;
  included?: V1AccountResource[];
};
export type GetBusinessUnitApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each business unit */
  include?: "account"[];
};
export type ListClientsForAccountApiResponse =
  /** status 200 Client list response */ {
    data?: V1ClientResource[];
    included?: (V1AccountResource | V1RateTableResource | V1ContactResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListClientsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    active?: boolean;
  };
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: (
    | "id"
    | "-id"
    | "active"
    | "-active"
    | "name"
    | "-name"
    | "msa-date"
    | "-msa-date"
  )[];
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each client */
  include?: ("account" | "rate-table" | "contacts")[];
};
export type CreateClientApiResponse = /** status 201 Create Client response */ {
  data?: V1ClientResource;
};
export type CreateClientApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ClientResource;
  };
};
export type GetClientApiResponse = /** status 200 Project response */ {
  data?: V1ClientResource;
  included?: (V1AccountResource | V1RateTableResource | V1ContactResource)[];
};
export type GetClientApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each client */
  include?: ("account" | "rate-table" | "contacts")[];
};
export type UpdateClientApiResponse = /** status 200 Update Client response */ {
  data?: V1ClientResource;
};
export type UpdateClientApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ClientResource;
  };
};
export type DeleteClientApiResponse =
  /** status 204 The client was deleted successfully */ undefined;
export type DeleteClientApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListContactsForAccountApiResponse =
  /** status 200 contact list response */ {
    data?: V1ContactResource[];
    links?: V1Paging;
  };
export type ListContactsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    email?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
};
export type CreateContactApiResponse =
  /** status 201 Create contact response */ {
    data?: V1ContactResource;
  };
export type CreateContactApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ContactResource;
  };
};
export type GetContactByIdApiResponse =
  /** status 200 Find contact by ID response */ {
    data?: V1ContactResource;
  };
export type GetContactByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateContactApiResponse =
  /** status 200 Update contact response */ {
    data?: V1ContactResource;
  };
export type UpdateContactApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ContactResource;
  };
};
export type DeleteContactApiResponse =
  /** status 204 The Delete contact was deleted successfully */ undefined;
export type DeleteContactApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListCrmOpportunitiesForAccountApiResponse =
  /** status 200 CRM Opportunity list response */ {
    data?: V1CrmOpportunityResource[];
    included?: (V1AccountResource | V1ProjectResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListCrmOpportunitiesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    "is-closed"?: boolean;
    "display-name"?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each CRM opportunity */
  include?: ("account" | "projects")[];
};
export type ListCurrencysForAccountApiResponse =
  /** status 200 Currency list response */ {
    data?: V1CurrencyResource[];
    included?: (V1AccountResource | V1RateTableResource | V1UserResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListCurrencysForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
    default?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each currency */
  include?: ("account" | "rate-tables" | "users")[];
};
export type CreateCurrencyApiResponse =
  /** status 201 Create Currency response */ {
    data?: V1CurrencyResource;
  };
export type CreateCurrencyApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1CurrencyResource;
  };
};
export type GetCurrencyApiResponse = /** status 200 Currency response */ {
  data?: V1CurrencyResource;
  included?: (V1AccountResource | V1RateTableResource | V1UserResource)[];
};
export type GetCurrencyApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each currency */
  include?: ("account" | "rate-tables" | "users")[];
};
export type UpdateCurrencyApiResponse =
  /** status 200 Update Currency response */ {
    data?: V1CurrencyResource;
  };
export type UpdateCurrencyApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1CurrencyResource;
  };
};
export type DeleteCurrencyApiResponse =
  /** status 204 The Delete Currency was deleted successfully */ undefined;
export type DeleteCurrencyApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListCustomerSuccessesApiResponse =
  /** status 200 Project Contact list response */ {
    data?: V1CustomerSuccessResource[];
    included?: (
      | V1ProjectResource
      | V1AccountResource
      | V1PerformanceIndicatorResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListCustomerSuccessesApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each blueprint */
  include?: ("project" | "account" | "performance-indicators")[];
};
export type CreateCustomerSuccessApiResponse =
  /** status 201 Create Project Contact response */ {
    data?: V1CustomerSuccessResource;
  };
export type CreateCustomerSuccessApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1CustomerSuccessResource;
  };
};
export type GetCustomerSuccessApiResponse =
  /** status 200 Project Contact list response */ {
    data?: V1CustomerSuccessResource;
    included?: (
      | V1ProjectResource
      | V1AccountResource
      | V1PerformanceIndicatorResource
    )[];
  };
export type GetCustomerSuccessApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each blueprint */
  include?: ("project" | "account" | "performance-indicators")[];
};
export type UpdateCustomerSuccessApiResponse =
  /** status 200 Update Project Contact response */ {
    data?: V1CustomerSuccessResource;
  };
export type UpdateCustomerSuccessApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1CustomerSuccessResource;
  };
};
export type DeleteCustomerSuccessApiResponse =
  /** status 204 The Project Contact was deleted successfully */ undefined;
export type DeleteCustomerSuccessApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListDocumentTemplatesForAccountApiResponse =
  /** status 200 Document Template list response */ {
    data?: V1DocumentTemplateResource[];
    included?: V1AccountResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListDocumentTemplatesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    active?: boolean[];
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each document template */
  include?: "account"[];
};
export type CreateDocumentTemplateApiResponse =
  /** status 201 Create DocumentTemplate response */ {
    data?: V1DocumentTemplateResource;
  };
export type CreateDocumentTemplateApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1DocumentTemplateResource;
  };
};
export type GetDocumentTemplateApiResponse =
  /** status 200 Document template response */ {
    data?: V1DocumentTemplateResource;
    included?: V1AccountResource[];
  };
export type GetDocumentTemplateApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each document template */
  include?: "account"[];
};
export type UpdateDocumentTemplateApiResponse =
  /** status 200 Update DocumentTemplate response */ {
    data?: V1DocumentTemplateResource;
  };
export type UpdateDocumentTemplateApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1DocumentTemplateResource;
  };
};
export type DeleteDocumentTemplateApiResponse =
  /** status 204 The document template was deleted successfully */ undefined;
export type DeleteDocumentTemplateApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type RestoreDocumentTemplateApiResponse =
  /** status 200 Update document template response */ {
    data?: V1DocumentTemplateResource;
  };
export type RestoreDocumentTemplateApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1DocumentTemplateResource;
  };
};
export type GetFilenameFormatOptionsApiResponse =
  /** status 200 Document template response */ {
    "filename-format"?: string[];
  };
export type GetFilenameFormatOptionsApiArg = {
  /** Slug of Account */
  slug: string;
};
export type ListExpenseCategoriessForAccountApiResponse =
  /** status 200 Expense category list response */ {
    data?: V1ExpenseCategoryResource[];
    included?: (V1AccountResource | V1PhaseResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListExpenseCategoriessForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each expense category */
  include?: ("account" | "phase")[];
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: (
    | "id"
    | "-id"
    | "active"
    | "-active"
    | "name"
    | "-name"
    | "rate"
    | "-rate"
    | "use-resource-rate"
    | "-use-resource-rate"
    | "markup"
    | "-markup"
  )[];
};
export type GetExpenseCategoryByIdApiResponse =
  /** status 200 Find expense category by ID response */ {
    data?: V1ExpenseCategoryResource;
    included?: (V1AccountResource | V1PhaseResource)[];
  };
export type GetExpenseCategoryByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each expense category */
  include?: ("account" | "phase")[];
};
export type ListGovernancesForAccountApiResponse =
  /** status 200 Governance list response */ {
    data?: V1GovernanceResource[];
    included?: (
      | V1AccountResource
      | V1ResourceResource
      | V1PhaseResource
      | V1ServiceCategoryResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListGovernancesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each governance */
  include?: ("account" | "resource" | "phase" | "service-category")[];
};
export type GetGovernanceByIdApiResponse =
  /** status 200 Find governance by ID response */ {
    data?: V1GovernanceResource;
    included?: (
      | V1AccountResource
      | V1ResourceResource
      | V1PhaseResource
      | V1ServiceCategoryResource
    )[];
  };
export type GetGovernanceByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each governance */
  include?: ("account" | "resource" | "phase" | "service-category")[];
};
export type ChangeGovernancePositionByIdApiResponse =
  /** status 200 Find governance by ID response */ {
    data?: V1GovernanceResource;
  };
export type ChangeGovernancePositionByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1GovernanceResource;
  };
};
export type ListIntegratedProductsApiResponse =
  /** status 200 Integrated Products list response */ {
    data?: V1IntegratedProductResource[];
    links?: V1Paging;
    meta?: {
      "record-count"?: number;
    };
  };
export type ListIntegratedProductsApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    "account-connection"?: string;
    "product-id"?: string;
    name?: string;
    "manufacturer-part-number"?: string;
    "manufacturer-name"?: string;
    "vendor-name"?: string;
    sku?: string;
    category?: string;
    subcategory?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
};
export type ListLanguageFieldsForAccountApiResponse =
  /** status 200 Langauge field list response */ {
    data?: V1LanguageFieldResource[];
    included?: (V1AccountResource | V1ProjectExpenseResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListLanguageFieldsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each language field */
  include?: ("account" | "project-expenses")[];
};
export type GetLanguageFieldByIdApiResponse =
  /** status 200 Find language field by ID response */ {
    data?: V1LanguageFieldResource;
    included?: (V1AccountResource | V1ProjectExpenseResource)[];
  };
export type GetLanguageFieldByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each language field */
  include?: ("account" | "project-expenses")[];
};
export type ChangeLanguageFieldPositionByIdApiResponse =
  /** status 200 Find language field by ID response */ {
    data?: V1LanguageFieldResource;
  };
export type ChangeLanguageFieldPositionByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1LanguageFieldResource;
  };
};
export type ListLineOfBusinessesForAccountApiResponse =
  /** status 200 LineOfBusiness list response */ {
    data?: V1LineOfBusinessResource[];
    included?: V1AccountResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListLineOfBusinessesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each line of business */
  include?: "account"[];
};
export type CreateLineOfBusinessApiResponse =
  /** status 201 Create LineOfBusiness response */ {
    data?: V1LineOfBusinessResource;
  };
export type CreateLineOfBusinessApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1LineOfBusinessResource;
  };
};
export type GetLineOfBusinessApiResponse =
  /** status 200 Line of Business response */ {
    data?: V1LineOfBusinessResource;
    included?: V1AccountResource[];
  };
export type GetLineOfBusinessApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each line of business */
  include?: "account"[];
};
export type UpdateLineOfBusinessApiResponse =
  /** status 200 Update Line Of Business response */ {
    data?: V1LineOfBusinessResource;
  };
export type UpdateLineOfBusinessApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1LineOfBusinessResource;
  };
};
export type DeleteLineOfBusinessApiResponse =
  /** status 204 The Delete Line Of Business was deleted successfully */ undefined;
export type DeleteLineOfBusinessApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListNotesApiResponse =
  /** status 200 Project Contact list response */ {
    data?: V1NoteResource[];
    included?: V1ProjectResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListNotesApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each note */
  include?: ("project" | "notable")[];
};
export type CreateNoteApiResponse =
  /** status 201 Create Project Contact response */ {
    data?: V1NoteResource;
  };
export type CreateNoteApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  body: {
    data?: V1NoteResource;
  };
};
export type GetNoteApiResponse =
  /** status 200 Project Contact list response */ {
    data?: V1NoteResource;
    included?: V1ProjectResource[];
  };
export type GetNoteApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each note */
  include?: ("project" | "notable")[];
};
export type UpdateNoteApiResponse =
  /** status 200 Update Project Contact response */ {
    data?: V1NoteResource;
  };
export type UpdateNoteApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  /** ID of record */
  id: number;
  body: {
    data?: V1NoteResource;
  };
};
export type DeleteNoteApiResponse =
  /** status 204 The Project Contact was deleted successfully */ undefined;
export type DeleteNoteApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  /** ID of record */
  id: number;
};
export type ListPartnerRequestsForAccountApiResponse =
  /** status 200 Partner Request list response */ {
    data?: V1PartnerRequestResource[];
    included?: (
      | V1AccountResource
      | V1ProjectResource
      | V1LobResource
      | V1SubcontractorResource
      | V1CurrencyResource
      | V1ThirdPartyServiceResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListPartnerRequestsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    subcontractor?: string;
    subproject?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each client */
  include?: (
    | "account"
    | "project"
    | "lob"
    | "subcontractor"
    | "currency"
    | "third-party-services"
  )[];
};
export type CreatePartnerRequestApiResponse =
  /** status 201 Create Partner Request response */ {
    data?: V1PartnerRequestResource;
  };
export type CreatePartnerRequestApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1PartnerRequestResource;
  };
};
export type GetPartnerRequestApiResponse =
  /** status 200 Partner ReqeuesProjectt response */ {
    data?: V1PartnerRequestResource;
  };
export type GetPartnerRequestApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdatePartnerRequestApiResponse =
  /** status 200 Update Partner Request response */ {
    data?: V1PartnerRequestResource;
  };
export type UpdatePartnerRequestApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1PartnerRequestResource;
  };
};
export type DeletePartnerRequestApiResponse =
  /** status 204 The Delete Partner Request was deleted successfully */ undefined;
export type DeletePartnerRequestApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CompletePartnerRequestApiResponse =
  /** status 200 Complete Partner Request response */ {
    data?: V1PartnerRequestResource;
  };
export type CompletePartnerRequestApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type RevisePartnerRequestApiResponse =
  /** status 200 Revise Partner Request response */ {
    data?: V1PartnerRequestResource;
  };
export type RevisePartnerRequestApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type DeclinePartnerRequestApiResponse =
  /** status 200 Decline Partner Request response */ {
    data?: V1PartnerRequestResource;
  };
export type DeclinePartnerRequestApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CountPartnerRequestsApiResponse =
  /** status 200 Partner requests count successful */ {
    meta?: V1RecordCount;
  };
export type CountPartnerRequestsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListExternalRequestsForAccountApiResponse =
  /** status 200 External Request list response */ {
    data?: V1ExternalRequestResource[];
    included?: (
      | V1AccountResource
      | V1ProjectResource
      | V1LobResource
      | V1SubcontractorResource
      | V1SubprojectResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListExternalRequestsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    subcontractor?: string;
    subproject?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each client */
  include?: ("account" | "project" | "lob" | "subcontractor" | "subproject")[];
};
export type GetExternalRequestApiResponse =
  /** status 200 External Request response */ {
    data?: V1ExternalRequestResource;
    included?: (
      | V1AccountResource
      | V1ProjectResource
      | V1LobResource
      | V1SubcontractorResource
      | V1SubprojectResource
    )[];
  };
export type GetExternalRequestApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each client */
  include?: ("account" | "project" | "lob" | "subcontractor" | "subproject")[];
};
export type AcceptExternalRequestApiResponse =
  /** status 200 Accept External Request response */ {
    data?: V1ExternalRequestResource;
  };
export type AcceptExternalRequestApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type OfferExternalRequestApiResponse =
  /** status 200 Offer External Request response */ {
    data?: V1ExternalRequestResource;
  };
export type OfferExternalRequestApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type DeclineExternalRequestApiResponse =
  /** status 200 Decline External Request response */ {
    data?: V1ExternalRequestResource;
  };
export type DeclineExternalRequestApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListPartnerRequestExternalServicesApiResponse =
  /** status 200 List of sesrvices offered by the subproject on a partner request */ {
    data?: V1ThirdPartyServiceResource[];
    included?: V1ProjectPhaseResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListPartnerRequestExternalServicesApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each team */
  include?: ("source" | "project-phase" | "project-task" | "subtasks")[];
};
export type ListPaymentCreditsForAccountApiResponse =
  /** status 200 Payment Credit list response */ {
    data?: V1PaymentCreditResource[];
    included?: V1AccountResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListPaymentCreditsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    "payment-source"?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each payment credit */
  include?: "account"[];
};
export type CreatePaymentCreditApiResponse =
  /** status 201 Create Payment Credit response */ {
    data?: V1PaymentCreditResource;
  };
export type CreatePaymentCreditApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1PaymentCreditResource;
  };
};
export type GetPaymentCreditApiResponse = /** status 200 Project response */ {
  data?: V1PaymentCreditResource;
};
export type GetPaymentCreditApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdatePaymentCreditApiResponse =
  /** status 200 Update Payment Credit response */ {
    data?: V1PaymentCreditResource;
  };
export type UpdatePaymentCreditApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1PaymentCreditResource;
  };
};
export type DeletePaymentCreditApiResponse =
  /** status 204 The Delete Payment Credit was deleted successfully */ undefined;
export type DeletePaymentCreditApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListPaymentTermsForAccountApiResponse =
  /** status 200 Payment Term list response */ {
    data?: V1PaymentTermResource[];
    included?: (V1AccountResource | V1PhaseResource | V1TermResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListPaymentTermsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each payment credit */
  include?: ("account" | "phase" | "terms")[];
};
export type GetPaymentTermApiResponse = /** status 200 Project response */ {
  data?: V1PaymentTermResource;
  included?: (V1AccountResource | V1PhaseResource | V1TermResource)[];
};
export type GetPaymentTermApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each payment credit */
  include?: ("account" | "phase" | "terms")[];
};
export type ListPerformanceIndicatorsForAccountApiResponse =
  /** status 200 Performance Indicator list response */ {
    data?: V1PerformanceIndicatorResource[];
    included?: V1CustomerSuccessResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListPerformanceIndicatorsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each performance indicator */
  include?: "customer-success"[];
};
export type CreatePerformanceIndicatorApiResponse =
  /** status 201 Create Performance Indicator response */ {
    data?: V1PerformanceIndicatorResource;
  };
export type CreatePerformanceIndicatorApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1PerformanceIndicatorResource;
  };
};
export type GetPerformanceIndicatorApiResponse =
  /** status 200 Project response */ {
    data?: V1PerformanceIndicatorResource;
    included?: V1CustomerSuccessResource[];
  };
export type GetPerformanceIndicatorApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each performance indicator */
  include?: "customer-success"[];
};
export type UpdatePerformanceIndicatorApiResponse =
  /** status 200 Update Performance Indicator response */ {
    data?: V1PerformanceIndicatorResource;
  };
export type UpdatePerformanceIndicatorApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1PerformanceIndicatorResource;
  };
};
export type DeletePerformanceIndicatorApiResponse =
  /** status 204 The Delete Performance Indicator was deleted successfully */ undefined;
export type DeletePerformanceIndicatorApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListPhasesForAccountApiResponse =
  /** status 200 Phase list response */ {
    data?: V1PhaseResource[];
    included?: (V1AccountResource | V1ProjectExpenseResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListPhasesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each phase */
  include?: ("account" | "project-expenses")[];
};
export type GetPhaseByIdApiResponse =
  /** status 200 Find phase by ID response */ {
    data?: V1PhaseResource;
    included?: (V1AccountResource | V1ProjectExpenseResource)[];
  };
export type GetPhaseByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each phase */
  include?: ("account" | "project-expenses")[];
};
export type ListPresalesEngineersForAccountApiResponse =
  /** status 200 Presales Engineer list response */ {
    data?: V1PresalesEngineerResource[];
    included?: V1AccountResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListPresalesEngineersForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    email?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each presales engineer */
  include?: "account"[];
};
export type GetPresalesEngineerByIdApiResponse =
  /** status 200 Find presales engineer by ID response */ {
    data?: V1PresalesEngineerResource;
    included?: V1AccountResource[];
  };
export type GetPresalesEngineerByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each presales engineer */
  include?: "account"[];
};
export type ListPricingAdjustmentsForAccountApiResponse =
  /** status 200 PricingAdjustment list response */ {
    data?: V1PricingAdjustmentResource[];
    included?: V1ProjectResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListPricingAdjustmentsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each pricing adjustment */
  include?: "project"[];
};
export type CreatePricingAdjustmentApiResponse =
  /** status 201 Create PricingAdjustment response */ {
    data?: V1PricingAdjustmentResource;
  };
export type CreatePricingAdjustmentApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  body: {
    data?: V1PricingAdjustmentResource;
  };
};
export type GetPricingAdjustmentByIdApiResponse =
  /** status 200 Update resource response */ {
    data?: V1PricingAdjustmentResource;
  };
export type GetPricingAdjustmentByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each pricing adjustment */
  include?: "project"[];
};
export type UpdatePricingAdjustmentByIdApiResponse =
  /** status 200 Update resource response */ {
    data?: V1PricingAdjustmentResource;
  };
export type UpdatePricingAdjustmentByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  /** ID of record */
  id: number;
  body: {
    data?: V1PricingAdjustmentResource;
  };
};
export type DeletePricingAdjustmentByIdApiResponse =
  /** status 204 The Pricing Adjustment was deleted successfully */ undefined;
export type DeletePricingAdjustmentByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  /** ID of record */
  id: number;
};
export type ListProductsForAccountApiResponse =
  /** status 200 Product list response */ {
    data?: V1ProductResource[];
    included?: (V1AccountResource | V1ServiceResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProductsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    category?: string;
    subcategory?: string;
    sku?: string;
    "product-id"?: string;
    "manufacturer-name"?: string;
    "manufacturer-part-number"?: string;
    "vendor-name"?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each product */
  include?: ("account" | "services")[];
};
export type CreateProductApiResponse =
  /** status 201 Create Product response */ {
    data?: V1ProductResource;
  };
export type CreateProductApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProductResource;
  };
};
export type GetProductApiResponse = /** status 200 Project response */ {
  data?: V1ProductResource;
  included?: (V1AccountResource | V1ServiceResource)[];
};
export type GetProductApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each product */
  include?: ("account" | "services")[];
};
export type UpdateProductApiResponse =
  /** status 200 Update Product response */ {
    data?: V1ProductResource;
  };
export type UpdateProductApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProductResource;
  };
};
export type DeleteProductApiResponse =
  /** status 204 The Delete Product was deleted successfully */ undefined;
export type DeleteProductApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListProjectsForAccountApiResponse =
  /** status 200 Project list response */ {
    data?: V1ProjectResource[];
    included?: (
      | V1AccountResource
      | V1BusinessUnitResource
      | V1ClientResource
      | V1CreatorResource
      | V1DocumentTemplateResource
      | V1ExternalRequestResource
      | V1SalesExecutiveResource
      | V1PresalesEngineerResource
      | V1PsaProjectResource
      | V1PaymentTermResource
      | V1RateTableResource
      | V1CrmOpportunityResource
      | V1ApprovalStepResource
      | V1CustomerSuccessResource
      | V1NoteResource
      | V1ProjectAttachmentResource
      | V1ProjectCollaboratorResource
      | V1ProjectContactResource
      | V1ProjectConditionResource
      | V1ProjectCreditResource
      | V1ProjectDocumentResource
      | V1ProjectExpenseResource
      | V1ProjectGovernanceResource
      | V1ProjectLocationResource
      | V1ProjectProductResource
      | V1ProjectPhaseResource
      | V1ProjectResourceResource
      | V1ResourcePlanResource
      | V1ProjectServiceResource
      | V1PartnerRequestResource
      | V1ProjectVersionResource
      | V1ResourceRateResource
      | V1QuoteResource
      | V1AuditLogResource
      | V1PricingAdjustmentResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    "project-name"?: string;
    status?:
      | "building"
      | "technical_approval"
      | "sales_approval"
      | "business_approval"
      | "approved"
      | "won"
      | "lost"
      | "canceled";
    "creator.id"?: string;
    "team.id"?: string;
    "collaborator.id"?: string;
    "client.id"?: string;
    "client.name"?: string;
    "presales-engineer.id"?: string;
    "presales-engineer.name"?: string;
    "sales-executive.id"?: string;
    "sales-executive.name"?: string;
    "created-at.after"?: string;
    "created-at.before"?: string;
    "updated-at.after"?: string;
    "updated-at.before"?: string;
    "my-projects"?: boolean;
    "tag-list"?: string;
    active?: boolean;
    "service-id"?: string;
  };
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: (
    | "id"
    | "-id"
    | "active"
    | "-active"
    | "project-name"
    | "-project-name"
    | "status"
    | "-status"
    | "project-variables"
    | "-project-variables"
    | "submitted-at"
    | "-submitted-at"
    | "approved-at"
    | "-approved-at"
    | "executive-summary"
    | "-executive-summary"
    | "key-objectives"
    | "-key-objectives"
    | "our-responsibilities"
    | "-our-responsibilities"
    | "client-responsibilities"
    | "-client-responsibilities"
    | "solution-summary"
    | "-solution-summary"
    | "out-of-scope"
    | "-out-of-scope"
    | "field-labels"
    | "-field-labels"
    | "payment-info"
    | "-payment-info"
    | "total-effort"
    | "-total-effort"
    | "total-revenue"
    | "-total-revenue"
    | "ms-revenue"
    | "-ms-revenue"
    | "third-party-revenue"
    | "-third-party-revenue"
    | "contract-revenue"
    | "-contract-revenue"
    | "contract-cost"
    | "-contract-cost"
    | "price-adjustment"
    | "-price-adjustment"
    | "mrr-adjustment"
    | "-mrr-adjustment"
    | "mrr-terms"
    | "-mrr-terms"
    | "recurring-billing-frequency"
    | "-recurring-billing-frequency"
    | "contract-margin"
    | "-contract-margin"
    | "service-start-date"
    | "-service-start-date"
    | "include-ps-revenue-in-mrr"
    | "-include-ps-revenue-in-mrr"
    | "net-mrr"
    | "-net-mrr"
    | "created-at"
    | "-created-at"
    | "updated-at"
    | "-updated-at"
    | "tag-list"
    | "-tag-list"
    | "client-name"
    | "-client-name"
    | "presales-engineer-name"
    | "-presales-engineer-name"
    | "sales-executive-name"
    | "-sales-executive-name"
    | "travel-limit"
    | "-travel-limit"
    | "service-category"
    | "-service-category"
    | "cola"
    | "-cola"
    | "msa-date"
    | "-msa-date"
    | "storage-options"
    | "-storage-options"
  )[];
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project */
  include?: (
    | "account"
    | "business-unit"
    | "client"
    | "creator"
    | "document-template"
    | "external-request"
    | "sales-executive"
    | "presales-engineer"
    | "psa-project"
    | "payment-term"
    | "rate-table"
    | "crm-opportunity"
    | "approval-steps"
    | "customer-successes"
    | "notes"
    | "project-attachments"
    | "project-collaborators"
    | "project-contacts"
    | "project-conditions"
    | "project-credits"
    | "project-documents"
    | "project-expenses"
    | "project-governances"
    | "project-locations"
    | "project-products"
    | "project-phases"
    | "project-resources"
    | "resource-plans"
    | "project-services"
    | "partner-requests"
    | "project-versions"
    | "resource-rates"
    | "quotes"
    | "audit-logs"
    | "pricing-adjustments"
  )[];
};
export type CreateProjectApiResponse =
  /** status 201 Create Project response */ {
    data?: V1ProjectResource;
  };
export type CreateProjectApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1CreateProjectResource;
  };
};
export type GetProjectByIdApiResponse =
  /** status 200 Project list response */ {
    data?: V1ProjectResource;
    included?: (
      | V1AccountResource
      | V1BusinessUnitResource
      | V1ClientResource
      | V1CreatorResource
      | V1DocumentTemplateResource
      | V1ExternalRequestResource
      | V1SalesExecutiveResource
      | V1PresalesEngineerResource
      | V1PsaProjectResource
      | V1PaymentTermResource
      | V1RateTableResource
      | V1CrmOpportunityResource
      | V1ApprovalStepResource
      | V1CustomerSuccessResource
      | V1NoteResource
      | V1ProjectAttachmentResource
      | V1ProjectCollaboratorResource
      | V1ProjectContactResource
      | V1ProjectConditionResource
      | V1ProjectCreditResource
      | V1ProjectDocumentResource
      | V1ProjectExpenseResource
      | V1ProjectGovernanceResource
      | V1ProjectLocationResource
      | V1ProjectProductResource
      | V1ProjectPhaseResource
      | V1ProjectResourceResource
      | V1ResourcePlanResource
      | V1ProjectServiceResource
      | V1PartnerRequestResource
      | V1ProjectVersionResource
      | V1ResourceRateResource
      | V1QuoteResource
      | V1AuditLogResource
      | V1PricingAdjustmentResource
    )[];
  };
export type GetProjectByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project */
  include?: (
    | "account"
    | "business-unit"
    | "client"
    | "creator"
    | "document-template"
    | "external-request"
    | "sales-executive"
    | "presales-engineer"
    | "psa-project"
    | "payment-term"
    | "rate-table"
    | "crm-opportunity"
    | "approval-steps"
    | "customer-successes"
    | "notes"
    | "project-attachments"
    | "project-collaborators"
    | "project-contacts"
    | "project-conditions"
    | "project-credits"
    | "project-documents"
    | "project-expenses"
    | "project-governances"
    | "project-locations"
    | "project-products"
    | "project-phases"
    | "project-resources"
    | "resource-plans"
    | "project-services"
    | "partner-requests"
    | "project-versions"
    | "resource-rates"
    | "quotes"
    | "audit-logs"
    | "pricing-adjustments"
  )[];
};
export type UpdateProjectApiResponse =
  /** status 200 Update Project response */ {
    data?: V1ProjectResource;
  };
export type UpdateProjectApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1CreateProjectResource;
  };
};
export type DeleteProjectApiResponse =
  /** status 204 The Project was deleted successfully */ undefined;
export type DeleteProjectApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type RequestApprovalForProjectByIdApiResponse =
  /** status 200 Project after approval request */ {
    data?: V1ProjectResource;
  };
export type RequestApprovalForProjectByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CancelApprovalRequestForProjectByIdApiResponse =
  /** status 200 Project after approval request canceled */ {
    data?: V1ProjectResource;
  };
export type CancelApprovalRequestForProjectByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type RestartScopingForProjectByIdApiResponse =
  /** status 200 Project after restarting scoping */ {
    data?: V1ProjectResource;
  };
export type RestartScopingForProjectByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateProjectToWonByIdApiResponse =
  /** status 200 Project after marked won */ {
    data?: V1ProjectResource;
  };
export type UpdateProjectToWonByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateProjectToLostByIdApiResponse =
  /** status 200 Project after marked lost */ {
    data?: V1ProjectResource;
  };
export type UpdateProjectToLostByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type SyncProjectRevenueToCrmApiResponse =
  /** status 204 The Project synchronized successfully */ undefined;
export type SyncProjectRevenueToCrmApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type DuplicateProjectByIdApiResponse =
  /** status 200 Duplicate Project */ {
    data?: V1ProjectResource;
  };
export type DuplicateProjectByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ExportProjectsApiResponse = /** status 202 Export job started */ {
  message?: string;
  job_id?: string;
};
export type ExportProjectsApiArg = {
  /** Slug of Account */
  slug: string;
};
export type ListProjectApprovalsForAccountApiResponse =
  /** status 200 Project Approval list response */ {
    data?: V1ProjectApprovalResource[];
    included?: (V1ProjectResource | V1UserResource | V1ApprovalStepResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectApprovalsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    "approval-step"?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each client */
  include?: ("project" | "user" | "approval-step")[];
};
export type CreateProjectApprovalApiResponse =
  /** status 201 Create Project Approval response */ {
    data?: V1ProjectApprovalResource;
  };
export type CreateProjectApprovalApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectApprovalResource;
  };
};
export type GetProjectApprovalApiResponse = /** status 200 Project response */ {
  data?: V1ProjectApprovalResource;
  included?: (V1ProjectResource | V1UserResource | V1ApprovalStepResource)[];
};
export type GetProjectApprovalApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each client */
  include?: ("project" | "user" | "approval-step")[];
};
export type UpdateProjectApprovalApiResponse =
  /** status 200 Update Project Approval response */ {
    data?: V1ProjectApprovalResource;
  };
export type UpdateProjectApprovalApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectApprovalResource;
  };
};
export type DeleteProjectApprovalApiResponse =
  /** status 204 The Delete Project Approval was deleted successfully */ undefined;
export type DeleteProjectApprovalApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ApproveProjectApprovalByIdApiResponse =
  /** status 200 Update Project Approval response */ {
    data?: V1ProjectApprovalResource;
  };
export type ApproveProjectApprovalByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectApprovalResource;
  };
};
export type CancelProjectApprovalByIdApiResponse =
  /** status 200 Update Project Approval response */ {
    data?: V1ProjectApprovalResource;
  };
export type CancelProjectApprovalByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectApprovalResource;
  };
};
export type DeclineProjectApprovalByIdApiResponse =
  /** status 200 Update Project Approval response */ {
    data?: V1ProjectApprovalResource;
  };
export type DeclineProjectApprovalByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectApprovalResource;
  };
};
export type RescopeProjectApprovalByIdApiResponse =
  /** status 200 Update Project Approval response */ {
    data?: V1ProjectApprovalResource;
  };
export type RescopeProjectApprovalByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectApprovalResource;
  };
};
export type ListProjectAttachmentsForAccountApiResponse =
  /** status 200 Project Attachment list response */ {
    data?: V1ProjectAttachmentResource[];
    included?: (V1ProjectResource | V1AttachmentTypeResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectAttachmentsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project attachment */
  include?: ("project" | "attachment-type")[];
};
export type GetProjectAttachmentByIdApiResponse =
  /** status 200 Find project attachment by ID response */ {
    data?: V1ProjectAttachmentResource;
    included?: (V1ProjectResource | V1AttachmentTypeResource)[];
  };
export type GetProjectAttachmentByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project attachment */
  include?: ("project" | "attachment-type")[];
};
export type CountProjectAttachmentsApiResponse =
  /** status 200 project attachments count successful */ {
    meta?: V1RecordCount;
  };
export type CountProjectAttachmentsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListAttachmentTypesForAccountApiResponse =
  /** status 200 Attachment Type list response */ {
    data?: V1AttachmentTypeResource[];
    included?: (V1AccountResource | V1ProjectAttachmentResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListAttachmentTypesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each attachment type */
  include?: ("account" | "project-attachments")[];
};
export type GetAttachmentTypeApiResponse = /** status 200 Project response */ {
  data?: V1AttachmentTypeResource;
  included?: (V1AccountResource | V1ProjectAttachmentResource)[];
};
export type GetAttachmentTypeApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each attachment type */
  include?: ("account" | "project-attachments")[];
};
export type ApplyBlueprintToProjectApiResponse =
  /** status 204 The blueprint is applied to the project */ undefined;
export type ApplyBlueprintToProjectApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  body: {
    data?: {
      id?: number;
      type?: string;
    };
  };
};
export type ListProjectCollaboratorsApiResponse =
  /** status 200 Project Collaborator list response */ {
    data?: V1ProjectCollaboratorResource[];
    included?: (V1ProjectResource | V1UserResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectCollaboratorsApiArg = {
  /** Slug of Account */
  slug: string;
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project collaborator */
  include?: ("project" | "user")[];
};
export type CreateProjectCollaboratorApiResponse =
  /** status 201 Create Project Collaborator response */ {
    data?: V1ProjectCollaboratorResource;
  };
export type CreateProjectCollaboratorApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectCollaboratorResource;
  };
};
export type GetProjectCollaboratorByIdApiResponse =
  /** status 200 Project Collaborator list response */ {
    data?: V1ProjectCollaboratorResource;
    included?: (V1ProjectResource | V1UserResource)[];
  };
export type GetProjectCollaboratorByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project collaborator */
  include?: ("project" | "user")[];
};
export type UpdateProjectCollaboratorApiResponse =
  /** status 200 Update Project Collaborator response */ {
    data?: V1ProjectCollaboratorResource;
  };
export type UpdateProjectCollaboratorApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectCollaboratorResource;
  };
};
export type DeleteProjectCollaboratorApiResponse =
  /** status 204 The Project Collaborator was deleted successfully */ undefined;
export type DeleteProjectCollaboratorApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CountProjectCollaboratorsApiResponse =
  /** status 200 project collaborators count successful */ {
    meta?: V1RecordCount;
  };
export type CountProjectCollaboratorsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListProjectConditionsForAccountApiResponse =
  /** status 200 ProjectCondition list response */ {
    data?: V1ProjectConditionResource[];
    included?: (V1ProjectResource | V1ServiceConditionResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectConditionsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project condition */
  include?: ("project" | "service-condition")[];
};
export type GetProjectConditionApiResponse =
  /** status 200 Project response */ {
    data?: V1ProjectConditionResource;
    included?: (V1ProjectResource | V1ServiceConditionResource)[];
  };
export type GetProjectConditionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project condition */
  include?: ("project" | "service-condition")[];
};
export type UpdateProjectConditionApiResponse =
  /** status 200 Update ProjectCondition response */ {
    data?: V1ProjectConditionResource;
  };
export type UpdateProjectConditionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectConditionResource;
  };
};
export type DeleteProjectConditionApiResponse =
  /** status 204 The Delete ProjectCondition was deleted successfully */ undefined;
export type DeleteProjectConditionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type SkipProjectConditionApiResponse =
  /** status 200 Skipped Project Condition response */ {
    data?: V1ProjectConditionResource;
  };
export type SkipProjectConditionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectConditionResource;
  };
};
export type RestoreProjectConditionApiResponse =
  /** status 200 Restored Project Condition response */ {
    data?: V1ProjectConditionResource;
  };
export type RestoreProjectConditionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectConditionResource;
  };
};
export type CountProjectConditionsApiResponse =
  /** status 200 project conditions count successful */ {
    meta?: V1RecordCount;
  };
export type CountProjectConditionsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListProjectContactsApiResponse =
  /** status 200 Project Contact list response */ {
    data?: V1ProjectContactResource[];
    included?: (V1ProjectResource | V1ContactResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectContactsApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    name?: string;
    email?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project contact */
  include?: ("project" | "contact")[];
};
export type CreateProjectContactApiResponse =
  /** status 201 Create Project Contact response */ {
    data?: V1ProjectContactResource;
  };
export type CreateProjectContactApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectContactResource;
  };
};
export type GetProjectContactApiResponse =
  /** status 200 Project Contact list response */ {
    data?: V1ProjectContactResource;
    included?: (V1ProjectResource | V1ContactResource)[];
  };
export type GetProjectContactApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project contact */
  include?: ("project" | "contact")[];
};
export type UpdateProjectContactApiResponse =
  /** status 200 Update Project Contact response */ {
    data?: V1ProjectContactResource;
  };
export type UpdateProjectContactApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectContactResource;
  };
};
export type DeleteProjectContactApiResponse =
  /** status 204 The Project Contact was deleted successfully */ undefined;
export type DeleteProjectContactApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CountProjectContactsApiResponse =
  /** status 200 project contacts count successful */ {
    meta?: V1RecordCount;
  };
export type CountProjectContactsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListProjectCreditsApiResponse =
  /** status 200 Project Credit list response */ {
    data?: V1ProjectCreditResource[];
    included?: (V1ProjectResource | V1PaymentCreditResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectCreditsApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project credit */
  include?: ("project" | "payment-credit")[];
};
export type CreateProjectCreditApiResponse =
  /** status 201 Create Project Credit response */ {
    data?: V1ProjectCreditResource;
  };
export type CreateProjectCreditApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectCreditResource;
  };
};
export type GetProjectCreditByIdApiResponse =
  /** status 200 Project credit response */ {
    data?: V1ProjectCreditResource;
    included?: (V1ProjectResource | V1PaymentCreditResource)[];
  };
export type GetProjectCreditByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project credit */
  include?: ("project" | "payment-credit")[];
};
export type UpdateProjectCreditApiResponse =
  /** status 200 Update Project Credit response */ {
    data?: V1ProjectCreditResource;
  };
export type UpdateProjectCreditApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectCreditResource;
  };
};
export type DeleteProjectCreditApiResponse =
  /** status 204 The Project Credit was deleted successfully */ undefined;
export type DeleteProjectCreditApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListProjectDocumentsForAccountApiResponse =
  /** status 200 Project Document list response */ {
    data?: V1ProjectDocumentResource[];
    included?: (V1ProjectResource | V1DocumentDeliveryResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectDocumentsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project document */
  include?: ("project" | "document-deliveries")[];
};
export type CreateProjectDocumentApiResponse =
  /** status 201 Create Project Document response */ {
    data?: V1ProjectDocumentResource;
  };
export type CreateProjectDocumentApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectDocumentResource;
  };
};
export type GetProjectDocumentApiResponse =
  /** status 200 Project document response */ {
    data?: V1ProjectDocumentResource;
    included?: (V1ProjectResource | V1DocumentDeliveryResource)[];
  };
export type GetProjectDocumentApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project document */
  include?: ("project" | "document-deliveries")[];
};
export type ListDocumentDeliveriesForAccountApiResponse =
  /** status 200 Document delivery list response */ {
    data?: V1DocumentDeliveryResource[];
    included?: (V1ProjectDocumentResource | V1ProjectResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListDocumentDeliveriesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project_document?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each document delivery */
  include?: ("project-document" | "project")[];
};
export type GetDocumentDeliveryApiResponse =
  /** status 200 Document delivery response */ {
    data?: V1DocumentDeliveryResource;
    included?: (V1ProjectDocumentResource | V1ProjectResource)[];
  };
export type GetDocumentDeliveryApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each document delivery */
  include?: ("project-document" | "project")[];
};
export type ListProjectDownloadTypesForProjectApiResponse =
  /** status 200 Project download type list response */ {
    data?: V1ProjectDownloadTypeResource[];
    links?: V1Paging;
  };
export type ListProjectDownloadTypesForProjectApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
  filter?: {
    "team.id"?: string[];
  };
};
export type ListProjectExpensesApiResponse =
  /** status 200 Project Expense list response */ {
    data?: V1ProjectExpenseResource[];
    included?: (
      | V1ProjectResource
      | V1ProjectPhaseResource
      | V1ExpenseCategoryResource
      | V1ProjectResourceResource
      | V1ResourceResource
      | V1ResourceRateResource
      | V1ProjectServiceResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectExpensesApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project expense */
  include?: (
    | "project"
    | "project-phase"
    | "expense-category"
    | "project-resource"
    | "resource"
    | "resource-rate"
    | "project-service"
  )[];
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: (
    | "id"
    | "-id"
    | "active"
    | "-active"
    | "rate"
    | "-rate"
    | "quantity"
    | "-quantity"
    | "description"
    | "-description"
    | "markup"
    | "-markup"
    | "revenue"
    | "-revenue"
    | "cost"
    | "-cost"
  )[];
};
export type CreateProjectExpenseApiResponse =
  /** status 201 Create Project Expense response */ {
    data?: V1ProjectExpenseResource;
  };
export type CreateProjectExpenseApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectExpenseResource;
  };
};
export type GetProjectExpenseByIdApiResponse =
  /** status 200 Project list response */ {
    data?: V1ProjectExpenseResource;
    included?: (
      | V1ProjectResource
      | V1ProjectPhaseResource
      | V1ExpenseCategoryResource
      | V1ProjectResourceResource
      | V1ResourceResource
      | V1ResourceRateResource
      | V1ProjectServiceResource
    )[];
  };
export type GetProjectExpenseByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project expense */
  include?: (
    | "project"
    | "project-phase"
    | "expense-category"
    | "project-resource"
    | "resource"
    | "resource-rate"
    | "project-service"
  )[];
};
export type UpdateProjectExpenseApiResponse =
  /** status 200 Update Project Expense response */ {
    data?: V1ProjectExpenseResource;
  };
export type UpdateProjectExpenseApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectExpenseResource;
  };
};
export type DeleteProjectExpenseApiResponse =
  /** status 204 The Project Expense was deleted successfully */ undefined;
export type DeleteProjectExpenseApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CountProjectExpensesApiResponse =
  /** status 200 project expenses count successful */ {
    meta?: V1RecordCount;
  };
export type CountProjectExpensesApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListProjectGovernancesApiResponse =
  /** status 200 Project Governance list response */ {
    data?: V1ProjectGovernanceResource[];
    included?: (
      | V1ProjectResource
      | V1ProjectPhaseResource
      | V1GovernanceResource
      | V1ProjectResourceResource
      | V1ResourceResource
      | V1ResourceRateResource
      | V1ServiceCategoryResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectGovernancesApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project governance */
  include?: (
    | "project"
    | "project-phase"
    | "governance"
    | "project-resource"
    | "resource"
    | "resource-rate"
    | "service-category"
  )[];
};
export type CreateProjectGovernanceApiResponse =
  /** status 201 Create Project Governance response */ {
    data?: V1ProjectGovernanceResource;
  };
export type CreateProjectGovernanceApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectGovernanceResource;
  };
};
export type GetProjectGovernanceByIdApiResponse =
  /** status 200 Project list response */ {
    data?: V1ProjectGovernanceResource;
    included?: (
      | V1ProjectResource
      | V1ProjectPhaseResource
      | V1GovernanceResource
      | V1ProjectResourceResource
      | V1ResourceResource
      | V1ResourceRateResource
      | V1ServiceCategoryResource
    )[];
  };
export type GetProjectGovernanceByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project governance */
  include?: (
    | "project"
    | "project-phase"
    | "governance"
    | "project-resource"
    | "resource"
    | "resource-rate"
    | "service-category"
  )[];
};
export type UpdateProjectGovernanceApiResponse =
  /** status 200 Update Project Governance response */ {
    data?: V1ProjectGovernanceResource;
  };
export type UpdateProjectGovernanceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectGovernanceResource;
  };
};
export type DeleteProjectGovernanceApiResponse =
  /** status 204 The Delete Project Governance was deleted successfully */ undefined;
export type DeleteProjectGovernanceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ChangeProjectGovernancePositionByIdApiResponse =
  /** status 200 Find project governance by ID response */ {
    data?: V1ProjectGovernanceResource;
  };
export type ChangeProjectGovernancePositionByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectGovernanceResource;
  };
};
export type CountProjectGovernancesApiResponse =
  /** status 200 project governances count successful */ {
    meta?: V1RecordCount;
  };
export type CountProjectGovernancesApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListProjectLocationsApiResponse =
  /** status 200 Project Location list response */ {
    data?: V1ProjectLocationResource[];
    included?: V1ProjectResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectLocationsApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project location */
  include?: "project"[];
};
export type CreateProjectLocationApiResponse =
  /** status 201 Create Project Location response */ {
    data?: V1ProjectLocationResource;
  };
export type CreateProjectLocationApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectLocationResource;
  };
};
export type GetProjectLocationByIdApiResponse =
  /** status 200 Project list response */ {
    data?: V1ProjectLocationResource;
    included?: V1ProjectResource[];
  };
export type GetProjectLocationByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project location */
  include?: "project"[];
};
export type UpdateProjectLocationApiResponse =
  /** status 200 Update Project Location response */ {
    data?: V1ProjectLocationResource;
  };
export type UpdateProjectLocationApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectLocationResource;
  };
};
export type DeleteProjectLocationApiResponse =
  /** status 204 The Delete Project Location was deleted successfully */ undefined;
export type DeleteProjectLocationApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CountProjectLocationsApiResponse =
  /** status 200 project locations count successful */ {
    meta?: V1RecordCount;
  };
export type CountProjectLocationsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListProjectPhasesForAccountApiResponse =
  /** status 200 Project Phase list response */ {
    data?: V1ProjectPhaseResource[];
    included?: (V1ProjectResource | V1PhaseResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectPhasesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
    project?: number;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project phase */
  include?: ("project" | "phase")[];
};
export type CreateProjectPhaseApiResponse =
  /** status 201 Create Project Phase response */ {
    data?: V1ProjectPhaseResource;
  };
export type CreateProjectPhaseApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectPhaseResource;
  };
};
export type GetProjectPhaseByIdApiResponse =
  /** status 200 Find Project Phase by ID response */ {
    data?: V1ProjectPhaseResource;
    included?: (V1ProjectResource | V1PhaseResource)[];
  };
export type GetProjectPhaseByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project phase */
  include?: ("project" | "phase")[];
};
export type UpdateProjectPhaseApiResponse =
  /** status 200 Update Project Phase response */ {
    data?: V1ProjectPhaseResource;
  };
export type UpdateProjectPhaseApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectPhaseResource;
  };
};
export type DeleteProjectPhaseApiResponse =
  /** status 204 The Project Phase was deleted successfully */ undefined;
export type DeleteProjectPhaseApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListProjectProductsForAccountApiResponse =
  /** status 200 Product list response */ {
    data?: V1ProjectProductResource[];
    included?: (
      | V1ProjectResource
      | V1ProductResource
      | V1ProjectServiceResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectProductsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project product */
  include?: ("project" | "product" | "project-service")[];
};
export type CreateProjectProductApiResponse =
  /** status 201 Create Product response */ {
    data?: V1ProjectProductResource;
  };
export type CreateProjectProductApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectProductResource;
  };
};
export type GetProjectProductApiResponse =
  /** status 200 Project product response */ {
    data?: V1ProjectProductResource;
    included?: (
      | V1ProjectResource
      | V1ProductResource
      | V1ProjectServiceResource
    )[];
  };
export type GetProjectProductApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project product */
  include?: ("project" | "product" | "project-service")[];
};
export type UpdateProjectProductApiResponse =
  /** status 200 Update Product response */ {
    data?: V1ProjectProductResource;
  };
export type UpdateProjectProductApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectProductResource;
  };
};
export type DeleteProjectProductApiResponse =
  /** status 204 The Delete Product was deleted successfully */ undefined;
export type DeleteProjectProductApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CountProjectProductsApiResponse =
  /** status 200 project products count successful */ {
    meta?: V1RecordCount;
  };
export type CountProjectProductsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListProjectPricingsApiResponse =
  /** status 200 Project Pricing list response */ {
    data?: V1ProjectPricingResource[];
    links?: V1Paging;
  };
export type ListProjectPricingsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListProjectResourcesForAccountApiResponse =
  /** status 200 ProjectResource list response */ {
    data?: V1ProjectResourceResource[];
    included?: (
      | V1ProjectResource
      | V1ResourceResource
      | V1LineOfBusinessResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectResourcesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    active?: boolean;
    "governed-categories"?: string[];
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project resource */
  include?: ("project" | "resource" | "line-of-business")[];
};
export type CreateProjectResourceApiResponse =
  /** status 201 Create Project Resource response */ {
    data?: V1ProjectResourceResource;
  };
export type CreateProjectResourceApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectResourceResource;
  };
};
export type GetProjectResourceApiResponse = /** status 200 Project response */ {
  data?: V1ProjectResourceResource;
  included?: (
    | V1ProjectResource
    | V1ResourceResource
    | V1LineOfBusinessResource
  )[];
};
export type GetProjectResourceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateProjectResourceApiResponse =
  /** status 200 Update ProjectResource response */ {
    data?: V1ProjectResourceResource;
  };
export type UpdateProjectResourceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectResourceResource;
  };
};
export type CountProjectResourcesApiResponse =
  /** status 200 Project resources count successful */ {
    meta?: V1RecordCount;
  };
export type CountProjectResourcesApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListProjectServicesApiResponse =
  /** status 200 Project Service list response */ {
    data?: V1ProjectServiceResource[];
    included?: (
      | V1ProjectResource
      | V1ProjectLocationResource
      | V1ProjectPhaseResource
      | V1ProjectResourceResource
      | V1ResourceResource
      | V1ServiceResource
      | V1LobResource
      | V1ServiceCategoryResource
      | V1ProjectSubserviceResource
      | V1NoteResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectServicesApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
    phase?: string;
    lob?: string;
    "project-location"?: string;
    "service-type"?: "professional_services" | "managed_services";
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project service */
  include?: (
    | "project"
    | "project-location"
    | "project-phase"
    | "project-resource"
    | "resource"
    | "service"
    | "lob"
    | "service-category"
    | "project-subservices"
    | "notes"
  )[];
};
export type CreateProjectServiceApiResponse =
  /** status 201 Create Project Service response */ {
    data?: V1ProjectServiceResource;
  };
export type CreateProjectServiceApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectServiceResource;
  };
};
export type GetProjectServiceByIdApiResponse =
  /** status 200 Project Service list response */ {
    data?: V1ProjectServiceResource;
    included?: (
      | V1ProjectResource
      | V1ProjectLocationResource
      | V1ProjectPhaseResource
      | V1ProjectResourceResource
      | V1ResourceResource
      | V1ServiceResource
      | V1LobResource
      | V1ServiceCategoryResource
      | V1ProjectSubserviceResource
      | V1NoteResource
    )[];
  };
export type GetProjectServiceByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project service */
  include?: (
    | "project"
    | "project-location"
    | "project-phase"
    | "project-resource"
    | "resource"
    | "service"
    | "lob"
    | "service-category"
    | "project-subservices"
    | "notes"
  )[];
};
export type UpdateProjectServiceApiResponse =
  /** status 200 Update Project Service response */ {
    data?: V1ProjectServiceResource;
  };
export type UpdateProjectServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectServiceResource;
  };
};
export type DeleteProjectServiceApiResponse =
  /** status 204 The Delete Project Service was deleted successfully */ undefined;
export type DeleteProjectServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type SyncrhonizeStandardProjectServiceByIdApiResponse =
  /** status 200 Project Service response */ {
    data?: V1ProjectServiceResource;
  };
export type SyncrhonizeStandardProjectServiceByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CountProjectServicesApiResponse =
  /** status 200 Project services count successful */ {
    meta?: V1RecordCount;
  };
export type CountProjectServicesApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListProjectSubservicesApiResponse =
  /** status 200 Project Subservice list response */ {
    data?: V1ProjectSubserviceResource[];
    included?: (
      | V1AccountResource
      | V1ProjectResource
      | V1ProjectServiceResource
      | V1ProjectLocationResource
      | V1LobResource
      | V1PhaseResource
      | V1ProjectResourceResource
      | V1ResourceResource
      | V1ServiceCategoryResource
      | V1SubserviceResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectSubservicesApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project subservice */
  include?: (
    | "account"
    | "project"
    | "project-service"
    | "project-location"
    | "lob"
    | "phase"
    | "project-resource"
    | "resource"
    | "service-category"
    | "subservice"
  )[];
};
export type CreateProjectSubserviceApiResponse =
  /** status 201 Create Project Subservice response */ {
    data?: V1ProjectSubserviceResource;
  };
export type CreateProjectSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectSubserviceResource;
  };
};
export type GetProjectSubserviceByIdApiResponse =
  /** status 200 Project Subservice list response */ {
    data?: V1ProjectSubserviceResource;
    included?: (
      | V1AccountResource
      | V1ProjectResource
      | V1ProjectServiceResource
      | V1ProjectLocationResource
      | V1LobResource
      | V1PhaseResource
      | V1ProjectResourceResource
      | V1ResourceResource
      | V1ServiceCategoryResource
      | V1SubserviceResource
    )[];
  };
export type GetProjectSubserviceByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateProjectSubserviceApiResponse =
  /** status 200 Update Project Subservice response */ {
    data?: V1ProjectSubserviceResource;
  };
export type UpdateProjectSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectSubserviceResource;
  };
};
export type DeleteProjectSubserviceApiResponse =
  /** status 204 The Delete Project Subservice was deleted successfully */ undefined;
export type DeleteProjectSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type SyncrhonizeStandardProjectSubserviceByIdApiResponse =
  /** status 200 Project Subservice response */ {
    data?: V1ProjectSubserviceResource;
  };
export type SyncrhonizeStandardProjectSubserviceByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListProjectVariablesApiResponse =
  /** status 200 Project Variable list response */ {
    data?: V1ProjectVariableResource[];
    included?: V1AccountResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectVariablesApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    "variable-context"?:
      | "project"
      | "service_location"
      | "project_contact"
      | "product"
      | "section"
      | "service";
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project variable */
  include?: "account"[];
};
export type GetProjectVariableByIdApiResponse =
  /** status 200 Find Project Variable by ID response */ {
    data?: V1ProjectVariableResource;
    included?: V1AccountResource[];
    links?: V1Paging;
  };
export type GetProjectVariableByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each project variable */
  include?: "account"[];
};
export type ListProjectVersionsApiResponse =
  /** status 200 Project Version list response */ {
    data?: V1ProjectVersionResource[];
    included?: (V1ProjectResource | V1ApprovalStepResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListProjectVersionsApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project version */
  include?: ("project" | "approval-steps")[];
};
export type CreateProjectVersionApiResponse =
  /** status 201 Create Project Version response */ {
    data?: V1ProjectVersionResource;
  };
export type CreateProjectVersionApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ProjectVersionResource;
  };
};
export type GetProjectVersionByIdApiResponse =
  /** status 200 Project list response */ {
    data?: V1ProjectVersionResource;
    included?: (V1ProjectResource | V1ApprovalStepResource)[];
  };
export type GetProjectVersionByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateProjectVersionApiResponse =
  /** status 200 Update Project Version response */ {
    data?: V1ProjectVersionResource;
  };
export type UpdateProjectVersionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ProjectVersionResource;
  };
};
export type RevertProjectVersionApiResponse =
  /** status 204 Project state was reverted successfully */ undefined;
export type RevertProjectVersionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CountProjectVersionsApiResponse =
  /** status 200 project versions count successful */ {
    meta?: V1RecordCount;
  };
export type CountProjectVersionsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListQuestionsForAccountApiResponse =
  /** status 200 Question list response */ {
    data?: V1QuestionResource[];
    included?: (V1AccountResource | V1QuestionnaireResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListQuestionsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each question */
  include?: ("account" | "questionnaire")[];
};
export type GetQuestionByIdApiResponse =
  /** status 200 Find question by ID response */ {
    data?: V1QuestionResource;
    included?: (V1AccountResource | V1QuestionnaireResource)[];
    links?: V1Paging;
  };
export type GetQuestionByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each question */
  include?: ("account" | "questionnaire")[];
};
export type ListQuestionnairesForAccountApiResponse =
  /** status 200 Questionnaire list response */ {
    data?: V1QuestionnaireResource[];
    included?: (V1AccountResource | V1QuestionResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListQuestionnairesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    "tag-list"?: string;
    "service-id"?: string;
    "team.id"?: string[];
    active?: boolean;
    published?: boolean;
  };
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: (
    | "id"
    | "-id"
    | "active"
    | "-active"
    | "name"
    | "-name"
    | "published"
    | "-published"
    | "created-at"
    | "-created-at"
    | "slug"
    | "-slug"
    | "introduction"
    | "-introduction"
    | "thank-you"
    | "-thank-you"
    | "completion-url"
    | "-completion-url"
    | "questions"
    | "-questions"
    | "tag-list"
    | "-tag-list"
    | "teams"
    | "-teams"
  )[];
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each questionnaire */
  include?: ("account" | "questions")[];
};
export type CreateQuestionnaireApiResponse =
  /** status 201 Create Questionnaire response */ {
    data?: V1QuestionnaireResource;
  };
export type CreateQuestionnaireApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1QuestionnaireResource;
  };
};
export type GetQuestionnaireByIdApiResponse =
  /** status 200 Find questionnaire by ID response */ {
    data?: V1QuestionnaireResource;
    included?: (V1AccountResource | V1QuestionResource)[];
    links?: V1Paging;
  };
export type GetQuestionnaireByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Include related resources in the response. The only supported value is "questions". */
  include?: ("account" | "questions")[];
};
export type UpdateQuestionnaireApiResponse =
  /** status 200 Update Questionnaire response */ {
    data?: V1QuestionnaireResource;
  };
export type UpdateQuestionnaireApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1QuestionnaireResource;
  };
};
export type DeleteQuestionnaireApiResponse =
  /** status 204 The Delete Questionnaire was deleted successfully */ undefined;
export type DeleteQuestionnaireApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListQuotesForAccountApiResponse =
  /** status 200 Quote list response */ {
    data?: V1QuoteResource[];
    included?: (V1ProjectResource | V1VendorResource | V1LobResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListQuotesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each project quote */
  include?: ("project" | "vendor" | "lob")[];
};
export type CreateQuoteApiResponse = /** status 201 Create Quote response */ {
  data?: V1QuoteResource;
};
export type CreateQuoteApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1QuoteResource;
  };
};
export type GetQuoteApiResponse = /** status 200 Project response */ {
  data?: V1QuoteResource;
};
export type GetQuoteApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateQuoteApiResponse = /** status 200 Update Quote response */ {
  data?: V1QuoteResource;
};
export type UpdateQuoteApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1QuoteResource;
  };
};
export type DeleteQuoteApiResponse =
  /** status 204 The Delete Quote was deleted successfully */ undefined;
export type DeleteQuoteApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type RecalculateQuoteApiResponse =
  /** status 200 Recalculate Quote response */ {
    data?: V1QuoteResource;
  };
export type RecalculateQuoteApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type AcceptVendorDocumentForQuoteApiResponse =
  /** status 200 Quote response */ {
    data?: V1QuoteResource;
  };
export type AcceptVendorDocumentForQuoteApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CountQuotesApiResponse = /** status 200 quotes count successful */ {
  meta?: V1RecordCount;
};
export type CountQuotesApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of project to which the resource is associated */
  projectId: number;
};
export type ListRateTablesForAccountApiResponse =
  /** status 200 Rate Table list response */ {
    data?: V1RateTableResource[];
    included?: (
      | V1AccountResource
      | V1CurrencyResource
      | V1ClientResource
      | V1ProjectResource
      | V1UserResource
      | V1ResourceRateResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListRateTablesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each rate table */
  include?: (
    | "account"
    | "currency"
    | "clients"
    | "projects"
    | "users"
    | "resource-rates"
  )[];
};
export type CreateRateTableApiResponse =
  /** status 201 Create Rate Table response */ {
    data?: V1RateTableResource;
  };
export type CreateRateTableApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1RateTableResource;
  };
};
export type GetRateTableApiResponse = /** status 200 Rate table response */ {
  data?: V1RateTableResource;
};
export type GetRateTableApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateRateTableApiResponse =
  /** status 200 Update Rate table response */ {
    data?: V1RateTableResource;
  };
export type UpdateRateTableApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1RateTableResource;
  };
};
export type DeleteRateTableApiResponse =
  /** status 204 The Delete Rate Table was deleted successfully */ undefined;
export type DeleteRateTableApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type DuplicateRateTableApiResponse =
  /** status 200 Newly duplicated Rate table response */ {
    data?: V1RateTableResource;
  };
export type DuplicateRateTableApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListResourcesForAccountApiResponse =
  /** status 200 Resource list response */ {
    data?: V1ResourceResource[];
    links?: V1Paging;
  };
export type ListResourcesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  /** Associated data that may be loaded with each resource */
  include?: (
    | "account"
    | "governances"
    | "project-governances"
    | "project-resources"
    | "project-services"
    | "project-subservices"
  )[];
};
export type CreateResourceApiResponse =
  /** status 201 Create Resource response */ {
    data?: V1ResourceResource;
  };
export type CreateResourceApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ResourceResource;
  };
};
export type GetResourceByIdApiResponse =
  /** status 200 Find resource by ID response */ {
    data?: V1ResourceResource;
  };
export type GetResourceByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateResourceApiResponse =
  /** status 200 Update Resource response */ {
    data?: V1ResourceResource;
  };
export type UpdateResourceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ResourceResource;
  };
};
export type DeleteResourceApiResponse =
  /** status 204 The resource was deleted successfully */ undefined;
export type DeleteResourceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListResourcePlansApiResponse =
  /** status 200 Resource plan list response */ {
    data?: V1ResourcePlanResource[];
    included?: (V1ResourcePlanResource | V1ProjectResourceResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListResourcePlansApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    project?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each resource plan */
  include?: ("project" | "resource-allocations")[];
};
export type CreateResourcePlanApiResponse =
  /** status 201 Create Resource plan response */ {
    data?: V1ResourcePlanResource;
  };
export type CreateResourcePlanApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ResourcePlanResource;
  };
};
export type GetResourcePlanByIdApiResponse =
  /** status 200 Project list response */ {
    data?: V1ResourcePlanResource;
  };
export type GetResourcePlanByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateResourcePlanApiResponse =
  /** status 200 Update Resource plan response */ {
    data?: V1ResourcePlanResource;
  };
export type UpdateResourcePlanApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ResourcePlanResource;
  };
};
export type DeleteResourcePlanApiResponse =
  /** status 204 The Resource plan was deleted successfully */ undefined;
export type DeleteResourcePlanApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type DuplicateResourcePlanApiResponse =
  /** status 200 The Resource plan was deleted successfully */ {
    data?: V1ResourcePlanResource;
  };
export type DuplicateResourcePlanApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ResourcePlanResource;
  };
};
export type ListResourceAllocationsApiResponse =
  /** status 200 Resource allocation list response */ {
    data?: V1ResourceAllocationResource[];
    included?: (V1ResourcePlanResource | V1ProjectResourceResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListResourceAllocationsApiArg = {
  /** Slug of Account */
  slug: string;
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each resource allocation */
  include?: ("resource-plan" | "project-resource")[];
};
export type CreateResourceAllocationApiResponse =
  /** status 201 Create Resource allocation response */ {
    data?: V1ResourceAllocationResource;
  };
export type CreateResourceAllocationApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ResourceAllocationResource;
  };
};
export type GetResourceAllocationByIdApiResponse =
  /** status 200 Project list response */ {
    data?: V1ResourceAllocationResource;
  };
export type GetResourceAllocationByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateResourceAllocationApiResponse =
  /** status 200 Update Resource allocation response */ {
    data?: V1ResourceAllocationResource;
  };
export type UpdateResourceAllocationApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ResourceAllocationResource;
  };
};
export type DeleteResourceAllocationApiResponse =
  /** status 204 The Resource allocation was deleted successfully */ undefined;
export type DeleteResourceAllocationApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListResourceRatesForAccountApiResponse =
  /** status 200 Resource Rate list response */ {
    data?: V1ResourceRateResource[];
    included?: (
      | V1AccountResource
      | V1CurrencyResource
      | V1ClientResource
      | V1ProjectResource
      | V1UserResource
      | V1ResourceRateResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListResourceRatesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    resource?: string;
    "rate-table"?: string;
    active?: boolean;
    "governed-categories"?: string[];
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each resource rate */
  include?: ("rate-table" | "resource" | "line-of-business")[];
};
export type CreateResourceRateApiResponse =
  /** status 201 Create ResourceRate response */ {
    data?: V1ResourceRateResource;
  };
export type CreateResourceRateApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ResourceRateResource;
  };
};
export type GetResourceRateByIdApiResponse =
  /** status 200 Find resource rate by ID response */ {
    data?: V1ResourceRateResource;
  };
export type GetResourceRateByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateResourceRateApiResponse =
  /** status 200 Update Resource Rate response */ {
    data?: V1ResourceRateResource;
  };
export type UpdateResourceRateApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ResourceRateResource;
  };
};
export type DeleteResourceRateApiResponse =
  /** status 204 The Delete Resource Rate was deleted successfully */ undefined;
export type DeleteResourceRateApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListSalesExecutivesForAccountApiResponse =
  /** status 200 Sales executive list response */ {
    data?: V1SalesExecutiveResource[];
    included?: V1AccountResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListSalesExecutivesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    email?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each sales executive */
  include?: "account"[];
};
export type CreateSalesExecutiveApiResponse =
  /** status 201 Create Sales executive response */ {
    data?: V1SalesExecutiveResource;
  };
export type CreateSalesExecutiveApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1SalesExecutiveResource;
  };
};
export type GetSalesExecutiveByIdApiResponse =
  /** status 200 Find sales executive by ID response */ {
    data?: V1SalesExecutiveResource;
    included?: V1AccountResource[];
    links?: V1Paging;
  };
export type GetSalesExecutiveByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each sales executive */
  include?: "account"[];
};
export type UpdateSalesExecutiveApiResponse =
  /** status 200 Update Sales executive response */ {
    data?: V1SalesExecutiveResource;
  };
export type UpdateSalesExecutiveApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1SalesExecutiveResource;
  };
};
export type DeleteSalesExecutiveApiResponse =
  /** status 204 The Delete Sales executive was deleted successfully */ undefined;
export type DeleteSalesExecutiveApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListServicesForAccountApiResponse =
  /** status 200 Service list response */ {
    data?: V1ServiceResource[];
    included?: (
      | V1AccountResource
      | V1ResourceResource
      | V1PhaseResource
      | V1ServiceCategoryResource
      | V1SubserviceResource
      | V1BlueprintItemResource
      | V1ProductResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListServicesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    tag_list?: string;
    sku?: string;
    lob?: string[];
    "service-category"?: string[];
    phase?: string[];
    blueprint?: string[];
    service_type?: "professional_services" | "managed_services";
    state?: ("pending" | "active" | "deleted" | "custom")[];
    "payment-frequency"?: ("one_time" | "monthly" | "quarterly" | "yearly")[];
    survey_recommended?: boolean;
    questionnaire?: string[];
    exclude?: string;
    active?: boolean;
    "created-at.after"?: string;
    "created-at.before"?: string;
    "updated-at.after"?: string;
    "updated-at.before"?: string;
    ids?: string[];
  };
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: (
    | "name"
    | "-name"
    | "service_description"
    | "-service_description"
    | "service_type"
    | "-service_type"
    | "sku"
    | "-sku"
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "service_category.nested_name"
    | "-service_category.nested_name"
    | "phase.name"
    | "-phase.name"
  )[];
  /** Associated data that may be loaded with each service */
  include?: (
    | "account"
    | "resource"
    | "phase"
    | "service-category"
    | "subservices"
    | "blueprint-items"
    | "products"
    | "subservices.resource"
  )[];
  page?: {
    number?: number;
    size?: number;
  };
};
export type CreateServiceApiResponse =
  /** status 201 Create Service response */ {
    data?: V1ServiceResource;
  };
export type CreateServiceApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ServiceResource;
  };
};
export type GetServiceByIdApiResponse =
  /** status 200 Find service by ID response */ {
    data?: V1ServiceResource;
    included?: (
      | V1AccountResource
      | V1ResourceResource
      | V1PhaseResource
      | V1ServiceCategoryResource
      | V1SubserviceResource
      | V1BlueprintItemResource
      | V1ProductResource
    )[];
    links?: V1Paging;
  };
export type GetServiceByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each service */
  include?: (
    | "account"
    | "resource"
    | "phase"
    | "service-category"
    | "subservices"
    | "blueprint-items"
    | "products"
  )[];
};
export type UpdateServiceApiResponse =
  /** status 200 Update Service response */ {
    data?: V1ServiceResource;
  };
export type UpdateServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ServiceResource;
  };
};
export type DeleteServiceApiResponse =
  /** status 204 The Delete Service was deleted successfully */ undefined;
export type DeleteServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ActivateServiceApiResponse =
  /** status 200 Update service response */ {
    data?: V1ServiceResource;
  };
export type ActivateServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ServiceResource;
  };
};
export type RestoreServiceApiResponse =
  /** status 200 Update service response */ {
    data?: V1ServiceResource;
  };
export type RestoreServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ServiceResource;
  };
};
export type ChangeServicePositionByIdApiResponse =
  /** status 200 Update Service response */ {
    data?: V1ServiceResource;
  };
export type ChangeServicePositionByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ServiceResource;
  };
};
export type DuplicateServiceApiResponse =
  /** status 200 The service was duplicated successfully */ {
    data?: V1ServiceResource;
  };
export type DuplicateServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ServiceResource;
  };
};
export type DownloadServiceApiResponse = unknown;
export type DownloadServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type DownloadListOfServicesApiResponse = unknown;
export type DownloadListOfServicesApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    ids?: string[];
  };
};
export type ListSubservicesForAccountApiResponse =
  /** status 200 Subservice list response */ {
    data?: V1SubserviceResource[];
    included?: (V1ResourceResource | V1ServiceResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListSubservicesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    state?: "pending" | "active" | "deleted" | "custom";
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each subservice */
  include?: ("resource" | "service")[];
};
export type CreateSubserviceApiResponse =
  /** status 201 Create Subservice response */ {
    data?: V1SubserviceResource;
  };
export type CreateSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1SubserviceResource;
  };
};
export type GetSubserviceByIdApiResponse =
  /** status 200 Find subservice by ID response */ {
    data?: V1SubserviceResource;
    included?: (V1ResourceResource | V1ServiceResource)[];
    links?: V1Paging;
  };
export type GetSubserviceByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each subservice */
  include?: ("resource" | "service")[];
};
export type UpdateSubserviceApiResponse =
  /** status 200 Update Subservice response */ {
    data?: V1SubserviceResource;
  };
export type UpdateSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1SubserviceResource;
  };
};
export type DeleteSubserviceApiResponse =
  /** status 204 The Delete Subservice was deleted successfully */ undefined;
export type DeleteSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ActivateSubserviceApiResponse =
  /** status 200 Update subservice response */ {
    data?: V1SubserviceResource;
  };
export type ActivateSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1SubserviceResource;
  };
};
export type ChangeSubservicePositionByIdApiResponse =
  /** status 200 Update Subservice response */ {
    data?: V1SubserviceResource;
  };
export type ChangeSubservicePositionByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1SubserviceResource;
  };
};
export type ListServiceCategorysForAccountApiResponse =
  /** status 200 Service Category list response */ {
    data?: V1ServiceCategoryResource[];
    included?: V1AccountResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListServiceCategorysForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    lob?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each service category */
  include?: "account"[];
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: (
    | "id"
    | "-id"
    | "active"
    | "-active"
    | "name"
    | "-name"
    | "nested-name"
    | "-nested-name"
    | "position"
    | "-position"
    | "code"
    | "-code"
    | "has-parent?"
    | "-has-parent?"
    | "lob-id"
    | "-lob-id"
  )[];
};
export type GetServiceCategoryApiResponse =
  /** status 200 Service Category response */ {
    data?: V1ServiceCategoryResource;
    included?: V1AccountResource[];
    links?: V1Paging;
  };
export type GetServiceCategoryApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListServiceConditionsForAccountApiResponse =
  /** status 200 ServiceCondition list response */ {
    data?: V1ServiceConditionResource[];
    included?: V1AccountResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListServiceConditionsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each service condition */
  include?: "account"[];
};
export type CreateServiceConditionApiResponse =
  /** status 201 Create Service Condition response */ {
    data?: V1ServiceConditionResource;
  };
export type CreateServiceConditionApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ServiceConditionResource;
  };
};
export type GetServiceConditionApiResponse =
  /** status 200 Service response */ {
    data?: V1ServiceConditionResource;
    included?: V1AccountResource[];
    links?: V1Paging;
  };
export type GetServiceConditionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each service condition */
  include?: "account"[];
};
export type UpdateServiceConditionApiResponse =
  /** status 200 Update ServiceCondition response */ {
    data?: V1ServiceConditionResource;
  };
export type UpdateServiceConditionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ServiceConditionResource;
  };
};
export type DeleteServiceConditionApiResponse =
  /** status 204 The Service Condition was deleted successfully */ undefined;
export type DeleteServiceConditionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListSubcontractorsForAccountApiResponse =
  /** status 200 Subcontractor list response */ {
    data?: V1SubcontractorResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListSubcontractorsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  page?: {
    number?: number;
    size?: number;
  };
};
export type GetSubcontractorApiResponse =
  /** status 200 Subcontractor Project response */ {
    data?: V1SubcontractorResource;
    links?: V1Paging;
  };
export type GetSubcontractorApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListSurveysForAccountApiResponse =
  /** status 200 Survey list response */ {
    data?: V1SurveyResource[];
    included?: (
      | V1AccountResource
      | V1QuestionnaireResource
      | V1ProjectResource
      | V1SurveyResponseResource
      | V1SurveyRecommendationResource
    )[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListSurveysForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    status?: ("pending" | "shared" | "completed" | "applied" | "revised")[];
    name?: string;
    "questionnaire-id"?: string[];
    "project-id"?: string[];
    "tag-list"?: string[];
    "contact-name"?: string;
    "created-at.after"?: string;
    "created-at.before"?: string;
    "updated-at.after"?: string;
    "updated-at.before"?: string;
    "sender-id"?: string;
    emails?: string;
    shared?: boolean;
  };
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: (
    | "id"
    | "-id"
    | "name"
    | "-name"
    | "contact_name"
    | "-contact_name"
    | "contact_email"
    | "-contact_email"
    | "responses"
    | "-responses"
    | "recommendations"
    | "-recommendations"
    | "updated_at"
    | "-updated_at"
    | "created_at"
    | "-created_at"
    | "status"
    | "-status"
    | "emails"
    | "-emails"
    | "sender"
    | "-sender"
    | "sent_at"
    | "-sent_at"
    | "calculations"
    | "-calculations"
    | "user_id"
    | "-user_id"
    | "completed_by"
    | "-completed_by"
    | "project.project_name"
    | "-project.project_name"
  )[];
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each survey */
  include?: (
    | "account"
    | "questionnaire"
    | "project"
    | "survey-responses"
    | "survey-recommendations"
  )[];
};
export type CreateSurveyApiResponse = /** status 201 Create Survey response */ {
  data?: V1SurveyResource;
};
export type CreateSurveyApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1SurveyResource;
  };
};
export type GetSurveyByIdApiResponse =
  /** status 200 Find survey by ID response */ {
    data?: V1SurveyResource;
    included?: (
      | V1AccountResource
      | V1QuestionnaireResource
      | V1ProjectResource
      | V1SurveyResponseResource
      | V1SurveyRecommendationResource
    )[];
  };
export type GetSurveyByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each survey */
  include?: (
    | "account"
    | "questionnaire"
    | "project"
    | "survey-responses"
    | "survey-recommendations"
  )[];
};
export type UpdateSurveyApiResponse = /** status 200 Update Survey response */ {
  data?: V1SurveyResource;
};
export type UpdateSurveyApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1SurveyResource;
  };
};
export type DeleteSurveyApiResponse =
  /** status 204 The Delete Survey was deleted successfully */ undefined;
export type DeleteSurveyApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type CalculateSurveyRecommendationsApiResponse =
  /** status 200 Survey with calculated recommendations */ {
    data?: V1SurveyResource;
  };
export type CalculateSurveyRecommendationsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ApplySurveyRecommendationsApiResponse =
  /** status 200 Survey with applied recommendations */ {
    data?: V1SurveyResource;
  };
export type ApplySurveyRecommendationsApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ShareSurveyApiResponse =
  /** status 201 Survey successfully shared and a shared_survey record was created. */ {
    data?: string;
  };
export type ShareSurveyApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: {
      emails?: string[];
    };
  };
};
export type ListSurveyResponsesForAccountApiResponse =
  /** status 200 Survey Response list response */ {
    data?: V1SurveyResponseResource[];
    included?: (V1SurveyResource | V1QuestionResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListSurveyResponsesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    survey?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each client */
  include?: ("survey" | "source")[];
};
export type CreateSurveyResponseApiResponse =
  /** status 201 Create Survey Response response */ {
    data?: V1SurveyResponseResource;
  };
export type CreateSurveyResponseApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1SurveyResponseResource;
  };
};
export type GetSurveyResponseByIdApiResponse =
  /** status 200 Find survey response by ID response */ {
    data?: V1SurveyResponseResource;
    included?: (V1SurveyResource | V1QuestionResource)[];
    links?: V1Paging;
  };
export type GetSurveyResponseByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each client */
  include?: ("survey" | "source")[];
};
export type UpdateSurveyResponseApiResponse =
  /** status 200 Update Survey Response response */ {
    data?: V1SurveyResponseResource;
  };
export type UpdateSurveyResponseApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1SurveyResponseResource;
  };
};
export type DeleteSurveyResponseApiResponse =
  /** status 204 The Delete Survey Response was deleted successfully */ undefined;
export type DeleteSurveyResponseApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListSurveyRecommendationsForAccountApiResponse =
  /** status 200 Survey list response */ {
    data?: V1SurveyRecommendationResource[];
    included?: (V1SurveyResource | V1ResourceResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListSurveyRecommendationsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    survey?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each survey */
  include?: ("survey" | "resource")[];
};
export type GetSurveyRecommendationByIdApiResponse =
  /** status 200 Find survey recommendation by ID response */ {
    data?: V1SurveyRecommendationResource;
    included?: (V1SurveyResource | V1ResourceResource)[];
  };
export type GetSurveyRecommendationByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each survey */
  include?: ("survey" | "resource")[];
};
export type UpdateSurveyRecommendationApiResponse =
  /** status 200 Update Survey response */ {
    data?: V1SurveyRecommendationResource;
  };
export type UpdateSurveyRecommendationApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1SurveyRecommendationResource;
  };
};
export type DeleteSurveyRecommendationApiResponse =
  /** status 204 The Delete Survey was deleted successfully */ undefined;
export type DeleteSurveyRecommendationApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ApplySurveyRecommendationApiResponse =
  /** status 200 Survey with calculated recommendations */ {
    data?: V1SurveyRecommendationResource;
  };
export type ApplySurveyRecommendationApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListTagsForAccountApiResponse =
  /** status 200 Tag list response */ {
    data?: V1TagResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListTagsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  page?: {
    number?: number;
    size?: number;
  };
  filter?: {
    name?: string;
  };
};
export type GetTagApiResponse = /** status 200 Project response */ {
  data?: V1TagResource;
};
export type GetTagApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type GetServiceLevelsOfEffortApiResponse =
  /** status 200 Service levels of effort retreived successfully */ {
    data?: V1LevelOfEffortResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type GetServiceLevelsOfEffortApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of service to which the resource is associated */
  serviceId: number;
  page?: {
    number?: number;
    size?: number;
  };
};
export type CreateLevelOfEffortForServiceApiResponse =
  /** status 201 Create level of effort response */ {
    data?: V1LevelOfEffortResource;
  };
export type CreateLevelOfEffortForServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of service to which the resource is associated */
  serviceId: number;
  body: {
    data?: V1LevelOfEffortResource;
  };
};
export type GetServiceLevelOfEffortApiResponse =
  /** status 200 Level of effort retreived successfully */ {
    data?: V1LevelOfEffortResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type GetServiceLevelOfEffortApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of service to which the resource is associated */
  serviceId: number;
  /** ID of record */
  id: number;
  page?: {
    number?: number;
    size?: number;
  };
};
export type UpdateLevelOfEffortForServiceApiResponse =
  /** status 201 Create level of effort response */ {
    data?: V1LevelOfEffortResource;
  };
export type UpdateLevelOfEffortForServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of service to which the resource is associated */
  serviceId: number;
  /** ID of record */
  id: number;
  body: {
    data?: V1LevelOfEffortResource;
  };
};
export type DeleteLevelOfEffortFromServiceApiResponse =
  /** status 204 The level of effort was deleted successfully */ undefined;
export type DeleteLevelOfEffortFromServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of service to which the resource is associated */
  serviceId: number;
  /** ID of record */
  id: number;
};
export type GetSubserviceLevelsOfEffortApiResponse =
  /** status 200 Subservice levels of effort retreived successfully */ {
    data?: V1LevelOfEffortResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type GetSubserviceLevelsOfEffortApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of subservice to which the resource is associated */
  subserviceId: number;
  page?: {
    number?: number;
    size?: number;
  };
};
export type CreateLevelOfEffortForSubserviceApiResponse =
  /** status 201 Create level of effort response */ {
    data?: V1LevelOfEffortResource;
  };
export type CreateLevelOfEffortForSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of subservice to which the resource is associated */
  subserviceId: number;
  body: {
    data?: V1LevelOfEffortResource;
  };
};
export type GetSubserviceLevelOfEffortApiResponse =
  /** status 200 Level of effort retreived successfully */ {
    data?: V1LevelOfEffortResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type GetSubserviceLevelOfEffortApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of subservice to which the resource is associated */
  subserviceId: number;
  /** ID of record */
  id: number;
  page?: {
    number?: number;
    size?: number;
  };
};
export type UpdateLevelOfEffortForSubserviceApiResponse =
  /** status 201 Create level of effort response */ {
    data?: V1LevelOfEffortResource;
  };
export type UpdateLevelOfEffortForSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of subservice to which the resource is associated */
  subserviceId: number;
  /** ID of record */
  id: number;
  body: {
    data?: V1LevelOfEffortResource;
  };
};
export type DeleteLevelOfEffortFromSubserviceApiResponse =
  /** status 204 The level of effort was deleted successfully */ undefined;
export type DeleteLevelOfEffortFromSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of subservice to which the resource is associated */
  subserviceId: number;
  /** ID of record */
  id: number;
};
export type GetServiceTaskRatesApiResponse =
  /** status 200 Service task rates retreived successfully */ {
    data?: V1TaskRateResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type GetServiceTaskRatesApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of service to which the resource is associated */
  serviceId: number;
  page?: {
    number?: number;
    size?: number;
  };
};
export type CreateTaskRateForServiceApiResponse =
  /** status 201 Create task rate response */ {
    data?: V1TaskRateResource;
  };
export type CreateTaskRateForServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of service to which the resource is associated */
  serviceId: number;
  body: {
    data?: V1TaskRateResource;
  };
};
export type GetServiceTaskRateApiResponse =
  /** status 200 Task rate retreived successfully */ {
    data?: V1TaskRateResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type GetServiceTaskRateApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of service to which the resource is associated */
  serviceId: number;
  /** ID of record */
  id: number;
  page?: {
    number?: number;
    size?: number;
  };
};
export type UpdateTaskRateForServiceApiResponse =
  /** status 201 Create task rate response */ {
    data?: V1TaskRateResource;
  };
export type UpdateTaskRateForServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of service to which the resource is associated */
  serviceId: number;
  /** ID of record */
  id: number;
  body: {
    data?: V1TaskRateResource;
  };
};
export type DeleteTaskRateFromServiceApiResponse =
  /** status 204 The task rate was deleted successfully */ undefined;
export type DeleteTaskRateFromServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of service to which the resource is associated */
  serviceId: number;
  /** ID of record */
  id: number;
};
export type GetSubserviceTaskRatesApiResponse =
  /** status 200 Subservice task rates retreived successfully */ {
    data?: V1TaskRateResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type GetSubserviceTaskRatesApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of subservice to which the resource is associated */
  subserviceId: number;
  page?: {
    number?: number;
    size?: number;
  };
};
export type CreateTaskRateForSubserviceApiResponse =
  /** status 201 Create task rate response */ {
    data?: V1TaskRateResource;
  };
export type CreateTaskRateForSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of subservice to which the resource is associated */
  subserviceId: number;
  body: {
    data?: V1TaskRateResource;
  };
};
export type GetSubserviceTaskRateApiResponse =
  /** status 200 Task rate retreived successfully */ {
    data?: V1TaskRateResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type GetSubserviceTaskRateApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of subservice to which the resource is associated */
  subserviceId: number;
  /** ID of record */
  id: number;
  page?: {
    number?: number;
    size?: number;
  };
};
export type UpdateTaskRateForSubserviceApiResponse =
  /** status 201 Create task rate response */ {
    data?: V1TaskRateResource;
  };
export type UpdateTaskRateForSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of subservice to which the resource is associated */
  subserviceId: number;
  /** ID of record */
  id: number;
  body: {
    data?: V1TaskRateResource;
  };
};
export type DeleteTaskRateFromSubserviceApiResponse =
  /** status 204 The task rate was deleted successfully */ undefined;
export type DeleteTaskRateFromSubserviceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of subservice to which the resource is associated */
  subserviceId: number;
  /** ID of record */
  id: number;
};
export type ListTeamsForAccountApiResponse =
  /** status 200 Team list response */ {
    data?: V1TeamResource[];
    included?: (V1AccountResource | V1UserResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListTeamsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each team */
  include?: ("account" | "users")[];
  filter?: {
    name?: string;
  };
};
export type CreateTeamApiResponse = /** status 201 Create Team response */ {
  data?: V1TeamResource;
};
export type CreateTeamApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1TeamResource;
  };
};
export type GetTeamApiResponse = /** status 200 Project response */ {
  data?: V1TeamResource;
  included?: (V1AccountResource | V1UserResource)[];
  links?: V1Paging;
};
export type GetTeamApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each team */
  include?: ("account" | "users")[];
};
export type UpdateTeamApiResponse = /** status 200 Update Team response */ {
  data?: V1TeamResource;
};
export type UpdateTeamApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1TeamResource;
  };
};
export type DeleteTeamApiResponse =
  /** status 204 The Team was deleted successfully */ undefined;
export type DeleteTeamApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListTermsForAccountApiResponse =
  /** status 200 Term list response */ {
    data?: V1TermResource[];
    included?: (V1PaymentTermResource | V1PhaseResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListTermsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each term */
  include?: ("payment-term" | "phase")[];
};
export type CreateTermApiResponse = /** status 201 Create Term response */ {
  data?: V1TermResource;
};
export type CreateTermApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1TermResource;
  };
};
export type GetTermApiResponse = /** status 200 Project response */ {
  data?: V1TermResource;
  included?: (V1PaymentTermResource | V1PhaseResource)[];
  links?: V1Paging;
};
export type GetTermApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each term */
  include?: ("payment-term" | "phase")[];
};
export type UpdateTermApiResponse = /** status 200 Update Term response */ {
  data?: V1TermResource;
};
export type UpdateTermApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1TermResource;
  };
};
export type DeleteTermApiResponse =
  /** status 204 The Delete Term was deleted successfully */ undefined;
export type DeleteTermApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListThirdPartyServicesForAccountApiResponse =
  /** status 200 ThirdPartyService list response */ {
    data?: V1ThirdPartyServiceResource[];
    included?: V1ProjectPhaseResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListThirdPartyServicesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    source_type?: "Quote" | "ThirdPartyRequest" | "ThirdPartyService";
    source_id?: string;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each third party service */
  include?: ("source" | "project-phase" | "project-task" | "subtasks")[];
};
export type CreateThirdPartyServiceApiResponse =
  /** status 201 Create ThirdPartyService response */ {
    data?: V1ThirdPartyServiceResource;
  };
export type CreateThirdPartyServiceApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1ThirdPartyServiceResource;
  };
};
export type GetThirdPartyServiceApiResponse =
  /** status 200 Project response */ {
    data?: V1ThirdPartyServiceResource;
    included?: V1ProjectPhaseResource[];
    links?: V1Paging;
  };
export type GetThirdPartyServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each third party service */
  include?: ("source" | "project-phase" | "project-task" | "subtasks")[];
};
export type UpdateThirdPartyServiceApiResponse =
  /** status 200 Update Third Party Service response */ {
    data?: V1ThirdPartyServiceResource;
  };
export type UpdateThirdPartyServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ThirdPartyServiceResource;
  };
};
export type DeleteThirdPartyServiceApiResponse =
  /** status 204 The Delete Third Party Service was deleted successfully */ undefined;
export type DeleteThirdPartyServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type MoveThirdPartyServiceApiResponse =
  /** status 200 Move Third Party Service response */ {
    data?: V1ThirdPartyServiceResource;
  };
export type MoveThirdPartyServiceApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1ThirdPartyServiceResource;
  };
};
export type ListUsersForAccountApiResponse =
  /** status 200 User list response */ {
    data?: V1UserResource[];
    included?: (V1AccountResource | V1RateTableResource | V1TeamResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListUsersForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    email?: string;
    exclude?: string;
    "view-only"?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each user */
  include?: ("account" | "rate-table" | "teams")[];
};
export type CreateUserApiResponse = /** status 201 Create User response */ {
  data?: V1UserResource;
};
export type CreateUserApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1UserResourceWrite;
  };
};
export type ShowCurrentUserForAccountApiResponse =
  /** status 200 Current User response */ {
    data?: V1UserResource[];
    included?: (V1AccountResource | V1RateTableResource | V1TeamResource)[];
    links?: V1Paging;
  };
export type ShowCurrentUserForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  /** Associated data that may be loaded with each user */
  include?: ("account" | "rate-table" | "teams")[];
};
export type GetUserApiResponse = /** status 200 Project response */ {
  data?: V1UserResource;
  included?: (V1AccountResource | V1RateTableResource | V1TeamResource)[];
  links?: V1Paging;
};
export type GetUserApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateUserApiResponse = /** status 200 Update User response */ {
  data?: V1UserResource;
};
export type UpdateUserApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1UserResourceWrite;
  };
};
export type DeleteUserApiResponse =
  /** status 204 The Delete User was deleted successfully */ undefined;
export type DeleteUserApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListVendorsForAccountApiResponse =
  /** status 200 Vendor list response */ {
    data?: V1VendorResource[];
    included?: (V1AccountResource | V1QuoteResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListVendorsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    active?: boolean;
  };
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each vendor */
  include?: ("account" | "quotes")[];
};
export type CreateVendorApiResponse = /** status 201 Create Vendor response */ {
  data?: V1VendorResource;
};
export type CreateVendorApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1VendorResource;
  };
};
export type GetVendorApiResponse = /** status 200 Project response */ {
  data?: V1VendorResource;
};
export type GetVendorApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateVendorApiResponse = /** status 200 Update Vendor response */ {
  data?: V1VendorResource;
};
export type UpdateVendorApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1VendorResource;
  };
};
export type DeleteVendorApiResponse =
  /** status 204 The Delete Vendor was deleted successfully */ undefined;
export type DeleteVendorApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListWebhookSubscriptionsForAccountApiResponse =
  /** status 200 Webhook Subscription list response */ {
    data?: V1WebhookSubscriptionResource[];
    included?: V1AccountResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListWebhookSubscriptionsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  /** Associated data that may be loaded with each webhook subscription */
  include?: "account"[];
  page?: {
    number?: number;
    size?: number;
  };
};
export type CreateWebhookSubscriptionApiResponse =
  /** status 201 Create Webhook Subscription response */ {
    data?: V1WebhookSubscriptionResource;
  };
export type CreateWebhookSubscriptionApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1WebhookSubscriptionResource;
  };
};
export type GetWebhookSubscriptionApiResponse =
  /** status 200 Project response */ {
    data?: V1WebhookSubscriptionResource;
    included?: V1AccountResource[];
    links?: V1Paging;
  };
export type GetWebhookSubscriptionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each webhook subscription */
  include?: "account"[];
};
export type UpdateWebhookSubscriptionApiResponse =
  /** status 200 Update Webhook Subscription response */ {
    data?: V1WebhookSubscriptionResource;
  };
export type UpdateWebhookSubscriptionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1WebhookSubscriptionResource;
  };
};
export type DeleteWebhookSubscriptionApiResponse =
  /** status 204 The Delete Webhook Subscription was deleted successfully */ undefined;
export type DeleteWebhookSubscriptionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListAccountConnectionsApiResponse =
  /** status 200 Acount Connection response */ {
    data?: V1AccountConnectionResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListAccountConnectionsApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    active?: boolean;
    products?: boolean;
    crm?: boolean;
  };
};
export type CreateAccountConnectionApiResponse =
  /** status 201 Create Account Connection response */ {
    data?: V1AccountConnectionResource;
  };
export type CreateAccountConnectionApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1AccountConnectionResource;
  };
};
export type GetAccountConnectionApiResponse =
  /** status 200 Project response */ {
    data?: V1AccountConnectionResource;
    included?: V1AccountResource[];
  };
export type GetAccountConnectionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each account-connection */
  include?: "account"[];
};
export type UpdateAccountConnectionApiResponse =
  /** status 200 Update Account Connection response */ {
    data?: V1AccountConnectionResource;
  };
export type UpdateAccountConnectionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1AccountConnectionResource;
  };
};
export type DeleteAccountConnectionApiResponse =
  /** status 204 The Delete Account Connection was deleted successfully */ undefined;
export type DeleteAccountConnectionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ReconnectAccountConnectionApiResponse =
  /** status 200 Update Account Connection response */ {
    data?: V1AccountConnectionResource;
  };
export type ReconnectAccountConnectionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1AccountConnectionResource;
  };
};
export type EnableAccountConnectionApiResponse =
  /** status 200 Update Account Connection response */ {
    data?: V1AccountConnectionResource;
  };
export type EnableAccountConnectionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1AccountConnectionResource;
  };
};
export type DisableAccountConnectionApiResponse =
  /** status 200 Update Account Connection response */ {
    data?: V1AccountConnectionResource;
  };
export type DisableAccountConnectionApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1AccountConnectionResource;
  };
};
export type ListContentTemplatesForAccountApiResponse =
  /** status 200 Content Template list response */ {
    data?: V1ContentTemplateResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListContentTemplatesForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    name?: string;
    "template-type"?: ("service" | "blueprint" | "survey")[];
  };
  /** Names of attributes that should be used to sort the data.  Prefix "-" indicates descending */
  sort?: (
    | "id"
    | "-id"
    | "name"
    | "-name"
    | "description"
    | "-description"
    | "template-type"
    | "-template-type"
  )[];
  page?: {
    number?: number;
    size?: number;
  };
};
export type GetContentTemplateApiResponse = /** status 200 Project response */ {
  data?: V1ContentTemplateResource;
};
export type GetContentTemplateApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ImportContentTemplateApiResponse =
  /** status 200 Project response */ {
    data?: V1ServiceResource[];
    included?: (V1PhaseResource | V1ResourceResource)[];
  };
export type ImportContentTemplateApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListIntegrationLogsForAccountApiResponse =
  /** status 200 IntegrationLog list response */ {
    data?: V1IntegrationLogResource[];
    included?: (V1AccountResource | V1AccountConnectionResource)[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListIntegrationLogsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  accountConnectionId: string;
  page?: {
    number?: number;
    size?: number;
  };
};
export type GetIntegrationLogApiResponse = /** status 200 Project response */ {
  data?: V1IntegrationLogResource;
  included?: (V1AccountResource | V1AccountConnectionResource)[];
};
export type GetIntegrationLogApiArg = {
  /** Slug of Account */
  slug: string;
  accountConnectionId: string;
  /** ID of record */
  id: number;
};
export type GetAccountFinancialsApiResponse =
  /** status 200 Project response */ {
    data?: V1AccountFinancialResource;
  };
export type GetAccountFinancialsApiArg = {
  /** Slug of Account */
  slug: string;
};
export type ListDataImportsForAccountApiResponse =
  /** status 200 Data import list response */ {
    data?: V1DataImportResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListDataImportsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
};
export type CreateDataImportApiResponse =
  /** status 201 Create data import response */ {
    data?: V1DataImportResource;
  };
export type CreateDataImportApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1DataImportResource;
  };
};
export type GetDataImportByIdApiResponse = /** status 200 Project response */ {
  data?: V1DataImportResource;
};
export type GetDataImportByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListFreeTrialsForAccountApiResponse =
  /** status 200 Free Trial list response */ {
    data?: V1FreeTrialResource[];
    included?: V1AccountResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListFreeTrialsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  page?: {
    number?: number;
    size?: number;
  };
  /** Associated data that may be loaded with each client */
  include?: "account"[];
};
export type CreateFreeTrialApiResponse =
  /** status 201 Create Free Trial response */ {
    data?: V1FreeTrialResource;
  };
export type CreateFreeTrialApiArg = {
  /** Slug of Account */
  slug: string;
};
export type GetFreeTrialApiResponse = /** status 200 Project response */ {
  data?: V1FreeTrialResource;
  included?: V1AccountResource[];
};
export type GetFreeTrialApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each client */
  include?: "account"[];
};
export type CancelFreeTrialApiResponse =
  /** status 204 The Free trial was canceled successfully */ undefined;
export type CancelFreeTrialApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListLandingPageContentApiResponse =
  /** status 200 Landing Page Content list response */ {
    data?: V1LandingPageContentResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListLandingPageContentApiArg = {
  filter?: {
    stage?: "free" | "essentials" | "business" | "premium";
  };
  page?: {
    number?: number;
    size?: number;
  };
};
export type ListSubscriptionsForAccountApiResponse =
  /** status 200 Subscription list response */ {
    data?: V1SubscriptionResource[];
  };
export type ListSubscriptionsForAccountApiArg = void;
export type CreateSubscriptionApiResponse =
  /** status 201 Create Subscription response */ {
    data?: V1SubscriptionResource;
  };
export type CreateSubscriptionApiArg = {
  body: {
    data?: V1SubscriptionResource;
  };
};
export type GetSubscriptionApiResponse = /** status 200 Project response */ {
  data?: V1SubscriptionResource;
};
export type GetSubscriptionApiArg = {
  /** ID of record */
  id: number;
};
export type UpdateSubscriptionApiResponse =
  /** status 200 Update Subscription response */ {
    data?: V1SubscriptionResource;
  };
export type UpdateSubscriptionApiArg = {
  /** ID of record */
  id: number;
  body: {
    data?: V1SubscriptionResource;
  };
};
export type DeleteSubscriptionApiResponse =
  /** status 204 The Delete Subscription was deleted successfully */ undefined;
export type DeleteSubscriptionApiArg = {
  /** ID of record */
  id: number;
};
export type GetBySlugV1UserFavoritesApiResponse =
  /** status 200 User Favorite list response */ {
    data?: V1UserFavoriteResource[];
    links?: V1Paging;
  };
export type GetBySlugV1UserFavoritesApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    type?: string;
  };
};
export type CreateUserFavoriteApiResponse =
  /** status 201 Create user favorite response */ {
    data?: V1UserFavoriteResource;
  };
export type CreateUserFavoriteApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1UserFavoriteResource;
  };
};
export type DeleteUserFavoriteApiResponse =
  /** status 204 The Delete user favorite was deleted successfully */ undefined;
export type DeleteUserFavoriteApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListUserFiltersForAccountApiResponse =
  /** status 200 User Filter list response */ {
    data?: V1UserFilterResource[];
    links?: V1Paging;
  };
export type ListUserFiltersForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    "data-type"?: string;
    "data-path"?: string;
    active?: boolean;
  };
};
export type CreateUserFilterApiResponse =
  /** status 201 Create User Filter response */ {
    data?: V1UserFilterResource;
  };
export type CreateUserFilterApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1UserFilterResource;
  };
};
export type GetUserFilterApiResponse = /** status 200 Project response */ {
  data?: V1UserFilterResource;
  links?: V1Paging;
};
export type GetUserFilterApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type UpdateUserFilterApiResponse =
  /** status 200 Update User Filter response */ {
    data?: V1UserFilterResource;
  };
export type UpdateUserFilterApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1UserFilterResource;
  };
};
export type DeleteUserFilterApiResponse =
  /** status 204 The Delete User Filter was deleted successfully */ undefined;
export type DeleteUserFilterApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type ListUserViewsForAccountApiResponse =
  /** status 200 User View list response */ {
    data?: V1UserViewResource[];
    included?: (V1UserResource | V1AccountResource)[];
    links?: V1Paging;
  };
export type ListUserViewsForAccountApiArg = {
  /** Slug of Account */
  slug: string;
  filter?: {
    "view-type"?: "project" | "product";
    products?: boolean;
  };
  /** Associated data that may be loaded with each user view */
  include?: ("user" | "account")[];
};
export type CreateUserViewApiResponse =
  /** status 201 Create User View response */ {
    data?: V1UserViewResource;
  };
export type CreateUserViewApiArg = {
  /** Slug of Account */
  slug: string;
  body: {
    data?: V1UserViewResource;
  };
};
export type GetUserViewApiResponse = /** status 200 Project response */ {
  data?: V1UserViewResource;
  included?: (V1UserResource | V1AccountResource)[];
  links?: V1Paging;
};
export type GetUserViewApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  /** Associated data that may be loaded with each user view */
  include?: ("user" | "account")[];
};
export type UpdateUserViewApiResponse =
  /** status 200 Update User View response */ {
    data?: V1UserViewResource;
  };
export type UpdateUserViewApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
  body: {
    data?: V1UserViewResource;
  };
};
export type DeleteUserViewApiResponse =
  /** status 204 The Delete User View was deleted successfully */ undefined;
export type DeleteUserViewApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: number;
};
export type GetSharedSurveyByIdApiResponse =
  /** status 200 Find survey by ID response */ {
    data?: V1SharedSurveyResource;
    included?: (
      | V1SurveyResource
      | V1QuestionnaireResource
      | V1QuestionResource
    )[];
  };
export type GetSharedSurveyByIdApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: string;
  /** Associated data that may be loaded with each survey */
  include?: ("survey" | "questionnaire" | "questions")[];
};
export type UpdateSharedSurveyApiResponse =
  /** status 200 Update Shared Survey response */ {
    data?: V1SharedSurveyResource;
  };
export type UpdateSharedSurveyApiArg = {
  /** Slug of Account */
  slug: string;
  /** ID of record */
  id: string;
  body: {
    data?: V1SharedSurveyResource;
  };
};
export type ListConnectedAppApiResponse =
  /** status 200 ConnectedApps collection response */ {
    data?: V1ConnectedAppResource[];
    meta?: V1PagingMeta;
    links?: V1Paging;
  };
export type ListConnectedAppApiArg = {
  /** Slug of Account */
  slug: string;
};
export type CreateAccountApiResponse = /** status 201 Account response */ {
  data?: V1AccountResource;
  included?: (
    | V1BusinessUnitResource
    | V1ClientResource
    | V1CrmOpportunityResource
    | V1DocumentTemplateResource
    | V1GovernanceResource
    | V1LineOfBusinessResource
    | V1PaymentCreditResource
    | V1PaymentTermResource
    | V1PhaseResource
    | V1ProductResource
    | V1ProjectVariableResource
    | V1ProjectResource
    | V1RateTableResource
    | V1ResourceResource
    | V1ServiceResource
    | V1TeamResource
    | V1UserResource
  )[];
};
export type CreateAccountApiArg = {
  body: {
    data?: V1AccountResource;
  };
};
export type V1MeResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    email?: string;
    phone?: string;
    title?: string;
    "account-slug"?: string;
    "account-id"?: string;
    privileges?: {
      privilege?: string;
      "access-level"?: "none" | "view" | "create" | "manage";
    }[];
    "site-admin"?: boolean;
  };
};
export type V1Paging = {
  first?: string;
  next?: string;
  last?: string;
};
export type V1AuthenticationError = {
  error?: string;
};
export type V1ToManyRelationships = {
  links?: {
    self?: string;
    related?: string;
  };
  data?: {
    id?: number;
    type?: string;
  }[];
};
export type V1AccountResource = {
  id?: string;
  type?: string;
  attributes?: {
    slug: string;
    "account-id"?: number;
    name: string;
    status?: "building" | "pending" | "trial" | "active" | "canceled";
    "rate-type"?: "hour" | "day" | "week";
    "loe-rounding"?:
      | "one_minute"
      | "five_minutes"
      | "tenth_of_an_hour"
      | "ten_minutes"
      | "quarter_hour"
      | "half_hour"
      | "one_hour";
    "short-date-format"?:
      | "%m/%d/%Y"
      | "%m-%d-%Y"
      | "%d/%m/%Y"
      | "%d-%m/%Y"
      | "%Y/%m/%d"
      | "%Y-%m-%d"
      | "%d-%b-%Y";
    "long-date-format"?: "%B %-e, %Y" | "%-e %B %Y" | "%Y %B %-e";
    "time-format"?: "%-l:%M %p" | "%l:%M %p" | "%H:%M";
    street_adddress?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    country?: string;
    "time-zone"?:
      | "International Date Line West"
      | "Midway Island"
      | "American Samoa"
      | "Hawaii"
      | "Alaska"
      | "Pacific Time (US & Canada)"
      | "Tijuana"
      | "Mountain Time (US & Canada)"
      | "Arizona"
      | "Chihuahua"
      | "Mazatlan"
      | "Central Time (US & Canada)"
      | "Saskatchewan"
      | "Guadalajara"
      | "Mexico City"
      | "Monterrey"
      | "Central America"
      | "Eastern Time (US & Canada)"
      | "Indiana (East)"
      | "Bogota"
      | "Lima"
      | "Quito"
      | "Atlantic Time (Canada)"
      | "Caracas"
      | "La Paz"
      | "Santiago"
      | "Newfoundland"
      | "Brasilia"
      | "Buenos Aires"
      | "Montevideo"
      | "Georgetown"
      | "Puerto Rico"
      | "Greenland"
      | "Mid-Atlantic"
      | "Azores"
      | "Cape Verde Is."
      | "Dublin"
      | "Edinburgh"
      | "Lisbon"
      | "London"
      | "Casablanca"
      | "Monrovia"
      | "UTC"
      | "Belgrade"
      | "Bratislava"
      | "Budapest"
      | "Ljubljana"
      | "Prague"
      | "Sarajevo"
      | "Skopje"
      | "Warsaw"
      | "Zagreb"
      | "Brussels"
      | "Copenhagen"
      | "Madrid"
      | "Paris"
      | "Amsterdam"
      | "Berlin"
      | "Bern"
      | "Zurich"
      | "Rome"
      | "Stockholm"
      | "Vienna"
      | "West Central Africa"
      | "Bucharest"
      | "Cairo"
      | "Helsinki"
      | "Kyiv"
      | "Riga"
      | "Sofia"
      | "Tallinn"
      | "Vilnius"
      | "Athens"
      | "Istanbul"
      | "Minsk"
      | "Jerusalem"
      | "Harare"
      | "Pretoria"
      | "Kaliningrad"
      | "Moscow"
      | "St. Petersburg"
      | "Volgograd"
      | "Samara"
      | "Kuwait"
      | "Riyadh"
      | "Nairobi"
      | "Baghdad"
      | "Tehran"
      | "Abu Dhabi"
      | "Muscat"
      | "Baku"
      | "Tbilisi"
      | "Yerevan"
      | "Kabul"
      | "Ekaterinburg"
      | "Islamabad"
      | "Karachi"
      | "Tashkent"
      | "Chennai"
      | "Kolkata"
      | "Mumbai"
      | "New Delhi"
      | "Kathmandu"
      | "Astana"
      | "Dhaka"
      | "Sri Jayawardenepura"
      | "Almaty"
      | "Novosibirsk"
      | "Rangoon"
      | "Bangkok"
      | "Hanoi"
      | "Jakarta"
      | "Krasnoyarsk"
      | "Beijing"
      | "Chongqing"
      | "Hong Kong"
      | "Urumqi"
      | "Kuala Lumpur"
      | "Singapore"
      | "Taipei"
      | "Perth"
      | "Irkutsk"
      | "Ulaanbaatar"
      | "Seoul"
      | "Osaka"
      | "Sapporo"
      | "Tokyo"
      | "Yakutsk"
      | "Darwin"
      | "Adelaide"
      | "Canberra"
      | "Melbourne"
      | "Sydney"
      | "Brisbane"
      | "Hobart"
      | "Vladivostok"
      | "Guam"
      | "Port Moresby"
      | "Magadan"
      | "Srednekolymsk"
      | "Solomon Is."
      | "New Caledonia"
      | "Fiji"
      | "Kamchatka"
      | "Marshall Is."
      | "Auckland"
      | "Wellington"
      | "Nuku'alofa"
      | "Tokelau Is."
      | "Chatham Is."
      | "Samoa";
    plan_name?: string;
    "crm?"?: boolean;
    "psa?"?: boolean;
    entitlements?: string[];
    settings?: object;
  };
  relationships?: {
    "business-units"?: V1ToManyRelationships;
    clients?: V1ToManyRelationships;
    "crm-opportunities"?: V1ToManyRelationships;
    "document-templates"?: V1ToManyRelationships;
    "expense-categories"?: V1ToManyRelationships;
    governances?: V1ToManyRelationships;
    "payment-credits"?: V1ToManyRelationships;
    "payment-terms"?: V1ToManyRelationships;
    phases?: V1ToManyRelationships;
    products?: V1ToManyRelationships;
    "project-variables"?: V1ToManyRelationships;
    projects?: V1ToManyRelationships;
    "rate-tables"?: V1ToManyRelationships;
    resources?: V1ToManyRelationships;
    "service-categories"?: V1ToManyRelationships;
    services?: V1ToManyRelationships;
    teams?: V1ToManyRelationships;
    users?: V1ToManyRelationships;
  };
  links?: {
    projects?: string;
    "business-unit"?: string;
    clients?: string;
    "crm-opportunities"?: string;
    "document-templates"?: string;
    "expense-categories"?: string;
    governances?: string;
    "payment-terms"?: string;
    "presales-engineers"?: string;
    phases?: string;
    "project-variables"?: string;
    resources?: string;
    "sales-executives"?: string;
    services?: string;
    users?: string;
    self?: string;
  };
};
export type V1Relationships = {
  links?: {
    self?: string;
    related?: string;
  };
  data?: {
    id?: number;
    type?: string;
  };
};
export type V1BusinessUnitResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    "external-name"?: string;
    "street-address"?: string;
    street2?: string;
    city?: string;
    state?: string;
    "postal-code"?: string;
    country?: string;
  };
  relationships: {
    account: V1Relationships;
  };
};
export type V1ClientResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    "msa-date"?: string;
  };
  relationships?: {
    account: V1Relationships;
    contacts?: V1ToManyRelationships;
    "rate-table"?: V1Relationships;
  };
};
export type V1CrmOpportunityResource = {
  id?: number;
  type?: string;
  attributes?: {
    "account-id"?: string;
    "account-name"?: string;
    name?: string;
    "display-name"?: string;
    amount?: number;
    stage?: string;
    "is-closed"?: boolean;
    "owner-id"?: string;
    "owner-name"?: string;
    "location-name"?: string;
    street?: string;
    city?: string;
    state?: string;
    "postal-code"?: string;
    country?: string;
    "custom-attributes"?: object;
  };
  relationships?: {
    account?: V1Relationships;
    projects?: V1ToManyRelationships;
  };
};
export type V1DocumentTemplateResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    format: "word_template" | "html";
    "merge-template"?: string;
    "merge-template-filename"?: string;
    "filename-format"?: string[];
  };
  relationships: {
    account: V1Relationships;
    "rate-table"?: V1Relationships;
  };
};
export type V1GovernanceResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    active?: boolean;
    rate?: number;
    "fixed-hours"?: number;
    "allocation-method"?:
      | "prorate_phases_by_effort"
      | "select_phase"
      | "select_category"
      | "project_management_phase";
    "calculation-type"?: "percent_of_total" | "fixed_hours";
    "required?"?: boolean;
    "assign-effort-to-service?"?: boolean;
    "filter-type"?: "all_services" | "filter_by_lob" | "filter_by_phase";
    "filter-id"?: number;
    position?: number;
  };
  relationships?: {
    account?: V1Relationships;
    resource?: V1Relationships;
    phase?: V1Relationships;
  };
};
export type V1LineOfBusinessResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    code?: string;
    "nested-name"?: string;
    position?: number;
  };
  relationships: {
    account: V1Relationships;
  };
};
export type V1PaymentCreditResource = {
  id?: number;
  type: string;
  attributes: {
    "payment-source": string;
    description: string;
    criteria?: string;
  };
  relationships: {
    account: V1Relationships;
  };
};
export type V1PaymentTermResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    slug?: string;
    position?: number;
    "include-expenses"?: boolean;
    "include-materials"?: boolean;
    "pricing-model"?: "other" | "fixed_fee" | "time_and_materials";
    rounding?:
      | "2000.00"
      | "1300.00"
      | "1240.00"
      | "1235.00"
      | "1234.60"
      | "1234.57"
      | "1234.568";
    default?: boolean;
  };
  relationships: {
    account: V1Relationships;
    "rate-table"?: V1Relationships;
    terms?: V1ToManyRelationships;
  };
};
export type V1PhaseResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    "sow-language": string;
    active?: boolean;
    position?: number;
  };
  relationships?: {
    account?: V1Relationships;
    "project-expenses"?: V1ToManyRelationships;
  };
};
export type V1ProductResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    description?: string;
    sku?: string;
    "product-id"?: string;
    "manufacturer-part-number"?: string;
    "unit-of-measure"?: string;
    category?: string;
    subcategory?: string;
    "unit-price"?: number;
    "unit-cost"?: number;
    "list-price"?: number;
    "vendor-discount"?: number;
    "vendor-rebate"?: number;
    markup?: number;
    "billing-frequency"?:
      | "one_time"
      | "monthly"
      | "bi-monthly"
      | "quarterly"
      | "semi-annual"
      | "yearly";
  };
  relationships: {
    account: V1Relationships;
  };
};
export type V1ProjectVariableResource = {
  id?: number;
  type?: string;
  attributes?: {
    label: string;
    name: string;
    variable_type?: "number" | "text" | "date";
    "variable-context"?:
      | "project"
      | "service_location"
      | "project_contact"
      | "product"
      | "section"
      | "service";
    minimum?: number;
    maximum?: number;
    required?: boolean;
    position?: number;
    select_options?: {
      key?: string;
      value?: string;
    }[];
  };
  relationships?: {
    account?: V1Relationships;
  };
};
export type V1ProjectResource = {
  id?: number;
  type: string;
  attributes: {
    "project-name": string;
    status?: string;
    "executive-summary"?: string;
    "key-objectives"?: string;
    "our-responsibilities"?: string;
    "client-responsibilities"?: string;
    "solution-summary"?: string;
    "out-of-scope"?: string;
    "contract-revenue"?: number;
    "contract-cost"?: number;
    "total-effort"?: number;
    "total-revenue"?: number;
    "one-time-adjustment"?: number;
    "ms-revenue"?: number;
    "mrr-adjustment"?: number;
    "mrr-terms"?: number;
    cola?: number;
    "recurring-billing-frequency"?: "monthly" | "quarterly" | "yearly";
    "include-ps-revenue-in-mrr"?:
      | "do_not_include_ps_revenue"
      | "add_to_first_month"
      | "amortize_over_all_months";
    "service-start-date"?: string;
    "third-party-revenue"?: number;
    "travel-limit"?: number;
    "submitted-at"?: string;
    "approved-at"?: string;
    "msa-date"?: string;
    "storage-options"?: object;
    "project-variables"?: V1ProjectVariableResource[];
    "field-labels"?: {
      executive_summary?: string;
      solution_summary?: string;
      customer_summary?: string;
      customer_success?: string;
      sales_executive?: string;
      presales_engineer?: string;
      client?: string;
      business_unit?: string;
      location?: string;
      our_responsibilities?: string;
      client_responsibilities?: string;
      out_of_scope?: string;
    };
    "tag-list"?: string[];
    "payment-info"?: {
      "pricing-model"?: "other" | "fixed_fee" | "time_and_materials";
      "include-expenses"?: boolean;
      "include-product"?: boolean;
      "rate-type"?: "hour" | "day" | "week";
    };
  };
  relationships: {
    account: V1Relationships;
    "business-unit"?: V1Relationships;
    client: V1Relationships;
    "document-template"?: V1Relationships;
    "external-request"?: V1Relationships;
    "sales-executive"?: V1Relationships;
    "presales-engineer"?: V1Relationships;
    "psa-project"?: V1Relationships;
    "crm-opportunity"?: V1Relationships;
    "payment-term"?: V1Relationships;
    user?: V1Relationships;
    "project-attachments"?: V1ToManyRelationships;
    "project-collaborators"?: V1ToManyRelationships;
    "project-contacts"?: V1ToManyRelationships;
    "project-conditions"?: V1ToManyRelationships;
    "project-credits"?: V1ToManyRelationships;
    "project-documents"?: V1ToManyRelationships;
    "project-expenses"?: V1ToManyRelationships;
    "project-governances"?: V1ToManyRelationships;
    "project-locations"?: V1ToManyRelationships;
    "project-materials"?: V1ToManyRelationships;
    "project-phases"?: V1ToManyRelationships;
    "project-products"?: V1ToManyRelationships;
    "project-resources"?: V1ToManyRelationships;
    "project-versions"?: V1ToManyRelationships;
    "project-services"?: V1ToManyRelationships;
    "rate-table": V1Relationships;
    "resource-plans"?: V1ToManyRelationships;
    "approval-steps"?: V1ToManyRelationships;
    notes?: V1ToManyRelationships;
    quotes?: V1ToManyRelationships;
    "partner-requests"?: V1ToManyRelationships;
    "audit-logs"?: V1ToManyRelationships;
    "customer-successes"?: V1ToManyRelationships;
  };
};
export type V1RateTableResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    default?: boolean;
  };
  relationships: {
    account: V1Relationships;
    currency?: V1Relationships;
    clients?: V1ToManyRelationships;
    projects?: V1ToManyRelationships;
    users?: V1ToManyRelationships;
    "resource-rates"?: V1ToManyRelationships;
  };
};
export type V1ResourceResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    active?: boolean;
    "external-name"?: string;
    description?: string;
    hourly_rate?: number;
    hourly_cost?: number;
  };
  relationships?: {
    account?: V1Relationships;
    governances?: V1ToManyRelationships;
    "project-governances"?: V1ToManyRelationships;
    "project-resources"?: V1ToManyRelationships;
    "project-services"?: V1ToManyRelationships;
    "project-subservices"?: V1ToManyRelationships;
  };
};
export type V1ServiceResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    sku?: string;
    "service-description"?: string;
    guidance?: string;
    languages?: object;
    state?: "pending" | "active" | "deleted" | "custom";
    position?: number;
    "minimum-quantity"?: number;
    lob_id?: number;
    "service-type"?: "professional_services" | "managed_services";
    "payment-frequency"?: "one_time" | "monthly" | "quarterly" | "yearly";
    "suggested-hours"?: number;
    "total-hours"?: number;
    "tag-list"?: string[];
    teams?: {
      id?: string;
      name?: string;
    }[];
    variables?: object;
    "updated-at"?: string;
    "actual-hours"?: number;
    deviation?: number;
    "custom-service-cost?"?: boolean;
    "custom-service-price?"?: boolean;
    "custom-hours?"?: boolean;
  };
  relationships: {
    account: V1Relationships;
    phase?: V1Relationships;
    resource?: V1Relationships;
    "service-category"?: V1Relationships;
    products?: V1ToManyRelationships;
    subservices?: V1ToManyRelationships;
    "blueprint-items"?: V1ToManyRelationships;
  };
};
export type V1TeamResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    "created-at": string;
    questionnaires?: {
      id?: string;
      name?: string;
      introduction?: string;
    }[];
    services?: {
      id?: string;
      name?: string;
      "service-catgory"?: string;
      phase?: string;
    }[];
  };
  relationships: {
    account: V1Relationships;
    users?: {
      links?: {
        self?: string;
        related?: string;
      };
      data?: {
        id?: string;
        type?: string;
      }[];
    };
  };
};
export type V1UserResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    phone?: string;
    email: string;
    title?: string;
    "view-only"?: boolean;
    privileges?: object;
    "guided-onboarding"?: {
      onboarding_status?: string;
      skipped_onboarding?: boolean;
      modified_governance?: boolean;
      modified_phases?: boolean;
      modified_resources?: boolean;
      modified_services?: boolean;
    };
    "preferred-rate-table"?: V1RateTableResource;
  };
  relationships: {
    account?: V1Relationships;
    "rate-table"?: V1Relationships;
    teams?: V1ToManyRelationships;
  };
};
export type V1NotFoundError = {
  errors?: {
    title?: string;
    detail?: string;
    code?: string;
    status?: string;
  }[];
};
export type V1AccountLogoResource = {
  id?: number;
  type: string;
  attributes: {
    logo?: string;
    "logo-uuid"?: string;
  };
};
export type V1ValidationError = {
  errors?: {
    title?: string;
    detail?: string;
    code?: string;
    status?: string;
  }[];
};
export type V1ApprovalStepResource = {
  id?: number;
  type?: string;
  attributes?: {
    status?: "pending" | "approved" | "declined" | "canceled" | "rescoped";
    step?: "technical_approval" | "sales_approval" | "business_approval";
    description?: string;
  };
  relationships?: {
    project?: V1Relationships;
    "project-version"?: V1Relationships;
    "project-approvals"?: V1ToManyRelationships;
  };
};
export type V1ProjectVersionResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    comment?: string;
    username?: string;
    "started-at"?: string;
    "completed-at"?: string;
    "updated-at"?: string;
  };
  relationships: {
    project: V1Relationships;
    "approval-steps"?: V1ToManyRelationships;
  };
};
export type V1ProjectApprovalResource = {
  id?: number;
  type: string;
  attributes: {
    status?:
      | "required"
      | "optional"
      | "declined"
      | "approved"
      | "canceled"
      | "rescope";
    role?: string;
    reason?: string;
    comment?: string;
    "completed-at"?: string;
    "approver-name"?: string;
  };
  relationships: {
    account?: V1Relationships;
    user: V1Relationships;
    "approval-step": V1Relationships;
    "approval-level"?: V1Relationships;
  };
};
export type V1PagingMeta = {
  "record-count"?: number;
  "page-count"?: number;
};
export type V1AuditLogResource = {
  id?: number;
  type?: string;
  attributes?: {
    name?: string;
    "item-type"?: string;
    "item-id"?: number;
    "changed-by"?: string;
    event?: string;
    "created-at"?: string;
    changes?: {
      attribute?: string;
      "changed-from"?: string;
      "changed-to"?: string;
    }[];
  };
};
export type V1BlueprintResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    code?: string;
    "tag-list"?: string[];
  };
  relationships: {
    account: V1Relationships;
    "blueprint-items"?: V1ToManyRelationships;
  };
};
export type V1BlueprintItemResource = {
  id?: number;
  type: string;
  attributes: {
    quantity: number;
    position?: number;
  };
  relationships: {
    account?: V1Relationships;
    service: V1Relationships;
    resource?: V1Relationships;
    blueprint: V1Relationships;
  };
};
export type V1ContactResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    phone?: string;
    email?: string;
    title?: string;
    active?: boolean;
    sales?: boolean;
    presales?: boolean;
  };
  relationships: {
    contactable?: V1Relationships;
  };
};
export type V1CurrencyResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    "currency-type":
      | "dollars"
      | "euros"
      | "dirham"
      | "francs"
      | "kroner"
      | "pounds"
      | "rand"
      | "rupeeI"
      | "rupeeN"
      | "shekels"
      | "won"
      | "yen";
    "conversion-rate": number;
    default?: boolean;
  };
  relationships?: {
    account: V1Relationships;
    "resource-rates"?: V1ToManyRelationships;
    users?: V1ToManyRelationships;
  };
};
export type V1CustomerSuccessResource = {
  id?: number;
  type: string;
  attributes: {
    outcome: string;
    "adoption-barriers"?: string;
    "ongoing-strategy"?: string;
    position?: number;
  };
  relationships: {
    account?: V1Relationships;
    project: V1Relationships;
    "performance-indicators"?: V1ToManyRelationships;
  };
};
export type V1PerformanceIndicatorResource = {
  id?: number;
  type: string;
  attributes: {
    "due-on"?: string;
    "started-on"?: string;
    "completed-on"?: string;
    status?: "pending" | "in_progress" | "delayed" | "completed";
    notes?: string;
  };
  relationships: {
    account?: V1Relationships;
    project?: V1Relationships;
    "customer-success": V1Relationships;
  };
};
export type V1ExpenseCategoryResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    rate?: number;
    "use-resource-rate"?: boolean;
  };
  relationships?: {
    account?: V1Relationships;
    phase?: V1Relationships;
  };
};
export type V1ServiceCategoryResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    code?: string;
    "nested-name"?: string;
    "has-parent?"?: boolean;
    position?: number;
  };
  relationships: {
    account: V1Relationships;
  };
};
export type V1IntegratedProductResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    description?: string;
    sku?: string;
    "product-id"?: string;
    "manufacturer-part-number"?: string;
    "unit-of-measure"?: string;
    category?: string;
    subcategory?: string;
    "unit-price"?: number;
    "unit-cost"?: number;
    "vendor-name"?: string;
    "vendor-discount"?: number;
    "vendor-rebate"?: number;
    markup?: number;
    "billing-frequency"?:
      | "one_time"
      | "monthly"
      | "bi-monthly"
      | "quarterly"
      | "semi-annual"
      | "yearly";
  };
};
export type V1LanguageFieldResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    "sow-language": string;
    active?: boolean;
    position?: number;
    preamble?: string;
    postamble?: string;
  };
  relationships?: {
    account?: V1Relationships;
    "project-expenses"?: V1ToManyRelationships;
  };
};
export type V1ProjectExpenseResource = {
  id?: number;
  type: string;
  attributes: {
    description: string;
    rate: number;
    quantity: number;
    "deleted-at"?: string;
    cost?: number;
    revenue?: number;
    markup?: number;
  };
  relationships: {
    project: V1Relationships;
    "project-phase"?: V1Relationships;
    "expense-category": V1Relationships;
    "project-resource"?: V1Relationships;
    resource: V1Relationships;
    "resource-rate"?: V1Relationships;
    "project-service"?: V1Relationships;
  };
};
export type V1NoteResource = {
  id?: number;
  type: string;
  attributes: {
    content: string;
    username?: string;
    created_at?: string;
  };
  relationships: {
    project: V1Relationships;
    notable?: V1Relationships;
  };
};
export type V1PartnerRequestResource = {
  id?: number;
  type: string;
  attributes: {
    description: string;
    "revision-description"?: string;
    "service-type"?: "professional_services" | "managed_services";
    cost: number;
    "recurring-cost"?: number;
    "billing-frequency"?: "one_time" | "monthly" | "quarterly" | "yearly";
    markup: number;
    price?: number;
    responded_at?: string;
  };
  relationships: {
    project: V1Relationships;
    lob?: V1Relationships;
    subcontractor?: V1Relationships;
    currency?: V1Relationships;
    "third-party-services"?: V1ToManyRelationships;
  };
};
export type V1LobResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    code?: string;
    "nested-name"?: string;
    position?: number;
  };
  relationships: {
    account: V1Relationships;
  };
};
export type V1SubcontractorResource = {
  id?: number;
  type: string;
  attributes: {
    slug: string;
    "subcontractor-id"?: number;
    name: string;
    street_adddress?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    country?: string;
  };
  links?: {
    projects?: string;
    "business-unit"?: string;
    clients?: string;
    "crm-opportunities"?: string;
    "document-templates"?: string;
    "expense-categories"?: string;
    governances?: string;
    "payment-terms"?: string;
    "presales-engineers"?: string;
    phases?: string;
    "project-variables"?: string;
    resources?: string;
    "sales-executives"?: string;
    services?: string;
    users?: string;
    self?: string;
  };
};
export type V1ThirdPartyServiceResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    "original-description"?: string;
    "service-description"?: string;
    quantity?: number;
    "est-hours"?: number;
    "est-rate"?: number;
    "est-cost"?: number;
    "mrr-terms"?: number;
    position?: number;
    languages?: object;
    "original-languages"?: object;
    "payment-frequency"?: "one_time" | "monthly" | "quarterly" | "yearly";
    "source-type"?: "Quote" | "ThirdPartyRequest" | "ThirdPartyService";
    "source-id"?: number;
  };
  relationships: {
    source?: V1Relationships;
    "project-phase"?: V1Relationships;
    "project-task"?: V1Relationships;
    subtasks?: V1ToManyRelationships;
  };
};
export type V1RecordCount = {
  "record-count"?: number;
};
export type V1ExternalRequestResource = {
  id?: number;
  type: string;
  attributes: {
    description: string;
    "revision-description"?: string;
    "service-type"?: "professional_services" | "managed_services";
    cost?: number;
    "recurring-cost"?: number;
    "billing-frequency"?: "one_time" | "monthly" | "quarterly" | "yearly";
    markup?: number;
    price?: number;
    requested_at?: string;
  };
  relationships: {
    account: V1Relationships;
    project: V1Relationships;
    lob?: V1Relationships;
    subcontractor?: V1Relationships;
    subproject?: V1Relationships;
  };
};
export type V1SubprojectResource = {
  id?: number;
  type?: string;
  attributes?: {
    "project-name": string;
    status?: string;
    "executive-summary"?: string;
    "key-objectives"?: string;
    "our-responsibilities"?: string;
    "client-responsibilities"?: string;
    "solution-summary"?: string;
    "out-of-scope"?: string;
    "total-effort"?: number;
    "total-revenue"?: number;
    "ms-revenue"?: number;
    "third-party-revenue"?: number;
    "submitted-at"?: string;
    "approved-at"?: string;
    "project-variables"?: V1ProjectVariableResource[];
    "field-labels"?: {
      executive_summary?: string;
      solution_summary?: string;
      customer_summary?: string;
      customer_success?: string;
      sales_executive?: string;
      presales_engineer?: string;
      client?: string;
      business_unit?: string;
      location?: string;
      our_responsibilities?: string;
      client_responsibilities?: string;
      out_of_scope?: string;
    };
  };
  relationships?: {
    account?: V1Relationships;
    "business-unit"?: V1Relationships;
    client?: V1Relationships;
    "document-template"?: V1Relationships;
    "external-request"?: V1Relationships;
    "sales-executive"?: V1Relationships;
    "presales-engineer"?: V1Relationships;
    "psa-project"?: V1Relationships;
    "crm-opportunity"?: V1Relationships;
    "payment-term"?: V1Relationships;
    user?: V1Relationships;
    "project-attachments"?: V1Relationships;
    "project-collaborators"?: V1Relationships;
    "project-contacts"?: V1Relationships;
    "project-conditions"?: V1Relationships;
    "project-expenses"?: V1Relationships;
    "project-governances"?: V1Relationships;
    "project-locations"?: V1Relationships;
    "project-materials"?: V1Relationships;
    "project-services"?: V1Relationships;
    "rate-table"?: V1Relationships;
    "approval-steps"?: V1Relationships;
  };
};
export type V1ProjectPhaseResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    "sow-language": string;
    active?: boolean;
    position?: number;
  };
  relationships?: {
    project?: V1Relationships;
    phase?: V1Relationships;
  };
};
export type V1TermResource = {
  id?: number;
  type: string;
  attributes: {
    description?: string;
    "term-type": "percent" | "milestone";
    position?: number;
    percentage_due?: number;
  };
  relationships: {
    account: V1Relationships;
    phase?: V1Relationships;
    "payment-term"?: V1Relationships;
  };
};
export type V1PresalesEngineerResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    phone?: string;
    email: string;
    title?: string;
  };
  relationships: {
    account: V1Relationships;
  };
};
export type V1PricingAdjustmentResource = {
  id?: number;
  type: string;
  attributes: {
    price: number;
    cost: number;
    source_type: string;
    source_id: number;
    billing_frequency: string;
  };
  relationships?: {
    project?: V1Relationships;
  };
};
export type V1CreatorResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    phone?: string;
    email: string;
    title?: string;
  };
  relationships: {
    account: V1Relationships;
  };
};
export type V1SalesExecutiveResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    phone?: string;
    email?: string;
    title?: string;
    active?: boolean;
  };
  relationships: {
    account: V1Relationships;
  };
};
export type V1PsaProjectResource = {
  id?: number;
  type?: string;
  attributes?: {
    "start-on"?: string;
    "end-on"?: string;
    "project-id"?: string;
    "error-message"?: string;
    "project-options"?: {
      board?: string;
      import?: "new" | "existing";
      process?: "current_process" | "task_as_ticket" | "phase_as_ticket";
      template?: string;
      governance?: string;
    };
  };
  relationships?: {
    account: V1Relationships;
    "rate-table"?: V1Relationships;
  };
};
export type V1ProjectAttachmentResource = {
  id?: number;
  type: string;
  attributes: {
    document: string;
  };
  relationships: {
    project: V1Relationships;
    "attachment-type"?: V1Relationships;
  };
};
export type V1ProjectCollaboratorResource = {
  id?: number;
  type: string;
  attributes?: {
    name?: string;
  };
  relationships: {
    project: V1Relationships;
    user: V1Relationships;
  };
};
export type V1ProjectContactResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    phone?: string;
    email?: string;
    title?: string;
    "contact-type"?:
      | "primary_customer_contact"
      | "customer_contact"
      | "primary_internal_contact"
      | "internal_contact";
    "project-variables"?: V1ProjectVariableResource[];
  };
  relationships: {
    project: V1Relationships;
    contact: V1Relationships;
  };
};
export type V1ProjectConditionResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    content?: string;
    status?: "active" | "skipped" | "deleted";
  };
  relationships: {
    project: V1Relationships;
    "service-condition": V1Relationships;
  };
};
export type V1ProjectCreditResource = {
  id?: number;
  type: string;
  attributes: {
    amount: number;
    criteria?: string;
  };
  relationships: {
    project: V1Relationships;
    "payment-credit": V1Relationships;
  };
};
export type V1ProjectDocumentResource = {
  id?: number;
  type: string;
  attributes: {
    name?: string;
    status?:
      | "created"
      | "finished"
      | "failed"
      | "to_delete"
      | "generated"
      | "delivering";
    "document-type": "sow" | "pricing" | "breakdown" | "autotask" | "report";
    "delivery-method"?: "download" | "email" | "docusign";
    "email-list"?: string;
    "force-regeneration"?: boolean;
    "template-id": string;
    "document-url"?: string;
    "error-text"?: string;
    "generate-pdf"?: boolean;
    "account-connection-id"?: string;
    "drive-id"?: string;
    filepath?: string;
    filename?: string;
  };
  relationships: {
    project: V1Relationships;
    "document-deliveries"?: V1ToManyRelationships;
  };
};
export type V1ProjectGovernanceResource = {
  id?: number;
  type?: string;
  attributes?: {
    description?: string;
    rate?: number;
    fixed_hours?: number;
    hours?: number;
    "calculation-type"?: "percent_of_total" | "fixed_hours";
    "allocation-method"?:
      | "prorate_phases_by_effort"
      | "select_phase"
      | "select_category"
      | "project_management_phase";
    "assign-effort-to-service?"?: boolean;
    "filter-type"?: "all_services" | "filter_by_lob" | "filter_by_phase";
    "filter-id"?: number;
    position?: number;
  };
  relationships?: {
    project?: V1Relationships;
    "project-phase"?: V1Relationships;
    governance?: V1Relationships;
    "project-resource"?: V1Relationships;
    resource?: V1Relationships;
    "allocationed-governances"?: V1Relationships;
  };
};
export type V1ProjectLocationResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    street: string;
    street2?: string;
    city: string;
    state?: string;
    "postal-code": string;
    country?: string;
    "deleted-at"?: string;
    "project-variables"?: V1ProjectVariableResource[];
  };
  relationships: {
    project: V1Relationships;
  };
};
export type V1ProjectProductResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    description?: string;
    custom?: boolean;
    source?: "standard" | "custom" | "imported" | "connectwise";
    variable_rates?: object;
    quantity: number;
    sku?: string;
    "product-id"?: string;
    "manufacturer-part-number"?: string;
    "unit-of-measure"?: string;
    category?: string;
    subcategory?: string;
    "unit-price"?: number;
    "unit-cost"?: number;
    "hardware-price"?: number;
    "hardware-cost"?: number;
    "line-number"?: string;
    "list-price"?: number;
    "vendor-discount"?: number;
    markup?: number;
    "billing-frequency"?:
      | "one_time"
      | "monthly"
      | "bi-monthly"
      | "quarterly"
      | "semi-annual"
      | "yearly";
  };
  relationships: {
    project: V1Relationships;
    product?: V1Relationships;
  };
};
export type V1ProjectResourceResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    "external-name"?: string;
    description?: string;
    "total-hours"?: number;
    "hourly-rate"?: number;
    "hourly-cost"?: number;
    "expense-rate"?: number;
    code?: string;
  };
  relationships: {
    project: V1Relationships;
    resource: V1Relationships;
  };
};
export type V1ResourcePlanResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    description?: string;
    "start-on"?: string;
    weeks: number;
    sprints?: number;
    baseline?: boolean;
    "phase-mapping"?: {
      date?: string;
      project_phase_id?: number;
    }[];
    "allocated-hours"?: {
      hours?: number;
      project_resource_id?: number;
    }[];
  };
  relationships?: {
    project?: V1Relationships;
    "resource-allocations"?: V1ToManyRelationships;
  };
};
export type V1ProjectServiceResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    quantity: number;
    "override-hours": number;
    "total-hours"?: number;
    "extended-hours"?: number;
    "actual-hours"?: number;
    position?: number;
    "service-type": "professional_services" | "managed_services";
    "lob-id": number;
    "payment-frequency"?: string;
    "task-source": string;
    languages?: object;
    "calculated-pricing"?: object;
  };
  relationships?: {
    project?: V1Relationships;
    "project-location"?: V1Relationships;
    "project-phase"?: V1Relationships;
    "project-resource"?: V1Relationships;
    resource?: V1Relationships;
    service?: V1Relationships;
    lob?: V1Relationships;
    "project-subservices"?: V1ToManyRelationships;
    notes?: V1ToManyRelationships;
    "allocated-governances"?: V1Relationships;
  };
};
export type V1ResourceRateResource = {
  id?: number;
  type: string;
  attributes: {
    name?: string;
    external_name?: string;
    description?: string;
    "hourly-rate": number;
    "hourly-cost": number;
    "expense-rate"?: number;
    code?: string;
  };
  relationships: {
    "rate-table": V1Relationships;
    resource: V1Relationships;
    "line-of-business"?: V1Relationships;
  };
};
export type V1QuoteResource = {
  id?: number;
  type: string;
  attributes: {
    description: string;
    service_type?: "professional_services" | "managed_services";
    cost?: number;
    "recurring-cost"?: number;
    "billing-frequency"?: "one_time" | "monthly" | "quarterly" | "yearly";
    markup?: number;
    price?: number;
    "service-pricing"?: boolean;
    "vendor-document"?: string;
    "vendor-document-status"?:
      | "pending"
      | "submitted"
      | "processed"
      | "error"
      | "accepted"
      | "not_applicable";
  };
  relationships: {
    project: V1Relationships;
    lob?: V1Relationships;
    "third-party-services"?: V1Relationships;
    vendor: V1Relationships;
  };
};
export type V1CreateProjectResource = {
  id?: number;
  type: string;
  attributes: {
    "project-name": string;
    status?: string;
    "executive-summary"?: string;
    "key-objectives"?: string;
    "our-responsibilities"?: string;
    "client-responsibilities"?: string;
    "solution-summary"?: string;
    "out-of-scope"?: string;
    "one-time-adjustment"?: number;
    "mrr-adjustment"?: number;
    "mrr-terms"?: number;
    cola?: number;
    "recurring-billing-frequency"?: "monthly" | "quarterly" | "yearly";
    "include-ps-revenue-in-mrr"?:
      | "do_not_include_ps_revenue"
      | "add_to_first_month"
      | "amortize_over_all_months";
    "service-start-date"?: string;
    "travel-limit"?: number;
    "submitted-at"?: string;
    "approved-at"?: string;
    "msa-date"?: string;
    "project-variables"?: V1ProjectVariableResource[];
    "field-labels"?: {
      executive_summary?: string;
      solution_summary?: string;
      customer_summary?: string;
      customer_success?: string;
      sales_executive?: string;
      presales_engineer?: string;
      client?: string;
      business_unit?: string;
      location?: string;
      our_responsibilities?: string;
      client_responsibilities?: string;
      out_of_scope?: string;
    };
    "tag-list"?: string[];
    "client-name"?: string;
    "sales-executive-name"?: string;
    "presales-engineer-name"?: string;
    "payment-info"?: {
      "pricing-model"?: "other" | "fixed_fee" | "time_and_materials";
      "include-expenses"?: boolean;
      "include-product"?: boolean;
      "rate-type"?: "hour" | "day" | "week";
    };
  };
  relationships: {
    account: V1Relationships;
    "business-unit"?: V1Relationships;
    client: V1Relationships;
    "document-template"?: V1Relationships;
    "external-request"?: V1Relationships;
    "sales-executive"?: V1Relationships;
    "presales-engineer"?: V1Relationships;
    "psa-project"?: V1Relationships;
    "crm-opportunity"?: V1Relationships;
    "payment-term"?: V1Relationships;
    user?: V1Relationships;
    "project-attachments"?: V1ToManyRelationships;
    "project-collaborators"?: V1ToManyRelationships;
    "project-contacts"?: V1ToManyRelationships;
    "project-conditions"?: V1ToManyRelationships;
    "project-credits"?: V1ToManyRelationships;
    "project-documents"?: V1ToManyRelationships;
    "project-expenses"?: V1ToManyRelationships;
    "project-governances"?: V1ToManyRelationships;
    "project-locations"?: V1ToManyRelationships;
    "project-materials"?: V1ToManyRelationships;
    "project-phases"?: V1ToManyRelationships;
    "project-products"?: V1ToManyRelationships;
    "project-resources"?: V1ToManyRelationships;
    "project-versions"?: V1ToManyRelationships;
    "project-services"?: V1ToManyRelationships;
    "rate-table": V1Relationships;
    "resource-plans"?: V1ToManyRelationships;
    "approval-steps"?: V1ToManyRelationships;
    notes?: V1ToManyRelationships;
    quotes?: V1ToManyRelationships;
    "partner-requests"?: V1ToManyRelationships;
    "audit-logs"?: V1ToManyRelationships;
    "customer-successes"?: V1ToManyRelationships;
  };
};
export type V1AttachmentTypeResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    document_type: "image" | "pdf" | "word";
  };
  relationships?: {
    account: V1Relationships;
    "rate-table"?: V1Relationships;
    "project-attachments"?: V1ToManyRelationships;
  };
};
export type V1ServiceConditionResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    content?: string;
    position?: number;
  };
  relationships: {
    account: V1Relationships;
  };
};
export type V1DocumentDeliveryResource = {
  id?: number;
  type: string;
  attributes: {
    "document-name"?: string;
    status?: "pending" | "in_progress" | "delivered" | "failed";
    delivery_type?: "email" | "docusign" | "download" | "cloud_storage";
    recipients?: {
      all?: string;
      internal?: string;
      external?: string;
    };
  };
  relationships: {
    account: V1Relationships;
    "rate-table"?: V1Relationships;
  };
};
export type V1ProjectDownloadTypeResource = {
  id?: string;
  type: string;
  attributes: {
    name?: string;
    filename?: string;
    "document-type"?: "sow" | "pricing" | "breakdown" | "autotask" | "report";
    "template-id"?: string;
    group?: string;
  };
};
export type V1ProjectPricingResource = {
  id?: number;
  type?: string;
  attributes?: {
    quantity?: number;
    hours?: number;
    "extended-hours"?: number;
    "hourly-rate"?: number;
    "hourly-cost"?: number;
    "allocated-pm"?: number;
    "service-type"?: "professional" | "managed";
    "service-revenue"?: number;
    "service-cost"?: number;
    "hardware-price"?: number;
    "hardware-cost"?: number;
    "billing-frequency"?: "one_time" | "monthly" | "quarterly" | "yearly";
    "source-id"?: number;
    "source-type"?: string;
    phase?: {
      name?: string;
      position?: number;
    };
    location?: {
      id?: number;
      name?: string;
      street?: string;
      street2?: string;
      city?: string;
      state?: string;
      postal_code?: string;
      country?: string;
    };
  };
};
export type V1ProjectSubserviceResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    quantity: number;
    "override-hours": number;
    "extended-hours"?: number;
    "actual-hours"?: number;
    position?: number;
    "service-type"?: string;
    "lob-id"?: number;
    "payment-frequency"?: string;
    "task-source"?: string;
    languages?: object;
  };
  relationships?: {
    "project-service"?: V1Relationships;
    "project-resource"?: V1Relationships;
    resource?: V1Relationships;
    service?: V1Relationships;
  };
};
export type V1SubserviceResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    "service-description"?: string;
    languages?: object;
    state?: "pending" | "active" | "deleted" | "custom";
    "suggested-hours"?: number;
    "minimum-quantity"?: number;
    quantity?: number;
    position?: number;
    "custom-service-cost?"?: boolean;
    "custom-service-price?"?: boolean;
    "custom-hours?"?: boolean;
  };
  relationships: {
    resource?: V1Relationships;
    service: V1Relationships;
  };
};
export type V1QuestionResource = {
  id?: number;
  type?: string;
  attributes?: {
    question?: string;
    slug?: string;
    postion?: number;
    "value-type"?: "text" | "number" | "date" | "checkbox" | "email" | "phone";
    required?: boolean;
    settings?: {
      min?: string;
      max?: string;
      step?: number;
    };
    "select-options"?: {
      key?: string;
      value?: string;
      default?: boolean;
    }[];
  };
  relationships?: {
    account?: V1Relationships;
    questionnaire?: V1Relationships;
  };
};
export type V1QuestionnaireResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    published?: boolean;
    "created-at"?: string;
    slug?: string;
    introduction?: string;
    thank_you?: string;
    "completion-url"?: string;
    questions?: {
      question?: string;
      slug?: string;
      postion?: number;
      "value-type"?:
        | "text"
        | "number"
        | "date"
        | "checkbox"
        | "email"
        | "phone";
      required?: boolean;
      settings?: {
        min?: string;
        max?: string;
        step?: number;
      };
      "select-options"?: {
        key?: string;
        value?: string;
      }[];
    }[];
    "tag-list"?: string[];
    teams?: {
      id?: string;
      name?: string;
    }[];
  };
  relationships?: {
    account?: V1Relationships;
    questions?: V1ToManyRelationships;
  };
};
export type V1VendorResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    "street-address"?: string;
    street2?: string;
    city?: string;
    state?: string;
    "postal-code"?: string;
    country?: string;
  };
  relationships: {
    account: V1Relationships;
    quotes?: V1ToManyRelationships;
  };
};
export type V1ResourceAllocationResource = {
  id?: number;
  type?: string;
  attributes?: {
    personnnel?: string;
    "assigned-hours"?: number[];
  };
  relationships?: {
    "resource-plan"?: V1Relationships;
    "project-resource"?: V1Relationships;
  };
};
export type V1SurveyResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    status?: "pending" | "shared" | "completed" | "applied" | "revised";
    "contact-name"?: string;
    "contact-email"?: string;
    responses?: {
      "survey-response-id"?: number;
      "question-id"?: number;
      question?: string;
      answer?: string;
    }[];
    calculations?: {
      calculation_id?: string;
      value?: string;
    }[];
    recommendations?: {
      status?: "pending" | "applied" | "inactive";
      quantity?: number;
      item_type?: "Governance" | "Material" | "Task";
      item_id?: number;
      resource_id?: number;
      target_type?: "Governance" | "Material" | "Task";
      target_id?: number;
      project_resource_id?: number;
      refinements?: {
        quantity?: number;
        status?: "pending" | "applied";
        item_type?: string;
        item_id?: string;
        resource_id?: string;
        project_resource_id?: string;
      }[];
    }[];
    "updated-at"?: string;
    emails?: string;
    sender?: string;
    sent_at?: string;
    completed_by?: string;
  };
  relationships: {
    account?: V1Relationships;
    questionnaire: V1Relationships;
    project?: V1Relationships;
    "survey-responses"?: V1ToManyRelationships;
    "survey-reommendations"?: V1ToManyRelationships;
  };
};
export type V1SurveyResponseResource = {
  id?: number;
  type: string;
  attributes: {
    question: string;
    answer: string;
    value_name?: string;
    value_type?: "text" | "number" | "date" | "checkbox" | "email" | "phone";
    slug?: string;
    settings?: {
      min?: string;
      max?: string;
      step?: number;
    };
  };
  relationships: {
    survey: V1Relationships;
    source: V1Relationships;
  };
};
export type V1SurveyRecommendationResource = {
  id?: number;
  type?: string;
  attributes?: {
    quantity?: number;
    status?: "pending" | "applied" | "inactive";
    "item-type"?: string;
    "item-id"?: string;
    refinements?: {
      quantity?: number;
      status?: "pending" | "applied";
      item_type?: string;
      item_id?: string;
      resource_id?: string;
      project_resource_id?: string;
    }[];
  };
  relationships?: {
    account: V1Relationships;
    "rate-table"?: V1Relationships;
  };
};
export type V1TagResource = {
  id?: number;
  type: string;
  attributes: {
    name: string;
  };
  relationships: object;
};
export type V1LevelOfEffortResource = {
  id?: number;
  type?: string;
  attributes?: {
    "minimum-quantity"?: number;
    "base-amount"?: number;
    "unit-amount"?: number;
  };
  relationships?: {
    service?: V1Relationships;
  };
};
export type V1TaskRateResource = {
  id?: number;
  type?: string;
  attributes?: {
    "rate-type"?: string;
    "minimum-quantity"?: number;
    "base-amount"?: number;
    "unit-amount"?: number;
  };
  relationships?: {
    service?: V1Relationships;
  };
};
export type V1UserResourceWrite = {
  id?: number;
  type: string;
  attributes: {
    name: string;
    phone?: string;
    email: string;
    title?: string;
    password?: string;
    "password-confirmation"?: string;
    "view-only"?: boolean;
    privileges?: object;
    "guided-onboarding"?: {
      onboarding_status?: string;
      skipped_onboarding?: boolean;
      modified_governance?: boolean;
      modified_phases?: boolean;
      modified_resources?: boolean;
      modified_services?: boolean;
    };
    "preferred-rate-table"?: V1RateTableResource;
  };
  relationships?: {
    account?: V1Relationships;
    "rate-table"?: V1Relationships;
    teams?: V1ToManyRelationships;
  };
};
export type V1WebhookSubscriptionResource = {
  id?: number;
  type: string;
  attributes: {
    "webhook-url"?: string;
    "event-type"?:
      | "project_status_changed"
      | "service_status_changed"
      | "document_status_changed";
  };
  relationships: {
    account: V1Relationships;
  };
};
export type AutotaskCredentials = {
  public_key: string;
  private_key: string;
};
export type ConnectwiseCredentials = {
  company_id: string;
  public_key: string;
  private_key: string;
};
export type DocusignCredentials = {
  client_id: string;
  private_key: string;
};
export type HalopsaCredentials = {
  client_id: string;
  client_secret: string;
};
export type HubspotCredentials = {
  client_secret?: string;
  private_key?: string;
};
export type MicrosoftDynamicsCredentials = {
  client_id: string;
  client_secret: string;
  tenant_id: string;
};
export type SalesforceCredentials = {
  client_id: string;
  client_secret: string;
  instance_url: string;
};
export type AutotaskMappings = {
  statuses?: string;
  stages?: string;
};
export type ConnectwiseMappings = {
  statuses?: string;
};
export type MicrosoftDynamicsMappings = {
  states?: string;
  stages?: string;
  product_types?: boolean;
  mark_won?: null | "won";
  mark_lost?: null | "lost";
};
export type SalesforceMappings = {
  stages?: string;
};
export type AutotaskSettings = {
  skip_revenue_sync?: boolean;
};
export type ConnectwiseSettings = {
  host?: string;
  version?: string;
  skip_revenue_sync?: boolean;
  include_products?: boolean;
};
export type DocusignSettings = {
  oauth_host?: string;
  title?: string;
  internal_signature_placeholder?: boolean;
  external_signature_placeholder?: boolean;
};
export type HalopsaSettings = {
  host?: string;
};
export type MicrosoftDynamicsSettings = {
  host?: string;
  crm_url?: string;
  skip_revenue_sync?: boolean;
};
export type SalesforceSettings = {
  host?: string;
  skip_revenue_sync?: boolean;
  pkce?: boolean;
};
export type V1AccountConnectionResource = {
  id?: number;
  type?: string;
  attributes?: {
    service?:
      | "salesforce"
      | "connectwise"
      | "hubspot"
      | "docusign"
      | "microsoft_dynamics"
      | "autotask"
      | "scopestack"
      | "halopsa"
      | "merge_dev";
    active?: boolean;
    host?: string;
    status?: "Activating" | "Connected" | "Disabled" | "Connection Error";
    opportunities?: number;
    "error-info"?: string;
    "status-information"?: string;
    "last-sync-at"?: string;
    "supports-crm"?: boolean;
    crm?: boolean;
    "supports-psa"?: boolean;
    psa?: boolean;
    "supports-product"?: boolean;
    product?: boolean;
    "supports-esignature"?: boolean;
    esignature?: boolean;
    oauth_url?: string;
    credentials?:
      | AutotaskCredentials
      | ConnectwiseCredentials
      | DocusignCredentials
      | HalopsaCredentials
      | HubspotCredentials
      | MicrosoftDynamicsCredentials
      | SalesforceCredentials;
    mappings?:
      | AutotaskMappings
      | ConnectwiseMappings
      | MicrosoftDynamicsMappings
      | SalesforceMappings;
    settings?:
      | AutotaskSettings
      | ConnectwiseSettings
      | DocusignSettings
      | HalopsaSettings
      | MicrosoftDynamicsSettings
      | SalesforceSettings;
  };
  relationships?: {
    account: V1Relationships;
  };
};
export type V1ContentTemplateResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    description?: string;
    "template-type"?: "service" | "blueprint" | "survey";
  };
  relationships?: object;
};
export type V1IntegrationLogResource = {
  id?: number;
  type?: string;
  attributes?: {
    content: string;
    "created-at"?: string;
  };
  relationships?: {
    account: V1Relationships;
    "account-connection"?: V1Relationships;
  };
};
export type V1AccountFinancialResource = {
  id?: number;
  type: string;
  attributes: {
    "total-projects"?: number;
    won?: number;
    lost?: number;
    "total-contract-value"?: string;
    "total-contract-cost"?: string;
    "average-contract-value"?: string;
    "average-contract-margin"?: string;
    "monthly-projects"?: {
      month?: string;
      "start-at"?: string;
      revenue?: string;
      cost?: string;
      won?: number;
      lost?: number;
    }[];
  };
};
export type V1DataImportResource = {
  id?: string;
  type?: string;
  attributes?: {
    "import-type":
      | "standard_services"
      | "bill_of_material"
      | "quoted_services"
      | "service_locations"
      | "managed_services"
      | "resource_rates"
      | "resources"
      | "standard_products";
    "context-type": "Account" | "Project";
    "context-id": string;
    "data-file": string;
  };
  relationships?: {
    account: V1Relationships;
  };
};
export type V1FreeTrialResource = {
  id?: number;
  type: string;
  attributes: {
    "start-on"?: string;
    "end-on"?: string;
    "trial-plan-id"?:
      | "ScopeStack-Essentials-Plan-Monthly-USD"
      | "ScopeStack-Business-Plan-Monthly-USD"
      | "ScopeStack-Premium-Plan-Monthly-USD";
    status?: "active" | "purchased" | "canceled" | "expired";
  };
  relationships: {
    account: V1Relationships;
  };
};
export type V1LandingPageContentResource = {
  id?: number;
  type?: string;
  attributes?: {
    title?: string;
    content?: string;
    link?: string;
    icon?: string;
    stage?: string;
    "content-type"?: string;
  };
  relationships?: object;
};
export type V1SubscriptionResource = {
  id?: number;
  type?: string;
  attributes?: {
    "plan-name"?: string;
    "plan-id"?: string;
    "plan-revenue"?: {
      price?: number;
      billing_period_unit?: "month" | "year";
    };
    "plan-quantity"?: number;
    "max-users"?: number;
    status?: "building" | "pending" | "trial" | "active" | "canceled";
    entitlements?: {
      id?: string;
      name?: string;
      description?: string;
    }[];
    addons?: {
      id?: string;
      name?: string;
      description?: string;
    }[];
    "cancellation-reason"?: string;
    "canceled-on"?: string;
    "available-plans"?: {
      name?: string;
      id?: string;
      price?: number;
      minimum_quantity?: number;
    }[];
    "free-trial-available"?: boolean;
  };
  relationships?: {
    account: V1Relationships;
    "rate-table"?: V1Relationships;
  };
};
export type FavoriteProduct = {
  name?: string;
  description?: string;
  product_id?: string;
  sku?: string;
  manufacturer_name?: string;
  manufacturer_part_number?: string;
  vendor_name?: string;
  category?: string;
  subcategory?: string;
  unit_cost?: number;
  unit_price?: number;
  unit_of_measure?: string;
  vendor_discount?: number;
  markup?: number;
  vendor_rebate?: number;
};
export type FavoriteSurvey = {
  name?: string;
  description?: string;
};
export type V1UserFavoriteResource = {
  id?: number;
  type?: string;
  attributes?: {
    "user-id"?: number;
    name: string;
    source?: "standard" | "connectwise";
    source_key?: string;
    content_type?: string;
    content?: FavoriteProduct | FavoriteSurvey;
  };
  relationships?: {
    user?: V1Relationships;
  };
};
export type ProjectFilter = {
  showMyProjectsOnly?: boolean;
  showMyProjects?: boolean;
  tags?: string[];
  teams?: string[];
  status?: string[];
  clients?: string[];
  teammates?: string[];
  presalesEngineers?: string[];
  salesExecs?: string[];
  dateCreatedStart?: string[];
  dateCreatedEnd?: string[];
  dateEditedStart?: string[];
  dateEditedEnd?: string[];
};
export type ServiceFilter = {
  lobs?: string[];
  categories?: string[];
  frequency?: ("one_time" | "monthly" | "quarterly" | "yearly")[];
  phases?: string[];
  blueprints?: string[];
  questionnaires?: string[];
  startDateCreated?: string;
  endDateCreated?: string;
  startDateUpdated?: string;
  endDateUpdated?: string;
};
export type V1UserFilterResource = {
  id?: number;
  type?: string;
  attributes?: {
    "data-type": string;
    "data-path"?: string;
    name?: string;
    filters: ProjectFilter | ServiceFilter;
    active?: boolean;
  };
  relationships?: {
    account: V1Relationships;
    user: V1Relationships;
  };
};
export type V1UserViewResource = {
  id?: number;
  type?: string;
  attributes?: {
    name: string;
    columns?: {
      label?: string;
      width?: number;
      format?:
        | "currency"
        | "date"
        | "decimal"
        | "percent"
        | "string"
        | "titleize";
      optional?: boolean;
      selected?: boolean;
      sortable?: boolean;
      sorted?: "asc" | "desc";
      position?: number;
      "data-path"?: string;
      "data-type"?: string;
    };
    filters?: {
      showMyProjectsOnly?: boolean;
      showMyProjects?: boolean;
      tags?: string[];
      teams?: string[];
      status?: string[];
      clients?: string[];
      teammates?: string[];
      presalesEngineers?: string[];
      salesExecs?: string[];
      dateCreatedStart?: string[];
      dateCreatedEnd?: string[];
      dateEditedStart?: string[];
      dateEditedEnd?: string[];
    };
    "page-size"?: number;
    "view-type"?: "project" | "product";
  };
  relationships?: {
    account: V1Relationships;
    user?: V1Relationships;
  };
};
export type V1SharedSurveyResource = {
  id?: string;
  type?: string;
  attributes?: {
    "survey-status"?:
      | "pending"
      | "shared"
      | "completed"
      | "applied"
      | "revised";
    "sent-by"?: string;
    responses?: {
      "survey-response-id"?: number;
      "question-id"?: number;
      question?: string;
      answer?: string;
      "value-type"?:
        | "text"
        | "number"
        | "date"
        | "checkbox"
        | "email"
        | "phone";
      "select-options"?: {
        key?: string;
        value?: string;
        default?: boolean;
      }[];
      required?: boolean;
      position?: number;
    }[];
  };
  relationships?: {
    survey?: V1Relationships;
    questionnaire?: V1Relationships;
    questions?: V1ToManyRelationships;
  };
};
export type V1ConnectedAppResource = {
  id?: number;
  type?: string;
  attributes?: {
    service?: string;
    about?: string;
    logo?: string;
    summary?: string;
    help_url?: string;
    authorization?: {
      type?: string;
      token?: string;
    };
    providers?: {
      name?: string;
      logo?: string;
    }[];
    features?: string[];
    categories?: {
      category?: string;
      fields?: {
        label?: string;
        path?: string;
        help?: string;
        type?: string;
        options?: string[];
      }[];
    }[];
  };
};
export const {
  useWhoAmIQuery,
  useGetAccountQuery,
  useGetAccountLogoQuery,
  useUpdateAccountLogoMutation,
  useListApprovalStepsForAccountQuery,
  useGetApprovalStepQuery,
  useListAuditLogsQuery,
  useListProjectAuditLogsQuery,
  useGetAuditLogByIdQuery,
  useRevertAuditLogByIdMutation,
  useListBlueprintsForAccountQuery,
  useCreateBlueprintMutation,
  useCreateBlueprintFromProjectMutation,
  useGetBlueprintQuery,
  useUpdateBlueprintMutation,
  useDeleteBlueprintMutation,
  useListBlueprintItemsForAccountQuery,
  useCreateBlueprintItemMutation,
  useGetBlueprintItemQuery,
  useUpdateBlueprintItemMutation,
  useDeleteBlueprintItemMutation,
  useListBusinessUnitsForAccountQuery,
  useGetBusinessUnitQuery,
  useListClientsForAccountQuery,
  useCreateClientMutation,
  useGetClientQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useListContactsForAccountQuery,
  useCreateContactMutation,
  useGetContactByIdQuery,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useListCrmOpportunitiesForAccountQuery,
  useListCurrencysForAccountQuery,
  useCreateCurrencyMutation,
  useGetCurrencyQuery,
  useUpdateCurrencyMutation,
  useDeleteCurrencyMutation,
  useListCustomerSuccessesQuery,
  useCreateCustomerSuccessMutation,
  useGetCustomerSuccessQuery,
  useUpdateCustomerSuccessMutation,
  useDeleteCustomerSuccessMutation,
  useListDocumentTemplatesForAccountQuery,
  useCreateDocumentTemplateMutation,
  useGetDocumentTemplateQuery,
  useUpdateDocumentTemplateMutation,
  useDeleteDocumentTemplateMutation,
  useRestoreDocumentTemplateMutation,
  useGetFilenameFormatOptionsQuery,
  useListExpenseCategoriessForAccountQuery,
  useGetExpenseCategoryByIdQuery,
  useListGovernancesForAccountQuery,
  useGetGovernanceByIdQuery,
  useChangeGovernancePositionByIdMutation,
  useListIntegratedProductsQuery,
  useListLanguageFieldsForAccountQuery,
  useGetLanguageFieldByIdQuery,
  useChangeLanguageFieldPositionByIdMutation,
  useListLineOfBusinessesForAccountQuery,
  useCreateLineOfBusinessMutation,
  useGetLineOfBusinessQuery,
  useUpdateLineOfBusinessMutation,
  useDeleteLineOfBusinessMutation,
  useListNotesQuery,
  useCreateNoteMutation,
  useGetNoteQuery,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useListPartnerRequestsForAccountQuery,
  useCreatePartnerRequestMutation,
  useGetPartnerRequestQuery,
  useUpdatePartnerRequestMutation,
  useDeletePartnerRequestMutation,
  useCompletePartnerRequestMutation,
  useRevisePartnerRequestMutation,
  useDeclinePartnerRequestMutation,
  useCountPartnerRequestsQuery,
  useListExternalRequestsForAccountQuery,
  useGetExternalRequestQuery,
  useAcceptExternalRequestMutation,
  useOfferExternalRequestMutation,
  useDeclineExternalRequestMutation,
  useListPartnerRequestExternalServicesQuery,
  useListPaymentCreditsForAccountQuery,
  useCreatePaymentCreditMutation,
  useGetPaymentCreditQuery,
  useUpdatePaymentCreditMutation,
  useDeletePaymentCreditMutation,
  useListPaymentTermsForAccountQuery,
  useGetPaymentTermQuery,
  useListPerformanceIndicatorsForAccountQuery,
  useCreatePerformanceIndicatorMutation,
  useGetPerformanceIndicatorQuery,
  useUpdatePerformanceIndicatorMutation,
  useDeletePerformanceIndicatorMutation,
  useListPhasesForAccountQuery,
  useGetPhaseByIdQuery,
  useListPresalesEngineersForAccountQuery,
  useGetPresalesEngineerByIdQuery,
  useListPricingAdjustmentsForAccountQuery,
  useCreatePricingAdjustmentMutation,
  useGetPricingAdjustmentByIdQuery,
  useUpdatePricingAdjustmentByIdMutation,
  useDeletePricingAdjustmentByIdMutation,
  useListProductsForAccountQuery,
  useCreateProductMutation,
  useGetProductQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useListProjectsForAccountQuery,
  useCreateProjectMutation,
  useGetProjectByIdQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useRequestApprovalForProjectByIdMutation,
  useCancelApprovalRequestForProjectByIdMutation,
  useRestartScopingForProjectByIdMutation,
  useUpdateProjectToWonByIdMutation,
  useUpdateProjectToLostByIdMutation,
  useSyncProjectRevenueToCrmMutation,
  useDuplicateProjectByIdMutation,
  useExportProjectsMutation,
  useListProjectApprovalsForAccountQuery,
  useCreateProjectApprovalMutation,
  useGetProjectApprovalQuery,
  useUpdateProjectApprovalMutation,
  useDeleteProjectApprovalMutation,
  useApproveProjectApprovalByIdMutation,
  useCancelProjectApprovalByIdMutation,
  useDeclineProjectApprovalByIdMutation,
  useRescopeProjectApprovalByIdMutation,
  useListProjectAttachmentsForAccountQuery,
  useGetProjectAttachmentByIdQuery,
  useCountProjectAttachmentsQuery,
  useListAttachmentTypesForAccountQuery,
  useGetAttachmentTypeQuery,
  useApplyBlueprintToProjectMutation,
  useListProjectCollaboratorsQuery,
  useCreateProjectCollaboratorMutation,
  useGetProjectCollaboratorByIdQuery,
  useUpdateProjectCollaboratorMutation,
  useDeleteProjectCollaboratorMutation,
  useCountProjectCollaboratorsQuery,
  useListProjectConditionsForAccountQuery,
  useGetProjectConditionQuery,
  useUpdateProjectConditionMutation,
  useDeleteProjectConditionMutation,
  useSkipProjectConditionMutation,
  useRestoreProjectConditionMutation,
  useCountProjectConditionsQuery,
  useListProjectContactsQuery,
  useCreateProjectContactMutation,
  useGetProjectContactQuery,
  useUpdateProjectContactMutation,
  useDeleteProjectContactMutation,
  useCountProjectContactsQuery,
  useListProjectCreditsQuery,
  useCreateProjectCreditMutation,
  useGetProjectCreditByIdQuery,
  useUpdateProjectCreditMutation,
  useDeleteProjectCreditMutation,
  useListProjectDocumentsForAccountQuery,
  useCreateProjectDocumentMutation,
  useGetProjectDocumentQuery,
  useListDocumentDeliveriesForAccountQuery,
  useGetDocumentDeliveryQuery,
  useListProjectDownloadTypesForProjectQuery,
  useListProjectExpensesQuery,
  useCreateProjectExpenseMutation,
  useGetProjectExpenseByIdQuery,
  useUpdateProjectExpenseMutation,
  useDeleteProjectExpenseMutation,
  useCountProjectExpensesQuery,
  useListProjectGovernancesQuery,
  useCreateProjectGovernanceMutation,
  useGetProjectGovernanceByIdQuery,
  useUpdateProjectGovernanceMutation,
  useDeleteProjectGovernanceMutation,
  useChangeProjectGovernancePositionByIdMutation,
  useCountProjectGovernancesQuery,
  useListProjectLocationsQuery,
  useCreateProjectLocationMutation,
  useGetProjectLocationByIdQuery,
  useUpdateProjectLocationMutation,
  useDeleteProjectLocationMutation,
  useCountProjectLocationsQuery,
  useListProjectPhasesForAccountQuery,
  useCreateProjectPhaseMutation,
  useGetProjectPhaseByIdQuery,
  useUpdateProjectPhaseMutation,
  useDeleteProjectPhaseMutation,
  useListProjectProductsForAccountQuery,
  useCreateProjectProductMutation,
  useGetProjectProductQuery,
  useUpdateProjectProductMutation,
  useDeleteProjectProductMutation,
  useCountProjectProductsQuery,
  useListProjectPricingsQuery,
  useListProjectResourcesForAccountQuery,
  useCreateProjectResourceMutation,
  useGetProjectResourceQuery,
  useUpdateProjectResourceMutation,
  useCountProjectResourcesQuery,
  useListProjectServicesQuery,
  useCreateProjectServiceMutation,
  useGetProjectServiceByIdQuery,
  useUpdateProjectServiceMutation,
  useDeleteProjectServiceMutation,
  useSyncrhonizeStandardProjectServiceByIdMutation,
  useCountProjectServicesQuery,
  useListProjectSubservicesQuery,
  useCreateProjectSubserviceMutation,
  useGetProjectSubserviceByIdQuery,
  useUpdateProjectSubserviceMutation,
  useDeleteProjectSubserviceMutation,
  useSyncrhonizeStandardProjectSubserviceByIdMutation,
  useListProjectVariablesQuery,
  useGetProjectVariableByIdQuery,
  useListProjectVersionsQuery,
  useCreateProjectVersionMutation,
  useGetProjectVersionByIdQuery,
  useUpdateProjectVersionMutation,
  useRevertProjectVersionMutation,
  useCountProjectVersionsQuery,
  useListQuestionsForAccountQuery,
  useGetQuestionByIdQuery,
  useListQuestionnairesForAccountQuery,
  useCreateQuestionnaireMutation,
  useGetQuestionnaireByIdQuery,
  useUpdateQuestionnaireMutation,
  useDeleteQuestionnaireMutation,
  useListQuotesForAccountQuery,
  useCreateQuoteMutation,
  useGetQuoteQuery,
  useUpdateQuoteMutation,
  useDeleteQuoteMutation,
  useRecalculateQuoteMutation,
  useAcceptVendorDocumentForQuoteMutation,
  useCountQuotesQuery,
  useListRateTablesForAccountQuery,
  useCreateRateTableMutation,
  useGetRateTableQuery,
  useUpdateRateTableMutation,
  useDeleteRateTableMutation,
  useDuplicateRateTableMutation,
  useListResourcesForAccountQuery,
  useCreateResourceMutation,
  useGetResourceByIdQuery,
  useUpdateResourceMutation,
  useDeleteResourceMutation,
  useListResourcePlansQuery,
  useCreateResourcePlanMutation,
  useGetResourcePlanByIdQuery,
  useUpdateResourcePlanMutation,
  useDeleteResourcePlanMutation,
  useDuplicateResourcePlanMutation,
  useListResourceAllocationsQuery,
  useCreateResourceAllocationMutation,
  useGetResourceAllocationByIdQuery,
  useUpdateResourceAllocationMutation,
  useDeleteResourceAllocationMutation,
  useListResourceRatesForAccountQuery,
  useCreateResourceRateMutation,
  useGetResourceRateByIdQuery,
  useUpdateResourceRateMutation,
  useDeleteResourceRateMutation,
  useListSalesExecutivesForAccountQuery,
  useCreateSalesExecutiveMutation,
  useGetSalesExecutiveByIdQuery,
  useUpdateSalesExecutiveMutation,
  useDeleteSalesExecutiveMutation,
  useListServicesForAccountQuery,
  useCreateServiceMutation,
  useGetServiceByIdQuery,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  useActivateServiceMutation,
  useRestoreServiceMutation,
  useChangeServicePositionByIdMutation,
  useDuplicateServiceMutation,
  useDownloadServiceMutation,
  useDownloadListOfServicesMutation,
  useListSubservicesForAccountQuery,
  useCreateSubserviceMutation,
  useGetSubserviceByIdQuery,
  useUpdateSubserviceMutation,
  useDeleteSubserviceMutation,
  useActivateSubserviceMutation,
  useChangeSubservicePositionByIdMutation,
  useListServiceCategorysForAccountQuery,
  useGetServiceCategoryQuery,
  useListServiceConditionsForAccountQuery,
  useCreateServiceConditionMutation,
  useGetServiceConditionQuery,
  useUpdateServiceConditionMutation,
  useDeleteServiceConditionMutation,
  useListSubcontractorsForAccountQuery,
  useGetSubcontractorQuery,
  useListSurveysForAccountQuery,
  useCreateSurveyMutation,
  useGetSurveyByIdQuery,
  useUpdateSurveyMutation,
  useDeleteSurveyMutation,
  useCalculateSurveyRecommendationsMutation,
  useApplySurveyRecommendationsMutation,
  useShareSurveyMutation,
  useListSurveyResponsesForAccountQuery,
  useCreateSurveyResponseMutation,
  useGetSurveyResponseByIdQuery,
  useUpdateSurveyResponseMutation,
  useDeleteSurveyResponseMutation,
  useListSurveyRecommendationsForAccountQuery,
  useGetSurveyRecommendationByIdQuery,
  useUpdateSurveyRecommendationMutation,
  useDeleteSurveyRecommendationMutation,
  useApplySurveyRecommendationMutation,
  useListTagsForAccountQuery,
  useGetTagQuery,
  useGetServiceLevelsOfEffortQuery,
  useCreateLevelOfEffortForServiceMutation,
  useGetServiceLevelOfEffortQuery,
  useUpdateLevelOfEffortForServiceMutation,
  useDeleteLevelOfEffortFromServiceMutation,
  useGetSubserviceLevelsOfEffortQuery,
  useCreateLevelOfEffortForSubserviceMutation,
  useGetSubserviceLevelOfEffortQuery,
  useUpdateLevelOfEffortForSubserviceMutation,
  useDeleteLevelOfEffortFromSubserviceMutation,
  useGetServiceTaskRatesQuery,
  useCreateTaskRateForServiceMutation,
  useGetServiceTaskRateQuery,
  useUpdateTaskRateForServiceMutation,
  useDeleteTaskRateFromServiceMutation,
  useGetSubserviceTaskRatesQuery,
  useCreateTaskRateForSubserviceMutation,
  useGetSubserviceTaskRateQuery,
  useUpdateTaskRateForSubserviceMutation,
  useDeleteTaskRateFromSubserviceMutation,
  useListTeamsForAccountQuery,
  useCreateTeamMutation,
  useGetTeamQuery,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useListTermsForAccountQuery,
  useCreateTermMutation,
  useGetTermQuery,
  useUpdateTermMutation,
  useDeleteTermMutation,
  useListThirdPartyServicesForAccountQuery,
  useCreateThirdPartyServiceMutation,
  useGetThirdPartyServiceQuery,
  useUpdateThirdPartyServiceMutation,
  useDeleteThirdPartyServiceMutation,
  useMoveThirdPartyServiceMutation,
  useListUsersForAccountQuery,
  useCreateUserMutation,
  useShowCurrentUserForAccountQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useListVendorsForAccountQuery,
  useCreateVendorMutation,
  useGetVendorQuery,
  useUpdateVendorMutation,
  useDeleteVendorMutation,
  useListWebhookSubscriptionsForAccountQuery,
  useCreateWebhookSubscriptionMutation,
  useGetWebhookSubscriptionQuery,
  useUpdateWebhookSubscriptionMutation,
  useDeleteWebhookSubscriptionMutation,
  useListAccountConnectionsQuery,
  useCreateAccountConnectionMutation,
  useGetAccountConnectionQuery,
  useUpdateAccountConnectionMutation,
  useDeleteAccountConnectionMutation,
  useReconnectAccountConnectionMutation,
  useEnableAccountConnectionMutation,
  useDisableAccountConnectionMutation,
  useListContentTemplatesForAccountQuery,
  useGetContentTemplateQuery,
  useImportContentTemplateMutation,
  useListIntegrationLogsForAccountQuery,
  useGetIntegrationLogQuery,
  useGetAccountFinancialsQuery,
  useListDataImportsForAccountQuery,
  useCreateDataImportMutation,
  useGetDataImportByIdQuery,
  useListFreeTrialsForAccountQuery,
  useCreateFreeTrialMutation,
  useGetFreeTrialQuery,
  useCancelFreeTrialMutation,
  useListLandingPageContentQuery,
  useListSubscriptionsForAccountQuery,
  useCreateSubscriptionMutation,
  useGetSubscriptionQuery,
  useUpdateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetBySlugV1UserFavoritesQuery,
  useCreateUserFavoriteMutation,
  useDeleteUserFavoriteMutation,
  useListUserFiltersForAccountQuery,
  useCreateUserFilterMutation,
  useGetUserFilterQuery,
  useUpdateUserFilterMutation,
  useDeleteUserFilterMutation,
  useListUserViewsForAccountQuery,
  useCreateUserViewMutation,
  useGetUserViewQuery,
  useUpdateUserViewMutation,
  useDeleteUserViewMutation,
  useGetSharedSurveyByIdQuery,
  useUpdateSharedSurveyMutation,
  useListConnectedAppQuery,
  useCreateAccountMutation,
} = injectedRtkApi;
