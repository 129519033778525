import { Service } from "../../../common/types";
import { V1ServiceResource, ServiceFilter } from "@generated";
import { UserSelectionsState } from "../../types";

export const getServiceStateFilter = (tab) => {
  switch (tab) {
    case "active":
      return ["active"];
    case "pending":
      return ["pending", "custom"];
    case "archived":
      return ["deleted"];
  }
};

export const formatServiceData = (
  data: Service,
  accountId: number,
  managedService: boolean
): V1ServiceResource => {
  let service: V1ServiceResource = {
    id: Number(data.id),
    type: "services",
    attributes: {
      name: data?.name,
      "service-type": managedService
        ? "managed_services"
        : "professional_services",
      sku: data?.sku || undefined,
      "suggested-hours": data?.hours ? Number(data.hours) : undefined,
      "tag-list": data.tags.map((t) => t.label),
      teams: data.teams.map((t) => {
        return { id: t.value, name: t.label };
      }),
      "payment-frequency":
        (data?.frequency as "one_time" | "monthly" | "quarterly" | "yearly") ||
        undefined,
      languages: data?.languages,
      "service-description": data?.["service-description"],
      guidance: data?.guidance,
    },
    relationships: {
      account: {
        data: {
          id: accountId,
          type: "accounts",
        },
      },
      phase: {
        data: {
          type: "phases",
          id: Number(data.phase),
        },
      },
      "service-category": {
        data: {
          type: "service-categories",
          id: Number(data.category),
        },
      },
    },
  };

  if (data?.resource) {
    service.relationships.resource = {
      data: {
        type: "resources",
        id: Number(data.resource),
      },
    };
  }

  return service;
};

export const filtersChanged = (
  userSelections: UserSelectionsState,
  apiFilterParams: ServiceFilter
) => {
  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  }

  if (
    !arraysEqual(
      apiFilterParams?.blueprints,
      userSelections.blueprints.map((b) => b.value)
    ) ||
    !arraysEqual(
      apiFilterParams?.phases,
      userSelections.phases.map((p) => p.value)
    ) ||
    !arraysEqual(
      apiFilterParams?.categories,
      userSelections.categories.map((c) => c.value)
    ) ||
    !arraysEqual(
      apiFilterParams?.lobs,
      userSelections.lobs.map((l) => l.value)
    ) ||
    !arraysEqual(
      apiFilterParams?.questionnaires,
      userSelections.questionnaires?.map((q) => q.value)
    ) ||
    !arraysEqual(
      apiFilterParams?.frequency,
      userSelections.frequency?.map((f) => f.value)
    ) ||
    apiFilterParams?.startDateCreated !== userSelections.startDateCreated ||
    apiFilterParams?.endDateCreated !== userSelections.endDateCreated ||
    apiFilterParams?.startDateUpdated !== userSelections.startDateUpdated ||
    apiFilterParams?.startDateUpdated !== userSelections.startDateUpdated
  ) {
    return true;
  }
  return false;
};
