import { useEffect, useState, useCallback, useRef } from "react";
import "../style.css";
import { Row, Col, Form, FormCheck } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsFilter,
  faTrashCan,
  faClockRotateLeft,
  faPencil,
} from "@fortawesome/pro-solid-svg-icons";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import SearchField from "@components/Forms/SearchField/SearchField";
import SlideOutMenuSectioned from "@components/SlideOutMenu/SlideOutMenuSectioned";
import { Tab, Tabs } from "react-bootstrap";
import CompletedSurveys from "../CompletedSurveys/CompletedSurveys";
import TakeSurvey from "../TakeSurvey/TakeSurvey";
import SharedSurveys from "../SharedSurvey/SharedSurvey";
import FormDateField from "@components/Forms/FormDateField";
import ArchivedSurveys from "../ArchivedSurveys/ArchivedSurveys";
import MultiSelectAutocomplete from "@components/Forms/MultiSelectAutocomplete/MultiSelectAutocomplete";
import { useDeleteQuestionnaireMutation } from "@generated";
import Select from "react-select";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import ManageSurveys from "../ManageSurveys/ManageSurveys";
import useFlags from "@common/hooks/useFlags";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { TextField } from "@components/FormsV2";
import {
  useListTagsForAccount,
  useListUsersForAccount,
  useListTeamsForAccount,
  useListQuestionnairesForAccount,
} from "../api";

const SurveyTabs = ({ surveysLoading, accessToken }) => {
  const { surveysV2ManageEnable } = useFlags();
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [deleteQuestionnaire, {}] = useDeleteQuestionnaireMutation();
  const [surveysCount, setSurveysCount] = useState({
    takeSurveys: null,
    completedSurveys: null,
    sharedSurveys: null,
    archivedSurveys: null,
  });

  const { tags, error, isLoading: tagsLoading } = useListTagsForAccount();

  const { users, usersLoading } = useListUsersForAccount();

  const { teams, teamsLoading } = useListTeamsForAccount();

  const { questionnaires, questionnairesLoading } =
    useListQuestionnairesForAccount();

  const [currentTab, setCurrentTab] = useState(1);
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<{ id: number }[]>([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [showSlideOutMenu, setShowSlideOutMenu] = useState(false);
  const [clearAllFields, setClearAllFields] = useState(false);
  const [slideOutContent, setSlideOutContent] = useState<any>({
    title: "Filter By",
    content: [],
  });
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // Search/filter values
  const relatedProjectSearchRef = useRef(null);
  const [relatedProjectSearch, setRelatedProjectSearch] = useState<string>("");

  const completedBySearchRef = useRef(null);
  const [completedBySearch, setCompletedBySearch] = useState<string>("");

  const [tagsSearch, setTagsSearch] = useState<string[]>([]);
  const [tagsOptions, setTagsOptions] = useState<Option[]>([]);

  const [completedStartSearch, setCompletedStartSearch] =
    useState<string | null>(null);
  const [completedEndSearch, setCompletedEndSearch] =
    useState<string | null>(null);

  // filter page 2
  const sentBySearchRef = useRef(null);
  const [sentBySearch, setSentBySearch] = useState<Option | null>(null);
  const [usersOptions, setUsersOptions] = useState<Option[]>([]);

  const sentToSearchRef = useRef(null);
  const [sentToSearch, setSentToSearch] = useState<string>("");

  const [statusChecks, setStatusChecks] = useState([
    { label: "Pending", checked: false },
    { label: "Shared", checked: false },
    { label: "Completed", checked: false },
    { label: "Applied", checked: false },
    { label: "Revised", checked: false },
  ]);
  const statusForm = document.getElementById("statusForm");

  const [sentStartSearch, setSentStartSearch] = useState<string | null>(null);
  const [sentEndSearch, setSentEndSearch] = useState<string | null>(null);

  //filter page 3
  const [teamsSearch, setTeamsSearch] = useState<string[]>([]);
  const [teamsOptions, setTeamsOptions] = useState<Option[]>([]);

  const [deleteQueue, setDeleteQueue] = useState<number[]>([]);
  const [refetchManageSurveys, setRefetchManageSurveys] =
    useState<boolean>(false);

  useEffect(() => {
    if (tags?.length && !tagsOptions.length) {
      const tagsList = tags.map((tag) => {
        return {
          label: tag.name,
          value: String(tag.id),
        };
      });
      setTagsOptions(tagsList);
    }

    if (users?.length && !usersOptions.length) {
      const usersList = users.map((user) => {
        return {
          label: user.name,
          value: String(user.id),
        };
      });
      setUsersOptions(usersList);
    }

    if (teams?.length && !teamsOptions.length) {
      const teamsList = teams.map((team) => {
        return {
          label: team.name,
          value: String(team.id),
        };
      });
      setTeamsOptions(teamsList);
    }
  }, [tags, users, teams]);

  const handleSearch = (e) => {
    e.preventDefault();
    searchSurveyName(searchInput, true);
  };

  const searchSurveyName = (input, clearFilterFields) => {
    if (input !== "") {
      handleClearFields();
      setFilterParams({ name: input });
    } else {
      if (clearFilterFields) handleClearFields();
      return;
    }
  };

  const archiveSurveys = () => {
    switch (currentTab) {
      case 4:
        let surveyId = deleteQueue[0];
        deleteQuestionnaire({ slug: accountSlug, id: surveyId })
          .unwrap()
          .then((response) => {
            setRefetchManageSurveys(true);
            if (!deleteQueue.slice(1).length) {
              setSuccessMessage("Questionnaire(s) archived.");
              setShowSuccessAlert(true);
            }
          })
          .catch((error) => {
            setErrorMessage("Something went wrong.");
            setShowFailAlert(true);
          });
        setDeleteQueue(deleteQueue.slice(1));
        break;
    }
  };

  const handleCancel = () => {
    setShowArchiveModal(false);
    setSelectedItems([]);
    setDeleteQueue([]);
  };

  const handleDelete = () => {
    setDeleteQueue([...selectedItems.map((item) => Number(item))]);
    setShowArchiveModal(true);
  };

  const unarchiveSurveys = () => {
    setShowArchiveModal(false);
  };

  const handleConfirmArchive = () => {
    archiveSurveys();
    if (deleteQueue.length <= 1) {
      setShowArchiveModal(false);
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    if (clearAllFields) {
      setSlideOutContent({
        title: "Filter By",
        content: getFilterSections(),
      });
      setClearAllFields(false);
    }
  }, [clearAllFields]);

  const getArchiveModalBody = () => {
    const target = deleteQueue[0];
    const surveyToDelete = questionnaires?.find((q) => Number(q.id) === target);
    const name = surveyToDelete ? surveyToDelete.name : "This survey";
    const oneSelected = selectedItems.length === 1;
    return (
      <div>
        <p>
          {currentTab === 5 ? (
            <>
              <strong>
                Un-archive {selectedItems.length} Survey{oneSelected ? "" : "s"}
                ?
              </strong>
              <br />
              <br />
              {oneSelected ? "This survey" : "These surveys"} will be moved to
              an active surveys tab.
            </>
          ) : (
            <>
              <strong>{name} is about to be archived.</strong>
              <br />
              <br />
              This can be undone and will be moved to the archived surveys tab.
            </>
          )}
        </p>
      </div>
    );
  };

  interface Option {
    label: string;
    value: string;
  }

  const getFilterSections = () => {
    let tab = currentTab;
    if (currentTab > 3) tab = 3;
    switch (tab) {
      case 1:
        return [
          {
            sectionTitle: "Survey Details",
            sectionClass: "",
            sectionContent: (
              <div className="slideOutSection">
                <MultiSelectAutocomplete
                  className="slideOutMultiSelect"
                  label="Teams"
                  width="500px"
                  options={teamsOptions}
                  value={undefined}
                  onChange={(e) => setTeamsSearch(e.map((t) => t.value))}
                  onInputChange={undefined}
                  updateRef={clearAllFields}
                />
                <MultiSelectAutocomplete
                  className="slideOutMultiSelect"
                  label="Tags"
                  width="500px"
                  options={tagsOptions}
                  value={undefined}
                  onChange={(e) => setTagsSearch(e.map((tag) => tag.label))}
                  onInputChange={undefined}
                  updateRef={clearAllFields}
                />
              </div>
            ),
          },
        ];
      case 2:
        return [
          {
            sectionTitle: "Survey Details",
            sectionClass: "",
            sectionContent: (
              <div className="slideOutSection">
                {/* <div className="singleSelect">
                  <TextField
                    id="relatedProject"
                    label="Related Project"
                    placeholder="Search by project name"
                    value={undefined}
                    onChange={(e) => setRelatedProjectSearch(e.target.value)}
                    updateRef={clearAllFields}
                    className="textFieldFilter"
                  />
                </div> */}
                <div className="singleSelect">
                  <TextField
                    id="contact_name"
                    label="Completed By"
                    placeholder="Search by name"
                    value={undefined}
                    onChange={(e) => setCompletedBySearch(e.target.value)}
                    updateRef={clearAllFields}
                    className="textFieldFilter"
                  />
                </div>

                <MultiSelectAutocomplete
                  className="slideOutMultiSelect"
                  label="Tags"
                  width="500px"
                  options={tagsOptions}
                  value={undefined}
                  onChange={(e) => setTagsSearch(e.map((tag) => tag.label))}
                  onInputChange={undefined}
                  updateRef={clearAllFields}
                />
              </div>
            ),
          },
          {
            sectionTitle: "Completed On",
            sectionClass: "",
            sectionContent: (
              <div className="slideOutSection dateFilter">
                <div className="dateField">
                  <FormDateField
                    label="Start Date"
                    placeholder="Search start date"
                    value={undefined}
                    onChange={(e) => setCompletedStartSearch(e.target.value)}
                    required={completedEndSearch !== ""}
                    defaultValue={undefined}
                    updateRef={clearAllFields}
                  />
                </div>{" "}
                To
                <div className="dateField">
                  <FormDateField
                    label="End Date"
                    placeholder="Search end date"
                    value={undefined}
                    onChange={(e) => setCompletedEndSearch(e.target.value)}
                    required={completedStartSearch !== ""}
                    defaultValue={null}
                    updateRef={clearAllFields}
                  />
                </div>
              </div>
            ),
          },
        ];
      case 3:
        return [
          {
            sectionTitle: "Survey Details",
            sectionClass: "",
            sectionContent: (
              <div className="slideOutSection">
                <div className="singleSelect">
                  <FormFieldLabel className="filterMenuLabel" label="Sent By" />
                  <Select
                    className="slideOutMultiSelect"
                    placeholder="Search and select"
                    options={usersOptions}
                    onChange={(e) => e && setSentBySearch(e as Option)}
                    ref={sentBySearchRef}
                  />
                </div>
                <div className="singleSelect">
                  <TextField
                    id="emails"
                    label="Sent To"
                    placeholder="Search by email"
                    value={undefined}
                    onChange={(e) => setSentToSearch(e.target.value)}
                    updateRef={clearAllFields}
                    className="textFieldFilter"
                  />
                </div>
              </div>
            ),
          },
          {
            sectionTitle: "Status",
            sectionClass: "",
            sectionContent: (
              <Form id="statusForm">
                <div className="slideOutSection statusOptions">
                  {statusChecks.map((option, i) => {
                    return (
                      <div key={i} className="surveyStatusOption">
                        <FormCheck
                          required={false}
                          id={option.label}
                          className="statusOption"
                          inline
                          key={option.label}
                          onChange={(e) => {
                            let updated = statusChecks.map((status) => {
                              if (status.label == e.target.id) {
                                status.checked = !status.checked;
                                return status;
                              } else {
                                return status;
                              }
                            });
                            setStatusChecks(updated);
                          }}
                        />{" "}
                        {option.label}
                      </div>
                    );
                  })}
                </div>
              </Form>
            ),
          },
          {
            sectionTitle: "Sent On",
            sectionClass: "",
            sectionContent: (
              <div className="slideOutSection dateFilter">
                <div className="dateField">
                  <FormDateField
                    label="Start Date"
                    placeholder="Search start date"
                    value={undefined}
                    onChange={(e) => setSentStartSearch(e.target.value)}
                    required={sentEndSearch !== ""}
                    defaultValue={undefined}
                    updateRef={clearAllFields}
                  />{" "}
                </div>
                To
                <div className="dateField">
                  <FormDateField
                    label="End Date"
                    placeholder="Search end date"
                    value={undefined}
                    onChange={(e) => setSentEndSearch(e.target.value)}
                    required={sentStartSearch !== ""}
                    defaultValue={undefined}
                    updateRef={clearAllFields}
                  />
                </div>
              </div>
            ),
          },
        ];
    }
  };

  const handleApplyFilters = () => {
    setSearchInput("");
    searchSurveyName("", false);
    let params = {};
    if (currentTab === 1) {
      //Take a Survey filters
      if (tagsSearch.length) params["tag-list"] = tagsSearch;
      if (teamsSearch.length) params["team.id"] = teamsSearch;
    } else if (currentTab === 2) {
      // Completed Surveys filters
      if (relatedProjectSearch) params["project-name"] = relatedProjectSearch;
      if (completedBySearch) params["contact-name"] = completedBySearch;
      if (tagsSearch.length) params["tag-list"] = tagsSearch;
      if (completedStartSearch && completedEndSearch) {
        params["updated-at.after"] = completedStartSearch;
        params["updated-at.before"] = completedEndSearch;
      } else if (completedStartSearch || completedEndSearch) {
        setErrorMessage("Please select both a start and end date.");
        setShowFailAlert(true);
        return;
      }
    } else if (currentTab === 3) {
      //Shared Surveys filters
      if (sentBySearch) params["sender-id"] = sentBySearch.value;
      if (sentToSearch) params["emails"] = sentToSearch;
      const selectedStatuses = statusChecks
        .filter((s) => s.checked)
        .map((s) => s.label.toLowerCase());
      if (selectedStatuses.length) params["status"] = selectedStatuses;
      if (sentStartSearch && sentEndSearch) {
        params["created-at.after"] = sentStartSearch;
        params["created-at.before"] = sentEndSearch;
      } else if (sentStartSearch || sentEndSearch) {
        setErrorMessage("Please select both a start and end date.");
        setShowFailAlert(true);
        return;
      }
    }
    setFilterParams(params);
    setShowSlideOutMenu(false);
  };
  const handleClearFields = () => {
    setClearAllFields(true);
    // @ts-ignore
    relatedProjectSearchRef?.current?.clearValue();
    // @ts-ignore
    completedBySearchRef?.current?.clearValue();
    // @ts-ignore
    sentToSearchRef?.current?.clearValue();
    // @ts-ignore
    sentBySearchRef?.current?.clearValue();
    // @ts-ignore
    statusForm?.reset();
    setFilterParams({});
    setRelatedProjectSearch("");
    setCompletedBySearch("");
    setTagsSearch([]);
    setCompletedStartSearch(null);
    setCompletedEndSearch(null);
    setSentBySearch(null);
    setSentToSearch("");
    setStatusChecks(
      statusChecks.map((s) => {
        return { ...s, checked: false };
      })
    );
    setSentStartSearch(null);
    setSentEndSearch(null);
    setTeamsSearch([]);

    setShowSlideOutMenu(false);
  };

  const handleTabSelect = useCallback((eventKey) => {
    setCurrentTab(Number(eventKey));
    setSelectedItems([]);
    setSearchInput("");
    handleClearFields();
  }, []);

  //Get count of any active filters that are not 'name' (i.e., everything but the search filter)
  const filterCount = Object.keys(filterParams).filter(
    (f) => f !== "name"
  ).length;

  const isLoading =
    surveysLoading ||
    tagsLoading ||
    questionnairesLoading ||
    usersLoading ||
    teamsLoading;
  return (
    <div className="surveysPageContainer ">
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {!isLoading && (
        <SlideOutMenuSectioned
          show={showSlideOutMenu}
          setShow={(showSlideOutMenu) => setShowSlideOutMenu(showSlideOutMenu)}
          menuHeader={slideOutContent.title}
          actionButtonText={
            slideOutContent.title == "Filter By" ? "Apply" : null
          }
          onActionButtonClick={() => {
            if (slideOutContent.title === "Filter By") {
              handleApplyFilters();
            } else {
              setShowSlideOutMenu(false);
            }
          }}
          clearFields={filterCount ? `Clear All (${filterCount})` : null}
          onCancel={() => setShowSlideOutMenu(false)}
          onClearFieldsClick={handleClearFields}
          sectionsArray={slideOutContent.content}
        />
      )}
      <ScopeStackModal
        modalTitle={`${currentTab === 5 ? "Un-archive" : "Archive"} Surveys`}
        modalBody={getArchiveModalBody()}
        button1Text="Cancel"
        handleButton1Click={handleCancel}
        button2Text={currentTab === 5 ? "Un-archive" : "Archive"}
        handleButton2Click={() =>
          currentTab === 5 ? unarchiveSurveys() : handleConfirmArchive()
        }
        handleClose={handleCancel}
        show={showArchiveModal}
      />
      <div className="surveysHeader">
        <h1>Surveys</h1>
      </div>
      <Row className="searchContainer">
        {selectedItems.length > 0 ? (
          <Col sm={1} className="selectedSurveysBox">
            <div className="flexCenter deleteSurveysBox">
              <div className="surveysSelected">
                {selectedItems.length} Selected
              </div>
              <div className="deleteSurveysDivider"></div>
              <FontAwesomeIcon
                style={{
                  color: "#1C2655",
                  marginRight: "6px",
                  cursor: "pointer",
                }}
                icon={currentTab === 5 ? faClockRotateLeft : faTrashCan}
                onClick={handleDelete}
              />
            </div>
          </Col>
        ) : null}

        <Col sm={selectedItems.length == 0 && currentTab > 3 ? 12 : 11}>
          <span className="searchLabel">Search</span>
          <div className="searchRow">
            <Form
              style={{ width: "100%" }}
              onSubmit={handleSearch}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  const input = e.target as HTMLInputElement;
                  setSearchInput(searchInput + "");
                }
              }}
            >
              <SearchField
                placeholder="Search by survey name"
                value={searchInput}
                onChange={(e) => {
                  const input = e.target.value;
                  setSearchInput(input);
                }}
                onClick={() => {
                  setSearchInput("");
                  searchSurveyName("", true);
                }}
                disabled={isLoading}
              />
            </Form>
          </div>
        </Col>
        {currentTab < 4 && !isLoading ? (
          <Col className="filterSurveys">
            <FontAwesomeIcon
              onClick={() => {
                setSlideOutContent({
                  title: "Filter By",
                  content: getFilterSections(),
                });
                setShowSlideOutMenu(true);
              }}
              icon={faBarsFilter}
              className={`filterIcon ${filterCount ? "activeFilters" : ""} cursorPoint`}
            />
            {filterCount ? (
              <span className="activeFilters">{`${filterCount}`} Active</span>
            ) : null}
          </Col>
        ) : null}
      </Row>
      <div>
        <Tabs
          defaultActiveKey={1}
          id="uncontrolled-tab"
          className="mb-3"
          onSelect={handleTabSelect}
        >
          <Tab
            eventKey={1}
            title={`Take a Survey ${
              currentTab === 1 && surveysCount.takeSurveys !== null
                ? `(${surveysCount.takeSurveys})`
                : ""
            }`}
            tabClassName="surveysTab"
            className="surveysTabContent takeSurveys"
          >
            {currentTab === 1 && (
              <TakeSurvey
                filterParams={filterParams}
                surveysCount={surveysCount}
                setSurveysCount={setSurveysCount}
                setShowSlideOutMenu={setShowSlideOutMenu}
                setSlideOutContent={setSlideOutContent}
                accessToken={accessToken}
              />
            )}
          </Tab>
          <Tab
            eventKey={2}
            title={`Completed Surveys ${
              currentTab === 2 && surveysCount.completedSurveys !== null
                ? `(${surveysCount.completedSurveys})`
                : ""
            }`}
            tabClassName="surveysTab"
            className="surveysTabContent completedSurveys"
          >
            {currentTab === 2 && (
              <CompletedSurveys
                surveysCount={surveysCount}
                setSurveysCount={setSurveysCount}
                filterParams={filterParams}
              />
            )}
          </Tab>
          <Tab
            eventKey={3}
            title={`Shared Surveys ${
              currentTab === 3 && surveysCount.sharedSurveys !== null
                ? `(${surveysCount.sharedSurveys})`
                : ""
            }`}
            tabClassName="surveysTab"
            className="surveysTabContent sharedSurveys"
          >
            {currentTab == 3 && (
              <SharedSurveys
                surveysCount={surveysCount}
                setSurveysCount={setSurveysCount}
                filterParams={filterParams}
                currentTab={currentTab}
              />
            )}
          </Tab>

          {surveysV2ManageEnable && (
            <Tab
              eventKey={4}
              title={
                <>
                  <FontAwesomeIcon className="tabIcon" icon={faPencil} />
                  Manage Surveys
                </>
              }
              tabClassName="surveysTab"
              className="surveysTabContent manageSurveys"
            >
              {currentTab == 4 && (
                <ManageSurveys
                  filterParams={filterParams}
                  selected={selectedItems}
                  setSelected={setSelectedItems}
                  refetchSurveys={refetchManageSurveys}
                  setRefetchSurveys={setRefetchManageSurveys}
                />
              )}
            </Tab>
          )}
          {/* <Tab
            eventKey={5}
            title={
              <>
                <FontAwesomeIcon className="tabIcon" icon={faTrashCan} />
                Archived Surveys (0)
              </>
            }
            tabClassName="surveysTab"
            className="surveysTabContent archivedSurveys"
          >
            <ArchivedSurveys
              surveysData={surveys}
            />
          </Tab> */}
        </Tabs>
      </div>
    </div>
  );
};

export default SurveyTabs;
