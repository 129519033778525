import { Card } from "react-bootstrap";
import FormTextField from "@components/Forms/FormTextField";
import { FileUpload } from "@components/FormsV2";
import { Col, Row, Button, Form } from "react-bootstrap";
import { useState, ChangeEvent, useEffect, FormEventHandler } from "react";
import "../styles.css";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useUpdateCompanyAccountMutation } from "../../../../services/ScopeStackAPI";
import { AccountInput, User } from "app/javascript/src/types";
import {
  handleAlert,
  formatUnprocessibleResponse,
  capitalizeFirstLetter,
} from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import useWhoAmI from "../api/useWhoAmI";
import useGetAccount from "../api/useGetAccount";
import fileToBase64 from "@utils/fileToBase64";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import {
  V1AccountLogoResource,
  useGetAccountLogoQuery,
  useUpdateAccountLogoMutation,
  useUpdateUserMutation,
  V1UserResourceWrite,
} from "@generated";

const CompanyInfo = (): JSX.Element => {
  const [updateCompanyAccount] = useUpdateCompanyAccountMutation();
  const [updateLogo] = useUpdateAccountLogoMutation();
  const [updateUser] = useUpdateUserMutation();

  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const {
    data: account,
    error: accountError,
    isLoading: accountLoading,
    isFetching: accountFetching,
    refetch,
  } = useGetAccount(accountSlug);

  const { data: logo, isLoading: logoLoading } = useGetAccountLogoQuery({
    slug: accountSlug,
  });

  const { data: user, error: userError, isLoading: userLoading } = useWhoAmI();

  const isLoading = accountLoading || userLoading || accountFetching;

  const [initialCompanyInfo, setInitialCompanyInfo] = useState<AccountInput>({
    slug: "",
    data: {
      id: "",
      type: "",
      attributes: {
        name: "",
        streetAddress: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
      },
    },
  });

  const [initialPersonalInfo, setInitialPersonalInfo] = useState<User>({
    id: "",
    type: "users",
    attributes: {
      name: "",
      email: "",
      phone: "",
      title: "",
    },
  });

  const [companyName, setCompanyName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postcode, setPostcode] = useState("");
  const [firstLastName, setFirstLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [logoSrc, setLogoSrc] = useState<string | undefined>("");

  // Toasts
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Personal info updated successfully!"
  );

  useEffect(() => {
    if (!isLoading && account && user) {
      const {
        attributes: { name, streetAddress, city, state, postalCode },
      } = account;

      const address = streetAddress?.split("\n") || [""];

      setInitialCompanyInfo({
        slug: account.attributes.slug || "",
        data: {
          id: account.id,
          type: account.type,
          attributes: {
            name,
            streetAddress: address[0],
            addressLine2: address?.[1] || "",
            city,
            state,
            postalCode,
          },
        },
      });

      setCompanyName(name || "");
      setStreetAddress(address[0]);
      setAddressLine2(address?.[1] || "");
      setCity(city || "");
      setState(state || "");
      setPostcode(postalCode || "");

      const {
        attributes: { name: userFullName, email, phone, title },
      } = user;

      setInitialPersonalInfo({
        id: user.id,
        type: "users",
        attributes: {
          name: userFullName || "",
          email: email || "",
          phone: phone || "",
          title: title || "",
        },
      });

      setFirstLastName(userFullName || "");
      setEmail(email || "");
      setPhone(phone || "");
    }
  }, [isLoading]);

  useEffect(() => {
    const newLogoSrc = logo?.data?.attributes?.logo ?? "";
    if (newLogoSrc) {
      setLogoSrc(newLogoSrc);
    }
  }, [logo]);

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSuccessMessage("Company info updated successfully!");

    if (!account) return; // early return if no account data is available

    const updatedAccount: AccountInput = {
      slug: account.attributes.slug || "",
      data: {
        id: account.id,
        type: account.type,
        attributes: {
          name: companyName || "",
          streetAddress: addressLine2
            ? streetAddress + "\n" + addressLine2
            : streetAddress,
          city: city || "",
          state: state || "",
          postalCode: postcode || "",
        },
      },
    };
    updateCompanyAccount(updatedAccount).then((res: any) => {
      if (res.error) {
        if (res.error.status == 422) {
          setErrorMessages(
            `Company info could not be saved. ${capitalizeFirstLetter(
              res.error.data.errors[0].detail
            )}.`
          );
        } else {
          setErrorMessages(
            "Something went wrong! Some of your changes could not be saved at this time."
          );
        }
        setShowFailAlert(true);
      } else {
        setShowSuccessAlert(true);
        refetch();
      }
    });
  };

  const submitPersonalInfo = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSuccessMessage("Personal info updated successfully!");

    if (!user) return; // early return if no user data is available

    const data: V1UserResourceWrite = {
      id: Number(user.id),
      type: "users",
      attributes: {
        name: firstLastName || "",
        email: email || "",
        phone: phone || "",
        title: user.attributes.title || "",
        "password-confirmation": password || "",
      },
    };

    updateUser({ slug: accountSlug, id: Number(user.id), body: { data } })
      .then((res) => {
        //@ts-ignore
        if (res?.data?.data?.id) {
          setShowSuccessAlert(true);
          setPassword("");
        } else {
          setErrorMessages(
            //@ts-ignore
            res?.error?.data?.errors?.[0]?.detail ||
              "Something went wrong, changes were not saved."
          );
          setShowFailAlert(true);
        }
      })
      .catch((error) => {
        setErrorMessages(formatUnprocessibleResponse(error, "company info"));
        setShowFailAlert(true);
      });
  };

  const isCompanyInfoChanged =
    JSON.stringify(initialCompanyInfo) !==
    JSON.stringify({
      slug: account?.attributes.slug || "",
      data: {
        id: account?.id,
        type: account?.type,
        attributes: {
          name: companyName || "",
          streetAddress: streetAddress || "",
          addressLine2: addressLine2 || "",
          city: city || "",
          state: state || "",
          postalCode: postcode || "",
        },
      },
    });

  const isPersonalInfoChanged =
    JSON.stringify(initialPersonalInfo) !==
    JSON.stringify({
      id: user?.id,
      type: "users",
      attributes: {
        name: firstLastName || "",
        email: email || "",
        phone: phone || "",
        title: user?.attributes.title || "",
      },
    });

  const handleFileSelect = async (file: File) => {
    const encodedFile = await fileToBase64(file);

    if (!account || !account.attributes?.slug) return;

    const updatedLogo: V1AccountLogoResource = {
      id: (logo && logo.data && logo.data.id) || undefined,
      type: "account-logos",
      attributes: {
        logo: encodedFile,
      },
    };

    setLogoSrc(encodedFile);

    updateLogo({ slug: accountSlug, body: { data: updatedLogo } }).catch(
      (error) => {
        setErrorMessages(formatUnprocessibleResponse(error, "company info"));
        setShowFailAlert(true);
      }
    );
  };

  return (
    <div>
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Row>
        <Col sm={6}>
          <Card className="whiteBg">
            <Card.Header className="headerOverride">Company Info</Card.Header>
            <hr className="cardHeadDivider" />
            <Card.Body className="whiteBg">
              <Form onSubmit={submit}>
                <FormTextField
                  id="companyName"
                  required={true}
                  label="Company Name"
                  value={companyName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCompanyName(e.target.value)
                  }
                  placeholder={"Name"}
                  readOnly={false}
                />
                <FormTextField
                  id="addressLine1"
                  required={true}
                  label="Street Address"
                  value={streetAddress}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setStreetAddress(e.target.value)
                  }
                  placeholder={"Street Address"}
                  readOnly={false}
                />
                <FormTextField
                  id="addressLine2"
                  required={false}
                  label="Address Line 2"
                  value={addressLine2}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAddressLine2(e.target.value)
                  }
                  placeholder={"Address Line 2"}
                  readOnly={false}
                />
                <Row>
                  <Col>
                    <FormTextField
                      id="city"
                      required={true}
                      label="City"
                      value={city}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setCity(e.target.value)
                      }
                      placeholder={"City"}
                      readOnly={false}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      id="state"
                      required={true}
                      label="State"
                      value={state}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setState(e.target.value)
                      }
                      placeholder={"State"}
                      readOnly={false}
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      id="postcode"
                      required={true}
                      label="Postcode"
                      value={postcode}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPostcode(e.target.value)
                      }
                      placeholder={"Postcode"}
                      readOnly={false}
                    />
                  </Col>
                </Row>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!isCompanyInfoChanged}
                >
                  Save
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6}>
          <Card className="whiteBg">
            <Card.Header className="headerOverride">Personal Info</Card.Header>
            <hr className="cardHeadDivider" />
            <Card.Body className="whiteBg">
              <Form onSubmit={submitPersonalInfo}>
                <FormTextField
                  id="firstLastName"
                  required={true}
                  label="First & Last Name"
                  value={firstLastName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFirstLastName(e.target.value)
                  }
                  placeholder={"Name"}
                  readOnly={false}
                />
                <FormTextField
                  id="streetAddress"
                  required={true}
                  label="Email"
                  value={email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                  placeholder={"Email"}
                  readOnly={false}
                />
                <FormTextField
                  id="phone"
                  required={false}
                  label="Phone"
                  value={phone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPhone(e.target.value)
                  }
                  placeholder={"Phone"}
                  readOnly={false}
                />
                <Form.Group className="formGroup">
                  <FormFieldLabel className={""} label={"*Password"} />
                  <Form.Control
                    type="password"
                    autoComplete="new-password"
                    placeholder={"Confirm password to save changes"}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                  />
                </Form.Group>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!isPersonalInfoChanged || !password}
                >
                  Save
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6}>
          <Card className="whiteBg">
            <Card.Header className="headerOverride">Branding</Card.Header>
            <hr className="cardHeadDivider" />
            <Card.Body className="whiteBg">
              <p style={{ marginBottom: "1em" }}>
                Upload your company’s logo to white-label your document
                templates
              </p>
              {logoSrc && <img src={logoSrc} style={{ width: "100%" }} />}
              <div style={{ marginTop: "1.5em" }}>
                <FileUpload onFileSelect={handleFileSelect}>
                  Upload File
                </FileUpload>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyInfo;
