import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import ControlledDropdown from "@components/Forms/ControlledDropDown";
import FormNumberField from "@components/Forms/FormNumberField";
import FormTextField from "@components/Forms/FormTextField";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import { useNavigate } from "react-router-dom";
import Select2 from "@components/Forms/Select2/Select2";
import {
  formatUnprocessibleResponse,
  renderResourceName,
  renderResourceOptions,
  findResourceByNameAndReturnPromise,
} from "@utils/helperFunctions";

function ExpenseComponent({
  account_slug,
  project_id,
  account_id,
  projectStatus,
  expenses,
  resources,
  projectResources,
  phases,
  services,
  expenseId,
  expenseCategories,
  setExpenseUpdated,
  setExpenseCreated,
  expenseCreated,
  expenseUpdated,
  currencyUnit,
  rateTableId,
  authorizationCode,
  lobs,
  isResourcesFromRates,
}) {
  const [resource, setResource] = useState({
    value: -1,
    label: "Pick Resource",
  });
  const [newResourceId, setNewResourceId] = useState(null);
  const [expense, setExpense] = useState("");
  const [newExpenseId, setNewExpenseId] = useState(null);
  const [phase, setPhase] = useState("Assign to Phase");
  const [newPhaseId, setNewPhaseId] = useState(null);
  const [projectServiceId, setProjectServiceId] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [markup, setMarkup] = useState("0.00");
  const [rate, setRate] = useState("0.0");
  const [amountDisabled, setAmountDisabled] = useState(false);
  const [phaseDisabled, setPhaseDisabled] = useState(false);
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);
  const [useResourceRate, setUseResourceRate] = useState(false);
  const [filteredResources, setFilteredResources] = useState([]);

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);

  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();

  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  useEffect(() => {
    let filteredResources = resources;
    if (isResourcesFromRates) {
      setFilteredResources(resources);
    } else {
      filteredResources = resources.filter(
        (resource) => resource.attributes.active === true
      );
      setFilteredResources(filteredResources);
    }
    let serviceOptions = services.map((item) => {
      return { label: item.attributes.name, value: item.id };
    });
    setServiceOptions(serviceOptions);

    // If we are on the edit expense page, set the states accordingly
    if (lastWordInLocation === "edit") {
      if (expenseCreated === true) {
        setCreatedAlertIsOpen(true);
      }
      for (let i = 0; i < expenses.length; i++) {
        if (expenses[i].id === id) {
          let relationships = expenses[i].relationships;
          if (relationships && relationships["project-service"].data) {
            let service = services.filter(
              (item) => item.id === relationships["project-service"].data.id
            );

            if (service.length > 0) {
              setProjectServiceId(service[0].id);
              setSelectedService({
                label: service[0].attributes.name,
                value: service[0].id,
              });
            }
          }

          // Iterate over resources and match them with the expense based on id
          let projectResourceRel =
            expenses[i].relationships["project-resource"].data;
          let identified = false;
          for (let i = 0; i < projectResources.length; i++) {
            let attr = projectResources[i].attributes;
            if (
              projectResourceRel &&
              projectResourceRel.id == projectResources[i].id
            ) {
              identified = true;
              setResource({
                value: projectResources[i].id,
                label: renderResourceName(projectResources[i], lobs),
              });
            }
          }

          if (!identified && expenses[i].relationships.resource.data) {
            let resourceId = expenses[i].relationships.resource.data.id;
            for (let i = 0; i < filteredResources.length; i++) {
              let attr = filteredResources[i].attributes;
              if (resourceId == filteredResources[i].id) {
                setResource({
                  value: filteredResources[i].id,
                  label: renderResourceName(filteredResources[i], lobs),
                });
              }
            }
          }

          // Set description, Quantity, & Rate
          setDescription(expenses[i].attributes.description);
          setQuantity(expenses[i].attributes.quantity);
          setMarkup(expenses[i].attributes.markup);
          let rate = expenses[i].attributes.rate;
          if (rate !== null) setRate(rate);

          // Match project phase by id
          let phaseData = expenses[i].relationships["project-phase"].data;
          for (let j = 0; j < phases.length; j++) {
            if (phaseData && phases[j].id === phaseData.id) {
              setPhase(phases[j].attributes.name);
            }
          }

          let expenseCategoryData =
            expenses[i].relationships["expense-category"].data;
          for (let j = 0; j < expenseCategories.length; j++) {
            if (
              expenseCategoryData !== null &&
              expenseCategories[j].id === expenseCategoryData.id
            ) {
              let expense = expenseCategories[j].attributes.name;
              setExpense(expense);
              let useResourceRate =
                expenseCategories[j].attributes["use-resource-rate"];
              setUseResourceRate(useResourceRate);
              let rate = expenseCategories[j].attributes.rate;
              if (useResourceRate && resource !== "") {
                handleResourceRate(resource, expense);
              }
              if (rate !== null || useResourceRate) {
                setAmountDisabled(true);
              }
              if (rate === null && !useResourceRate) {
                setAmountDisabled(false);
              }
              let phaseData =
                expenseCategories[j].relationships["phase"] &&
                expenseCategories[j].relationships["phase"].data
                  ? expenseCategories[j].relationships["phase"].data
                  : null;
              for (let k = 0; k < phases.length; k++) {
                if (
                  phaseData !== null &&
                  phases[k].relationships["phase"].data.id === phaseData.id
                ) {
                  setPhaseDisabled(true);
                }
                if (phaseData === null) {
                  setPhaseDisabled(false);
                }
              }
            }
          }
        }
      }
    }
  }, [
    resources,
    projectResources,
    phases,
    expenses,
    expenseCategories,
    expenseCreated,
    expenseUpdated,
    rateTableId,
  ]);

  const expenseOptions = expenseCategories.map((expense) => {
    return (
      <option accessKey={expense.id} key={expense.id}>
        {expense.attributes.name}
      </option>
    );
  });

  const phaseOptions = phases.map((phase) => {
    return (
      <option accessKey={phase.id} key={phase.id}>
        {phase.attributes.name}
      </option>
    );
  });

  const handleResourceRate = (resource, expense) => {
    let useResourceRate = "";
    let resourceId = "";
    for (let j = 0; j < expenseCategories.length; j++) {
      if (expenseCategories[j].attributes.name === expense) {
        useResourceRate = expenseCategories[j].attributes["use-resource-rate"];
        setUseResourceRate(useResourceRate);
        if (useResourceRate && resource !== "") {
          for (let i = 0; i < filteredResources.length; i++) {
            if (filteredResources[i].attributes.name === resource) {
              resourceId = filteredResources[i].id;
            }
          }
          API.Get(
            `${apiHost}/${account_slug}/v1/resource-rates?filter[rate-table]=${rateTableId}&filter[resource]=${resourceId}`,
            authorizationCode
          ).then((res) => {
            if (res.data.data !== null && res.data.data.length !== 0) {
              setRate(res.data.data[0].attributes["expense-rate"]);
              setAmountDisabled(true);
            }
          });
        }
      }
    }
  };

  const handleRateAndPhase = (expense) => {
    for (let i = 0; i < expenseCategories.length; i++) {
      if (expenseCategories[i].attributes.name === expense) {
        let rate = expenseCategories[i].attributes.rate;
        let useResourceRate =
          expenseCategories[i].attributes["use-resource-rate"];
        let phaseData =
          expenseCategories[i].relationships["phase"] &&
          expenseCategories[i].relationships["phase"].data
            ? expenseCategories[i].relationships["phase"].data
            : null;

        if (rate !== null) setRate(rate);
        for (let j = 0; j < phases.length; j++) {
          if (
            phaseData !== null &&
            phases[j].relationships.phase.data.id === phaseData.id
          ) {
            setPhase(phases[j].attributes.name);
            setNewPhaseId(phaseData.id);
            setPhaseDisabled(true);
          }
          if (phaseData === null) {
            setPhase("Assign to Phase");
            setPhaseDisabled(false);
          }
        }
        if (rate !== null || useResourceRate) {
          setAmountDisabled(true);
        }
        if (rate === null && !useResourceRate) {
          setAmountDisabled(false);
        }

        let markup = expenseCategories[i].attributes.markup;
        setMarkup(markup);
      }
    }
  };

  const redirectToEditExpense = (expenseId) => {
    navigate(`/projects/${project_id}/project_expenses/${expenseId}/edit`);
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const setResourceRelationship = (expenseData) => {
      if (newResourceId !== null || 0) {
        if (newResourceId.includes("resources")) {
          expenseData.data.relationships.resource = {
            data: { type: "resources", id: newResourceId.split("-")[1] },
          };
        }
        if (newResourceId.includes("rate")) {
          expenseData.data.relationships["resource-rate"] = {
            data: { type: "resource-rates", id: newResourceId.split("-")[2] },
          };
        }
        if (newResourceId.includes("project")) {
          delete expenseData.data.relationships.resource;
          expenseData.data.relationships["project-resource"] = {
            data: {
              type: "project-resources",
              id: newResourceId.split("-")[2],
            },
          };
        }
      }
    };

    const getServiceRelationship = () => {
      return projectServiceId
        ? { data: { id: projectServiceId, type: "project-services" } }
        : null;
    };

    const getPhase = () => {
      if (newPhaseId !== null || 0) {
        return { data: { type: "project-phases", id: parseInt(newPhaseId) } };
      }
    };

    const getExpenseCategory = () => {
      if (newExpenseId !== null || 0) {
        return { data: { type: "expense-categories", id: newExpenseId } };
      }
    };

    if (lastWordInLocation === "edit") {
      let updatedExpenseData = {
        data: {
          type: "project-expenses",
          id: id,
          attributes: {
            description: description,
            rate: rate,
            quantity: quantity,
            markup: markup,
          },
          relationships: {
            project: { data: { type: "projects", id: project_id } },
            "project-phase": getPhase(),
            "expense-category": getExpenseCategory(),
            "project-service": getServiceRelationship(),
          },
        },
      };

      setResourceRelationship(updatedExpenseData);

      API.Patch(
        `${apiHost}/${account_slug}/v1/project-expenses/${id}`,
        updatedExpenseData,
        authorizationCode
      )
        .then((response) => {
          const data = response.data.data;
          setUpdatedAlertIsOpen(true);
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
          setDescription(data.attributes.description);
          setQuantity(data.attributes.quantity);
          setMarkup(data.attributes.markup);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "expense"));
          setShowFailAlert(true);
        });
    }

    if (lastWordInLocation === "new") {
      let newExpenseData = {
        data: {
          type: "project-expenses",
          attributes: {
            description: description,
            rate: rate,
            quantity: quantity,
            markup: markup,
          },
          relationships: {
            project: { data: { type: "projects", id: project_id } },
            "project-phase": getPhase(),
            "expense-category": getExpenseCategory(),
            "project-service": getServiceRelationship(),
          },
        },
      };

      setResourceRelationship(newExpenseData);

      API.Post(
        `${apiHost}/${account_slug}/v1/project-expenses`,
        newExpenseData,
        authorizationCode
      )
        .then((response) => {
          let id = response.data.data.id;
          setExpenseCreated(true);
          redirectToEditExpense(id);
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "expense"));
          setShowFailAlert(true);
        });
    }
  };

  const detailsUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="expenseAlerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Travel/Expense details updated"
        />
      );
    }
  };

  const detailsCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="expenseAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Travel/Expense details created"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleFailAlert()}
      {detailsUpdatedAlert()}
      {detailsCreatedAlert()}
      <Card>
        <CardHeader title="Travel/Expense" />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                <ControlledDropdown
                  label="Expense *"
                  value={expense}
                  required={true}
                  options={
                    lastWordInLocation === "new"
                      ? [
                          <option value="" accessKey={0} key={0}>
                            Pick Travel/Expense
                          </option>,
                          expenseOptions,
                        ]
                      : expenseOptions
                  }
                  onChange={(e) => {
                    let exp = e.target.value;
                    setExpense(exp);
                    handleRateAndPhase(exp);
                    handleResourceRate(resource, exp);
                    let selected = e.target.options.selectedIndex;
                    setNewExpenseId(e.target.options[selected].accessKey);
                  }}
                />
              </Col>
              <Col sm={6}>
                <FormTextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <ControlledDropdown
                  label="Phase"
                  value={phase}
                  disabled={phaseDisabled}
                  onChange={(e) => {
                    setPhase(e.target.value);
                    let selected = e.target.options.selectedIndex;
                    setNewPhaseId(e.target.options[selected].accessKey);
                  }}
                  options={
                    phase === "Assign to Phase"
                      ? [
                          <option accessKey={0} key={0}>
                            Assign to Phase
                          </option>,
                          phaseOptions,
                        ]
                      : phaseOptions
                  }
                />
              </Col>
              <Col sm={3}>
                <Select2
                  label="Resource"
                  value={resource}
                  onInputChange={(e) => {
                    if (e !== "") {
                      findResourceByNameAndReturnPromise(
                        resources[0].type,
                        e,
                        account_slug,
                        authorizationCode,
                        `resource,rate-table,line-of-business&filter[rate-table]=${rateTableId}`
                      ).then((response) => {
                        if (response.data.data) {
                          let newResources = response.data.data;
                          if (newResources[0].type == "resources") {
                            setFilteredResources(
                              newResources.filter(
                                (res) => res.attributes.active
                              )
                            );
                          } else {
                            let resourcesToMatch =
                              response.data.included.filter(
                                (item) =>
                                  item.type == "resources" &&
                                  item.attributes.active
                              );
                            let resourceRatesToReturn = new Set();
                            newResources.forEach((resourceRate) => {
                              resourcesToMatch.forEach((resource) => {
                                if (
                                  resource.id ==
                                  resourceRate.relationships.resource.data.id
                                ) {
                                  resourceRatesToReturn.add(resourceRate);
                                }
                              });
                            });
                            setFilteredResources(
                              Array.from(resourceRatesToReturn)
                            );
                          }
                        }
                      });
                    }
                  }}
                  onChange={(e) => {
                    setResource({
                      value: e.value,
                      label: e.label,
                    });
                    setNewResourceId(e.value);
                  }}
                  options={renderResourceOptions(
                    projectResources,
                    filteredResources,
                    lobs
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <FormNumberField
                  label="Quantity *"
                  required={true}
                  min={1}
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </Col>
              <Col sm={2}>
                <FormNumberField
                  readOnly={amountDisabled}
                  label="Amount Per Item/Day"
                  required={true}
                  min={0}
                  step={0.01}
                  value={rate}
                  onChange={(e) => {
                    setRate(e.target.value);
                  }}
                />
              </Col>
              <Col sm={2}>
                <FormNumberField
                  label="Target Margin (%) *"
                  required={true}
                  min={0}
                  step={0.01}
                  value={markup}
                  onChange={(e) => setMarkup(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Select2
                  label="Which service makes use of this expense?"
                  value={selectedService}
                  defaultValue={selectedService}
                  options={serviceOptions}
                  onChange={(e) => {
                    setSelectedService({ label: e.label, value: e.value });
                    setProjectServiceId(e.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {projectStatus === "building" && <SubmitButton />}
              </Col>
              <Col xs={6}>
                <BackButton url={`/projects/${project_id}/project_expenses`} />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ExpenseComponent;
