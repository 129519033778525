import API from "../../../../../../utils/API/API";

const loadPhases = async ({ account, surveyToSearch }) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const url = new URL(`/${account.slug}/v1/questionnaires`, apiHost);
  url.searchParams.append("filter[name]", surveyToSearch);
  url.searchParams.append("filter[published]", true);
  url.searchParams.append("include", "questions");

  try {
    const response = await API.Get(url.toString(), account.authorizationCode);

    return {
      data: response.data.data,
      included: response.data.included,
      error: null,
    };
  } catch (error) {
    return { data: null, error };
  }
};

export default loadPhases;
