import DataTable from "@components/DataTable";
import React, { useEffect, useState } from "react";
import { AuditLog, Change } from "./types/AuditLog";
import { ColumnDef } from "@tanstack/react-table";
import useListAuditLogs from "./api/useListAuditLogs";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import "./VersionHistory.css";
import { useGetAccountQuery, useRevertAuditLogByIdMutation } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
const strftime = require("strftime");

function VersionHistory({ filter, permission, account }) {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [revertAuditLog] = useRevertAuditLogByIdMutation();
  const [auditLogId, setAuditLogId] = useState<number | null>(null);
  const [showConfirmRevertModal, setShowConfirmRevertModal] = useState(false);
  // Get AuditLogs
  const { auditLogs, auditLogsError, auditLogsLoading, pageMeta, refetch } =
    useListAuditLogs({
      filter: filter,
      page: {
        number: pageNumber,
        size: pageSize,
      },
    });
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");

  useEffect(() => {
    refetch();
  }, []);

  const revert = () => {
    if (auditLogId) {
      revertAuditLog({
        slug: accountSlug,
        id: auditLogId || 0,
      })
        .unwrap()
        .then(() => {
          setSuccessMessage("Version reverted successfully!");
          setShowSuccessAlert(true);
          setShowConfirmRevertModal(false);
          refetch();
        })
        .catch((err) => {
          const error = err?.data?.error;
          setErrorMessage(
            `Version could not be reverted due to ${error} error! Please try again later.`
          );
          setShowFailAlert(true);
          setShowConfirmRevertModal(false);
        });
    }
  };

  const openRevertConfirmModal = (auditLogId) => {
    setAuditLogId(Number(auditLogId));
    setShowConfirmRevertModal(true);
  };

  const getVersionHistoryColumns = () => {
    let versionHistoryColumns: ColumnDef<AuditLog>[] = [
      {
        header: "Summary",
        cell(props) {
          return props.row.original.name;
        },
      },
      {
        header: "Change",
        cell(props) {
          return props.row.original.id === "noRow"
            ? ""
            : props.row.original.event;
        },
      },
      {
        header: "Changed On",
        cell(props) {
          const createdAt: Date = props.row.original?.createdAt
            ? new Date(props.row.original.createdAt)
            : new Date();
          return props.row.original.id === "noRow"
            ? ""
            : strftime(account?.attributes?.["long-date-format"] + " @ " + account?.attributes?.["time-format"], createdAt);;
        },
      },
      {
        header: "Changed By",
        cell(props) {
          return props.row.original.id === "noRow"
            ? ""
            : props.row.original.changedBy;
        },
      },
    ];
    if (permission) {
      versionHistoryColumns.push({
        header: " ",
        cell(props) {
          return props.row.original.id === "noRow" ? (
            ""
          ) : (
            <FontAwesomeIcon
              icon={faClockRotateLeft}
              onClick={() => openRevertConfirmModal(props.row.original.id)}
            />
          );
        },
      });
    }
    return versionHistoryColumns;
  };

  const changesColumns: ColumnDef<Change>[] = [
    {
      header: "Attribute",
      cell(props) {
        return props.row.original.attribute;
      },
    },
    {
      header: "Was",
      cell(props) {
        return props.row.original.changedFrom;
      },
    },
    {
      header: "Is",
      cell(props) {
        return props.row.original.changedTo;
      },
    },
  ];

  if (!auditLogs || auditLogsLoading) {
    return <SmallSpinner />;
  }

  return (
    <div>
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}

      <DataTable
        classForTable="versionHistoryTable"
        data={
          auditLogs.length > 0
            ? auditLogs.map((auditLog) => {
                return {
                  ...auditLog,
                  collapsedData: (
                    <div>
                      <DataTable
                        classForTable={`auditLogChanges-${auditLog?.id}`}
                        data={
                          auditLog?.changes?.length &&
                          auditLog?.changes?.length > 0
                            ? auditLog?.changes
                            : [
                                {
                                  id: "noRow",
                                  attribute: "No changes in this version",
                                },
                              ]
                        }
                        columns={changesColumns}
                        selectable={false}
                        striped={false}
                        selectedIds={[]}
                        setSelectedIds={() => null}
                        dragAndDrop={false}
                        isCollapsible={false}
                        hasSettings={false}
                      />
                    </div>
                  ),
                };
              })
            : [
                {
                  id: "noRow",
                  name: "No version history found",
                },
              ]
        }
        columns={getVersionHistoryColumns()}
        selectable={false}
        striped={true}
        selectedIds={[]}
        setSelectedIds={() => null}
        dragAndDrop={false}
        isCollapsible={auditLogs.length > 0}
        hasSettings={false}
        totalPages={pageMeta.pageCount}
        totalRows={pageMeta.recordCount}
        currentPage={pageNumber}
        setCurrentPage={setPageNumber}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        paginationEnabled
        pageSizeEnabled
      />
      <ConfirmationModal
        show={showConfirmRevertModal}
        title="Revert Change"
        message="Are you sure you want to revert this change?"
        onConfirm={revert}
        onCancel={() => setShowConfirmRevertModal(false)}
      />
    </div>
  );
}

export default VersionHistory;
