import React from "react"

class Loader extends React.PureComponent {
  render () {
    return(
      <div className="widget-loading">
        <span className="fa fa-2x fa-spinner fa-spin" />
      </div>
    )
  }
}

export default Loader

