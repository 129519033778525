class PricingCalculator {
    constructor(type, adjustment, array, terms) {
      this.type = type;
      this.revenue = array
        .map((service) => {
          if (service.attributes["billing-frequency"] === "one_time") {
            return parseFloat(service.attributes["service-revenue"])
          } else {
            return parseFloat(service.attributes.mrr)
          }
        })
        .reduce((prev, curr) => prev + curr, 0)
        .toFixed(2);

      this.adjustment = parseFloat(adjustment).toFixed(2);

      this.contractAdjustment =
        this.type == "managed" || this.type == "amortize"
          ? (parseFloat(adjustment) * parseFloat(terms)).toFixed(2)
          : this.adjustment;

      this.net = (parseFloat(this.revenue) + parseFloat(this.adjustment)).toFixed(2);   

      this.totalCost = array
      .map((service) => parseFloat(service.attributes["contract-cost"]))
      .reduce((prev, curr) => prev + curr, 0)
      .toFixed(2);

      this.tcv = (
        array
          .map((service) => parseFloat(service.attributes["contract-revenue"]))
          .reduce((prev, curr) => prev + curr, 0) +
        parseFloat(this.contractAdjustment)
      ).toFixed(2);

      this.cost = array
        .map((service) => parseFloat(service.attributes["contract-cost"]))
        .reduce((prev, curr) => prev + curr, 0)
        .toFixed(2);

      this.profit = (parseFloat(this.tcv) - parseFloat(this.cost)).toFixed(2);

      this.margin = (
        (parseFloat(this.profit) / parseFloat(this.tcv)) *
        100
      ).toFixed(2);
    }
  }

  export default PricingCalculator
