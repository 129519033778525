import { Recommendation } from "../types";

export const compareTwoStrings = (
  a: string,
  b: string,
  order: string
): number => {
  if (a === "" && b === "") {
    return 0;
  } else if (a === "") {
    return order === "asc" ? 1 : -1;
  } else if (b === "") {
    return order === "asc" ? -1 : 1;
  }

  return order === "asc" ? a.localeCompare(b) : b.localeCompare(a);
};

export const sortItemsByAttribute = (
  data,
  propertyName,
  sortBy,
  keepOrder?: boolean
) => {
  const updatedSortBy = { ...sortBy };

  if (!keepOrder) {
    if (updatedSortBy[propertyName] === "") {
      updatedSortBy[propertyName] = "asc";
    } else if (updatedSortBy[propertyName] === "asc") {
      updatedSortBy[propertyName] = "desc";
    } else if (updatedSortBy[propertyName] === "desc") {
      updatedSortBy[propertyName] = "asc";
    }
  }

  // Reset other properties to ""
  Object.keys(updatedSortBy).forEach((key) => {
    if (key !== propertyName) {
      updatedSortBy[key] = "";
    }
  });

  if (!data) {
    return { columns: updatedSortBy, data: null };
  }

  let sorted = data.data;
  if (updatedSortBy[propertyName] !== "") {
    sorted = data.data.slice().sort((a, b) => {
      return compareTwoStrings(
        a.attributes[propertyName],
        b.attributes[propertyName],
        sortBy[propertyName] == "asc" ? "asc" : "desc"
      );
    });
  }

  return { columns: updatedSortBy, data: { ...data, data: sorted } };
};

export const sortItemsByDate = (data, attribute, sortBy) => {
  return data.slice().sort((a, b) => {
    const dateA = a.attributes[attribute]
      ? new Date(a.attributes[attribute]).getTime()
      : 0;
    const dateB = b.attributes[attribute]
      ? new Date(b.attributes[attribute]).getTime()
      : 0;
    return sortBy[attribute] === "asc" ? dateB - dateA : dateA - dateB;
  });
};

export const compareRecommendations = (
  oldRecommendations: Recommendation[],
  newRecommendations: Recommendation[]
) => {
  return newRecommendations.map((newRecommendation) => {
    //check to see if each new recommendation exists in old recommendations
    const alreadyRecommendedService = oldRecommendations.find(
      (oldRecommendation) =>
        oldRecommendation.service.id === newRecommendation.service.id
    );
    //If not, set recalculated to true to highlight it
    if (!alreadyRecommendedService) {
      newRecommendation.service = {
        ...newRecommendation.service,
        recalculated: true,
      };
      //Also highlight any subservices, which will all be new as well
      newRecommendation.subservices.map((sub) => {
        return { ...sub, recalculated: true };
      });
    } else {
      //Otherwise, check if values on the recommended service have changed
      const valuesHaveChanged =
        JSON.stringify(newRecommendation.service) !==
        JSON.stringify(alreadyRecommendedService.service);

      newRecommendation.service = {
        ...newRecommendation.service,
        recalculated: valuesHaveChanged,
      };

      //Check subservices for changes
      newRecommendation.subservices = newRecommendation.subservices.map(
        (newSubservice) => {
          //For each new subservice, find old subservice to compare against
          const oldSubservice = alreadyRecommendedService.subservices.find(
            (oldSubservice) => {
              oldSubservice.id === newSubservice.id;
            }
          );
          //If it doesn't match, highlight
          if (!oldSubservice) {
            return { ...newSubservice, recalculated: true };
          } else {
            //Otherwise, compare old vs new subservice
            const valuesHaveChanged =
              JSON.stringify(newSubservice) !== JSON.stringify(oldSubservice);

            return {
              ...newSubservice,
              recalculated: valuesHaveChanged,
            };
          }
        }
      );
    }
    return newRecommendation;
  });
};
