import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import "./Collaborator.css";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";
import useUnloadWarning from "@utils/customHooks/useUnloadWarning";

function Collaborators({ account_slug, project_id, account_id }) {
  const [users, setUsers] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [collaborator, setCollaborator] = useState("Pick Teammate");
  const [newCollaboratorId, setNewCollaboratorId] = useState(null);

  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);
  const [removedAlertIsOpen, setRemovedAlertIsOpen] = useState(false);
  const [mustPickAlertIsOpen, setMustPickAlertIsOpen] = useState(false);
  const [alreadyExistsAlertIsOpen, setAlreadyExistsAlertIsOpen] =
    useState(false);

  const [authorizationCode] = useOauth();

  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setFormDirty] = useState(false);

  useUnloadWarning(isFormDirty);

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get users to add to collaborator dropdown
      API.Get(
        `${apiHost}/${account_slug}/v1/users?page[size]=200`,
        authorizationCode
      ).then((response) => {
        setUsers(response.data.data);
      });

      // Get collaborators to render in table
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-collaborators?include=user`,
        authorizationCode
      ).then((response) => {
        setCollaborators(response.data.data);
        setIsLoading(false);
      });
    }
  }, [authorizationCode]);

  const collaboratorOptions = users.map((user) => {
    return (
      <option key={user.id} accessKey={user.id}>
        {user.attributes.name}
      </option>
    );
  });

  const renderRows = () => {
    if (collaborators.length !== 0) {
      return collaborators.map((collaborator) => {
        let id = collaborator.id;

        const deleteCollaborator = () => {
          let collaboratorData = {
            data: {
              type: "project-collaborators",
              id: collaborator.id,
            },
          };
          API.Delete(
            `${apiHost}/${account_slug}/v1/project-collaborators/${id}`,
            collaboratorData,
            authorizationCode
          )
            .then((response) => {
              if (response.status == 204) {
                setRemovedAlertIsOpen(true);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((err) => {
              setErrorMessages(
                formatUnprocessibleResponse(err, "collaborator")
              );
              setShowFailAlert(true);
            });
        };

        const renderAction = () => {
          return (
            <a
              onClick={deleteCollaborator}
              rel="nofollow"
              data-confirm="Are you sure?"
            >
              <i className="fa fa-remove redDeleteBtn pull-right"></i>
            </a>
          );
        };

        return (
          <tr key={id}>
            <td>{collaborator.attributes.name}</td>
            <td>{renderAction()}</td>
          </tr>
        );
      });
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (newCollaboratorId === null || newCollaboratorId === "pick") {
      setMustPickAlertIsOpen(true);
      isValid = false;
    }
    for (let i = 0; i < collaborators.length; i++) {
      if (collaborators[i].attributes.name === collaborator) {
        setAlreadyExistsAlertIsOpen(true);
        isValid = false;
      }
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormDirty(false);

    const formIsValid = validateForm();

    if (formIsValid === false) {
      return;
    }

    const collaboratorData = {
      data: {
        type: "project-collaborators",
        attributes: {},
        relationships: {
          project: { data: { type: "projects", id: project_id } },
          user: { data: { type: "users", id: newCollaboratorId } },
        },
      },
    };

    API.Post(
      `${apiHost}/${account_slug}/v1/project-collaborators`,
      collaboratorData,
      authorizationCode
    )
      .then((response) => {
        if (response.status == 201) {
          setCreatedAlertIsOpen(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "collaborator"));
        setShowFailAlert(true);
      });
  };

  const handleFormChange = (event) => {
    setFormDirty(true);
  };

  const collaboratorCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="collaboratorAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Teammate Added"
        />
      );
    }
  };

  const collaboratorRemovedAlert = () => {
    if (removedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="collaboratorAlerts"
          variant="info"
          onClose={() => setRemovedAlertIsOpen(false)}
          text="Teammate removed from project"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  const mustPickAlert = () => {
    if (mustPickAlertIsOpen === true && createdAlertIsOpen === false) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setMustPickAlertIsOpen(false)}
          text="You must pick a collaborator to submit this form"
        />
      );
    }
  };

  const alreadyExistsAlert = () => {
    if (alreadyExistsAlertIsOpen === true && createdAlertIsOpen === false) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setAlreadyExistsAlertIsOpen(false)}
          text="Teammate already exists on project"
        />
      );
    }
  };

  return (
    <div>
      {handleFailAlert()}
      {collaboratorCreatedAlert()}
      {collaboratorRemovedAlert()}
      {mustPickAlert()}
      {alreadyExistsAlert()}
      <Card>
        <CardHeader title="Teammates" />
        <Card.Body className="collaborators">
          <BaseTable
            headerRows={null}
            dataRows={renderRows()}
            footerRows={null}
            striped={false}
            hover={false}
            bordered={false}
            isLoading={isLoading}
          />
          <Form className="collaboratorForm" onSubmit={handleSubmit} onChange={handleFormChange}>
            <ControlledDropDown
              label="Add Teammates"
              value={collaborator}
              options={[
                <option key="pick">Pick Teammate</option>,
                collaboratorOptions,
              ]}
              onChange={(e) => {
                let collaborator = e.target.value;
                setCollaborator(collaborator);
                let selected = e.target.options.selectedIndex;
                setNewCollaboratorId(e.target.options[selected].accessKey);
              }}
            />
            <Row className="buttonRow">
              <Col xs={12}>
                <SeafoamButton type="submit" text="Add Teammate" />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Collaborators;
