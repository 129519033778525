import { useListUserViewsForAccountQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { UserView } from "../types";

const useGetUserViewResource = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { refetch, data, isLoading, error } = useListUserViewsForAccountQuery({
    slug: accountSlug,
  });

  let userViews: UserView[] = [];

  if (data?.data) {
    userViews = data.data.map((userView) => {
      const filters = userView.attributes?.filters || {};

      return {
        id: userView.id || 0,
        name: userView.attributes?.name || "",
        type: userView?.type || "",
        filters: {
          showMyProjectsOnly: filters.showMyProjectsOnly || false,
          showMyProjects: filters.showMyProjects || false,
          tags: filters.tags || [],
          teams: filters.teams || [],
          status: filters.status || [],
          clients: filters.clients || [],
          teammates: filters.teammates || [],
          presalesEngineers: filters.presalesEngineers || [],
          salesExecs: filters.salesExecs || [],
          dateCreatedStart: filters["created-at.after"] || "",
          dateCreatedEnd: filters["created-at.before"] || "",
          dateEditedStart: filters["updated-at.after"] || "",
          dateEditedEnd: filters["updated-at.before"] || "",
        },
      };
    });
  }

  return { userViews, isLoading, error, refetch };
};

export default useGetUserViewResource;
