import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers/rootReducer";
import FormFieldLabel from "../../../../components/Forms/FormFieldLabel/FormFieldLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEye,
  faEyeSlash,
  faX,
} from "@fortawesome/pro-solid-svg-icons";
import {
  handleAlert,
  formatUnprocessibleResponse,
  containsUpperCase,
  containsLowerCase,
  containsSpecialCharacter,
} from "../../../../utils/helperFunctions";
import ToastAlert from "../../../../components/Alerts/ToastAlert/ToastAlert";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import axios from "axios";
import useGetAccount from "../api/useGetAccount";

const Password = (): JSX.Element => {
  //Redux/API
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const { data: account, error, isLoading } = useGetAccount(accountSlug);

  // Component States
  const userId = location.pathname.split("/")[2];
  const [authenticityParam, setAuthenticityParam] = useState("");
  const [authenticityToken, setAuthenticityToken] = useState("");
  const doorkeeper = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const [currentPassword, setCurrentPassword] = useState({
    value: "",
    show: false,
  });
  const [desiredPassword, setDesiredPassword] = useState({
    value: "",
    show: false,
  });
  const [confirmDesiredPassword, setConfirmDesiredPassword] = useState({
    value: "",
    show: false,
  });
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Password updated successfully!"
  );

  useEffect(() => {
    let param = document.head.querySelector(
      "[name~=csrf-param][content]"
    ) as HTMLMetaElement;
    let token = document.head.querySelector(
      "[name~=csrf-token][content]"
    ) as HTMLMetaElement;
    if (param && token) {
      setAuthenticityParam(param.content);
      setAuthenticityToken(token.content);
    }
  }, [account]);

  if (isLoading || error || !account) {
    // TD: Add loading spinner or something
    return <></>;
  }

  const isSso = account.attributes["sso?"];

  const desiredPasswordNotEmpty = () => {
    return desiredPassword.value !== "";
  };

  const validateUpperCase = () => {
    return containsUpperCase(desiredPassword.value);
  };

  const validateLowerCase = () => {
    return containsLowerCase(desiredPassword.value);
  };

  const validateSpecialCharacter = () => {
    return containsSpecialCharacter(desiredPassword.value);
  };

  const isEightCharacters = () => {
    return desiredPassword.value.length >= 8;
  };

  const desiredAndConfirmMatch = () => {
    return desiredPassword.value === confirmDesiredPassword.value;
  };

  const formIsNotValid = () => {
    return (
      !desiredAndConfirmMatch() ||
      !validateUpperCase ||
      !validateLowerCase ||
      !isEightCharacters
    );
  };

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const passwordData = {
      [authenticityParam]: authenticityToken,
      current_password: currentPassword.value,
      user: {
        password: desiredPassword.value,
        password_confirmation: confirmDesiredPassword.value,
      },
    };
    axios
      .patch(`${doorkeeper}/users/${userId}/password.json`, passwordData)
      .then((res) => {
        setShowSuccessAlert(true);
        const defaultValue = { value: "", show: false };
        setCurrentPassword(defaultValue);
        setDesiredPassword(defaultValue);
        setConfirmDesiredPassword(defaultValue);
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "password"));
        setShowFailAlert(true);
      });
  };

  return (
    <div>
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Row>
        <Col sm={6}>
          <Card className="whiteBg">
            <Card.Header className="headerOverride">Password</Card.Header>
            <hr className="cardHeadDivider" />
            <Card.Body className="whiteBg">
              {isSso ? (
                <p className="cardSubtitle">
                  Your password is managed via SSO, please contact your
                  administrator to change your password.
                </p>
              ) : (
                <p className="cardSubtitle">
                  Enter current password as well as your desired password and
                  confirmation to change your password.
                </p>
              )}
              {!isSso ? (
                <Form onSubmit={submit}>
                  <Form.Control name="utf8" type="hidden" value="&#x2713;" />
                  <Form.Control
                    type="hidden"
                    name="authenticity_token"
                    value={authenticityToken}
                  />
                  <Form.Group className="formGroup">
                    <FormFieldLabel className={""} label={"Current Password"} />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Control
                        style={{ width: "90%" }}
                        id="currentPassword"
                        required={true}
                        type={!currentPassword.show ? "password" : "text"}
                        value={currentPassword.value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setCurrentPassword({
                            value: e.target.value,
                            show: currentPassword.show,
                          })
                        }
                        placeholder={"Enter your current password"}
                        readOnly={false}
                      />
                      <FontAwesomeIcon
                        style={{ marginLeft: "10px" }}
                        icon={currentPassword.show ? faEyeSlash : faEye}
                        onClick={() =>
                          setCurrentPassword({
                            ...currentPassword,
                            show: !currentPassword.show,
                          })
                        }
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="formGroup">
                    <FormFieldLabel className={""} label={"Desired Password"} />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Control
                        style={{ width: "90%" }}
                        id="desiredPassword"
                        required={true}
                        type={!desiredPassword.show ? "password" : "text"}
                        value={desiredPassword.value}
                        onChange={(e: any) =>
                          setDesiredPassword({
                            value: e.target.value,
                            show: desiredPassword.show,
                          })
                        }
                        placeholder={"Enter your desired password"}
                        readOnly={false}
                      />

                      <FontAwesomeIcon
                        style={{ marginLeft: "10px" }}
                        icon={desiredPassword.show ? faEyeSlash : faEye}
                        onClick={() =>
                          setDesiredPassword({
                            ...desiredPassword,
                            show: !desiredPassword.show,
                          })
                        }
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="formGroup">
                    <FormFieldLabel
                      className={""}
                      label={"Confirm Desired Password"}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Control
                        style={{ width: "90%" }}
                        id="confirmDesiredPassword"
                        required={true}
                        type={
                          !confirmDesiredPassword.show ? "password" : "text"
                        }
                        value={confirmDesiredPassword.value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setConfirmDesiredPassword({
                            value: e.target.value,
                            show: confirmDesiredPassword.show,
                          })
                        }
                        placeholder={"Confirm your desired password"}
                        readOnly={false}
                      />

                      <FontAwesomeIcon
                        style={{ marginLeft: "10px" }}
                        icon={confirmDesiredPassword.show ? faEyeSlash : faEye}
                        onClick={() =>
                          setConfirmDesiredPassword({
                            ...confirmDesiredPassword,
                            show: !confirmDesiredPassword.show,
                          })
                        }
                      />
                    </div>
                  </Form.Group>
                  <Button
                    type="submit"
                    className={
                      currentPassword.value === "" ||
                      desiredPassword.value === "" ||
                      confirmDesiredPassword.value === "" ||
                      formIsNotValid()
                        ? "seafoamBgButton"
                        : "btnSeafoam"
                    }
                    variant="secondary"
                    disabled={
                      currentPassword.value === "" ||
                      desiredPassword.value === "" ||
                      confirmDesiredPassword.value === "" ||
                      formIsNotValid()
                    }
                  >
                    Update Password
                  </Button>
                  <div className="greyBox">
                    <p>
                      <strong>Password Requirements</strong>
                    </p>
                    <span
                      className="flex"
                      style={
                        desiredPasswordNotEmpty() && !validateUpperCase()
                          ? { color: "#B90200" }
                          : desiredPasswordNotEmpty() && validateUpperCase()
                          ? { color: "#418172" }
                          : { color: "#1c2655" }
                      }
                    >
                      <FontAwesomeIcon
                        style={{
                          fontSize: "12px",
                          marginRight: "5px",
                          marginTop: "5px",
                        }}
                        icon={
                          desiredPasswordNotEmpty() && !validateUpperCase()
                            ? faX
                            : desiredPasswordNotEmpty() && validateUpperCase()
                            ? faCheck
                            : faCircle
                        }
                      />
                      <p>Must contain an uppercase letter</p>
                    </span>
                    <span
                      className="flex"
                      style={
                        desiredPasswordNotEmpty() && !validateLowerCase()
                          ? { color: "#B90200" }
                          : desiredPasswordNotEmpty() && validateLowerCase()
                          ? { color: "#418172" }
                          : { color: "#1c2655" }
                      }
                    >
                      <FontAwesomeIcon
                        style={{
                          fontSize: "12px",
                          marginRight: "5px",
                          marginTop: "5px",
                        }}
                        icon={
                          desiredPasswordNotEmpty() && !validateLowerCase()
                            ? faX
                            : desiredPasswordNotEmpty() && validateLowerCase()
                            ? faCheck
                            : faCircle
                        }
                      />
                      <p>Must contain an lowercase letter</p>
                    </span>
                    <span
                      className="flex"
                      style={
                        desiredPasswordNotEmpty() && !validateSpecialCharacter()
                          ? { color: "#B90200" }
                          : desiredPasswordNotEmpty() &&
                            validateSpecialCharacter()
                          ? { color: "#418172" }
                          : { color: "#1c2655" }
                      }
                    >
                      <FontAwesomeIcon
                        style={{
                          fontSize: "12px",
                          marginRight: "5px",
                          marginTop: "5px",
                        }}
                        icon={
                          desiredPasswordNotEmpty() &&
                          !validateSpecialCharacter()
                            ? faX
                            : desiredPasswordNotEmpty() &&
                              validateSpecialCharacter()
                            ? faCheck
                            : faCircle
                        }
                      />
                      <p>Must contain a symbol (!,@,#,etc)</p>
                    </span>
                    <span
                      className="flex"
                      style={
                        desiredPasswordNotEmpty() && !isEightCharacters()
                          ? { color: "#B90200" }
                          : desiredPasswordNotEmpty() && isEightCharacters()
                          ? { color: "#418172" }
                          : { color: "#1c2655" }
                      }
                    >
                      <FontAwesomeIcon
                        style={{
                          fontSize: "12px",
                          marginRight: "5px",
                          marginTop: "5px",
                        }}
                        icon={
                          desiredPasswordNotEmpty() && !isEightCharacters()
                            ? faX
                            : desiredPasswordNotEmpty() && isEightCharacters()
                            ? faCheck
                            : faCircle
                        }
                      />
                      <p>Must be at least 8 characters</p>
                    </span>
                    <span
                      className="flex"
                      style={
                        desiredPasswordNotEmpty() && !desiredAndConfirmMatch()
                          ? { color: "#B90200" }
                          : desiredPasswordNotEmpty() &&
                            desiredAndConfirmMatch()
                          ? { color: "#418172" }
                          : { color: "#1c2655" }
                      }
                    >
                      <FontAwesomeIcon
                        style={{
                          fontSize: "12px",
                          marginRight: "5px",
                          marginTop: "5px",
                        }}
                        icon={
                          desiredPasswordNotEmpty() && !desiredAndConfirmMatch()
                            ? faX
                            : desiredPasswordNotEmpty() &&
                              desiredAndConfirmMatch()
                            ? faCheck
                            : faCircle
                        }
                      />
                      <p>Desired Password and Confirmation must match</p>
                    </span>
                  </div>
                </Form>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Password;
