import React, { useEffect, useState } from "react";
import {
  useGetQuestionnaireByIdQuery,
  useGetSurveyByIdQuery,
  useWhoAmIQuery,
} from "../../../services/generated";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { Card, Row, Col, Button } from "react-bootstrap";
import Survey from "../SurveyHelpers/Survey";
import newSurvey from "../SurveyHelpers/newSurvey";
import getService from "../../Projects/CreateProjectMspa/Services/Questionnaire/api/getService";
import calculate from "../../Projects/CreateProjectMspa/Services/Questionnaire/api/calculate";
import SurveyResults from "../SurveyResults/SurveyResults";
import "../style.css";
import { getFormattedDate, removeDuplicates } from "@utils/helperFunctions";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useLocation, useNavigate } from "react-router";
import { useGetMeQuery } from "../../../services/ScopeStackAPI";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import API from "../../../utils/API/API";
import StaticBanner from "@components/Alerts/StaticBanner/StaticBanner";
import { Recommendation } from "../types";
import { compareRecommendations } from "../api";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import {
  handleAlert,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";

function TakeASurvey({ accessToken, setCreateProjectData }): JSX.Element {
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const locationHook = useLocation();
  const isNewSurvey = locationHook.state?.isNewSurvey;
  const queryParams = new URLSearchParams(locationHook.search);
  const surveyId = locationHook.state?.surveyId || queryParams.get("surveyId");
  const returningFromCreatePage = locationHook.state?.projectData;

  const navigate = useNavigate();

  const location = window.location.href;
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  let {
    data: currentSurvey,
    isLoading: currentSurveyLoading,
    error: currentSurveyError,
    refetch,
  } = useGetSurveyByIdQuery({
    slug: accountSlug,
    id: returningFromCreatePage
      ? locationHook.state?.projectData?.currentSurvey?.data?.id
      : surveyId
      ? surveyId
      : locationHook.state?.survey?.data?.id,
    include: ["survey-responses", "survey-recommendations", "project"],
  });

  const [showRecommendations, setShowRecommendations] = useState(
    isNewSurvey ? false : true
  );
  const [recommendations, setRecommendations] = useState<Recommendation[]>(
    returningFromCreatePage
      ? locationHook.state?.projectData.recommendations
      : []
  );
  const [calculations, setCalculations] = useState<any[]>(
    returningFromCreatePage ? locationHook.state?.projectData?.calculations : []
  );
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [resources, setResources] = useState<any[]>(
    returningFromCreatePage ? locationHook.state?.projectData?.resources : []
  );
  const [phases, setPhases] = useState<any[]>(
    returningFromCreatePage ? locationHook.state?.projectData?.phases : []
  );
  const [nonServiceRecommendations, setNonServiceRecommendations] = useState<
    any[]
  >(
    returningFromCreatePage
      ? locationHook.state?.projectData?.nonServiceRecommendations
      : []
  );

  const [recalculateClicked, setRecalculateClicked] = useState(false);
  const [recalculatedCalculations, setRecalculatedCalculations] = useState<
    any[]
  >([]);
  const [recalculatedRecommendations, setRecalculatedRecommendations] =
    useState<Recommendation[]>([]);

  const [
    recalculatedNonServiceRecommendations,
    setRecalculatedNonServiceRecommendations,
  ] = useState<any[]>([]);
  const [calculateClicked, setCalculateClicked] = useState(false);
  const [originalSurveyResponses, setOriginalSurveyResponses] = useState<any[]>(
    []
  );
  const [revertClicked, setRevertClicked] = useState(false);
  const [showNoAccessError, setShowNoAccessError] = useState(false);

  const [surveyResponses, setSurveyResponses] = useState(
    returningFromCreatePage
      ? locationHook.state?.projectData?.surveyResponses
      : isNewSurvey
      ? locationHook.state?.survey?.data?.attributes?.responses
      : currentSurvey?.data?.attributes?.responses || []
  );

  const { data: questionnaire, isLoading: questionnaireLoading } =
    useGetQuestionnaireByIdQuery({
      slug: accountSlug,
      id: Number(id),
      include: ["questions"],
    });

  const { data: account, isLoading: accountLoading } = useWhoAmIQuery();

  const { data: currentUser, isLoading: currentUserLoading } = useGetMeQuery();

  const getServicesForRecommendations = async () => {
    const services: any[] = [];
    const resources: any[] = [];
    const subservices: any[] = [];
    const phases: any[] = [];
    const nonServiceRecommendations: any[] = [];
    const recs = (currentSurvey?.data?.attributes?.recommendations || []).map(
      async (recommendation) => {
        let accountObj = {
          id:
            account?.data && account.data.attributes
              ? account.data.attributes["account-id"]
              : "",
          slug:
            account?.data && account.data.attributes
              ? account.data.attributes["account-slug"]
              : "",
          authorizationCode: accessToken,
        };
        if (recommendation["item_type"] === "Task") {
          const { data, included, error } = await getService({
            account: accountObj,
            serviceId: recommendation["item_id"],
          });
          if (error) {
            setRecommendations([]);
            setShowNoAccessError(true);
            return;
          }

          if (recommendation.refinements) {
            let foundMatchingRefinement = false;
            for (const refinement of recommendation.refinements) {
              if (refinement.item_id === recommendation.item_id) {
                data.quantity = refinement.quantity;
                foundMatchingRefinement = true;
                break;
              }
            }

            if (!foundMatchingRefinement && data) {
              data.quantity = recommendation.quantity;
            }
          } else {
            data.quantity = recommendation.quantity;
          }

          services.push({
            ...data,
            relationships: {
              ...data.relationships,
              resource: {
                data: {
                  type: "resources",
                  id:
                    recommendation && recommendation["resource_id"]
                      ? recommendation["resource_id"]
                      : data &&
                        data.relationships &&
                        data.relationships.resource &&
                        data.relationships.resource.data
                      ? data.relationships.resource.data.id
                      : null,
                },
              },
            },
          });
          resources.push(
            ...included.filter((item) => item.type === "resources")
          );

          (recommendation.refinements || []).forEach((refinement) => {
            included
              .filter((item) => item.type === "subservices")
              .forEach((subservice) => {
                if (subservice.id == refinement.item_id) {
                  subservices.push({
                    ...subservice,
                    relationships: {
                      ...subservice.relationships,
                      resource: {
                        data: {
                          type: "resources",
                          id: refinement["resource_id"]
                            ? refinement["resource_id"]
                            : subservice.relationships.resource.data.id,
                        },
                      },
                    },
                  });
                }
              });
          });
          for (const subservice of subservices) {
            for (const refinement of recommendation.refinements || []) {
              if (subservice.id == refinement.item_id) {
                subservice.attributes.quantity = refinement.quantity;
              } else {
                subservice.attributes.quantity = recommendation.quantity;
              }
            }
          }

          phases.push(...included.filter((item) => item.type === "phases"));
          setResources(resources);
          setPhases(phases);
        } else {
          nonServiceRecommendations.push(recommendation);
          setNonServiceRecommendations(nonServiceRecommendations);
        }

        return services.map((service) => {
          return {
            service,
            subservices: subservices.filter((subservice) => {
              return subservice.relationships.service.data.id == service.id;
            }),
          };
        });
      }
    );
    return recs;
  };

  useEffect(() => {
    if (isNewSurvey) {
      currentSurvey = locationHook.state?.survey?.data;
    } else if (currentSurvey?.data?.attributes?.status !== "shared") {
      if (!revertClicked && !recalculateClicked) {
        setOriginalSurveyResponses(
          currentSurvey?.data?.attributes?.responses || []
        );
        setSurveyResponses(currentSurvey?.data?.attributes?.responses || []);
      }
      getServicesForRecommendations().then((promises) => {
        if (promises.length !== 0) {
          Promise.all(promises).then((res) => {
            const responses = res.filter((res) => res?.length !== 0);
            let newRecommendations = new Set();
            responses.forEach((response) => {
              (response || []).forEach((recItem) => {
                newRecommendations.add(recItem);
              });
            });
            const newRecs = removeDuplicates(
              Array.from(newRecommendations),
              (item) => item.service.id
            );
            setRecommendations(newRecs);
            setShowRecommendations(true);
          });
        }
      });
    }
  }, [questionnaire, account, currentUser, currentSurvey]);

  const createProjectFromRecommendations = () => {
    navigate(`/surveys/${currentSurvey?.data?.id}/create_project`);
  };

  const handleSurveySubmit = async (
    e,
    revertClicked,
    recalculateClicked,
    originalSurveyResponses
  ) => {
    e.preventDefault();
    newSurvey({
      questionnaire: questionnaire?.data,
      responses: revertClicked ? originalSurveyResponses : surveyResponses,
      account,
      surveyId: currentSurvey ? currentSurvey?.data?.id : null,
      accessToken,
      projectId: null,
    }).then((survey) => {
      calculate({
        account: {
          slug: account?.data?.attributes["account-slug"],
          authorizationCode: accessToken,
        },
        surveyId: survey.data.id,
      })
        .then((calcSurvey) => {
          if (calcSurvey.error) {
            setErrorMessages(
              "Something went wrong while calculating this survey."
            );
            setShowFailAlert(true);
            setRecommendations([]);
            setShowRecommendations(true);
            return;
          } else {
            setSuccessMessage("Survey calculated.");
            setShowSuccessAlert(true);
            refetch();
          }

          setSurveyResponses(calcSurvey.data.attributes.responses);
          if (calculateClicked || revertClicked) {
            if (hasRelatedProject) {
              currentSurvey = {
                ...calcSurvey.data,
                relationships: {
                  ...calcSurvey.data.relationships,
                  project: {
                    data: { id: hasRelatedProject, type: "projects" },
                  },
                },
              };
            } else {
              currentSurvey = calcSurvey.data;
            }
          }
          if (!recalculateClicked) {
            setCalculations(calcSurvey.data.attributes.calculations);
          } else {
            setRecalculatedCalculations(
              calcSurvey.data.attributes.calculations
            );
            setRecalculatedRecommendations(
              calcSurvey.data.attributes.recommendations
            );
          }

          const services: any[] = [];
          const resources: any[] = [];
          const subservices: any[] = [];
          const phases: any[] = [];
          const nonServiceRecommendations: any[] = [];
          let refinements = new Set();

          calcSurvey.data.attributes.recommendations.map((recommendation) => {
            if (recommendation["item_type"] === "Task") {
              API.Get(
                `${apiHost}/${accountSlug}/v1/services/${recommendation["item_id"]}?include=resource,phase,subservices.resource,subservices.service,service-category`,
                accessToken
              )
                .then((response) => {
                  let data = response?.data?.data;
                  let included = response?.data?.included;

                  if (recommendation.refinements) {
                    let foundMatchingRefinement = false;
                    for (const refinement of recommendation.refinements) {
                      if (refinement.item_id === recommendation.item_id) {
                        data.quantity = refinement.quantity;
                        foundMatchingRefinement = true;
                        break;
                      }
                    }

                    if (!foundMatchingRefinement && data) {
                      data.quantity = recommendation.quantity;
                    }
                  } else {
                    data.quantity = recommendation.quantity;
                  }

                  services.push(data);
                  resources.push(
                    ...included.filter((item) => item.type === "resources")
                  );

                  recommendation.refinements.forEach((refinement) => {
                    included
                      .filter((item) => item.type === "subservices")
                      .forEach((subservice) => {
                        if (subservice.id == refinement.item_id) {
                          subservice = {
                            ...subservice,
                            attributes: {
                              ...subservice.attributes,
                              quantity: refinement.quantity
                                ? refinement.quantity
                                : subservice.attributes.quantity,
                            },
                          };
                          refinements.add(subservice);
                        }
                      });
                  });

                  phases.push(
                    ...included.filter((item) => item.type === "phases")
                  );
                  setResources(resources);
                  setPhases(phases);

                  if (!recalculateClicked) {
                    setNonServiceRecommendations(nonServiceRecommendations);
                  } else {
                    setRecalculatedNonServiceRecommendations(
                      nonServiceRecommendations
                    );
                  }

                  const newRecs = services.map((service) => {
                    return {
                      service,
                      subservices: (
                        Array.from(refinements) as Recommendation["subservices"]
                      ).filter((subservice: any) => {
                        return (
                          subservice.relationships.service.data.id == service.id
                        );
                      }),
                    };
                  });
                  if (!recalculateClicked) {
                    setRecommendations(newRecs);
                  } else {
                    const comparedRecommendations = compareRecommendations(
                      recommendations,
                      newRecs
                    );
                    setRecalculatedRecommendations(comparedRecommendations);
                  }
                  setShowRecommendations(true);
                })
                .catch((err) => {
                  setShowNoAccessError(true);
                  setRecommendations([]);
                  setShowRecommendations(false);
                });
            } else {
              nonServiceRecommendations.push(recommendation);
            }
          });
        })
        .catch((error) => {
          setErrorMessages(
            formatUnprocessibleResponse(error, "survey calculation")
          );
        });
    });
  };

  const handleApplySurveys = async (projectId) => {
    if (!projectId) {
      return;
    }
    const survey = await newSurvey({
      questionnaire: questionnaire?.data,
      responses: surveyResponses,
      account,
      surveyId: currentSurvey ? currentSurvey?.data?.id : null,
      accessToken,
      projectId,
    });

    if (account && account.data) {
      API.Put(
        `${apiHost}/${account.data?.attributes["account-slug"]}/v1/surveys/${survey.data.id}/apply`,
        {},
        accessToken
      ).then((response) => {
        window.location.replace(`${appHost}/projects/${projectId}/edit`);
      });
    }
  };

  const hasRelatedProject =
    currentSurvey &&
    currentSurvey?.data?.relationships?.project?.data &&
    currentSurvey?.data?.relationships.project.data.id;

  return questionnaire ? (
    <main className="takeASurveyPage">
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Row className="flexCenter">
        <Col sm={6} className="takeSurveyName">
          {questionnaire.data?.attributes?.name}
        </Col>
        {showRecommendations &&
        currentSurvey?.data?.attributes?.status !== "pending" &&
        currentSurvey?.data?.attributes?.status !== "shared" ? (
          <Col sm={6}>
            <Row className="flexCenter">
              <Col sm={7}>
                <span className="standardTextSizeNavy floatRight">
                  <strong>Completed By: </strong>&nbsp;
                  {isNewSurvey
                    ? currentUser?.attributes.name
                    : currentSurvey &&
                      currentSurvey?.data?.attributes["contact-name"]
                    ? currentSurvey &&
                      currentSurvey?.data?.attributes["contact-name"]
                    : currentUser?.attributes.name}
                  ,&nbsp;
                  {isNewSurvey
                    ? currentUser?.attributes["email"]
                    : currentSurvey &&
                      currentSurvey?.data?.attributes["contact-email"]
                    ? currentSurvey?.data?.attributes["contact-email"]
                    : currentUser?.attributes["email"]}
                </span>
              </Col>
              <Col sm={5}>
                <span className="standardTextSizeNavy floatRight">
                  <strong>Completed On: </strong>&nbsp;
                  {getFormattedDate(
                    isNewSurvey
                      ? new Date()
                      : new Date(
                          (currentSurvey &&
                            currentSurvey?.data?.attributes["updated-at"]) ||
                            ""
                        )
                  )}
                </span>
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
      <Card className="whiteBg takeASurveyOuterCard">
        <Card.Header className="cardHeaderOverride takeASurveyCardHeader">
          <Row className="flexCenter">
            <Col sm={8}>About This Survey</Col>
            {showRecommendations ? (
              <Col>
                <Row className="flexCenter">
                  {recalculateClicked && hasRelatedProject ? (
                    <Col
                      onClick={(e) => {
                        setRecalculateClicked(false);
                        setRevertClicked(true);
                        setSurveyResponses(originalSurveyResponses);
                        handleSurveySubmit(
                          e,
                          true,
                          false,
                          originalSurveyResponses
                        );
                      }}
                    >
                      <span className="revertButton cursorPoint">
                        Revert To Previous
                      </span>
                    </Col>
                  ) : null}
                  <Col>
                    {!showNoAccessError &&
                    currentSurvey?.data?.attributes?.status !== "pending" &&
                    currentSurvey?.data?.attributes?.status !== "shared" ? (
                      <Button
                        id="surveysContinue"
                        className="btnSeafoam floatRight"
                        onClick={() => {
                          setCreateProjectData({
                            resources,
                            phases,
                            currentSurvey,
                            calculations: recalculateClicked
                              ? recalculatedCalculations
                              : calculations,
                            recommendations: recalculateClicked
                              ? recalculatedRecommendations
                              : recommendations,
                            nonServiceRecommendations: recalculateClicked
                              ? recalculatedNonServiceRecommendations
                              : nonServiceRecommendations,
                            surveyResponses: surveyResponses,
                            questionnaire,
                          });
                          if (hasRelatedProject && !recalculateClicked) {
                            window.open(
                              `/projects/${currentSurvey?.data?.relationships?.project?.data?.id}/edit`,
                              "_blank"
                            );
                          } else if (hasRelatedProject && recalculateClicked) {
                            handleApplySurveys(
                              currentSurvey?.data?.relationships?.project?.data
                                ?.id
                            );
                          } else {
                            createProjectFromRecommendations();
                          }
                        }}
                      >
                        {hasRelatedProject && !recalculateClicked
                          ? "View Related Project"
                          : hasRelatedProject && recalculateClicked
                          ? "Apply To Project"
                          : "Review / Add Project Details"}
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            ) : null}
          </Row>
          <div className="surveyIntro">
            {questionnaire.data?.attributes?.introduction}
          </div>
        </Card.Header>
        <Card.Body>
          <Card className="whiteBg takeASurveyOuterCard takeSurveyInnerCard">
            <Card.Header className="cardHeaderOverride takeASurveyCardHeader">
              Responses
            </Card.Header>
            <Card.Body>
              <div className="surveyIntro">
                {questionnaireLoading || currentSurveyLoading ? (
                  <SmallSpinner />
                ) : (
                  <Survey
                    questionnaire={questionnaire.data}
                    questions={questionnaire.included}
                    setSurveyResponses={(r) => setSurveyResponses(r)}
                    surveyResponses={surveyResponses}
                    handleSurveySubmit={(
                      e,
                      revertClickedParam,
                      recalcClickedParam
                    ) =>
                      handleSurveySubmit(
                        e,
                        revertClickedParam,
                        recalcClickedParam,
                        originalSurveyResponses
                      )
                    }
                    showRecommendations={showRecommendations}
                    isFromSurveyPage={true}
                    currentSurvey={currentSurvey?.data}
                    setRecalculateClicked={(bool) =>
                      setRecalculateClicked(bool)
                    }
                    isNewSurvey={isNewSurvey}
                    calculateClicked={calculateClicked}
                    setCalculateClicked={(bool) => setCalculateClicked(bool)}
                    revertClicked={revertClicked}
                    originalSurveyResponses={originalSurveyResponses}
                    setName={(n) => null}
                    showNoAccessError={showNoAccessError}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        </Card.Body>
        {showNoAccessError ? (
          <StaticBanner
            bannerText={
              "You do not have access to one or more services that have been recommended for this survey."
            }
            variant="warning"
          />
        ) : null}
      </Card>
      {!showNoAccessError ? (
        <SurveyResults
          recommendations={
            recalculateClicked ? recalculatedRecommendations : recommendations
          }
          recalculateClicked={recalculateClicked}
          setShowRecommendations={(r) => setShowRecommendations(r)}
          nonServiceRecommendations={
            recalculateClicked
              ? recalculatedNonServiceRecommendations
              : nonServiceRecommendations
          }
          showRecommendations={showRecommendations}
          account_slug={accountSlug}
          resources={resources}
          phases={phases}
          currentSurvey={currentSurvey}
          calculations={
            recalculateClicked ? recalculatedCalculations : calculations
          }
          revertClicked={revertClicked}
          surveyResponses={surveyResponses}
          accessToken={accessToken}
          hasCalculationsInAccordion={true}
          calculationsAtTop={true}
        />
      ) : null}
    </main>
  ) : (
    <ScopeStackSpinner />
  );
}

export default TakeASurvey;
