import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useNavigate } from "react-router";
import AccordionCard from "@components/AccordionCard/AccordionCard";
import { faArrowDown, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import "../GuidedOnboarding.css";
import OnboardingCards from "../reusable/OnboardingCards";
import { getLightBars } from "../onboardingHelpers/onboardingHelpers";

function InfoComponent({ currentPage, setCurrentPage }) {
  let navigate = useNavigate();

  const goToNextPage = () => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    if (newPage === 4) {
      navigate("/onboarding/phases");
      setCurrentPage(1);
    }
  };
  return (
    <OnboardingCards
      contentCardHeader={
        "There are some things you should know before getting started."
      }
      leftContent={
        <div>
          The big picture, a.k.a. an entire customer engagement, is called a{" "}
          <strong>Project</strong>.
          {currentPage > 1 ? (
            <div>
              <br />
              <p>
                Within a project, you will organize groups of related effort
                into <strong>Phases</strong>.
                <br />
                Phases correspond to project or billing milestones.
              </p>
            </div>
          ) : null}
          {currentPage === 3 ? (
            <div>
              <p>
                Under each Phase, there are <strong>Services</strong>. Services
                are the specific deliverables that make things happen.
              </p>
              <p>
                Finally, within a Service, you’ll find{" "}
                <strong>Subservices</strong> – those nitty-gritty detailed
                steps, actions or subtasks you take to complete the Service.
              </p>
            </div>
          ) : null}
        </div>
      }
      rightContent={
        <Card
          style={
            currentPage === 2
              ? { height: "470px" }
              : currentPage === 3
              ? { height: "540px" }
              : {}
          }
          className="slideCard"
        >
          <CardHeader className="internalCardHeader">
            Cisco Super Mega Router Install (Project)
          </CardHeader>
          <hr />
          <Card.Body className="internalCardBody">
            {currentPage === 1 ? (
              <div>
                <div className="darkBar"></div>
                {getLightBars()}
              </div>
            ) : null}
            {currentPage === 2 || currentPage === 3 ? (
              <div>
                <AccordionCard
                  header={
                    <div
                      style={{ marginRight: "29vw" }}
                      className="accordionPlanHeader"
                    >
                      Plan (Phase)
                    </div>
                  }
                  body={
                    currentPage === 2 ? (
                      getLightBars()
                    ) : (
                      <div className="serviceContainer">
                        <p>
                          <strong>(Service)</strong> Networking design session
                        </p>
                        {[
                          { name: "Whiteboard session" },
                          { name: "Approved design document" },
                          { name: "Subservice 3" },
                        ].map((subservice) => (
                          <div key={subservice.name} className="whiteBar">
                            <strong>(subservice)</strong>
                            {subservice.name}
                          </div>
                        ))}
                      </div>
                    )
                  }
                  className="phaseAccordion"
                  iconOpen={faArrowDown}
                  iconClosed={faArrowRight}
                  closeable={true}
                  iconHexCode="#1c2655"
                  headerBackgroundHexCode="#fff"
                  cardBackgroundHexCode="#fff"
                  cardClass="stillAccordion"
                  startingAccordionState={"open"}
                  setAccordianIsOpen={() => null}
                />
                <AccordionCard
                  header={
                    <div
                      style={{ marginRight: "28vw" }}
                      className="accordionDesignHeader"
                    >
                      Design (Phase)
                    </div>
                  }
                  body={<div></div>}
                  className="phaseAccordion"
                  iconOpen={faArrowDown}
                  iconClosed={faArrowRight}
                  closeable={false}
                  iconHexCode="#1c2655"
                  headerBackgroundHexCode="#fff"
                  cardBackgroundHexCode="#fff"
                  cardClass="stillAccordion"
                  startingAccordionState={"closed"}
                  setAccordianIsOpen={() => null}
                />
                <AccordionCard
                  header={
                    <div
                      style={{ marginRight: "26vw" }}
                      className="accordionImplementHeader"
                    >
                      Implement (Phase)
                    </div>
                  }
                  body={<div></div>}
                  className="phaseAccordion"
                  iconOpen={faArrowDown}
                  iconClosed={faArrowRight}
                  closeable={false}
                  iconHexCode="#1c2655"
                  headerBackgroundHexCode="#fff"
                  cardBackgroundHexCode="#fff"
                  cardClass="stillAccordion"
                  startingAccordionState={"closed"}
                  setAccordianIsOpen={() => null}
                />
              </div>
            ) : null}{" "}
          </Card.Body>
        </Card>
      }
      rightButtonText={"Next"}
      rightButtonOnClick={() => goToNextPage()}
      skipSetupButton={false}
      currentPage={currentPage}
    />
  );
}

export default InfoComponent;
