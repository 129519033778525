import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form, FormCheck } from "react-bootstrap";
import NoneExistScreen from "@components/NoneExistScreen/NoneExistScreen";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import "./ResourcePlans.css";
import API from "@API";
import FormTextField from "@components/Forms/FormTextField";
import FormDateField from "@components/Forms/FormDateField";
import FormNumberField from "@components/Forms/FormNumberField";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import { getDate, formatUnprocessibleResponse } from "@utils/helperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faCopy,
  faPencil,
  faSquareList,
} from "@fortawesome/pro-regular-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { useNavigate } from "react-router-dom";

function ResourcePlans({
  account_slug,
  project_id,
  account_id,
  authorizationCode,
  scenarios,
  setScenarios,
  isLoading,
}) {
  //Env
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const navigate = useNavigate();

  //Modals
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    message: "",
    title: "",
    actionButton: "",
    scenarioId: "",
  });
  const [newScenarioButtonClicked, setNewScenarioButtonClicked] =
    useState(false);
  const [editScenarioButtonClicked, setEditScenarioButtonClicked] =
    useState(false);

  //Scenario States
  const [scenarioName, setScenarioName] = useState("");
  const [duplicatedScenarioName, setDuplicatedScenarioName] = useState("");
  const [scenarioStartDate, setScenarioStartDate] = useState("");
  const [numberOfWeeks, setNumberOfWeeks] = useState("");
  const [scenarioId, setScenarioId] = useState(null);

  //Alerts
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Scenario details created"
  );
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {}, [scenarios, isLoading]);

  const handleSuccess = (successMessage) => {
    setSuccessMessage(successMessage);
    setShowSuccessAlert(true);
    setTimeout(() => {
      setShowSuccessAlert(false);
    }, 3000);
  };

  const handleErr = (err, setShow) => {
    setErrorMessages(formatUnprocessibleResponse(err, "scenario"));
    setShow(false);
    setShowFailAlert(true);
  };

  const closeCreateModal = () => {
    setShowCreateModal(false);
    setScenarioName("");
    setScenarioStartDate("");
    setNumberOfWeeks("");
  };

  let scenarioData = {
    data: {
      type: "resource-plans",
      attributes: {
        name: scenarioName,
        "start-on": scenarioStartDate,
        weeks: numberOfWeeks,
      },
      relationships: {
        project: { data: { type: "projects", id: project_id } },
      },
    },
  };

  const createScenario = () => {
    scenarioData.data.attributes = {
      name: scenarioName,
      "start-on": scenarioStartDate,
      weeks: numberOfWeeks,
    };

    API.Post(
      `${apiHost}/${account_slug}/v1/resource-plans`,
      scenarioData,
      authorizationCode
    )
      .then((res) => {
        if (res.status == 201) {
          closeCreateModal();
          handleSuccess("Scenario details created");
          scenarios.unshift(res.data.data);
          setForceUpdate(!forceUpdate);
        }
      })
      .catch((err) => {
        handleErr(err, setShowCreateModal);
      });
  };

  const deleteScenario = (id) => {
    setShowConfirmModal(false);
    let scenarioData = {
      data: {
        type: "resource-plans",
        id: id,
      },
    };

    API.Delete(
      `${apiHost}/${account_slug}/v1/resource-plans/${id}`,
      scenarioData,
      authorizationCode
    )
      .then((response) => {
        if (response.status == 204) {
          handleSuccess("Scenario removed successfully");
          let newScenarios = scenarios.filter((scenario) => scenario.id !== id);
          setScenarios(newScenarios);
        }
      })
      .catch((err) => {
        handleErr(err, setShowConfirmModal);
      });
  };

  const duplicateScenario = (id, newScenarioName) => {
    setShowConfirmModal(false);
    scenarioData.data.attributes = {
      name: newScenarioName,
    };
    API.Post(
      `${apiHost}/${account_slug}/v1/resource-plans/${id}/duplicate`,
      scenarioData,
      authorizationCode
    )
      .then((response) => {
        if (response.status == 200) {
          handleSuccess("Scenario duplicated successfully");
          scenarios.unshift(response.data.data);
          setForceUpdate(!forceUpdate);
        }
      })
      .catch((err) => {
        handleErr(err, setShowConfirmModal);
      });
  };

  const useScenarioAsBaseline = (id) => {
    setShowConfirmModal(false);
    scenarioData.data.id = id;
    scenarioData.data.attributes = { baseline: true };
    API.Patch(
      `${apiHost}/${account_slug}/v1/resource-plans/${id}`,
      scenarioData,
      authorizationCode
    )
      .then((response) => {
        if (response.status == 200) {
          handleSuccess("Scenario successfully set as baseline");
        }
      })
      .catch((err) => {
        handleErr(err, setShowConfirmModal);
      });
  };

  const createModalBody = (
    <div>
      <small>
        To create a new scenario please provide the name and estimated project
        duration
      </small>
      <Row style={{ marginTop: "20px" }}>
        <Col>
          <FormTextField
            label="Scenario Name"
            placeholder={"What do you want to call this scenario"}
            value={scenarioName}
            onChange={(e) => setScenarioName(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormDateField
            label="Start Date"
            value={scenarioStartDate}
            onChange={(e) => setScenarioStartDate(e.target.value)}
          />
        </Col>
        <Col>
          <FormNumberField
            label="Number of Weeks"
            placeholder={"Choose length of Project"}
            value={numberOfWeeks}
            min={0}
            step={1}
            onChange={(e) => setNumberOfWeeks(e.target.value)}
          />
        </Col>
      </Row>
    </div>
  );

  let confirmModalBody = (
    <div>
      <small>{confirmModalData.message}</small>
    </div>
  );

  if (confirmModalData.title == "Duplicate Scenario") {
    confirmModalBody = (
      <div>
        <small>{confirmModalData.message}</small>
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <FormTextField
              label="Scenario Name"
              placeholder={"What do you want to call this scenario"}
              value={duplicatedScenarioName}
              onChange={(e) => {
                setDuplicatedScenarioName(e.target.value);
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }

  const successAlert = () => {
    if (showSuccessAlert == true) {
      return (
        <DismissibleAlert
          className="scenarioAlerts"
          variant="info"
          onClose={() => setShowSuccessAlert(false)}
          text={successMessage}
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div className="col-sm-12" id="sow-workarea">
      <div className="tab-content">
        <div className="tab-pane show active">
          {successAlert()}
          {handleFailAlert()}
          {isLoading ? (
            <ScopeStackSpinner />
          ) : (
            <div className="cardContainer">
              <ScopeStackModal
                modalTitle={"Create Scenario"}
                modalBody={createModalBody}
                button1Text="Cancel"
                handleButton1Click={() => setShowCreateModal(false)}
                button2Text={"Create"}
                handleButton2Click={createScenario}
                show={showCreateModal}
                handleClose={() => setShowCreateModal(false)}
              />
              <ScopeStackModal
                modalTitle={confirmModalData.title}
                modalBody={confirmModalBody}
                button1Text="Cancel"
                handleButton1Click={() => setShowConfirmModal(false)}
                button2Text={confirmModalData.actionButton.name}
                handleButton2Click={() =>
                  confirmModalData.actionButton.function(
                    confirmModalData.scenarioId,
                    duplicatedScenarioName
                  )
                }
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
              />
              <Row className="scenarioHeader">
                <Col sm={10}>
                  <div className="planningScenarios">Planning Scenarios</div>
                </Col>
                {/* {scenarios.length !== 0 ? (
                  <Col sm={3}>
                    <div
                      className="btnSeawhite"
                      onClick={() => {
                        console.log("here");
                      }}
                    >
                      Export Scenarios
                    </div>
                  </Col>
                ) : null} */}
                <Col sm={2}>
                  <Button
                    variant="primary"
                    style={{ width: "140px" }}
                    className="seafoamBgButton"
                    onClick={() => {
                      setShowCreateModal(true);
                      setNewScenarioButtonClicked(true);
                    }}
                  >
                    New Scenario
                  </Button>
                </Col>
              </Row>
              <hr style={{ width: "100%", marginBottom: "30px" }}></hr>
              {scenarios.length === 0 ? (
                <NoneExistScreen
                  noneExistText={"No Scenarios Exist"}
                  createNewText={`Click "New Scenario" to get started!`}
                />
              ) : (
                scenarios.map((scenario) => (
                  <Card
                    style={{
                      border: "1px solid #eee",
                      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.15",
                      marginBottom: "30px",
                      marginLeft: "2%",
                      width: "95%",
                    }}
                    className={
                      scenario.attributes.baseline
                        ? "isBaseline scenarioTile"
                        : "scenarioTile"
                    }
                    key={scenario.id}
                  >
                    <Card.Body>
                      <Row>
                        <Col sm={8}>
                          <Card.Text className="scenarioName">
                            {scenario.attributes.name}
                          </Card.Text>
                        </Col>
                        <Col className="pull-right text-right">
                          <span
                            className="scenarioTileButton"
                            onClick={() => {
                              setScenarioId(scenario.id);
                              navigate(
                                `/projects/${project_id}/resource_plans/${scenario.id}`,
                                { id: scenario.id }
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faPencil} />
                            &nbsp;Edit
                          </span>
                          <span
                            className="scenarioTileButton"
                            onClick={() => {
                              setConfirmModalData({
                                message: `Are you sure you want to duplicate ${scenario.attributes.name}?`,
                                title: "Duplicate Scenario",
                                actionButton: {
                                  name: "Duplicate",
                                  function: duplicateScenario,
                                },
                                scenarioId: scenario.id,
                                name: scenario.attributes.name,
                              });
                              setShowConfirmModal(true);
                            }}
                          >
                            <FontAwesomeIcon
                              style={{ marginLeft: "8px" }}
                              icon={faCopy}
                            />
                            &nbsp; Duplicate
                          </span>
                          <span
                            className="scenarioTileButton"
                            onClick={() => {
                              setConfirmModalData({
                                message: `Are you sure you want to delete ${scenario.attributes.name}? This action cannot be undone.`,
                                title: "Delete Scenario",
                                actionButton: {
                                  name: "Delete",
                                  function: deleteScenario,
                                },
                                scenarioId: scenario.id,
                                name: scenario.attributes.name,
                              });
                              setShowConfirmModal(true);
                            }}
                          >
                            <FontAwesomeIcon
                              className="scenarioTileButton"
                              icon={faTrashCan}
                            />
                            &nbsp; Delete
                          </span>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col sm={2}>
                          <FontAwesomeIcon
                            className="scenarioDates"
                            icon={faCalendarDay}
                          />
                          &nbsp;
                          <span className="scenarioDates">{`Starting ${getDate(
                            scenario.attributes["start-on"],
                            {
                              month: "numeric",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}`}</span>
                        </Col>
                        <Col sm={8}>
                          <FontAwesomeIcon
                            className="scenarioDates"
                            icon={faSquareList}
                          />
                          &nbsp;
                          <span className="scenarioDates">{`${scenario.attributes.weeks} Weeks / ${scenario.attributes.sprints} Sprints`}</span>
                        </Col>
                        <Col
                          sm={2}
                          style={{ cursor: "pointer", textAlign: "right" }}
                        >
                          <Form>
                            <FormCheck
                              id={scenario.id}
                              className="useAsBaseline"
                              label="Use As Basline"
                              checked={scenario.attributes.baseline}
                              //   custom={true}
                              onChange={(e) => {
                                let updatedScenarios = scenarios.map(
                                  (scenario) => {
                                    if (e.target.id == scenario.id) {
                                      scenario.attributes.baseline = true;
                                      return scenario;
                                    } else {
                                      scenario.attributes.baseline = false;
                                      return scenario;
                                    }
                                  }
                                );
                                setScenarios(updatedScenarios);
                                useScenarioAsBaseline(scenario.id);
                              }}
                            />
                          </Form>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResourcePlans;
