const buildOptionsArray = (source) => {
  let optionsArray = [];
  if (source !== null) {
    optionsArray = source.map((item) => {
      return { label: item.attributes.name, value: item.id };
    });
  }
  return optionsArray;
};

export default buildOptionsArray;
