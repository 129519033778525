import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import RouterButton from "@components/Buttons/RouterButton/RouterButton";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import {
  snakeToPascalCase,
  splitPascalCase,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";
import { useNavigate } from "react-router";
import API from "@API";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";

function ProjectContacts({
  account_slug,
  project_id,
  account_id,
  setContactId,
  contactId,
  projectStatus,
  contacts,
  contactCreated,
  contactUpdated,
  isLoading,
  authorizationCode,
}) {
  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    if (contactCreated === true) {
      setCreatedAlertIsOpen(true);
    }
    if (contactUpdated === true) {
      setUpdatedAlertIsOpen(true);
    }
  }, [isLoading]);

  const getTableData = () => {
    if (contacts.length === 0) {
      return [];
    }
    return contacts.map((contact, index) => {
      let contactId = contact.id;

      const deleteContact = (e) => {
        e.stopPropagation();
        let userConfirms = confirm(
          "Are you sure you want to delete this contact?"
        );
        if (userConfirms) {
          let contact = {
            data: {
              type: "project-contacts",
              id: contactId,
            },
          };
          API.Delete(
            `${apiHost}/${account_slug}/v1/project-contacts/${contactId}`,
            contact,
            authorizationCode
          )
            .then((response) => {
              if (response.status == "204") {
                window.location.reload();
              }
            })
            .catch((err) => {
              setErrorMessages(formatUnprocessibleResponse(err, "contact"));
              setShowFailAlert(true);
            });
        }
      };

      // Render icon buttons under actions on hover if the project status is: building
      const renderActions = () => {
        if (projectStatus === "building") {
          return (
            <div>
              <OnHoverButtonIcon
                className="actions"
                iconClass="fa fa-remove"
                dataConfirm="Are you sure?"
                buttonText="Delete"
                onClick={(e) => deleteContact(e)}
              />
            </div>
          );
        } else {
          return null;
        }
      };

      const redirectToEditContact = () => {
        setContactId(contactId);
        return navigate(
          `/projects/${project_id}/project_contacts/${contactId}/edit`
        );
      };

      // Map returns data for ScopeStackTable Class
      return {
        rowClass: "contactRow",
        onClick: redirectToEditContact,
        columns: [
          {
            class: "",
            name: contact.attributes.name,
          },
          {
            class: "",
            name: contact.attributes.title,
          },
          {
            class: "",
            name: contact.attributes.email,
          },
          {
            class: "",
            name: contact.attributes.phone,
          },
          {
            class: "",
            name: splitPascalCase(
              snakeToPascalCase(contact.attributes["contact-type"])
            ),
          },
        ],
        actions: renderActions(),
      };
    });
  };

  const getFooterData = () => {
    let content = "";
    if (projectStatus === "building") {
      content = (
        <RouterButton
          path={`/projects/${project_id}/project_contacts/new`}
          className="newResource"
          iconClass="fa fa-plus"
          buttonText="Add Contact"
        />
      );
    }
    return {
      rowClass: "col-12",
      columns: [{ class: "contactFooter", content: content }],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Contact",
      },
      {
        class: "",
        name: "Title",
      },
      {
        class: "",
        name: "Email",
      },
      {
        class: "",
        name: "Phone",
      },
      {
        class: "",
        name: "Contact Type",
      },
    ],
  };

  const tableData = getTableData();
  const footerData = getFooterData();

  let contactsTable = new ScopeStackTable(
    "contacts",
    headerData,
    tableData,
    footerData
  );

  const contactCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="governanceAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Contact created"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {contactCreatedAlert()}
      {handleFailAlert()}
      <Card>
        <CardHeader title="Contacts" />
        <Card.Body>
          <BaseTable
            className="scopestack contactsTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={contactsTable.buildHeaderRows()}
            dataRows={contactsTable.buildDataRows()}
            footerRows={contactsTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProjectContacts;
