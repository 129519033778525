import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router";

function ProductPricing({
  account_slug,
  account_id,
  project_id,
  projectStatus,
  products,
  projectProducts,
  currencyUnit,
  productPricings,
  isLoading,
  setProductId,
}) {
  // Env
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const navigate = useNavigate();

  const getExtCost = (productId) => {
    return productPricings
      .filter((product) => {
        const numericId = product.id.split("-")[2]; // Extract the numeric portion
        return numericId === productId;
      })
      .map((product) => parseFloat(product.attributes["hardware-cost"]))
      .reduce((prev, curr) => prev + curr, 0);
  };
  

  const getExtPrice = (productId) => {
    return productPricings
      .filter((product) => {
        const numericId = product.id.split("-")[2]; // Extract the numeric portion
        return numericId === productId;
      })
      .map((product) => parseFloat(product.attributes["hardware-price"]))
      .reduce((prev, curr) => prev + curr, 0);
  };
  

  //States

  useEffect(() => {}, [isLoading, productPricings, projectProducts]);

  const getTableData = (array) => {
    if (array.length == 0) {
      return [];
    }

    return array.map((item) => {
      if (item.type === "totalRow") {
        return {
          rowClass: "productPricingRow",
          onClick: null,
          columns: [
            {
              class: "",
              name: "",
            },
            {
              class: "text-right",
              name: "",
            },
            {
              class: "text-right",
              name: "",
            },
            {
              class: "text-right",
              name: "",
            },
            {
              class: "text-right",
              name: "",
            },
            {
              class: "text-right",
              name: "",
            },
            {
              class: "text-right",
              name: item.name,
            },
            {
              class: "text-right",
              name: item.totalCost ? (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={
                    item.totalCost && item.totalCost !== "NaN"
                      ? parseFloat(item.totalCost).toFixed(2)
                      : parseFloat(0).toFixed(2)
                  }
                />
              ) : (
                ""
              ),
            },
            {
              class: "text-right",
              name:
                item.name !== "MARGIN" ? (
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={
                      item.totalPrice && item.totalPrice !== "NaN"
                        ? parseFloat(item.totalPrice).toFixed(2)
                        : parseFloat(0).toFixed(2)
                    }
                  />
                ) : (
                  item.totalPrice
                ),
            },
          ],
          actions: null,
        };
      }

      if (item.type !== "totalRow") {
        let productId = item.id;
        let attr = item.attributes;
        let product = attr.name;
        let quantity = attr.quantity;
        let mfrPrice = attr["list-price"];
        let discount = attr["vendor-discount"];
        let blendedCost = getExtCost(productId) / quantity;
        let markup = attr["markup"];
        let blendedPrice = getExtPrice(productId) / quantity;
        let extCost = getExtCost(productId);
        let extPrice = getExtPrice(productId);

        const redirectToEditProductPricing = () => {
          setProductId(productId);
          return navigate(
            `/projects/${project_id}/project_material_pricings/${productId}/edit`
          );
        };

        return {
          rowClass: "productPricingRow",
          onClick: redirectToEditProductPricing,
          columns: [
            {
              class: "col-3",
              name: product,
            },
            {
              class: "text-right col-1",
              name: quantity,
            },
            {
              class: "text-right col-2",
              name:
                mfrPrice == "0.0" ? (
                  "—"
                ) : (
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString={true}
                    thousandSeparator={true}
                    value={mfrPrice}
                  />
                ),
            },
            {
              class: "text-right col-1",
              name: mfrPrice == "0.0" ? "—" : discount + "%",
            },
            {
              class: "text-right col-1",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={
                    blendedCost && blendedCost !== "NaN"
                      ? parseFloat(blendedCost).toFixed(2)
                      : parseFloat(0).toFixed(2)
                  }
                />
              ),
            },
            {
              class: "text-right col-1",
              name: mfrPrice == "0.0" ? "—" : markup + "%",
            },
            {
              class: "text-right col-1",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={
                    blendedPrice && blendedPrice !== "NaN"
                      ? parseFloat(blendedPrice).toFixed(2)
                      : parseFloat(0).toFixed(2)
                  }
                />
              ),
            },
            {
              class: "text-right col-1",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={
                    extCost && extCost !== "NaN"
                      ? parseFloat(extCost).toFixed(2)
                      : parseFloat(0).toFixed(2)
                  }
                />
              ),
            },
            {
              class: "text-right col-1",
              name: (
                <CurrencyFormat
                  displayType="text"
                  prefix={currencyUnit}
                  isNumericString={true}
                  thousandSeparator={true}
                  value={
                    extPrice && extPrice !== "NaN"
                      ? parseFloat(extPrice).toFixed(2)
                      : parseFloat(0).toFixed(2)
                  }
                />
              ),
            },
          ],
          actions: null,
        };
      }
    });
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "col-3",
        name: "Product",
      },
      {
        class: "text-right col-1",
        name: "Quantity",
      },
      {
        class: "text-right col-2",
        name: "MFR Price",
      },
      {
        class: "text-right col-1",
        name: "Discount",
      },
      {
        class: "text-right col-1",
        name: "Blended Cost",
      },
      {
        class: "text-right col-1",
        name: "Markup",
      },
      {
        class: "text-right col-1",
        name: "Blended Price",
      },
      {
        class: "text-right col-1",
        name: "EXT Cost",
      },
      {
        class: "text-right col-1",
        name: "EXT Price",
      },
    ],
  };

  const arrangeDataForTable = () => {
    let tableData = [...projectProducts];
    let totalCost = 0;
    let totalPrice = 0;

    tableData.forEach((product) => {
      totalCost += getExtCost(product.id);
    });
    tableData.forEach((product) => {
      totalPrice += getExtPrice(product.id);
    });
    
    if (tableData.length !== 0) {
      let total = {
        type: "totalRow",
        name: "TOTAL",
        totalPrice: totalPrice,
        totalCost: totalCost
      };

      let profit = {
        type: "totalRow",
        name: "PROFIT",
        totalPrice: total.totalPrice - total.totalCost,
      };

      let marginValue = parseFloat(
        (profit.totalPrice / total.totalPrice) * 100
      ).toFixed(2);

      let margin = {
        type: "totalRow",
        name: "MARGIN",
        totalPrice:
          marginValue && marginValue !== "NaN" ? marginValue + "%" : "—",
      };
      tableData.push(total);
      tableData.push(profit);
      tableData.push(margin);
    }
    return tableData;
  };

  const tableData = getTableData(arrangeDataForTable());
  const footerData = null;

  let productPricingTable = new ScopeStackTable(
    "products",
    headerData,
    tableData,
    footerData
  );
  return (
    <div>
      <Card>
        <CardHeader title="Product Pricing" />
        <Card.Body>
          <BaseTable
            className="scopestack productPricingTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={productPricingTable.buildHeaderRows()}
            dataRows={productPricingTable.buildDataRows()}
            footerRows={productPricingTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProductPricing;
