import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import API from "../../utils/API/API";
import { Select2V2 } from "@components/FormsV2";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import React, { useEffect, useState } from "react";
import { Button, Form, FormCheck, Modal, Row, Col } from "react-bootstrap";
import {
  getPathname,
  handleAlert,
  snakeToText,
  validateEmails,
} from "@utils/helperFunctions";
import "./style/style.css";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import loading from "./images/SS-icon-checkmark.gif";
import successImg from "./images/SS-icon-checkmark.png";
import StationaryModal from "@components/StationaryModal/StationaryModal";
import FolderTreeComponent from "@components/FolderTreeComponent/FolderTreeComponent";

function GenerateDocument({
  orientation,
  show,
  setShow,
  account_slug,
  project_id,
  saveToCloudStorageFlag,
  isStationary,
  authorizationCode,
  project,
}) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [generateDocumentOptions, setGenerateDocumentOptions] = useState([
    { name: "Download" },
    { name: "Email" },
    { name: "Save To Cloud Storage" },
  ]);
  const [documentTypes, setDocumentTypes] = useState<
    ProjectDownLoadType[] | []
  >([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState({
    label: "",
    value: 0,
  });
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] =
    useState("download");
  const [emailFieldChecked, setEmailFieldChecked] = useState(false);
  const [saveToCloudStorageChecked, setSaveToCloudStorageChecked] =
    useState(false);
  const [emailList, setEmailList] = useState("");
  const [emailsAreInvalid, setEmailsAreInvalid] = useState(false);
  const [emailListChanged, setEmailListChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [documentId, setDocumentId] = useState(null);
  const [documentURL, setDocumentURL] = useState(null);
  const [generateDocumentIsSubmitted, setGenerateDocumentIsSubmitted] =
    useState(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessfulGenerationModal, setshowSuccessfulGenerationModal] =
    useState(false);
  const [accountConnections, setAccountConnections] = useState([]);
  const [showFileTreeModal, setShowFileTreeModal] = useState(false);
  const [connectedApps, setConnectedApps] = useState([]);
  const [storageIntegrations, setStorageIntegrations] = useState<any[]>([]);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [filename, setFilename] = useState<string | null>(null);
  const [selectedFolder, setSelectedFolder] = useState({ id: "0", name: "" });
  const [originalSelectedFolder, setOriginalSelectedFolder] = useState({
    id: "0",
    name: "",
  });
  const [folderLoading, setFolderLoading] = useState(false);
  const [allFolders, setAllFolders] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [fileTreeData, setFileTreeData] = useState({});
  const [rootFolder, setRootFolder] = useState("");
  const [generatePdf, setGeneratePdf] = useState(false);
  const [forceRegeneration, setForceRegeneration] = useState(false);

  const storageOptions = project?.attributes?.["storage-options"];

  const getFileTreeData = (accountConnection, selectedIntegration) => {
    setSelectedIntegration(selectedIntegration);
    if (accountConnection) {
      setSelectedConnection(accountConnection);
      API.Get(
        `${apiHost}/${account_slug}/v1/file-storages/${accountConnection.id}/get-contents`,
        authorizationCode
      ).then((res) => {
        const folders = res.data.data.attributes.folders.map((folder) => {
          folder = { ...folder, type: "folder", children: [] };
          return folder;
        });
        const files = res.data.data.attributes.files.map((file) => {
          file = { ...file, type: "file" };
          return file;
        });
        setAllFolders(folders);
        setAllFiles(files);
        const foldersWithChildren = folders.map((folder) => {
          folder = {
            ...folder,
            children: (
              folders.filter((f) => f.parent_folder_id == folder.id) || []
            ).concat(files.filter((file) => file.folder_id == folder.id) || []),
          };
          return folder;
        });
        const foldersInRoot = foldersWithChildren.filter(
          (folder) => folder.parent_folder_id === null
        );
        const fileTreeData = foldersInRoot.concat(
          files.filter((file) => file.folder_id === null)
        );
        const rootFolder = getPathname(foldersInRoot[0].file_url);
        const rootFolderName = rootFolder.split("/")[1];
        setRootFolder(rootFolderName);
        setFileTreeData({
          name: `${snakeToText(rootFolderName)}`,
          children: fileTreeData,
        });
        const folderId = storageOptions[accountConnection.id]?.storage_id;
        let folder = { id: "0", name: rootFolder };
        let foundFolder;
        if (folderId) {
          foundFolder = folders.find((folder) => folder.id == folderId);
          folder = {
            id: foundFolder.id,
            name: getPathname(foundFolder.file_url),
          };
        }
        setSelectedFolder(folder);
        setOriginalSelectedFolder(folder);

        setFolderLoading(false);
      });
    } else {
      setSelectedFolder({ id: "0", name: rootFolder });
      setFolderLoading(false);
    }
  };

  useEffect(() => {
    if (authorizationCode !== "") {
      //Check for docusign
      API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
        (res) => {
          const documentGenerateOptions = saveToCloudStorageFlag
            ? generateDocumentOptions
            : generateDocumentOptions.filter(
                (option) => option.name !== "Save To Cloud Storage"
              );
          if (res.data.data.attributes["esignature?"]) {
            if (
              !generateDocumentOptions.find(
                (option) => option.name === "Electronic Signature"
              )
            ) {
              setGenerateDocumentOptions([
                ...documentGenerateOptions,
                { name: "Electronic Signature" },
              ]);
            }
          } else {
            setGenerateDocumentOptions(documentGenerateOptions);
          }
          setIsLoading(false);
        }
      );

      //GET ProjectDownloadTypes
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-download-types`,
        authorizationCode
      ).then((response) => {
        const documentTypes = response.data.data;
        setDocumentTypes(documentTypes);
        setSelectedDocumentType({
          value: documentTypes[0].attributes["template-id"],
          label: documentTypes[0].attributes.name,
        });
        setFilename(documentTypes[0].attributes?.filename);
        setIsLoading(false);
      });

      // GET Account Connections
      API.Get(
        `${apiHost}/${account_slug}/v1/account-connections`,
        authorizationCode
      ).then((response) => {
        const accountConnections = response?.data?.data;
        setAccountConnections(accountConnections);
        const mergeDevAccountConnections = accountConnections.filter(
          (ac) => ac.attributes.service === "merge_dev"
        );

        // GET Connected Apps
        API.Get(
          `${apiHost}/${account_slug}/v1/connected-apps`,
          authorizationCode
        ).then((res) => {
          const connectedApps = res?.data?.data;
          let mergeDevConnectedApp = connectedApps.find(
            (ac) => ac.attributes.service === "merge.dev"
          );
          let storageIntegrations: any[] = [];
          mergeDevAccountConnections.forEach((ac) => {
            mergeDevConnectedApp?.attributes?.providers.forEach((provider) => {
              if (
                provider.name
                  .toLowerCase()
                  .includes(ac?.attributes?.settings.storage)
              ) {
                storageIntegrations.push({
                  provider: provider,
                  connection: ac,
                });
              }
            });
          });
          setConnectedApps(connectedApps);
          setStorageIntegrations(storageIntegrations);
          let accountConnection;
          let selectedIntegration;
          if (storageIntegrations.length === 1) {
            accountConnection = storageIntegrations[0].connection;
            selectedIntegration = storageIntegrations[0];
          } else if (Object.keys(storageOptions).length !== 0) {
            accountConnection = accountConnections.find(
              (ac) => ac.id == Object.keys(storageOptions)[0]
            );
            selectedIntegration = storageIntegrations.find((si) =>
              si.provider.name
                .toLowerCase()
                .includes(accountConnection?.attributes?.settings.storage)
            );
          }
          getFileTreeData(accountConnection, selectedIntegration);
        });
      });
    }
  }, [show]);

  useEffect(() => {
    const checkForDocURL = setInterval(() => {
      if (documentId !== null && selectedDeliveryMethod !== "docusign") {
        API.Get(
          `${apiHost}/${account_slug}/v1/project-documents/${documentId}`,
          authorizationCode
        ).then((res) => {
          // Stop polling once the document has moved on from 'created'
          if (res.data.data.attributes.status !== "created") {
            clearInterval(checkForDocURL);
            if (res.data.data.attributes["document-url"] !== null) {
              setDocumentURL(res.data.data.attributes["document-url"]);
            }
            if (res.data.data.attributes.status === "failed") {
              let errorMsgs = res.data.data.attributes["error-text"];
              setErrorMessages(errorMsgs.split("\n").map((msg) => msg + "."));
              setShowFailAlert(true);
            }
          }
        });
      } else {
        clearInterval(checkForDocURL);
      }
    }, 10000);
  }, [documentId]);

  const renderProjectDownLoadOptions = () => {
    let options = new Set();
    let documentGroups = new Set();
    documentTypes.forEach((documentType) => {
      documentGroups.add(documentType?.["attributes"]?.["group"]);
    });

    Array.from(documentGroups).forEach((docGroup) => {
      options.add({
        label: docGroup,
        options: documentTypes
          .filter((docType) => docType?.["attributes"]?.["group"] === docGroup)
          .map((dt) => {
            return {
              label: dt?.["attributes"]?.["name"],
              value: dt?.["attributes"]?.["template-id"],
            };
          }),
      });
    });
    return Array.from(options);
  };

  const closeModal = () => {
    setShow(false);
    setEmailFieldChecked(false);
    setShowFileTreeModal(false);
    setSaveToCloudStorageChecked(false);
    setEmailList("");
    setEmailListChanged(false);
    setForceRegeneration(false);
    setGeneratePdf(false);
  };

  const closeSuccessModal = () => {
    setDocumentId(null);
    setDocumentURL(null);
    setGenerateDocumentIsSubmitted(false);
    setshowSuccessfulGenerationModal(false);
  };

  const generateDocument = (e) => {
    e.preventDefault();

    closeModal();
    if (selectedDeliveryMethod !== "docusign") {
      setshowSuccessfulGenerationModal(true);
    }

    setGenerateDocumentIsSubmitted(true);
    let documentData = {
      data: {
        type: "project-documents",
        attributes: {
          "document-type":
            documentTypes.length > 0
              ? documentTypes?.find(
                  (documentType) =>
                    documentType?.attributes?.["template-id"] ===
                    selectedDocumentType?.value
                )?.attributes?.["document-type"]
              : "",
          "delivery-type": selectedDeliveryMethod,
          "generate-pdf": generatePdf,
          "template-id": selectedDocumentType.value,
          "force-regeneration": forceRegeneration,
        },
        relationships: {
          project: { data: { id: project_id, type: "projects" } },
        },
      },
    };

    if (selectedDeliveryMethod == "email") {
      if (!validateEmails(emailList) || emailList === "") {
        return;
      }
      documentData.data.attributes["email-list"] = emailList;
    }

    if (selectedDeliveryMethod == "cloud_storage") {
      documentData.data.attributes["filepath"] = selectedFolder.name;
      documentData.data.attributes["filename"] = filename;
      documentData.data.attributes["account-connection-id"] =
        selectedConnection?.["id"];
    }

    API.Post(
      `${apiHost}/${account_slug}/v1/project-documents`,
      documentData,
      authorizationCode
    ).then((res) => {
      if (res.data.data.attributes.status === "failed") {
        let errorMsgs = res.data.data.attributes["error-text"];
        setErrorMessages(errorMsgs.split("\n").map((msg) => msg + "."));
        setShowFailAlert(true);
      } else {
        if (selectedDeliveryMethod === "docusign") {
          setSuccessMessage("Submitted to Docusign.");
          setShowSuccessAlert(true);
        } else {
          setDocumentId(res.data.data.id);
        }
      }
    });
  };

  const getGenerateForm = () => {
    return (
      <Form
        style={{
          display: "flex",
          flexDirection: orientation === "horizontal" ? "row" : "column",
        }}
        className="generateForm"
      >
        <div>
          <div className="text20">Generation Method</div>
          <p>What type of document would you like to generate?</p>
          <Select2V2
            label={"*Document Type"}
            options={renderProjectDownLoadOptions()}
            isDisabled={false}
            onChange={(e) => {
              setSelectedDocumentType(e);
              const documentType = documentTypes.find(
                (docType) => docType.id == e.value
              );
              setFilename(documentType?.attributes?.["filename"]);
            }}
            value={selectedDocumentType}
          />
          {documentTypes?.find(
            (documentType) =>
              documentType?.attributes?.["template-id"] ===
              selectedDocumentType?.value
          )?.attributes?.["document-type"] === "sow" && (
            <Form.Check
              style={{ color: "#418172", marginTop: "10px" }}
              type="checkbox"
              id="generatePdfCheck"
              label="Convert Word Document to PDF?"
              checked={generatePdf}
              onChange={(e) => {
                setGeneratePdf(e.target.checked);
              }}
            />
          )}
          <FormFieldLabel
            className="marginTop10"
            label="How would you like to deliver this document?"
          />
          {generateDocumentOptions.map((item, index) => (
            <FormCheck
              defaultChecked={index == 0 ? true : false}
              required={true}
              id={
                item.name == "Email"
                  ? "email"
                  : item.name == "Download"
                  ? "download"
                  : item.name == "Save To Cloud Storage"
                  ? "cloud_storage"
                  : "docusign"
              }
              className="deliveryCheckbox"
              key={index}
              label={item.name}
              name="group1"
              type="radio"
              onChange={(e) => {
                setSelectedDeliveryMethod(e?.target?.id);
                if (e.target.id == "email") {
                  setEmailFieldChecked(true);
                } else {
                  setEmailFieldChecked(false);
                }
                if (e.target.id == "cloud_storage") {
                  setSaveToCloudStorageChecked(true);
                } else {
                  setSaveToCloudStorageChecked(false);
                }
              }}
            />
          ))}
          <Form.Check
            style={{
              color: "#418172",
              marginTop: "2em",
              minWidth: "165px",
              marginBottom: "15px",
            }}
            type="checkbox"
            id="forceRegenerationCheck"
            label="Force regeneration?"
            checked={forceRegeneration}
            onChange={(e) => {
              setForceRegeneration(e.target.checked);
            }}
          />
        </div>
        {emailFieldChecked && (
          <div
            className={
              orientation === "horizontal"
                ? "emailSectionHorizontal"
                : "emailSectionVertical"
            }
          >
            <div className="text20">Send by Email</div>
            <p>Where would you like the generated document sent?</p>
            <Form.Group className="formGroup">
              <FormFieldLabel
                className={""}
                label="*Email List (comma separated list of emails)"
              />
              <Form.Control
                required={true}
                value={emailList}
                onChange={(e) => {
                  setEmailListChanged(true);
                  setEmailList(e.target.value);
                }}
                isInvalid={emailsAreInvalid && emailListChanged}
                onBlur={() =>
                  setEmailsAreInvalid(
                    (!validateEmails(emailList) || emailList === "") &&
                      selectedDeliveryMethod === "email"
                  )
                }
              />
              {emailsAreInvalid && (
                <Form.Control.Feedback type="invalid">
                  Please enter a valid list of emails
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        )}
        {saveToCloudStorageChecked && (
          <div
            className={
              orientation === "horizontal"
                ? "emailSectionHorizontal"
                : "emailSectionVertical"
            }
          >
            <div className="text20">Save To Cloud Storage</div>
            <p>Choose your cloud storage provider.</p>
            <div className="flexCenter" style={{ marginTop: "-20px" }}>
              {storageIntegrations.map((si) => {
                return (
                  <img
                    onClick={() => {
                      setFolderLoading(true);
                      getFileTreeData(si?.connection, si);
                    }}
                    key={si?.provider?.name}
                    style={
                      selectedConnection?.["id"] == si?.connection?.id
                        ? {
                            border: "2px solid #418172",
                            width: "110px",
                            cursor: "pointer",
                            borderRadius: "7%",
                            margin: "5px",
                            padding: "5px",
                          }
                        : { width: "110px", cursor: "pointer", padding: "5px" }
                    }
                    src={si?.provider?.logo}
                  />
                );
              })}
            </div>
            {!folderLoading ? (
              <Form.Group className="formGroup">
                <FormFieldLabel
                  className={""}
                  label={`*${snakeToText(
                    selectedConnection?.["attributes"]?.["settings"]?.[
                      "storage"
                    ]
                  )} storage folder`}
                />
                <Form.Control
                  type="text"
                  readOnly={true}
                  value={selectedFolder.name}
                />
                <small
                  onClick={() => setShowFileTreeModal(true)}
                  className="editRoot"
                >
                  Edit Storage Folder
                </small>
              </Form.Group>
            ) : (
              <SmallSpinner />
            )}
            <Form.Group className="formGroup">
              <FormFieldLabel className={""} label="*Generated File Name" />
              <Form.Control
                required={true}
                value={filename ? filename : ""}
                onChange={(e) => {
                  setFilename(e.target.value);
                }}
                isInvalid={false}
              />
            </Form.Group>
          </div>
        )}
        {isStationary && (
          <Button
            style={
              selectedDeliveryMethod !== "email" ? { marginTop: "20px" } : {}
            }
            onClick={(e) => generateDocument(e)}
            className="seafoamBgButton"
            disabled={
              ((!validateEmails(emailList) || emailList === "") &&
                emailFieldChecked) ||
              (saveToCloudStorageChecked &&
                (selectedFolder.id === "0" || !filename))
            }
          >
            Generate
          </Button>
        )}
      </Form>
    );
  };

  const onNameClick = ({ defaultOnClick, nodeData }) => {
    defaultOnClick();

    const folderNames = Array.from(
      document.getElementsByClassName("displayName")
    );
    folderNames.forEach((folderElement) => {
      if (folderElement.innerHTML === nodeData.name) {
        folderElement.classList.add("selectedFolder");
      } else {
        folderElement.classList.remove("selectedFolder");
      }
    });

    const { id, file_url, type } = nodeData;
    let selectedFolder = {
      id: id,
      name: getPathname(file_url),
    };

    if (type === "folder") {
      setSelectedFolder(selectedFolder);
    } else {
      let folderOfFile = allFolders.find(
        (folder) => folder?.["id"] == nodeData.folder_id
      );
      selectedFolder = {
        id: folderOfFile ? folderOfFile["id"] : "0",
        name: folderOfFile
          ? getPathname(folderOfFile?.["file_url"])
          : rootFolder,
      };
      setSelectedFolder(selectedFolder);
    }
  };

  return (
    <div>
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "danger",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {isStationary ? (
        <StationaryModal
          header={"Generate Document"}
          body={isLoading ? <SmallSpinner /> : getGenerateForm()}
        />
      ) : (
        <ScopeStackModal
          className={
            orientation === "horizontal" &&
            (emailFieldChecked || saveToCloudStorageChecked)
              ? "wide "
              : ""
          }
          modalTitle={"Generate Document"}
          modalBody={isLoading ? <SmallSpinner /> : getGenerateForm()}
          button1Text="Cancel"
          handleButton1Click={() => closeModal()}
          button2Text={"Generate"}
          handleButton2Click={(e) => generateDocument(e)}
          button2Disabled={
            ((!validateEmails(emailList) || emailList === "") &&
              emailFieldChecked) ||
            (saveToCloudStorageChecked &&
              (selectedFolder.id === "0" || !filename))
          }
          show={show}
          handleClose={() => closeModal()}
        />
      )}
      <Modal
        centered
        show={showSuccessfulGenerationModal}
        onHide={closeSuccessModal}
      >
        <Modal.Body>
          {documentURL && selectedDeliveryMethod === "email" ? (
            <div>
              <img src={successImg} style={{ margin: "0px 115px 0px 135px" }} />
              <h1 style={{ textAlign: "center" }}>Success!</h1>
            </div>
          ) : (
            <div>
              <img src={loading} style={{ margin: "0 105px" }} />
              <p style={{ textAlign: "center" }}>
                The document is being generated. It will be{" "}
                {selectedDeliveryMethod === "download"
                  ? "available to download"
                  : "sent via email"}{" "}
                as soon as the file is ready. Please do not close browser
                window.
              </p>
            </div>
          )}
        </Modal.Body>
        {documentURL && (
          <Modal.Footer>
            <>
              <Button variant="secondary" onClick={closeSuccessModal}>
                Close
              </Button>
              {selectedDeliveryMethod === "download" && (
                <Button variant="primary" href={documentURL} target="_blank">
                  Download
                </Button>
              )}
            </>
          </Modal.Footer>
        )}
      </Modal>
      <ScopeStackModal
        className="fileTreeModal"
        show={showFileTreeModal}
        modalTitle={
          <>
            <Row>
              <Col>
                <img
                  style={{ width: "110px" }}
                  src={selectedIntegration?.["provider"]?.["logo"]}
                />
              </Col>
              <Col
                className="flexCenter"
                style={{ marginLeft: "344px", marginTop: "10px" }}
              >
                <Button
                  onClick={() => {
                    setSelectedFolder(originalSelectedFolder);
                    setShowFileTreeModal(false);
                  }}
                  className="ssButtonBgWhite"
                  style={{ width: "110px", marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setOriginalSelectedFolder(selectedFolder);
                    setShowFileTreeModal(false);
                  }}
                  className="squareGreenButton"
                  style={{ width: "112px" }}
                >
                  Use Folder
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ fontSize: "14px" }}>
                  <span>Selected Folder:</span>&nbsp;
                  <span style={{ fontWeight: "normal" }}>
                    {selectedFolder.name}
                  </span>
                </div>
              </Col>
            </Row>
          </>
        }
        modalBody={
          <FolderTreeComponent
            showCheckbox={false}
            readOnly={true}
            fileTreeData={fileTreeData}
            onNameClick={onNameClick}
          />
        }
        button1Text={null}
        button2Text={null}
        handleClose={() => setShowFileTreeModal(false)}
        handleButton1Click={() => null}
        handleButton2Click={() => null}
      />
    </div>
  );
}

export default GenerateDocument;
