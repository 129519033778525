import React, { useState, useEffect, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Nav } from "react-bootstrap";
import DropDownField from "@components/Forms/DropDownField";
import ToggleOnOff from "@components/Buttons/ToggleOnOff/ToggleOnOff";
import "./styles.css";
import { RootState } from "@reducers/rootReducer";
import {
  snakeToPascalCase,
  splitPascalCase,
  toSnakeCase,
  handleAlert,
} from "@utils/helperFunctions";
import {
  useUpdateCompanyAccountMutation,
  useGetSwaggerQuery,
  useGetSubscriptionQuery,
} from "../../../../services/ScopeStackAPI";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { AccountInput } from "../../../../types";
import useGetAccount from "../api/useGetAccount";
import usePrivilegeCheck from "@common/hooks/usePrivilegeCheck";
import useAccountHasEnetitlement from "@common/hooks/useAccountHasEntitlement";

const Advanced = () => {
  const [updateCompanyAccount] = useUpdateCompanyAccountMutation();

  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const {
    data: account,
    error,
    isLoading,
    refetch,
  } = useGetAccount(accountSlug);
  const { data: swagger } = useGetSwaggerQuery(null);
  const {
    data: subscription,
    error: subscriptionError,
    isLoading: subscriptionLoading,
  } = useGetSubscriptionQuery(null);
  const { hasPrivilege } = usePrivilegeCheck();

  const [shortDateFormat, setShortDateFormat] = useState("");
  const [longDateFormat, setLongDateFormat] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [loeUnits, setLoeUnits] = useState("");
  const [loeRounding, setLoeRounding] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [guidedProjectCreation, setGuidedProjectCreation] =
    useState<boolean>(false);
  const [watermarkedDocuments, setWatermarkedDocuments] =
    useState<boolean>(false);
  const [projectResourceRates, setProjectResourceRates] =
    useState<boolean>(false);
  const [itemChanged, setItemChanged] = useState(false);
  const [accountDataSet, setAccountDataSet] = useState(false);
  const [shortDateKey, setShortDateKey] = useState({});
  const [longDateKey, setLongDateKey] = useState({});
  const [timeZones, setTimeZones] = useState<string[]>([]);
  const [timeKey, setTimeKey] = useState({});

  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const successMessage = "Your changes were saved.";

  const [surveysEnabled, setSurveysEnabled] = useState(false);
  const [approvalWorkflowEnabled, setApprovalWorkflowEnabled] = useState(false);
  const [partnerServicesEnabled, setPartnerServicesEnabled] = useState(false);
  const [customerSuccessEnabled, setCustomerSuccessEnabled] = useState(false);
  const [showPlanSettings, setShowPlanSettings] = useState(false);
  const { hasEntitlement } = useAccountHasEnetitlement();

  const getDateTimeFormat = (object, value) => {
    for (let key in object) {
      if (object[key] === value) {
        return key;
      }
    }
    return null;
  };

  const getCurrentDateFormats = (dateFormatType) => {
    const currentDate = new Date();
    const shortDates = [
      "mm/dd/YYYY",
      "mm-dd-YYYY",
      "dd/mm/YYYY",
      "dd-mm-YYYY",
      "YYYY/mm/dd",
      "YYYY-mm-dd",
      "dd-Mon-YYYY",
    ];

    const longDates = ["MM dd, YYYY", "dd MM YYYY", "YYYY MM dd"];
    let dateFormats: string[] = [];

    dateFormatType === "short"
      ? (dateFormats = shortDates)
      : (dateFormats = longDates);

    const formattedDates = dateFormats.map((format) => {
      let formattedDate = format.replace(
        "mm",
        ("0" + (currentDate.getMonth() + 1)).slice(-2)
      );
      formattedDate = formattedDate.replace(
        "dd",
        ("0" + currentDate.getDate()).slice(-2)
      );
      formattedDate = formattedDate.replace(
        "YYYY",
        currentDate.getFullYear().toString()
      );
      formattedDate = formattedDate.replace(
        "Mon",
        currentDate.toLocaleString("default", { month: "short" })
      );

      if (format.includes("MM")) {
        formattedDate = formattedDate.replace(
          "MM",
          currentDate.toLocaleString("default", { month: "long" })
        );
      }

      return formattedDate;
    });

    return formattedDates;
  };

  const shortDateValues = getCurrentDateFormats("short");
  const longDateValues = getCurrentDateFormats("long");
  const timeValues = ["2:30 PM", "02:30 PM", "14:30"];

  const buildTimeDateKey = (arrayOfFormats, arrayOfDisplayValues) => {
    const result = arrayOfFormats.reduce((obj, string, index) => {
      obj[string] = arrayOfDisplayValues[index];
      return obj;
    }, {});
    return result;
  };

  useEffect(() => {
    if (!swagger) {
      return;
    }
    setShortDateKey(buildTimeDateKey(swagger.shortDateFormat, shortDateValues));
    setLongDateKey(buildTimeDateKey(swagger.longDateFormat, longDateValues));
    setTimeKey(buildTimeDateKey(swagger.timeFormat, timeValues));
    setTimeZones(swagger.timeZone);
  }, [swagger]);

  const generateListOptions = (optionsArray: string[]) => {
    return optionsArray.map((option: any, i): JSX.Element => {
      return (
        <option key={String(i)} accessKey={String(i)}>
          {option}
        </option>
      );
    });
  };

  useEffect(() => {
    if (!isLoading && account && subscription) {
      setShortDateFormat(
        shortDateKey[account.attributes.shortDateFormat || ""]
      );
      setLongDateFormat(longDateKey[account.attributes.longDateFormat || ""]);
      setTimeFormat(timeKey[account.attributes.timeFormat || ""]);
      setTimeZone(account.attributes.timeZone || "");
      setLoeUnits(
        splitPascalCase(snakeToPascalCase(account.attributes.rateType))
      );
      setLoeRounding(
        splitPascalCase(snakeToPascalCase(account.attributes.loeRounding))
      );
      setGuidedProjectCreation(
        account.attributes.settings &&
          account.attributes.settings["guided_project_creation"]
          ? account.attributes.settings["guided_project_creation"]
          : false
      );
      if (
        account &&
        account.attributes &&
        account.attributes.settings &&
        account.attributes.settings.watermarking !== undefined
      ) {
        setWatermarkedDocuments(account.attributes.settings.watermarking);
      } else {
        setWatermarkedDocuments(false);
      }
      setProjectResourceRates(
        account.attributes.settings &&
          account.attributes.settings["project_resource_rates"]
          ? account.attributes.settings["project_resource_rates"]
          : false
      );
      setAccountDataSet(true);

      setSurveysEnabled(
        !!account.attributes?.planDetails?.questionnaires ?? false
      );
      setApprovalWorkflowEnabled(
        !!account.attributes?.planDetails?.approval_workflow ?? false
      );
      setPartnerServicesEnabled(
        !!account.attributes?.planDetails?.partner_services ?? false
      );
      setCustomerSuccessEnabled(
        !!account.attributes?.planDetails?.customer_success ?? false
      );

      if (hasPrivilege("settings.account", ["manage"])) {
        setShowPlanSettings(true);
      }
    }
  }, [account, timeKey, shortDateKey, longDateKey, subscription]);

  useEffect(() => {
    if (itemChanged) {
      setItemChanged(false);

      const shortDate = getDateTimeFormat(shortDateKey, shortDateFormat);
      const longDate = getDateTimeFormat(longDateKey, longDateFormat);
      const time = getDateTimeFormat(timeKey, timeFormat);
      const effortUnits = toSnakeCase(loeUnits);
      const effortRounding = toSnakeCase(loeRounding);

      if (
        !account ||
        shortDate === null ||
        longDate === null ||
        time === null
      ) {
        return;
      }

      const updatedAccount: AccountInput = {
        slug: accountSlug,
        data: {
          id: account.id,
          type: account.type,
          attributes: {
            shortDateFormat: shortDate,
            longDateFormat: longDate,
            timeFormat: time,
            timeZone: timeZone,
            rateType: effortUnits,
            loeRounding: effortRounding,
            settings: {
              guided_project_creation: guidedProjectCreation,
              project_resource_rates: projectResourceRates,
              watermarking: watermarkedDocuments,
            },
            ...(showPlanSettings
              ? {
                  planDetails: {
                    questionnaires: surveysEnabled,
                    approval_workflow: approvalWorkflowEnabled,
                    partner_services: partnerServicesEnabled,
                    customer_success: customerSuccessEnabled,
                  } as any,
                }
              : {}),
          },
        },
      };

      handleUpdateAccount(updatedAccount);
    }
  }, [itemChanged]);

  const handleUpdateAccount = async (updatedAccount) => {
    await updateCompanyAccount(updatedAccount);

    let response = await refetch();

    if (error) {
      setErrorMessage(error.toString());
      setShowFailAlert(true);
    } else {
      setShowSuccessAlert(true);
    }
  };

  return (
    <>
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {accountDataSet ? (
        <div>
          <Row>
            <Col className="dateAndTime">
              <h4>Date & Time</h4>
              <p>
                Define how you wish date and time to appear throughout your
                document templates and within the app.
              </p>
              <div className="formFieldRow">
                <Row>
                  <Col>
                    <DropDownField
                      label="Short date format"
                      options={generateListOptions(Object.values(shortDateKey))}
                      value={shortDateFormat}
                      onChange={(e) => {
                        setShortDateFormat(e.target.value);
                        setItemChanged(true);
                      }}
                      required={false}
                    />
                  </Col>
                  <Col>
                    <DropDownField
                      label="Long date format"
                      options={generateListOptions(Object.values(longDateKey))}
                      value={longDateFormat}
                      onChange={(e) => {
                        setLongDateFormat(e.target.value);
                        setItemChanged(true);
                      }}
                      required={false}
                    />
                  </Col>
                  <Col>
                    <DropDownField
                      label="Time format"
                      options={generateListOptions(Object.values(timeKey))}
                      value={timeFormat}
                      onChange={(e) => {
                        setTimeFormat(e.target.value);
                        setItemChanged(true);
                      }}
                      required={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DropDownField
                      label="Time zone"
                      options={generateListOptions(timeZones)}
                      value={timeZone}
                      onChange={(e) => {
                        setTimeZone(e.target.value);
                        setItemChanged(true);
                      }}
                      required={false}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="levelOfEffort">
              <h4>Level of Effort</h4>
              <p>
                By default, we assume that level of effort values are in hours
                but you can adjust that to match your current process. You may
                also tailor the precision of calculated hours through level of
                effort rounding.
              </p>
              <Row className="formFieldRow">
                <Col>
                  <DropDownField
                    label="Level of effort units"
                    options={generateListOptions(["Hour", "Day", "Week"])}
                    value={loeUnits}
                    onChange={(e) => {
                      setLoeUnits(e.target.value);
                      setItemChanged(true);
                    }}
                    required={false}
                  />
                </Col>
                <Col>
                  <DropDownField
                    label="Level of effort rounding"
                    options={generateListOptions([
                      "One Minute",
                      "Five Minutes",
                      "Tenth of an hour",
                      "Ten Minutes",
                      "Quarter Hour",
                      "Half Hour",
                      "One Hour",
                    ])}
                    value={loeRounding}
                    onChange={(e) => {
                      setLoeRounding(e.target.value);
                      setItemChanged(true);
                    }}
                    required={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <div className="accountPreferences">
              <h4>Account-wide Preferences</h4>
              <Row>
                <Col>
                  <div>
                    <h5>Guided Project Creation</h5>
                    <p>
                      Use a multi-step guided project creation flow to set
                      critical data for a successful project
                    </p>
                  </div>
                  <ToggleOnOff
                    initialValue={guidedProjectCreation}
                    onToggle={(e) => {
                      setGuidedProjectCreation(e);
                      setItemChanged(true);
                    }}
                  />
                </Col>
                {subscription?.data.attributes.entitlements.find(
                  (item) => item.id == "watermarking"
                ) && (
                  <Col>
                    <div>
                      <h5>Watermarked Documents</h5>
                      <p>
                        Watermark documents such as statements of work or
                        managed service agreements prior to project approval
                      </p>
                    </div>
                    <ToggleOnOff
                      initialValue={watermarkedDocuments}
                      onToggle={(e) => {
                        setWatermarkedDocuments(e);
                        setItemChanged(true);
                      }}
                    />
                  </Col>
                )}
                <Col>
                  <div>
                    <h5>Project Resource from Resource Rates</h5>
                    <p>
                      When showing Standard Resources in a project dropdown,
                      require the list of resources to come from the Rate Table
                      configured for the project.
                    </p>
                  </div>
                  <ToggleOnOff
                    initialValue={projectResourceRates}
                    onToggle={(e) => {
                      setProjectResourceRates(e);
                      setItemChanged(true);
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Row>
          {showPlanSettings && (
            <Row>
              <div
                className="accountPreferences"
                style={{ marginTop: "16px", width: "100%" }}
              >
                <h4>Plan Settings</h4>
                <Row>
                  {hasEntitlement("recommendation-engine") && (
                    <Col>
                      <div>
                        <h5>Surveys</h5>
                      </div>
                      <ToggleOnOff
                        initialValue={surveysEnabled}
                        onToggle={(e) => {
                          setSurveysEnabled((prev) => !prev);
                          setItemChanged(true);
                        }}
                      />
                    </Col>
                  )}
                  {hasEntitlement("workflow-approval") && (
                    <Col>
                      <div>
                        <h5>Approval Workflow</h5>
                      </div>
                      <ToggleOnOff
                        initialValue={approvalWorkflowEnabled}
                        onToggle={(e) => {
                          setApprovalWorkflowEnabled((prev) => !prev);
                          setItemChanged(true);
                        }}
                      />
                    </Col>
                  )}
                  {hasEntitlement("scopestack-connect") && (
                    <Col>
                      <div>
                        <h5>Partner Services</h5>
                      </div>
                      <ToggleOnOff
                        initialValue={partnerServicesEnabled}
                        onToggle={(e) => {
                          setPartnerServicesEnabled((prev) => !prev);
                          setItemChanged(true);
                        }}
                      />
                    </Col>
                  )}
                  <Col>
                    <div>
                      <h5>Customer Success</h5>
                    </div>
                    <ToggleOnOff
                      initialValue={customerSuccessEnabled}
                      onToggle={(e) => {
                        setCustomerSuccessEnabled((prev) => !prev);
                        setItemChanged(true);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Row>
          )}
        </div>
      ) : (
        <SmallSpinner />
      )}
    </>
  );
};

export default Advanced;
