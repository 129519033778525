import {
  ListProjectExpensesApiArg,
  V1LineOfBusinessResource,
  useListProjectExpensesQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Expense, OptionType } from "../../types/Expense";
import { renderResourceName } from "@utils/helperFunctions";

export interface HookArgs {
  page?: ListProjectExpensesApiArg["page"];
  include?: ListProjectExpensesApiArg["include"];
  filter?: ListProjectExpensesApiArg["filter"];
  lobs?: V1LineOfBusinessResource[] | [];
}

const useListExpenses = ({ page, include, filter, lobs }: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } = useListProjectExpensesQuery({
    slug: accountSlug,
    page,
    include,
    filter,
  });

  let expenses: Expense[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    const projectResources = data?.included?.filter(
      (includedData) => includedData.type == "project-resources"
    );
    const resources = data?.included?.filter(
      (includedData) => includedData.type == "resources"
    );
    const phases = data?.included?.filter(
      (includedData) => includedData.type == "project-phases"
    );

    const expenseCategories = data?.included?.filter(
      (includedData) => includedData.type == "expense-categories"
    );

    const services = data?.included?.filter(
      (includedData) => includedData.type == "project-services"
    );

    try {
      expenses = data.data.map((expense) => {
        if (!expense.id) {
          throw new Error("Missing expense.id");
        }

        let resource: OptionType = {
          label: "Select Resource",
          value: 0,
        };
        let phase = {
          label: "Assign to Phase",
          value: 0,
        };
        let expenseCategory = "";
        let service: OptionType = { label: "Select Service", value: 0 };

        let foundProjectResource = (projectResources || []).find(
          (rr) =>
            expense?.relationships &&
            expense?.relationships["project-resource"] &&
            expense?.relationships?.["project-resource"].data?.id == rr.id
        );
        if (foundProjectResource && foundProjectResource.id) {
          resource = {
            label: renderResourceName(foundProjectResource, lobs),
            value: Number(foundProjectResource.id),
          };
        } else {
          let foundResource = (resources || []).find(
            (r) => expense?.relationships?.["resource"].data?.id == r.id
          );
          if (foundResource && foundResource.id) {
            resource = {
              label: renderResourceName(foundResource, lobs),
              value: Number(foundResource.id),
            };
          }
        }

        let foundPhase = (phases || []).find(
          (p) =>
            expense?.relationships &&
            expense?.relationships["project-phase"] &&
            expense?.relationships?.["project-phase"].data?.id == p.id
        );
        if (foundPhase && foundPhase.id) {
          phase = {
            label: foundPhase.attributes?.["name"],
            value: foundPhase.id,
          };
        }

        let foundExpenseCategory = (expenseCategories || []).find(
          (ec) => expense?.relationships?.["expense-category"].data?.id == ec.id
        );
        if (foundExpenseCategory) {
          expenseCategory = foundExpenseCategory?.attributes?.["name"];
        }

        let foundService = (services || []).find(
          (s) => expense?.relationships?.["project-service"]?.data?.id == s.id
        );
        if (foundService && foundService.id) {
          service = {
            label: foundService.attributes?.["name"],
            value: foundService.id,
          };
        }

        return {
          id: String(expense.id),
          name: `${expenseCategory}: ${expense?.attributes?.description || ""}`,
          description: expense?.attributes?.description || "",
          expenseCategory: expenseCategory || "",
          rate: expense?.attributes?.rate || 0,
          quantity: Number(expense?.attributes?.quantity) || 1,
          "deleted-at": expense?.attributes["deleted-at"] || "",
          resource: resource,
          phase: phase,
          service: service,
          unitCost: Number(expense?.attributes?.rate),
          totalCost:
            Number(expense?.attributes?.rate) *
            Number(expense?.attributes?.quantity),
          markup: Number(expense?.attributes?.["markup"]) || 0.0,
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    expenses: expenses,
    expensesError: error || customError,
    expensesLoading: isLoading,
    pageMeta,
    refetch,
  };
};

export default useListExpenses;
