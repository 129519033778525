import React, { useEffect } from "react";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import Changes from "./Changes";

function ChangesInVersion({
  versions,
  changesSince,
  changesIn,
  project_id,
  isLoading,
  account_slug,
  authorizationCode,
}) {
  useEffect(() => {}, [isLoading]);

  if (isLoading) {
    return (
      <div>
        <ScopeStackSpinner />
      </div>
    );
  } else {
    return (
      <Changes
        versions={versions}
        changesSince={changesSince}
        changesIn={changesIn}
        project_id={project_id}
        account_slug={account_slug}
        authorizationCode={authorizationCode}
      />
    );
  }
}

export default ChangesInVersion;
