export interface Privilege {
  privilege?: string;
  accessLevel?: string;
}

interface Attributes {
  uuid?: string;
  name: string;
  title: string | null;
  email: string;
  phone: string | null;
  accountId?: number;
  accountSlug?: string;
  privileges?: Privilege[];
}

interface Links {
  self: string;
}

export interface User {
  id: string;
  type: string;
  links?: Links;
  attributes: Attributes;
}

export enum Privileges {
  PROJECTS_OVERVIEW = "projects.overview",
  PROJECTS_PSA = "projects.psa",
  PROJECTS_TERMS_AND_CONDITIONS = "projects.terms_and_conditions",
  PROJECTS_NOTES = "projects.notes",
  PROJECTS_LOCATIONS = "projects.locations",
  PROJECTS_ATTACHMENTS = "projects.attachments",
  PROJECTS_CONTACTS = "projects.contacts",
  PROJECTS_COLLABORATORS = "projects.collaborators",
  PROJECTS_VERSIONS = "projects.versions",
  PROJECTS_OUTCOMES = "projects.outcomes",
  PROJECTS_SUCCESS_STRATEGY = "projects.success_strategy",
  PROJECTS_ADOPTION_BARRIERS = "projects.adoption_barriers",
  PROJECTS_PROJECT_PHASES = "projects.project_phases",
  PROJECTS_TASKS = "projects.tasks",
  PROJECTS_CUSTOM_TASKS = "projects.custom_tasks",
  PROJECTS_MANAGED_SERVICES = "projects.managed_services",
  PROJECTS_SERVICE_PRICING = "projects.service_pricing",
  PROJECTS_SOW_LANGUAGE = "projects.sow_language",
  PROJECTS_MATERIALS = "projects.materials",
  PROJECTS_PARTNER_REQUESTS = "projects.partner_requests",
  PROJECTS_VENDOR_QUOTES = "projects.vendor_quotes",
  PROJECTS_PROJECT_GOVERNANCE = "projects.project_governance",
  PROJECTS_TRAVEL_AND_EXPENSE = "projects.travel_and_expense",
  PROJECTS_PAYMENT_CREDITS = "projects.payment_credits",
  PROJECTS_PRICING = "projects.pricing",
  PROJECTS_SERVICE_MARGINS = "projects.service_margins",
  PROJECTS_RESOURCE_RATES = "projects.resource_rates",
  PROJECTS_RESOURCE_COSTS = "projects.resource_costs",
  PROJECTS_WORK_BREAKDOWN = "projects.work_breakdown",
  PROJECTS_RESOURCE_PLANNING = "projects.resource_planning",
  PROJECTS_SURVEYS = "projects.surveys",
  PROJECTS_MERGE_DATA = "projects.merge_data",
  SETTINGS_ACCOUNT = "settings.account",
  SETTINGS_CONNECTED_APPS = "settings.connected_apps",
  SETTINGS_OAUTH = "settings.oauth",
  SETTINGS_WEBHOOKS = "settings.webhooks",
  SETTINGS_ACCESS_TOKENS = "settings.access_tokens",
  SETTINGS_LINES_OF_BUSINESS = "settings.lines_of_business",
  SETTINGS_TASK_TYPES = "settings.task_types",
  SETTINGS_LANGUAGE_FIELDS = "settings.language_fields",
  SETTINGS_TASKS = "settings.tasks",
  SETTINGS_MANAGED_SERVICES = "settings.managed_services",
  SETTINGS_MATERIALS = "settings.materials",
  SETTINGS_BLUEPRINTS = "settings.blueprints",
  SETTINGS_TEXT_BLOCKS = "settings.text_blocks",
  SETTINGS_TERMS_AND_CONDITIONS = "settings.terms_and_conditions",
  SETTINGS_SIGNATURE_TYPES = "settings.signature_types",
  SETTINGS_SOW_TEMPLATES = "settings.sow_templates",
  SETTINGS_ATTACHMENT_TYPES = "settings.attachment_types",
  SETTINGS_QUESTIONNAIRES = "settings.questionnaires",
  SETTINGS_RESOURCES = "settings.resources",
  SETTINGS_TRAVEL_AND_EXPENSE = "settings.travel_and_expense",
  SETTINGS_PAYMENT_TERMS = "settings.payment_terms",
  SETTINGS_PAYMENT_CREDITS = "settings.payment_credits",
  SETTINGS_CURRENCIES = "settings.currencies",
  SETTINGS_PROJECT_GOVERNANCE = "settings.project_governance",
  SETTINGS_PROJECT_PHASES = "settings.project_phases",
  SETTINGS_PROJECT_VARIABLES = "settings.project_variables",
  SETTINGS_PROJECT_APPROVAL = "settings.project_approval",
  SETTINGS_USERS = "settings.users",
  SETTINGS_ROLES = "settings.roles",
  SETTINGS_TEAMS = "settings.teams",
  SETTINGS_BUSINESS_UNITS = "settings.business_units",
  SETTINGS_CLIENTS = "settings.clients",
  SETTINGS_PARTNERSHIPS = "settings.partnerships",
  SETTINGS_VENDORS = "settings.vendors",
  SETTINGS_BILLING = "settings.billing",
}

export enum AccessLevels {
  MANAGE = "manage",
  ADJUST_STANDARDS = "adjust_standards",
  VIEW = "view",
}

export interface DeleteUserInput {
  data: {
    type: string;
    id: string;
  };
}
