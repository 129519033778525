import React from "react"
import PropTypes from "prop-types"
import { Line } from 'react-chartjs-2'
import Widget from './Widget'
import {COLORS} from './config'
import {hexToRgba} from './utils'

const tooltipLabel = (item, data) => {
  let label = data.datasets[item.datasetIndex].label
  let value = new Intl.NumberFormat(dashboardLocale).format(item.yLabel)
  return `${label}: ${value}%`
}

class DailyActiveUsers extends Widget {
  componentDidMount() {
    fetch(this.props.dataURL)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json, loading: false }))
  }

  renderChart () {
    let { data } = this.state
    if (!data || data.length == 0) return;
    let chartData = {
      labels: Object.keys(Object.values(data)[0]),
      datasets: Object.entries(data).map((dataset, index) => {
        return {
          label: dataset[0],
          data: Object.values(dataset[1]),
          borderColor: COLORS[index],
          backgroundColor: hexToRgba(COLORS[index], 0.2),
          borderWidth: 0.1,
          pointBorderWidth: 2,
          pointRadius: 2,
          pointHoverRadius: 3
        }
      })
    }

    return(
      <Line
        data={chartData}
        height={100}
        options={{legend: {display: false}, tooltips: {callbacks:{ label: tooltipLabel },intersect: false, mode: 'index'}}}
      />)
  }

  renderBody () {
    return(
      <React.Fragment>
      {this.renderChart()}
      </React.Fragment>
    )
  }

  renderFooter () {
    return null
  }
}

export default DailyActiveUsers
