import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Product } from "../types";
import { ColumnDef } from "@tanstack/react-table";
import DataTable from "@components/DataTable";
import CurrencyFormat from "react-currency-format";
import {
  useCreateProjectProductMutation,
  V1ProjectProductResource,
} from "@generated";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useNavigate } from "react-router";

function Add({
  products,
  selectedProductIds,
  setSelectedProductIds,
  setOnAddPage,
  currencyUnit,
  projectId,
}) {
  const navigate = useNavigate();
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>(products);
  const [createProjectProduct, {}] = useCreateProjectProductMutation();

  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    document.getElementById("selectedProduct0")?.focus();
  }, []);

  const productsTableColumns: ColumnDef<Product>[] = [
    {
      header: "* Qty",
      cell(props) {
        return (
          <Form.Control
            id={"selectedProduct" + props.row.index.toString()}
            readOnly={false}
            required={true}
            defaultValue={
              props.row.original.quantity ? props.row.original.quantity : 1
            }
            onChange={(e) => props.row.original.quantity = Number(e.target.value)}
            onBlur={(e) => {
              setSelectedProducts(
                selectedProducts.map((prod) => {
                  if (String(prod.id) === String(props.row.original.id)) {
                    prod = { ...prod, quantity: Number(e.target.value) };
                  }
                  return prod;
                })
              );
            }}
            type="number"
            min={1}
            step={1}
          />
        );
      },
    },
    {
      header: "Name",
      cell(props) {
        return (
          <div className="displayFlex">{props.row.original.name}&nbsp;</div>
        );
      },
    },
    {
      header: "MFR Part",
      cell(props) {
        return <>{props.row.original.mfrPartNumber}</>;
      },
    },
    {
      header: "SKU",
      cell(props) {
        return <>{props.row.original.sku}</>;
      },
    },
    {
      header: "Int Prod Id",
      cell(props) {
        return <>{props.row.original.productId}</>;
      },
    },
    {
      header: "Units",
      cell(props) {
        return <>{props.row.original.units}</>;
      },
    },
    {
      header: "Category",
      cell(props) {
        return <>{props.row.original.category}</>;
      },
    },
    {
      header: "Unit Price",
      cell(props) {
        return (
          <CurrencyFormat
            displayType="text"
            prefix={currencyUnit}
            isNumericString={true}
            thousandSeparator={true}
            value={(props.row.original.unitPrice
              ? Number(props.row.original.unitPrice)
              : 0
            ).toFixed(2)}
          />
        );
      },
    },
  ];

  const handleSaveProducts = (e) => {
    e.preventDefault();

    selectedProducts.forEach((product, i) => {
      let data: V1ProjectProductResource = {
        type: "project-products",
        attributes: {
          name: product.name,
          source: product.source,
          quantity: product.quantity ? product.quantity : 1,
        },
        relationships: {
          project: { data: { id: Number(projectId), type: "projects" } },
        },
      };

      if (product.type !== "products") {
        // If not already a standard product, pass info from integrated product to api
        data.attributes.custom = true;
        data.attributes.description = product.description;
        data.attributes.category = product.category;
        data.attributes["manufacturer-part-number"] = product.mfrPartNumber;
        data.attributes.markup = parseFloat(product?.markup || "") || 0;
        data.attributes["product-id"] = product.productId;
        data.attributes.sku = product.sku;
        data.attributes.subcategory = product.subcategory;
        data.attributes["unit-cost"] = parseFloat(product?.unitCost || "") || 0;
        data.attributes["unit-of-measure"] = product.units;
        data.attributes["unit-price"] = parseFloat(product?.price || "") || 0;
        data.attributes["vendor-discount"] =
          parseFloat(product?.vendorDiscount || "") || 0;
        data.attributes["billing-frequency"] = product?.billingFrequency as any || undefined;
      } else {
        data.relationships.product = {
          data: { id: Number(product.id), type: "products" },
        };
      }

      createProjectProduct({ slug: accountSlug, body: { data } })
        .unwrap()
        .then((response) => {
          if (i === selectedProducts.length - 1 && response.data) {
            navigate(`/projects/${projectId}/project_materials`, {
              state: { productsCreated: selectedProducts.length },
            });
          }
        })
        .catch((error) => {
          setErrorMessage("Something went wrong, please try again.");
          setShowFailAlert(true);
        });
    });
  };
  return (
    <div className="addProducts reviewProducts">
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <div className="addProductsCard">
        <div className="addProductsHeader">
          <div className="displayFlex">
            <Button
              className={"ssButtonBgWhite"}
              onClick={(e) => {
                setOnAddPage(false);
              }}
            >
              Back
            </Button>
            <h2>Review Selected Products</h2>
          </div>
          <Button
            className={`actionButton ${
              selectedProductIds.length === 0 ? "disabled" : ""
            }`}
            onClick={handleSaveProducts}
          >
            Add&nbsp;
            <span>
              {selectedProductIds.length > 0 &&
                `(${selectedProductIds.length})`}
              &nbsp;
            </span>
            Products
          </Button>
        </div>
        <div className="instructionalText">
          * All Products must have a quantity to continue.
        </div>
        <DataTable
          data={
            selectedProducts?.length > 0
              ? selectedProducts
              : [
                  {
                    id: 0,
                    isFavorited: false,
                    source: "standard",
                    name: "No products found",
                    mfrPartNumber: "",
                    productId: "",
                    units: "",
                    price: "",
                  },
                ]
          }
          columns={productsTableColumns}
          selectable={false}
          bordered
          striped
          hover
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          selectedIds={selectedProductIds}
          setSelectedIds={setSelectedProductIds}
          paginationEnabled={false}
          pageSizeEnabled
        />
        <div className="text24 text-right">
          Subtotal:&nbsp;
          <CurrencyFormat
            displayType="text"
            prefix={currencyUnit}
            isNumericString={true}
            thousandSeparator={true}
            value={selectedProducts
              .map(
                (product) =>
                  Number(product.unitPrice ? product.unitPrice : 0) *
                  Number(product.quantity ? product.quantity : 1)
              )
              .reduce(function (accumulator, currentValue) {
                return accumulator + currentValue;
              }, 0)
              .toFixed(2)}
          />
        </div>
      </div>
    </div>
  );
}

export default Add;
