import React from "react";
import { Form } from 'react-bootstrap';
import FormFieldLabel from "./FormFieldLabel/FormFieldLabel";

function DisabledTextField({label, placeholder, value, onChange, children}) {
  return (
      <Form.Group className="formGroup">
        <FormFieldLabel label={label} />
        <Form.Control disabled  value={value === null ? "" : value} placeholder={placeholder} onChange={onChange} type="text"/>
        {children}
      </Form.Group>
  );
}

export default DisabledTextField;