import { useListQuestionnairesForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { PreviewTableItem } from "../types";
interface Params {
  serviceId: string;
  currentPage: number;
}

const useListQuestionnairesForService = ({
  serviceId,
  currentPage,
}: Params) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError

  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListQuestionnairesForAccountQuery({
      slug: accountSlug,
      filter: { "service-id": serviceId },
      page: {
        number: currentPage,
        size: 5,
      },
    });

  let questionnaires: PreviewTableItem[] = [];

  if (data?.data) {
    try {
      questionnaires = data.data.map((questionnaire, i) => {
        if (!questionnaire.id) {
          throw new Error("Missing questionnaire.id");
        }
        if (!questionnaire.attributes?.name) {
          throw new Error("Missing questionnaire.attributes.name");
        }

        return {
          id: String(questionnaire.id),
          name: questionnaire.attributes.name,
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    questionnaires,
    questionnairesLoading: isLoading,
    questionnairesError: error ?? customError,
    questionnairesPageMeta: pageMeta,
    questionnairesRefetch: refetch,
    questionnairesFetching: isFetching,
  };
};
export default useListQuestionnairesForService;
