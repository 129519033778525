import React, { useState, useRef } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import QuoteForm from "../common/QuoteForm/QuoteForm";

function New({ project_id, account, permission, project, revenueCalculationLabel }) {
  const navigate = useNavigate();
  const submitRef = useRef<any>();

  return (
    <div className="newQuotePage">
      <Card>
        <Card.Body>
          <Card.Header className="cardHeaderOverride">
            <div className="headerContainer">
              <div className="actionBtns">
                <Button
                  style={{ marginRight: "20px" }}
                  className="btnSeawhite"
                  onClick={(e) =>
                    navigate(`/projects/${project_id}/quotes`, {
                      state: null,
                    })
                  }
                >
                  Cancel
                </Button>
                <div>
                  <span className="text24">Add Vendor Quote</span>
                </div>
              </div>
              <div className="actionBtns">
                {permission !== "view" &&
                project?.data?.attributes?.status === "building" ? (
                  <Button
                    style={{ width: "200px", marginRight: "10px" }}
                    className="squareGreenButton"
                    onClick={() => {
                      submitRef.current?.saveQuote(true);
                    }}
                  >
                    Save and Add Another
                  </Button>
                ) : null}
                {permission !== "view" &&
                project?.data?.attributes?.status === "building" ? (
                  <Button
                    className="squareGreenButton"
                    onClick={() => {
                      submitRef.current?.saveQuote(false);
                    }}
                  >
                    Save
                  </Button>
                ) : null}
              </div>
            </div>
          </Card.Header>
          <QuoteForm
            project_id={project_id}
            account={account}
            ref={submitRef}
            permission={permission}
            project={project}
            authorizationCode={null}
            revenueCalculationLabel={revenueCalculationLabel}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default New;
