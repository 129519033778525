import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import SeafoamButton from "../Buttons/SeafoamButton/SeafoamButton";
import "./SlideOutMenuSectioned.css";

function SlideOutMenuFilter({
  show,
  setShow,
  menuHeader,
  actionButtonText,
  onActionButtonClick,
  clearFields,
  onClearFieldsClick,
  onCancel,
  sectionsArray,
  showDivider = true,
}) {
  const handleClose = () => setShow(false);

  // Stops scroll of the body if the slide-out menu is open
  useEffect(() => {
    if (show) {
      let screenOverlay = document.createElement("div");
      document.body.setAttribute("style", "overflow: hidden");
      screenOverlay.classList.add("screenOverlay");
      let nav = document.querySelector(".navbar");
      let parentNode = nav.parentNode;
      parentNode.insertBefore(screenOverlay, nav);
    } else if (show === false) {
      document.body.removeAttribute("style");
      let screenOverlay = document.querySelector(".screenOverlay");
      if (screenOverlay) {
        screenOverlay.remove();
      }
    }
  }, [show]);

  return (
    <div className={show ? "slideOutMenu show" : "slideOutMenu hide"}>
      <div className="slideOutHeader">
        <h3>{menuHeader}</h3>
        <div className="ctas">
          <Button
            onClick={() => {
              onCancel();
              handleClose();
            }}
            variant="secondary"
            className="cancelBtn"
            id="cancelBtn"
          >
            {actionButtonText ? "Cancel" : "Close"}
          </Button>
          {actionButtonText && (
            <SeafoamButton
              onClick={onActionButtonClick}
              className="actionBtn"
              text={actionButtonText}
            />
          )}
        </div>
      </div>
      {clearFields ? (
        <div onClick={onClearFieldsClick} className="clearFields">
          {clearFields}
        </div>
      ) : null}
      {sectionsArray.map((section, index) => {
        return (
          <div className={section.containerClass} key={index}>
            {showDivider && <hr className="divider" />}
            <div className={section.sectionClass}>
              {section.sectionTitle && section.sectionTitle.length > 0 && (
                <div className="slideOutSectionTitle">
                  {section.sectionTitle}
                </div>
              )}
              <div>{section.sectionContent}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SlideOutMenuFilter;
