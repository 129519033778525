import { renderResourceName } from "@utils/helperFunctions";

export const getTotalRow = (array, priceAdjustment) => {
  let totalRow = {
    type: "totalRow",
    label: "TOTAL",
    totalHours: array
      .map((item) => parseFloat(item.hours))
      .reduce((a, b) => a + b, 0),
    totalRevenue:
      array.map((item) => parseFloat(item.revenue)).reduce((a, b) => a + b, 0) +
      parseFloat(priceAdjustment),
    totalCost: array
      .map((item) => parseFloat(item.totalCost))
      .reduce((a, b) => a + b, 0),
    totalGrossProfit:
      array
        .map((item) => parseFloat(item.grossProfit))
        .reduce((a, b) => a + b, 0) + parseFloat(priceAdjustment),
  };
  return totalRow;
};

export const getServiceTotalRow = (array, label, type) => {
  let totalRow = {
    type: type,
    label: label,
    totalHours: array
      .map((item) => parseFloat(item.totalHours))
      .reduce((a, b) => a + b, 0),
    totalRevenue: array
      .map((item) => parseFloat(item.totalRevenue))
      .reduce((a, b) => a + b, 0),
    totalCost: array
      .map((item) => parseFloat(item.totalCost))
      .reduce((a, b) => a + b, 0),
    totalGrossProfit: array
      .map((item) => parseFloat(item.totalGrossProfit))
      .reduce((a, b) => a + b, 0),
  };
  return totalRow;
};

export const arrangeDataForTable = (array, resources, lobs) => {
  return resources.map((resource, index) => {
    let servicesByResource = [];

    // Match up resources with the resource id listed on the resource/service
    array.forEach((service) => {
      if (
        service.attributes["resource-id"] == resource.id &&
        service.attributes["resource-type"] == resource.type
      ) {
        servicesByResource.push(service);
      }
    });

    let hours = servicesByResource
      .map((service) => parseFloat(service.attributes["extended-hours"]))
      .reduce((a, b) => a + b, 0);

    let revenue = servicesByResource
      .map((service) => parseFloat(service.attributes["contract-revenue"]))
      .reduce((a, b) => a + b, 0);

    let cost = servicesByResource
      .map((service) => parseFloat(service.attributes["contract-cost"]))
      .reduce((a, b) => a + b, 0);

    let effectiveCost = parseFloat(cost) / parseFloat(hours);

    let rate = parseFloat(revenue) / parseFloat(hours);

    let totalCost = cost;
    let grossProfit = parseFloat(revenue) - parseFloat(cost);

    return {
      resource: renderResourceName(resource, lobs),
      hours: hours,
      rate: rate,
      revenue: revenue,
      effectiveCost: effectiveCost,
      totalCost: totalCost,
      grossProfit: grossProfit,
    };
  });
};
