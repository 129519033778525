import React, { useState, FC } from "react";
import "./style.css";

interface GenericToggleProps {
  initialValueOfIsOn: boolean;
  onToggle: (newValue: boolean) => void;
  onText: string;
  offText: string;
  hasText: boolean;
}

const GenericToggle: FC<GenericToggleProps> = ({
  initialValueOfIsOn,
  onToggle,
  onText = "Enable",
  offText = "Disable",
  hasText = true,
}) => {
  const [isOn, setIsOn] = useState(initialValueOfIsOn);

  const handleToggle = () => {
    const newValue = !isOn;
    setIsOn(newValue);
    if (onToggle) {
      onToggle(newValue);
    }
  };

  return (
    <div className="switchContainer">
      <label className="switch">
        <input type="checkbox" checked={isOn} onChange={() => null} />
        <span onClick={handleToggle} className="slider round"></span>
      </label>
      {hasText ? (
        <span className="spaceLeft">{isOn ? onText : offText}</span>
      ) : null}
    </div>
  );
};

export default GenericToggle;
