import React, { useState, useEffect } from "react";
import "../NewProjectDetails/NewProjectDetails.css";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import {
  splitPascalCase,
  toSnakeCase,
  snakeToPascalCase,
} from "@utils/helperFunctions";
import API from "@API";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import FormDateField from "@components/Forms/FormDateField";
import FormNumberField from "@components/Forms/FormNumberField";

function Pricing({
  account_slug,
  account_id,
  authorizationCode,
  projectId,
  patchProjectUrl,
  setCurrentPage,
  setPageReturn,
  pageReturn,
  projectServices,
  handleSavedTermsChange,
  savedTerms,
}) {
  // Env/ API
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const continueProjectCreationWorkFlow = () => {
    setCurrentPage(5);
  };

  //Payment Terms Form Field States
  const [paymentTermsAccordianIsOpen, setPaymentTermsAccordianIsOpen] =
    useState(false);
  const [paymentTermsSubmitted, setPaymentTermsSubmitted] = useState(false);
  const [paymentTermsList, setPaymentTermsList] = useState([]);
  const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [paymentTermsName, setPaymentTermsName] = useState(null);
  const [paymentTermsId, setPaymentTermsId] = useState(null);
  const [savedPaymentTermsId, setSavedPaymentTermsId] = useState(null);

  const [paymentTermsLength, setPaymentTermsLength] = useState(0);
  const [paymentTermsDate, setPaymentTermsDate] = useState("");
  const [paymentTermsFrequency, setPaymentTermsFrequency] = useState("monthly");
  const [paymentTermsIncludeRevenue, setPaymentTermsIncludeRevenue] = useState(
    "do_not_include_ps_revenue"
  );
  const [paymentTermsSchedule, setPaymentTermsSchedule] = useState([]);
  const [savedPaymentTerms, setSavedPaymentTerms] = useState(null);
  const [savedManagedTerms, setSavedManagedTerms] = useState(null);
  const [userChanges, setUserChanges] = useState(false);
  const [termsSaved, setTermsSaved] = useState(savedTerms ? savedTerms : false);

  const professionalServices = projectServices.some(
    (service) => service.attributes["service-type"] === "professional_services"
  );
  const managedServices = projectServices.some(
    (service) => service.attributes["service-type"] === "managed_services"
  );

  useEffect(() => {
    handleSavedTermsChange(termsSaved);
    if (savedTerms) {
      setPaymentTermsSubmitted(true);
    }
  }, [termsSaved]);

  if (authorizationCode !== "" && paymentTermsList.length === 0) {
    // Get Payment Terms
    API.Get(
      `${apiHost}/${account_slug}/v1/payment-terms?include=terms`,
      authorizationCode
    ).then((response) => {
      let paymentTermsList = response.data.data;
      let options = response.data;
      if (paymentTermsList.length !== 0) {
        setPaymentTermsOptions(options);
        setPaymentTermsList(
          paymentTermsList.map((paymentTerm) => {
            return (
              <option
                className="paymentTermOptions"
                accessKey={paymentTerm.id}
                key={paymentTerm.id}
              >
                {paymentTerm.attributes.name}
              </option>
            );
          })
        );
        setPaymentTermsId(paymentTermsList[0].id);
        setPaymentTerms(paymentTermsList[0]);
        setPaymentTermsSchedule(getSchedule(paymentTermsList[0], options));
        setPaymentTermsName(paymentTermsList[0].attributes.name);

        if (pageReturn && termsSaved) {
          API.Get(
            `${apiHost}/${account_slug}/v1/projects/${projectId}?include=project-phases`,
            authorizationCode
          ).then((response) => {
            const projectData = response.data.data;
            // Get current payment term from project
            API.Get(
              `${projectData.relationships["payment-term"].links.related}?include=terms`,
              authorizationCode
            ).then((response) => {
              let term = response.data.data;
              setPaymentTerms(term);
              setPaymentTermsName(term.attributes.name);
              setPaymentTermsId(term.id);
              setPaymentTermsSchedule(getSchedule(term, options));
              setPaymentTermsLength(projectData.attributes["mrr-terms"]);
              setPaymentTermsDate(projectData.attributes["service-start-date"]);
              setPaymentTermsFrequency(
                projectData.attributes["recurring-billing-frequency"]
              );
              setPaymentTermsIncludeRevenue(
                projectData.attributes["include-ps-revenue-in-mrr"]
              );
              setSavedManagedTerms({
                contractLength: projectData.attributes["mrr-terms"],
                date: projectData.attributes["service-start-date"],
                frequency:
                  projectData.attributes["recurring-billing-frequency"],
                includeRevenue:
                  projectData.attributes["include-ps-revenue-in-mrr"],
              });
              setSavedPaymentTerms(term);
              setPaymentTermsSubmitted(true);
              closePaymentTermsAccordian();
            });
          });
        } else {
          setPaymentTermsId(paymentTermsList[0].id);
          setPaymentTerms(paymentTermsList[0]);
          setPaymentTermsSchedule(getSchedule(paymentTermsList[0], options));
          setPaymentTermsName(paymentTermsList[0].attributes.name);
          setPaymentTermsLength(0);
          setPaymentTermsFrequency("monthly");
          setPaymentTermsIncludeRevenue("do_not_include_ps_revenue");
        }
      }
    });
  }

  const getSchedule = (terms, options) => {
    let ids = {};
    let schedule = [];
    terms.relationships.terms.data.forEach((term) => (ids[term.id] = true));
    let items = options
      ? options.included.filter((term) => ids[term.id] && term.type === "terms")
      : paymentTermsOptions.included.filter(
          (term) => ids[term.id] && term.type === "terms"
        );
    items.forEach((item) =>
      schedule.push({
        type: item.attributes["percentage-due"]
          ? `${item.attributes["percentage-due"]}%`
          : snakeToPascalCase(item.attributes["term-type"]),
        description: item.attributes.description,
      })
    );
    return schedule;
  };

  const updatePaymentTermsDetails = (valueToUpdate) => {
    setTermsSaved(true);

    let updatedData = {
      data: {
        type: "projects",
        id: projectId,
        attributes: {
          "recurring-billing-frequency": paymentTermsFrequency,
          "include-ps-revenue-in-mrr": paymentTermsIncludeRevenue,
        },
        relationships: {
          account: { data: { type: "accounts", id: account_id } },
          "payment-term": {
            data: { type: "payment-terms", id: paymentTermsId },
          },
        },
      },
    };
    if (valueToUpdate.attribute !== "payment-term") {
      if (valueToUpdate.attribute === "recurring-billing-frequency") {
        updatedData.data.attributes["recurring-billing-frequency"] =
          valueToUpdate.value;
      }
      if (valueToUpdate.attribute === "include-ps-revenue-in-mrr") {
        updatedData.data.attributes["include-ps-revenue-in-mrr"] =
          valueToUpdate.value;
      }
      if (
        valueToUpdate.attribute !== "recurring-billing-frequency" &&
        valueToUpdate.attribute !== "include-ps-revenue-in-mrr"
      ) {
        updatedData.data.attributes = {
          ...updatedData.data.attributes,
          [valueToUpdate.attribute]: valueToUpdate.value,
        };
      }
    } else {
      updatedData.data.relationships = {
        ...updatedData.data.relationships,
        [valueToUpdate.attribute]: valueToUpdate.value,
      };
    }
    if (managedServices && !professionalServices) {
      delete updatedData.data.relationships;
    }
    if (professionalServices && !managedServices) {
      delete updatedData.data.attributes;
    }

    API.Patch(patchProjectUrl, updatedData, authorizationCode).then(
      (response) => {
        if (response.status == 200) {
          let data = response.data.data;
          setSavedPaymentTermsId(paymentTermsId);
          setSavedPaymentTerms(
            paymentTermsOptions.data.filter(
              (terms) => terms.id === paymentTermsId
            )[0]
          );
          setSavedManagedTerms({
            contractLength: data.attributes["mrr-terms"],
            date: data.attributes["service-start-date"],
            frequency: data.attributes["recurring-billing-frequency"],
            includeRevenue: data.attributes["include-ps-revenue-in-mrr"],
          });
          setPaymentTermsSubmitted(true);
          setUserChanges(false);
        }
      }
    );
  };

  const closePaymentTermsAccordian = () =>
    document
      .querySelector(".details .paymentTermsAccordian #openCloseToggle")
      .click();

  const learnAbout = (url) => {
    return (
      <span
        style={{ color: "#418172", cursor: "pointer" }}
        onClick={() => window.open(url)}
      >
        <strong>Learn about Payment Terms</strong>
      </span>
    );
  };

  const handleTypeChange = (event) => {
    let selected = event.target.options.selectedIndex;
    let term = paymentTermsOptions.data.filter(
      (option) => option.attributes.name === event.target.value
    )[0];
    const newPaymentTermId = event.target.options[selected].accessKey;
    setPaymentTerms(term);
    setPaymentTermsName(term.attributes.name);
    setPaymentTermsId(newPaymentTermId);
    setPaymentTermsSchedule(getSchedule(term));
    setUserChanges(true);
    const valueToUpdate = {
      attribute: "payment-term",
      value: {
        data: { id: newPaymentTermId, type: "payment-terms" },
      },
    };
    updatePaymentTermsDetails(valueToUpdate);
  };

  const handleLengthChange = (event) => {
    const valueToUpdate = { attribute: "mrr-terms", value: event.target.value };
    setPaymentTermsLength(valueToUpdate.value);
    setUserChanges(true);
    updatePaymentTermsDetails(valueToUpdate);
  };
  const handleDateChange = (event) => {
    const valueToUpdate = {
      attribute: "service-start-date",
      value: event.target.value,
    };
    setPaymentTermsDate(valueToUpdate.value);
    setUserChanges(true);
    updatePaymentTermsDetails(valueToUpdate);
  };
  const handleFrequencyChange = (event) => {
    const valueToUpdate = {
      attribute: "recurring-billing-frequency",
      value: event.target.value,
    };
    setPaymentTermsFrequency(valueToUpdate.value);
    setUserChanges(true);
    updatePaymentTermsDetails(valueToUpdate);
  };
  const handleIncludeRevenueChange = (event) => {
    const newIncludeRevenueValue = event.target.value;
    const valueToUpdate = {
      attribute: "include-ps-revenue-in-mrr",
      value: newIncludeRevenueValue,
    };
    setPaymentTermsIncludeRevenue(valueToUpdate.value);
    setUserChanges(true);
    updatePaymentTermsDetails(valueToUpdate);
  };

  const paymentTermsBody = (
    <>
      {professionalServices ? (
        <>
          <div style={{ marginBottom: "15px", fontSize: "15px" }}>
            <strong>Professional Services Terms. </strong>
            <span>
              Select payment terms to collect a one-time fee for Professional
              Services.
            </span>
          </div>
          <Row style={{ width: "100%", fontSize: "14px" }}>
            <Col>
              <ControlledDropDown
                label="Select Professional Services Payment Terms"
                value={paymentTermsName}
                options={paymentTermsList}
                onChange={handleTypeChange}
                required={true}
              />
            </Col>
          </Row>
          {paymentTerms ? (
            <div
              style={{
                background: "#f2f2f2",
                borderRadius: "5px",
                padding: "20px",
                margin: "0px 30px 25px 0px",
                fontSize: "15px",
              }}
            >
              {paymentTerms.attributes["pricing-model"] ? (
                <>
                  <strong>Pricing Model: </strong>
                  <span>
                    {splitPascalCase(
                      snakeToPascalCase(
                        paymentTerms.attributes["pricing-model"]
                      )
                    ) + " "}
                  </span>
                </>
              ) : null}

              {paymentTerms.attributes["include-materials"] ||
              paymentTerms.attributes["include-expense-in-revenue"] ||
              paymentTerms.attributes["include-expense-in-cost"] ? (
                <>
                  <strong style={{ marginLeft: "25px" }}>Includes: </strong>
                  <span>
                    {paymentTerms !== undefined ? (
                      <>
                        {paymentTerms.attributes["include-materials"] ? (
                          <span>Materials</span>
                        ) : null}
                        {paymentTerms.attributes["include-materials"] &&
                        (paymentTerms.attributes[
                          "include-expense-in-revenue"
                        ] ||
                          paymentTerms.attributes[
                            "include-expense-in-cost"
                          ]) ? (
                          <span>, </span>
                        ) : null}
                        {paymentTerms.attributes[
                          "include-expense-in-revenue"
                        ] ||
                        paymentTerms.attributes["include-expense-in-cost"] ? (
                          <span>Travel & Expense</span>
                        ) : null}
                      </>
                    ) : null}
                  </span>
                </>
              ) : null}
              {paymentTermsSchedule[0] ? (
                <div style={{ marginTop: "15px" }}>
                  <strong>Schedule: </strong>
                  <span>
                    {paymentTermsSchedule.map((item, index) => (
                      <span key={index}>{`${item.description} (${item.type})${
                        paymentTermsSchedule[index + 1] ? ", " : ""
                      }`}</span>
                    ))}
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}
          {managedServices ? <br /> : null}
        </>
      ) : null}
      {managedServices ? (
        <>
          <div style={{ margin: "0px 0px 15px 0px", fontSize: "15px" }}>
            <strong>Managed Services Terms. </strong>
            <span>
              Select payment terms to collect recurring revenue for Managed
              Services.
            </span>
          </div>
          <Row
            style={{ width: "100%", fontSize: "14px", marginBottom: "10px" }}
          >
            <Col lg={3} xl={3}>
              <FormNumberField
                onChange={handleLengthChange}
                value={paymentTermsLength}
                label="Length of Contract (Months) *"
                step="1"
                min="1"
                max="99999"
                required={true}
              />
            </Col>
            <Col lg={2} xl={2}>
              <FormDateField
                label="Contract Start Date *"
                value={paymentTermsDate}
                onChange={handleDateChange}
                required={true}
              />
            </Col>
            <Col lg={2} xl={2}>
              <ControlledDropDown
                label="Billing Frequency"
                value={paymentTermsFrequency}
                options={[
                  <option value="monthly" key="monthly">
                    Monthly
                  </option>,
                  <option value="quarterly" key="quarterly">
                    Quarterly
                  </option>,
                  <option value="yearly" key="yearly">
                    Yearly
                  </option>,
                ]}
                onChange={handleFrequencyChange}
                required={true}
              />
            </Col>
            <Col lg={5} xl={5}>
              <ControlledDropDown
                label="Include Professional Services Revenue in Recurring Payments?"
                value={paymentTermsIncludeRevenue}
                options={[
                  <option value="do_not_include_ps_revenue" key="no">
                    No
                  </option>,
                  <option value="add_to_first_month" key="first-month">
                    Add To First Month
                  </option>,
                  <option value="amortize_over_all_months" key="amortize">
                    Amortize Over All Months
                  </option>,
                ]}
                onChange={handleIncludeRevenueChange}
                required={true}
              />
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );

  const backToServiceDescriptions = () => {
    setCurrentPage(3);
    setPageReturn(true);
  };

  return (
    <>
      <div className="details">
        <Row>
          <Col>
            <Form>
              <Card>
                <Card.Body>
                  <Card.Header className="cardHeaderOverride">
                    <div className="headerContainer">
                      <div className="actionBtns">
                        <Button
                          style={{ marginRight: "20px" }}
                          className="btnSeawhite"
                          type="button"
                          onClick={() => backToServiceDescriptions()}
                          disabled={false}
                        >
                          Back
                        </Button>
                        <h2>
                          <span className="text24">Set Payment Terms</span>
                        </h2>
                      </div>
                      <div className="actionBtns">
                        <Button
                          className="btnSeafoam"
                          type="button"
                          onClick={() => {
                            if (professionalServices && !userChanges) {
                              updatePaymentTermsDetails({
                                attribute: "payment-term",
                                value: {
                                  data: {
                                    id: paymentTermsId,
                                    type: "payment-terms",
                                  },
                                },
                              });
                            }
                            continueProjectCreationWorkFlow();
                          }}
                          disabled={
                            managedServices &&
                            (paymentTermsLength === 0 ||
                              paymentTermsDate === "")
                          }
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </Card.Header>
                  <Row>
                    <Col>
                      <div className="cardSubtitle">
                        Assign payment terms to this project for services
                        provided.{" "}
                        {learnAbout(
                          `https://support.scopestack.io/help/payment-terms-for-professional-services`
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div style={{ marginLeft: "16px" }}>{paymentTermsBody}</div>
                </Card.Body>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Pricing;
