import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import CardHeader from "@components/CardHeader/CardHeader";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import RouterButton from "@components/Buttons/RouterButton/RouterButton";
import { useNavigate } from "react-router-dom";
import { getDate, formatUnprocessibleResponse } from "@utils/helperFunctions";

function ProjectOutcomes({
  account_slug,
  project_id,
  projectStatus,
  outcomes,
  statuses,
  indicators,
  indicatorId,
  setIndicatorId,
  indicatorCreated,
  indicatorUpdated,
  outcomeId,
  account_id,
  outcomeCreated,
  outcomeUpdated,
  setOutcomeId,
  setCurrentStatus,
  isLoading,
  authorizationCode,
}) {
  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");

  useEffect(() => {
    if (outcomeCreated === true) {
      setCreatedAlertIsOpen(true);
    }
    if (outcomeUpdated === true) {
      setUpdatedAlertIsOpen(true);
    }
    if (indicatorCreated === true) {
      setCreatedAlertIsOpen(true);
    }
    if (indicatorUpdated === true) {
      setUpdatedAlertIsOpen(true);
    }
  }, [outcomes, indicators, isLoading]);

  const getTableData = (arr) => {
    if (arr.length === 0) {
      return [];
    }
    // Map over arr & return data for ScopeStackTable
    return arr.map((item) => {
      let itemId = item.id;
      let type = item.type;
      let initialStatus = "";
      if (type === "performance-indicators") {
        for (let i = 0; i < statuses.length; i++) {
          if (item.attributes.status === statuses[i].id) {
            initialStatus = statuses[i].attributes.name;
          }
        }
      }
      const deleteItem = (e) => {
        e.stopPropagation();
        let userConfirms = confirm(
          "Are you sure you want to delete this item?"
        );
        if (userConfirms) {
          const item = {
            data: {
              type: type,
              id: itemId,
            },
          };
          API.Delete(
            `${apiHost}/${account_slug}/v1/${type}/${itemId}`,
            item,
            authorizationCode
          )
            .then((response) => {
              if (response.status == 204) {
                window.location.reload();
              }
            })
            .catch((err) => {
              setErrorMessages(formatUnprocessibleResponse(err, "outcome"));
              setShowFailAlert(true);
            });
        }
      };

      const redirectToAddIndicator = (e) => {
        e.stopPropagation();
        navigate(
          `/projects/${project_id}/customer_successes/${itemId}/performance_indicators/new`
        );
      };

      // Handle dates
      const options = { month: "short", day: "numeric", year: "numeric" };
      let due = "";
      item.attributes["due-on"] !== null
        ? (due = getDate(item.attributes["due-on"], options))
        : (due = "");
      let completed = "";
      item.attributes["completed-on"] !== null &&
      item.attributes.status === "completed"
        ? (completed = getDate(item.attributes["completed-on"], options))
        : (completed = "");
      let startedOn = "";
      item.attributes["started-on"] !== null
        ? (startedOn = getDate(item.attributes["started-on"], options))
        : (startedOn = null);

      // Render icon buttons under actions on hover if the project status is: building
      const renderActions = () => {
        if (projectStatus === "building") {
          if (type === "customer-successes") {
            return (
              <div style={{ display: "flex" }}>
                <div>
                  <OnHoverButtonIcon
                    className="buttonKPI"
                    iconClass="fa fa-list"
                    buttonText="Add KPI"
                    onClick={(e) => redirectToAddIndicator(e)}
                  />
                </div>
                <div>
                  <OnHoverButtonIcon
                    className="actions"
                    iconClass="fa fa-remove"
                    dataConfirm="Are you sure?"
                    buttonText="Delete"
                    onClick={(e) => deleteItem(e)}
                  />
                </div>
              </div>
            );
          }
          if (type === "performance-indicators") {
            return (
              <div style={{ display: "flex" }}>
                <div>
                  <OnHoverButtonIcon
                    className="actions"
                    iconClass="fa fa-remove"
                    dataConfirm="Are you sure?"
                    buttonText="Delete"
                    onClick={deleteItem}
                  />
                </div>
                <div></div>
              </div>
            );
          }
        } else {
          return null;
        }
      };

      const redirectToEditOutcome = () => {
        setOutcomeId(itemId);
        return navigate(
          `/projects/${project_id}/customer_successes/${itemId}/edit`
        );
      };

      let outcomeId = "";
      for (let i = 0; i < indicators.length; i++) {
        if (indicators[i].id === itemId) {
          outcomeId = indicators[i].relationships["customer-success"].data.id;
          setOutcomeId(outcomeId);
        }
      }
      const redirectToEditIndicator = () => {
        setIndicatorId(itemId);
        return navigate(
          `/projects/${project_id}/customer_successes/${outcomeId}/performance_indicators/${itemId}/edit`
        );
      };

      const getOnClick = () => {
        if (type === "customer-successes") {
          return redirectToEditOutcome;
        }
        if (type === "performance-indicators") {
          return redirectToEditIndicator;
        }
      };

      const getStartDate = () => {
        if (startedOn !== null && item.attributes.status !== "pending") {
          return (
            <>
              <br />
              <small>{`(${startedOn})`}</small>
            </>
          );
        }
      };

      const getFirstColumn = () => {
        if (type === "customer-successes") {
          return {
            class: "bold",
            name: `Outcome: ${item.attributes.outcome}`,
          };
        }
        if (type === "performance-indicators") {
          return {
            class: "",
            name: (
              <div style={{ marginLeft: "2em" }}>
                {item.attributes.name}
                {getStartDate()}
              </div>
            ),
          };
        }
      };

      const getSecondColumn = () => {
        if (type === "customer-successes") {
          return {
            class: "",
            name: "",
          };
        }
        if (type === "performance-indicators") {
          return {
            class: "",
            name: due,
          };
        }
      };

      const getThirdColumn = () => {
        if (type === "customer-successes") {
          return getSecondColumn();
        }
        if (type === "performance-indicators") {
          return {
            class: "",
            name: completed,
          };
        }
      };

      const statusOptions = statuses.map((status) => {
        return (
          <option accessKey={status.id} key={status.id}>
            {status.attributes.name}
          </option>
        );
      });

      const updateStatus = (statusId) => {
        const updatedPerformanceIndicator = {
          data: {
            type: "performance-indicators",
            id: itemId,
            attributes: {
              status: statusId,
            },
            relationships: {
              "customer-success": {
                data: { type: "customer-successes", id: outcomeId },
              },
            },
          },
        };
        return API.Patch(
          `${apiHost}/${account_slug}/v1/performance-indicators/${itemId}`,
          updatedPerformanceIndicator,
          authorizationCode
        );
      };

      const getFourthColumn = () => {
        if (type === "customer-successes") {
          return getSecondColumn();
        }
        if (type === "performance-indicators") {
          return {
            class: "",
            name: (
              <Form.Control
                as="select"
                defaultValue={initialStatus}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setCurrentStatus(e.target.value);
                  let selected = e.target.options.selectedIndex;
                  let statusId = e.target.options[selected].accessKey;
                  updateStatus(statusId).catch((err) => {
                    setErrorMessages(
                      formatUnprocessibleResponse(err, "outcome")
                    );
                    setShowFailAlert(true);
                  });
                }}
              >
                {statusOptions}
              </Form.Control>
            ),
          };
        }
      };

      // Map returns a table row for each Outcome
      return {
        rowClass: "outomesRow",
        onClick: getOnClick(),
        columns: [
          getFirstColumn(),
          getSecondColumn(),
          getThirdColumn(),
          getFourthColumn(),
        ],
        actions: renderActions(),
      };
    });
  };

  const getFooterData = () => {
    let content = "";
    if (projectStatus === "building") {
      content = (
        <RouterButton
          path={`/projects/${project_id}/customer_successes/new`}
          className="newResource"
          iconClass="fa fa-plus"
          buttonText="Add Outcome"
        />
      );
    }
    return {
      rowClass: "col-12",
      columns: [{ class: "outcomesFooter", content: content }],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "col-sm-5",
        name: "Measure",
      },
      {
        class: "col-sm-2",
        name: "Due",
      },
      {
        class: "col-sm-2",
        name: "Completed",
      },
      {
        class: "col-sm-2",
        name: "Status",
      },
      {
        class: "col-sm-1",
        name: "Action",
      },
    ],
  };

  let rowArray = [];

  for (let i = 0; i < outcomes.length; i++) {
    rowArray.push(outcomes[i]);
    if (indicators.length !== 0) {
      for (let j = 0; j < indicators.length; j++) {
        let indicatorRelationshipData =
          outcomes[i].relationships["performance-indicators"].data;
        if (
          indicatorRelationshipData &&
          indicatorRelationshipData.length !== 0
        ) {
          for (let k = 0; k < indicatorRelationshipData.length; k++) {
            if (indicatorRelationshipData[k].id === indicators[j].id) {
              rowArray.push(indicators[j]);
            }
          }
        }
      }
    }
  }

  const tableData = getTableData(rowArray);
  const footerData = getFooterData();

  let outcomesTable = new ScopeStackTable(
    "outcomes",
    headerData,
    tableData,
    footerData
  );

  const detailsCreatedAlert = () => {
    let message = "";
    if (indicatorCreated) {
      message = "Added Performance Indicator";
    }
    if (outcomeCreated) {
      message = "Added customer outcome";
    }
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="outcomeAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text={message}
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {detailsCreatedAlert()}
      {handleFailAlert()}
      <Card>
        <CardHeader title="Outcomes and KPIs" />
        <Card.Body>
          <BaseTable
            className="scopestack outcomesTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={outcomesTable.buildHeaderRows()}
            dataRows={outcomesTable.buildDataRows()}
            footerRows={outcomesTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProjectOutcomes;
