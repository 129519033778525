import React, { useState, useEffect } from "react";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import NavTabs from "@components/NavTabs/NavTabs";
import { Card, Row, Col, Form } from "react-bootstrap";
import API from "@API";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import {
  snakeToPascalCase,
  splitPascalCase,
  capitalizeFirstLetter,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";

function OurDescription({
  account_slug,
  project_id,
  account_id,
  projectStatus,
  partnerRequestId,
  partners,
  languageFields,
  phases,
  thirdPartyServices,
  subtasks,
  requestStatus,
  currencyUnit,
  authorizationCode,
}) {
  // Env
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  // Alerts
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Form/table states
  const [requestId, setRequestId] = useState(null);
  const [services, setServices] = useState([]);
  const [subservices, setSubservices] = useState([]);
  const [languageField, setLanguageField] = useState("");

  useEffect(() => {
    for (let i = 0; i < languageFields.length; i++) {
      setLanguageField(languageFields[0].attributes.name);
    }

    const getLanguages = (service) => {
      let newLanguages = languageFields.map((language) => {
        return {
          ...service.attributes.languages,
          [language.attributes["sow-language"]]: "",
        };
      });

      let objOfLanguages = Object.assign({}, ...newLanguages);
      objOfLanguages["service_description"] =
        service.attributes["service-description"] != null
          ? service.attributes["service-description"]
          : "";
      return objOfLanguages;
    };

    const getFrequency = (service) => {
      let frequency = service.attributes["payment-frequency"];
      if (frequency.includes("_")) {
        return splitPascalCase(snakeToPascalCase(frequency));
      }
      return capitalizeFirstLetter(frequency);
    };

    setServices(
      thirdPartyServices.map((service) => {
        let phaseData = service.relationships["project-phase"].data;
        return {
          id: service.id,
          type: "service",
          phase: phaseData ? phaseData.id : null,
          name: service.attributes.name,
          quantity: service.attributes.quantity,
          hours: service.attributes["est-hours"],
          rate: service.attributes["est-rate"],
          cost: service.attributes["est-cost"],
          frequency: getFrequency(service),
          languages: getLanguages(service),
          changed: false,
        };
      })
    );
    setSubservices(
      subtasks.map((subservice) => {
        return {
          serviceId: subservice.attributes["source-id"],
          id: subservice.id,
          type: "subservice",
          name: subservice.attributes.name,
          quantity: subservice.attributes.quantity,
          hours: subservice.attributes["est-hours"],
          rate: subservice.attributes["est-rate"],
          cost: subservice.attributes["est-cost"],
          frequency: "",
          languages: getLanguages(subservice),
          changed: false,
        };
      })
    );

    setRequestId(id);
  }, [
    authorizationCode,
    languageFields,
    phases,
    thirdPartyServices,
    subtasks,
    requestStatus,
    currencyUnit,
  ]);

  const languageOptions = languageFields.map((languageField) => {
    return (
      <option accessKey={languageField.id} key={languageField.id}>
        {languageField.attributes.name}
      </option>
    );
  });

  const getTableData = (array) => {
    return array.map((item) => {
      const getColumns = () => {
        if (item.type == "project-phase") {
          return [
            { class: "col newPhase", name: <strong>{item.name}</strong> },
          ];
        } else {
          const serviceNameField = {
            class: "col",
            name: item.name,
          };

          const descriptionField = {
            class: "col-6",
            name: languageFields.map((language, index) => (
              <Form.Control
                key={index}
                as="textarea"
                rows={2}
                style={
                  language.attributes.name !== languageField
                    ? { display: "none" }
                    : { display: "block" }
                }
                id={item.id}
                value={item.languages[language.attributes["sow-language"]]}
                onChange={(e) => {
                  e.persist();
                  setHasUnsavedChanges(true);
                  if (item.type == "subservice") {
                    setSubservices(
                      subservices.map((subservice) => {
                        let langArray = languageFields.map((lang) => {
                          if (
                            lang.attributes["sow-language"] ===
                            language.attributes["sow-language"]
                          ) {
                            let key = lang.attributes["sow-language"];
                            return {
                              ...subservice.languages,
                              [key]: e.target.value,
                            };
                          }
                        });
                        let newLangs = Object.assign({}, ...langArray);
                        if (subservice.id == e.target.id) {
                          return {
                            ...subservice,
                            changed: true,
                            languages: newLangs,
                          };
                        } else {
                          return subservice;
                        }
                      })
                    );
                  }
                  if (item.type !== "subservice") {
                    setServices(
                      services.map((service) => {
                        let langArray = languageFields.map((lang) => {
                          if (
                            lang.attributes["sow-language"] ===
                            language.attributes["sow-language"]
                          ) {
                            let key = lang.attributes["sow-language"];
                            return {
                              ...service.languages,
                              [key]: e.target.value,
                            };
                          }
                        });
                        let newLangs = Object.assign({}, ...langArray);
                        if (service.id == e.target.id) {
                          return {
                            ...service,
                            changed: true,
                            languages: newLangs,
                          };
                        } else {
                          return service;
                        }
                      })
                    );
                  }
                }}
              />
            )),
          };

          return [serviceNameField, descriptionField];
        }
      };
      return {
        rowClass:
          item.type !== "subservice"
            ? "thirdPartyServicesRow row"
            : "thirdPartyServicesRow row subserviceRow",
        onClick: null,
        columns: getColumns(),
        actions: null,
      };
    });
  };

  let thirdPartyServicesHeaderData = {
    rowClass: "thirdPartyServicesHeaderRow row",
    columns: [
      {
        class: "col",
        name: "Service",
      },
      {
        class: "col-6",
        name: "Content",
      },
    ],
  };

  const getThirdPartyServicesFooterData = () => {
    let content = "";
    content = (
      <div>
        <SubmitButton />
      </div>
    );
    return {
      rowClass: "col-12",
      columns: [{ class: "thirdPartyServicesFooter", content: content }],
    };
  };

  const arrangeDataForTable = () => {
    let array = [];
    let phasesWithServices = [];
    let noPhaseServices = services.filter((service) => {
      if (service.phase == null) service.phase = "noPhase";
      return service.phase == "noPhase";
    });
    if (noPhaseServices.length !== 0) {
      phasesWithServices.push({
        id: "noPhase",
        attributes: { name: "No Phase" },
      });
    }
    for (let i = 0; i < phases.length; i++) {
      services.forEach((service) => {
        if (service.phase == phases[i].id) {
          phasesWithServices.push(phases[i]);
        }
      });
    }
    phasesWithServices = Array.from(phasesWithServices);

    for (let i = 0; i < phasesWithServices.length; i++) {
      array.push({
        type: "project-phase",
        name: phasesWithServices[i].attributes.name,
      });
      for (let k = 0; k < services.length; k++) {
        let phaseId = services[k].phase;
        if (phaseId == phasesWithServices[i].id) {
          array.push(services[k]);
          for (let j = 0; j < subservices.length; j++) {
            if (subservices[j].serviceId == services[k].id) {
              array.push(subservices[j]);
            }
          }
        }
      }
    }
    return array;
  };

  const adjustedServices = arrangeDataForTable();

  const thirdPartyServicesTableData = getTableData(adjustedServices);
  const thirdPartyServicesFooterData = getThirdPartyServicesFooterData();

  let thirdPartyServicesTable = new ScopeStackTable(
    "services",
    thirdPartyServicesHeaderData,
    thirdPartyServicesTableData,
    thirdPartyServicesFooterData
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setHasUnsavedChanges(false);
    let promises = [];

    const getUpdatedLanguages = (service) => {
      let languages = service.languages;
      delete service.languages["service_description"];
      return languages;
    };

    const submitSubservices = (serviceId, postedServiceId, phaseId) => {
      subservices.forEach((subservice) => {
        let subserviceData = {
          data: {
            type: "third-party-services",
            id: subservice.id,
            attributes: {
              position: subservice.id,
              "service-description":
                subservice.languages["service_description"],
              "source-type": "ThirdPartyService",
              "source-id": postedServiceId,
            },
            relationships: {
              "project-phase": {
                data: { type: "project-phases", id: phaseId },
              },
            },
          },
        };
        if (subservice.serviceId == serviceId && subservice.changed === true) {
          subserviceData.data.attributes.languages =
            getUpdatedLanguages(subservice);
          promises.push(
            API.Patch(
              `${apiHost}/${account_slug}/v1/third-party-services/${subservice.id}`,
              subserviceData,
              authorizationCode
            )
          );
        }
      });
    };

    services.forEach((service, index) => {
      if (subservices.length !== 0) {
        submitSubservices(service.id, service.id, service.phase);
      }
      if (service.changed === true) {
        let serviceData = {
          data: {
            type: "third-party-services",
            id: service.id,
            attributes: {
              position: index + 1,
              "service-description": service.languages["service_description"],
              languages: getUpdatedLanguages(service),
              "source-type": "ThirdPartyRequest",
              "source-id": id,
            },
            relationships: {
              "project-phase": {
                data: { type: "project-phases", id: service.phase },
              },
            },
          },
        };
        promises.push(
          API.Patch(
            `${apiHost}/${account_slug}/v1/third-party-services/${service.id}`,
            serviceData,
            authorizationCode
          )
        );
      }
    });

    Promise.all(promises)
      .then((response) => {
        let allSuccessful = true;
        response.forEach((res) => {
          let data = res.data.data;
          if (
            (res.status == 200 || res.status == 201) &&
            data.attributes["source-type"] == "ThirdPartyRequest"
          ) {
            setServices(
              services.map((service) => {
                if (service.id == data.id) {
                  return {
                    ...service,
                    languages: {
                      ...service.languages,
                      service_description:
                        data.attributes["service-description"],
                    },
                  };
                } else {
                  return service;
                }
              })
            );
          }
          if (
            (res.status == 200 || res.status == 201) &&
            data.attributes["source-type"] == "ThirdPartyService"
          ) {
            setSubservices(
              subservices.map((subservice) => {
                if (subservice.id == data.id) {
                  let languages = data.attributes.languages;
                  languages["service_description"] =
                    data.attributes["service-description"];
                  return {
                    ...subservice,
                    languages: languages,
                  };
                } else {
                  return subservice;
                }
              })
            );
          }
          if (res.status !== 200 && res.status !== 201) {
            allSuccessful = false;
          }
        });
        if (allSuccessful) {
          setUpdatedAlertIsOpen(true);
          setTimeout(() => {
            setUpdatedAlertIsOpen(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "service details"));
        setShowFailAlert(true);
      });
  };

  const serviceUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Service details updated"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      <NavTabs
        tabOneText="Partner Request"
        tabOneUrl={`/projects/${project_id}/third_party_requests/${requestId}/edit`}
        tabTwoText="Partner Description"
        tabTwoUrl={`/projects/${project_id}/third_party_requests/${requestId}/external_services`}
        tabThreeText="Our Description"
        tabThreeUrl={`/projects/${project_id}/third_party_requests/${requestId}/third_party_services`}
        hasUnsavedChanges={hasUnsavedChanges}
      />
      <div className="col-sm-12" id="sow-workarea">
        <div className="tabContent">
          {serviceUpdatedAlert()}
          {handleFailAlert()}
          <Card>
            <Card.Body className="no-gutters">
              <Row>
                <Col></Col>
                <Col sm={3}>
                  <ControlledDropDown
                    className="pull-right"
                    label="View"
                    value={languageField}
                    onChange={(e) => setLanguageField(e.target.value)}
                    options={languageOptions}
                  />
                </Col>
              </Row>
              <Form onSubmit={handleSubmit}>
                <BaseTable
                  className="thirdPartyServicesTable"
                  striped={false}
                  hover={false}
                  bordered={false}
                  headerRows={thirdPartyServicesTable.buildHeaderRows()}
                  dataRows={thirdPartyServicesTable.buildDataRows()}
                  footerRows={thirdPartyServicesTable.buildFooterRows()}
                />
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default OurDescription;
