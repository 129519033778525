import React from "react";
import IconTextButton from "@components/Buttons/IconTextButton/IconTextButton";

function CreatePsaBtn({ accountSlug, projectId, className }) {
  return (
    <IconTextButton
      className={`btn btn-success createPsaBtn ${className}`}
      url={`/projects/${projectId}/psa_project/new`}
      iconClass="fa fa-share-square"
      buttonText="Push to PSA Project"
    />
  );
}

export default CreatePsaBtn;
