import React, { useState, useRef, useEffect } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import DocumentTemplateForm from "../DocumentTemplateForm/DocumentTemplateForm";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { handleAlert } from "@utils/helperFunctions";

function Edit({ account, permission }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [documentTemplate, setDocumentTemplate] = useState(
    location?.state?.documentTemplate || null
  );
  const submitRef = useRef<any>();
  const [errorMessages, setErrorMessages] = useState<string | string[]>(
    "Something went wrong! Your changes could not be saved at this time."
  );
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Document Template saved successfully!"
  );
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  useEffect(() => {
    if (location?.["state"]?.new) {
      setSuccessMessage("Document Template created successfully!");
      setShowSuccessAlert(true);
    }
  }, []);

  return (
    <div className="editDocumentTemplatesPage">
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Card>
        <Card.Header className="headerOverride">
          <div className="headerContainer headerPadding">
            <div className="actionBtns">
              <Button
                className="btnSeawhite marginRight10"
                onClick={(e) =>
                  navigate(`/admin/document_templates`, {
                    state: {},
                  })
                }
              >
                Cancel
              </Button>
              <h2 className="headerText">{`Edit ${
                documentTemplate?.name || ""
              }`}</h2>
            </div>

            <div className="actionBtns">
              {permission !== "view" && (
                <>
                  {documentTemplate?.mergeTemplate && (
                    <a href={documentTemplate?.mergeTemplate} rel="noreferrer">
                      <Button
                        className="btnSeawhite marginRight10"
                        style={{ width: "155px" }}
                      >
                        Download Current
                      </Button>
                    </a>
                  )}
                  <Button
                    className="squareGreenButton"
                    onClick={() => {
                      submitRef.current?.saveDocumentTemplate();
                    }}
                  >
                    Save
                  </Button>
                </>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <DocumentTemplateForm
            documentTemplateProp={documentTemplate}
            account={account}
            ref={submitRef}
            permission={permission}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default Edit;
