import { Row, Col, Form } from "react-bootstrap";
import { useStyles } from "./styles";
import { Button } from "@components/Button";
import DataTable from "@components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { Product } from "../types";
import { useGetAllProducts } from "./api";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router";
import { useState, useEffect } from "react";
import {
  formatUnprocessibleResponse,
  handleAlert,
  capitalizeFirstLetter,
} from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { faFloppyDisk, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import {
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetAccountQuery,
  useCreateDataImportMutation,
  useDeleteProjectProductMutation,
  useUpdateProjectProductMutation,
} from "@generated";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import fileToBase64 from "@utils/fileToBase64";
import { FileUploadDragAndDrop } from "@components/FormsV2/FileUploadDragAndDrop";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { V1ProductResource } from "@generated";
import usePrivilegeCheck from "@common/hooks/usePrivilegeCheck";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";

const Show = ({ projectId }: { projectId: string }): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const newProductsCreated = location.state?.productsCreated;
  const { hasPrivilege } = usePrivilegeCheck();
  const canAdd = hasPrivilege("projects.materials", ["create", "manage"]);
  const canCreate = hasPrivilege("settings.materials", ["manage"]);
  const canDelete = hasPrivilege("projects.materials", ["manage"]);

  const [createStandardProduct] = useCreateProductMutation();
  const [updateStandardProduct] = useUpdateProductMutation();
  const [updateProjectProduct] = useUpdateProjectProductMutation();
  const [importBillOfMaterials] = useCreateDataImportMutation();

  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [showBillOfMaterialsModal, setShowBillOfMaterialsModal] =
    useState(false);
  const [showDeleteProductsModal, setShowDeleteProductsModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [rowToUpdate, setRowToUpdate] = useState("");
  const [quanityLoading, setQuantityLoading] = useState(false);
  const [loadingBOM, setLoadingBOM] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [updateTriggered, setUpdateTriggered] = useState<boolean>(false);

  const [deleteProduct] = useDeleteProjectProductMutation();
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const {
    data: account,
    isLoading: accountLoading,
    isError: accountErr,
  } = useGetAccountQuery({ slug: accountSlug });

  const {
    products,
    loading: productsLoading,
    refetch,
  } = useGetAllProducts({ projectId });

  useEffect(() => {
    if (newProductsCreated) {
      setSuccessMessage(
        newProductsCreated <= 1
          ? "Product has been added."
          : `(${newProductsCreated}) products added.`
      );
      setShowSuccessAlert(true);
    }
    refetch();
  }, []);

  const handleUpdateQuantity = (e, product) => {
    const data = {
      id: Number(product.id),
      type: "project-products",
      attributes: {
        name: product.name,
        quantity: Number(e.target.value),
      },
      relationships: {
        project: {
          data: { id: Number(projectId), type: "projects" },
        },
      },
    };
    updateProjectProduct({
      slug: accountSlug,
      id: Number(product.id),
      body: {
        data,
      },
    })
      .unwrap()
      .then((response) => {
        if (response.data) {
          refetch().then(() => {
            setSuccessMessage("Product quantity updated.");
            setShowSuccessAlert(true);
            setQuantityLoading(false);
            setRowToUpdate("");
            setUpdateTriggered(false);
          });
        }
      })
      .catch(() => {
        setSuccessMessage("Something went wrong, your changes were not saved.");
        setShowFailAlert(true);
      });
  };

  const productColumns: ColumnDef<Product>[] = [
    {
      header: "Name",
      cell(props) {
        return (
          <>
            <div className={styles.displayFlex}>
              <span>{props.row.original.name} </span>
              {props.row.original.id !== 0 && (
                <FontAwesomeIcon
                  className={styles.productPreview}
                  icon={faPencil}
                  onClick={() => {
                    const productSelected = products.find(
                      (product) =>
                        Number(product.id) === Number(props.row.original.id)
                    );
                    navigate("custom", {
                      state: productSelected,
                    });
                  }}
                />
              )}
            </div>
            {(props.row.original.customHardwareCost ||
              props.row.original.customHardwarePrice) && (
              <p style={{ color: "#418172", marginBottom: "-14px" }}>
                Advanced pricing applied
              </p>
            )}
          </>
        );
      },
    },
    {
      header: "Source",
      cell(props) {
        return capitalizeFirstLetter(props.row.original.source || "");
      },
    },
    {
      header: "MFR Part",
      cell(props) {
        return props.row.original.mfrPartNumber;
      },
    },
    {
      header: "Int Prod Id",
      cell(props) {
        return props.row.original.productId;
      },
    },
    {
      header: "qty",
      cell(props) {
        const [qty, setQty] = useState<string>(
          String(props.row.original.quantity)
        );

        useEffect(() => {
          const timer = setTimeout(() => {
            if (
              !updateTriggered &&
              props.row.original["prevQuantity"] &&
              Number(qty) !== props.row.original["prevQuantity"] &&
              Number(qty) > 0
            ) {
              setQuantityLoading(true);
              setRowToUpdate(String(props.row.original.id));
              handleUpdateQuantity(
                { target: { value: qty } },
                props.row.original
              );
              setUpdateTriggered(true);
              props.row.original["prevQuantity"] = props.row.original.quantity;
            }
          }, 3000);

          return () => clearTimeout(timer);
        }, [qty]);

        return props.row.original.id === 0 ? (
          ""
        ) : quanityLoading && rowToUpdate === String(props.row.original.id) ? (
          <div className={styles.spinner}>
            <SmallSpinner />
          </div>
        ) : (
          <Form.Control
            id={String(props.row.original.id)}
            value={qty ? Number(qty) : ""}
            onChange={(e) => {
              const value = e.target.value;
              setQty(String(value));
              props.row.original["prevQuantity"] = props.row.original.quantity;
              props.row.original.quantity = Number(value);
            }}
            onBlur={(e) => {
              if (timer) clearTimeout(timer);

              if (Number(e.target.value) === 0 && !updateTriggered) {
                setErrorMessage("Quantity must be greater than zero");
                setShowFailAlert(true);
                return;
              }
              if (
                Number(e.target.value) > 0 &&
                !updateTriggered &&
                props.row.original["prevQuantity"] &&
                Number(e.target.value) !== props.row.original["prevQuantity"]
              ) {
                setQuantityLoading(true);
                setRowToUpdate(String(props.row.original.id));
                handleUpdateQuantity(e, props.row.original);
              }

              setUpdateTriggered(false);
            }}
            type="number"
            min={1}
            step={1}
            style={{ width: "70px", height: "30px" }}
            onFocus={(e) => {
              if (showSuccessAlert) setShowSuccessAlert(false);
            }}
          />
        );
      },
    },
    {
      header: "units",
      cell(props) {
        return props.row.original.units;
      },
    },
    {
      header: "unit price",
      cell(props) {
        return props.row.original.id === 0
          ? ""
          : formatCurrency(props.row.original.price);
      },
    },
  ];

  const formatCurrency = (price) => (
    <CurrencyFormat
      displayType="text"
      prefix={
        account?.data?.attributes?.["field-labels"]["currency_unit"]
          ? account.data.attributes["field-labels"]["currency_unit"]
          : "$"
      }
      isNumericString={true}
      thousandSeparator={true}
      value={(Number(price) || 0).toFixed(2)}
    />
  );

  const handleSaveAsStandard = () => {
    //Get an array of products from the selected ids
    const selectedProducts = selectedProductIds.map((id, i) =>
      products.find((p) => Number(p.id) === Number(id))
    );

    const handleSuccessResponse = () => {
      const length = selectedProducts.length;
      const message = length > 1 ? `${length} products` : "Product";
      setSuccessMessage(`${message} saved as standard.`);
      setShowSuccessAlert(true);
      setSelectedProductIds([]);
    };

    const handleFailResponse = (msg) => {
      if (msg) {
        setErrorMessage(msg);
        setShowFailAlert(true);
      } else {
        setErrorMessage("Something went wrong.");
        setShowFailAlert(true);
      }
    };
    //For each product, organize data and submit
    selectedProducts.forEach((product, i) => {
      if (product && account?.data?.attributes?.["account-id"]) {
        let data: V1ProductResource = {
          type: "products",
          attributes: {
            name: product.name,
            description: product.description,
            sku: product.sku,
            "product-id": product.productId,
            "manufacturer-part-number": product.mfrPartNumber,
            "unit-of-measure": product.units,
            category: product.category,
            subcategory: product.subcategory,
            "unit-cost": parseFloat(product.unitCost || "") || 0,
            "unit-price": parseFloat(product.price || "") || 0,
            "list-price": parseFloat(product.listPrice || "") || 0,
            "vendor-discount": parseFloat(product.vendorDiscount || "") || 0,
            markup: parseFloat(product.markup || "") || 0,
          },
          relationships: {
            account: {
              data: {
                id: account.data.attributes["account-id"],
                type: "accounts",
              },
            },
          },
        };

        // If it has a related product-id, update the standard product, otherwise post as new product
        const standardProductId = product?.relationships?.product?.data?.id;
        if (standardProductId) {
          data.id = standardProductId;
          //PATCH product
          updateStandardProduct({
            slug: accountSlug,
            id: standardProductId,
            body: { data },
          })
            .unwrap()
            .then((response) => {
              if (i === selectedProducts.length - 1 && response.data) {
                handleSuccessResponse();
              }
            })
            .catch((error) =>
              handleFailResponse(error?.data?.errors?.[0]?.detail)
            );
        } else {
          //CREATE product
          createStandardProduct({ slug: accountSlug, body: { data } })
            .unwrap()
            .then((response) => {
              if (i === selectedProducts.length - 1 && response.data) {
                handleSuccessResponse();
              }
            })
            .catch((error) =>
              handleFailResponse(error?.data?.errors?.[0]?.detail)
            );
        }
      } else {
        handleFailResponse("");
      }
    });
    setShowSaveModal(false);
  };

  const handleCancel = () => {
    setShowSaveModal(false);
    setSelectedProductIds([]);
  };

  const getSaveModalBody = () => {
    const items = selectedProductIds.map(
      (id, i) =>
        products.find((p) => Number(p.id) === Number(id)) ||
        ({ id: i } as Product)
    );
    return (
      <>
        <p>
          <strong>
            You are about to save {items.length} product
            {items.length > 1 && "s"} as Standard:
          </strong>
        </p>
        <div className={styles.modal}>
          <ul>
            {items.map((item, i) => (
              <li key={i}>{item.name}</li>
            ))}
          </ul>
        </div>
        <p>
          These can be edited and removed in{" "}
          <strong>
            Settings {">"} Content {">"} Product
          </strong>
        </p>
      </>
    );
  };
  const handleFileSelect = async (file: File) => {
    if (file) {
      const encodedFile = await fileToBase64(file);

      if (!account || !account.data?.attributes?.slug) return;
      const importData = {
        type: "data-imports",
        attributes: {
          "import-type": "bill_of_material" as "bill_of_material",
          "context-type": "Project" as "Project",
          "context-id": projectId.toString(),
          "data-file": encodedFile,
        },
        relationships: {
          account: {
            data: {
              type: "accounts",
              id: account.data?.attributes["account-id"],
            },
          },
        },
      };

      if (encodedFile) {
        setLoadingBOM(true);
        importBillOfMaterials({
          slug: accountSlug,
          body: { data: importData },
        })
          .then((res) => {
            const importId = res["data"].data.id;
            setShowBillOfMaterialsModal(false);
            window.location.replace(
              `${appHost}/projects/${projectId}/project_imports/${importId}/edit`
            );
          })
          .catch((error) => {
            setLoadingBOM(false);
            setErrorMessage(
              formatUnprocessibleResponse(error, "bill of materials")
            );
            setShowFailAlert(true);
          });
      }
    }
  };

  const getToolTip = (msg) => (
    <Tooltip id={msg.split(" ").join("")}>{msg}</Tooltip>
  );

  const handleDeleteProducts = () => {
    if (selectedProductIds.length > 0) {
      const promises = selectedProductIds.map((id) => {
        return deleteProduct({
          slug: accountSlug,
          id: Number(id),
        });
      });

      Promise.all(promises)
        .then(() => {
          setShowDeleteProductsModal(false);
          setSuccessMessage(
            selectedProductIds.length <= 1
              ? "Product has been deleted."
              : `(${selectedProductIds.length}) products deleted.`
          );
          setShowSuccessAlert(true);
          setSelectedProductIds([]);
          refetch();
        })
        .catch((error) => {
          setErrorMessage(formatUnprocessibleResponse(error, "product"));
          setShowFailAlert(true);
        });
    }
  };

  const productCount = selectedProductIds.length;

  const productsToDelete = selectedProductIds.map((id) =>
    products.find((product) => Number(product.id) === Number(id))
  );
  // Filter out any undefined entries in case a product wasn't found
  const validProductsToDelete = productsToDelete.filter(Boolean);

  // Create the productText element using JSX
  const productTextElement = (
    <div>
      <p>
        <strong>
          You are about to delete {productCount} product
          {productCount > 1 ? "s" : ""}:
        </strong>
      </p>
      <ul>
        {validProductsToDelete.map(
          (product) => product && <li key={product.id}>{product.name}</li> // This ensures product is not undefined
        )}
      </ul>
      <p>This cannot be undone. Do you wish to proceed?</p>
    </div>
  );

  return products &&
    account &&
    !productsLoading &&
    !accountLoading &&
    !loadingBOM ? (
    <div>
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}

      <Row>
        <Col>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <h2>Products</h2>
              {canAdd && (
                <div className={styles.actionButtons}>
                  <div className={styles.button}>
                    <Button onClick={() => setShowBillOfMaterialsModal(true)}>
                      Import Bill of Materials
                    </Button>
                  </div>
                  <div className={styles.button}>
                    <Button
                      onClick={() =>
                        navigate("custom", {
                          state: null,
                        })
                      }
                    >
                      Add Custom
                    </Button>
                  </div>
                  <div className={styles.button}>
                    <Button onClick={() => navigate("new")}>
                      Add Products
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <hr></hr>
            {selectedProductIds.length > 0 ? (
              <div className={styles.selectedProducts}>
                <div className={styles.selectedMessage}>
                  {selectedProductIds.length} Selected
                </div>
                {/* Save & Delete selected products buttons */}
                <div className={styles.selectedIcons}>
                  {canDelete && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={getToolTip("Delete")}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        onClick={() => setShowDeleteProductsModal(true)}
                      />
                    </OverlayTrigger>
                  )}
                  {canCreate && (
                    <div
                      className={canDelete && canCreate ? styles.saveIcon : ""}
                    >
                      <OverlayTrigger
                        placement="bottom"
                        overlay={getToolTip("Save as standard")}
                      >
                        <FontAwesomeIcon
                          icon={faFloppyDisk}
                          onClick={() => setShowSaveModal(true)}
                        />
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className={canCreate || canDelete ? styles.placeHolder : ""}
              ></div>
            )}
            <div className={styles.dataTable}>
              <DataTable
                data={
                  products?.length > 0
                    ? products
                    : [
                        {
                          id: 0,
                          name: "Add or import products to get started",
                        },
                      ]
                }
                columns={productColumns}
                selectable={products.length > 0 && (canCreate || canDelete)}
                striped
                hover
                selectedIds={selectedProductIds}
                setSelectedIds={setSelectedProductIds}
              />
            </div>
            {products.length > 0 && (
              <div className={`text-right ${styles.subtotal}`}>
                <strong>Subtotal:</strong>&nbsp;
                {formatCurrency(
                  products
                    .map((p) => Number(p.hardwarePrice))
                    .reduce((acc, current) => acc + current, 0)
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <ScopeStackModal
        modalTitle="Delete Products"
        modalBody={
          <div>
            <div className="instructionalText">{productTextElement}</div>
          </div>
        }
        button1Text="Cancel"
        handleButton1Click={() => setShowDeleteProductsModal(false)}
        button2Text={"Delete"}
        handleButton2Click={() => handleDeleteProducts()}
        show={showDeleteProductsModal}
        handleClose={() => setShowDeleteProductsModal(false)}
        deleteModal={true}
      />
      <ScopeStackModal
        modalTitle="Import Bill of  Materials"
        modalBody={
          <div>
            <div className="instructionalText seafoamText bold">
              * Upload Bill of Materials (CSV or Excel)
            </div>
            <FileUploadDragAndDrop handleFileSelect={handleFileSelect} />
          </div>
        }
        button1Text="Cancel"
        handleButton1Click={() => setShowBillOfMaterialsModal(false)}
        button2Text={null}
        handleButton2Click={() => null}
        show={showBillOfMaterialsModal}
        handleClose={() => setShowBillOfMaterialsModal(false)}
      />
      <ScopeStackModal
        show={showSaveModal}
        modalTitle="Save as Standard"
        modalBody={getSaveModalBody()}
        button1Text="Cancel"
        handleButton1Click={handleCancel}
        button2Text="Save Products"
        handleButton2Click={handleSaveAsStandard}
        handleClose={handleCancel}
      />
    </div>
  ) : (
    <ScopeStackSpinner />
  );
};

export default Show;
