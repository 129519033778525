import React from "react";
import { Form } from "react-bootstrap";
import FormFieldLabel from "./FormFieldLabel/FormFieldLabel";

function FormNumberField({
  label,
  placeholder,
  value,
  onChange,
  min,
  max,
  required,
  defaultValue,
  readOnly,
  step,
  fieldFooter,
}) {
  return (
    <Form.Group className="formGroup">
      <FormFieldLabel label={label} />
      <Form.Control
        readOnly={readOnly}
        required={required}
        defaultValue={defaultValue}
        value={value === null ? "" : value}
        placeholder={placeholder}
        onChange={onChange}
        type="number"
        min={min}
        max={max}
        step={step}
      />
      {fieldFooter !== null ? <small>{fieldFooter}</small> : null}
    </Form.Group>
  );
}

export default FormNumberField;
