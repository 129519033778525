import React from "react";
import { SpinnerCircular } from "spinners-react";

function SmallSpinner() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px",
      }}
    >
      <SpinnerCircular
        color="#418172"
        secondaryColor="#1C2655"
        thickness={200}
        size={30}
      />
    </div>
  );
}

export default SmallSpinner;
