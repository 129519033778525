import React from "react";
import { Button } from "react-bootstrap";

function SubmitButton({className, id }) {
  return (
      <Button id={id} className={className} type="submit" variant="primary">Save</Button>
  );
}

export default SubmitButton;
