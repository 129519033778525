import React, { useState, useEffect } from "react";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import Requests from "./Requests";

function ServiceRequests({
  serviceRequests,
  partners,
  isLoading,
  authorizationCode,
}) {
  useEffect(() => {}, [serviceRequests, partners, isLoading]);

  if (isLoading) {
    return (
      <div style={{ paddingTop: "20px" }}>
        <ScopeStackSpinner />
      </div>
    );
  } else {
    return (
      <Requests
        arr={serviceRequests}
        partners={partners}
        authorizationCode={authorizationCode}
      />
    );
  }
}

export default ServiceRequests;
