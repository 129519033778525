import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import CardHeader from "@components/CardHeader/CardHeader";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import RouterButton from "@components/Buttons/RouterButton/RouterButton";
import { useNavigate } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";

function ProjectVersions({
  account_slug,
  project_id,
  projectStatus,
  versions,
  versionId,
  account_id,
  versionCreated,
  versionUpdated,
  setVersionId,
  isLoading,
  authorizationCode,
}) {
  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showUnauthorizedAlert, setShowUnauthorizedAlert] = useState(false);

  useEffect(() => {
    if (versionCreated === true) {
      setCreatedAlertIsOpen(true);
    }
  }, [versions, isLoading]);

  const getTableData = () => {
    if (versions.length === 0) {
      return [];
    }
    // Map over versions & return data for ScopeStackTable
    return versions.map((version, index) => {
      const versionId = version.id;

      const renderRestoreButton = () => {
        if (
          projectStatus === "building" &&
          version.attributes["completed-at"] !== null
        ) {
          return (
            <OnHoverButtonIcon
              className="actions"
              iconClass="fa fa-undo"
              buttonText="Restore"
              onClick={(e) => {
                e.stopPropagation();
                API.Put(version.links.revert, null, authorizationCode)
                  .then((res) => {
                    if (res.status == 204) {
                      window.location.reload();
                    }
                  })
                  .catch((err) => {
                    setErrorMessages(
                      formatUnprocessibleResponse(err, "Version")
                    );
                    setShowFailAlert(true);
                  });
              }}
            />
          );
        } else {
          return null;
        }
      };

      const renderChangesSinceButton = () => {
        if (version.attributes["completed-at"] !== null) {
          return (
            <OnHoverButtonIcon
              className="actions"
              iconClass="fa fa-object-ungroup"
              buttonText="Changes Since This Version"
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `/projects/${project_id}/project_versions/${versionId}/audit_logs?changes=true`
                );
              }}
            />
          );
        } else {
          return null;
        }
      };

      // Render icon buttons under actions on hover if the project status is: building
      const renderActions = () => {
        return (
          <div className="actionsDiv">
            {renderRestoreButton()}
            <OnHoverButtonIcon
              className="actions"
              iconClass="fa fa-object-group"
              buttonText="Changes In This Version"
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `/projects/${project_id}/project_versions/${versionId}/audit_logs`
                );
              }}
            />
            {renderChangesSinceButton()}
          </div>
        );
      };

      const redirectToEditVersion = () => {
        setVersionId(versionId);
        return navigate(
          `/projects/${project_id}/project_versions/${versionId}/edit`
        );
      };

      const createdOn = new Date(version.attributes["started-at"]);
      const options = { month: "long", day: "numeric", year: "numeric" };

      // Map returns a table row for each Version
      return {
        rowClass: "versionsRow",
        onClick: redirectToEditVersion,
        columns: [
          {
            class: "",
            name: version.attributes.name,
          },
          {
            class: "",
            name:
              version.attributes.username !== null
                ? version.attributes.username
                : "ScopeStack",
          },
          {
            class: "",
            name: createdOn.toLocaleDateString("en-US", options),
          },
          {
            class: "",
            name: version.attributes.comment,
          },
        ],
        actions: renderActions(),
      };
    });
  };

  const getFooterData = () => {
    let content = "";
    if (projectStatus === "building") {
      content = (
        <RouterButton
          path={`/projects/${project_id}/project_versions/new`}
          className="newResource"
          iconClass="fa fa-plus"
          buttonText="Add Version"
        />
      );
    }
    return {
      rowClass: "col-12",
      columns: [{ class: "versionsFooter", content: content }],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Name",
      },
      {
        class: "",
        name: "Created By",
      },
      {
        class: "",
        name: "Created At",
      },
      {
        class: "",
        name: "Comment",
      },
    ],
  };
  const tableData = getTableData();
  const footerData = getFooterData();

  let versionsTable = new ScopeStackTable(
    "versions",
    headerData,
    tableData,
    footerData
  );

  const detailsCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="versionAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Version created"
        />
      );
    }
  };

  const handleUnauthorizedAlert = () => {
    if (showUnauthorizedAlert === true) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setShowUnauthorizedAlert(false)}
          text="You are unauthorized to edit this version. Only the person who created the version can edit it."
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {detailsCreatedAlert()}
      {handleFailAlert()}
      {handleUnauthorizedAlert()}
      <Card>
        <CardHeader title="Project Versions" />
        <Card.Body>
          <BaseTable
            className="scopestack versionsTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={versionsTable.buildHeaderRows()}
            dataRows={versionsTable.buildDataRows()}
            footerRows={versionsTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProjectVersions;
