import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';
import React from 'react';
import CountrySelect from 'react-bootstrap-country-select';

function CountryPicker ({ country, onCountryChange }) {

  return (
    <CountrySelect
      value={country}
      onChange={onCountryChange}
    />
  );

};

export default CountryPicker;
