import FolderTree, { testData } from "react-folder-tree";
import "react-folder-tree/dist/style.css";

import React from "react";

function FolderTreeComponent({
  showCheckbox,
  readOnly,
  fileTreeData,
  onNameClick,
}) {
  const onTreeStateChange = (state, event) => null;

  return (
    <FolderTree
      data={fileTreeData}
      onChange={onTreeStateChange}
      showCheckbox={showCheckbox}
      readOnly={readOnly}
      onNameClick={onNameClick}
    />
  );
}

export default FolderTreeComponent;
