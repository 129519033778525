import { css } from "@emotion/css";
import useTheme from "@common/hooks/useTheme";

const useStyles = () => {
  const theme = useTheme();

  const card = css`
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray["2"]};
    background: ${theme.colors.white};
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    padding: 1.25em 1.75em;
    margin: 2em;
  `;

  const cardHeader = css`
    h2 {
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: ${theme.colors.navy["1"]};
      margin: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 15px;
  `;

  const formSection = css`
    margin-top: 20px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d7d7d7;
  `;
  const pricing = css`
    margin-top: 25px;
  `;
  const sectionHeader = css`
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 18px;
  `;

  const actionButtons = css`
    display: flex;
    align-items: center;
  `;

  const button = css`
    border-radius: 0px;
    height: 40px;
    color: white;
    font-weight: 700;
    font-size: 16px;
    padding: 8px 16px;
  `;
  const cancelButton = css`
    margin-right: 16px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 700;
    background: white;
    border-radius: 0px;
    border: 2px solid #418172;
    color: #418172;
    &:hover {
      background: white !important;
      color: #418172;
      border: 2px solid #418172;
    }
  `;

  const addAnotherBtn = css`
    margin-right: 16px;
  `;

  const radioOption = css`
    color: #418172 !important;
    padding-top: 5px;
    padding-bottom: 5px;
    & > * {
      accent-color: #418172 !important;
    }
  `;

  const selected = css`
    font-weight: 700;
  `;

  const disabled = css`
    background-color: #c4c4c4 !important;
    &:hover {
      background: #c4c4c4;
    }
  `;

  return {
    card,
    cardHeader,
    formSection,
    pricing,
    sectionHeader,
    actionButtons,
    button,
    cancelButton,
    addAnotherBtn,
    radioOption,
    selected,
    disabled,
  };
};

export default useStyles;
