import React, { useState, useEffect } from "react";
import PartnerRequest from "../PartnerRequest/PartnerRequest";

function EditPartnerRequest({
  account_slug,
  project_id,
  projectStatus,
  partnerRequests,
  partners,
  partnerRequestId,
  account_id,
  setPartnerRequestUpdated,
  linesOfBusiness,
  thirdPartyServices,
  currencies,
  requestStatus,
  currencyUnit,
  authorizationCode,
}) {
  // Env
  const location = window.location.href;
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  const [requestId, setRequestId] = useState(null);
  const [services, setServices] = useState([]);

  useEffect(() => {
    // Get third-party-services
    setServices(thirdPartyServices);

    setRequestId(id);
  }, [
    authorizationCode,
    thirdPartyServices,
    currencies,
    linesOfBusiness,
    partnerRequests,
    requestStatus,
  ]);

  return (
    <PartnerRequest
      account_slug={account_slug}
      project_id={project_id}
      projectStatus={projectStatus}
      partnerRequests={partnerRequests}
      partners={partners}
      partnerRequestId={partnerRequestId}
      account_id={account_id}
      setPartnerRequestUpdated={setPartnerRequestUpdated}
      linesOfBusiness={linesOfBusiness}
      currencies={currencies}
      thirdPartyServices={thirdPartyServices}
      requestStatus={requestStatus}
      currencyUnit={currencyUnit}
      authorizationCode={authorizationCode}
    />
  );
}

export default EditPartnerRequest;
