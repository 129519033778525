import React, { useState, useEffect } from "react";

function PaginationNumbersAPI({
  pageCount,
  pageArray,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  leftArrowOnClick,
  rightArrowOnClick,
  onNumberClick,
}) {
  let activeStyles = {
    borderRadius: "10%",
    backgroundColor: "#eef8f6",
  };

  useEffect(() => {}, [pageArray, pageCount, currentPage, itemsPerPage]);
  const truncatedPageArray = () => {
    if (pageArray.length <= 6) {
      return pageArray;
    }
    const start = Math.max(1, currentPage - 4);
    const end = Math.min(pageCount, start + 4);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const truncated = truncatedPageArray();

  return pageCount <= 1 ? (
    <div
      style={{
        display: "flex",
        color: "#2f3a7c",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="pageNumber" style={activeStyles}>
        1
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        color: "#2f3a7c",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="pageArrow"
        onClick={leftArrowOnClick}
        style={{ marginRight: "10px" }}
      >
        <i className="fa fa-angle-left"></i>
      </div>
      {!truncated.includes(1) && (
        <>
          <div
            className="pageNumber"
            onClick={() => {
              setCurrentPage(1);
              onNumberClick(itemsPerPage, 1);
            }}
            style={currentPage === 1 ? activeStyles : null}
          >
            1
          </div>
          {!truncated.includes(2) && <span className="ellipses">...</span>}
        </>
      )}
      {truncatedPageArray().map((count) => {
        return (
          <div
            key={count}
            className="pageNumber"
            onClick={() => {
              setCurrentPage(count);
              onNumberClick(itemsPerPage, count);
            }}
            style={count === currentPage ? activeStyles : null}
          >
            {count}
          </div>
        );
      })}
      {!truncated.includes(pageCount) && (
        <>
          {!truncated.includes(pageCount - 1) && (
            <span className="ellipses">...</span>
          )}

          <div
            className="pageNumber"
            onClick={() => {
              setCurrentPage(pageCount);
              onNumberClick(itemsPerPage, pageCount);
            }}
            style={pageCount === currentPage ? activeStyles : null}
          >
            {pageCount}
          </div>
        </>
      )}
      <div
        className="pageArrow"
        onClick={rightArrowOnClick}
        style={{ marginLeft: "10px" }}
      >
        <i className="fa fa-angle-right"></i>
      </div>
    </div>
  );
}

export default PaginationNumbersAPI;
