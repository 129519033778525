import { useListUsersForAccountQuery } from "../../../../services/generated";
import { V1UserResource } from "../../../../services/generated";

const useListUsers = ({ slug, currentPage }) => {
  const listUsersApiArg = {
    slug,
    page: {
      number: currentPage,
      size: 10,
    },
  };
  const { data, error, isLoading, refetch } = useListUsersForAccountQuery({
    ...listUsersApiArg,
    filter: { "view-only": false },
  });
  let user: V1UserResource[] = [];
  let customError: string | null = null;
  let pageMeta = {
    "record-count": 0,
    "page-count": 0,
  };

  if (data?.data) {
    try {
      user = data.data;
      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        "record-count": data.meta["record-count"],
        "page-count": data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    data: user,
    error: error || customError,
    isLoading,
    pageMeta,
    refetch,
  };
};

export default useListUsers;
