import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import CardHeader from "@components/CardHeader/CardHeader";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import RouterButton from "@components/Buttons/RouterButton/RouterButton";
import { useNavigate } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import {
  capitalizeFirstLetter,
  capitalizeWords,
  replaceUnderscoresWithSpaces,
} from "@utils/helperFunctions";
import CurrencyFormat from "react-currency-format";

function ProjectPartnerRequests({
  account_slug,
  project_id,
  projectStatus,
  partnerRequests,
  partnerRequestId,
  account_id,
  partnerRequestCreated,
  partnerRequestUpdated,
  setPartnerRequestId,
  partners,
  currencyUnit,
  isLoading,
}) {
  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showUnauthorizedAlert, setShowUnauthorizedAlert] = useState(false);

  useEffect(() => {
    if (partnerRequestCreated === true) {
      setCreatedAlertIsOpen(true);
    }
  }, [partnerRequests, partners, isLoading]);

  const getTableData = () => {
    if (partnerRequests.length === 0) {
      return [];
    }
    // Map over partnerRequests & return data for ScopeStackTable
    return partnerRequests.map((partnerRequest, index) => {
      const partnerRequestId = partnerRequest.id;
      let partner = "";

      const redirectToEditPartnerRequest = () => {
        setPartnerRequestId(partnerRequestId);
        return navigate(
          `/projects/${project_id}/third_party_requests/${partnerRequestId}/edit`
        );
      };

      for (let i = 0; i < partners.length; i++) {
        let partnerData = partnerRequest.relationships.subcontractor.data;
        if (partnerData && partners[i].id == partnerData.id) {
          partner = partners[i].attributes.name;
        }
      }

      // Map returns a table row for each Partner Request
      let status = partnerRequest.attributes.status;
      return {
        rowClass:
          status === "offered" || status === "complete"
            ? "partnerRequestsRow"
            : "partnerRequestsRow noRow",
        onClick:
          status === "offered" || status === "complete"
            ? redirectToEditPartnerRequest
            : null,
        columns: [
          {
            class: "",
            name: partner,
          },
          {
            class: "",
            name: partnerRequest.attributes.description,
          },
          {
            class: "",
            name: partnerRequest.attributes.status.includes("_")
              ? capitalizeWords(
                  replaceUnderscoresWithSpaces(partnerRequest.attributes.status)
                )
              : capitalizeFirstLetter(partnerRequest.attributes.status),
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={
                  parseFloat(partnerRequest.attributes.cost).toFixed(2)
                    ? parseFloat(partnerRequest.attributes.cost).toFixed(2)
                    : parseFloat(0).toFixed(2)
                }
              />
            ),
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={
                  partnerRequest.attributes["recurring-cost"]
                    ? parseFloat(
                        partnerRequest.attributes["recurring-cost"]
                      ).toFixed(2)
                    : parseFloat(0).toFixed(2)
                }
              />
            ),
          },
          {
            class: "text-right",
            name: parseFloat(partnerRequest.attributes.markup).toFixed(2)
              ? parseFloat(partnerRequest.attributes.markup).toFixed(2) + "%"
              : parseFloat(0).toFixed(2) + "%",
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={
                  parseFloat(partnerRequest.attributes.price).toFixed(2)
                    ? parseFloat(partnerRequest.attributes.price).toFixed(2)
                    : parseFloat(0).toFixed(2)
                }
              />
            ),
          },
          {
            class: "text-right",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={
                  parseFloat(
                    partnerRequest.attributes["recurring-price"]
                  ).toFixed(2) &&
                  parseFloat(
                    partnerRequest.attributes["recurring-price"]
                  ).toFixed(2) !== "NaN"
                    ? parseFloat(
                        partnerRequest.attributes["recurring-price"]
                      ).toFixed(2)
                    : parseFloat(0).toFixed(2)
                }
              />
            ),
          },
        ],
        actions: null,
      };
    });
  };

  const getFooterData = () => {
    let content = "";
    if (projectStatus === "building") {
      content = (
        <RouterButton
          path={`/projects/${project_id}/third_party_requests/new`}
          className="newResource"
          iconClass="fa fa-plus"
          buttonText="Add Partner Request"
        />
      );
    }
    return {
      rowClass: "col-12",
      columns: [
        { class: "partnerRequestsFooter", content: content, colSpan: "12" },
      ],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Partner",
      },
      {
        class: "",
        name: "Description",
      },
      {
        class: "",
        name: "Status",
      },
      {
        class: "text-right",
        name: "One Time Cost",
      },
      {
        class: "text-right",
        name: "Recurring Cost",
      },
      {
        class: "text-right",
        name: "Markup",
      },
      {
        class: "text-right",
        name: "One Time Revenue",
      },
      {
        class: "text-right",
        name: "Recurring Revenue",
      },
    ],
  };
  const tableData = getTableData();
  const footerData = getFooterData();

  let partnerRequestsTable = new ScopeStackTable(
    "Partner Requests",
    headerData,
    tableData,
    footerData
  );

  const detailsCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="partnerRequestsAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Partner Request created"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text="Something went wrong! Some of your changes could not be saved at this time."
        />
      );
    }
  };

  return (
    <div className="col-sm-12" id="sow-workarea">
      <div className="tabContent">
        {detailsCreatedAlert()}
        {handleFailAlert()}
        <Card>
          <CardHeader title="Partner Request" />
          <Card.Body>
            <BaseTable
              className="scopestack partnerRequestsTable"
              striped={true}
              hover={true}
              bordered={false}
              headerRows={partnerRequestsTable.buildHeaderRows()}
              dataRows={partnerRequestsTable.buildDataRows()}
              footerRows={partnerRequestsTable.buildFooterRows()}
              isLoading={isLoading}
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default ProjectPartnerRequests;
