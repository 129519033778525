import API from "../../../../../../utils/API/API";

const NewSurvey = async ({ questionnaire, responses, account, surveyId, projectId }) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const url = new URL(
    `/${account.slug}/v1/surveys${surveyId ? "/" + surveyId : ""}`,
    apiHost
  );

  const payload = {
    data: {
      type: "surveys",
      attributes: {
        name: questionnaire.attributes.name,
        responses,
      },
      relationships: {
        account: {
          data: {
            id: account.id,
            type: "accounts",
          },
        },
        questionnaire: {
          data: {
            id: questionnaire.id,
            type: questionnaire.type,
          },
        },
      },
    },
  };

  if (surveyId) {
    payload.data.id = surveyId;
  }

  if (projectId) {
    payload.data.relationships["project"] = {
      data: {
        id: projectId,
        type: "projects",
      },
    };
  }

  try {
    let response;
    if (surveyId) {
      response = await API.Put(
        url.toString(),
        payload,
        account.authorizationCode
      );
    } else {
      response = await API.Post(
        url.toString(),
        payload,
        account.authorizationCode
      );
    }

    return { data: response.data.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export default NewSurvey;
