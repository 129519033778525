import API from "../../../utils/API/API";

const NewSurvey = async ({
  questionnaire,
  responses,
  account,
  surveyId,
  accessToken,
  projectId,
}) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const url = new URL(
    `/${account.data.attributes["account-slug"]}/v1/surveys${
      surveyId ? "/" + surveyId : ""
    }`,
    apiHost
  );

  if (responses) {
    responses = responses.map((res) => {
      if (res["survey-response-id"] === null) {
        delete res["survey-response-id"];
      }
      return res;
    });
  }

  const payload = {
    data: {
      type: "surveys",
      id: undefined,
      attributes: {
        name: questionnaire.attributes.name,
        responses,
      },
      relationships: {
        account: {
          data: {
            id: account.data.attributes["account-id"],
            type: "accounts",
          },
        },
        questionnaire: {
          data: {
            id: questionnaire.id,
            type: "questionnaires",
          },
        },
      },
    },
  };

  if (surveyId) {
    payload.data.id = surveyId;
  }

  if (projectId) {
    payload.data.relationships["project"] = {
      data: {
        id: projectId,
        type: "projects",
      },
    };
  }

  try {
    let response;
    if (surveyId) {
      response = await API.Put(url.toString(), payload, accessToken);
    } else {
      response = await API.Post(url.toString(), payload, accessToken);
    }

    return { data: response.data.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export default NewSurvey;
