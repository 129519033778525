import React from "react";
import { Col } from "react-bootstrap";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import "./SubmitAndScopeBlueprintBtns.css";

function SubmitAndScopeBlueprintBtns({ onClick, id, submitDisabled, scopeDisabled }) {
  return (
    <Col>
      <SeafoamButton
        id={id}
        text="Save"
        className="submitNewProjectBtn"
        type="submit"
        disabled={submitDisabled}
      />
      <SeafoamButton
        onClick={onClick}
        type="submit"
        className="scopeBlueprintBtn"
        text="Start Scoping from Blueprint"
        disabled={scopeDisabled}
      />
    </Col>
  );
}

export default SubmitAndScopeBlueprintBtns;
