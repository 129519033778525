import { useListPhasesForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";

interface Phase {
  label: string;
  value: string;
}

const useListPhasesForAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListPhasesForAccountQuery({
      slug: accountSlug,
      filter: { active: true },
    });

  let phases: Phase[] = [];

  if (data?.data) {
    try {
      phases = data.data.map((phase, i) => {
        if (!phase.id) {
          throw new Error("Missing phase.id");
        }
        if (!phase.attributes?.name) {
          throw new Error("Missing phase.attributes.name");
        }

        return {
          value: String(phase.id),
          label: phase.attributes.name || "",
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    phases,
    phasesLoading: isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListPhasesForAccount;
