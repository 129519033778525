import API from "@API";

const loadRateType = async ({ account_slug, authorizationCode }) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const url = new URL(`/v1/accounts/${account_slug}`, apiHost);

  try {
    const response = await API.Get(url.toString(), authorizationCode);

    return {
      rate: response.data.data.attributes["rate-type"],
      error: null,
    };
  } catch (error) {
    return { data: null, error };
  }
};

export default loadRateType;
