import { useEffect } from 'react';

// This tool warns the user to save their changes before leaving the page
// To use it in your page 
// import useUnloadWarning from @utils/customHooks/useUnloadWarning

// const [isFormDirty, setFormDirty] = useState(false);

// useUnloadWarning(isFormDirty);

// const handleFormChange = (event) => {
//     // Handle form input change and set isFormDirty accordingly
//     setFormDirty(true);
//   };

// On form object set onChange={handleFormChange}
// On handleSubmit add setFormDirty(false);

const useUnloadWarning = (isFormDirty) => {
  const handleBeforeUnload = (event) => {
    if (isFormDirty) {
      event.preventDefault();
      event.returnValue = 'Changes you made may not be saved';
    }
  };

  const handleUnload = () => {
    alert('Changes you made may not be saved');
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [isFormDirty]);
};

export default useUnloadWarning;