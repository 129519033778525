import { faLoveseat } from "@fortawesome/pro-solid-svg-icons";
import {
  ListResourceRatesForAccountApiArg,
  V1ResourceRateResource,
  V1ResourceResource,
  useListResourceRatesForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";

export interface HookArgs {
  resources: V1ResourceResource[];
  resourcesFromResourceRates: boolean;
  filter: ListResourceRatesForAccountApiArg["filter"];
}

const useResourceRates = ({
  resources = [],
  resourcesFromResourceRates,
  filter
}: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  if (resourcesFromResourceRates) {
    const {
      data: resourceRates,
      error: resourceRatesError,
      isLoading: resourceRatesLoading,
      refetch: resourceRatesRefetch,
    } = useListResourceRatesForAccountQuery({
      slug: accountSlug,
      page: { size: 1000 },
      include: ["line-of-business"],
      filter,
    });

    let resourcesToReturn = new Set();
    let customError: string | null = null;
    let pageMeta = {
      recordCount: 0,
      pageCount: 0,
    };

    if (resourceRates?.data) {
      try {
        resourceRates?.data.forEach((resourceRate: V1ResourceRateResource) => {
          if (!resourceRate.id) {
            throw new Error("Missing resourceRate.id");
          }

          if (resources.length > 0) {
            resources.forEach((resource) => {
              if (
                resource.id == resourceRate?.relationships?.resource?.data?.id
              ) {
                resourcesToReturn.add(resourceRate);
              }
            });
          }
        });

        if (!resourceRates?.meta) {
          throw new Error("Missing data.meta");
        }

        if (!resourceRates?.meta["record-count"]) {
          throw new Error('Missing data.meta["record-count"]');
        }

        if (!resourceRates?.meta["page-count"]) {
          throw new Error('Missing data.meta["page-count"]');
        }
        pageMeta = {
          recordCount: resourceRates?.meta["record-count"],
          pageCount: resourceRates?.meta["page-count"],
        };
      } catch (err) {
        if (err instanceof Error) {
          customError = err.message;
        } else {
          customError = "An unknown error occurred";
        }
      }
    }

    return {
      resourceRates: Array.from(resourcesToReturn),
      resourceRatesError: resourceRatesError || customError,
      resourceRatesLoading: resourceRatesLoading,
      pageMeta,
      resourceRatesRefetch: resourceRatesRefetch,
    };
  } else {
    return {
      resourceRates: [],
      resourceRatesError: null,
      resourceRatesLoading: false,
      pageMeta: {
        recordCount: 0,
        pageCount: 1,
      },
      resourceRatesRefetch: () => [],
    };
  }
};

export default useResourceRates;
