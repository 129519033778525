import React from "react";
import { Button } from "react-bootstrap";

function SeafoamButton({ onClick, type, className, disabled, text, style }) {
  return (
    <Button
      onClick={onClick}
      type={type}
      className={className}
      variant="primary"
      disabled={disabled}
      style={style}
    >
      {text}
    </Button>
  );
}

export default SeafoamButton;
