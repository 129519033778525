import { css } from "@emotion/css";
import useTheme from "@common/hooks/useTheme";

const useStyles = () => {
  const theme = useTheme();

  const card = css`
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray["2"]};
    background: ${theme.colors.white};
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    padding: 1.25em 1em;
  `;

  const cardActionButtons = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const cardActionButtonsGroup = css`
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  `;

  const cardBody = css`
    text-align: center;
    margin-top: 1rem;
    h3 {
      ${theme.typography.h3}
      color: ${theme.colors.navy["1"]};
    }
    p {
      ${theme.typography.p}
      color: ${theme.colors.navy["1"]};
    }
  `;

  const cardIcon = css`
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
  `;

  const cardIconClickable = css`
    cursor: pointer;
    color: ${theme.colors.teal["1"]};
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  const activeCount = css`
    text-align: center;
  `;

  const cardIconCircled = css`
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
    border-radius: 50%;
    border: 4px solid ${theme.colors.teal["1"]};
    padding: 0.5rem;
    color: ${theme.colors.teal["1"]};
    margin-right: 1rem;
  `;

  const cardHeader = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      ${theme.typography.h2}
      margin: unset;
      color: ${theme.colors.navy["1"]};
    }
  `;

  const cardTitle = css`
    display: flex;
    align-items: center;
  `;

  const cardGlance = css`
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 5px;
    background: ${theme.colors.gray["6"]};
    max-width: 32.5%;
    h1 {
      color: ${theme.colors.teal["1"]};
      font-family: Open Sans;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  `;

  const glanceContainer = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;

  const line = css`
    background-color: ${theme.colors.gray["2"]};
    margin: 1rem 0.5r;
    width: 100%;
  `;

  const tableHeader = css`
    color: ${theme.colors.navy["1"]};
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `;

  const title = css`
    ${theme.typography.h1}
    color: ${theme.colors.navy["1"]};
  `;

  const recentProjectsHeader = css`
    text-align: left;
  `;

  const row = css`
    display: flex;
    gap: 1rem;
  `;

  const wrapper = css`
    margin: 1rem;
    .row,
    .col {
      margin: 0;
    }
    .col {
      padding: 0;
    }
  `;

  const nextSteps = css`
    ${card};
    color: inherit;
    text-decoration: none;
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    h2 {
      ${theme.typography.h2}
      margin: unset;
      color: ${theme.colors.navy["1"]};
    }
    p {
      margin: unset;
    }
    &:hover {
      background-color: ${theme.colors.gray["2"]};
      color: inherit;
      text-decoration: none;
    }
  `;

  const recentProjectButtonWrapper = css`
    display: flex;
    justify-content: space-between;
  `;

  const upsell = css`
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: ${theme.colors.teal["1"]};
    color: ${theme.colors.white};
    margin-bottom: 1rem;
  `;

  const upsellIcon = css`
    margin-right: 0.5em;
  `;

  const upsellTextWrap = css`
    display: flex;
    align-items: center;
  `;

  return {
    card,
    cardActionButtons,
    cardActionButtonsGroup,
    cardBody,
    cardIcon,
    cardIconClickable,
    cardIconCircled,
    cardTitle,
    cardGlance,
    cardHeader,
    line,
    tableHeader,
    title,
    wrapper,
    recentProjectsHeader,
    row,
    nextSteps,
    glanceContainer,
    recentProjectButtonWrapper,
    upsell,
    upsellIcon,
    upsellTextWrap,
    activeCount,
  };
};

export default useStyles;
