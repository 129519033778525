import React from "react";
import { Form } from "react-bootstrap";
import FormFieldLabel from "./FormFieldLabel/FormFieldLabel";

function ControlledDropDown({
  label,
  value,
  defaultValue,
  options,
  onChange,
  required,
  readOnly,
  disabled,
  fieldFooter,
}) {
  return (
    <Form.Group className="formGroup" controlId="formGridState">
      <FormFieldLabel label={label} />
      <Form.Control
        as="select"
        value={value === null ? "" : value}
        defaultValue={defaultValue}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        onChange={onChange}
      >
        {options}
      </Form.Control>
      {fieldFooter !== null ? <small>{fieldFooter}</small> : null}
    </Form.Group>
  );
}

export default ControlledDropDown;
