import React from "react";
import Outcome from "../Outcome/Outcome";

function EditOutcome({
  account_slug,
  project_id,
  projectStatus,
  outcomes,
  outcomeId,
  account_id,
  setOutcomeUpdated,
  authorizationCode,
}) {
  return (
    <Outcome
      account_slug={account_slug}
      project_id={project_id}
      projectStatus={projectStatus}
      outcomes={outcomes}
      outcomeId={outcomeId}
      account_id={account_id}
      setOutcomeUpdated={setOutcomeUpdated}
      authorizationCode={authorizationCode}
    />
  );
}

export default EditOutcome;
