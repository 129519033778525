import React from "react";
import { Form } from "react-bootstrap";
import FormFieldLabel from "./FormFieldLabel/FormFieldLabel";

function MultipleLineField({
  id,
  label,
  rows,
  placeholder,
  value,
  defaultValue,
  onChange,
  onBlur,
  required,
  disabled = false,
}) {
  return (
    <Form.Group className="mb-3 formGroup">
      <FormFieldLabel label={label} />
      <Form.Control
        required={required}
        id={id}
        as="textarea"
        rows={rows}
        value={value === null ? "" : value}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Form.Group>
  );
}

export default MultipleLineField;
