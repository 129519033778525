import React from "react";
import { Row, Col, Button } from "react-bootstrap";

function Footer({
  rightbuttonType,
  leftButtonType,
  disabledLeftButton,
  disabledRightButton,
  leftButtonOnClick,
  rightButtonOnClick,
  rightButtonText,
  leftButtonText,
  leftButtonClass,
  rightButtonClass,
  leftButtonVariant,
  rightButtonVariant,
  footerClass,
  firstColLength,
  secondColLength,
}) {
  return (
    <div className={footerClass}>
      <Row>
        <Col sm={firstColLength}>
          {leftButtonText ? (
            <Button
              onClick={leftButtonOnClick}
              variant={leftButtonVariant}
              type={leftButtonType}
              className={leftButtonClass}
              disabled={disabledLeftButton}
            >
              {leftButtonText}
            </Button>
          ) : null}
        </Col>
        <Col sm={secondColLength}>
          {rightButtonText ? (
            <Button
              variant={rightButtonVariant}
              className={rightButtonClass}
              type={rightbuttonType}
              onClick={rightButtonOnClick}
              disabled={disabledRightButton}
            >
              {rightButtonText}
            </Button>
          ) : null}
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
