import React, { useState, useEffect } from "react";
import NavTabs from "@components/NavTabs/NavTabs";
import VendorQuote from "../VendorQuote/VendorQuote";
import API from "@API";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";

function EditVendorQuote({
  account_slug,
  project_id,
  projectStatus,
  vendorQuotes,
  vendorQuoteId,
  account_id,
  setVendorQuoteUpdated,
  vendors,
  linesOfBusiness,
  thirdPartyServices,
  deriveFromServices,
  setDeriveFromServices,
  authorizationCode,
}) {
  // Env
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  const [quoteId, setQuoteId] = useState(null);
  const [services, setServices] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Get third-party-services
    setServices(thirdPartyServices);

    if (authorizationCode !== null) {
      // Get third party services
      API.Get(
        `${apiHost}/${account_slug}/v1/third-party-services?filter[source-type]=Quote&filter[source-id]=${id}&include=project-phase,subtasks`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        if (data !== null) {
          setServices(data);
        }
        setIsLoading(false);
      });
    }

    setQuoteId(id);
  }, [thirdPartyServices, authorizationCode]);

  return (
    <div>
      <NavTabs
        tabOneText="Vendor Quote"
        tabOneUrl={`/projects/${project_id}/quotes/${quoteId}/edit`}
        tabTwoText="Vendor Description"
        tabTwoUrl={
          services.length !== 0
            ? `/projects/${project_id}/quotes/${quoteId}/quoted_service`
            : `/projects/${project_id}/quotes/${quoteId}/quoted_service/new`
        }
        tabThreeText="Our Description"
        tabThreeUrl={`/projects/${project_id}/quotes/${quoteId}/third_party_services`}
        hasUnsavedChanges={hasUnsavedChanges}
      />
      <div className="col-sm-12" id="sow-workarea">
        <div className="tabContent">
          {isLoading ? (
            <SmallSpinner />
          ) : (
            <VendorQuote
              account_slug={account_slug}
              project_id={project_id}
              projectStatus={projectStatus}
              vendorQuotes={vendorQuotes}
              vendorQuoteId={vendorQuoteId}
              account_id={account_id}
              setVendorQuoteUpdated={setVendorQuoteUpdated}
              vendors={vendors}
              linesOfBusiness={linesOfBusiness}
              deriveFromServices={deriveFromServices}
              setDeriveFromServices={setDeriveFromServices}
              hasUnsavedChanges={hasUnsavedChanges}
              setHasUnsavedChanges={(bool) => setHasUnsavedChanges(bool)}
              authorizationCode={authorizationCode}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EditVendorQuote;
