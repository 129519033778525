import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import useOauth from "@utils/customHooks/useOauth";
import { setAccountSlug } from "../../slices/slug";
import { setCredentials } from "../../slices/auth";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

export interface AuthorizationContextType {
  authorized: boolean;
  isTokenAvailable: boolean;
}

const AuthorizationContext =
  createContext<AuthorizationContextType | null>(null);

export const useAuthorization = () => {
  return useContext(AuthorizationContext);
};

export const AuthorizationProvider = ({ children, account_slug }) => {
  const [accessToken] = useOauth();
  const dispatch = useDispatch();
  const authorized = useSelector((state: RootState) => state.auth.authorized);

  const [isTokenAvailable, setIsTokenAvailable] = useState(false);

  useEffect(() => {
    if (authorized) {
      setIsTokenAvailable(true);
    }
  }, [authorized]);

  useEffect(() => {
    dispatch(setAccountSlug(account_slug));

    if (accessToken) {
      dispatch(setCredentials({ token: accessToken, authorized: true }));
    }
  }, [accessToken, dispatch]);

  const value = {
    authorized,
    isTokenAvailable,
  };

  return (
    <AuthorizationContext.Provider value={value}>
      {isTokenAvailable ? (
        children
      ) : authorized === false ? (
        <div>Not Authorized</div>
      ) : (
        <ScopeStackSpinner />
      )}
    </AuthorizationContext.Provider>
  );
};

export default AuthorizationProvider;
