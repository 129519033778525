import React from "react";
import KPI from "./KPI";

function EditPerformanceIndicator({
  account_slug,
  project_id,
  projectStatus,
  outcomes,
  account_id,
  indicators,
  setIndicatorCreated,
  statuses,
  authorizationCode,
}) {
  return (
    <KPI
      account_slug={account_slug}
      project_id={project_id}
      projectStatus={projectStatus}
      outcomes={outcomes}
      account_id={account_id}
      indicators={indicators}
      setIndicatorCreated={setIndicatorCreated}
      statuses={statuses}
      authorizationCode={authorizationCode}
    />
  );
}

export default EditPerformanceIndicator;
