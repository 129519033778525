import { ChangeEvent, FC, useRef } from "react";
import { Button } from "@components/Button";

interface FileUploadProps {
  onFileSelect: (file: File) => void;
  children: React.ReactNode;
  buttonVariant?: "primary" | "secondary";
  acceptedFileTypes?: string;
  maxFileSize?: number; // Maximum file size in bytes
}

const FileUpload: FC<FileUploadProps> = ({
  onFileSelect,
  children,
  buttonVariant = "primary",
  acceptedFileTypes = "image/*",
  maxFileSize = 5242880, // Default max size of 5MB
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      if (maxFileSize && files[0].size > maxFileSize) {
        alert(`File size should not exceed ${maxFileSize / 1024 / 1024} MB`);
        return;
      }
      onFileSelect(files[0]);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileInput}
        style={{ display: "none" }}
        accept={acceptedFileTypes}
      />
      <Button variant={buttonVariant} onClick={handleClick}>
        {children}
      </Button>
    </div>
  );
};

export default FileUpload;
