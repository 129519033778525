import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import AutoCompleteText from "@components/Forms/AutoCompleteText/AutoCompleteText";
import FormTextField from "@components/Forms/FormTextField";
import AddressForm from "@components/Forms/AddressForm";
import DropDownField from "@components/Forms/DropDownField";
import "./ProjectOverview.css";
import IconButton from "@components/Buttons/IconButton/Iconbutton";
import IconTextButton from "@components/Buttons/IconTextButton/IconTextButton";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import {
  capitalizeFirstLetter,
  formatDate,
  snakeToPascalCase,
  splitPascalCase,
  formatUnprocessibleResponse,
  removeDuplicates,
  handleAlert,
} from "@utils/helperFunctions";
import API from "@API";
import SubmitAndRequestBtns from "./ProjectButtons/SubmitAndRequestBtns";
import CreatePsaBtn from "./ProjectButtons/CreatePsaBtn";
import CreateSalesOrderBtn from "./ProjectButtons/CreateSalesOrderBtn";
import CreateProjectBlueprintBtn from "./ProjectButtons/CreateProjectBlueprintBtn";
import BackButton from "@components/Buttons/BackButton/BackButton";
import RescopeBtn from "./ProjectButtons/RescopeBtn";
import OfferServicesBtn from "./ProjectButtons/OfferServicesBtn";
import CancelApprovalRequestBtn from "./ProjectButtons/CancelApprovalRequestBtn";
import DisabledTextField from "@components/Forms/DisabledTextField";
import useOauth from "@utils/customHooks/useOauth";
import NewProjectHeader from "./NewProjectHeader/NewProjectHeader";
import SubmitAndScopeBlueprintBtns from "./ProjectButtons/SubmitAndScopeBlueprintBtns/SubmitAndScopeBlueprintBtns";
import ProjectVariables from "../../ProjectVariables/ProjectVariables";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import FormDateField from "@components/Forms/FormDateField";
import {
  getUpdatedProjectVariables,
  getUpdatedCrm,
  getUpdatedPaymentTerm,
  getUpdatedPresalesEngineer,
  getUpdatedRateTable,
  getUpdatedBusinessUnit,
  upsertClientId,
  upsertProjectLocation,
  upsertSalesExecutiveId,
  findResourceByName,
  createResourceByName,
  newResourceByName,
  validateLocation,
  validateVariables,
  handleCharacterAlert,
  handleFailAlert,
  handleLocationAlert,
  handleUserExistsAlert,
} from "../projectUtils/projectHelperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import useUnloadWarning from "@utils/customHooks/useUnloadWarning";
const crmAlert = require("../../../images/error-message-white.png");
import Select from "react-select";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import ControlledDropDown from "@components/Forms/ControlledDropDown";

function ProjectOverview({
  account_slug,
  project_id,
  account_id,
  crmFlag,
  reactProjectHeaderFlag,
  sales_exec_required_flag,
  hide_msa_date_flag,
}) {
  // Set the states for our data
  const [projectData, setProjectData] = useState({});
  const [project, setProject] = useState([]);
  const [projectStatus, setProjectStatus] = useState("");
  const [projectName, setProjectName] = useState(null);
  const [unrequiredProjectVariables, setUnrequiredProjectVariables] = useState(
    []
  );
  const [requiredProjectVariables, setRequiredProjectVariables] = useState([]);
  const [requiredLocationVariables, setRequiredLocationVariables] = useState(
    []
  );
  const [unrequiredLocationVariables, setUnrequiredLocationVariables] =
    useState([]);
  const [userExistsAlertIsOpen, setUserExistsAlertIsOpen] = useState(false);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  // Client States
  const [clientList, setClientList] = useState([]);
  var chosenClient = "";
  const [newClientId, setNewClientId] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientName, setClientName] = useState("");
  const [userTypedClient, setUserTypedClient] = useState(false);
  const [msaDate, setMsaDate] = useState(null);

  // Payment Term states
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [currentPaymentTerm, setCurrentPaymentTerm] = useState("");
  const [newPaymentTermId, setNewPaymentTermId] = useState(null);

  // Rate table states
  const [rateTables, setRateTables] = useState([]);
  const [currentRateTable, setCurrentRateTable] = useState("");
  const [newRateTableId, setNewRateTableId] = useState(null);

  // Location States
  const [projectLocations, setProjectLocations] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [addressChanged, setAddressChanged] = useState(false);
  const [customerSite, setCustomerSite] = useState("");
  const [remoteSite, setRemoteSite] = useState(false);
  const [address, setAddress] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState("");

  //Crm States
  const [crmState, setCrmState] = useState([]);
  const [crmId, setCrmId] = useState("");
  const [currentCrmOpporutnity, setCurrentCrmOpportunity] = useState("");
  const [newCrmId, setNewCrmId] = useState(null);
  const [showCrmWarning, setShowCrmWarning] = useState(false);

  //Sales exec states
  const [salesExecutives, setSalesExecutives] = useState([]);
  const [salesExecs, setSalesExecs] = useState([]);
  const [newSalesExecId, setNewSalesExecId] = useState(null);
  const [currentSalesExec, setCurrentSalesExec] = useState("");
  var chosenSalesExec = "";
  const [userTypedSalesExec, setUserTypedSalesExec] = useState(false);

  // Presales states
  const [presales, setPresales] = useState([]);
  const [currentPresalesEngineer, setCurrentPresalesEngineer] = useState("");
  const [newPresalesEngineerId, setNewPresalesEngineerId] = useState(null);

  // Business Unit States
  const [businessUnits, setBusinessUnits] = useState([]);
  const [currentBusinessUnit, setCurrentBusinessUnit] = useState("");
  const [newBusinessUnitId, setNewBusinessUnitId] = useState(null);

  const [externalRequestId, setExternalRequestId] = useState("");
  const [externalRequestStatus, setExternalRequestStatus] = useState("");
  const [psaState, setPsaState] = useState("");
  const [submitUrl, setSubmitUrl] = useState("");

  // Project Tags States
  const [tags, setTags] = useState("");

  // Alerts, labels, api, env
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successText, setSuccessText] = useState("Project changes saved");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [submitFail, setSubmitFail] = useState(false);
  const [fieldLabels, setFieldLabels] = useState({
    presales_engineer: "Pre-Sales Engineer",
    sales_executive: "Sales Executive",
    business_unit: "Business Unit",
    location: "Service Location",
    client: "Client",
  });
  const [scopeBlueprintButtonClicked, setScopeBlueprintButtonClicked] =
    useState(false);

  const [authorizationCode] = useOauth();

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  const editProjectLocation = `${appHost}/projects/${project_id}/edit`;
  const newProjectLocation = `${appHost}/projects/new`;

  const [showProjectCreatedAlert, setShowProjectCreatedAlert] = useState(false);
  const [showLocationAlert, setShowLocationAlert] = useState(false);
  const [locationErrors, setLocationErrors] = useState([]);

  const [showMinCharAlert, setShowMinCharAlert] = useState(false);
  const [showMaxCharAlert, setShowMaxCharAlert] = useState(false);

  const [isCrm, setIsCrm] = useState(null);
  const [isPsa, setIsPsa] = useState(null);

  const today = new Date();
  const [currentUser, setCurrentUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [showBlueprintCreatedAlert, setShowBlueprintCreatedAlert] =
    useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setFormDirty] = useState(false);
  useUnloadWarning(isFormDirty);

  // Current User states
  const [projectOverviewPrivilege, setProjectOverviewPrivilege] =
    useState(null);

  // When the page loads
  useEffect(() => {
    // If we are on the edit project page and we came from the new project page
    // (and the user has not refreshed the page), show the project created alert

    if (
      location !== newProjectLocation &&
      document.referrer === newProjectLocation &&
      performance.navigation.type !== 1
    ) {
      setShowProjectCreatedAlert(true);
    }

    if (
      location !== newProjectLocation &&
      document.referrer ===
        `${appHost}/projects/${project_id}/sow_blueprints/new` &&
      performance.navigation.type !== 1
    ) {
      setShowBlueprintCreatedAlert(true);
    }

    // Get all the data from the API that we need and put it into the appropriate state
    if (authorizationCode != "") {
      // Account information
      API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
        (response) => {
          const data = response.data.data;
          setIsCrm(data.attributes["crm?"]);
          setIsPsa(data.attributes["psa?"]);

          // Field Labels
          setFieldLabels(data.attributes["field-labels"]);

          // Crm opportunities for dropdown field
          API.Get(
            data.links["crm-opportunities"] + "?page[size]=500",
            authorizationCode
          ).then((response) => {
            setCrmState(response.data.data);
          });

          // Client list for typeahead field
          API.Get(
            data.links.clients + "?include=rate-table&page[size]=100",
            authorizationCode
          ).then((response) => {
            const data = response.data.data;
            const clients = data.map((client) => ({
              id: client.id,
              client: client.attributes.name,
              msa_date: client.attributes["msa-date"],
              relationships: client.relationships,
            }));
            if (clients.length !== 0) {
              setClients(clients);
              const clientNames = data.map((client) => client.attributes.name);
              setClientList(clientNames);
            }
          });

          // Sales executives
          API.Get(data.links["sales-executives"], authorizationCode).then(
            (response) => {
              const salesExecList = response.data.data;
              if (salesExecList.length !== 0) {
                const salesExecs = salesExecList.map((salesExec) => ({
                  id: salesExec.id,
                  salesExec: salesExec.attributes.name,
                }));
                setSalesExecs(salesExecs);

                const salesExecNames = salesExecList.map(
                  (obj) => obj.attributes.name
                );
                setSalesExecutives(salesExecNames);
              }
            }
          );

          // Payment Terms
          API.Get(data.links["payment-terms"], authorizationCode).then(
            (response) => {
              let paymentTerms = response.data.data;
              setPaymentTerms(paymentTerms);
            }
          );

          // Presales Engineers
          API.Get(
            data.links["presales-engineers"] + "?page[size]=500",
            authorizationCode
          ).then((response) => setPresales(response.data.data));

          // Business Units
          API.Get(data.links["business-units"], authorizationCode).then(
            (response) => {
              let businessUnits = response.data.data;
              if (businessUnits !== null) setBusinessUnits(businessUnits);
              if (businessUnits.length === 1) {
                setCurrentBusinessUnit(businessUnits[0].attributes.name);
                setNewBusinessUnitId(businessUnits[0].id);
              }
            }
          );

          // New Project Location
          if (location === newProjectLocation) {
            // Rate-table
            API.Get(
              data.links["rate-tables"] + "?page[size]=500",
              authorizationCode
            ).then((response) => {
              const rateTableData = response.data.data;
              setRateTables(
                rateTableData.filter(
                  (rateTable) => rateTable.attributes.active === true
                )
              );

              // Current User
              API.Get(
                `${apiHost}/${account_slug}/v1/users/current?include=rate-table`,
                authorizationCode
              ).then((response) => {
                const data = response.data;
                setCurrentUser(data);

                const userRateTable =
                  data.attributes["preferred-rate-table"].name;
                setCurrentRateTable(userRateTable);
                let [rateTable] = rateTableData.filter(
                  (rateTable) => rateTable.attributes.name === userRateTable
                );
                setNewRateTableId(rateTable.id);
              });
            });

            // Project Variables
            API.Get(
              data.links["project-variables"] +
                "?filter[variable-context]=project,service_location",
              authorizationCode
            ).then((response) => {
              let variables = response.data.data.map((pv) => {
                let attr = pv.attributes;
                let options = attr["select-options"];
                delete attr["select-options"];
                return { ...attr, select_options: options };
              });
              if (variables.length !== 0) {
                let reqProjectVariables = variables.filter((variable) => {
                  if (variable.required === true) {
                    return variable.required;
                  }
                });
                reqProjectVariables = reqProjectVariables.map((variable) => {
                  if (variable["variable-type"] === "date") {
                    return { ...variable, value: formatDate(today) };
                  } else {
                    return { ...variable, value: "" };
                  }
                });
                setRequiredProjectVariables(
                  reqProjectVariables.filter(
                    (variable) => variable["variable-context"] == "project"
                  )
                );
                setRequiredLocationVariables(
                  reqProjectVariables.filter(
                    (variable) =>
                      variable["variable-context"] == "service_location"
                  )
                );
                let unreqProjectVariables = variables.filter((variable) => {
                  if (variable.required === false) {
                    return variable;
                  }
                });
                unreqProjectVariables = unreqProjectVariables.map(
                  (variable) => {
                    return { ...variable, value: "" };
                  }
                );
                setUnrequiredProjectVariables(
                  unreqProjectVariables.filter(
                    (variable) => variable["variable-context"] == "project"
                  )
                );
                setUnrequiredLocationVariables(
                  unreqProjectVariables.filter(
                    (variable) =>
                      variable["variable-context"] == "service_location"
                  )
                );
              }
              setIsLoading(false);
            });
          } // End new project location if statement
        }
      ); // End Account API call

      // Get Current User with privelages
      API.Get(`${apiHost}/v1/me`, authorizationCode).then((response) => {
        let data = response.data.data;
        setProjectOverviewPrivilege(
          data.attributes.privileges.find(
            (privilege) => privilege.privilege === "projects.overview"
          )["access-level"]
        );
      });
    } // End authorizationCode if statement

    // Edit Project Location
    if (location !== newProjectLocation && authorizationCode != "") {
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=account,business-unit,external-request,project-locations,payment-term,client,rate-table`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        const includedData = response.data.included;
        setProject(data);
        const projectInfo = {
          links: data.links,
          status: data.attributes.status,
          name: data.attributes["project-name"],
          paymentTerm: "",
          rateTable: "",
          businessUnit: "",
          tags: data.attributes["tag-list"].join(","),
          clientName: "",
          msaDate: data.attributes["msa-date"],
          clientId: data.relationships.client.data.id,
          externalReqId:
            data.relationships["external-request"].data !== null
              ? data.relationships["external-request"].data.id
              : null,
          externalReqStatus: "",
          currentCrmOpporutnityUrl:
            data.relationships["crm-opportunity"].links.related,
          currentPaymentTermUrl:
            data.relationships["payment-term"].links.related,
          currentRateTableUrl: data.relationships["rate-table"].links.related,
          currentPresalesUrl:
            data.relationships["presales-engineer"].links.related,
          currentBusinessUnitUrl:
            data.relationships["business-unit"].links.related,
          currentSalesExecUrl:
            data.relationships["sales-executive"].links.related,
          clientListUrl: "",
          submitUrl: data.links.self,
          psaUrl: data.relationships["psa-project"].links.related,
          locationsUrl: data.relationships["project-locations"].links.related,
        };
        // Iterate over the included data & put it in the project info object
        for (let i = 0; i < includedData.length; i++) {
          includedData[i].type === "payment-terms"
            ? (projectInfo.paymentTerm = includedData[i].attributes.name)
            : null;
          includedData[i].type === "business-units"
            ? (projectInfo.businessUnit = includedData[i].attributes.name)
            : null;
          includedData[i].type === "clients"
            ? (projectInfo.clientName = includedData[i].attributes.name)
            : null;
          includedData[i].type === "external-requests"
            ? (projectInfo.externalReqId = includedData[i].id)
            : null;
          includedData[i].type === "external-requests"
            ? (projectInfo.externalReqStatus =
                includedData[i].attributes.status)
            : null;
        }
        setProjectData(projectInfo);
        if (projectInfo.businessUnit !== null)
          setCurrentBusinessUnit(projectInfo.businessUnit);

        // Project Name, Status, Request ID/Status & submit url
        if (projectInfo.name !== null) setProjectName(projectInfo.name);
        if (projectInfo.clientName !== null)
          setClientName(projectInfo.clientName);
        if (projectInfo.status !== null) setProjectStatus(projectInfo.status);
        if (projectInfo.externalReqId !== null)
          setExternalRequestId(projectInfo.externalReqId);
        if (projectInfo.externalReqStatus !== null)
          setExternalRequestStatus(projectInfo.externalReqStatus);
        if (projectInfo.submitUrl !== null) setSubmitUrl(projectInfo.submitUrl);
        if (projectInfo.tags !== null) setTags(projectInfo.tags);
        if (projectInfo.msaDate !== null) setMsaDate(projectInfo.msaDate);

        // Project Variables
        let projVariables = data.attributes["project-variables"];
        if (projVariables.length !== 0) {
          let reqProjVariables = projVariables.filter((variable) => {
            if (variable.required === true) {
              return variable;
            }
          });
          setRequiredProjectVariables(reqProjVariables);
          let unreqProjVariables = projVariables.filter((variable) => {
            if (variable.required === false) {
              return variable;
            }
          });
          setUnrequiredProjectVariables(unreqProjVariables);
        }

        // Current Sales Exec
        API.Get(projectInfo.currentSalesExecUrl, authorizationCode).then(
          (response) => {
            if (response.data.data) {
              setCurrentSalesExec(response.data.data.attributes.name);
            }
          }
        );

        // Current Presales Engineer
        API.Get(projectInfo.currentPresalesUrl, authorizationCode).then(
          (response) => {
            if (response.data.data !== null) {
              setCurrentPresalesEngineer(response.data.data.attributes.name);
            }
          }
        );

        // Current Payment Term
        API.Get(projectInfo.currentPaymentTermUrl, authorizationCode).then(
          (response) => {
            if (response.data.data !== null)
              projectInfo.paymentTerm = response.data.data.attributes.name;
          }
        );

        API.Get(
          `${apiHost}/${account_slug}/v1/rate-tables?page[size]=500`,
          authorizationCode
        ).then((response) => {
          let rateTables = response.data.data;
          let rateTableData = data.relationships["rate-table"].data;
          let newRateTables = [];
          for (let i = 0; i < rateTables.length; i++) {
            if (rateTables[i].attributes.active) {
              newRateTables.push(rateTables[i]);
            }
            if (rateTableData && rateTables[i].id === rateTableData.id) {
              newRateTables.push(rateTables[i]);
              setCurrentRateTable(rateTables[i].attributes.name);
            }
          }
          setRateTables(removeDuplicates(newRateTables, (item) => item.id));
        });

        // Locations
        API.Get(projectInfo.locationsUrl, authorizationCode).then(
          (response) => {
            const projectLocations = response.data.data;
            setProjectLocations(projectLocations);
            // If the array of project locations is empty, don't display the Manage Locations button, else render the first location
            const manageLocationsBtn = document.querySelector(
              ".manageLocationsBtn"
            );
            if (projectLocations.length === 0 && manageLocationsBtn) {
              manageLocationsBtn.setAttribute("style", "display: none;");
            } else if (projectLocations.length !== 0) {
              let locationVariables =
                projectLocations[0].attributes["project-variables"];
              setLocationId(projectLocations[0].id);
              setCustomerSite(projectLocations[0].attributes.name);
              setRemoteSite(projectLocations[0].attributes.remote);
              setAddress(projectLocations[0].attributes.street);
              setCountry(projectLocations[0].attributes.country);
              setCity(projectLocations[0].attributes.city);
              setState(projectLocations[0].attributes.state);
              setZip(projectLocations[0].attributes["postal-code"]);
              setCountry(projectLocations[0].attributes.country);
              setRequiredLocationVariables(
                locationVariables.filter((variable) => {
                  if (variable.required === true) {
                    return variable;
                  }
                })
              );
              setUnrequiredLocationVariables(
                locationVariables.filter((variable) => {
                  if (variable.required === false) {
                    return variable;
                  }
                })
              );
            }
            if (
              projectLocations.length !== 0 &&
              projectLocations[0].attributes.street2
            ) {
              setStreet2(projectLocations[0].attributes.street2);
            }
          }
        );

        // Current CRM Opportunity
        API.Get(projectInfo.currentCrmOpporutnityUrl, authorizationCode).then(
          (response) => {
            if (response.data.data !== null) {
              let id = response.data.data.id;
              setCrmId(id);
              let displayName = response.data.data.attributes["display-name"];
              setCurrentCrmOpportunity(displayName);
            }
          }
        );

        // PSA
        API.Get(projectInfo.psaUrl, authorizationCode).then((response) => {
          if (response) setPsaState(response.data.data);
        });
        setIsLoading(false);
      }); // End projects api call
    } //End edit project location if statement
  }, [authorizationCode]); // END USE EFFECT

  const renderProjectStatus = () => {
    if (
      projectStatus === "technical_approval" ||
      projectStatus === "sales_approval" ||
      projectStatus === "business_approval"
    ) {
      return <span>{splitPascalCase(snakeToPascalCase(projectStatus))}</span>;
    } else {
      return <span>{capitalizeFirstLetter(projectStatus)}</span>;
    }
  };

  // Render Page Header based on location (url)
  const renderHeader = () => {
    if (location !== newProjectLocation) {
      return (
        <Card.Header as="h5">
          <div className="fold"></div>
          Project Overview
          <div className="pull-right">Status: {renderProjectStatus()}</div>
        </Card.Header>
      );
    } else {
      return <NewProjectHeader />;
    }
  };

  // Conditionals for rendering blue buttons at the bottom of the card
  const renderButtons = () => {
    if (location === newProjectLocation) {
      return (
        <SubmitAndScopeBlueprintBtns
          onClick={() => setScopeBlueprintButtonClicked(true)}
        />
      );
    } else if (reactProjectHeaderFlag) {
      return (
        <div style={{ marginLeft: "16px" }}>
          <SubmitButton />
        </div>
      );
    }

    let buttons = [];
    if (projectStatus === "building" && projectOverviewPrivilege === "manage") {
      return (
        <SubmitAndRequestBtns
          label={fieldLabels["request_approval"]}
          onClick={(e) => {
            API.Put(
              projectData.links["request-approval"],
              project,
              authorizationCode
            )
              .then((res) => {
                if (res.status == 200) {
                  setProject(res.data.data);
                  setProjectStatus(res.data.data.attributes.status);
                  setSuccessText("The Project has been sent for approval");
                  setShowSuccessAlert(true);
                  setTimeout(() => {
                    setShowSuccessAlert(false);
                    window.location.reload();
                  }, 3000);
                }
              })
              .catch((err) => {
                setErrorMessage(formatUnprocessibleResponse(err, "Project"));
                setShowFailAlert(true);
                setSubmitFail(true);
              });
          }}
          key={0}
          accountSlug={account_slug}
          projectId={project_id}
        />
      );
    }
    if (
      projectStatus === "technical_approval" ||
      projectStatus === "sales_approval" ||
      projectStatus === "business_approval"
    ) {
      return (
        <CancelApprovalRequestBtn
          key={1}
          onClick={(e) => {
            API.Put(
              projectData.links["cancel-approval"],
              project,
              authorizationCode
            )
              .then((res) => {
                if (res.status == 200) {
                  setProject(res.data.data);
                  setProjectStatus(res.data.data.attributes.status);
                  setSuccessText("The Project approval has been canceled.");
                  setShowSuccessAlert(true);
                }
              })
              .catch((err) => {
                setErrorMessage(formatUnprocessibleResponse(err, "Project"));
                setShowFailAlert(true);
                setSubmitFail(true);
              });
          }}
        />
      );
    }

    let previouslyApproved =
      projectStatus === "won" ||
      projectStatus === "lost" ||
      projectStatus === "approved";

    if (
      previouslyApproved &&
      (externalRequestStatus === null ||
        externalRequestStatus !== "completed" ||
        externalRequestStatus !== "offered")
    ) {
      buttons.push(
        <RescopeBtn
          key={2}
          onClick={(e) => {
            API.Put(projectData.links["rescope"], project, authorizationCode)
              .then((res) => {
                if (res.status == 200) {
                  setProject(res.data.data);
                  setProjectStatus(res.data.data.attributes.status);
                  setSuccessText(
                    "The Project has been resubmitted for scoping"
                  );
                  setShowSuccessAlert(true);
                }
              })
              .catch((err) => {
                setErrorMessage(formatUnprocessibleResponse(err, "Project"));
                setShowFailAlert(true);
                setSubmitFail(true);
              });
          }}
        />
      );
    }

    if (
      previouslyApproved &&
      (externalRequestStatus === "accepted" ||
        externalRequestStatus === "change_request")
    ) {
      buttons.push(
        <OfferServicesBtn
          onClick={() => {
            API.Put(
              `${apiHost}/${account_slug}/v1/external-requests/${externalRequestId}/offer`,
              null,
              authorizationCode
            )
              .then((res) => {
                if (res.status == 200) {
                  setProject(res.data.data);
                  setProjectStatus(res.data.data.attributes.status);
                  setSuccessText(
                    `The Project has been offered to ${clientName}`
                  );
                  setShowSuccessAlert(true);
                  setTimeout(() => {
                    setShowSuccessAlert(false);
                    window.location.reload();
                  }, 2000);
                }
              })
              .catch((err) => {
                setErrorMessage(formatUnprocessibleResponse(err, "Project"));
                setShowFailAlert(true);
                setSubmitFail(true);
              });
          }}
          key={3}
        />
      );
    }
    if (previouslyApproved && psaState === null && isPsa === true) {
      buttons.push(
        <CreateSalesOrderBtn
          className={
            externalRequestStatus === null ||
            externalRequestStatus !== "completed" ||
            externalRequestStatus !== "offered"
              ? "noBorderRadius"
              : null
          }
          key={4}
          accountSlug={account_slug}
          projectId={project_id}
        />
      );
    }
    if (previouslyApproved && psaState === null && isPsa === true) {
      buttons.push(
        <CreatePsaBtn
          className={
            externalRequestStatus === null ||
            externalRequestStatus !== "completed" ||
            externalRequestStatus !== "offered"
              ? "noBorderRadius"
              : null
          }
          key={5}
          accountSlug={account_slug}
          projectId={project_id}
        />
      );
    }
    if (previouslyApproved) {
      buttons.push(
        <CreateProjectBlueprintBtn
          key={6}
          accountSlug={account_slug}
          projectId={project_id}
        />
      );
    }

    return (
      <>
        <Col></Col>
        <Col
          xs={() => {
            if (buttons.length === 1) return 3;
            if (buttons.length === 2) return 5;
            if (buttons.length === 3) return 6;
            if (buttons.length > 3) return 8;
          }}
        >
          <Row>{[buttons]}</Row>
        </Col>
      </>
    );
  };

  // Adjust border radius of btns depending on which ones render so they all appear to be one button
  const rescopeBtn = document.querySelector(".rescopeBtn");
  const offerServicesBtn = document.querySelector(".offerServicesBtn");

  if (!rescopeBtn && offerServicesBtn) {
    offerServicesBtn.setAttribute(
      "style",
      "border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem;"
    );
  }

  const getCrmOptions = (event) => {
    API.Get(
      `${apiHost}/${account_slug}/v1/crm-opportunities?filter[display-name]=${event}&page[size]=500`,
      authorizationCode
    ).then((response) => {
      let options = response.data.data;
      setCrmState(options);
    });
  };

  const renderCrmOptions = () => {
    let options = crmState.map((crmList) => {
      let id = crmList.id;
      let crm = crmList.attributes["display-name"];
      return { value: id, label: crm };
    });
    options.unshift({ value: -1, label: "--None--" });
    return options;
  };

  async function updateProject(projectData, authorizationCode) {
    setErrorMessage(null);

    API.Patch(submitUrl, projectData, authorizationCode)
      .then((response) => {
        setSuccessText("Project changes saved");
        setShowSuccessAlert(true);
        if (newCrmId) {
          setCurrentCrmOpportunity(
            crmState.find((option) => option.id == newCrmId)?.attributes?.[
              "display-name"
            ] || ""
          );
          if (!crmId) {
            setCrmId(newCrmId);
          }
        }
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 3000);
      })
      .catch((err) => {
        setErrorMessage(formatUnprocessibleResponse(err, "Project"));
        setShowFailAlert(true);
        setSubmitFail(true);
      });
  }

  const filterClientList = (clientName) => {
    return API.Get(
      `${apiHost}/${account_slug}/v1/clients?filter[active]=true&filter[name]=${encodeURIComponent(
        clientName
      )}&include=rate-table`,
      authorizationCode
    )
  };

  const handleCrmChange = (e) => {
    let crmsId = e.value;
    setNewCrmId(crmsId);
    setShowCrmWarning(false);

    if (crmsId === -1) {
      // document.getElementById("projectName").readOnly = false;
      document.getElementById("clientName").readOnly = false;
      setNewCrmId(null);
      setCurrentCrmOpportunity("");
    } else {
      for (let i = 0; i < crmState.length; i++) {
        if (crmState[i].id === crmsId) {
          let crmAttr = crmState[i].attributes;
          setProjectName(crmAttr.name);
          setClientName(crmAttr["account-name"]);
          filterClientList(crmAttr["account-name"]).then((res) => {
            const data = res.data.data;
            const clients = data.map((client) => ({
              id: client.id,
              client: client.attributes.name,
              msa_date: client.attributes["msa-date"],
              relationships: client.relationships,
            }));
            setClients(clients);
            const clientNames = data.map((client) => client.attributes.name);
            setClientList(clientNames);

            let clientExists = "";
            for (let i = 0; i < data.length; i++) {
              if (data[i].attributes.name === clientName) clientExists = data[i].id;
            }
            for (let j = 0; j < clients.length; j++) {
              if (crmAttr["account-name"] === clients[j].client) {
                setNewClientId(clients[j].id);
                setMsaDate(clients[j].msa_date);
              }
            }
          });

          if (
            location == newProjectLocation ||
            (location !== newProjectLocation && projectLocations.length <= 1)
          ) {
            let locationName = crmAttr["location-name"];
            if (locationName != null) {
              setCustomerSite(crmAttr["location-name"]);
            } else {
              setCustomerSite(crmAttr["account-name"]);
            }
            setAddress(crmAttr.street);
            if (crmAttr.street2) {
              setStreet2(crmAttr.street2);
            }
            setState(crmAttr.state);
            setCity(crmAttr.city);
            setZip(crmAttr["postal-code"]);
            if (crmAttr.country) {
              setCountry(crmAttr.country);
            }
            setAddressChanged(true);
          }
          let customAttributes = crmAttr["custom-attributes"];
          if (customAttributes !== null && customAttributes.length !== 0) {
            handleCustomAttributes(
              customAttributes,
              unrequiredProjectVariables,
              setUnrequiredProjectVariables
            );
            handleCustomAttributes(
              customAttributes,
              requiredProjectVariables,
              setRequiredProjectVariables
            );
          }
          if (crmAttr["owner-name"]) {
            setCurrentSalesExec(crmAttr["owner-name"]);
          }
        }
      }
      // document.getElementById("projectName").readOnly = true;
      document.getElementById("clientName").readOnly = true;
    }
  };

  const handleCustomAttributes = (
    customAttributes,
    variableArray,
    setVariableArray
  ) => {
    if (customAttributes !== null && customAttributes.length !== 0) {
      customAttributes.forEach((attr) => {
        if (attr.key === "sow_presales_engineer_id") {
          presales.forEach((eng) => {
            if (eng.attributes.name === attr.value) {
              setCurrentPresalesEngineer(attr.value);
              setNewPresalesEngineerId(eng.id);
            }
          });
        }
        if (attr.key === "client_manager_name") {
          upsertSalesExecutiveId(attr.value).then((salesExec) => {
            setNewSalesExecId(salesExec.id);
            setCurrentSalesExec(attr.value);
          });
        }
      });
      let newVariableArray = variableArray;

      for (let i = 0; i < newVariableArray.length; i++) {
        for (let j = 0; j < customAttributes.length; j++) {
          newVariableArray = newVariableArray.map((variable) =>
            variable.name === customAttributes[j].key
              ? { ...variable, value: customAttributes[j].value }
              : variable
          );
        }
      }
      setVariableArray(newVariableArray);
    }
  };

  const renderCrmOpportunity = () => {
    if (currentCrmOpporutnity !== "") {
      // document.getElementById("projectName").readOnly = true;
      document.getElementById("clientName").readOnly = true;
      return (
        <DisabledTextField
          value={currentCrmOpporutnity}
          label={`${fieldLabels["crm_opportunity"]} ${crmFlag ? "*" : ""}`}
        />
      );
    } else {
      return (
        <>
          <Form.Group style={{ marginBottom: "0px !important" }}>
            <Form.Label>{`${fieldLabels["crm_opportunity"]} ${
              crmFlag ? "*" : ""
            }`}</Form.Label>
            <Select
              placeholder={`Select ${fieldLabels["crm_opportunity"]}`}
              defaultValue={
                currentCrmOpporutnity === ""
                  ? { value: -1, label: "-- None --" }
                  : { value: crmId, label: currentCrmOpporutnity }
              }
              onChange={(e) => handleCrmChange(e)}
              onInputChange={(e) => getCrmOptions(e)}
              options={renderCrmOptions()}
              required={crmFlag}
            />
          </Form.Group>
          {(showCrmWarning || !newCrmId) && crmFlag && (
            <div className="crmWarning">
              <img
                src={crmAlert}
                alt="Please fill out this field error message"
              />
            </div>
          )}
        </>
      );
    }
  };

  const renderLocation = () => {
    if (projectLocations.length >= 2) {
      return null;
    } else {
      return (
        <>
          <AddressForm
            label={fieldLabels.location}
            onSiteChange={(e) => {
              setCustomerSite(e.target.value);
              setAddressChanged(true);
            }}
            siteName={customerSite}
            onAddressChange={(e) => {
              setAddress(e.target.value);
              setAddressChanged(true);
            }}
            remoteLocation={remoteSite}
            onRemoteChanged={(e) => {
              setRemoteSite(e.target.checked);
              setAddressChanged(true);
            }}
            addressText={address}
            onCityChange={(e) => {
              setCity(e.target.value);
              setAddressChanged(true);
            }}
            cityText={city}
            onStateChange={(e) => {
              setState(e.target.value);
              setAddressChanged(true);
            }}
            stateText={state}
            onZipChange={(e) => {
              setZip(e.target.value);
              setAddressChanged(true);
            }}
            zipText={zip}
            placeholder="Customer Site"
            street2Text={street2}
            onStreet2Change={(e) => {
              setStreet2(e.target.value);
              setAddressChanged(true);
            }}
            country={country}
            onCountryChange={(e) => {
              setCountry(e);
              setCountryId(e.id);
              setAddressChanged(true);
            }}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <ProjectVariables
              overridePadding={true}
              arr={requiredLocationVariables}
              account_slug={account_slug}
              project_id={project_id}
              setState={setRequiredLocationVariables}
              setVariableChanged={setAddressChanged}
            />
            <ProjectVariables
              overridePadding={true}
              arr={unrequiredLocationVariables}
              account_slug={account_slug}
              project_id={project_id}
              setState={setUnrequiredLocationVariables}
              setVariableChanged={setAddressChanged}
            />
          </div>
        </>
      );
    }
  };

  const renderLocationButtons = () => {
    if (location !== newProjectLocation) {
      let locationButtons = [];

      if (projectLocations.length === 1) {
        locationButtons.push(
          <Col key="addLocation">
            <IconTextButton
              url={`/projects/${project_id}/project_locations/new`}
              className="addLocation"
              iconClass="fa fa-plus"
              buttonText="Add Service Location"
            />
          </Col>
        );
      }
      if (projectLocations.length >= 1) {
        locationButtons.push(
          <Col key="manageLocations">
            <IconTextButton
              url={`/projects/${project_id}/project_locations`}
              className="addLocation manageLocationsBtn"
              iconClass="fa fa-edit"
              buttonText="Manage Service Locations"
            />
          </Col>
        );
      }
      return <>{[locationButtons]}</>;
    }
  };

  const handleFormChange = (event) => {
    setFormDirty(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.persist();

    if (!newCrmId && !crmId && crmFlag) {
      window.scrollTo(0, 0);
      setShowCrmWarning(true);
      return;
    }

    setFormDirty(false);

    // Project Variable Validation
    const allProjectVariables = requiredProjectVariables.concat(
      unrequiredProjectVariables
        .concat(requiredLocationVariables)
        .concat(unrequiredLocationVariables)
    );
    const projectVariablesAreValid = validateVariables(
      allProjectVariables,
      setMinValue,
      setMaxValue,
      setShowMinCharAlert,
      setShowMaxCharAlert
    );
    if (projectVariablesAreValid === false) {
      return;
    }

    // Location Validation
    const locationIsValid = validateLocation(
      addressChanged,
      customerSite,
      address,
      city,
      state,
      zip,
      remoteSite,
      setLocationErrors
    );
    if (locationIsValid === false) {
      setShowLocationAlert(true);
      return;
    }

    const buildProjectPromises = () => {
      let projectPromises = [];

      if (clientName !== "") {
        projectPromises.push(
          upsertClientId(
            clientName,
            account_slug,
            authorizationCode,
            account_id
          )
        );
      }
      if (currentSalesExec !== "") {
        projectPromises.push(
          upsertSalesExecutiveId(
            currentSalesExec,
            account_slug,
            authorizationCode,
            account_id
          )
        );
      }

      return projectPromises;
    };

    // ------Handle Submit for Editing Existing Project---------
    if (location !== newProjectLocation) {
      // Prepare project data
      let updatedProjectData = {
        data: {
          type: "projects",
          id: project_id,
          attributes: {
            "project-name": projectName,
            "project-variables":
              getUpdatedProjectVariables(allProjectVariables),
            "tag-list": tags.trim(),
            "msa-date": msaDate,
          },
          relationships: {
            account: { data: { type: "accounts", id: account_id } },
            "crm-opportunity": getUpdatedCrm(newCrmId),
            "payment-term": getUpdatedPaymentTerm(newPaymentTermId),
            "presales-engineer": getUpdatedPresalesEngineer(
              newPresalesEngineerId
            ),
            "business-unit": getUpdatedBusinessUnit(newBusinessUnitId),
            "rate-table": getUpdatedRateTable(newRateTableId),
          },
        },
      };

      // Update project resources in parallel
      let projectPromises = buildProjectPromises();
      if (addressChanged) {
        projectPromises.push(
          upsertProjectLocation(
            project_id,
            unrequiredLocationVariables,
            requiredLocationVariables,
            locationId,
            account_slug,
            authorizationCode,
            customerSite,
            address,
            street2,
            city,
            state,
            zip,
            remoteSite,
            countryId
          ).catch((err) => {
            setShowFailAlert(true);
            setSubmitFail(true);
            setButtonsDisabled(false);
          })
        );
      }

      // If there are associations to be created then create them
      // before updating the project data otherwise submit existing data
      if (projectPromises.length !== 0) {
        Promise.all(projectPromises).then((values) => {
          values.forEach((res) => {
            if (res.type == "clients") {
              updatedProjectData.data.relationships["client"] = { data: res };
            }
            if (res.type == "sales-executives") {
              if (res.id) {
                updatedProjectData.data.relationships["sales-executive"] = {
                  data: res,
                };
              } else {
                setCurrentSalesExec("");
                delete updatedProjectData.data.relationships["sales-executive"];
              }
            }
          });
          if (updatedProjectData.data.relationships["client"].data.id.error) {
            setErrorMessage(
              updatedProjectData.data.relationships["client"].data.id.error
            );
            setShowFailAlert(true);
            setSubmitFail(true);
          } else {
            updateProject(updatedProjectData, authorizationCode);
          }
        });
      } else {
        updateProject(updatedProjectData, authorizationCode);
      }
    } // End edit project location if statement
  }; // End Submit Function

  const handleProjectCreatedAlert = () => {
    if (showProjectCreatedAlert === true) {
      return (
        <DismissibleAlert
          variant="info"
          onClose={() => setShowProjectCreatedAlert(false)}
          text="Project created"
        />
      );
    }
  };

  const handleBlueprintCreatedAlert = () => {
    if (showBlueprintCreatedAlert === true) {
      return (
        <DismissibleAlert
          variant="info"
          onClose={() => setShowBlueprintCreatedAlert(false)}
          text={
            <span>
              Blueprint created.{" "}
              <a href={`${appHost}/admin/blueprints/`}>Click here</a> to view.
            </span>
          }
        />
      );
    }
  };

  // Return our component with appropriate data from the state
  return (
    <div className="projectOverview">
      {submitFail === true
        ? handleAlert(
            showFailAlert,
            errorMessage,
            setShowFailAlert,
            "danger",
            ToastAlert
          )
        : handleAlert(
            showSuccessAlert,
            successText,
            setShowSuccessAlert,
            "success",
            ToastAlert
          )}
      {handleCharacterAlert(
        showMinCharAlert,
        setShowMinCharAlert,
        showMaxCharAlert,
        setShowMaxCharAlert,
        ToastAlert,
        minValue,
        maxValue
      )}
      {location !== newProjectLocation ? handleProjectCreatedAlert() : null}
      {handleLocationAlert(
        showLocationAlert,
        locationErrors,
        setShowLocationAlert
      )}
      {handleUserExistsAlert(
        userExistsAlertIsOpen,
        setUserExistsAlertIsOpen,
        ToastAlert
      )}
      {location !== newProjectLocation ? handleBlueprintCreatedAlert() : null}
      <Card>
        {renderHeader()}
        <Card.Body>
          {isLoading ? (
            <div style={{ marginTop: "20px" }}>
              <ScopeStackSpinner />
            </div>
          ) : (
            <Form onChange={handleFormChange} onSubmit={(e) => handleSubmit(e)}>
              <Row className="crmRow">
                <div
                  className={
                    crmId != ""
                      ? "hasCrm"
                      : (showCrmWarning || !newCrmId) && crmFlag
                      ? "noCrm crmAlert"
                      : "noCrm"
                  }
                >
                  {isCrm === true ? renderCrmOpportunity() : null}
                </div>
                {crmId !== "" ? (
                  <div className="removeCrm">
                    <IconButton
                      className="removeButton"
                      dataMethod="delete"
                      url={`/projects/${project_id}/crm_integration`}
                      iconClass="fa fa-chain-broken"
                      buttonText="Remove"
                    />
                  </div>
                ) : null}
              </Row>
              <Row>
                <Col xs={6}>
                  <FormTextField
                    required={true}
                    id="projectName"
                    onChange={(e) => {
                      setProjectName(e.target.value);
                    }}
                    value={projectName}
                    label="Project Name *"
                  />
                  <Row
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col sm={6}>
                      <AutoCompleteText
                        label={
                          sales_exec_required_flag
                            ? fieldLabels["sales_executive"] + " *"
                            : fieldLabels["sales_executive"]
                        }
                        suggestionList={salesExecutives}
                        value={currentSalesExec}
                        monitorValue={(value) => {
                          chosenSalesExec = value;
                          for (let i = 0; i < salesExecs.length; i++) {
                            if (salesExecs[i].salesExec === chosenSalesExec) {
                              const id = salesExecs[i].id;
                              setNewSalesExecId(id);
                            }
                          }
                          setCurrentSalesExec(value);
                        }}
                        setTextChanged={(bool) => setUserTypedSalesExec(bool)}
                        required={sales_exec_required_flag}
                      />
                    </Col>
                    <Col sm={6}>
                      <DropDownField
                        label={fieldLabels["presales_engineer"]}
                        value={currentPresalesEngineer}
                        onChange={(e) => {
                          let selected = e.target.options.selectedIndex;
                          setNewPresalesEngineerId(
                            e.target.options[selected].accessKey
                          );
                        }}
                        options={presales.map((presale) => {
                          return (
                            <option
                              className="presalesOptions"
                              key={presale.id}
                              accessKey={presale.id}
                            >
                              {presale.attributes.name}
                            </option>
                          );
                        })}
                      />
                    </Col>
                    <Col sm={6}>
                      <DropDownField
                        required={true}
                        value={
                          location === newProjectLocation
                            ? currentPaymentTerm
                            : projectData.paymentTerm
                        }
                        onChange={(e) => {
                          let selected = e.target.options.selectedIndex;
                          setNewPaymentTermId(
                            e.target.options[selected].accessKey
                          );
                        }}
                        options={paymentTerms.map((term) => {
                          return (
                            <option
                              className="paymentTermOptions"
                              key={term.id}
                              accessKey={term.id}
                            >
                              {term.attributes.name}
                            </option>
                          );
                        })}
                        label="Payment Term *"
                      />
                    </Col>
                    {rateTables.length > 1 ? (
                      <Col sm={6}>
                        <DropDownField
                          label="Rate Table *"
                          value={currentRateTable}
                          onChange={(e) => {
                            let selected = e.target.options.selectedIndex;
                            setNewRateTableId(
                              e.target.options[selected].accessKey
                            );
                          }}
                          options={rateTables.map((rateTable) => (
                            <option key={rateTable.id} accessKey={rateTable.id}>
                              {rateTable.attributes.name}
                            </option>
                          ))}
                        />
                      </Col>
                    ) : null}
                    {businessUnits.length !== 0 ? (
                      <Col sm={6}>
                        <ControlledDropDown
                          label={fieldLabels["business_unit"]}
                          value={currentBusinessUnit}
                          onChange={(e) => {
                            setCurrentBusinessUnit(e.target.value);
                            let selected = e.target.options.selectedIndex;
                            setNewBusinessUnitId(
                              e.target.options[selected].accessKey
                            );
                          }}
                          options={[
                            <option
                              value={null}
                              key={null}
                              accessKey={null}
                            ></option>,
                            businessUnits.map((businessUnit) => {
                              return (
                                <option
                                  className="businessUnitOptions"
                                  key={businessUnit.id}
                                  accessKey={businessUnit.id}
                                >
                                  {businessUnit.attributes.name}
                                </option>
                              );
                            }),
                          ]}
                        />
                      </Col>
                    ) : null}
                    {!hide_msa_date_flag && (
                      <Col sm={6}>
                        <FormDateField
                          label="MSA Date"
                          value={msaDate}
                          onChange={(e) => {
                            setMsaDate(e.target.value);
                          }}
                        />
                      </Col>
                    )}
                    <ProjectVariables
                      arr={requiredProjectVariables}
                      account_slug={account_slug}
                      project_id={project_id}
                      setState={setRequiredProjectVariables}
                      setVariableChanged={null}
                    />
                    <ProjectVariables
                      arr={unrequiredProjectVariables}
                      account_slug={account_slug}
                      project_id={project_id}
                      setState={setUnrequiredProjectVariables}
                      setVariableChanged={null}
                    />
                  </Row>
                </Col>
                <Col xs={6}>
                  <AutoCompleteText
                    required={true}
                    id="clientName"
                    label={fieldLabels.client + " *"}
                    suggestionList={clientList}
                    value={clientName}
                    monitorValue={(value) => {
                      chosenClient = value;
                      filterClientList(value).then((res) => {
                        const data = res.data.data;
                        const clients = data.map((client) => ({
                          id: client.id,
                          client: client.attributes.name,
                          msa_date: client.attributes["msa-date"],
                          relationships: client.relationships,
                        }));
                        setClients(clients);
                        const clientNames = data.map((client) => client.attributes.name);
                        setClientList(clientNames);
            
                        let clientExists = "";
                        for (let i = 0; i < data.length; i++) {
                          if (data[i].attributes.name === clientName) clientExists = data[i].id;
                        }
                      });

                      for (let i = 0; i < clients.length; i++) {
                        if (clients[i].client === chosenClient) {
                          setMsaDate(clients[i].msa_date);
                          setNewClientId(clients[i].id);
                          const rateTableData =
                            clients[i].relationships["rate-table"].data;
                          if (rateTableData !== null) {
                            for (let j = 0; j < rateTables.length; j++) {
                              if (rateTables[j].id === rateTableData.id) {
                                setCurrentRateTable(
                                  rateTables[j].attributes.name
                                );
                                setNewRateTableId(rateTables[j].id);
                              }
                            }
                          }
                        }
                      }
                      setClientName(chosenClient);
                    }}
                    setTextChanged={(bool) => {
                      setUserTypedClient(bool);
                    }}
                    crmId={newCrmId}
                  />
                  {renderLocation()}
                  <Row>{renderLocationButtons()}</Row>
                  <FormTextField
                    id="tag-list"
                    value={tags}
                    onChange={(e) => {
                      setTags(e.target.value);
                      setShowFailAlert(false);
                      setSubmitFail(false);
                    }}
                    label="Tags"
                  />
                  <Form.Text muted id="tag-list-info">
                    Comma-separated list of tags for the project
                  </Form.Text>
                </Col>
              </Row>
              <Row className="footerBtns">
                {renderButtons()}
                <Col>
                  <BackButton url={`/projects`} />
                </Col>
              </Row>
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProjectOverview;
