import React from "react";
import { Form } from "react-bootstrap";
import FormFieldLabel from "./FormFieldLabel/FormFieldLabel";

function FormPhoneField({ label, id, placeholder, value, onChange, required }) {
  return (
    <Form.Group className="formGroup">
      <FormFieldLabel label={label} />
      <Form.Control
        required={required}
        id={id}
        value={value === null ? "" : value}
        placeholder={placeholder}
        pattern="^\+?(?:[0-9][\s\-]?){6,14}[0-9]$"
        onChange={onChange}
        type="tel"
      />
    </Form.Group>
  );
}

export default FormPhoneField;
