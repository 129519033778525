import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import Phase from "./ByPhase";
import Location from "./ByLocation";
import ByLob from "./ByLob";
import { capitalizeFirstLetter } from "@utils/helperFunctions";

function SummariesMspa({ account_slug, project_id, account_id }) {
  const [authorizationCode] = useOauth();
  const [projectServices, setProjectServices] = useState([]);
  const [projectSubservices, setProjectSubservices] = useState([]);
  const [resources, setResources] = useState([]);
  const [projectResources, setProjectResources] = useState([]);
  const [lobs, setLobs] = useState([]);
  const [phases, setPhases] = useState([]);
  const [projectLocations, setProjectLocations] = useState([]);
  const [linesOfBusiness, setLinesOfBusiness] = useState([]);
  const [projectStatus, setProjectStatus] = useState("");
  const [totalEffort, setTotalEffort] = useState("");
  const [rateType, setRateType] = useState("Hours");

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode != "") {
      // Get Project Info
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        const status = data.attributes.status;
        const effort = data.attributes["total-effort"];
        if (status !== null) setProjectStatus(status);
        if (effort !== null) setTotalEffort(effort);
        const paymentInfo = data.attributes["payment-info"];
        let rateType =
          paymentInfo["rate-type"] !== "hours"
            ? capitalizeFirstLetter(paymentInfo["rate-type"]) + "s"
            : capitalizeFirstLetter(paymentInfo["rate-type"]);
        setRateType(rateType);
      });

      //Get services with included data & use to set state
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-services?filter[service-type]=professional_services&filter[active]=true&include=project-location,lob,project-phase,project-resource,project-subservices,project-subservices.project-resource`,
        authorizationCode
      ).then((response) => {
        let includedData = response.data.included;
        let subservices = [];
        let phases = [];
        let linesOfBusiness = [];
        let projectLocations = [];
        if (includedData) {
          for (let i = 0; i < includedData.length; i++) {
            if (includedData[i].type === "project-subservices") {
              subservices.push(includedData[i]);
            }
            if (includedData[i].type === "project-phases") {
              phases.push(includedData[i]);
            }
            if (includedData[i].type === "lobs") {
              linesOfBusiness.push(includedData[i]);
            }
            if (includedData[i].type === "project-locations") {
              projectLocations.push(includedData[i]);
            }
          }
        }
        setProjectSubservices(
          subservices.filter(
            (subservice) => subservice.attributes.active === true
          )
        );
        setPhases(phases);
        setLinesOfBusiness(linesOfBusiness);
        setProjectServices(response.data.data);
        setIsLoading(false);
      });

      // Get resources
      API.Get(
        `${apiHost}/${account_slug}/v1/project-resources?page[size]=1000&filter[project]=${project_id}&include=resource,line-of-business`,
        authorizationCode
      ).then((response) => {
        setProjectResources(response.data.data);
        let resources = response.data.included.filter(
          (item) => item.type == "resources"
        );
        setResources(resources);
        setLobs(
          response.data.included.filter(
            (item) => item.type == "line-of-businesses"
          )
        );
      });

      // Get Locations
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-locations`,
        authorizationCode
      ).then((response) => {
        if (response.data.data !== null) {
          setProjectLocations(response.data.data);
        }
      });
    }
  }, [authorizationCode]);
  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/summary"}
            element={
              <ByLob
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectServices={projectServices}
                lobs={lobs}
                resources={resources}
                projectResources={projectResources}
                projectLocations={projectLocations}
                projectSubservices={projectSubservices}
                linesOfBusiness={linesOfBusiness}
                phases={phases}
                isLoading={isLoading}
                projectStatus={projectStatus}
                totalEffort={totalEffort}
                rateType={rateType}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/summary/phase"}
            element={
              <Phase
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectServices={projectServices}
                lobs={lobs}
                resources={resources}
                projectResources={projectResources}
                phases={phases}
                projectLocations={projectLocations}
                projectSubservices={projectSubservices}
                isLoading={isLoading}
                rateType={rateType}
              />
            }
          />
          <Route
            path={"/projects/:project_id/summary/location"}
            element={
              <Location
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectServices={projectServices}
                lobs={lobs}
                resources={resources}
                projectResources={projectResources}
                projectLocations={projectLocations}
                projectSubservices={projectSubservices}
                isLoading={isLoading}
                rateType={rateType}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default SummariesMspa;
