import React, { useEffect } from "react";
import UpdateComponent from "../UpdateComponent/UpdateComponent";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

function AdoptionBarriers({
  account_slug,
  project_id,
  projectStatus,
  outcomes,
  outcomeId,
  account_id,
  isLoading,
  authorizationCode,
}) {
  useEffect(() => {}, [isLoading]);

  if (isLoading) {
    return (
      <div style={{ paddingTop: "20px" }}>
        <ScopeStackSpinner />
      </div>
    );
  } else {
    return (
      <UpdateComponent
        account_slug={account_slug}
        project_id={project_id}
        projectStatus={projectStatus}
        outcomes={outcomes}
        outcomeId={outcomeId}
        account_id={account_id}
        type="Adoption Barriers"
        secondHeader="Barriers"
        authorizationCode={authorizationCode}
      />
    );
  }
}

export default AdoptionBarriers;
