import DataTable from "@components/DataTable";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./style.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import ProductMenu from "./ProductMenu";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { handleAlert } from "@utils/helperFunctions";
import {
  useUpdateServiceMutation,
  V1ServiceResource,
  V1ToManyRelationships,
} from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import useGetAccount from "../../../Teams/common/api/useGetAccount";

const Products = ({ service, refetch }) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { account } = useGetAccount();
  const [selectedDeleteIds, setSelectedDeleteIds] = useState<string[]>([]);
  const [selectedAddIds, setSelectedAddIds] = useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [slideoutOpen, setSlideoutOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [updateService] = useUpdateServiceMutation();

  const productColumns = [
    {
      header: "Name",
      cell(props) {
        return (
          <div className={props.row.original.id === "0" ? "fullWidth" : ""}>
            <div>{props.row.original.name}</div>
          </div>
        );
      },
    },
    {
      header: "MFR Part",
      cell(props) {
        return props.row.original?.mfrPart || "";
      },
    },
    {
      header: "SKU",
      cell(props) {
        return props.row.original?.sku || "";
      },
    },
    {
      header: "Price",
      cell(props) {
        return (
          <div className="priceCol">{props.row.original?.price || ""}</div>
        );
      },
    },
  ];

  const getDeleteModalBody = () => {
    const count = selectedDeleteIds.length;
    return loading ? (
      <SmallSpinner />
    ) : (
      <div>
        <p>
          <strong>
            {`You are about to delete ${count} product${
              count > 1 ? "s" : ""
            } from this service`}
          </strong>
        </p>
        <p>Do you wish to proceed?</p>
      </div>
    );
  };

  const handleFailResponse = (msg) => {
    setLoading(false);
    setErrorMessage(msg || "Something went wrong.");
    setShowFailAlert(true);
  };

  const handleUpdateService = (action: "add" | "delete") => {
    if (!service.id || !account?.id) {
      handleFailResponse("");
      return;
    }
    let productIds: V1ToManyRelationships["data"] =
      service?.products.map((product) => {
        return { id: Number(product.id), type: "products" };
      }) || [];

    if (action === "add") {
      const newProducts = selectedAddIds.map((id) => {
        return { id: Number(id), type: "products" };
      });
      productIds = [...productIds!, ...newProducts];
    } else {
      productIds = productIds!.filter(
        (product) => !selectedDeleteIds.includes(String(product.id))
      );
    }

    const data: V1ServiceResource = {
      id: Number(service.id),
      type: "services",
      attributes: {
        name: service.name,
      },
      relationships: {
        account: {
          data: {
            id: account.id,
            type: "accounts",
          },
        },
        products: { data: productIds },
      },
    };

    updateService({ slug: accountSlug, id: service?.id, body: { data } })
      .then((res) => {
        if (action === "add") {
          setSuccessMessage(`Products added to service`);
          setSelectedAddIds([]);
          setSlideoutOpen(false);
        } else if (action === "delete") {
          setShowDeleteModal(false);
          setSelectedDeleteIds([]);
          setSuccessMessage("Products removed from this service");
        }
        setLoading(false);
        setShowSuccessAlert(true);
        refetch();
      })
      .catch((err) => {
        handleFailResponse("");
      });
  };

  return !service ? (
    <SmallSpinner />
  ) : (
    <div className="serviceProductsContainer">
      <div
        className={`serviceProductsHeader ${
          selectedDeleteIds.length ? "" : "end"
        }`}
      >
        {selectedDeleteIds.length > 0 && (
          <div className="selectedProducts">
            <div className="selectedMessage">
              {selectedDeleteIds.length} Selected
            </div>
            <div className="selectedIcons">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Delete product(s)</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faTrashCan}
                  onClick={() => setShowDeleteModal(true)}
                />
              </OverlayTrigger>
            </div>
          </div>
        )}
        <div className="actionBtns">
          <Button className="button" onClick={() => setSlideoutOpen(true)}>
            Select Products
          </Button>
        </div>
      </div>
      <div className="serviceProductsTable">
        <DataTable
          data={
            service?.products?.length
              ? service?.products
              : [
                  {
                    id: "0",
                    name: "Select products to get started. Any assigned products will automatically be added to the project when this service is assigned. ",
                  },
                ]
          }
          columns={productColumns}
          selectable={service?.products?.length ? true : false}
          striped
          hover
          selectedIds={selectedDeleteIds}
          setSelectedIds={setSelectedDeleteIds}
        />
      </div>

      <ScopeStackModal
        modalTitle="Delete Products"
        modalBody={getDeleteModalBody()}
        button1Text="Cancel"
        handleButton1Click={() => {
          setSelectedDeleteIds([]);
          setShowDeleteModal(false);
        }}
        button2Text={"Delete"}
        handleButton2Click={() => {
          setLoading(true);
          handleUpdateService("delete");
        }}
        show={showDeleteModal}
        handleClose={() => {
          setSelectedDeleteIds([]);
          setShowDeleteModal(false);
        }}
        deleteModal={true}
        button2Disabled={loading}
      />
      <ProductMenu
        slideoutOpen={slideoutOpen}
        setSlideoutOpen={setSlideoutOpen}
        selectedIds={selectedAddIds}
        setSelectedIds={setSelectedAddIds}
        productColumns={productColumns}
        onSave={() => handleUpdateService("add")}
      />
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
    </div>
  );
};

export default Products;
