import React, { useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import {
  useGetCompanyAccountQuery,
  useGetMeQuery,
} from "../../services/ScopeStackAPI";

const LaunchDarklyProvider = ({ children, accountSlug }) => {
  const ldClient = useLDClient();
  const userSignedIn = useSelector((state: RootState) => state.auth.authorized);
  const { data: currentUser } = useGetMeQuery();
  const { data: account } = useGetCompanyAccountQuery(accountSlug);

  useEffect(() => {
    if (userSignedIn && currentUser && account && ldClient) {
      const context = {
        user: {
          key: currentUser.attributes.uuid,
          email: currentUser.attributes.email,
          name: currentUser.attributes.name,
        },
        organization: {
          key: account.attributes.uuid,
          name: account.attributes.name,
        },
        kind: "multi",
      };

      ldClient.identify(context);
    }
  }, [ldClient, currentUser, account, userSignedIn]);

  return <>{children}</>;
};

export default LaunchDarklyProvider;
