import React from "react";
import { Row, Col } from "react-bootstrap";
import { getDate } from "@utils/helperFunctions";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";

const ProjectReview = ({ data }) => {
  return (
    <div className="reviewContainer">
      {data.name ? (
        <div>
          <div>
            <h3 style={{ marginTop: "30px", paddingTop: "10px" }}>
              {data.name}
            </h3>
          </div>
          <div>
            <div>
              <h3 className="reviewHeader">Project Details</h3>
              <Row>
                {data.salesExecutive && data.presalesEngineer ? (
                  <>
                    <Col>
                      <div className="reviewColumn">
                        <strong>Sales Executive</strong>
                        <span>{data.salesExecutive}</span>
                      </div>
                    </Col>
                    <Col>
                      <div className="reviewColumn">
                        <strong>Pre-Sales Engineer</strong>
                        <span>{data.presalesEngineer}</span>
                      </div>
                    </Col>
                    <Col>
                      <div className="reviewColumn">
                        <strong>Rate Table</strong>
                        <span>{data.rateTable.attributes.name}</span>
                      </div>
                    </Col>
                    <Col>
                      {data.businessUnit && (
                        <div className="reviewColumn">
                          <strong>Business Unit</strong>
                          <span>{data.businessUnit.attributes.name}</span>
                        </div>
                      )}
                    </Col>
                  </>
                ) : data.salesExecutive ? (
                  <>
                    <Col>
                      <div className="reviewColumn">
                        <strong>Sales Executive</strong>
                        <span>{data.salesExecutive}</span>
                      </div>
                    </Col>
                    <Col>
                      <div className="reviewColumn">
                        <strong>Rate Table</strong>
                        <span>{data.rateTable.attributes.name}</span>
                      </div>
                    </Col>
                    <Col>
                      {data.businessUnit && (
                        <div className="reviewColumn">
                          <strong>Business Unit</strong>
                          <span>{data.businessUnit.attributes.name}</span>
                        </div>
                      )}
                    </Col>
                    <Col></Col>
                  </>
                ) : data.presalesEngineer ? (
                  <>
                    <Col>
                      <div className="reviewColumn">
                        <strong>Pre-Sales Engineer</strong>
                        <span>{data.presalesEngineer}</span>
                      </div>
                    </Col>
                    <Col>
                      <div className="reviewColumn">
                        <strong>Rate Table</strong>
                        <span>{data.rateTable.attributes.name}</span>
                      </div>
                    </Col>
                    <Col>
                      {data.businessUnit && (
                        <div className="reviewColumn">
                          <strong>Business Unit</strong>
                          <span>{data.businessUnit.attributes.name}</span>
                        </div>
                      )}
                    </Col>
                    <Col></Col>
                  </>
                ) : (
                  <>
                    <Col>
                      <div className="reviewColumn">
                        <strong>Rate Table</strong>
                        <span>{data.rateTable.attributes.name}</span>
                      </div>
                    </Col>
                    <Col>
                      {data.businessUnit && (
                        <div className="reviewColumn">
                          <strong>Business Unit</strong>
                          <span>{data.businessUnit.attributes.name}</span>
                        </div>
                      )}
                    </Col>
                    <Col></Col>
                    <Col></Col>
                  </>
                )}
              </Row>
              {data.projectVariables.length > 0 && (
                <Row style={{ marginTop: "20px", marginBottom: "-15px" }}>
                  {(() => {
                    const result = [];
                    for (let i = 0; i < 4; i++) {
                      result.push(
                        data.projectVariables[i] ? (
                          <Col key={i}>
                            <div className="reviewColumn">
                              <strong>{data.projectVariables[i].label}</strong>
                              <span>
                                {data.projectVariables[i].label === "MSA Date"
                                  ? getDate(data.projectVariables[i].value)
                                  : data.projectVariables[i].value}
                              </span>
                            </div>
                          </Col>
                        ) : (
                          <Col key={i}>
                            <div className="reviewColumn"></div>
                          </Col>
                        )
                      );
                    }
                    return result;
                  })()}
                </Row>
              )}
              {data.projectVariables.length > 4 && (
                <Row style={{ marginTop: "34px", marginBottom: "-15px" }}>
                  {(() => {
                    const result = [];
                    for (let i = 4; i < 8; i++) {
                      result.push(
                        data.projectVariables[i] ? (
                          <Col key={i}>
                            <div className="reviewColumn">
                              <strong>{data.projectVariables[i].label}</strong>
                              <span>{data.projectVariables[i].value}</span>
                            </div>
                          </Col>
                        ) : (
                          <Col key={i}>
                            <div className="reviewColumn"></div>
                          </Col>
                        )
                      );
                    }
                    return result;
                  })()}
                </Row>
              )}
            </div>
            <div style={{ padding: "5px 0px" }}>
              <h3 className="reviewHeader">Client Details</h3>
              <Row
                style={{
                  margin: "0px",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #dee2e6",
                  display: "flex",
                }}
              >
                <div style={{ width: "30%", paddingLeft: "20px" }}>
                  <strong>Name</strong>
                </div>
                <div style={{ width: "30%" }}>
                  <strong>Location</strong>
                </div>
                <div style={{ width: "36%" }}>
                  <strong>Address</strong>
                </div>
              </Row>
              {data.locations.length === 0 ? (
                <Row
                  style={{
                    padding: "10px 0px",
                    margin: "0px",
                    backgroundColor: "#f2f2f2",
                    display: "flex",
                  }}
                >
                  <div style={{ width: "30%", paddingLeft: "20px" }}>
                    <span>{data.client.attributes.name}</span>
                  </div>

                  <div style={{ width: "30%" }}>
                    <span>--</span>
                  </div>
                  <div style={{ width: "36%" }}>
                    <span>--</span>
                  </div>
                </Row>
              ) : (
                data.locations.map((location, index) => (
                  <Row
                    key={index}
                    style={{
                      padding: "10px 0px",
                      margin: "0px",
                      backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "30%", paddingLeft: "20px" }}>
                      <span>
                        {index === 0 ? data.client.attributes.name : "--"}
                      </span>
                    </div>
                    <div style={{ width: "30%" }}>
                      <span>{location.attributes.name}</span>
                    </div>
                    <div style={{ width: "36%" }}>
                      <span>
                        {location.attributes.street !== null
                          ? `${location.attributes.street}${
                              location.attributes.city ||
                              location.attributes.state ||
                              location.attributes["postal-code"]
                                ? ", "
                                : ""
                            }`
                          : ""}
                        {location.attributes.city !== null
                          ? `${location.attributes.city}${
                              location.attributes.state ||
                              location.attributes["postal-code"]
                                ? ", "
                                : ""
                            }`
                          : ""}
                        {location.attributes.state !== null
                          ? `${location.attributes.state}${
                              location.attributes["postal-code"] ? ", " : ""
                            }`
                          : ""}
                        {location.attributes["postal-code"] !== null
                          ? `${location.attributes["postal-code"]}`
                          : ""}
                      </span>
                    </div>
                  </Row>
                ))
              )}
            </div>
          </div>
        </div>
      ) : (
        <SmallSpinner />
      )}
    </div>
  );
};

export default ProjectReview;
