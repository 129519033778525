import {
  ListIntegrationLogsForAccountApiArg,
  ListIntegrationLogsForAccountApiResponse,
  V1PagingMeta,
  V1Relationships,
  useListIntegrationLogsForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { IntegrationLog } from "../../types/connection";

export interface HookArgs {
  connectionId: string;
  page?: ListIntegrationLogsForAccountApiArg["page"];
}

const useListIntegrationLogs = ({ connectionId, page }: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } =
    useListIntegrationLogsForAccountQuery({
      slug: accountSlug,
      accountConnectionId: connectionId,
      page,
    });

  let integrationLogs: IntegrationLog[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    try {
      integrationLogs = data.data.map((log) => {
        if (!log.id) {
          throw new Error("Missing log.id");
        }

        return {
          id: log.id,
          time: log?.attributes?.["created-at"] || "",
          activity: log?.attributes?.content || "",
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    integrationLogs,
    integrationLogsError: error || customError,
    integrationLogsLoading: isLoading,
    pageMeta,
    refetch,
  };
};

export default useListIntegrationLogs;
