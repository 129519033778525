import React from "react";
import { Table } from "react-bootstrap";
import SmallSpinner from "../../SmallSpinner/SmallSpinner";

function BaseTable({
  headerRows,
  dataRows,
  footerRows,
  striped,
  hover,
  bordered,
  className,
  isLoading,
}) {
  if (isLoading) {
    return <SmallSpinner />;
  } else {
    return (
      <Table
        className={className}
        striped={striped}
        hover={hover}
        bordered={bordered}
      >
        {headerRows !== null ? <thead>{headerRows}</thead> : null}
        {dataRows !== null ? <tbody>{dataRows}</tbody> : null}
        {footerRows !== null ? <tfoot>{footerRows}</tfoot> : null}
      </Table>
    );
  }
}

export default BaseTable;
