import SlideOutMenuV2 from "@components/SlideOutMenuV2";
import DataTable from "@components/DataTable";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import useListProductsForAccount from "../api/useListProducts";
import { useState } from "react";
import { Form } from "react-bootstrap";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import SearchField from "@components/Forms/SearchField/SearchField";
import { ListServicesForAccountApiArg } from "@generated";

const ProductMenu = ({
  slideoutOpen,
  setSlideoutOpen,
  selectedIds,
  setSelectedIds,
  productColumns,
  onSave,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchInput, setSearchInput] = useState<string>("");
  const [filterParams, setFilterParams] = useState<
    ListServicesForAccountApiArg["filter"]
  >({ active: true, name: "" });

  const { products, productsLoading, isFetching, pageMeta, refetch } =
    useListProductsForAccount({
      filter: filterParams,
      page: { number: currentPage, size: pageSize },
    });

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchName(searchInput);
  };

  const searchName = (input: string) => {
    setFilterParams({ ...filterParams, name: input });
  };

  return (
    <SlideOutMenuV2
      title="Select Products"
      isOpen={slideoutOpen}
      onClose={() => {
        setSlideoutOpen(false);
        setSelectedIds([]);
      }}
      onSave={onSave}
      actionBtnText={`Add ${selectedIds.length || ""} Selected`}
      actionBtnDisabled={selectedIds.length ? false : true}
    >
      {productsLoading || isFetching ? (
        <SmallSpinner />
      ) : (
        <div>
          <FormFieldLabel label="Search Catalog" className={undefined} />
          <Form
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch(e);
              }
            }}
            onSubmit={handleSearch}
            className="searchField"
          >
            <SearchField
              placeholder="Enter search term to get started"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              value={searchInput}
              onClick={(e) => {
                setSearchInput("");
                searchName("");
              }}
              fullWidth
            />
          </Form>
          <DataTable
            data={
              products?.length
                ? products
                : [{ id: "0", name: "No products found" }]
            }
            columns={productColumns}
            striped
            hover
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            selectable={products?.length ? true : false}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            paginationEnabled
            pageSizeEnabled
            totalPages={pageMeta?.pageCount}
            totalRows={pageMeta?.recordCount}
          />
        </div>
      )}
    </SlideOutMenuV2>
  );
};

export default ProductMenu;
