import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

function OnboardingFooter({
  skipSetupButton,
  rightButtonOnClick,
  rightButtonText,
  disabledRightButton,
  currentPage,
}) {
  const navigate = useNavigate();
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [pageNumber, setPageNumber] = useState(currentPage);
  const aboutPages = [1, 2, 3];
  const setupPages = [4, 5, 6, 7, 8, 9, 10];

  let location = useLocation();
  let path = location.pathname;

  useEffect(() => {
    switch (path) {
      case "/onboarding":
        if (currentPage <= 3) {
          setPageNumber(currentPage);
        } else {
          setPageNumber(1);
        }
        break;
      case "/onboarding/phases":
        setPageNumber(4);
        break;
      case "/onboarding/language-fields-info":
        setPageNumber(5);
        break;
      case "/onboarding/language-fields-edit":
        setPageNumber(6);
        break;
      case "/onboarding/resources":
        setPageNumber(7);
        break;
      case "/onboarding/governances":
        setPageNumber(8);
        break;
      case "/onboarding/standard-services":
        setPageNumber(9);
        break;
      case "/onboarding/complete":
        setPageNumber(10);
        break;
    }
  }, [currentPage, path]);

  return (
    <>
      <div className="whiteBg onboardingFooter">
        <Row>
          <Col sm={2} className="footerButton skip">
            {skipSetupButton ? (
              <div
                onClick={() => navigate("/onboarding/complete")}
                className="skipSetup"
              >
                Skip Setup
              </div>
            ) : null}
          </Col>
          <Col sm={8} className="onboardingPagination">
            <div className="paginationCard">
              <div>
                <div className="pages aboutPages">
                  {aboutPages.map((num) => (
                    <div
                      key={num}
                      className={`footerNumCircle ${
                        num === pageNumber ? "currentPage" : ""
                      }`}
                    >
                      <span className="footerNum">{num}</span>
                    </div>
                  ))}
                </div>
                <div
                  className={`pagesLabel ${
                    aboutPages.includes(pageNumber) ? "currentPage" : ""
                  }`}
                >
                  About
                </div>
              </div>
              <div>
                <div className="pages aboutPages">
                  {setupPages.map((num) => (
                    <div
                      key={num}
                      className={`footerNumCircle ${
                        num === pageNumber ? "currentPage" : ""
                      }`}
                    >
                      <span className="footerNum">{num}</span>
                    </div>
                  ))}
                </div>
                <div
                  className={`pagesLabel ${
                    setupPages.includes(pageNumber) ? "currentPage" : ""
                  }`}
                >
                  Setup
                </div>
              </div>
            </div>
          </Col>
          <Col sm={2} className="footerButton continue">
            {rightButtonText ? (
              <Button
                variant={"btn-secondary"}
                className={"btnSeafoam"}
                type={"button"}
                onClick={rightButtonOnClick}
                disabled={disabledRightButton}
              >
                {rightButtonText}
              </Button>
            ) : null}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default OnboardingFooter;
