import { useListTeamsForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";

interface Team {
  id: number;
  name: string;
}

const useListTeamsForAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  let customError: string | null = null; // Declare customError
  const {
    data,
    error,
    isLoading: teamsLoading,
  } = useListTeamsForAccountQuery({ slug: accountSlug });
  let teams: Team[] = [];
  if (data?.data) {
    try {
      teams = data.data.map((team) => {
        if (!team.id) {
          throw new Error("Missing team.id");
        }
        if (!team.attributes.name) {
          throw new Error("Missing team.attributes.name");
        }
        return {
          id: team.id,
          name: team.attributes.name,
        };
      });
    } catch (e) {
      customError = "An unknown error occurred";
    }
  }
  return { teams, teamsLoading, error: error ?? customError };
};
export default useListTeamsForAccount;
