import { useWhoAmIQuery } from "../../../../services/generated";
import { camelizeHyphenCaseKeys } from "@utils/helpers";
import { User } from "app/javascript/src/types";

const useWhoAmI = () => {
  const { data, error, isLoading } = useWhoAmIQuery();
  let user: User | null = null;

  if (data?.data) {
    user = camelizeHyphenCaseKeys(data.data);
  }

  return { data: user, error, isLoading };
};

export default useWhoAmI;
