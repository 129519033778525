import { Route, Routes, Outlet } from "react-router-dom";
import Show from "./Show";
import New from "./New";
import Edit from "./Edit";

const Products = (props): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<Show {...props} />} />
      </Route>
      <Route path="/new" element={<Outlet />}>
        <Route index element={<New {...props} />} />
      </Route>
      <Route path="/custom" element={<Outlet />}>
        <Route index element={<Edit {...props} />} />
      </Route>
    </Routes>
  );
};

export default Products;
