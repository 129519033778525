import {
  ListConnectedAppApiArg,
  V1Relationships,
  useListConnectedAppQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { AvailableApp } from "../../types";

const useListConnectedApps = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } = useListConnectedAppQuery({
    slug: accountSlug,
  });

  let apps: AvailableApp[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    try {
      apps = data.data.map((app) => {
        if (!app.id) {
          throw new Error("Missing app.id");
        }

        return {
          id: String(app.id),
          logo: app?.attributes?.logo || "",
          summary: app?.attributes?.summary || "",
          service: app?.attributes?.service || "",
          description: app?.attributes?.about || "",
          helpUrl: app?.attributes?.["help-url"] || "",
          authorization: app?.attributes?.authorization || {
            type: "",
            token: "",
          },
          features: app?.attributes?.features || [],
          categories: app?.attributes?.categories || [],
          providers: app?.attributes?.providers?.length
            ? app.attributes.providers
            : undefined,
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    availableApps: apps,
    appsError: error || customError,
    appsLoading: isLoading,
    pageMeta,
    refetch,
  };
};

export default useListConnectedApps;
