import { css } from "@emotion/css";
import useTheme from "@common/hooks/useTheme";

const useStyles = () => {
  const theme = useTheme();

  const card = css`
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray["2"]};
    background: ${theme.colors.white};
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    padding: 1.25em 1em;
    margin: 2em;
    div.px-3.py-2 {
      padding: 0.5rem 0px !important;
    }
  `;

  const cardHeader = css`
    h2 {
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: ${theme.colors.navy["1"]};
      margin: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  const productPreview = css`
    margin-left: 8px;
    height: 15px;
    cursor: pointer;
  `;

  const displayFlex = css`
    display: flex;
    align-items: center;
  `;

  const actionButtons = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;

  const selectedProducts = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    border-radius: 4px;
    border: 1px solid #d7d7d7;
    padding: 2px 0px 2px 8px;
    color: #418172;
    font-size: 12px;
    font-weight: 700;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    margin: -5px 0 5px 0;
  `;

  const selectedMessage = css`
    padding: 3px 2px;
    border-right: 1px solid #d7d7d7;
    margin: 7px 0px;
    width: 75px;
  `;

  const selectedIcons = css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    color: #1c2655;
    cursor: pointer;
    padding: 0px 13px;
  `;
  const subtotal = css`
    padding-right: 15px;
    font-size: 22px;
    margin-top: -5px;
  `;

  const saveIcon = css`
    font-size: 21px;
    padding: 2px 0px 0px 20px;
  `;

  const placeHolder = css`
    height: 44.5px;
  `;

  const modal = css`
    margin: 20px -15px;
  `;

  const button = css`
    margin-right: 10px;
  `;

  const spinner = css`
    & > * {
      margin: 0px !important;
    }
  `;

  const dataTable = css`
    .nameCol {
      width: 25%;
    }
    .sourceCol {
      width: 10%;
    }
    .mfrPartCol {
      width: 17%;
    }
    .intProdIdCol {
      width: 17%;
    }
    .unitsCol {
      width: 10%;
    }
    .unitPriceCol {
      width: 10%;
    }
  `;

  return {
    card,
    cardHeader,
    productPreview,
    displayFlex,
    actionButtons,
    selectedProducts,
    selectedMessage,
    selectedIcons,
    subtotal,
    saveIcon,
    placeHolder,
    modal,
    button,
    spinner,
    dataTable,
  };
};

export default useStyles;
