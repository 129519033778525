import React from "react";
import Governance from "../Governance/Governance";

function NewGovernance({
  account_slug,
  project_id,
  projectStatus,
  governances,
  resources,
  projectResources,
  totalEffort,
  governanceId,
  allocationMethods,
  phases,
  account_id,
  setGovernanceCreated,
  rateType,
  authorizationCode,
  lobs,
  isResourcesFromRates,
  rateTableId,
}) {
  return (
    <Governance
      account_slug={account_slug}
      project_id={project_id}
      projectStatus={projectStatus}
      governances={governances}
      resources={resources}
      projectResources={projectResources}
      totalEffort={totalEffort}
      governanceId={governanceId}
      allocationMethods={allocationMethods}
      calculationTypes={calculationTypes}
      phases={phases}
      account_id={account_id}
      setGovernanceCreated={setGovernanceCreated}
      rateType={rateType}
      authorizationCode={authorizationCode}
      lobs={lobs}
      isResourcesFromRates={isResourcesFromRates}
      rateTableId={rateTableId}
    />
  );
}

export default NewGovernance;
