import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProjectOutcomes from "./ProjectOutcomes/ProjectOutcomes";
import NewOutcome from "./NewOutcome/NewOutcome";
import EditOutcome from "./EditOutcome/EditOutcome";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import AdoptionBarriers from "./AdoptionBarriers/AdoptionBarriers";
import OngoingStrategies from "./OngoingStrategies/OngoingStrategies";
import NewPerformanceIndicator from "./PerformanceIndicators/NewPerformanceIndicator";
import EditPerformanceIndicator from "./PerformanceIndicators/NewPerformanceIndicator";

function CustomerSuccessesMspa({ account_slug, project_id, account_id }) {
  const [outcomes, setOutcomes] = useState([]);
  const [outcomeId, setOutcomeId] = useState(null);
  const [indicatorId, setIndicatorId] = useState(null);
  const [indicators, setIndicators] = useState([]);
  const [indicatorCreated, setIndicatorCreated] = useState(false);
  const [indicatorUpdated, setIndicatorUpdated] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("");
  const [outcomeCreated, setOutcomeCreated] = useState(false);
  const [outcomeUpdated, setOutcomeUpdated] = useState(false);
  const [projectStatus, setProjectStatus] = useState("");

  const [authorizationCode] = useOauth();

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);

  // When page loads, call API to get Outcomes and lift it up to the state
  useEffect(() => {
    if (authorizationCode != "") {
      //Get Project Status
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        const status = data.attributes.status;
        if (status !== null) setProjectStatus(status);

        //Get Outcomes
        API.Get(
          data.relationships["customer-successes"].links.related +
            "?include=performance-indicators",
          authorizationCode
        ).then((response) => {
          let outcomes = response.data.data;
          let includedData = response.data.included;
          if (outcomes !== null && outcomes.length !== 0) {
            setOutcomes(outcomes);
            if (includedData && includedData !== null) {
              setIndicators(
                includedData.filter(
                  (indicator) => indicator.attributes.active === true
                )
              );
            }
          }
        });
      });

      //Get Perfromance Indicator statuses from account
      API.Get(
        `${apiHost}/${account_slug}/v1/performance-indicator-statuses`,
        authorizationCode
      ).then((response) => {
        let statuses = response.data.data;
        if (statuses !== null && statuses.length !== 0) {
          setStatuses(statuses.reverse());
        }
        setIsLoading(false);
      });
    }
  }, [authorizationCode, currentStatus]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/customer_successes"}
            element={
              <ProjectOutcomes
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                outcomeId={outcomeId}
                setOutcomeId={(outcomeId) => setOutcomeId(outcomeId)}
                projectStatus={projectStatus}
                outcomes={outcomes}
                statuses={statuses}
                indicators={indicators}
                indicatorId={indicatorId}
                setIndicatorId={(indicatorId) => setIndicatorId(indicatorId)}
                indicatorCreated={indicatorCreated}
                indicatorUpdated={indicatorUpdated}
                outcomeCreated={outcomeCreated}
                outcomeUpdated={outcomeUpdated}
                setCurrentStatus={(status) => setCurrentStatus(status)}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/customer_successes/new"}
            element={
              <NewOutcome
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                outcomes={outcomes}
                setOutcomeCreated={(created) => setOutcomeCreated(created)}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/customer_successes/:outcomeId/edit"}
            element={
              <EditOutcome
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                outcomes={outcomes}
                outcomeId={outcomeId}
                setOutcomeUpdated={(updated) => setOutcomeUpdated(updated)}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            exact
            path={"/projects/:project_id/adoption_barriers"}
            element={
              <AdoptionBarriers
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                outcomeId={outcomeId}
                setOutcomeId={(outcomeId) => setOutcomeId(outcomeId)}
                projectStatus={projectStatus}
                outcomes={outcomes}
                outcomeCreated={outcomeCreated}
                outcomeUpdated={outcomeUpdated}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            exact
            path={"/projects/:project_id/success_strategies"}
            element={
              <OngoingStrategies
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                outcomeId={outcomeId}
                setOutcomeId={(outcomeId) => setOutcomeId(outcomeId)}
                projectStatus={projectStatus}
                outcomes={outcomes}
                outcomeCreated={outcomeCreated}
                outcomeUpdated={outcomeUpdated}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            exact
            path={
              "/projects/:project_id/customer_successes/:outcomeId/performance_indicators/new"
            }
            element={
              <NewPerformanceIndicator
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                outcomes={outcomes}
                indicators={indicators}
                statuses={statuses}
                setIndicatorCreated={(created) => setIndicatorCreated(created)}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            exact
            path={
              "/projects/:project_id/customer_successes/:outcomeId/performance_indicators/:indicatorId/edit"
            }
            element={
              <EditPerformanceIndicator
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                outcomes={outcomes}
                indicators={indicators}
                statuses={statuses}
                setIndicatorUpdated={(updated) => setIndicatorUpdated(updated)}
                currentStatus={currentStatus}
                authorizationCode={authorizationCode}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default CustomerSuccessesMspa;
