import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useListResourcesForAccountQuery } from "@generated";

interface Option {
  label: string;
  value: string;
}

const useListResourcesForAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError

  const { data, error, isLoading, refetch, isFetching } =
    useListResourcesForAccountQuery({
      slug: accountSlug,
      filter: { active: true },
    });

  let resources: Option[] = [];

  if (data?.data) {
    try {
      resources = data.data.map((resource) => {
        if (!resource.id) {
          throw new Error("Missing resource.id");
        }
        if (!resource.attributes?.name) {
          throw new Error("Missing resource.attributes.name");
        }

        return {
          value: String(resource.id),
          label: resource.attributes.name || "",
        };
      });
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    resources,
    isLoading,
    error: error ?? customError,
    refetch,
    isFetching,
  };
};
export default useListResourcesForAccount;
