import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../types";

interface UserState {
  data: User | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  data: null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // your reducers here
    setUserData: (state, action: PayloadAction<User>) => {
      state.data = action.payload;
    },
    // other reducers...
  },
});

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
