import React, { FC, useEffect, useState } from "react";
import DataTable from "@components/DataTable";
import "./ServicesTable.css";
import { Service, Subservice } from "../types/Service";
import { ColumnDef } from "@tanstack/react-table";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

interface ServiceTableProps {
  servicesPassed: any[];
  onUpdateServices: (services) => void;
  serviceColumns: any[];
  onDragEnd?: (item) => void;
  subserviceColumns: any[];
  addSubservice?: (selectedService) => void;
  deleteService?: (selectedService) => void;
  setAdvancedPricingItem?: (id) => void;
  hasSettings?: boolean;
  hasDragAndDrop?: boolean;
  isCollapsible?: boolean;
  startUncollapsed?: boolean;
  placeholderText?: string;
}

export const ServicesTable: FC<ServiceTableProps> = ({
  servicesPassed,
  onUpdateServices,
  serviceColumns,
  subserviceColumns,
  addSubservice,
  deleteService,
  setAdvancedPricingItem,
  hasSettings,
  hasDragAndDrop,
  isCollapsible = true,
  placeholderText = "No services exist, please add or import",
  startUncollapsed,
}) => {
  const [services, setServices] = useState<any[] | []>(servicesPassed || []);
  const [selectedService, setSelectedService] =
    useState<Service | Subservice>();
  const [selectedSubservice, setSelectedSubservice] =
    useState<Service | Subservice>();

  const getServiceSettingsItems = () => {
    //NOTE: In Settings --> Services --> General: the subservices are passed in here as services
    const items: {
      name: string | JSX.Element;
      onClick: () => void;
      disabled: boolean;
      tooltip?: string;
    }[] = [
      {
        name: "Delete",
        onClick: () =>
          deleteService ? deleteService(selectedService) : () => null,
        disabled: false,
      },
    ];
    const isNew = String(selectedService?.id).includes("new");

    if (setAdvancedPricingItem) {
      items.unshift({
        name: "Set advanced pricing",
        onClick: () =>
          setAdvancedPricingItem({
            id: selectedService?.id,
            type: "subservice",
          }),
        disabled: isNew ? true : false,
        tooltip: isNew
          ? "You must save this service before setting advanced pricing"
          : undefined,
      });
    } else if (isCollapsible) {
      items.unshift({
        name: "Add Subservice",
        onClick: () =>
          addSubservice ? addSubservice(selectedService) : () => null,
        disabled: false,
      });
    }

    return items;
  };

  useEffect(() => {
    const servicesWithCollapsedSubservices = isCollapsible
      ? servicesPassed.map((service) => {
          return {
            ...service,
            collapsedData: (
              <div>
                <div className="instructionalText bold">Subservices</div>
                <DataTable
                  classForTable={`subserviceTableForService${service?.id}`}
                  data={
                    service?.subservices?.length &&
                    service?.subservices?.length > 0
                      ? service.subservices
                      : [
                          {
                            id: "noRow",
                            name: "No subservices exist on this service",
                          },
                        ]
                  }
                  setData={onUpdateServices}
                  columns={subserviceColumns}
                  selectable={false}
                  striped={true}
                  selectedIds={[]}
                  setSelectedIds={() => null}
                  dragAndDrop={
                    service?.subservices?.length &&
                    service?.subservices?.length > 1 &&
                    hasDragAndDrop
                  }
                  isCollapsible={false}
                  hasSettings={service?.subservices?.length > 0 && hasSettings}
                  settingsListItems={[
                    {
                      name: "Delete",
                      onClick: () =>
                        deleteService
                          ? deleteService(selectedSubservice)
                          : () => null,
                      disabled: false,
                    },
                  ]}
                  onSettingsClick={(
                    subservice: Subservice,
                    settingsMenuOpen: boolean
                  ) => {
                    setSelectedSubservice(subservice);
                  }}
                  settingsMenuStyles={{ right: "8%", marginTop: "60px" }}
                />
              </div>
            ),
          };
        })
      : servicesPassed;
    setServices(servicesWithCollapsedSubservices);
  }, [servicesPassed, selectedSubservice, subserviceColumns]);

  return (
    <div>
      <DataTable
        classForTable="servicesTable"
        data={
          services?.length > 0
            ? services
            : [
                {
                  id: "noRow",
                  phase: {
                    label: placeholderText,
                    value: 0,
                  },
                },
              ]
        }
        setData={onUpdateServices}
        columns={serviceColumns}
        selectable={false}
        striped
        selectedIds={[]}
        setSelectedIds={() => null}
        dragAndDrop={services.length > 1 && hasDragAndDrop}
        isCollapsible={services.length > 0 && isCollapsible}
        startUncollapsed={startUncollapsed}
        hasSettings={services.length > 0 && hasSettings}
        settingsListItems={getServiceSettingsItems()}
        onSettingsClick={(service: Service, settingsMenuOpen: boolean) => {
          setSelectedService(service);
        }}
        settingsMenuStyles={{ right: "5%", marginTop: "77px" }}
      />
    </div>
  );
};
