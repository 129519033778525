import React from "react"
import PropTypes from "prop-types"
import Widget from './Widget'
import {COLORS} from './config'

class WidgetSowsByClient extends Widget {
  componentDidMount() {
    fetch(this.props.dataURL)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json.clients, loading: false }))
  }

  renderBody () {
    let { data } = this.state
    if (!data || data.length == 0) return;

    let sum = data.reduce((sum, item) => sum + item.sow_count, 0)

    return(
      <div className="widget-body">
        {data.slice(0,5).map((item, index) => {
          return(
            <div style={{marginBottom: 12}} key={index}>
              <span className="text-muted">{item.name}</span>
              <div className="progress" style={{backgroundColor: '#fff', borderRadius: 0, height: 12}}>
                <div className="progress-bar" style={{backgroundColor: COLORS[index], width: `${item.sow_count/sum * 100}%`}}>{item.sow_count}</div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  renderFooter() {
    return(
      <div className="widget-footer text-right">
        <a href={this.props.moreURL || '#'} className="link-more">View All Projects</a>
      </div>
    )
  }
}

export default WidgetSowsByClient
