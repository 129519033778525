import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function OnHoverButtonIcon({ style, dataConfirm, className, dataMethod, url, iconClass, buttonText, onClick }) {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {buttonText}
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger 
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
      >
        <a
          style={style}
          data-confirm={dataConfirm}
          className={className}
          rel="nofollow"
          data-method={dataMethod}
          href={url}
          onClick={onClick}
        >
          <i className={iconClass}></i>
        </a>
      </OverlayTrigger>
    </>
  );
}

export default OnHoverButtonIcon;
