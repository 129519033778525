import { css } from "@emotion/css";
import useTheme from "@common/hooks/useTheme";

const useStyles = () => {
  const theme = useTheme();

  const slideoutMenu = css`
    position: fixed;
    top: 0;
    right: -33.33%;
    width: 33.33%;
    height: 100%;
    background-color: ${theme.colors.white};
    transition: right 0.3s ease-out;
    overflow-y: scroll;
    z-index: 1000;
    box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.25);
    &.open {
      right: 0;
    }
  `;

  const menuContent = css`
    padding: 1rem;
    overflow-y: visible;
  `;

  const overlay = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #808080;
    opacity: 0.5;
    z-index: 999;
  `;

  const header = css`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    align-self: stretch;

    color: var(--navy-1, #1c2655);
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid ${theme.colors.gray["2"]};
    & > h3 {
      margin-bottom: 0px;
    }
  `;

  const buttonWrapper = css`
    display: flex;
    gap: 1rem;
  `;

  const buttonSubWrapper = css`
    color: ${theme.colors.teal["1"]};
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    cursor: pointer;
  `;

  return {
    slideoutMenu,
    menuContent,
    overlay,
    buttonWrapper,
    buttonSubWrapper,
    header,
  };
};

export default useStyles;
