import { useState, useEffect } from "react";
import useListServices from "../../api/useListServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown,
  faSortUp,
  faSort,
  faEye,
  faCircle,
  faPencil,
} from "@fortawesome/pro-solid-svg-icons";
import { ColumnDef } from "@tanstack/react-table";
import { ProfessionalService } from "../../types";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import DataTable from "@components/DataTable";
import { getServiceStateFilter } from "../helpers";
import { useNavigate } from "react-router";

const ProfessionalServicesTable = ({
  serviceType,
  tab,
  filterParams,
  mode,
  servicesCount,
  setServicesCount,
  selectedIds,
  setSelectedIds,
  refetchServices,
  setRefetchServices,
  currentTab,
  setPreviewSlideoutOpen,
  setPreviewServiceId,
  manageFlag,
}) => {
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const serviceYellow = 0.1;
  const serviceRed = 0.25;

  const { services, isLoading, pageMeta, isFetching, refetch } =
    useListServices({
      serviceType,
      pageSize,
      pageNumber,
      filterParams: { ...filterParams, state: getServiceStateFilter(tab) },
      sortBy,
      sortOrder,
    });

  useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      pageMeta.recordCount !== servicesCount[tab]
    )
      setServicesCount({
        ...servicesCount,
        [tab]: pageMeta.recordCount,
      });
  }, [pageMeta]);

  useEffect(() => {
    if (refetchServices) {
      refetch();
      setRefetchServices(false);
    }
  }, [refetchServices]);

  useEffect(() => {
    refetch();
  }, [currentTab]);

  const handleSort = (columnId: string) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  const renderSortIcon = (columnId: string) => {
    if (sortBy === columnId) {
      return sortOrder === "asc" ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      );
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const getDeviationColor = (deviation: string | undefined) => {
    const dev = Math.abs(Number(deviation));
    if (!dev || dev < serviceYellow) {
      return "#63C4AB";
    } else if (dev >= serviceYellow && dev < serviceRed) {
      return "#FCCE2B";
    } else if (dev >= serviceRed) {
      return "#B90200";
    }
  };

  let servicesColumns: ColumnDef<ProfessionalService>[] = [
    {
      id: "name",
      header: () => (
        <div
          className={`${
            serviceType === "managed_services" ? "serviceNameHeader" : ""
          } profServicesColumnHeader`}
        >
          Name
          <div className="sortIcon" onClick={() => handleSort("name")}>
            {renderSortIcon("name")}
          </div>
        </div>
      ),
      cell(props) {
        const service = props.row.original;
        const name = service.attributes.name;
        return service.id != "0" ? (
          <div
            className="service"
            onClick={() => {
              if (manageFlag) {
                navigate(`${service.id}/edit`);
              } else {
                window.location.replace(
                  `${appHost}/admin/${
                    serviceType === "professional_services"
                      ? "tasks"
                      : "managed_services"
                  }/${service.id}/edit`
                );
              }
            }}
          >
            {name}
            <FontAwesomeIcon icon={faPencil} style={{ paddingLeft: "5px" }} />
          </div>
        ) : (
          name
        );
      },
    },
    {
      id: "status",
      header: "Status",
      cell(props) {
        const service = props.row.original;
        const status = service.attributes?.state;
        return service.id != "0" ? (
          <div className="statusContainer">
            <div className="statusIcon">
              {status == "custom" && (
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{ color: "#63C4AB", marginRight: "-5px" }}
                />
              )}
              <FontAwesomeIcon icon={faCircle} style={{ color: "#FCCE2B" }} />
            </div>
            <div className="status">
              <div>Pending</div>
              {status === "custom" && <div>(Custom)</div>}
            </div>
          </div>
        ) : (
          ""
        );
      },
    },
    {
      id: "phase.name",
      header: () => (
        <div className="profServicesColumnHeader">
          Phase{" "}
          <div className="sortIcon" onClick={() => handleSort("phase.name")}>
            {renderSortIcon("phase.name")}
          </div>
        </div>
      ),
      cell(props) {
        return props.row.original.attributes.phase;
      },
    },
    {
      id: "service-category.nested-name",
      header: () => (
        <div className="profServicesColumnHeader">
          LOB / Category
          <div
            className="sortIcon"
            onClick={() => handleSort("service-category.nested-name")}
          >
            {renderSortIcon("service-category.nested-name")}
          </div>
        </div>
      ),
      cell(props) {
        return props.row.original?.attributes.category;
      },
    },
    {
      id: "used-in",
      header: () => (
        <div className="usedIn">
          <span>Used In</span>
        </div>
      ),
      cell(props) {
        const service = props.row.original;
        return service.id !== "0" ? (
          <div className="servicePreview">
            <FontAwesomeIcon
              icon={faEye}
              onClick={() => {
                setPreviewServiceId(props.row.original.id);
                setPreviewSlideoutOpen(true);
              }}
            />
          </div>
        ) : (
          ""
        );
      },
    },
    {
      id: "created-at",
      header: () => (
        <div className="profServicesColumnHeader">
          Created
          <div className="sortIcon" onClick={() => handleSort("created-at")}>
            {renderSortIcon("created-at")}
          </div>
        </div>
      ),
      cell(props) {
        return props.row.original.attributes.created;
      },
    },
    {
      id: "updated-at",
      header: () => (
        <div className="profServicesColumnHeader">
          Updated
          <div className="sortIcon" onClick={() => handleSort("updated-at")}>
            {renderSortIcon("updated-at")}
          </div>
        </div>
      ),
      cell(props) {
        return props.row.original.attributes.updated;
      },
    },
  ];

  const analyticsColumns = [
    ...servicesColumns.filter(
      (col) =>
        col.id !== "phase" &&
        col.id !== "updated-at" &&
        col.id !== "status" &&
        col.id !== "created-at"
    ),
    {
      id: "suggested-hours",
      header: "EST HRs",
      cell(props) {
        const service = props.row.original;
        const deviation = service.attributes.deviation;
        const rounded =
          deviation !== undefined ? Number(deviation).toFixed(2) : undefined;
        const estimated = service.attributes.estimatedHRs;
        const color = getDeviationColor(rounded);
        return service.id != "0" ? (
          <div
            className="estHRs"
            style={color !== "#63C4AB" ? { color, fontWeight: 700 } : {}}
          >
            <>{estimated}</>
            <FontAwesomeIcon
              icon={faCircle}
              style={{ color, paddingLeft: "10px" }}
            />
          </div>
        ) : (
          ""
        );
      },
    },
    {
      id: "actual-hrs",
      header: "ACT HRs",
      cell(props) {
        const deviation = props.row.original.attributes.deviation;
        const rounded =
          deviation !== undefined ? Number(deviation).toFixed(2) : undefined;
        const actual = props.row.original.attributes.actualHRs;
        const color = getDeviationColor(rounded);
        return (
          <div
            className="actHRs"
            style={color !== "#63C4AB" ? { color, fontWeight: 700 } : {}}
          >
            {actual}
          </div>
        );
      },
    },
    {
      id: "deviation",
      header: "Deviation",
      cell(props) {
        const deviation = props.row.original.attributes.deviation;
        const rounded =
          deviation !== undefined ? Number(deviation).toFixed(2) : undefined;
        const color = getDeviationColor(rounded);
        return (
          <div
            className="deviation"
            style={color !== "#63C4AB" ? { color, fontWeight: 700 } : {}}
          >
            {Number(rounded) > 0 ? `+${rounded}` : rounded}
          </div>
        );
      },
    },
  ];

  const getServicesColumns = (mode) => {
    if (mode === "analytics") return analyticsColumns;
    if (tab !== "pending") {
      return servicesColumns.filter((col) => col.id !== "status");
    }
    return servicesColumns;
  };

  return isLoading || isFetching ? (
    <SmallSpinner />
  ) : (
    <DataTable
      data={
        services.length
          ? services
          : [
              {
                id: "0",
                attributes: { name: "No services found" },
              },
            ]
      }
      columns={
        serviceType === "professional_services"
          ? getServicesColumns(mode)
          : getServicesColumns(mode).filter((c) => c.id !== "phase.name")
      }
      bordered
      striped
      hover
      totalPages={pageMeta.pageCount}
      totalRows={pageMeta.recordCount}
      currentPage={pageNumber}
      setCurrentPage={setPageNumber}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      paginationEnabled
      pageSizeEnabled
      selectable={services.length ? true : false}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
    />
  );
};

export default ProfessionalServicesTable;
