import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import ProjectContacts from "./ProjectContacts/ProjectContacts";
import NewContact from "./NewContact/NewContact";
import EditContact from "./EditContact/EditContact";
import API from "@API";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";

function ContactsMspa({ account_slug, project_id, account_id }) {
  const [contactId, setContactId] = useState(null);

  // Set the State
  const [projectStatus, setProjectStatus] = useState("");
  const [contacts, setContacts] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [authorizationCode] = useOauth();
  const [contactCreated, setContactCreated] = useState(false);
  const [contactUpdated, setContactUpdated] = useState(false);
  const [contactTypes, setContactTypes] = useState([]);
  const [projVariables, setProjVariables] = useState([]);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get Project Info & Set Project Status
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=client`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        const status = data.attributes.status;
        if (status !== null) setProjectStatus(status);
        if (data.relationships.client !== null) {
          let clientId = data.relationships.client.data.id;
          setClientId(clientId);
        }
      });

      // Get Project Contacts
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-contacts?page[size]=200`,
        authorizationCode
      ).then((response) => {
        let contacts = response.data.data;
        if (contacts.length !== 0) {
          setContacts(contacts);
        }
        setIsLoading(false);
      });

      // Get Contact Types
      API.Get(
        `${apiHost}/${account_slug}/v1/contact-types`,
        authorizationCode
      ).then((response) => {
        let contactTypes = response.data.data;
        if (contactTypes.length !== 0) {
          setContactTypes(contactTypes);
        }
      });

      // Get Project Variables
      API.Get(
        `${apiHost}/${account_slug}/v1/project-variables?filter[variable-context]=project_contact`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        let variables = data.map((pv) => {
          let attr = pv.attributes;
          let options = attr["select-options"];
          delete attr["select-options"];
          return { ...attr, select_options: options };
        });
        if (data.length !== 0) setProjVariables(variables);
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/project_contacts"}
            element={
              <ProjectContacts
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                contactId={contactId}
                setContactId={(contactId) => setContactId(contactId)}
                projectStatus={projectStatus}
                contacts={contacts}
                contactCreated={contactCreated}
                contactUpdated={contactUpdated}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_contacts/new"}
            element={
              <NewContact
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                contacts={contacts}
                setContactCreated={(created) => setContactCreated(created)}
                contactTypes={contactTypes}
                clientId={clientId}
                projVariables={projVariables}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_contacts/:contactId/edit"}
            element={
              <EditContact
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                contacts={contacts}
                contactId={contactId}
                setContactUpdated={(updated) => setContactUpdated(updated)}
                contactTypes={contactTypes}
                clientId={clientId}
                projVariables={projVariables}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default ContactsMspa;
