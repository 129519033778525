import { css } from "@emotion/css";
import useTheme from "@common/hooks/useTheme";

const useStyles = () => {
  const theme = useTheme();

  const checkboxGroup = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: ${theme.colors.teal["1"]};
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    overflow: hidden;
    gap: 1rem;
    label {
      white-space: nowrap;
    }
  `;

  const line = css`
    color: ${theme.colors.gray["2"]};
  `;

  const wrapper = css`
    margin-top: 1rem;
  `;

  return {
    checkboxGroup,
    line,
    wrapper,
  };
};

export default useStyles;
