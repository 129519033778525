import React, { useEffect, useState } from "react";
import "./UpgradePage.style.css";
import { Col, Row } from "react-bootstrap";
import UpgradeCard from "./UpgradeCard/UpgradeCard";
import API from "../../utils/API/API";
import useOauth from "../../utils/customHooks/useOauth";
import { upgradePageData } from "./upgradePageData";
import ScopeStackSpinnner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";

function UpgradePage({ account_slug, project_id, account_id, mode, message }) {
  //Env/API
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [authorizationCode] = useOauth();
  const chargeBeeEnv = process.env.CHARGEBEE_ENV;

  //States
  const [currentSubscription, setCurrentSubscription] = useState({});
  const [currentPlan, setCurrentPlan] = useState({});
  const [cbInstance, setCbInstance] = useState(null);
  const [hasActiveFreeTrial, setHasActiveFreeTrial] = useState(false);
  const [trialAvailable, setTrialAvailable] = useState(false);
  const [freeTrialPlan, setFreeTrialPlan] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showToastAlert, setShowToastAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [trialModalShowing, setTrialModalShowing] = useState(false);
  const [upgradePermission, setUpgradePermission] = useState(false);
  //Check whether its being rendered at /upgrades or /projects/project-id/marketing
  const marketingPath = window.location.pathname
    .split("/")
    .includes("marketing");

  useEffect(() => {
    if (authorizationCode !== "") {
      //Get account free trials
      API.Get(
        `${apiHost}/${account_slug}/v1/free-trials`,
        authorizationCode
      ).then((response) => {
        const trials = response.data.data;
        const activeTrials = trials.filter(
          (trial) => trial.attributes.status === "active"
        );
        if (activeTrials.length > 0) {
          setHasActiveFreeTrial(true);
        }
      });

      // Get user permission
      API.Get(`${apiHost}/v1/me`, authorizationCode).then((response) => {
        const data = response.data.data.attributes.privileges;
        const userPrivileges = data.find(
          (p) => p.privilege === "settings.users"
        );

        if (userPrivileges && userPrivileges["access-level"] === "manage") {
          setUpgradePermission(true);
        }
      });

      //Get subscription
      API.Get(`${apiHost}/v1/subscriptions`, authorizationCode).then((res) => {
        const subscription = res.data.data;
        if (subscription) {
          setCurrentSubscription(res.data.data);
          setTrialAvailable(subscription.attributes["free-trial-available"]);
          if (!subscription.attributes["plan-id"]) setUpgradePermission(true);

          //Match subscription to upgradePageData item. If none exists, default to free plan
          const plan = upgradePageData.filter(
            (plan) => subscription.attributes["plan-id"] == plan.id
          );
          if (plan.length > 0) {
            setCurrentPlan(plan[0]);
          } else {
            setCurrentPlan(
              upgradePageData.filter((plan) => plan.id === "free")[0]
            );
          }
        } else {
          const freeSubscription = {
            attributes: {
              "plan-id": "free",
              "plan-name": "Free",
            },
          };
          setCurrentSubscription(freeSubscription);
          setCurrentPlan(upgradePageData[0]);
        }
        setIsLoading(false);
      });
    }
  }, [authorizationCode]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.chargebee.com/v2/chargebee.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);

  useEffect(() => {
    if (
      window.location.hash.substring(1).split(",")[0] === "upgrade-now" &&
      currentSubscription.attributes
    ) {
      const plan = upgradePageData.find(
        (plan) => plan.id === window.location.hash.substring(1).split(",")[1]
      );
      buyPlanNow(null, plan);
    }
  }, [currentSubscription, currentPlan]);

  if (
    document.readyState === "complete" &&
    window["Chargebee"] &&
    !cbInstance
  ) {
    //Create chargbee instance
    let instance = window["Chargebee"].init({ site: chargeBeeEnv });
    instance.setBusinessEntity(account_slug);
    setCbInstance(instance);
  }

  const buyPlanNow = (event, planClicked) => {
    if (event) {
      event.preventDefault();
    }
    let quantity = 1;
    if (
      currentSubscription &&
      (Number(currentSubscription.attributes["max-users"]) <=
        planClicked.minQuantity ||
        currentSubscription.attributes["max-users"] == "")
    ) {
      quantity = planClicked.minQuantity;
    } else {
      quantity = Number(currentSubscription.attributes["max-users"]);
    }
    if (cbInstance) {
      cbInstance.openCheckout({
        hostedPage: async () => {
          let upgradeData = {};
          if (currentSubscription) {
            upgradeData = {
              data: {
                type: "purchase-pages",
                attributes: {
                  mode: currentPlan.id == "free" ? "purchase" : "upgrade",
                  "subscription-items": [
                    {
                      item_price_id: planClicked.id,
                      quantity: quantity,
                    },
                  ],
                },
              },
            };
          }

          let response = await API.Post(
            `${apiHost}/v1/purchase-pages`,
            upgradeData,
            authorizationCode
          );
          return response.data.data.attributes["hosted-page"];
        },
        // When the chargebee dialog has done it's magic, We can do whatever makes sense here
        success: function (hostedPageId) {},
        close: function () {
          window.history.replaceState(
            null,
            document.title,
            window.location.pathname
          );
          window.location.reload();
        },
      });
    }
  };
  const getPlanNameFromId = (planName) => {
    switch (planName) {
      case "free":
        return "free";
      case "ScopeStack-Essentials-Plan-USD-Monthly":
        return "Essentials";
      case "ScopeStack-Business-Plan-USD-Monthly":
        return "Business";
      case "ScopeStack-Premium-Plan-USD-Monthly":
        return "Premium";
      default:
        return null;
    }
  };

  const startFreeTrial = (e, planClicked) => {
    e.preventDefault();
    let trialData = {
      data: {
        type: "free-trials",
        attributes: { "trial-plan-id": planClicked.id },
        relationships: {
          account: { data: { type: "accounts", id: account_id } },
        },
      },
    };
    API.Post(
      `${apiHost}/${account_slug}/v1/free-trials`,
      trialData,
      authorizationCode
    )
      .then((response) => {
        if (response.status == 201) {
          setFreeTrialPlan(response.data.data.attributes["trial-plan-id"]);
          setTrialAvailable(false);
          setHasActiveFreeTrial(true);
          setTrialModalShowing(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const trialStartedModal = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ScopeStackModal
        modalTitle={`Welcome to ${
          getPlanNameFromId(freeTrialPlan)
            ? "the " + getPlanNameFromId(freeTrialPlan) + " plan"
            : "your free trial"
        }!`}
        modalBody={
          <div className="trialMessage">
            <strong style={{ marginBottom: "15px" }}>
              Your free trial has started.
            </strong>
            <span>
              {`You have 30 days remaining to try ${
                getPlanNameFromId(freeTrialPlan)
                  ? "the " + getPlanNameFromId(freeTrialPlan) + " plan"
                  : "this plan"
              }`}
              , explore new features, and get started now.
            </span>
          </div>
        }
        button2Text="Get Started"
        handleButton2Click={() => window.history.back()}
        show={trialModalShowing}
        handleClose={() => {
          setTrialModalShowing(false);
        }}
      />
    </div>
  );

  useEffect(() => {
    if (
      !isLoading &&
      cbInstance &&
      message &&
      performance.navigation.type !== 1
    ) {
      setShowToastAlert(true);
    }
  }, [isLoading, cbInstance]);

  return (
    <div className="upgradePage">
      {isLoading ? (
        <div className={`spinnerContainer ${!marketingPath && "upgradeRoute"}`}>
          <ScopeStackSpinnner />
        </div>
      ) : (
        <div>
          {handleAlert(
            showToastAlert,
            message,
            setShowToastAlert,
            "warning",
            ToastAlert
          )}
          {handleAlert(
            showSuccessAlert,
            successMessage,
            setShowSuccessAlert,
            "success",
            ToastAlert
          )}
          {trialStartedModal}
          <div className="upgradeHeaderContainer">
            <Row>
              <Col className="upgradePageHeader">
                {mode === "feature"
                  ? `Try all our best features${
                      trialAvailable ? " free for 30 days" : ""
                    }!`
                  : `Try a more advanced plan${
                      trialAvailable ? " free for 30 days" : ""
                    }!`}
              </Col>
            </Row>
            <Row>
              <h3 className="upgradePageSubheader">
                Explore what’s possible with the added functionality of our more
                robust plans.
              </h3>
            </Row>
          </div>
          <div className="upgradeCardsContainer">
            {upgradePageData.map((plan, index) => {
              return (
                <Row key={plan.cardTitle}>
                  <Col>
                    <UpgradeCard
                      cardTitle={plan.cardTitle}
                      cardSubtitle={plan.cardSubtitle}
                      subheader={plan.subheader}
                      buttonText={
                        currentSubscription.attributes &&
                        currentSubscription.attributes["plan-id"] == plan.id
                          ? "Current plan"
                          : "Buy Now"
                      }
                      listItems={plan.listItems}
                      currentSubscription={currentSubscription}
                      plan={plan}
                      currentPlan={currentPlan}
                      onButtonClick={(e) => buyPlanNow(e, plan)}
                      freeTrialClick={(e) => startFreeTrial(e, plan)}
                      hasActiveFreeTrial={hasActiveFreeTrial}
                      upgradePermission={upgradePermission}
                      trialAvailable={trialAvailable}
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default UpgradePage;
