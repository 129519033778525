import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip);

function ScopeStackPieChart({ data, width, height }) {
  return <Pie style={{ width: width, height: height }} data={data} />;
}

export default ScopeStackPieChart;
