import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import CardHeader from "@components/CardHeader/CardHeader";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import RouterButton from "@components/Buttons/RouterButton/RouterButton";
import { useNavigate } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";

function ProjectNotes({
  account_slug,
  project_id,
  projectStatus,
  notes,
  noteId,
  account_id,
  noteCreated,
  noteUpdated,
  setNoteId,
  isLoading,
  authorizationCode,
}) {
  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showUnauthorizedAlert, setShowUnauthorizedAlert] = useState(false);

  useEffect(() => {
    if (noteCreated === true) {
      setCreatedAlertIsOpen(true);
    }
    if (noteUpdated === true) {
      setUpdatedAlertIsOpen(true);
    }
  }, [notes, isLoading]);

  const getTableData = () => {
    if (notes.length === 0) {
      return [];
    }
    // Map over notes & return data for ScopeStackTable
    return notes.map((note, index) => {
      const noteId = note.id;

      const deleteNote = (e) => {
        e.stopPropagation();
        let userConfirms = confirm("Are you sure you want to delete this note?")
        if (note.attributes.editable === true && userConfirms) {
          let note = {
            data: {
              type: "notes",
              id: noteId,
            },
          };
          API.Delete(
            `${apiHost}/${account_slug}/v1//projects/${project_id}/notes/${noteId}`,
            note,
            authorizationCode
          )
            .then((response) => {
              if (response.status == 204) {
                window.location.reload();
              }
            })
            .catch((err) => {
              setErrorMessages(formatUnprocessibleResponse(err, "note"));
              setShowFailAlert(true);
            });
        } else {
          setShowUnauthorizedAlert(true);
        }
      };

      // Render icon buttons under actions on hover if the project status is: building
      const renderActions = () => {
        if (projectStatus === "building") {
          return (
            <div className="actionsDiv">
              <OnHoverButtonIcon
                className="actions"
                iconClass="fa fa-remove"
                dataConfirm="Are you sure?"
                buttonText="Delete"
                onClick={(e) => deleteNote(e)}
              />
            </div>
          );
        } else {
          return null;
        }
      };

      const redirectToEditNote = () => {
        setNoteId(noteId);
        return navigate(`/projects/${project_id}/notes/${noteId}/edit`);
      };

      const createdOn = new Date(note.attributes["created-at"]);
      const options = { month: "long", day: "numeric", year: "numeric" };

      // Map returns a table row for each Note
      return {
        rowClass: "notesRow",
        onClick: redirectToEditNote,
        columns: [
          {
            class: "",
            name: note.attributes.name,
          },
          {
            class: "",
            name: note.attributes.content,
          },
          {
            class: "",
            name: note.attributes.username,
          },
          {
            class: "",
            name: createdOn.toLocaleDateString("en-US", options),
          },
        ],
        actions: renderActions(),
      };
    });
  };

  const getFooterData = () => {
    let content = "";
    if (projectStatus === "building") {
      content = (
        <RouterButton
          path={`/projects/${project_id}/notes/new`}
          className="newResource"
          iconClass="fa fa-plus"
          buttonText="Add Note"
        />
      );
    }
    return {
      rowClass: "col-12",
      columns: [{ class: "notesFooter", content: content }],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Note Name",
      },
      {
        class: "",
        name: "Note Content",
      },
      {
        class: "",
        name: "Created By",
      },
      {
        class: "",
        name: "Created On",
      },
    ],
  };
  const tableData = getTableData();
  const footerData = getFooterData();

  let notesTable = new ScopeStackTable(
    "notes",
    headerData,
    tableData,
    footerData
  );

  const detailsCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="noteAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Note created"
        />
      );
    }
  };

  const handleUnauthorizedAlert = () => {
    if (showUnauthorizedAlert === true) {
      return (
        <DismissibleAlert
          variant="danger"
          onClose={() => setShowUnauthorizedAlert(false)}
          text="You are unauthorized to edit this note. Only the person who created the note can edit it."
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {detailsCreatedAlert()}
      {handleFailAlert()}
      {handleUnauthorizedAlert()}
      <Card>
        <CardHeader title="Notes" />
        <Card.Body>
          <BaseTable
            className="scopestack notesTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={notesTable.buildHeaderRows()}
            dataRows={notesTable.buildDataRows()}
            footerRows={notesTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProjectNotes;
