import { useState } from "react";
import { useGetAccountFinancialsQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";

const useGetTotals = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [isRefetching, setIsRefetching] = useState(false);

  const {
    data,
    isLoading,
    refetch: originalRefetch,
  } = useGetAccountFinancialsQuery({
    slug: accountSlug,
  });

  const refetch = async () => {
    setIsRefetching(true);
    await originalRefetch();
    setIsRefetching(false);
  };

  const totals = ((data) => {
    return {
      totalRevenue: Number(data?.data?.attributes["total-contract-value"]) || 0,
      averageRevenue:
        Number(data?.data?.attributes["average-contract-value"]) || 0,
      averageMargin:
        Number(data?.data?.attributes["average-contract-margin"]) || 0,
    };
  })(data);

  const combinedLoading = isLoading || isRefetching;

  return { isLoading: combinedLoading, totals, refetch };
};

export default useGetTotals;
