/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
// Support component names relative to this directory:
var componentRequireContext = require.context("src", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext);

// Seems crazy but importing css will cause them to be extracted and compiled to css
// Components
import '../src/components/Forms/AutoCompleteText/AutoCompleteText.css';

// Pages
import '../src/pages/common.css';
import '../src/pages/Projects/ProjectOverview/ProjectOverview.css';
import '../src/pages/Projects/ProjectOverview/NewProjectHeader/NewProjectHeader.css'
import '../src/pages/GovernanceMspa/ProjectGovernance/ProjectGovernance.css';
import '../src/pages/GovernanceMspa/Governance/Governance.css';
