import {
  ListProjectVersionsApiArg,
  useListProjectVersionsQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Version } from "../../types/Version";

export interface HookArgs {
  page?: ListProjectVersionsApiArg["page"];
  include?: ListProjectVersionsApiArg["include"];
  filter?: ListProjectVersionsApiArg["filter"];
}

const useListVersionsForProject = ({ page, include, filter }: HookArgs) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } = useListProjectVersionsQuery({
    slug: accountSlug,
    page,
    include,
    filter,
  });

  let versions: Version[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    try {
      versions = data.data.map((version) => {
        if (!version.id) {
          throw new Error("Missing version.id");
        }

        return {
          id: String(version.id),
          name: version?.attributes?.name,
          description: version?.attributes?.comment,
          createdBy: version?.attributes?.username,
          createdOn: version?.attributes?.["started-at"],
          commpletedAt: version?.attributes?.["completed-at"]
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    data: versions,
    versionsError: error || customError,
    versionsLoading: isLoading,
    pageMeta,
    refetch,
  };
};

export default useListVersionsForProject;
