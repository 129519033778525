import React from "react";
import { Form } from 'react-bootstrap';
import FormFieldLabel from "./FormFieldLabel/FormFieldLabel";

function FormEmailField({label, id, placeholder, value, onChange, required}) {
  return (
      <Form.Group className="formGroup">
        <FormFieldLabel label={label} />
        <Form.Control required={required} id={id} value={value === null ? "" : value} placeholder={placeholder} pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" onChange={onChange} type="email"/>
      </Form.Group>
  );
}

export default FormEmailField;