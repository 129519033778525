import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import SeafoamButton from "@components/Buttons/SeafoamButton/SeafoamButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";

function TaskBlueprint({ account_slug, project_id, account_id }) {
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [services, setServices] = useState([]);
  const [resources, setResources] = useState([]);
  const [phases, setPhases] = useState([]);
  const [serviceBlueprints, setServiceBlueprints] = useState([]);
  const [selectedBlueprintServices, setSelectedBlueprintServices] = useState(
    []
  );
  const [checkedBlueprints, setCheckedBlueprints] = useState([]);
  const [blueprintItems, setBlueprintItems] = useState([]);
  const [blueprintId, setBlueprintId] = useState(null);

  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode !== "") {
      //Get Blueprints
      API.Get(
        `${apiHost}/${account_slug}/v1/blueprints?include=blueprint-items,blueprint-items.resource,blueprint-items,blueprint-items.service,blueprint-items.service.phase`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        let includedData = response.data.included;
        setBlueprintItems(
          includedData.filter((item) => item.type === "blueprint-items")
        );
        setServices(includedData.filter((item) => item.type === "services"));
        setServiceBlueprints(data);
        setCheckedBlueprints(
          data.map((blueprint) => ({ id: blueprint.id, checked: false }))
        );
        setIsLoading(false);
      });

      //Get Phases from Acct
      API.Get(`${apiHost}/${account_slug}/v1/phases`, authorizationCode).then(
        (response) => {
          let data = response.data.data;
          setPhases(data);
        }
      );

      // Get Project Data
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
      });

      // Get Resources
      API.Get(
        `${apiHost}/${account_slug}/v1/resources`,
        authorizationCode
      ).then((response) => {
        let resources = response.data.data;
        if (resources !== null && resources.length !== 0) {
          setResources(resources);
        }
      });
    }
  }, [authorizationCode]);

  const handleServices = (id) => {
    let services = [];
    for (let i = 0; i < blueprintItems.length; i++) {
      let blueprintItemData = blueprintItems[i].relationships.blueprint.data;
      if (blueprintItemData && blueprintItemData.id === id) {
        services.push(blueprintItems[i]);
      }
    }
    setSelectedBlueprintServices(services);
  };

  const getTableData = (array) => {
    return array.map((item, index) => {
      const handleCheck = (id) => {
        if (checkedBlueprints.length === 0) {
          return false;
        }
        for (let i = 0; i < checkedBlueprints.length; i++) {
          if (checkedBlueprints[i].id === id) {
            return checkedBlueprints[i].checked;
          }
        }
      };

      const getFirstColumn = () => {
        if (array === serviceBlueprints) {
          return {
            class: "",
            name: (
              <Form.Check
                type="radio"
                checked={handleCheck(item.id)}
                value={item.id}
                onChange={(e) => {
                  let id = e.target.value;
                  setBlueprintId(id);
                  setCheckedBlueprints(
                    checkedBlueprints.map((blueprint) =>
                      blueprint.id === id
                        ? { ...blueprint, checked: true }
                        : { ...blueprint, checked: false }
                    )
                  );
                  handleServices(id);
                }}
              />
            ),
          };
        }
        if (array === selectedBlueprintServices) {
          let phase = "";
          for (let i = 0; i < services.length; i++) {
            let serviceData =
              item.relationships.service && item.relationships.service.data
                ? item.relationships.service.data
                : null;
            let phaseData =
              services[i].relationships["phase"] &&
              services[i].relationships["phase"].data
                ? services[i].relationships["phase"].data
                : null;
            if (serviceData && serviceData.id === services[i].id) {
              for (let j = 0; j < phases.length; j++) {
                if (phaseData && phaseData.id === phases[j].id) {
                  phase = phases[j].attributes.name;
                }
              }
            }
          }
          return {
            class: "",
            name: phase,
          };
        }
      };

      const getSecondColumn = () => {
        if (array === serviceBlueprints) {
          return {
            class: "",
            name: item.attributes.name,
          };
        }
        if (array === selectedBlueprintServices) {
          let serviceName = "";
          for (let i = 0; i < services.length; i++) {
            let serviceData = item.relationships.service.data;
            if (serviceData && serviceData.id === services[i].id) {
              serviceName = services[i].attributes.name;
            }
          }
          return {
            class: "",
            name: serviceName,
          };
        }
      };

      const getThirdColumn = () => {
        if (array === serviceBlueprints) {
          return {
            class: "",
            name: item.attributes.code,
          };
        }
        if (array === selectedBlueprintServices) {
          return {
            class: "",
            name: item.attributes.quantity,
          };
        }
      };

      const getFourthColumn = () => {
        if (array === serviceBlueprints) {
          return;
        }
        if (array === selectedBlueprintServices) {
          let resource = "";
          for (let i = 0; i < resources.length; i++) {
            let resourceData = item.relationships.resource.data;
            if (resourceData && resourceData.id === resources[i].id) {
              resource = resources[i].attributes.name;
            }
          }
          return {
            class: "",
            name: resource,
          };
        }
      };

      const getColumns = () => {
        if (array === serviceBlueprints) {
          return [getFirstColumn(), getSecondColumn(), getThirdColumn()];
        }
        if (array === selectedBlueprintServices) {
          return [
            getFirstColumn(),
            getSecondColumn(),
            getThirdColumn(),
            getFourthColumn(),
          ];
        }
      };

      // Map returns data for ScopeStackTable Class
      return {
        rowClass: "blueprintRow",
        onClick: null,
        columns: getColumns(),
        actions: null,
      };
    });
  };

  const serviceBlueprintHeaderData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Select",
      },
      {
        class: "",
        name: "Name",
      },
      {
        class: "",
        name: "Code",
      },
    ],
  };

  const selectedBlueprintHeaderData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Phase",
      },
      {
        class: "",
        name: "Name",
      },
      {
        class: "",
        name: "Quantity",
      },
      {
        class: "",
        name: "Resource",
      },
    ],
  };

  const serviceBluePrintTableData = getTableData(serviceBlueprints);
  const selectedBlueprintTableData = getTableData(selectedBlueprintServices);
  const footerData = null;

  let serviceBlueprintTable = new ScopeStackTable(
    "blueprints",
    serviceBlueprintHeaderData,
    serviceBluePrintTableData,
    footerData
  );

  let selectedBlueprintTable = new ScopeStackTable(
    "blueprints",
    selectedBlueprintHeaderData,
    selectedBlueprintTableData,
    footerData
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const blueprintData = {
      data: { type: "blueprints", id: blueprintId },
    };
    API.Post(
      `${apiHost}/${account_slug}/v1/projects/${project_id}/project-blueprints`,
      blueprintData,
      authorizationCode
    )
      .then((response) => {
        if (response.status === 204) {
          window.location.replace(
            `${appHost}/projects/${project_id}/project_editor`
          );
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "blueprint"));
        setShowFailAlert(true);
      });
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div className="tab-content">
      <div className="tab-pane active">
        {handleFailAlert()}
        <Row>
          <Col xs={6}>
            {/* {handleFailAlert()} */}
            <Card>
              <CardHeader title="Apply Service Blueprint" />
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <BaseTable
                    className="scopestack blueprintTable"
                    striped={false}
                    hover={true}
                    bordered={false}
                    headerRows={serviceBlueprintTable.buildHeaderRows()}
                    dataRows={serviceBlueprintTable.buildDataRows()}
                    footerRows={serviceBlueprintTable.buildFooterRows()}
                    isLoading={isLoading}
                  />
                  <Row>
                    <Col xs={6}>
                      <SeafoamButton text="Apply Blueprint" type="submit" />
                    </Col>
                    <Col xs={6}>
                      <BackButton url={`/projects/${project_id}/edit`} />
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6}>
            <Card>
              <CardHeader title="Services in Selected Blueprint" />
              <Card.Body>
                <BaseTable
                  className="scopestack blueprintTable"
                  striped={false}
                  hover={true}
                  bordered={false}
                  headerRows={selectedBlueprintTable.buildHeaderRows()}
                  dataRows={selectedBlueprintTable.buildDataRows()}
                  footerRows={selectedBlueprintTable.buildFooterRows()}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TaskBlueprint;
