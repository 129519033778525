import API from "../../../../../../utils/API/API";

const calculate = async ({ account, surveyId }) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const url = new URL(
    `/${account.slug}/v1/surveys/${surveyId}/calculate`,
    apiHost
  );

  try {
    const response = await API.Put(
      url.toString(),
      {},
      account.authorizationCode
    );

    return {
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return { data: null, error };
  }
};

export default calculate;
