import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "@utils/helperFunctions";
import { useNavigate } from "react-router";
import useListSharedSurveys from "./api/useListSharedSurveys";
import DataTable from "@components/DataTable";
import type { ColumnDef } from "@tanstack/react-table";
import { SharedSurvey } from "./types";
import { faSortDown, faSortUp, faSort } from "@fortawesome/pro-solid-svg-icons";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SharedSurveys({
  filterParams,
  surveysCount,
  setSurveysCount,
  currentTab,
}): JSX.Element {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(""); // State to track the sorting column
  const [sortOrder, setSortOrder] = useState("asc");

  const { sharedSurveys, isLoading, pageMeta, isFetching, refetch } =
    useListSharedSurveys({
      pageSize,
      pageNumber,
      filterParams,
      sortBy,
      sortOrder,
    });

  useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      pageMeta.recordCount !== surveysCount.sharedSurveys
    )
      setSurveysCount({
        ...surveysCount,
        sharedSurveys: pageMeta.recordCount,
      });
  }, [pageMeta]);

  useEffect(() => {
    refetch();
  }, [currentTab]);

  const handleSort = (columnId: string) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  const renderSortIcon = (columnId: string) => {
    if (sortBy === columnId) {
      return sortOrder === "asc" ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      );
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const columns: ColumnDef<SharedSurvey>[] = [
    {
      id: "name",
      header: () => (
        <div className="surveysColumnHeader">
          Survey Name
          <div className="sortIcon" onClick={() => handleSort("name")}>
            {renderSortIcon("name")}
          </div>
        </div>
      ),
      cell(props) {
        const survey = props.row.original;
        const name = survey.attributes.name;
        return name === "No surveys found" ? (
          <>{name}</>
        ) : (
          <span
            className="surveyName"
            onClick={
              survey.attributes.questionnaireId
                ? () =>
                    navigate(
                      `/surveys/${survey.attributes.questionnaireId}/take`,
                      {
                        state: { surveyId: survey.id },
                      }
                    )
                : () =>
                    alert(
                      "This survey does not have any questions attached to it. Please go to settings to configure this survey's questions."
                    )
            }
          >
            {survey.attributes.name}
          </span>
        );
      },
    },
    {
      id: "status",
      header: () => (
        <div className="surveysColumnHeader">
          Status{" "}
          <div className="sortIcon" onClick={() => handleSort("status")}>
            {renderSortIcon("status")}
          </div>
        </div>
      ),
      cell(props) {
        const survey = props.row.original;
        const status = survey.attributes?.status;
        return status ? (
          <span className={`surveyStatus ${status}`}>
            {capitalizeFirstLetter(status)}{" "}
            {(status === "revised" || status === "applied") &&
              survey.attributes.projectId && (
                <>
                  (
                  <a
                    onClick={(e) =>
                      window.open(
                        `/projects/${survey.attributes.projectId}/edit`,
                        "_blank"
                      )
                    }
                  >
                    View Project
                  </a>
                  )
                </>
              )}
          </span>
        ) : (
          <></>
        );
      },
    },
    {
      id: "sender",
      header: "Sent By",
      cell(props) {
        return <>{props.row.original.attributes?.sentBy || ""}</>;
      },
    },
    {
      id: "emails",
      header: "Sent To",
      cell(props) {
        return <>{props.row.original.attributes?.sentTo || ""}</>;
      },
    },
    {
      id: "created_at",
      header: () => (
        <div className="surveysColumnHeader">
          Sent On{" "}
          <div className="sortIcon" onClick={() => handleSort("created_at")}>
            {renderSortIcon("created_at")}
          </div>
        </div>
      ),
      cell(props) {
        return <>{props.row.original.attributes?.sentOn || ""}</>;
      },
    },
  ];

  return isLoading || isFetching ? (
    <SmallSpinner />
  ) : (
    <DataTable
      data={
        sharedSurveys.length
          ? sharedSurveys
          : [
              {
                id: "0",
                attributes: { name: "No surveys found" },
              },
            ]
      }
      columns={columns}
      bordered
      striped
      hover
      totalPages={pageMeta.pageCount}
      totalRows={pageMeta.recordCount}
      currentPage={pageNumber}
      setCurrentPage={setPageNumber}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      paginationEnabled
      pageSizeEnabled
    />
  );
}

export default SharedSurveys;
