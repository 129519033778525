import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import PricingByLob from "./PricingByLob/PricingByLob";
import PricingByPhase from "./PricingByPhase/PricingByPhase";
import PricingByService from "./PricingByService/PricingByService";
import PricingByLocation from "./PricingByLocation/PricingByLocation";
import {
  capitalizeFirstLetter,
  renderResourceName,
} from "@utils/helperFunctions";

function PsPricingMspa({
  account_slug,
  project_id,
  account_id,
  insight_pricing_adjustments,
}) {
  const [authorizationCode] = useOauth();
  const [projectStatus, setProjectStatus] = useState("");
  const [projectPricings, setProjectPricings] = useState([]);
  const [resources, setResources] = useState([]);
  const [projectResources, setProjectResources] = useState([]);
  const [phases, setPhases] = useState([]);
  const [projectLocations, setProjectLocations] = useState([]);
  const [linesOfBusiness, setLinesOfBusiness] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [currentPaymentTerm, setCurrentPaymentTerm] = useState("");
  const [governances, setGovernances] = useState([]);
  const [currencyUnit, setCurrencyUnit] = useState("");
  const [oneTimeAdjustment, setOneTimeAdjustment] = useState(0.0);
  const [rateType, setRateType] = useState("Hours");
  const [pricingPrivilege, setPricingPrivilege] = useState(false);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode != "") {
      // Get Project Data & set Currency Unit
      API.Get(`${apiHost}/v1/me`, authorizationCode).then((res) => {
        const privilege = res.data.data.attributes.privileges.find(
          (p) => p.privilege === "projects.pricing_adjustment"
        );
        if (privilege && privilege["access-level"] === "manage") {
          setPricingPrivilege(true);
        }
      });
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-phases`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;

        // Get current payment term from project
        API.Get(
          data.relationships["payment-term"].links.related,
          authorizationCode
        ).then((response) => {
          if (response.data.data !== null) {
            setCurrentPaymentTerm(response.data.data);
          }
        });

        // Get Project Resources
        let resourcesUrl =
          data.relationships["project-resources"].links.related +
          "?include=line-of-business";
        API.Get(resourcesUrl, authorizationCode).then((response) => {
          let resources = response.data.data;
          // setProjectResources(
          //   resources.map((resource) =>
          //     resource.attributes["external-name"]
          //       ? `${resource.attributes["external-name"]} / ${resource.attributes.name}`
          //       : resource.attributes.name
          //   )
          // );
          setProjectResources(
            resources.map((resource) => {
              resource.type = "ProjectResource";
              return resource;
            })
          );
        });

        setProjectStatus(data.attributes.status);
        setCurrencyUnit(data.attributes["field-labels"].currency_unit);
        setOneTimeAdjustment(parseFloat(data.attributes["price-adjustment"]));
        const paymentInfo = data.attributes["payment-info"];
        let rateType =
          paymentInfo["rate-type"] !== "hours"
            ? capitalizeFirstLetter(paymentInfo["rate-type"]) + "s"
            : capitalizeFirstLetter(paymentInfo["rate-type"]);
        setRateType(rateType);

        let projectPhases = response.data.included.filter(
          (item) => item.type === "project-phases"
        );
        setPhases(projectPhases);
      });

      // Get lines of business
      API.Get(
        `${apiHost}/${account_slug}/v1/line-of-businesses`,
        authorizationCode
      ).then((response) => {
        setLinesOfBusiness(response.data.data);
      });

      // Get Locations
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-locations`,
        authorizationCode
      ).then((response) => {
        if (response.data.data !== null) {
          setProjectLocations(response.data.data);
        }
      });

      // Get Payment Terms
      API.Get(
        `${apiHost}/${account_slug}/v1/payment-terms`,
        authorizationCode
      ).then((response) => {
        if (response.data.data !== null) {
          setPaymentTerms(response.data.data);
        }
      });

      // Get Governances
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-governances?include=project-resource,project-phase`,
        authorizationCode
      ).then((response) => {
        // Sets governance state to be the array of governance objects
        if (response.data.data !== null) {
          setGovernances(response.data.data);
        }
      });

      // Get Resources
      API.Get(
        `${apiHost}/${account_slug}/v1/resources`,
        authorizationCode
      ).then((res) => {
        let resources = res.data.data;

        // Get Project pricings
        API.Get(
          `${apiHost}/${account_slug}/v1/projects/${project_id}/project-pricings`,
          authorizationCode
        ).then((response) => {
          let data = response.data.data;
          setProjectPricings(data);
          setResources(resources);
          setIsLoading(false);
        });
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/sow_pricings"}
            element={
              <PricingByLob
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                resources={projectResources.concat(resources)}
                projectLocations={projectLocations}
                linesOfBusiness={linesOfBusiness}
                phases={phases}
                lobs={linesOfBusiness}
                paymentTerms={paymentTerms}
                currentPaymentTerm={currentPaymentTerm}
                governances={governances}
                currencyUnit={currencyUnit}
                projectPricings={projectPricings}
                projectStatus={projectStatus}
                oneTimeAdjustment={oneTimeAdjustment}
                setOneTimeAdjustment={(adjustment) =>
                  setOneTimeAdjustment(adjustment)
                }
                isLoading={isLoading}
                rateType={rateType}
                authorizationCode={authorizationCode}
                pricingPrivilege={pricingPrivilege}
                insight_pricing_adjustments={insight_pricing_adjustments}
              />
            }
          />
          <Route
            path={"/projects/:project_id/sow_pricings/phase"}
            element={
              <PricingByPhase
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                resources={projectResources.concat(resources)}
                phases={phases}
                lobs={linesOfBusiness}
                projectLocations={projectLocations}
                governances={governances}
                currencyUnit={currencyUnit}
                projectPricings={projectPricings}
                projectStatus={projectStatus}
                oneTimeAdjustment={oneTimeAdjustment}
                isLoading={isLoading}
                rateType={rateType}
              />
            }
          />
          <Route
            path={"/projects/:project_id/sow_pricings/service"}
            element={
              <PricingByService
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                resources={projectResources.concat(resources)}
                phases={phases}
                lobs={linesOfBusiness}
                projectLocations={projectLocations}
                governances={governances}
                currencyUnit={currencyUnit}
                projectPricings={projectPricings}
                projectStatus={projectStatus}
                oneTimeAdjustment={oneTimeAdjustment}
                isLoading={isLoading}
                rateType={rateType}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/sow_pricings/locations"}
            element={
              <PricingByLocation
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                resources={projectResources.concat(resources)}
                lobs={linesOfBusiness}
                projectLocations={projectLocations}
                governances={governances}
                currencyUnit={currencyUnit}
                projectPricings={projectPricings}
                projectStatus={projectStatus}
                oneTimeAdjustment={oneTimeAdjustment}
                isLoading={isLoading}
                rateType={rateType}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default PsPricingMspa;
