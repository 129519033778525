import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import RouterButton from "@components/Buttons/RouterButton/RouterButton";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import { useNavigate } from "react-router";
import API from "@API";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import ImportButton from "@components/Buttons/ImportButton/ImportButton";
import CurrencyFormat from "react-currency-format";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";

function ProjectProducts({
  account_slug,
  project_id,
  account_id,
  setProductId,
  productId,
  projectStatus,
  products,
  services,
  projectProducts,
  productCreated,
  productUpdated,
  currencyUnit,
  isLoading,
  authorizationCode,
}) {
  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);

  useEffect(() => {
    if (productCreated === true) {
      setCreatedAlertIsOpen(true);
    }
  }, [projectProducts, isLoading]);

  const getTableData = () => {
    if (projectProducts.length === 0) {
      return [];
    }
    return projectProducts.map((product, index) => {
      let productId = product.id;

      const deleteProduct = (e) => {
        e.stopPropagation();
        let userConfirms = confirm(
          "Are you sure you want to delete this product?"
        );
        if (userConfirms) {
          let product = {
            data: {
              type: "project-materials",
              id: productId,
            },
          };
          API.Delete(
            `${apiHost}/${account_slug}/v1/project-products/${productId}`,
            product,
            authorizationCode
          )
            .then((response) => {
              if (response.status == "204") {
                window.location.reload();
              }
            })
            .catch((err) => {
              setErrorMessages(formatUnprocessibleResponse(err, "Product"));
              setShowFailAlert(true);
            });
        }
      };

      // Render icon buttons under actions on hover if the project status is: building
      const renderActions = () => {
        if (projectStatus === "building") {
          return (
            <div className="actionsDiv">
              <OnHoverButtonIcon
                className="actions"
                iconClass="fa fa-remove"
                dataConfirm="Are you sure?"
                buttonText="Delete"
                onClick={deleteProduct}
              />
            </div>
          );
        } else {
          return null;
        }
      };

      const redirectToEditProduct = () => {
        setProductId(productId);
        return navigate(
          `/projects/${project_id}/project_materials/${productId}/edit`
        );
      };

      let unitPrice = product.attributes["unit-price"];

      // Map returns data for ScopeStackTable Class
      return {
        rowClass: "productRow",
        onClick: redirectToEditProduct,
        columns: [
          {
            class: "col-3",
            name: (
              <span>
                {product.attributes.name}
                {product.attributes.description
                  ? `/${product.attributes.description}`
                  : null}
              </span>
            ),
          },
          {
            class: "col-3",
            name: product.attributes["manufacturer-part-number"],
          },
          {
            class: "col-2",
            name: product.attributes["product-id"],
          },
          {
            class: "text-right col-",
            name: product.attributes.quantity,
          },
          {
            class: "col-1",
            name: product.attributes["unit-of-measure"],
          },
          {
            class: "text-right col-1",
            name: (
              <CurrencyFormat
                displayType="text"
                prefix={currencyUnit}
                isNumericString={true}
                thousandSeparator={true}
                value={
                  unitPrice && unitPrice !== "NaN"
                    ? parseFloat(unitPrice).toFixed(2)
                    : parseFloat(0).toFixed(2)
                }
                decimalScale={2}
                fixedDecimalScale={true}
              />
            ),
          },
        ],
        actions: renderActions(),
      };
    });
  };

  const getFooterData = () => {
    let content = "";
    if (projectStatus === "building") {
      content = (
        <div className="actions row">
          <RouterButton
            path={`/projects/${project_id}/project_materials/new`}
            className="newResource"
            iconClass="fa fa-plus"
            buttonText="Add Products"
          />
          <span> &nbsp; | &nbsp; </span>
          <RouterButton
            path={`/projects/${project_id}/project_materials/new?custom=true`}
            className="newResource"
            iconClass="fa fa-plus"
            buttonText="Add Custom Products"
          />
        </div>
      );
    }
    return {
      rowClass: "col-12",
      columns: [{ class: "productFooter col-6", content: content }],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "col-3",
        name: "Name/Description",
      },
      {
        class: "col-3",
        name: "MFR Part",
      },
      {
        class: "col-2",
        name: "Product ID",
      },
      {
        class: "text-right col-1",
        name: "Quantity",
      },
      {
        class: "col-1",
        name: "Units",
      },
      {
        class: "text-right col-1",
        name: "Unit Price",
      },
      {
        class: "col-1",
        name: <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>,
      },
    ],
  };

  const tableData = getTableData();
  const footerData = getFooterData();

  let productsTable = new ScopeStackTable(
    "products",
    headerData,
    tableData,
    footerData
  );

  const productCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="productAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Product created"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {productCreatedAlert()}
      {handleFailAlert()}
      <Card>
        <CardHeader title="Products" />
        <Card.Body>
          <ImportButton
            account_slug={account_slug}
            project_id={project_id}
            contextId={project_id}
            contextType="Project"
            label="Bill of Materials (CSV or Excel)"
            value="bill_of_material"
          />
          <BaseTable
            className="scopestack productsTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={productsTable.buildHeaderRows()}
            dataRows={productsTable.buildDataRows()}
            footerRows={productsTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProjectProducts;
