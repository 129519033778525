import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import StripedTable from "@components/Tables/StripedTable/StripedTable";
import CardHeader from "@components/CardHeader/CardHeader";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import OnHoverButtonIcon from "@components/Buttons/OnHoverButtons/OnHoverButtonIcon";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import RouterButton from "@components/Buttons/RouterButton/RouterButton";
import { useNavigate } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import "./ProjectLocations.css";
import ImportButton from "@components/Buttons/ImportButton/ImportButton";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";

function ProjectLocations({
  account_slug,
  project_id,
  setLocationId,
  projectStatus,
  locations,
  locationCreated,
  locationUpdated,
  isLoading,
  authorizationCode,
}) {
  let navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [createdAlertIsOpen, setCreatedAlertIsOpen] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);

  useEffect(() => {
    if (locationCreated === true) {
      setCreatedAlertIsOpen(true);
    }
    if (locationUpdated === true) {
      setUpdatedAlertIsOpen(true);
    }
  }, [isLoading]);

  const getTableData = () => {
    if (locations.length === 0) {
      return [];
    }
    // Map over & return data for ScopeStackTable class
    return locations.map((location, index) => {
      const locationId = location.id;

      const deleteLocation = (e) => {
        e.stopPropagation();
        let userConfirms = confirm(
          "Are you sure you want to delete this location?"
        );
        if (userConfirms) {
          let locationData = {
            data: {
              type: "project-locations",
              id: locationId,
            },
          };
          API.Delete(
            `${apiHost}/${account_slug}/v1/project-locations/${locationId}`,
            locationData,
            authorizationCode
          )
            .then((response) => {
              if (response.status == 204) {
                window.location.reload();
              }
            })
            .catch((err) => {
              setErrorMessages(formatUnprocessibleResponse(err, "location"));
              setShowFailAlert(true);
            });
        }
      };

      // Render icon buttons under actions on hover if the project status is: building
      const renderActions = () => {
        if (projectStatus === "building") {
          return (
            <div>
              <OnHoverButtonIcon
                className="actions"
                iconClass="fa fa-remove"
                dataConfirm="Are you sure?"
                buttonText="Delete"
                onClick={(e) => deleteLocation(e)}
              />
            </div>
          );
        } else {
          return null;
        }
      };

      const redirectToEditLocation = () => {
        setLocationId(locationId);
        return navigate(
          `/projects/${project_id}/project_locations/${locationId}/edit`
        );
      };
      let address = location.attributes.street;
      let street2 = location.attributes.street2;
      let city = location.attributes.city;
      let state = location.attributes.state;
      let zip = location.attributes["postal-code"];
      let country = location.attributes.country;
      let remoteLocation = location.attributes.remote;

      // Map returns a table row for each Location
      return {
        rowClass: "locationRow",
        onClick: redirectToEditLocation,
        columns: [
          {
            class: "",
            name: location.attributes.name,
          },
          {
            class: "",
            name: `${address !== null ? address : ""},  ${
              street2 !== null ? street2 : ""
            } ${city !== null ? city : ""} ${state !== null ? state : ""} ${
              zip !== null ? zip : ""
            } ${country !== null ? country.toUpperCase() : ""}`,
          },
        ],
        actions: renderActions(),
      };
    });
  };

  const getFooterData = () => {
    let content = "";
    if (projectStatus === "building") {
      content = (
        <div>
          <RouterButton
            path={`/projects/${project_id}/project_locations/new`}
            className="newResource"
            iconClass="fa fa-plus"
            buttonText="Add Location"
          />
        </div>
      );
    }
    return {
      rowClass: "col-12",
      columns: [{ class: "locationFooter", content: content }],
    };
  };

  const headerData = {
    rowClass: "",
    columns: [
      {
        class: "",
        name: "Location",
      },
      {
        class: "",
        name: "Address",
      },
      {
        class: "text-right",
        name: "Actions",
      },
    ],
  };
  const tableData = getTableData();
  const footerData = getFooterData();

  let locationTable = new ScopeStackTable(
    "locations",
    headerData,
    tableData,
    footerData
  );

  const detailsCreatedAlert = () => {
    if (createdAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="locationAlerts"
          variant="info"
          onClose={() => setCreatedAlertIsOpen(false)}
          text="Location created"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  // Return the JSX for the Project Locations Component
  return (
    <div>
      {detailsCreatedAlert()}
      {handleFailAlert()}
      <Card>
        <CardHeader title="Project Locations" />
        <Card.Body>
          <ImportButton
            account_slug={account_slug}
            project_id={project_id}
            contextId={project_id}
            contextType="Project"
            label="Service Locations (CSV or Excel)"
            value="service_locations"
          />
          <BaseTable
            className="scopestack locationTable"
            striped={true}
            hover={true}
            bordered={false}
            headerRows={locationTable.buildHeaderRows()}
            dataRows={locationTable.buildDataRows()}
            footerRows={locationTable.buildFooterRows()}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProjectLocations;
