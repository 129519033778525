import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ContextMenu.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function ContextMenu({ menuItems, style }) {
  return (
    <div className="context-menu-container" style={style}>
      <ul className="context-menu">
        {menuItems.map((menuItem, index) => {
          const menuContent = (
            <div key={index}>
              <li
                className={`context-menu-item ${
                  menuItem.disabled ? "disabled-context-menu-item" : ""
                }`}
              >
                {menuItem.disabled ? (
                  <span>
                    {menuItem.iconClass && (
                      <FontAwesomeIcon
                        icon={menuItem.iconClass}
                        style={menuItem.style}
                      />
                    )}
                    {menuItem.name}
                  </span>
                ) : (
                  <a
                    href={menuItem.href ? menuItem.href : null}
                    target={menuItem.newTab ? "_blank" : ""}
                    onClick={menuItem.onClick}
                  >
                    {menuItem.iconClass && (
                      <FontAwesomeIcon
                        icon={menuItem.iconClass}
                        style={menuItem.style}
                      />
                    )}
                    {menuItem.name}
                  </a>
                )}
              </li>
            </div>
          );
          return (
            <div key={index}>
              {menuItem.disabled && menuItem.tooltip ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-${index}`}>
                      {menuItem.tooltip}
                    </Tooltip>
                  }
                >
                  {menuContent}
                </OverlayTrigger>
              ) : (
                menuContent
              )}
              {menuItem.hasBreakingLine ? <hr /> : null}
            </div>
          );
        })}
      </ul>
    </div>
  );
}

export default ContextMenu;
