const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

export default class DeconstructedUrl {
  constructor(account_slug) {
    this.protocol = "https";
    this.host = apiHost;
    this.path = `${account_slug}/v1/projects`;
    this.queryParams = {
      include: "",
      page: { size: 50, number: 1 },
      filter: { active: true },
      sort: "",
    };
  }


  buildQueryElement(elementName) {
    let filters = Object.keys(this.queryParams[elementName]);
    let filterElements = filters.map((filter) => {
      let val = this.queryParams[elementName][filter];
      if (val !== undefined && val !== null && val !== "") {
        return `${elementName}[${filter}]=${encodeURIComponent(val)}`
      } else {
        return null
      }
    });
    return filterElements.filter((val) => { return val }).join('&');
  }

  buildQueryParam(elementName) {
    let val = this.queryParams[elementName];
    if (val !== undefined && val !== null && val !== "") {
      return `${elementName}=${encodeURIComponent(val)}`
    } else {
      return null
    }
  }

  buildUrl() {
    let params = [
      this.buildQueryParam("include"),
      this.buildQueryElement("filter"),
      this.buildQueryParam("sort"),
      this.buildQueryElement("page"),
    ].filter((val) => { return val });

    let url = `${apiHost}/${this.path}`;
    if (params.length > 0) {
      url = url + '?' + params.join('&')
    }


    return url;
  }
}
