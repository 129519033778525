import { useGetAccountQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { Account } from "../types";

const useGetAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading } = useGetAccountQuery({
    slug: accountSlug,
  });

  let account: Account | null = null;
  let customError: string | null = null;

  if (data?.data && data.data.attributes?.["account-id"]) {
    try {
      account = {
        id: data.data.attributes["account-id"],
        type: "accounts",
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return { account, error: error || customError, isLoading };
};

export default useGetAccount;
