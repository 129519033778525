import React from "react";

function RescopeBtn({ onClick, style }) {
  return (
    <div style={style} onClick={onClick}>
    <p className="btn btn-success">
      <i className="fa fa-sliders"></i> Rescope
    </p>
  </div>
  );
}

export default RescopeBtn;
