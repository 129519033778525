import formatData from "./formatData";
import API from "@API";

const loadLobs = async ({ account_slug, authorizationCode, lob_id }) => {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const url = new URL(`/${account_slug}/v1/line-of-businesses`, apiHost);
  url.searchParams.append("filter[active]", "true");

  try {
    const response = await API.Get(url.toString(), authorizationCode);

    return { data: response.data.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export default loadLobs;
