const buildNestedOptionsArray = (categories) => {
  let options = new Set();
  let categoryGroups = new Set();
  categories.forEach((category) => {
    categoryGroups.add(category.attributes["nested-name"].split(" > ")[0]);
  });

  Array.from(categoryGroups).forEach((categoryGroup) => {
    options.add({
      label: categoryGroup,
      options: categories
        .filter((category) => {
          return (
            category.attributes["nested-name"] &&
            category.attributes["nested-name"].split(" > ")[0] === categoryGroup
          );
        })
        .map((category) => {
          return {
            label: category.attributes["nested-name"],
            value: category.id,
          };
        }),
    });
  });

  return Array.from(options);
};

export default buildNestedOptionsArray;
