import { useListTeamsForAccount, useListTagsForAccount } from "../api";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Option } from "../../Show/types";
import { MultiSelectAutocomplete } from "@components/FormsV2/MultiSelectAutoComplete";

const TeamsTags = ({ service, setService, teams, permission }) => {
  const [teamOptions, setTeamOptions] = useState<Option[]>([]);

  const { tags } = useListTagsForAccount();
  const [tagOptions, setTagOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (teams.length !== teamOptions.length) {
      setTeamOptions(teams);
    }
  }, [teams]);

  useEffect(() => {
    if (tags.length !== tagOptions.length) {
      setTagOptions(tags);
    }
  }, [tags]);
  return (
    <div className="teamsTags">
      <Row>
        <Col sm={6}>
          <h5 className="teamsTagsHeader">Assigned Teams</h5>
          <MultiSelectAutocomplete
            label="Search Teams"
            placeholder="Search by team name or select from list"
            id="serviceTeams"
            options={teamOptions}
            value={service?.teams || []}
            onChange={(e) =>
              //@ts-ignore
              setService({ ...service, teams: e })
            }
            isDisabled={permission !== "manage"}
          />
        </Col>
        <Col sm={6}>
          <h5 className="teamsTagsHeader">Assigned Tags</h5>
          <MultiSelectAutocomplete
            label="Enter Tags"
            placeholder="Enter tags or select from list"
            id="serviceTags"
            options={tagOptions}
            value={service?.tags || []}
            onChange={(e) =>
              //@ts-ignore
              setService({ ...service, tags: e })
            }
            isDisabled={permission !== "manage"}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TeamsTags;
