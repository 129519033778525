import React, { useState, useEffect, useImperativeHandle } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { Quote, OptionType } from "../../types/Quote";
import {
  GetProjectByIdApiResponse,
  V1AccountResource,
  useCreateQuoteMutation,
  useGetQuoteQuery,
  useListServiceCategorysForAccountQuery,
  useListVendorsForAccountQuery,
  useUpdateQuoteMutation,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { handleAlert, snakeToText } from "@utils/helperFunctions";
import { FormNumberFieldV2 } from "@components/FormsV2/FormNumberFieldV2";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import MultipleLineFieldV2 from "@components/FormsV2/MultipleLineFieldV2";
import RequiredReactSelect from "@components/FormsV2/RequiredReactSelect/RequiredReactSelect";
import { Select2V2 } from "@components/FormsV2";
import AddVendorSlideout from "../AddVendorSlideout";
import DropdownApi from "@components/FormsV2/DropdownApi/DropdownApi";
import { getFormattedQuote } from "../../quoteHelpers/quoteHelpers";
import API from "../../../../utils/API/API";
import useOauth from "../../../../utils/customHooks/useOauth";

interface Props {
  quoteProp?: Quote;
  project_id: number;
  account: {
    data: V1AccountResource;
  };
  permission: string;
  project: GetProjectByIdApiResponse;
  authorizationCode: string | null;
  revenueCalculationLabel: string;
}

const QuoteForm = React.forwardRef(
  (
    {
      quoteProp,
      project_id,
      account,
      permission,
      project,
      authorizationCode,
      revenueCalculationLabel
    }: Props,
    ref
  ) => {
    // Redux / ENV
    const { accountSlug } = useSelector((state: RootState) => state.slug);
    const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
    // Location States / React Router
    const location = window.location.href;
    const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
    var locationArr = location.split("/");
    var id = locationArr[locationArr.length - 1];
    let navigate = useNavigate();
    const historyLocation = useLocation();
    const [quote, setQuote] = useState(quoteProp);
    const [loading, setLoading] = useState(true);

    // Field States
    const [vendorName, setVendorName] = useState<OptionType>(
      quote?.vendor || { value: 0, label: "Search and select vendor name" }
    );
    const [description, setDescription] = useState(quote?.description || "");
    const [markup, setMarkup] = useState(quote?.markup || "");
    const [oneTimeCost, setOneTimeCost] = useState(quote?.oneTimeCost || "");
    const [lob, setLob] = useState<OptionType>(
      quote?.lob || {
        value: 0,
        label: "Assign to a Line of Business",
      }
    );
    const [deriveFromServices, setDeriveFromServices] = useState(
      quote?.deriveFromServices || false
    );
    const [billingFrequency, setBillingFrequency] = useState<OptionType>(
      quote?.billingFrequency || {
        value: 0,
        label: "How often do costs recur",
      }
    );
    const [recurrenceTerm, setRecurrenceTerm] = useState(quote?.terms || "");
    const [costPerTerm, setCostPerTerm] = useState(quote?.recurringCost || "");

    // Get Service Categories
    const {
      data: lobs,
      error: lobsError,
      isLoading: lobsLoading,
      refetch: lobsRefetch,
    } = useListServiceCategorysForAccountQuery({
      slug: accountSlug,
    });

    // Get Vendors
    const {
      data: vendors,
      error: vendorsError,
      isLoading: vendorsLoading,
      refetch: vendorsRefetch,
    } = useListVendorsForAccountQuery({
      slug: accountSlug,
    });

    const billingFrequencies: OptionType[] = [
      "one_time",
      "monthly",
      "quarterly",
      "yearly",
    ].map((bf) => {
      return { value: bf, label: snakeToText(bf) || "" } as OptionType;
    });

    // Alert States
    const [errorMessages, setErrorMessages] = useState<string | string[]>(
      "Something went wrong! Your changes could not be saved at this time."
    );
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState(
      "Project Expense saved successfully!"
    );
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [vendorNameInvalid, setVendorNameInvalid] = useState(false);
    const [lobInvalid, setLobInvalid] = useState(false);
    const [slideoutOpen, setSlideoutOpen] = useState(false);
    const [newVendorId, setNewVendorId] = useState("");

    // Mutations
    const [createVendorQuote] = useCreateQuoteMutation();
    const [updateVendorQuote] = useUpdateQuoteMutation();

    useEffect(() => {
      if (authorizationCode && authorizationCode !== "") {
        API.Get(
          `${apiHost}/${accountSlug}/v1/quotes/${id}?include=lob,vendor`,
          authorizationCode
        ).then((res) => {
          const retrievedQuote = getFormattedQuote(
            res?.data?.data,
            vendors?.data,
            billingFrequencies,
            lobs?.data
          );
          setQuote(retrievedQuote);
          setVendorName(
            retrievedQuote?.vendor || {
              value: 0,
              label: "Search and select vendor name",
            }
          );
          setDescription(retrievedQuote?.description);
          setMarkup(retrievedQuote?.markup);
          setOneTimeCost(retrievedQuote?.oneTimeCost);
          setLob(
            retrievedQuote?.lob || {
              value: 0,
              label: "Assign to a Line of Business",
            }
          );
          setDeriveFromServices(retrievedQuote?.deriveFromServices || false);
          setBillingFrequency(
            retrievedQuote?.billingFrequency || {
              value: 0,
              label: "How often do costs recur",
            }
          );
          setRecurrenceTerm(retrievedQuote?.terms);
          setCostPerTerm(retrievedQuote?.recurringCost);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }, [authorizationCode]);

    useEffect(() => {
      if (historyLocation?.state?.new) {
        setSuccessMessage("Vendor Quote created successfully!");
        setShowSuccessAlert(true);
      }
    }, []);

    useImperativeHandle(ref, () => ({
      saveQuote,
    }));

    const billingFrequencyOptions: OptionType[] = [
      "one_time",
      "monthly",
      "quarterly",
      "yearly",
    ].map((bf) => {
      return { value: bf, label: snakeToText(bf) || "" } as OptionType;
    });

    const lobOptions: any = (lobs?.data || []).map((lob) => {
      return {
        label:
          lob?.attributes?.["nested-name"] || lob?.attributes?.["name"] || "",
        value: lob?.id?.toString() || "",
      };
    });

    const vendorOptions: any = (vendors?.data || []).map((vendor) => {
      return {
        label: vendor?.attributes?.["name"] || "",
        value: vendor?.id?.toString() || "",
      };
    });

    const clearForm = () => {
      setVendorName({ value: 0, label: "Search and select vendor name" });
      setDescription("");
      setMarkup("");
      setOneTimeCost("");
      setBillingFrequency({
        value: 0,
        label: "How often do costs recur",
      });
      setCostPerTerm("");
      setRecurrenceTerm("");
      setDeriveFromServices(false);
      setLob({
        value: 0,
        label: "If applicable, assign to a Line of Business",
      });
    };

    const saveQuote = (saveAndAddClicked) => {
      if (vendorName.value === 0) {
        setVendorNameInvalid(true);
        return;
      }
      if (lob.value === 0) {
        setLobInvalid(true);
        return;
      }
      let quoteData: any = {
        type: "quotes",
        id: quote?.id,
        attributes: {
          description: description,
          cost: oneTimeCost === "" ? 0 : oneTimeCost,
          "recurring-cost": costPerTerm === "" ? 0 : costPerTerm,
          "billing-frequency":
            billingFrequency.value === 0 ? "one_time" : billingFrequency.value,
          markup: markup === "" ? 0 : markup,
          "service-pricing": deriveFromServices,
          "mrr-terms": recurrenceTerm === "" ? 0 : recurrenceTerm,
        },
        relationships: {
          project: { data: { type: "projects", id: project_id } },
          vendor: { data: { type: "vendors", id: vendorName.value } },
          lob: { data: { type: "lobs", id: lob.value } },
        },
      };

      if (lastWordInLocation !== "new") {
        updateVendorQuote({
          slug: accountSlug,
          id: Number(quoteData.id),
          body: { data: quoteData },
        })
          .unwrap()
          .then((response) => {
            if (response?.data?.id) {
              setSuccessMessage("Vendor Quote updated successfully!");
              setShowSuccessAlert(true);
            }
          })
          .catch((err) => {
            let msg = err?.data?.errors?.[0]?.detail;
            setErrorMessages(
              msg
                ? msg
                : "Something went wrong. Your changes could not be saved at this time."
            );
            setShowFailAlert(true);
          });
      } else {
        delete quoteData.id;
        createVendorQuote({
          slug: accountSlug,
          body: { data: quoteData },
        })
          .unwrap()
          .then((response) => {
            if (response?.data?.id) {
              if (saveAndAddClicked) {
                clearForm();
                setSuccessMessage("Vendor Quote created successfully!");
                setShowSuccessAlert(true);
              } else {
                let quote = response?.data;
                quote = {
                  ...quote,
                  relationships: {
                    vendor: {
                      data: {
                        type: "vendors",
                        id: Number(vendorName.value.toString()) || 0,
                      },
                    },
                    lob: { data: { type: "lobs", id: Number(lob.value) } || 0 },
                    project: { data: { type: "projects", id: project_id } },
                  },
                };
                let quoteToPass = getFormattedQuote(
                  quote,
                  vendors?.data,
                  billingFrequencies,
                  lobs?.data
                );
                navigate(
                  `/projects/${project_id}/quotes/edit/${response?.data?.id}`,
                  { state: { new: true, quote: quoteToPass } }
                );
              }
            }
          })
          .catch((err) => {
            let msg = err?.data?.errors?.[0]?.detail;
            setErrorMessages(
              msg
                ? msg
                : "Something went wrong. Your changes could not be saved at this time."
            );
            setShowFailAlert(true);
          });
      }
    };

    useEffect(() => {
      if (newVendorId) {
        const newVendor = vendorOptions?.find((v) => v.value === newVendorId);
        setVendorName(
          newVendor || { value: 0, label: "Search and select vendor name" }
        );
        setNewVendorId("");
      }
    }, [vendors]);

    if (!account || !lobs || lobsLoading || loading) {
      return <SmallSpinner />;
    }
    return (
      <Form
        style={{ marginLeft: "16px" }}
        className="quoteForm"
        onSubmit={(e) => e.preventDefault()}
      >
        {handleAlert(
          showFailAlert,
          errorMessages,
          setShowFailAlert,
          "warning",
          ToastAlert
        )}
        {handleAlert(
          showSuccessAlert,
          successMessage,
          setShowSuccessAlert,
          "success",
          ToastAlert
        )}
        <section>
          <AddVendorSlideout
            slideoutOpen={slideoutOpen}
            setSlideoutOpen={setSlideoutOpen}
            setNewVendorId={setNewVendorId}
            refetch={vendorsRefetch}
          />
          <div className="instructionalText bold">Vendor / Quote Info</div>
          <Row style={{ alignItems: "center", marginRight: "1px" }}>
            <Col sm={10}>
              <DropdownApi
                label="* Vendor Name"
                value={vendorName}
                required={true}
                isInvalid={vendorNameInvalid}
                onChange={(e) => {
                  setVendorNameInvalid(false);
                  const vendorName = e as OptionType;
                  setVendorName(vendorName);
                }}
                getDataHook={useListVendorsForAccountQuery}
                isDisabled={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
            <Col sm={2}>
              <Button
                className="btnSeawhite"
                onClick={() => setSlideoutOpen(true)}
              >
                Add Vendor
              </Button>
            </Col>
          </Row>
          <Row style={{ marginRight: "1px" }}>
            <Col sm={12}>
              <MultipleLineFieldV2
                id="quoteDescription"
                label="Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                readOnly={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
                required={false}
                placeholder="Enter a high-level description of the work being performed"
              />
            </Col>
          </Row>
          <Row style={{ marginRight: "1px" }}>
            <Col sm={6}>
              <RequiredReactSelect
                label="* Line of Business"
                value={lob}
                required={true}
                isInvalid={lobInvalid}
                options={lobOptions}
                onChange={(e) => {
                  setLobInvalid(false);
                  let event = e as OptionType;
                  setLob(event);
                }}
                isDisabled={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
          </Row>
        </section>
        <hr style={{ marginRight: "10px" }} />
        <section>
          <Row style={{ alignItems: "center", marginRight: "1px" }}>
            <Col sm={8}>
              <div className="instructionalText bold">Pricing Info</div>
            </Col>
            <Col sm={4}>
              <Form.Group className="formGroup">
                <Form.Check
                  style={{ marginTop: "2em", color: "#418172" }}
                  type="checkbox"
                  id="deriveFromServicesCheck"
                  label="Derive one-time and recurring costs from services?"
                  checked={deriveFromServices}
                  value="derive_from_services"
                  onChange={() => {
                    setDeriveFromServices(!deriveFromServices);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginRight: "1px" }}>
            <Col sm={6}>
              <FormNumberFieldV2
                label="One Time Cost"
                required={false}
                min={1}
                value={oneTimeCost}
                onChange={(e) => setOneTimeCost(e.target.value)}
                step={1}
                placeholder="Enter one time cost"
                readOnly={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
            <Col sm={6}>
              <FormNumberFieldV2
                label={snakeToText(revenueCalculationLabel)}
                required={false}
                min={0}
                step={0.01}
                value={markup}
                onChange={(e) => setMarkup(e.target.value)}
                placeholder="Enter a markup percentage"
                readOnly={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
          </Row>
          <Row style={{ marginRight: "1px" }}>
            <Col>
              <Select2V2
                label="Includes Recurring Costs"
                value={billingFrequency}
                options={billingFrequencyOptions}
                onChange={(e) => {
                  let event = e as OptionType;
                  setBillingFrequency(event);
                }}
                isDisabled={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
            <Col>
              <FormNumberFieldV2
                label="Recurrence Term"
                required={false}
                min={0}
                step={1}
                value={recurrenceTerm}
                onChange={(e) => setRecurrenceTerm(e.target.value)}
                placeholder="How many times does this recur"
                readOnly={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
            <Col>
              <FormNumberFieldV2
                label="Cost Per Term"
                required={false}
                min={0}
                step={0.01}
                value={costPerTerm}
                onChange={(e) => setCostPerTerm(e.target.value)}
                placeholder="Enter a cost per term"
                readOnly={
                  (permission === "view" ||
                    permission === "create" ||
                    project?.data?.attributes?.status !== "building") &&
                  lastWordInLocation !== "new"
                }
              />
            </Col>
          </Row>
        </section>
      </Form>
    );
  }
);

export default QuoteForm;
