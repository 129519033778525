import React from "react";
import { Link } from "react-router-dom";

function RouterButton({ path, className, iconClass, buttonText }) {
  return (
    <Link to={path}>
      <p className={className}>
        <i className={iconClass}></i> {buttonText}
      </p>
    </Link>
  );
}

export default RouterButton;
