import React from "react"
import PropTypes from "prop-types"
import 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import Widget from './Widget'
import { COLORS } from './config'

class WidgetWinLoss extends Widget {
  componentDidMount() {
    fetch(this.props.dataURL)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json, loading: false }))
  }

  renderChart () {
    let { data } = this.state
    if (!data || data.length == 0) return;
    let chartData = {
      labels: Object.keys(Object.values(data)[0]),
      datasets: Object.entries(data).map((dataset, index) => {
        return {
          label: dataset[0],
          data: Object.values(dataset[1]),
          borderColor: '#FFFFFF',
          backgroundColor: COLORS[index]
        }
      })
    }

    return(
      <Bar
        data={chartData}
        height={180}
        options={{
          plugins: {
            legend: { display: false },
            tooltips: {
              intersect: false,
              mode: 'index'
            }
          },
          scales: {
            x: { display: false, stacked: true, barThickness: 12 },
            y: { stacked: true }
          },
        }}
      />)
  }

  renderBody () {
    return(
      <React.Fragment>
      {this.renderChart()}
      </React.Fragment>
    )
  }

  renderFooter () {
    return null
  }
}

export default WidgetWinLoss
