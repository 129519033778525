import { useEffect, useState } from "react";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { getSurveyTags } from "../SurveyHelpers/surveyHelpers";
import { useNavigate } from "react-router";
import useListCompletedSurveys from "./api/useListCompletedSurveys";
import DataTable from "@components/DataTable";
import type { ColumnDef } from "@tanstack/react-table";
import { CompletedSurvey } from "./types";
import { faSortDown, faSortUp, faSort } from "@fortawesome/pro-solid-svg-icons";

const CompletedSurveys = ({
  filterParams,
  surveysCount,
  setSurveysCount,
}): JSX.Element => {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(""); // State to track the sorting column
  const [sortOrder, setSortOrder] = useState("asc");

  const { completedSurveys, isLoading, pageMeta, isFetching } =
    useListCompletedSurveys({
      pageSize,
      pageNumber,
      filterParams,
      sortBy,
      sortOrder,
    });

  useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      pageMeta.recordCount !== surveysCount.completedSurveys
    )
      setSurveysCount({
        ...surveysCount,
        completedSurveys: pageMeta.recordCount,
      });
  }, [pageMeta]);

  const handleSort = (columnId: string) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  const renderSortIcon = (columnId: string) => {
    if (sortBy === columnId) {
      return sortOrder === "asc" ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      );
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const columns: ColumnDef<CompletedSurvey>[] = [
    {
      id: "name",
      header: () => (
        <div className="surveysColumnHeader">
          Survey Name
          <div className="sortIcon" onClick={() => handleSort("name")}>
            {renderSortIcon("name")}
          </div>
        </div>
      ),
      cell(props) {
        const survey = props.row.original;
        const name = survey.attributes.name;
        return name === "No surveys found" ? (
          <>{name}</>
        ) : (
          <span
            className="surveyName"
            onClick={
              survey.attributes.questionnaireId
                ? () =>
                    navigate(
                      `/surveys/${survey.attributes.questionnaireId}/take`,
                      {
                        state: { surveyId: survey.id },
                      }
                    )
                : () =>
                    alert(
                      "This survey does not have any questions attached to it. Please go to settings to configure this survey's questions."
                    )
            }
          >
            {name}
          </span>
        );
      },
    },
    {
      id: "project.project_name",
      header: () => (
        <div className="surveysColumnHeader">
          Related Project{" "}
          <div
            className="sortIcon"
            onClick={() => handleSort("project.project_name")}
          >
            {renderSortIcon("project.project_name")}
          </div>
        </div>
      ),
      cell(props) {
        const survey = props.row.original;
        return survey.attributes?.project ? (
          <div className="relatedProject">
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              onClick={() =>
                window.open(
                  `/projects/${survey.attributes.projectId}/edit`,
                  "_blank"
                )
              }
              className="previewProject"
            />
            <span
              onClick={() =>
                window.open(
                  `/projects/${survey.attributes.projectId}/edit`,
                  "_blank"
                )
              }
            >
              {survey.attributes.project}
            </span>
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      id: "contact_name",
      header: () => (
        <div className="surveysColumnHeader">
          Completed By{" "}
          <div className="sortIcon" onClick={() => handleSort("contact_name")}>
            {renderSortIcon("contact_name")}
          </div>
        </div>
      ),
      cell(props) {
        return <>{props.row.original.attributes?.completedBy || ""}</>;
      },
    },
    {
      id: "updated_at",
      header: () => (
        <div className="surveysColumnHeader">
          Completed On{" "}
          <div className="sortIcon" onClick={() => handleSort("updated_at")}>
            {renderSortIcon("updated_at")}
          </div>
        </div>
      ),
      cell(props) {
        return <>{props.row.original.attributes?.completedOn || ""}</>;
      },
    },
    {
      id: "tag_list",
      header: "Tags",
      cell(props) {
        return getSurveyTags(props.row.original.attributes?.tags || []);
      },
    },
  ];

  return isLoading || isFetching ? (
    <SmallSpinner />
  ) : (
    <DataTable
      data={
        completedSurveys.length
          ? completedSurveys
          : [
              {
                id: "0",
                attributes: { name: "No surveys found" },
              },
            ]
      }
      columns={columns}
      bordered
      striped
      hover
      totalPages={pageMeta.pageCount}
      totalRows={pageMeta.recordCount}
      currentPage={pageNumber}
      setCurrentPage={setPageNumber}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      paginationEnabled
      pageSizeEnabled
    />
  );
};

export default CompletedSurveys;
