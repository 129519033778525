import React from "react"
import PropTypes from "prop-types"
import Loader from './Loader'

class WidgetTotals extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, data: {} }
  }

  componentDidMount() {
    fetch(this.props.dataURL)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json, loading: false }))
  }

  render () {
    const { data, loading } = this.state
    return (
      <div className="row" style={{position: 'relative'}}>
        <div className="col-sm-2 widget-total">
          <div className="widget-title">Projects Scoped This Month</div>
          <div className="widget-value">{data.scoped_count}</div>
        </div>
        <div className="col-sm-2 widget-total">
          <div className="widget-title">AVG Win/Loss %</div>
          <div className="widget-value">{new Intl.NumberFormat(dashboardLocale, { maximumFractionDigits: 2 } ).format(data.win_loss * 100 || 0)}%</div>
        </div>
        <div className="col-sm-2 widget-total">
          <div className="widget-title">AVG Margin Per Project</div>
          <div className="widget-value">{new Intl.NumberFormat(dashboardLocale, { maximumFractionDigits: 2 } ).format(data.avg_margin * 100 || 0)}%</div>
        </div>
        <div className="col-sm-3 widget-total">
          <div className="widget-title">AVG Revenue Per Project</div>
          <div className="widget-value">{new Intl.NumberFormat(dashboardLocale, currencyFormat).format(data.avg_cost || 0)}</div>
        </div>
        <div className="col-sm-3 widget-total">
          <div className="widget-title">Total Revenue Scoped</div>
          <div className="widget-value">{new Intl.NumberFormat(dashboardLocale, currencyFormat).format(data.total_revenue || 0)}</div>
        </div>
        {loading && <Loader />}
      </div>
    );
  }
}

export default WidgetTotals
