import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { generated } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { AppDispatch } from "@store/*";
import { Product } from "../../types";
import { useListProjectProductsForAccountQuery } from "@generated";
import { snakeToText } from "@utils/helperFunctions";

const useGetAllProducts = ({
  projectId,
}: {
  projectId: string | undefined;
}) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const dispatch = useDispatch<AppDispatch>();
  const { data, isLoading, error, refetch } =
    useListProjectProductsForAccountQuery({
      filter: { project: projectId },
      slug: accountSlug,
      page: {},
      include: ["product"],
    });
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(isLoading);

  const mapProductData = (productData) => {
    return productData.map((product) => ({
      id: product.id || 0,
      type: product.type,
      active: product.active,
      source: product.attributes.source,
      name: product.attributes.name,
      description: product.attributes.description || "",
      quantity: product.attributes.quantity || 1,
      sku: product.attributes.sku || "",
      productId: product.attributes["product-id"] || "",
      mfrPartNumber: product.attributes["manufacturer-part-number"] || "",
      units: product.attributes["unit-of-measure"] || "",
      category: product.attributes.category || "",
      subcategory: product.attributes.subcategory || "",
      price: product.attributes["unit-price"] || "",
      hardwarePrice: product.attributes["hardware-price"] || "",
      hardwareCost: product.attributes["hardware-cost"] || "",
      unitCost: product.attributes["unit-cost"] || "",
      custom: product.attributes.custom || false,
      lineNumber: product.attributes["line-number"] || "",
      vendorDiscount: product.attributes["vendor-discount"] || "",
      listPrice: product.attributes["list-price"] || "",
      markup: product.attributes.markup || "",
      billingFrequency: product.attributes["billing-frequency"] || "one_time",
      customHardwarePrice:
        product.attributes["custom-hardware-price?"] || false,
      customHardwareCost: product.attributes["custom-hardware-cost?"] || false,
      relationships: {
        product: product.relationships.product?.data?.id
          ? product.relationships.product
          : null,
      },
    }));
  };

  useEffect(() => {
    const fetchProducts = async () => {
      if (!isLoading && data && data.data && data.meta && !error) {
        setProducts(mapProductData(data.data));
        const pageCount = data.meta["page-count"] || 1;
        if (pageCount > 1) {
          setLoading(true);
          try {
            for (let page = 2; page <= pageCount; page++) {
              const resultAction = await dispatch(
                generated.endpoints.listProjectProductsForAccount.initiate({
                  slug: accountSlug,
                  page: { number: page },
                })
              ).unwrap();

              if (resultAction.data && Array.isArray(resultAction.data)) {
                setProducts((prevProducts) => [
                  ...prevProducts,
                  ...mapProductData(resultAction.data),
                ]);
              }
            }
          } catch (err) {
            console.error("Failed to fetch additional pages:", err);
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      }
    };

    fetchProducts();
  }, [accountSlug, data, dispatch, error, isLoading]);

  return { products, loading, error, refetch };
};

export default useGetAllProducts;
