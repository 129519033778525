import React from "react";
import Location from "../Location/Location";

function NewLocation({
  account_slug,
  project_id,
  projectStatus,
  locations,
  locationId,
  account_id,
  setLocationCreated,
  projVariables,
  authorizationCode,
}) {
  return (
    <Location
      account_slug={account_slug}
      project_id={project_id}
      projectStatus={projectStatus}
      locations={locations}
      locationId={locationId}
      account_id={account_id}
      setLocationCreated={setLocationCreated}
      projVariables={projVariables}
      authorizationCode={authorizationCode}
    />
  );
}

export default NewLocation;
