const formatData = ({ data, included }) => {
  const lobs = included.filter((item) => item.type === "line-of-businesses");
  const phases = included.filter((item) => item.type === "phases");
  const serviceCategories = included.filter(
    (item) => item.type === "service-categories"
  );
  const allSubservices = included.filter((item) => item.type === "subservices");
  const resources = included.filter((item) => item.type === "resources");
  const standardServices = data
    .map((service) => {
      const lob = service.attributes["lob-id"]
        ? lobs.find((lob) => lob.id == service.attributes["lob-id"])
        : "None";

      const phase = service.relationships.phase.data
        ? phases.find(
            (phase) => phase.id === service.relationships.phase.data.id
          )
        : "None";

      const serviceCategory = service.relationships["service-category"].data
        ? serviceCategories.find(
            (serviceCategory) =>
              serviceCategory.id ===
              service.relationships["service-category"].data.id
          ).attributes.name
        : "None";

      const subservices = allSubservices.filter(
        (subservice) => subservice.relationships.service.data.id === service.id
      );

      return {
        id: service.id,
        name: service.attributes.name || "",
        category: serviceCategory,
        lob: lob.attributes.name || "",
        phase: phase,
        tags: service.attributes["tag-list"] || "",
        relationships: service.relationships,
        attributes: service.attributes,
        type: service.type,
        subservices,
      };
    })
    .filter((item) => item !== undefined)
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  return { standardServices, subservices: allSubservices, resources };
};

export default formatData;
