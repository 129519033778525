import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProjectVersions from "./ProjectVersions/ProjectVersions";
import NewVersion from "./NewVersion/NewVersion";
import EditVersion from "./EditVersion/EditVersion";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import ChangesInVersion from "./Changes/ChangesInVersion";
import ChangesSinceVersion from "./Changes/ChangesSinceVersion";

function VersionsMspa({ account_slug, project_id, account_id }) {
  //Env
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  //States
  const [versions, setVersions] = useState([]);
  // const [changesSince, setChangesSince] = useState([]);
  // const [changesIn, setChangesIn] = useState([]);
  const [versionId, setVersionId] = useState(null);
  const [versionCreated, setVersionCreated] = useState(false);
  const [versionUpdated, setVersionUpdated] = useState(false);
  const [projectStatus, setProjectStatus] = useState("");
  const [currentUser, setCurrentUser] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  // When page loads, call API to get Versions and lift it up to the state
  useEffect(() => {
    if (authorizationCode != "") {
      //Get Project Status
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        const status = data.attributes.status;
        if (status !== null) setProjectStatus(status);

        //Get Versions
        API.Get(
          data.relationships["project-versions"].links.related,
          authorizationCode
        ).then((response) => {
          let versions = response.data.data;
          setVersions(versions);
          setIsLoading(false);

          versions.forEach((version) => {
            if (version.id == id) {
              setVersionId(version.id);
              //Get audit-logs for changesSince
              // API.Get(
              //   `${apiHost}/${account_slug}/v1/projects/${project_id}/audit-logs?filter[after]=${version.attributes["started-at"]}`,
              //   authorizationCode
              // ).then((response) => {
              //   let changes = response.data.data;
              //   setChangesSince(changes);
              // });
              // //Get Audit logs for changesIn
              // API.Get(
              //   `${apiHost}/${account_slug}/v1/projects/${project_id}/audit-logs?filter[after]=${version.attributes["started-at"]}&filter[before]=${version.attributes["completed-at"]}`,
              //   authorizationCode
              // ).then((response) => {
              //   let changes = response.data.data;
              //   setChangesIn(changes);
              // });
            }
          });
        });
      });

      // Get current user
      API.Get(
        `${apiHost}/${account_slug}/v1/users/current`,
        authorizationCode
      ).then((response) => {
        setCurrentUser(response.data.attributes.name);
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/project_versions"}
            element={
              <ProjectVersions
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                versionId={versionId}
                setVersionId={(versionId) => setVersionId(versionId)}
                projectStatus={projectStatus}
                versions={versions}
                versionCreated={versionCreated}
                versionUpdated={versionUpdated}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_versions/new"}
            element={
              <NewVersion
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                versions={versions}
                currentUser={currentUser}
                setVersionCreated={(created) => setVersionCreated(created)}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/project_versions/:versionId/edit"}
            element={
              <EditVersion
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                versions={versions}
                currentUser={currentUser}
                versionId={versionId}
                setVersionUpdated={(updated) => setVersionUpdated(updated)}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={
              "/projects/:project_id/project_versions/:versionId/audit_logs"
            }
            element={
              <ChangesInVersion
                versions={versions}
                // changesIn={changesIn}
                // changesSince={changesSince}
                project_id={project_id}
                account_slug={account_slug}
                authorizationCode={authorizationCode}
                isLoading={isLoading}
              />
            }
          />
          <Route
            path={
              "/projects/:project_id/project_versions/:versionId/audit_logs?changes=true"
            }
            element={
              <ChangesSinceVersion
                versions={versions}
                // changesIn={changesIn}
                // changesSince={changesSince}
                project_id={project_id}
                account_slug={account_slug}
                authorizationCode={authorizationCode}
                isLoading={isLoading}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default VersionsMspa;
