import DataTable from "@components/DataTable";
import useListTeamsWithUsers from "./api";
import type { ColumnDef } from "@tanstack/react-table";
import type { Team } from "./types";
import { useState } from "react";
import { Button, Card, Form, Col, Row } from "react-bootstrap";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import SearchField from "@components/Forms/SearchField/SearchField";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDeleteTeamMutation } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import SlideOutMenuSectioned from "@components/SlideOutMenu/SlideOutMenuSectioned";
import { faEye } from "@fortawesome/pro-solid-svg-icons";

const Show = (): JSX.Element => {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [teamToSearch, setTeamToSearch] = useState("");
  const [filter, setFilter] = useState<{ name?: string }>({});
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [deleteTeam, {}] = useDeleteTeamMutation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteQueue, setDeleteQueue] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [slideOutContent, setSlideOutContent] = useState<{
    title: string;
    content: any[];
  }>({ title: "", content: [] });

  const { teams, pageMeta, isLoading, refetch } = useListTeamsWithUsers({
    page: {
      number: pageNumber,
      size: pageSize,
    },
    filter,
  });

  const handleDelete = () => {
    setDeleteQueue([...selectedIds]);
    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    if (deleteQueue.length === 0) return;

    const id = deleteQueue[0];
    deleteTeam({ slug: accountSlug, id: parseInt(id) })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((error) => {
        console.error(error);
      });

    setDeleteQueue(deleteQueue.slice(1));
  };

  const handleConfirm = () => {
    confirmDelete();
    if (deleteQueue.length <= 1) {
      setShowConfirmationModal(false);
      setSelectedIds([]);
    }
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
    setDeleteQueue([]);
  };

  const searchTeamName = (teamName: string) => {
    setFilter({ name: teamName });
  };

  const handleTeamSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchTeamName(teamToSearch);
  };

  const getConfirmationMessage = () => {
    const targetId = Number(deleteQueue[0]);

    const teamToDelete = teams.find((team) => team.id == targetId) || {
      name: "",
      users: [],
      questionnaires: [],
      services: [],
    };

    const { name, users, questionnaires, services } = teamToDelete;

    return (
      <>
        <p
          style={{
            color: "var(--navy-1, #1C2655)",
            fontSize: "14px",
            fontWeight: "700",
          }}
        >
          Are you sure you want to delete {name}?
        </p>
        <p
          style={{
            color: "var(--navy-1, #1C2655)",
            fontSize: "14px",
          }}
        >
          This team has <strong>{users.length}</strong> members and is assigned
          to <strong>{questionnaires.length}</strong> surveys and{" "}
          <strong>{services.length}</strong> services.
        </p>
        <p
          style={{
            color: "var(--navy-1, #1C2655)",
            fontSize: "14px",
          }}
        >
          Deleting a team is final and cannot be undone.
        </p>
      </>
    );
  };

  const columns: ColumnDef<Team>[] = [
    {
      header: "Team Name",
      cell(props) {
        return (
          <div
            className="teamName"
            onClick={() => {
              localStorage.setItem("lastPage", window.location.href);
              navigate(`edit/${props.row.original.id}`);
            }}
          >
            {props.row.original.name}
          </div>
        );
      },
    },
    {
      header: "Assigned Users",
      cell(props) {
        return (
          <>
            {props.row.original.users
              .slice(0, 2)
              .map((user) => user.name)
              .join(", ")}
            {props.row.original.users.length > 2 && (
              <FontAwesomeIcon
                onClick={() => {
                  setSlideOutContent({
                    title: "Users",
                    content: getSlideOutTable(
                      "users",
                      props.row.original.users
                    ),
                  });
                  setIsOpen(true);
                }}
                icon={faEye}
              />
            )}
          </>
        );
      },
    },
    {
      header: "Assigned Services",
      cell(props) {
        return (
          <>
            {props.row.original.services
              .slice(0, 2)
              .map((service) => service.name)
              .join(", ")}
            {props.row.original.services.length > 2 && (
              <FontAwesomeIcon
                onClick={() => {
                  setSlideOutContent({
                    title: "Services",
                    content: getSlideOutTable(
                      "services",
                      props.row.original.services
                    ),
                  });
                  setIsOpen(true);
                }}
                icon={faEye}
              />
            )}
          </>
        );
      },
    },
    {
      header: "Assigned Surveys",
      cell(props) {
        return (
          <>
            {props.row.original.questionnaires
              .slice(0, 2)
              .map((questionnaire) => questionnaire.name)
              .join(", ")}
            {props.row.original.questionnaires.length > 2 && (
              <FontAwesomeIcon
                onClick={() => {
                  setSlideOutContent({
                    title: "Surveys",
                    content: getSlideOutTable(
                      "surveys",
                      props.row.original.questionnaires
                    ),
                  });
                  setIsOpen(true);
                }}
                icon={faEye}
              />
            )}
          </>
        );
      },
    },
    {
      header: "Created On",
      cell(props) {
        return new Date(props.row.original.createdAt).toLocaleDateString();
      },
    },
  ];
  if (isLoading) {
    return <ScopeStackSpinner />;
  }

  interface Column {
    header: string;
    cell: (props: any) => JSX.Element;
  }

  const getSlideOutTable = (type, data) => {
    const column: Column[] = [
      {
        header: type,
        cell(props) {
          return props.row.original.name;
        },
      },
    ];

    return [
      {
        sectionTitle: "",
        sectionClass: "",
        sectionContent: (
          <aside>
            <DataTable data={data} columns={column} striped />
          </aside>
        ),
      },
    ];
  };

  return (
    <div className="teamsTable">
      <SlideOutMenuSectioned
        show={isOpen}
        setShow={setIsOpen}
        menuHeader={`All Assigned ${slideOutContent.title}`}
        actionButtonText={null}
        onActionButtonClick={() => setIsOpen(false)}
        clearFields={null}
        onCancel={() => setIsOpen(false)}
        onClearFieldsClick={() => null}
        sectionsArray={slideOutContent.content}
      />
      <Card
        style={{
          padding: "10px",
          borderRadius: "4px",
          border: "1px solid var(--gray-3-stroke, #D7D7D7)",
          background: " var(--white, #FFF)",
          boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Card.Header
          style={{
            backgroundColor: "#fff",
            lineHeight: "unset",
            display: "block",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="accordianTitle" style={{ margin: "0" }}>
              Teams
            </div>
            <Button onClick={() => navigate(`create`)}>Create A Team</Button>
          </div>
          <hr></hr>
          <div
            style={{
              display: "flex",
            }}
          >
            {selectedIds.length > 0 && (
              <Button
                style={{
                  marginRight: "10px",
                  alignSelf: "flex-end",
                  background: "unset",
                  color: "var(--teal-1, #418172)",
                  fontWeight: "700",
                  width: "11em",
                  height: "40px",
                  borderRadius: "4px",
                  border: "1px solid var(--gray-3-stroke, #D7D7D7)",
                }}
                onClick={handleDelete}
              >
                {selectedIds.length} Selected{" "}
                <span
                  style={{
                    borderLeft: "1px solid #D7D7D7",
                    height: "24px",
                    margin: "0 8px",
                  }}
                ></span>
                <FontAwesomeIcon style={{ color: "black" }} icon={faTrashCan} />
              </Button>
            )}

            <div style={{ width: "-webkit-fill-available" }}>
              <FormFieldLabel label="Search Teams" className={undefined} />
              <Form
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleTeamSearch(e);
                  }
                }}
                onSubmit={handleTeamSearch}
              >
                <SearchField
                  placeholder="Search by team name"
                  onChange={(e) => {
                    setTeamToSearch(e.target.value);
                  }}
                  value={teamToSearch}
                  onClick={() => {
                    setTeamToSearch("");
                    searchTeamName("");
                  }}
                  fullWidth
                />
              </Form>
            </div>
          </div>
        </Card.Header>

        <DataTable
          data={teams}
          columns={columns}
          selectable
          bordered
          striped
          hover
          totalPages={pageMeta.pageCount}
          totalRows={pageMeta.recordCount}
          currentPage={pageNumber}
          setCurrentPage={setPageNumber}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          paginationEnabled
          pageSizeEnabled
        />
      </Card>
      <ConfirmationModal
        title="Confirm Delete"
        message={getConfirmationMessage()}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={showConfirmationModal}
        onHide={handleCancel}
      />
    </div>
  );
};

export default Show;
