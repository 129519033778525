import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import FormTextField from "@components/Forms/FormTextField";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import BackButton from "@components/Buttons/BackButton/BackButton";
import DisabledTextField from "@components/Forms/DisabledTextField";
import FormNumberField from "@components/Forms/FormNumberField";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";

function SowBlueprint({ account_slug, project_id, account_id }) {
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const [professionalServices, setProfessionalServices] = useState([]);
  const [managedServices, setManagedServices] = useState([]);
  const [resources, setResources] = useState([]);
  const [changedQuantities, setChangedQuantities] = useState([]);
  const [changedResources, setChangedResources] = useState([]);
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);

  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [submitFail, setSubmitFail] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get Project Data
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-services,project-services.service,project-services.resource`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        const includedData = response.data.included.filter(
          (item) => item.attributes.active === true
        );
        let professionalServices = [];
        let managedServices = [];
        setName(data.attributes["project-name"]);

        // Get Resources
        API.Get(
          `${apiHost}/${account_slug}/v1/resources`,
          authorizationCode
        ).then((response) => {
          let resources = response.data.data;
          if (resources !== null && resources.length !== 0) {
            setResources(resources);
          }

          if (includedData) {
            for (let i = 0; i < includedData.length; i++) {
              if (includedData[i].type === "project-services") {
                if (
                  includedData[i].attributes["service-type"] ===
                    "professional_services" &&
                  includedData[i].attributes["task-source"] !== "third_party"
                ) {
                  professionalServices.push(includedData[i]);
                }
                if (
                  includedData[i].attributes["service-type"] ===
                    "managed_services" &&
                  includedData[i].attributes["task-source"] !== "third_party"
                ) {
                  managedServices.push(includedData[i]);
                }
              }
            }
            setProfessionalServices(professionalServices);
            setManagedServices(managedServices);
          }
          setIsLoading(false);
        });
      });
    }
  }, [authorizationCode]);

  const getTableData = (services) => {
    return services.map((service, index) => {
      let serviceId = service.id;
      let resource = "";

      const resourceOptions = resources.map((resource) => {
        return (
          <option accessKey={resource.id} key={resource.id}>
            {resource.attributes.name}
          </option>
        );
      });

      for (let i = 0; i < resources.length; i++) {
        let resourceRelationship = service.relationships.resource;
        let resourceData = "";
        if (resourceRelationship) {
          resourceData = service.relationships.resource.data;
        }
        if (resourceData && resources[i].id === resourceData.id) {
          resource = resources[i].attributes.name;
        }
      }

      const getSecondColumn = () => {
        if (services === managedServices) {
          return {
            class: "col-sm-3",
            name: "",
          };
        }
        if (services === professionalServices) {
          return {
            class: "col-sm-3",
            name: (
              <ControlledDropDown
                defaultValue={resource}
                options={resourceOptions}
                onChange={(e) => {
                  let selected = e.target.options.selectedIndex;
                  let resource = e.target.options[selected].accessKey;
                  setChangedResources((changedResources) => {
                    e.persist();
                    return [
                      ...changedResources,
                      {
                        service: serviceId,
                        resourceId: resource,
                      },
                    ];
                  });
                }}
              />
            ),
          };
        }
      };

      // Map returns data for ScopeStackTable Class
      return {
        rowClass: "blueprintRow row",
        onClick: null,
        columns: [
          {
            class: "col-sm-6",
            name: <DisabledTextField value={service.attributes.name} />,
          },
          {
            class: "col-sm-3",
            name: (
              <FormNumberField
                defaultValue={service.attributes.quantity}
                onChange={(e) => {
                  let value = e.target.value;
                  setChangedQuantities((changedQuantities) => {
                    e.persist();
                    return [
                      ...changedQuantities,
                      {
                        service: serviceId,
                        quantity: value,
                      },
                    ];
                  });
                }}
              />
            ),
          },
          getSecondColumn(),
        ],
        actions: null,
      };
    });
  };

  const professionalServicesHeaderData = {
    rowClass: "row",
    columns: [
      {
        class: "col-sm-6",
        name: "Service",
      },
      {
        class: "col-sm-3",
        name: "Quantity",
      },
      {
        class: "col-sm-3",
        name: "Resource",
      },
    ],
  };

  const managedServicesHeaderData = {
    rowClass: "row",
    columns: [
      {
        class: "col-sm-6",
        name: "Service",
      },
      {
        class: "col-sm-3",
        name: "Quantity",
      },
      {
        class: "col-sm-3",
        name: <span>&nbsp;</span>,
      },
    ],
  };

  const professionalTableData = getTableData(professionalServices);
  const managedServicesTableData = getTableData(managedServices);
  const footerData = null;

  let professionalServicesTable = new ScopeStackTable(
    "services",
    professionalServicesHeaderData,
    professionalTableData,
    footerData
  );

  let managedServicesTable = new ScopeStackTable(
    "services",
    managedServicesHeaderData,
    managedServicesTableData,
    footerData
  );

  const handleSubmit = (e) => {
    setSubmitFail(false);
    e.preventDefault();
    let blueprintId = null;

    // Post blueprint
    const blueprint = {
      data: {
        type: "blueprints",
        attributes: {
          name: name,
          code: code,
        },
        relationships: {
          account: { data: { type: "accounts", id: account_id } },
        },
      },
    };

    let promises = [];
    API.Post(
      `${apiHost}/${account_slug}/v1/blueprints/create-from-project?project_id=${project_id}`,
      blueprint,
      authorizationCode
    )
      .then((response) => {
        if (response.status !== 201) {
          setSubmitFail(true);
          return;
        }

        blueprintId = response.data.data.id;
      })
      .then((res) => {
        if (submitFail === false) {
          window.location.replace(`${appHost}/projects/${project_id}/edit`);
        }
      })
      .catch((err) => {
        setSubmitFail(true);
        setErrorMessages(formatUnprocessibleResponse(err, "blueprint"));
        setShowFailAlert(true);
      });
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleFailAlert()}
      <Card>
        <CardHeader title="New Project Blueprint" />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                <FormTextField
                  required={true}
                  label="Name *"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
              <Col sm={6}>
                <FormTextField
                  label="Code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <h2 style={{ margin: "2em 0 0" }}>Professional Services</h2>
                <BaseTable
                  className="blueprintTable"
                  striped={false}
                  hover={true}
                  bordered={false}
                  headerRows={professionalServicesTable.buildHeaderRows()}
                  dataRows={professionalServicesTable.buildDataRows()}
                  footerRows={professionalServicesTable.buildFooterRows()}
                  isLoading={isLoading}
                />
                <h2 style={{ margin: "2em 0 0" }}>Managed Services</h2>
                <BaseTable
                  className="blueprintTable"
                  striped={false}
                  hover={true}
                  bordered={false}
                  headerRows={managedServicesTable.buildHeaderRows()}
                  dataRows={managedServicesTable.buildDataRows()}
                  footerRows={managedServicesTable.buildFooterRows()}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <SubmitButton />
              </Col>
              <Col xs={6}>
                <BackButton url={`/projects/${project_id}/edit`} />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default SowBlueprint;
