import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import ResourcePlans from "./ResourcePlans/ResourcePlans";
import PlanningDetails from "./PlanningDetails/PlanningDetails";

function ResourcePlanningMspa({ account_slug, project_id, account_id }) {
  //Env
  const [authorizationCode] = useOauth();

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  //States
  const [projectStatus, setProjectStatus] = useState("");
  const [currencyUnit, setCurrencyUnit] = useState("");
  const [scenarios, setScenarios] = useState([]);
  const [phases, setPhases] = useState([]);
  const [lobs, setLobs] = useState([]);
  const [resources, setResources] = useState([]);
  const [resourceAllocations, setResourceAllocations] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode != "") {
      //Get resource-plans
      API.Get(
        `${apiHost}/${account_slug}/v1/resource-plans?filter[project]=${project_id}?include=resource-allocations,resource-allocations.project-resource`,
        authorizationCode
      ).then((res) => {
        setScenarios(res.data.data);
      });

      // Get Service Categories
      API.Get(
        `${apiHost}/${account_slug}/v1/service-categories`,
        authorizationCode
      ).then((response) => {
        setLobs(response.data.data);
      });

      // Get Project Data with phases included & Project Status
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-phases,project-phases.phase,project-resources,project-resources.resource,resource-plans`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;

        if (data.attributes.status !== null)
          setProjectStatus(data.attributes.status);
        setCurrencyUnit(data.attributes["field-labels"].currency_unit);

        if (response.data.included) {
          //Project Phases
          let projectPhases = response.data.included.filter(
            (item) => item.type === "project-phases"
          );
          setPhases(projectPhases);

          //Project Resources
          let projectResources = response.data.included.filter(
            (item) => item.type === "project-resources"
          );
          setResources(projectResources);
        }

        setIsLoading(false);
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/resource_plans"}
            element={
              <ResourcePlans
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                currencyUnit={currencyUnit}
                scenarios={scenarios}
                setScenarios={(s) => setScenarios(s)}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            exact
            path={"/projects/:project_id/resource_plans/:resource_id"}
            element={
              <PlanningDetails
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                currencyUnit={currencyUnit}
                scenarios={scenarios}
                setScenarios={(s) => setScenarios(s)}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
                phases={phases}
                resources={resources}
                lobs={lobs}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default ResourcePlanningMspa;
