import React from "react";
import { Form } from "react-bootstrap";
import FormFieldLabel from "./FormFieldLabel/FormFieldLabel";

function FormTextField({
  label,
  id,
  placeholder,
  value,
  onChange,
  required,
  readOnly,
}) {
  return (
    <Form.Group className="formGroup">
      <FormFieldLabel label={label} />
      <Form.Control
        required={required}
        id={id}
        value={value === null ? "" : value}
        placeholder={placeholder}
        onChange={onChange}
        type="text"
        readOnly={readOnly}
      />
    </Form.Group>
  );
}

export default FormTextField;
