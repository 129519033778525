import React from "react";
import Note from "../Note/Note";

function EditNote({
  account_slug,
  project_id,
  projectStatus,
  notes,
  noteId,
  account_id,
  setNoteUpdated,
  currentUser
}) {
  return (
    <Note
      account_slug={account_slug}
      project_id={project_id}
      projectStatus={projectStatus}
      notes={notes}
      currentUser={currentUser}
      noteId={noteId}
      account_id={account_id}
      setNoteUpdated={setNoteUpdated}
    />
  );
}

export default EditNote;
