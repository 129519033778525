import React, { useState, useEffect } from "react";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import NavTabs from "@components/NavTabs/NavTabs";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import API from "@API";
import ImportButton from "@components/Buttons/ImportButton/ImportButton";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import ArrowDropdownButton from "@components/Buttons/ArrowDropdownButton/ArrowDropdownButton";
import {
  removeDuplicates,
  snakeToPascalCase,
  splitPascalCase,
  toSnakeCase,
  wordCount,
  capitalizeFirstLetter,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";

function QuotedService({
  account_slug,
  project_id,
  account_id,
  projectStatus,
  vendorQuoteId,
  languageFields,
  phases,
  thirdPartyServices,
  subtasks,
  vendorQuotes,
  deriveFromServices,
  frequencyOptions,
  isLoading,
  rateType,
  authorizationCode,
  setThirdPartyServices,
  setSubtasks
}) {
  // Env
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  let navigate = useNavigate();
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 3];
  if (lastWordInLocation !== "new") {
    id = locationArr[locationArr.length - 2];
  }

  // Alerts
  const [updatedAlertIsOpen, setUpdatedAlertIsOpen] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Form field states
  const [quoteId, setQuoteId] = useState(null);
  const [languageField, setLanguageField] = useState("");
  const [services, setServices] = useState([]);
  const [subservices, setSubservices] = useState([]);
  const [tableIsLoading, setTableIsLoading] = useState(false);

  useEffect(() => {
    for (let i = 0; i < languageFields.length; i++) {
      setLanguageField(languageFields[0].attributes.name);
    }

    const getFrequency = (service) => {
      let frequency = service.attributes["payment-frequency"];
      if (frequency.includes("_")) {
        return splitPascalCase(snakeToPascalCase(frequency));
      }
      return capitalizeFirstLetter(frequency);
    };

    setServices(
      thirdPartyServices.map((service) => {
        let phaseData = service.relationships["project-phase"].data;
        let attr = service.attributes;
        let languages = attr["original-languages"];
        languages["service_description"] =
          attr["original-description"] != null
            ? attr["original-description"]
            : "";
        return {
          id: service.id,
          type: "service",
          new: false,
          phase: phaseData ? phaseData.id : "noPhase",
          name: attr.name,
          quantity: attr.quantity,
          hours: attr["est-hours"],
          rate: attr["est-rate"],
          cost: attr["est-cost"],
          frequency: getFrequency(service),
          languages: languages,
          changed: false,
        };
      })
    );
    setSubservices(
      subtasks.map((subservice) => {
        let attr = subservice.attributes;
        let languages = attr["original-languages"];
        languages["service_description"] =
          attr["original-description"] != null
            ? attr["original-description"]
            : "";
        return {
          serviceId: attr["source-id"],
          id: subservice.id,
          type: "subservice",
          new: false,
          name: attr.name,
          quantity: attr.quantity,
          hours: attr["est-hours"],
          rate: attr["est-rate"],
          cost: attr["est-cost"],
          frequency: getFrequency(subservice),
          isServiceDerived: attr["service-pricing"],
          languages: languages,
          changed: false,
        };
      })
    );

    // Make Vendor description tab active for edit page
    const vendorDescriptionTab =
      document.querySelector(".nav-tabs").children[1];
    vendorDescriptionTab.classList.remove("inactive");
    vendorDescriptionTab.classList.add("active");
    setQuoteId(id);
  }, [
    languageFields,
    phases,
    thirdPartyServices,
    subtasks,
    deriveFromServices,
    isLoading,
  ]);

  const languageOptions = languageFields.map((languageField) => {
    return (
      <option accessKey={languageField.id} key={languageField.id}>
        {languageField.attributes.name}
      </option>
    );
  });

  const phaseOptions = phases.map((phase) => {
    return (
      <option accessKey={phase.id} key={phase.id}>
        {phase.attributes.name}
      </option>
    );
  });

  phaseOptions.unshift(<option accessKey="noPhase" key="noPhase"></option>);

  const getLanguages = () => {
    let languages = languageFields.map((language) => ({
      [language.attributes["sow-language"]]: "",
    }));

    let objOfLanguages = Object.assign({}, ...languages);
    return objOfLanguages;
  };

  const getTableData = (arr) => {
    return arr.map((item) => {
      let addSubserviceBtn = (
        <div
          id={item.id}
          className="dropdown-item btn-sm additional-effort"
          onClick={(e) => {
            e.persist();
            for (let i = 0; i < services.length; i++) {
              if (services[i].id == e.target.id) {
                setSubservices((subservices) => [
                  ...subservices,
                  {
                    serviceId: e.target.id,
                    id: Math.floor(Math.random() * 100),
                    type: "subservice",
                    new: true,
                    name: "",
                    quantity: "1",
                    hours: "0.0",
                    rate: "0.0",
                    cost: "0.0",
                    changed: true,
                    languages: getLanguages(),
                  },
                ]);
              }
            }
          }}
        >
          <i className="fa fa-plus"></i>
          Add Subservice
        </div>
      );

      const deleteService = () => {
        let userResponse = confirm("Are you sure?");
        if (userResponse === false) {
          return;
        }
        let service = {
          data: {
            type: "third-party-services",
            id: item.id,
          },
        };
        API.Delete(
          `${apiHost}/${account_slug}/v1/third-party-services/${item.id}`,
          service,
          authorizationCode
        )
          .then((response) => {
            if (response.status == 204) {
              window.location.reload();
            }
          })
          .catch((err) => {
            setErrorMessages(
              formatUnprocessibleResponse(err, "Service details")
            );
            setShowFailAlert(true);
          });
      };

      const deleteSubserviceRow = (e) => {
        setSubservices(
          subservices.filter((subservice) => subservice.id != e.target.id)
        );
      };

      const deleteBtn = (
        <div
          id={item.id}
          className="dropdown-item btn-sm additional-effort"
          onClick={(e) => {
            e.persist();
            if (item.new === false) {
              deleteService();
            }
            if (item.new === true) {
              deleteSubserviceRow(e);
            }
          }}
        >
          <i className="fa fa-remove"></i>
          Delete
        </div>
      );

      const getOptions = () => {
        if (item.type !== "subservice") {
          return (
            <>
              {addSubserviceBtn}
              {deleteBtn}
            </>
          );
        }
        if (item.type === "subservice") {
          return <>{deleteBtn}</>;
        }
      };

      const getColumnOne = () => {
        if (item.type == "subservice") {
          return {
            class: "col-2",
            name: "",
          };
        }
        if (item.type !== "subservice") {
          let phase = " ";
          for (let i = 0; i < phases.length; i++) {
            let phaseId = item.phase;
            if (phaseId == phases[i].id) {
              phase = phases[i].attributes.name;
            }
          }

          return {
            class: "col-2",
            name: (
              <Form.Control
                as="select"
                id={item.id}
                value={phase}
                onChange={(e) => {
                  setHasUnsavedChanges(true);
                  let selected = e.target.options.selectedIndex;
                  setServices(
                    services.map((service) =>
                      service.id == e.target.id
                        ? {
                            ...service,
                            id: e.target.id,
                            phase: e.target.options[selected].accessKey,
                            changed: true,
                          }
                        : service
                    )
                  );
                }}
              >
                {phaseOptions}
              </Form.Control>
            ),
          };
        }
      };

      const getColumns = () => {
        if (item.type == "project-phase") {
          return [{ class: "col-12", name: <strong>{item.name}</strong> }];
        } else {
          const serviceNameField = {
            class: "col-3",
            name: (
              <Form.Control
                type="text"
                value={item.name}
                id={item.id}
                onChange={(e) => {
                  e.persist();
                  setHasUnsavedChanges(true);
                  if (item.type === "service") {
                    setServices(
                      services.map((service) =>
                        service.id == e.target.id
                          ? {
                              ...service,
                              name: e.target.value,
                              changed: true,
                            }
                          : service
                      )
                    );
                  }
                  if (item.type === "subservice") {
                    setSubservices(
                      subservices.map((subservice) =>
                        subservice.id == e.target.id
                          ? {
                              ...subservice,
                              name: e.target.value,
                              changed: true,
                            }
                          : subservice
                      )
                    );
                  }
                }}
              />
            ),
          };

          const quantityField = {
            class: "col-1 text-right",
            name: (
              <Form.Control
                type="number"
                min={"1"}
                step={"1"}
                id={item.id}
                value={item.quantity}
                onChange={(e) => {
                  e.persist();
                  setHasUnsavedChanges(true);
                  let propertyToModify = "quantity";
                  if (item.type !== "subservice") {
                    setServices(
                      services.map((service) =>
                        service.id == e.target.id
                          ? {
                              ...service,
                              [propertyToModify]: e.target.value,
                              changed: true,
                            }
                          : service
                      )
                    );
                  }
                  if (item.type === "subservice") {
                    setSubservices(
                      subservices.map((subservice) =>
                        subservice.id == e.target.id
                          ? {
                              ...subservice,
                              [propertyToModify]: e.target.value,
                              changed: true,
                            }
                          : subservice
                      )
                    );
                  }
                }}
              />
            ),
          };

          const hoursField = {
            class: "col-1 text-right",
            name: (
              <Form.Control
                type="number"
                min={"0"}
                step={"0.01"}
                id={item.id}
                value={item.hours}
                onChange={(e) => {
                  e.persist();
                  setHasUnsavedChanges(true);
                  let propertyToModify = "hours";
                  if (item.type !== "subservice") {
                    setServices(
                      services.map((service) =>
                        service.id == e.target.id
                          ? {
                              ...service,
                              [propertyToModify]: e.target.value,
                              changed: true,
                            }
                          : service
                      )
                    );
                  }
                  if (item.type === "subservice") {
                    setSubservices(
                      subservices.map((subservice) =>
                        subservice.id == e.target.id
                          ? {
                              ...subservice,
                              [propertyToModify]: e.target.value,
                              changed: true,
                            }
                          : subservice
                      )
                    );
                  }
                }}
              />
            ),
          };

          const descriptionField = {
            class: "col-5",
            name: languageFields.map((language, index) => (
              <Form.Control
                key={index}
                as="textarea"
                rows={2}
                style={
                  language.attributes.name !== languageField
                    ? { display: "none" }
                    : { display: "block" }
                }
                id={item.id}
                value={
                  item.languages[language.attributes["sow-language"]] ||
                  item.type == "subservice"
                    ? item.languages[language.attributes["sow-language"]]
                    : ""
                }
                onChange={(e) => {
                  e.persist();
                  setHasUnsavedChanges(true);
                  if (item.type == "subservice") {
                    setSubservices(
                      subservices.map((subservice) => {
                        let langArray = languageFields.map((lang) => {
                          if (
                            lang.attributes["sow-language"] ===
                            language.attributes["sow-language"]
                          ) {
                            let key = lang.attributes["sow-language"];
                            return {
                              ...subservice.languages,
                              [key]: e.target.value,
                            };
                          }
                        });
                        let newLangs = Object.assign({}, ...langArray);
                        if (subservice.id == e.target.id) {
                          return {
                            ...subservice,
                            changed: true,
                            languages: newLangs,
                          };
                        } else {
                          return subservice;
                        }
                      })
                    );
                  }
                  if (item.type !== "subservice") {
                    setServices(
                      services.map((service) => {
                        let langArray = languageFields.map((lang) => {
                          if (
                            lang.attributes["sow-language"] ===
                            language.attributes["sow-language"]
                          ) {
                            let key = lang.attributes["sow-language"];
                            return {
                              ...service.languages,
                              [key]: e.target.value,
                            };
                          }
                        });
                        let newLangs = Object.assign({}, ...langArray);
                        if (service.id == e.target.id) {
                          return {
                            ...service,
                            changed: true,
                            languages: newLangs,
                          };
                        } else {
                          return service;
                        }
                      })
                    );
                  }
                }}
              />
            )),
          };

          const arrowButtonColumn = {
            class: "arrowDropdownColWidth",
            name: (
              <div className="actionsDiv">
                <ArrowDropdownButton id={item.id} options={getOptions()} />
              </div>
            ),
          };

          const rateField = {
            class: "col-1 text-right",
            name: (
              <Form.Control
                readOnly={deriveFromServices === false}
                type="number"
                min="0.0"
                step="0.01"
                id={item.id}
                value={item.rate}
                onChange={(e) => {
                  e.persist();
                  setHasUnsavedChanges(true);
                  if (item.type !== "subservice") {
                    setServices(
                      services.map((service) =>
                        service.id == e.target.id
                          ? {
                              ...service,
                              rate: e.target.value,
                              changed: true,
                            }
                          : service
                      )
                    );
                  }
                  if (item.type === "subservice") {
                    setSubservices(
                      subservices.map((subservice) =>
                        subservice.id == e.target.id
                          ? {
                              ...subservice,
                              rate: e.target.value,
                              changed: true,
                            }
                          : subservice
                      )
                    );
                  }
                }}
              />
            ),
          };

          const costField = {
            class: "col-1 text-right",
            name: (
              <Form.Control
                readOnly={deriveFromServices === false}
                type="number"
                min="0.0"
                step="0.01"
                id={item.id}
                value={
                  item.hours !== "0.0" && item.rate !== "0.0"
                    ? parseFloat(item.hours * item.rate)
                    : item.cost
                }
                onChange={(e) => {
                  e.persist();
                  setHasUnsavedChanges(true);
                  if (item.type !== "subservice") {
                    setServices(
                      services.map((service) =>
                        service.id == e.target.id
                          ? {
                              ...service,
                              cost: e.target.value,
                              changed: true,
                            }
                          : service
                      )
                    );
                  }
                  if (item.type === "subservice") {
                    setSubservices(
                      subservices.map((subservice) =>
                        subservice.id == e.target.id
                          ? {
                              ...subservice,
                              cost: e.target.value,
                              changed: true,
                            }
                          : subservice
                      )
                    );
                  }
                }}
              />
            ),
          };

          const frequencyField = {
            class: "col-2",
            name: (
              <Form.Control
                as="select"
                id={item.id}
                value={item.frequency}
                onChange={(e) => {
                  setHasUnsavedChanges(true);
                  if (item.type !== "subservice") {
                    setServices(
                      newServices.map((service) =>
                        service.id == e.target.id
                          ? {
                              ...service,
                              id: e.target.id,
                              frequency: e.target.value,
                              changed: true,
                            }
                          : service
                      )
                    );
                  }
                  if (item.type === "subservice") {
                    setSubservices(
                      subservices.map((subservice) =>
                        subservice.id == e.target.id
                          ? {
                              ...subservice,
                              id: e.target.id,
                              frequency: e.target.value,
                              changed: true,
                            }
                          : subservice
                      )
                    );
                  }
                }}
              >
                {frequencyOptions}
              </Form.Control>
            ),
          };

          if (languageField !== "Pricing") {
            return [
              getColumnOne(),
              serviceNameField,
              quantityField,
              descriptionField,
              arrowButtonColumn,
            ];
          } else {
            return [
              getColumnOne(),
              serviceNameField,
              quantityField,
              hoursField,
              rateField,
              costField,
              frequencyField,
              arrowButtonColumn,
            ];
          }
        }
      };

      return {
        rowClass: "thirdPartyServicesRow row",
        onClick: null,
        columns: getColumns(),
        actions: null,
      };
    });
  };

  const getHeaderColumns = () => {
    if (languageField === "Pricing") {
      return [
        {
          class: "col-2",
          name: "Phase",
        },
        {
          class: "col-3",
          name: "Service",
        },
        {
          class: "col-1 text-right",
          name: "Qty",
        },
        {
          class: "col-1 text-right",
          name: rateType,
        },
        {
          class: "col-1 text-right",
          name: "Rate",
        },
        {
          class: "col-1 text-right",
          name: "Cost",
        },
        {
          class: "col-2",
          name: "Frequency",
        },
        {
          class: "arrowDropdownColWidth",
          name: "",
        },
      ];
    }
    if (languageField !== "Pricing") {
      return [
        {
          class: "col-2",
          name: "Phase",
        },
        {
          class: "col-3",
          name: "Service",
        },
        {
          class: "col-1 text-right",
          name: "Qty",
        },
        {
          class: "col-5",
          name: "Content",
        },
        {
          class: "arrowDropdownColWidth",
          name: "",
        },
      ];
    }
  };

  const quotedServiceHeaderData = {
    rowClass: "newQuotedServiceHeaderRow row",
    columns: getHeaderColumns(),
  };

  const getQuotedServicesFooterData = () => {
    let content = "";
    content = (
      <div>
        <SubmitButton />
      </div>
    );
    return {
      rowClass: "col-12",
      columns: [{ class: "thirdPartyServicesFooter", content: content }],
    };
  };

  const arrangeDataForServices = () => {
    let array = [];
    let phasesWithServices = new Set();
    for (let i = 0; i < phases.length; i++) {
      let phaseServices = services.filter(
        (service) => service.phase == phases[i].id
      );
      if (phaseServices.length > 0) phasesWithServices.add(phases[i]);
    }

    let noPhaseServices = services.filter(
      (service) => service.phase == null || service.phase == "noPhase"
    );
    if (noPhaseServices.length > 0) {
      phasesWithServices.add({
        id: "noPhase",
        attributes: { name: "No Phase" },
      });
    }
    phasesWithServices = Array.from(phasesWithServices);

    for (let i = 0; i < phasesWithServices.length; i++) {
      array.push({
        type: "project-phase",
        name: phasesWithServices[i].attributes.name,
      });
      for (let k = 0; k < services.length; k++) {
        let phaseId = services[k].phase;
        if (phaseId == phasesWithServices[i].id) {
          array.push(services[k]);
          for (let j = 0; j < subservices.length; j++) {
            if (subservices[j].serviceId == services[k].id) {
              array.push(subservices[j]);
            }
          }
        }
      }
    }
    return array;
  };

  const newServices = arrangeDataForServices();

  let quotedServiceTableData = getTableData(newServices);

  const quotedServiceFooterData = getQuotedServicesFooterData();

  let quotedServiceTable = new ScopeStackTable(
    "services",
    quotedServiceHeaderData,
    quotedServiceTableData,
    quotedServiceFooterData
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setTableIsLoading(true);
    setHasUnsavedChanges(false);
    let promises = [];

    const getUpdatedLanguages = (service) => {
      let languages = service.languages;
      delete service.languages["service_description"];
      return languages;
    };

    const submitSubservices = (
      serviceFrequency,
      serviceId,
      postedServiceId,
      phaseId
    ) => {
      subservices.forEach((subservice) => {
        let subserviceData = {
          data: {
            type: "third-party-services",
            id: subservice.id,
            attributes: {
              name: subservice.name,
              quantity: subservice.quantity,
              position: subservice.id,
              "original-description":
                subservice.languages["service_description"],
              "est-cost": subservice.cost,
              "est-hours": subservice.hours,
              "est-rate": subservice.rate,
              "payment-frequency":
                wordCount(serviceFrequency) == 2
                  ? toSnakeCase(serviceFrequency)
                  : serviceFrequency.toLowerCase(),
              "source-type": "ThirdPartyService",
              "source-id": postedServiceId,
            },
            relationships: {
              "project-phase": {
                data: { type: "project-phases", id: phaseId },
              },
            },
          },
        };

        if (
          subservice.serviceId == serviceId &&
          subservice.changed === true &&
          subservice.new === true
        ) {
          delete subserviceData.data.id;
          subserviceData["original-languages"] =
            getUpdatedLanguages(subservice);
          promises.push(
            API.Post(
              `${apiHost}/${account_slug}/v1/third-party-services/`,
              subserviceData,
              authorizationCode
            )
          );
        }

        if (phaseId === "noPhase") {
          delete subserviceData.data.relationships["project-phase"];
        }

        if (
          subservice.serviceId == serviceId &&
          subservice.changed === true &&
          subservice.new === false
        ) {
          subserviceData.data.attributes["original-languages"] =
            getUpdatedLanguages(subservice);
          promises.push(
            API.Patch(
              `${apiHost}/${account_slug}/v1/third-party-services/${subservice.id}`,
              subserviceData,
              authorizationCode
            )
          );
        }
      });
    };

    services.forEach((service, index) => {
      if (service.type == "service" && subservices.length !== 0) {
        submitSubservices(
          service.frequency,
          service.id,
          service.id,
          service.phase
        );
      }

      if (service.changed === true) {
        let serviceData = {
          data: {
            type: "third-party-services",
            id: service.id,
            attributes: {
              name: service.name,
              quantity: service.quantity,
              position: index + 1,
              "original-description": service.languages["service_description"],
              "original-languages": getUpdatedLanguages(service),
              "est-cost": service.cost,
              "est-hours": service.hours,
              "est-rate": service.rate,
              "payment-frequency":
                wordCount(service.frequency) == 2
                  ? toSnakeCase(service.frequency)
                  : service.frequency.toLowerCase(),
              "source-type": "Quote",
              "source-id": quoteId,
            },
            relationships: {
              "project-phase": {
                data: {
                  type: "project-phases",
                  id: service.phase === "noPhase" ? null : service.phase,
                },
              },
            },
          },
        };

        promises.push(
          API.Patch(
            `${apiHost}/${account_slug}/v1/third-party-services/${service.id}`,
            serviceData,
            authorizationCode
          )
        );
      }
    });

    Promise.all(promises)
      .then((response) => {
        let allSuccessful = true;
        response.forEach((res) => {
          if (res.status !== 200 && res.status !== 201) {
            allSuccessful = false;
          }
        });
        setTableIsLoading(false);
        if (allSuccessful) {
          // Get third party services
          API.Get(
            `${apiHost}/${account_slug}/v1/third-party-services?filter[source-type]=Quote&filter[source-id]=${id}&include=project-phase,subtasks`,
            authorizationCode
          ).then((response) => {
            let data = response.data.data;
            let includedData = response.data.included;
            if (data !== null) {
              setThirdPartyServices(data);
              if (includedData) {
                let subservices = includedData.filter(
                  (item) =>
                    item.attributes["source-type"] === "ThirdPartyService"
                );
                setSubtasks(subservices);
              }
            }
          });
          API.Post(
            `${apiHost}/${account_slug}/v1/quotes/${quoteId}/recalculate`,
            { data: { type: "quotes", id: quoteId } },
            authorizationCode
          ).then((response) => {
            setUpdatedAlertIsOpen(true);
            setTimeout(() => {
              setUpdatedAlertIsOpen(false);
            }, 3000);
          });
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "Service details"));
        setShowFailAlert(true);
      });
  };

  const serviceUpdatedAlert = () => {
    if (updatedAlertIsOpen === true) {
      return (
        <DismissibleAlert
          className="Alerts"
          variant="info"
          onClose={() => setUpdatedAlertIsOpen(false)}
          text="Service details updated"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      <NavTabs
        tabOneText="Vendor Quote"
        tabOneUrl={`/projects/${project_id}/quotes/${quoteId}/edit`}
        tabTwoText="Vendor Description"
        tabTwoUrl={
          services.length !== 0
            ? `/projects/${project_id}/quotes/${quoteId}/quoted_service`
            : `/projects/${project_id}/quotes/${quoteId}/quoted_service/new`
        }
        tabThreeText="Our Description"
        tabThreeUrl={`/projects/${project_id}/quotes/${quoteId}/third_party_services`}
        hasUnsavedChanges={hasUnsavedChanges}
      />
      <div className="col-sm-12" id="sow-workarea">
        <div className="tabContent">
          {serviceUpdatedAlert()}
          {handleFailAlert()}
          <Card>
            <Card.Body>
              <Row>
                <Col style={{ marginTop: "2em" }}>
                  <div style={{ display: "flex" }}>
                    <Button
                      style={{ marginRight: "5px" }}
                      variant="outline-secondary"
                      onClick={() =>
                        navigate(
                          `/projects/${project_id}/quotes/${quoteId}/quoted_service/new`
                        )
                      }
                    >
                      Add Services
                    </Button>
                    <ImportButton
                      account_slug={account_slug}
                      project_id={project_id}
                      contextId={quoteId !== null ? quoteId : id}
                      contextType="Quote"
                      label="Quoted Services (CSV or Excel)"
                      value="quoted_services"
                    />
                  </div>
                </Col>
                <Col sm={3}>
                  <ControlledDropDown
                    className="pull-right"
                    label="View"
                    value={languageField}
                    onChange={(e) => setLanguageField(e.target.value)}
                    options={languageOptions}
                  />
                </Col>
              </Row>
              <Form onSubmit={handleSubmit}>
                <BaseTable
                  className="newQuotedServiceTable"
                  striped={false}
                  hover={false}
                  bordered={false}
                  headerRows={quotedServiceTable.buildHeaderRows()}
                  dataRows={quotedServiceTable.buildDataRows()}
                  footerRows={quotedServiceTable.buildFooterRows()}
                  isLoading={isLoading || tableIsLoading}
                />
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default QuotedService;
