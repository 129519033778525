import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import ProjectVendorQuotes from "./ProjectVendorQuotes/ProjectVendorQuotes";
import NewVendorQuote from "./NewVendorQuote/NewVendorQuote";
import API from "@API";
import { capitalizeFirstLetter } from "@utils/helperFunctions";
import EditVendorQuote from "./EditVendorQuote/EditVendorQuote";
import QuotedService from "./Services/QuotedService";
import ThirdPartyServices from "./Services/ThirdPartyServices";
import AddServices from "./Services/AddServices";

function VendorQuotesMspa({ account_slug, project_id, account_id }) {
  // Env
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];
  if (lastWordInLocation === "new") {
    id = locationArr[locationArr.length - 3];
  }

  // Set the State
  const [projectStatus, setProjectStatus] = useState("");

  const [vendorQuoteCreated, setVendorQuoteCreated] = useState(false);
  const [vendorQuoteUpdated, setVendorQuoteUpdated] = useState(false);
  const [vendorQuoteId, setVendorQuoteId] = useState(null);
  const [vendorQuotes, setVendorQuotes] = useState([]);
  const [linesOfBusiness, setLinesOfBusiness] = useState([]);
  const [currencyUnit, setCurrencyUnit] = useState("");
  const [deriveFromServices, setDeriveFromServices] = useState(false);

  const [vendors, setVendors] = useState([]);

  const [languageFields, setLanguageFields] = useState([]);
  const [ourDescriptLangFields, setOurDescriptLangFields] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [phases, setPhases] = useState([]);
  const [thirdPartyServices, setThirdPartyServices] = useState([]);
  const [subtasks, setSubtasks] = useState([]);
  const [rateType, setRateType] = useState("Hours");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get Project Info with vendor quotes included
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=project-phases`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setProjectStatus(data.attributes.status);
        setCurrencyUnit(data.attributes["field-labels"].currency_unit);

        const paymentInfo = data.attributes["payment-info"];
        let rateType =
          paymentInfo["rate-type"] !== "hours"
            ? capitalizeFirstLetter(paymentInfo["rate-type"]) + "s"
            : capitalizeFirstLetter(paymentInfo["rate-type"]);
        setRateType(rateType);

        let projectPhases = response.data.included.filter(
          (item) => item.type === "project-phases"
        );
        setPhases(projectPhases);
      });

      // Get Vendors
      API.Get(`${apiHost}/${account_slug}/v1/vendors?page[size]=250`, authorizationCode).then(
        (response) => {
          let data = response.data.data;
          setVendors(data);
        }
      );

      // Get Quotes
      API.Get(
        `${apiHost}/${account_slug}/v1/quotes?filter[project]=${project_id}&include=vendor,lob`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        setVendorQuotes(data);
      });

      // Get lines of business
      API.Get(
        `${apiHost}/${account_slug}/v1/service-categories?page[size]=250`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        if (data !== null) {
          let lobs = data
            .filter((item) => !item.attributes["has-parent?"])
            .sort((a, b) => a.attributes.position - b.attributes.position);

          lobs = lobs.map((item) => {
            let children = data
              .filter((child) => child.attributes["lob-id"] == item.id)
              .sort((a, b) => a.attributes.position - b.attributes.position);
            item.categories = children;
            return item;
          });
          setLinesOfBusiness(lobs);
        }
        setIsLoading(false);
      });

      // Get third party services
      API.Get(
        `${apiHost}/${account_slug}/v1/third-party-services?filter[source-type]=Quote&filter[source-id]=${id}&include=project-phase,subtasks`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        let includedData = response.data.included;
        if (data !== null) {
          setThirdPartyServices(data);
          if (includedData) {
            let subservices = includedData.filter(
              (item) => item.attributes["source-type"] === "ThirdPartyService"
            );
            setSubtasks(subservices);
          }
        }
      });

      //Get Quote Info
      API.Get(
        `${apiHost}/${account_slug}/v1/quotes?filter[project]=${project_id}&filter[id]=${id}&include=vendor,lob`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        if (data.length !== 0) {
          setDeriveFromServices(data[0].attributes["service-pricing"]);
          let quoteFrequency = data[0].attributes["billing-frequency"];
          let options = [
            <option accessKey="one_time" key="one_time">
              One Time
            </option>,
          ];
          if (quoteFrequency !== "one_time") {
            options.push(
              <option accessKey={quoteFrequency} key={quoteFrequency}>
                {capitalizeFirstLetter(quoteFrequency)}
              </option>
            );
          }
          setFrequencyOptions(options);
        }
      });

      // Get Language Fields
      API.Get(
        `${apiHost}/${account_slug}/v1/language-fields`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        let pricing = {
          id: -1,
          type: "language-fields",
          attributes: {
            name: "Pricing",
            "sow-language": "pricing",
          },
        };
        data.push(pricing);
        let serviceDescription = {
          id: 0,
          type: "language-fields",
          attributes: {
            name: "Service Description",
            "sow-language": "service_description",
          },
        };
        data.unshift(serviceDescription);
        setLanguageFields(data);
        setOurDescriptLangFields(data.filter((item) => item.id !== -1));
        setIsLoading(false);
      });
    }
  }, [authorizationCode]);

  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/:project_id/quotes"}
            element={
              <ProjectVendorQuotes
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                vendorQuoteId={vendorQuoteId}
                setVendorQuoteId={(id) => setVendorQuoteId(id)}
                projectStatus={projectStatus}
                vendorQuotes={vendorQuotes}
                vendorQuoteCreated={vendorQuoteCreated}
                vendorQuoteUpdated={vendorQuoteUpdated}
                vendors={vendors}
                linesOfBusiness={linesOfBusiness}
                currencyUnit={currencyUnit}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/quotes/new"}
            element={
              <NewVendorQuote
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                vendorQuotes={vendorQuotes}
                vendorQuoteCreated={vendorQuoteCreated}
                setVendorQuoteCreated={(created) =>
                  setVendorQuoteCreated(created)
                }
                vendorQuoteId={vendorQuoteId}
                setVendorQuoteId={(id) => setVendorQuoteId(id)}
                vendors={vendors}
                linesOfBusiness={linesOfBusiness}
                deriveFromServices={deriveFromServices}
                setDeriveFromServices={(bool) => setDeriveFromServices(bool)}
                authorizationCode={authorizationCode}
              />
            }
          />

          <Route
            path={"/projects/:project_id/quotes/:vendorQuoteId/edit"}
            element={
              <EditVendorQuote
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                vendorQuotes={vendorQuotes}
                vendorQuoteUpdated={vendorQuoteUpdated}
                vendorQuoteCreated={vendorQuoteCreated}
                setVendorQuoteUpdated={(updated) =>
                  setVendorQuoteUpdated(updated)
                }
                vendorQuoteId={vendorQuoteId}
                vendors={vendors}
                linesOfBusiness={linesOfBusiness}
                thirdPartyServices={thirdPartyServices}
                deriveFromServices={deriveFromServices}
                setDeriveFromServices={setDeriveFromServices}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={
              "/projects/:project_id/quotes/:vendorQuoteId/quoted_service/new"
            }
            element={
              <AddServices
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                vendorQuoteId={vendorQuoteId}
                languageFields={languageFields}
                phases={phases}
                vendorQuotes={vendorQuotes}
                deriveFromServices={deriveFromServices}
                setDeriveFromServices={setDeriveFromServices}
                frequencyOptions={frequencyOptions}
                rateType={rateType}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            path={"/projects/:project_id/quotes/:vendorQuoteId/quoted_service"}
            element={
              <QuotedService
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                vendorQuoteId={vendorQuoteId}
                languageFields={languageFields}
                phases={phases}
                thirdPartyServices={thirdPartyServices}
                subtasks={subtasks}
                vendorQuotes={vendorQuotes}
                deriveFromServices={deriveFromServices}
                setDeriveFromServices={setDeriveFromServices}
                frequencyOptions={frequencyOptions}
                isLoading={isLoading}
                rateType={rateType}
                authorizationCode={authorizationCode}
                setThirdPartyServices={(s) => setThirdPartyServices(s)}
                setSubtasks={(s) => setSubtasks(s)}
              />
            }
          />
          <Route
            path={
              "/projects/:project_id/quotes/:vendorQuoteId/third_party_services"
            }
            element={
              <ThirdPartyServices
                account_slug={account_slug}
                project_id={project_id}
                account_id={account_id}
                projectStatus={projectStatus}
                vendorQuoteId={vendorQuoteId}
                languageFields={ourDescriptLangFields}
                phases={phases}
                thirdPartyServices={thirdPartyServices}
                subtasks={subtasks}
                vendorQuotes={vendorQuotes}
                deriveFromServices={deriveFromServices}
                setDeriveFromServices={setDeriveFromServices}
                isLoading={isLoading}
                authorizationCode={authorizationCode}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default VendorQuotesMspa;
