import { faSortDown, faSortUp, faSort } from "@fortawesome/pro-solid-svg-icons";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import PaginationNumbersAPI from "@components/Buttons/PaginationAPI/PaginationNumbersAPI";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import Select from "react-select";
import { Form } from "react-bootstrap";

/* This component renders a table with client-side pagination

Parameters:

1. tableType 
    a string describing whats in table (i.e., "projects", "surveys") 

2. columnHeaders 
    an array of objects with the following format:
    Eg., */
interface ColumnHeader {
  label: string;
  sortable: boolean;
  sorted: string;
  width: number;
}
/*
3. tableData 
    an object with properties meta (pagination) and data (array of table items)
    Eg., */
interface TableData<T> {
  meta: {
    "record-count": number;
    "page-count"?: number;
  };
  data: T[];
}
/*
    Note: the items in the tableData.data array must each have a key/value for each column 
    on the table and have to appear in the same order 
*/
interface HasID {
  id: string | number;
  url?: string;
}
interface TableProps<T extends HasID> {
  tableType: string;
  columnHeaders: ColumnHeader[];
  tableItemsData: TableData<T>;
  checkboxes: boolean;
  filtered?: boolean;
  checkedItems?: HasID[];
  setCheckedItems?: (items: HasID[]) => void;
  onSort?: (value: string, keepOrder?: boolean) => void;
  pageSizeEnabled?: boolean;
}

const FEPaginatedTable = <T extends HasID>({
  tableType,
  columnHeaders,
  tableItemsData,
  checkboxes,
  filtered = false,
  checkedItems = [],
  setCheckedItems = () => {},
  onSort = (value) => {},
  pageSizeEnabled = false,
}: TableProps<T>) => {
  const getTableItems = (items, pageSize, currentPage) => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;

    return items.slice(start, end);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableItems, setTableItems] = useState(
    tableItemsData
      ? getTableItems(tableItemsData.data, pageSize, currentPage)
      : []
  );
  const [totalPages, setTotalPages] = useState(
    Math.ceil(tableItemsData.data.length / pageSize)
  );

  useEffect(() => {
    if (filtered && currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [tableItemsData]);

  useEffect(() => {
    const items = getTableItems(tableItemsData.data, pageSize, currentPage);
    setTableItems(items);
    setTotalPages(Math.ceil(tableItemsData.data.length / pageSize));
  }, [tableItemsData, pageSize, currentPage, filtered]);

  const getIconClass = (sort) => {
    if (sort == "desc") {
      return faSortDown;
    } else if (sort == "asc") {
      return faSortUp;
    } else {
      return faSort;
    }
  };

  const headerData = {
    rowClass: "",
    columns: columnHeaders.map((column: any) => {
      let headerColumn = {
        class: `cursorPoint col-${column.width}`,
        id: column.label,
        name: (
          <div onClick={(e) => onSort(column.label)} id={column.label}>
            {column.label}
            {column.sortable ? (
              <FontAwesomeIcon
                id={column.label}
                className="sortIcon"
                icon={getIconClass(column.sorted) as IconProp}
              />
            ) : null}
          </div>
        ),
      };

      return headerColumn;
    }),
  };
  const onPageSizeChange = (value) => {
    setCurrentPage(1);
    setPageSize(value);
  };
  ///Get table data
  const updateCheckedItems = (tableItem) => {
    setTableItems(
      tableItems.map((item) => {
        return item.id == tableItem.id
          ? { ...item, checked: !tableItem.checked }
          : item;
      })
    );
    let alreadySelected = checkedItems.find((item) => item.id == tableItem.id);
    if (alreadySelected) {
      const updatedItems = checkedItems.filter(
        (item) => item.id !== tableItem.id
      );

      setCheckedItems(updatedItems);
    } else {
      setCheckedItems([...checkedItems, tableItem]);
    }
  };

  const getTableColumns = (tableItem) => {
    return columnHeaders.map((column: any, index) => {
      if (checkboxes && index == 0) {
        return {
          class: "bold",
          name: (
            <span
              className="flexCenter"
              style={{
                marginLeft: "20px",
              }}
            >
              <input
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  e.stopPropagation();
                  updateCheckedItems(tableItem);
                }}
                type="checkbox"
                className="form-check-input"
                checked={
                  checkedItems.filter((item) => item.id == tableItem.id)?.[0]
                    ? true
                    : false
                }
              ></input>
              <span style={{ marginLeft: "5px" }}>
                {tableItem.attributes[Object.keys(tableItem.attributes)[index]]}
              </span>
            </span>
          ),
        };
      } else {
        return {
          class: `col-${column.width}`,
          name: (
            <>
              {tableItem.attributes[Object.keys(tableItem.attributes)[index]]}
            </>
          ),
        };
      }
    });
  };

  const getTableData = () => {
    if (tableItems.length == 0) {
      return [];
    }
    // Map over projects & return data for ScopeStackTable
    return tableItems.map((item: any, index) => {
      // Map returns a table row for each Project
      return {
        rowClass: "tableRow",
        onClick: null,
        columns: getTableColumns(item),
        actions: null,
      };
    });
  };

  // Footer data
  const getFooterData = () => {
    let content = (
      <div
        style={{
          display: "flex",
          justifyContent: `${pageSizeEnabled ? "space-between" : "center"}`,
          alignItems: "center",
        }}
      >
        {/* Row size dropdown */}
        {pageSizeEnabled && (
          <div style={{ display: "flex", alignItems: "center" }}>
            Showing
            <Form.Group
              className="formGroup"
              style={{ marginBottom: "0", marginLeft: "1em" }}
            >
              <Select
                className="basic-single"
                onChange={(e) => {
                  if (e) {
                    onPageSizeChange(e.value);
                  }
                }}
                defaultValue={{ value: 10, label: "10" }}
                options={[
                  { value: 10, label: "10" },
                  { value: 20, label: "20" },
                  { value: 50, label: "50" },
                ]}
              />
            </Form.Group>
          </div>
        )}
        {/* Page numbers */}
        <PaginationNumbersAPI
          pageCount={totalPages}
          pageArray={Array.from(Array(totalPages), (_, i) => i + 1)}
          currentPage={currentPage}
          setCurrentPage={(page) => setCurrentPage(page)}
          leftArrowOnClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
          rightArrowOnClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
          itemsPerPage={pageSize}
          onNumberClick={(_, number) => setCurrentPage(number)}
        />
        {/* Viewing x - y of z */}
        {pageSizeEnabled && tableItemsData.meta["record-count"] > 0 ? (
          <div style={{ display: "flex", margin: "auto 0" }}>
            Viewing {currentPage == 1 ? 1 : (currentPage - 1) * pageSize + 1} -{" "}
            {currentPage == 1
              ? tableItems.length
              : currentPage == totalPages
              ? tableItemsData.meta["record-count"]
              : currentPage * pageSize}{" "}
            of{" "}
            {Math.max(
              tableItemsData.data.length,
              tableItemsData.meta["record-count"]
            )}
          </div>
        ) : pageSizeEnabled && tableItemsData.meta["record-count"] == 0 ? (
          <div style={{ display: "flex", margin: "auto 0" }}>
            Viewing 0 - 0 of 0
          </div>
        ) : null}
      </div>
    );

    return {
      rowClass: "col-12",
      columns: [
        { class: "projectsListFooter", content: content, colSpan: "12" },
      ],
    };
  };

  const tableData = getTableData();
  const footerData = getFooterData();

  let tableOutput = new ScopeStackTable(
    tableType,
    headerData,
    tableData,
    footerData
  );
  return (
    <>
      <BaseTable
        className="sansTable greyBackground"
        striped={true}
        hover={true}
        bordered={true}
        headerRows={tableOutput.buildHeaderRows()}
        dataRows={tableOutput.buildDataRows()}
        footerRows={tableOutput.buildFooterRows()}
        isLoading={!tableItemsData ? true : false}
      />
    </>
  );
};

export default FEPaginatedTable;
