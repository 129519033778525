import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SlugState {
  accountSlug: string;
}

const initialState: SlugState = {
  accountSlug: "",
};

const slugSlice = createSlice({
  name: "slug",
  initialState,
  reducers: {
    setAccountSlug: (state, action: PayloadAction<string>) => {
      state.accountSlug = action.payload;
    },
    clearAccountSlug: (state) => {
      state.accountSlug = "";
    },
  },
});

export const { setAccountSlug, clearAccountSlug } = slugSlice.actions;

export default slugSlice.reducer;
