import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AccordianDrop({
  header,
  body,
  iconClosed,
  iconOpen,
  closeable,
  setAccordianIsOpen,
  className,
  iconHexCode,
  headerBackgroundHexCode,
  cardBackgroundHexCode,
  cardClass,
  startingAccordionState,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (startingAccordionState === "open") {
      buttonRef.current.click();
    }
  }, []);

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setIsOpen(!isOpen);
      setAccordianIsOpen(!isOpen);
    });

    return (
      <Row className={className} style={{ flexWrap: "nowrap" }}>
        <div>{children}</div>
        <div
          ref={buttonRef}
          className={isOpen ? "open" : ""}
          id="openCloseToggle"
          onClick={closeable ? decoratedOnClick : null}
        >
          <FontAwesomeIcon
            style={{ color: iconHexCode, fontSize: "30px" }}
            icon={isOpen ? iconOpen : iconClosed}
          />
        </div>
      </Row>
    );
  }

  return (
    <Accordion eventkey="0" flush>
      <Card
        className={cardClass}
        style={{
          padding: "20px",
          border: "1px solid #dee2e6",
          background: cardBackgroundHexCode,
        }}
      >
        {header !== null ? (
          <Card.Header
            style={{
              backgroundColor: headerBackgroundHexCode,
              color: "#1C2655",
            }}
          >
            <CustomToggle eventKey="0">{header}</CustomToggle>
          </Card.Header>
        ) : null}
        {body !== null ? (
          <Accordion.Collapse eventKey="0">
            <Card.Body>{body}</Card.Body>
          </Accordion.Collapse>
        ) : null}
      </Card>
    </Accordion>
  );
}

export default AccordianDrop;
