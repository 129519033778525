import React from "react";
import { Button } from "react-bootstrap";

function BackButton({ url }) {
  return (
    <a rel="nofollow" href={url}>
      <Button className="pull-right" variant="outline-secondary">
        Back
      </Button>
    </a>
  );
}

export default BackButton;
