import React, { useState } from "react";
import "./ToastAlert.css";
import Toast from "react-bootstrap/Toast";
import { ToastContainer } from "react-bootstrap";
import CloseButtonX from "../../CloseButtonX/CloseButtonX";
function ToastAlert({ variant, text, onClose }) {
  const [show, setShow] = useState(true);
  // text can be a string or an array of strings
  const isString = typeof text === "string";
  const autoHideToast = isString
    ? text.split(" ").length < 20
    : text.join(" ").split(" ").length < 20;

  return (
    <ToastContainer className="p-3">
      <Toast
        style={{
          backgroundColor:
            variant === "success"
              ? "#418172"
              : variant === "danger"
              ? "#B90200"
              : "#CC672F",
        }}
        show={show}
        delay={4000}
        autohide={autoHideToast}
        onClose={onClose}
      >
        <Toast.Body>
          <div className="toastBody">
            <strong style={{ alignSelf: "center" }}>
              {variant === "success" ? "Success!" : "Oops!"}
            </strong>
            {isString ? (
              <span className="toastMessage">{text}</span>
            ) : (
              <div className="toastMessage">
                {text.map((msg, i) => (
                  <span key={i}>{msg}</span>
                ))}
              </div>
            )}
            <CloseButtonX onClose={onClose} style={{ marginLeft: "10px" }} />
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
export default ToastAlert;
