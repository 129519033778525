import { useGetAccountQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";

const useAccountHasEnetitlement = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const {
    data: account,
    isLoading,
    isError,
  } = useGetAccountQuery({ slug: accountSlug });

  const hasEntitlement = (entitlementToCheck: string): boolean => {
    const entitlements = account?.data?.attributes?.entitlements ?? [];

    return entitlements.some((entitlement) => {
      return entitlementToCheck === entitlement;
    });
  };

  return { hasEntitlement, isLoading, isError, account };
};

export default useAccountHasEnetitlement;
