import { useState } from "react";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/rootReducer";
import Password from "./Password";
import CompanyInfo from "./CompanyInfo";
import Licenses from "./Licenses";
import Advanced from "./Advanced";
import "./styles.css";
import { MenuOption } from "./types";
import {
  useGetCompanyAccountQuery,
  useGetMeQuery,
} from "../../../services/ScopeStackAPI";
import Billing from "./Billing/Billing";

interface Props {
  options: MenuOption[];
}

const Menu = ({ options }: Props): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const [isOpen, setIsOpen] = useState(false);
  const {
    data: account,
    error: accountError,
    isLoading: accountLoading,
  } = useGetCompanyAccountQuery(accountSlug);
  const {
    data: user,
    error: userError,
    isLoading: userLoading,
  } = useGetMeQuery();

  const isLoading = accountLoading || userLoading;
  // TODO Error handling
  const error = accountError || userError;
  if (isLoading || error || !user || !account) return <></>;

  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey={options[0].toLowerCase().replace(/\s/g, "")}
    >
      <Row className="myAccount">
        <Col
          sm={3}
          className="left-menu4"
          style={{ padding: "20px 0px 0px 46px", maxWidth: "20%" }}
        >
          <h3 className="menu-title">Account Preferences </h3>
          <hr
            style={{ marginTop: "3px", marginBottom: "3px", width: "90%" }}
          ></hr>
          <Nav variant="pills" className="flex-column">
            {options.map((option, index) => (
              <Nav.Item key={index} className="nav-item">
                <Nav.Link
                  eventKey={option.toLowerCase().replace(/\s/g, "")}
                  className="nav-link"
                >
                  {option}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col
          sm={9}
          style={{
            padding: "25px 0px 0px",
          }}
        >
          <Tab.Content>
            {options.map((option, index) => (
              <Tab.Pane
                key={index}
                eventKey={option.toLowerCase().replace(/\s/g, "")}
              >
                {option === MenuOption.Contact && <CompanyInfo />}
                {option === MenuOption.Password && <Password />}
                {option === MenuOption.Billing && <Billing />}
                {option === MenuOption.Licenses && <Licenses />}
                {option === MenuOption.Advanced && <Advanced />}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};
export default Menu;
