import TeamInfo from "../common/TeamInfo";
import { Tab, Tabs } from "react-bootstrap";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import useGetTeam from "./api";
import { useEffect, useState } from "react";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import FEPaginatedTable from "@components/Tables/FEPaginatedTable/FEPaginatedTable";
import { sortItemsByAttribute } from "../../../Surveys/api";
import { Services, Surveys } from "./types";

const Edit = () => {
  const location = window.location.href.split("/");
  const projectId = location[location.length - 1];
  const lastPageURL = localStorage.getItem("lastPage");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [sortedServices, setSortedServices] = useState<Services | null>(null);
  const [sortedSurveys, setSortedSurveys] = useState<Surveys | null>(null);
  const [sortBy, setSortBy] = useState({
    name: "",
    category: "",
    phase: "",
    description: "",
  });

  const { data: team, error, isLoading, refetch } = useGetTeam(projectId);

  useEffect(() => {
    if (lastPageURL?.split("/").includes("create")) {
      setSuccessMessage("Team created.");
      setShowSuccessAlert(true);
      localStorage.removeItem("lastPage");
    }
  }, []);

  useEffect(() => {
    if (
      team &&
      ((team.services.length > 0 && !sortedServices) ||
        (team.questionnaires.length > 0 && !sortedSurveys))
    ) {
      setSortedServices({
        data: team.services.map((service) => {
          return {
            id: service.id,
            checked: false,
            attributes: {
              phase: service.phase,
              category: service.category,
              name: service.name,
            },
          };
        }),
        meta: { "record-count": team.services.length },
      });
      setSortedSurveys({
        data: team.questionnaires.map((questionnaire) => {
          return {
            id: questionnaire.id,
            checked: false,
            attributes: {
              name: questionnaire.name,
              description: questionnaire.description,
            },
          };
        }),
        meta: { "record-count": team.questionnaires.length },
      });
    }
  }, [team]);

  if (!team || isLoading) {
    return <SmallSpinner />;
  }

  const assignedServicesColumns = [
    {
      label: "Phase",
      width: 4,
      sorted: sortBy.phase,
      sortable: true,
    },
    {
      label: "Service Category",
      width: 4,
      sorted: sortBy.category,
      sortable: true,
    },
    {
      label: "Service Name",
      width: 4,
      sorted: sortBy.name,
      sortable: true,
    },
  ];

  const assignedSurveysColumns = [
    {
      label: "Survey Name",
      width: 4,
      sorted: sortBy.name,
      sortable: true,
    },
    {
      label: "Survey Description",
      width: 8,
      sorted: sortBy.description,
      sortable: true,
    },
  ];

  const handleSort = (column) => {
    let location = "services";
    if (column == "Survey Name" || column == "Survey Description") {
      location = "surveys";
    }

    let name = column.split(" ").pop().toLowerCase();

    const sorted = sortItemsByAttribute(
      location === "services" ? sortedServices : sortedSurveys,
      name,
      sortBy
    );

    if (location == "services") {
      setSortedServices(sorted.data);
    } else {
      setSortedSurveys(sorted.data);
    }
    setSortBy(sorted.columns);
  };

  return (
    <>
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <TeamInfo mode="edit" data={team} refetch={refetch} />
      <div className="servicesSurveysTabs">
        <Tabs
          defaultActiveKey={1}
          id="uncontrolled-tab"
          className="mb-3"
          onSelect={() => null}
        >
          <Tab
            eventKey={1}
            title={`Assigned Services (${
              sortedServices ? sortedServices.meta["record-count"] : 0
            })`}
            tabClassName="servicesTab"
            className="servicesContent"
          >
            <h3 className="teamsTableHeader">Assigned Services</h3>
            <FEPaginatedTable
              tableType="services"
              tableItemsData={
                sortedServices
                  ? sortedServices
                  : { data: [], meta: { "record-count": 0, "page-count": 0 } }
              }
              columnHeaders={assignedServicesColumns}
              checkboxes={false}
              onSort={handleSort}
              pageSizeEnabled
            />
          </Tab>
          <Tab
            eventKey={2}
            title={`Assigned Surveys (${
              sortedSurveys ? sortedSurveys.meta["record-count"] : 0
            })`}
            tabClassName="surveysTab"
            className="surveysContent"
          >
            <h3 className="teamsTableHeader">Assigned Surveys</h3>
            <FEPaginatedTable
              tableType="surveys"
              tableItemsData={
                sortedSurveys
                  ? sortedSurveys
                  : { data: [], meta: { "record-count": 0, "page-count": 0 } }
              }
              columnHeaders={assignedSurveysColumns}
              checkboxes={false}
              onSort={handleSort}
              pageSizeEnabled
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default Edit;
