import { useListBlueprintsForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";

interface Blueprint {
  label: string;
  value: string;
}

const useListBlueprintsForAccount = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListBlueprintsForAccountQuery({ slug: accountSlug });

  let blueprints: Blueprint[] = [];

  if (data?.data) {
    try {
      blueprints = data.data.map((blueprint) => {
        if (!blueprint.id) {
          throw new Error("Missing blueprint.id");
        }
        if (!blueprint.attributes?.name) {
          throw new Error("Missing blueprint.attributes.name");
        }

        return {
          value: String(blueprint.id),
          label: blueprint.attributes.name || "",
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    blueprints,
    blueprintsLoading: isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListBlueprintsForAccount;
